// Application Actions
// Saga Actions
export const APPLICATION_INIT_REQUEST_ACTION = 'APPLICATION_INIT_REQUEST';
export const APPLICATION_INIT_SUCCESS_ACTION = 'APPLICATION_INIT_SUCCESS';
export const APPLICATION_DATA_REQUEST_ACTION = 'APPLICATION_DATA_REQUEST';
export const APPLICATION_DATA_SUCCESS_ACTION = 'APPLICATION_DATA_SUCCESS';
export const APPLICATION_SET_ERROR_ACTION = 'APPLICATION_SET_ERROR';
export const APPLICATION_SET_USER_CHECK_ACTION = 'APPLICATION_SET_USER_CHECK';
export const APPLICATION_SET_PAYMENT_KEY_ACTION = 'APPLICATION_SET_PAYMENT_KEY';
export const APPLICATION_SET_SUBSCRIPTIONS_ACTION = 'APPLICATION_SET_SUBSCRIPTIONS';
export const APPLICATION_SET_PAY_GATED_ACTION = 'APPLICATION_SET_PAY_GATED';
export const APPLICATION_SUBSCRIPTIONS_REQUEST_ACTION = 'APPLICATION_SUBSCRIPTIONS_REQUEST';
export const APPLICATION_SUBSCRIPTIONS_SUCCESS_ACTION = 'APPLICATION_SUBSCRIPTIONS_SUCCESS';
export const APPLICATION_DATA_CLEAR_REQUEST_ACTION =
  'APPLICATION_DATA_CLEAR_REQUEST';
export const APPLICATION_DATA_LOAD_SUCCESS_ACTION =
  'APPLICATION_DATA_LOAD_SUCCESS';
export const APPLICATION_DATA_LOAD_FAILURE_ACTION =
  'APPLICATION_DATA_LOAD_FAILURE';
export const APPLICATION_SET_DESIRED_ROUTE_ACTION =
  'APPLICATION_SET_DESIRED_ROUTE';
export const APPLICATION_SET_TODAY_ACTION = 'APPLICATION_SET_TODAY';
export const APPLICATION_SET_SYNCING_ACTION = 'APPLICATION_SET_SYNCING';

export const initializeApplicationAction = () => ({
  type: APPLICATION_INIT_REQUEST_ACTION
});

export const loadApplicationDataAction = () => ({
  type: APPLICATION_DATA_REQUEST_ACTION
});

export const applicationDataSuccessAction = type => ({
  type: APPLICATION_DATA_LOAD_SUCCESS_ACTION,
  payload: type
});

export const clearApplicationDataAction = () => ({
  type: APPLICATION_DATA_CLEAR_REQUEST_ACTION
});

export const setDesiredRouteAction = route => ({
  type: APPLICATION_SET_DESIRED_ROUTE_ACTION,
  payload: route
});

export const setErrorAction = error => ({
  type: APPLICATION_SET_ERROR_ACTION,
  payload: error
});

export const setUserCheckAction = userCheck => ({
  type: APPLICATION_SET_USER_CHECK_ACTION,
  payload: userCheck
});

export const setPaymentKeyAction = key => ({
  type: APPLICATION_SET_PAYMENT_KEY_ACTION,
  payload: key
});

export const setSubscriptionsAction = subscriptions => ({
  type: APPLICATION_SET_SUBSCRIPTIONS_ACTION,
  payload: subscriptions
});

export const fetchSubscriptionsAction = (code) => ({
  type: APPLICATION_SUBSCRIPTIONS_REQUEST_ACTION,
  payload: code
});

export const setPayGateAction = gated => ({
  type: APPLICATION_SET_PAY_GATED_ACTION,
  payload: gated
});

export const setTodayAction = today => ({
  type: APPLICATION_SET_TODAY_ACTION,
  payload: today
});

export const setSyncingAction = payload => ({
  type: APPLICATION_SET_SYNCING_ACTION,
  payload
});
