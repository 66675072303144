import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/Remove';
import NavigateNextIcon from '@material-ui/icons/Add';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import { getTimeOfDayFromMinutes, getDayTimeSteps } from '../../utils/date';

export const style = () => ({
  valueChipRoot: {
    height: '20px'
  },
  timeChip: {
    paddingLeft: 9,
    paddingRight: 9,
    minWidth: '2em',
    display: 'initial'
  },
  smallIconButton: {
    padding: 3,
    marginTop: 0
  },
  smallIcon: {
    paddingLeft: 3,
    paddingRight: 3
  }
});

class TimeOfDayHelper extends Component {
  state = {
    value: 0,
    open: false
  };

  componentDidMount() {
    this.populateValue();
  }

  componentDidUpdate() {
    this.checkDeferredCommands();
  }

  anchorEl;

  getFormattedTimeSteps() {
    const { value } = this.state;
    const { timeSteps } = this.props;

    return timeSteps.map(step => (
      <MenuItem
        key={step}
        onClick={this.handleMenuClose(step)}
        selected={step === value}
      >
        {getTimeOfDayFromMinutes(step / 60)}
      </MenuItem>
    ));
  }

  populateValue = () => {
    const { value } = this.state;
    const { date } = this.props;

    if (value > 0) return;

    const dateAsMoment = moment(date);
    const timeOfDayInSeconds = dateAsMoment.diff(
      moment(date).startOf('day'),
      'seconds'
    );

    this.setState({ value: timeOfDayInSeconds - (timeOfDayInSeconds % 900) });
  };

  // List of commands to run after render.
  deferredCommands = [];

  /*
   *
   * The Deferred Commands model is to allow us to run functions once the
   * state has been set.
   * This is useful when we want to persist data via a redux action, or run
   * multiple steps to record progress.
   *
   */
  checkDeferredCommands = () => {
    if (!this.deferredCommands.length) return;

    this.deferredCommands = this.deferredCommands.filter(command => {
      command();
      return false;
    });
  };

  handleSetTime = value => {
    const { onSelect } = this.props;

    const today = moment();

    onSelect(
      today
        .startOf('day')
        .add(value, 'seconds')
        .toDate()
    );
  };

  handleTimeStep = (back = false) => {
    const { timeSteps } = this.props;
    const { value } = this.state;
    let currentTimeStepIndex = timeSteps.indexOf(parseInt(value, 10));

    if (currentTimeStepIndex === -1) currentTimeStepIndex = 0;

    if (back) {
      currentTimeStepIndex =
        currentTimeStepIndex === 0 ? 0 : currentTimeStepIndex - 1;
    } else {
      currentTimeStepIndex =
        currentTimeStepIndex === timeSteps.length - 1
          ? timeSteps.length - 1
          : currentTimeStepIndex + 1;
    }

    const newValue = timeSteps[currentTimeStepIndex];

    this.setState({ value: newValue });
    this.handleSetTime(newValue);
  };

  handleDecrement = () => {
    this.handleTimeStep(true);
  };

  handleIncrement = () => {
    this.handleTimeStep();
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleMenuClose = (value = null) => () => {
    if (value !== null) {
      this.setState({ value });
      this.handleSetTime(value);
    }

    this.setState({ open: false });
  };

  render() {
    const { value, open } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <IconButton
          className={classes.smallIconButton}
          onClick={this.handleDecrement}
        >
          <NavigateBeforeIcon className={classes.smallIcon} />
        </IconButton>
        <IconButton
          buttonRef={node => {
            this.anchorEl = node;
          }}
          className={classes.smallIconButton}
          onClick={this.handleOpen}
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
        >
          <Chip label={getTimeOfDayFromMinutes(value / 60)} />
        </IconButton>
        <IconButton
          className={classes.smallIconButton}
          onClick={this.handleIncrement}
        >
          <NavigateNextIcon className={classes.smallIcon} />
        </IconButton>
        <Menu
          anchorEl={this.anchorEl}
          keepMounted
          PaperProps={{
            style: {
              maxHeight: 250,
              width: 200
            }
          }}
          open={open}
          onClose={this.handleMenuClose}
        >
          {this.getFormattedTimeSteps()}
        </Menu>
      </div>
    );
  }
}

TimeOfDayHelper.defaultProps = {
  date: new Date(),
  timeSteps: getDayTimeSteps()
};

TimeOfDayHelper.propTypes = {
  classes: PropTypes.any.isRequired,
  date: PropTypes.any,
  timeSteps: PropTypes.array,
  onSelect: PropTypes.func.isRequired
};

export default withStyles(style)(TimeOfDayHelper);
