import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/Remove';
import NavigateNextIcon from '@material-ui/icons/Add';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { getHoursFromMinutes } from '../../utils/date';

export const style = () => ({
  timeChipRoot: {
    height: '20px'
  },
  timeChip: {
    paddingLeft: 9,
    paddingRight: 9,
    minWidth: '5em',
    display: 'initial'
  },
  smallIconButton: {
    padding: 3,
    marginTop: 0
  },
  smallIcon: {
    paddingLeft: 3,
    paddingRight: 3
  }
});

class TimeSelectorHelper extends Component {
  state = {
    value: 0,
    open: false
  };

  componentDidMount() {
    this.populateValue();
  }

  anchorEl;

  populateValue = () => {
    const { value } = this.state;
    const { minutes } = this.props;

    if (value === minutes) return;

    this.setState({ value: minutes });
  };

  handleSetTime = minutes => {
    const { onSelect } = this.props;
    onSelect(minutes);
  };

  handleTimeStep = (back = false) => {
    const { timeSteps } = this.props;
    const { value } = this.state;
    let currentTimeStepIndex = timeSteps.indexOf(parseInt(value, 10));

    if (currentTimeStepIndex === -1) currentTimeStepIndex = 0;

    if (back) {
      currentTimeStepIndex =
        currentTimeStepIndex === 0 ? 0 : currentTimeStepIndex - 1;
    } else {
      currentTimeStepIndex =
        currentTimeStepIndex === timeSteps.length - 1
          ? timeSteps.length - 1
          : currentTimeStepIndex + 1;
    }

    const newValue = timeSteps[currentTimeStepIndex];

    this.setState({ value: newValue });
    this.handleSetTime(newValue);
  };

  handleDecrement = () => {
    this.handleTimeStep(true);
  };

  handleIncrement = () => {
    this.handleTimeStep();
  };

  handleMenuOpen = () => {
    this.setState({ open: true });
  };

  handleMenuClose = (value = null) => () => {
    if (value !== null) {
      this.setState({ open: false, value });
      this.handleSetTime(value);
    } else {
      this.setState({ open: false });
    }
  };

  render() {
    const { classes, timeSteps } = this.props;
    const { value, open } = this.state;
    return (
      <div>
        <IconButton
          className={classes.smallIconButton}
          onClick={this.handleDecrement}
        >
          <NavigateBeforeIcon className={classes.smallIcon} />
        </IconButton>
        <IconButton
          buttonRef={node => {
            this.anchorEl = node;
          }}
          className={classes.smallIconButton}
          onClick={this.handleMenuOpen}
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
        >
          <Chip
            classes={{
              root: classes.timeChipRoot,
              label: classes.timeChip
            }}
            label={getHoursFromMinutes(value)}
          />
        </IconButton>
        <IconButton
          className={classes.smallIconButton}
          onClick={this.handleIncrement}
        >
          <NavigateNextIcon className={classes.smallIcon} />
        </IconButton>
        <Popper
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          style={{ zIndex: 2 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper elevation={2}>
                <ClickAwayListener onClickAway={this.handleMenuClose()}>
                  <MenuList>
                    {timeSteps.map(step => (
                      <MenuItem key={step} onClick={this.handleMenuClose(step)}>
                        {getHoursFromMinutes(step)}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

TimeSelectorHelper.defaultProps = {
  minutes: 0,
  timeSteps: [0, 5, 10, 15, 30, 60, 120, 240, 480]
};

TimeSelectorHelper.propTypes = {
  classes: PropTypes.any.isRequired,
  minutes: PropTypes.any,
  timeSteps: PropTypes.array,
  onSelect: PropTypes.func.isRequired
};

export default withStyles(style)(TimeSelectorHelper);
