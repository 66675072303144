import { v4 as uuidv4 } from 'uuid';
import { getLearnPageStruct } from '../../structs/learn';

// Learn Actions
export const ADD_LEARN_PAGE = 'ADD_LEARN_PAGE';
export const LOAD_LEARN_PAGES_FROM_DISK = 'LOAD_LEARN_PAGES_FROM_DISK';
export const DELETE_LEARN_PAGE = 'DELETE_LEARN_PAGE';
export const SAVE_LEARN_PAGE = 'SAVE_LEARN_PAGE';
export const LEARN_PAGE_CREATE_REQUEST_ACTION = 'LEARN_PAGE_CREATE_REQUEST';
export const LEARN_PAGE_CREATE_SUCCESS_ACTION = 'LEARN_PAGE_CREATE_SUCCESS';
export const LEARN_PAGE_DELETE_REQUEST_ACTION = 'LEARN_PAGE_DELETE_REQUEST';
export const LEARN_PAGE_DELETE_SUCCESS_ACTION = 'LEARN_PAGE_DELETE_SUCCESS';
export const LEARN_PAGE_UPDATE_REQUEST_ACTION = 'LEARN_PAGE_UPDATE_REQUEST';
export const LEARN_PAGE_UPDATE_SUCCESS_ACTION = 'LEARN_PAGE_UPDATE_SUCCESS';
export const LEARN_PAGE_DATA_LOAD_REQUEST_ACTION =
  'LEARN_PAGE_DATA_LOAD_REQUEST';
export const LEARN_PAGE_DATA_LOAD_SUCCESS_ACTION =
  'LEARN_PAGE_DATA_LOAD_SUCCESS';

export const addLearnPageAction = (page, createCallback = null) => ({
  type: LEARN_PAGE_CREATE_REQUEST_ACTION,
  payload: Object.assign({}, getLearnPageStruct(page), {
    id: uuidv4()
  }),
  callback: createCallback
});

export const loadLearnPagesFromDiskAction = pages => ({
  type: LEARN_PAGE_DATA_LOAD_REQUEST_ACTION,
  payload: pages
});

export const deleteLearnPagesAction = id => ({
  type: LEARN_PAGE_DELETE_REQUEST_ACTION,
  payload: id
});

export const saveLearnPageAction = list => ({
  type: LEARN_PAGE_UPDATE_REQUEST_ACTION,
  payload: list
});
