import { SET_INTERNET_STATUS } from '../actions/notifications';
import { InternetStatusStruct } from '../../structs/notification';

export default function internetStatusReducer(
  oldstate = Object.assign({}, InternetStatusStruct),
  action
) {
  switch (action.type) {
    case SET_INTERNET_STATUS:
      if (oldstate.status !== action.payload)
        return Object.assign({}, InternetStatusStruct, {
          status: action.payload
        });
      return oldstate;

    default:
      return oldstate;
  }
}
