import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import DeviceSync from '../../containers/Backup/Sync';
import GlobalSearch from '../../containers/Search/GlobalSearch';

const styles = theme => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: theme.palette.secondary.dark
  },
  toolBar: {
    minHeight: 40,
    color: theme.palette.secondary.contrastText,
    justifyContent: 'space-between'
  },
  overline: {
    color: theme.palette.secondary.contrastText
  }
});

class BottomBar extends Component {
  state = {};

  render() {
    const { classes } = this.props;
    return (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Typography variant="overline" className={classes.overline}>
            {`Formigio`}
            {` `}
            <Hidden only={['xs', 'sm']}>Planner</Hidden>
          </Typography>
          <DeviceSync />
          <div>
            <GlobalSearch />
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}
BottomBar.propTypes = {
  classes: PropTypes.any.isRequired
};

export default withStyles(styles)(BottomBar);
