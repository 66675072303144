export const SUBSCRIPTION_CODE_PUBLIC = 'public';

export const SubscriptionStruct = {
  schemaVersion: '1.0.0',
  id: '',
  name: '',
  label: '',
  description: '',
  stripePlanId: '',
  active: false,
  level: '',
  sort: 0,
  price: 0,
  code: SUBSCRIPTION_CODE_PUBLIC
};

export const getSubscriptionStruct = (
  subscription = SubscriptionStruct
) => Object.assign({}, SubscriptionStruct, subscription);

export default SubscriptionStruct;
