import {REMOVE_LOCAL_COMMITMENT_TRAY, UPSERT_LOCAL_COMMITMENT_TRAY} from "../actions/commitmentTray";

export default function commitmentTrayReducer(state = [], action) {
  switch (action.type) {
    case UPSERT_LOCAL_COMMITMENT_TRAY:
      if (
        state.filter(tray => action.payload.id === tray.id)
          .length
      )
        return state.map(tray => {
          if (action.payload.id === tray.id) {
            return action.payload;
          }
          return tray;
        });

      return [...state, action.payload];

    case REMOVE_LOCAL_COMMITMENT_TRAY:
      return state.filter(tray => tray.id !== action.payload.id);

    default:
      return state;
  }
}
