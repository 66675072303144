import { take, put, select, takeEvery } from 'redux-saga/effects';
import {
  REQUEST_ACTIVE_TRACKER,
  requestActiveTrackerAction, resetActiveTrackerAction,
  setActiveTrackerAction
} from '../actions/activeTracker';
import {
  fetchTrackerAction, migrateTrackerAction,
  SAVE_LOCAL_TRACKER,
  TRACKER_CREATE_SUCCESS_ACTION, TRACKER_DELETE_SUCCESS_ACTION,
  TRACKER_FETCH_SUCCESS_ACTION, TRACKER_MIGRATE_SUCCESS_ACTION,
  UPSERT_LOCAL_TRACKER
} from '../actions/trackers';
import {requiresMigration} from '../../structs/trackers';

function* handleActiveTrackerUpdate(action) {
  // Anytime the active tracker's tracker is updated, send that to the store.
  const tracker = action.payload;
  const activeTracker = yield select(store => store.activeTracker);
  if (
    activeTracker.tracker &&
    tracker.id === activeTracker.tracker.id &&
    tracker.updatedAt !== activeTracker.tracker.updatedAt
  ) {
    yield put(setActiveTrackerAction(tracker));
  }
}

function* handleActiveTrackerRequest(action) {
  // Set ActiveTracker to the store

  const trackers = yield select(store => store.trackers);

  if (!trackers.length) return;

  const trackerToSet = trackers
    .filter(tracker => tracker.id === action.payload)
    .pop();

  if (!trackerToSet) return;

  yield put(fetchTrackerAction(trackerToSet));

  const { payload: fetchedTracker } = yield take(TRACKER_FETCH_SUCCESS_ACTION);
  
  if (requiresMigration(fetchedTracker)) {
    yield put(migrateTrackerAction(fetchedTracker));
    const { payload: savedTracker } = yield take(TRACKER_MIGRATE_SUCCESS_ACTION);
    yield put(setActiveTrackerAction(savedTracker));
  } else {
    yield put(setActiveTrackerAction(fetchedTracker));
  }
}

function* handleCreateTrackerSuccess(action) {
  const newTracker = action.payload;
  yield put(requestActiveTrackerAction(newTracker.id));
}

function* handleTrackerDelete() {
  yield put(resetActiveTrackerAction());
}

export default function* activeTrackerSaga() {
  yield takeEvery(REQUEST_ACTIVE_TRACKER, handleActiveTrackerRequest);
  yield takeEvery(TRACKER_CREATE_SUCCESS_ACTION, handleCreateTrackerSuccess);
  yield takeEvery(SAVE_LOCAL_TRACKER, handleActiveTrackerUpdate);
  yield takeEvery(UPSERT_LOCAL_TRACKER, handleActiveTrackerUpdate);
  yield takeEvery(TRACKER_DELETE_SUCCESS_ACTION, handleTrackerDelete)
}
