import {
  SET_USER_EMAIL_PREFERENCE,
  SET_USER_NOTIFICATIONS,
} from "../actions/notifications";
import {getEmailPreferenceStruct} from '../../structs/notification';

export const initialState = {
  userPreference: getEmailPreferenceStruct(),
  userNotificationList: [],
};

export default function userEmailPreferenceReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case SET_USER_EMAIL_PREFERENCE:
      return {
        ...state,
        userPreference: action.payload,
      };

    case SET_USER_NOTIFICATIONS:
      return {
        ...state,
        userNotificationList: action.payload,
      };

    default:
      return state;
  }
}
