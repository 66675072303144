import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import {ListItem, ListItemAvatar} from "@material-ui/core";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import {Announcement, DeleteForever} from "@material-ui/icons";
import {deleteCommitInviteAction} from "../../redux/actions/commitInvite";
import moment from "moment";
import {stringifyApn} from "../../utils/apn/v2";

const InviteItem = ({ invite, isOwner, onEdit, onRespond }) => {
  const dispatch = useDispatch();
  const user = useSelector(store => store.user);
  const userApn = stringifyApn({ userId: user.id });

  const isInvitee = invite.toUserApn === userApn;
  const isInviter = invite.fromUserApn === userApn;

  const handleDelete = () => {
    dispatch(deleteCommitInviteAction(invite));
  };

  const handleInviteClick = () => {
    if (isInvitee) {
      onRespond(invite);
    }

    if (isInviter || isOwner) {
      onEdit(invite);
    }
  };

  const getUserName = () => {
    return isInvitee ? `You by ${invite.fromUserName}` : invite.toUserName;
  };

  return (
    <ListItem
      onClick={handleInviteClick}
      button
    >
      <ListItemAvatar><Announcement color={"disabled"} /></ListItemAvatar>
      <ListItemText
        primary={`${invite.description}`}
        secondary={`To: ${getUserName()} | When: ${moment(invite.createdAt).calendar()} | Status: ${invite.status}`}
      />
      {(isOwner || isInviter)
        ? (
          <ListItemSecondaryAction>
            <IconButton
              onClick={handleDelete}
            >
              <DeleteForever/>
            </IconButton>
          </ListItemSecondaryAction>
        ) : null}
    </ListItem>
  );
};

InviteItem.defaultProps = {
  isOwner: false,
  onEdit: () => null
};

InviteItem.propTypes = {
  invite: PropTypes.object.isRequired,
  onRespond: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  isOwner: PropTypes.bool
};

export default InviteItem;
