import { API, graphqlOperation } from 'aws-amplify';
import {
    updateEmailPreferences,
    createEmailPreferences,
    createEmailNotification,
    updateEmailNotification,
    deleteEmailNotification,
} from '../../graphql/mutations';
import {
  getEmailPreferences,
  listEmailNotifications,
} from '../../graphql/queries';

import {
  getEmailNotificationStruct,
  getEmailPreferenceStruct,
} from '../../structs/notification';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {prepAndPruneForAppSync} from "../appSync";

export const saveEmailPreference = async (emailPreference) => {
  const currentStatus = await fetchEmailPreferences({
    id: emailPreference.userApn
  });
  if (currentStatus) {
    const { data: { updateEmailPreferences: preference }} = await API.graphql(
      graphqlOperation(updateEmailPreferences, {
        input: getEmailPreferenceStruct(emailPreference),
      })
    );
    return preference;
  }

  const { data: { createEmailPreferences: preference }} = await API.graphql(
    graphqlOperation(createEmailPreferences, {
      input: getEmailPreferenceStruct(emailPreference),
    })
  );
  
  return preference;
};

export const fetchEmailPreferences = async (user) => {
  const { data: { getEmailPreferences: preference }} = await API.graphql(
    graphqlOperation(getEmailPreferences, {
      userApn: user.id,
    })
  );
  return preference;
};

export const createRemoteEmailNotification = async (notificationDetails) => {
  const { data: { createEmailNotification: notification }} = await API.graphql(
    graphqlOperation(createEmailNotification, {
      input: prepAndPruneForAppSync({
        ...notificationDetails,
        id: uuidv4(),
        sendTimestamp: notificationDetails.sendTimestamp
          ? notificationDetails.sendTimestamp
          : moment().toISOString(),
        processedTimestamp: new Date(null),
      }, getEmailNotificationStruct)
    })
  );

  return notification;
};

export const updateNotificationById = async (details) => {
  const {
    createdAt,
    updatedAt,
    ...rest
  } = details.notificationDetails;
  const { data: { updateEmailNotification: { items }}} = await API.graphql(
    graphqlOperation(updateEmailNotification, {
      input: prepAndPruneForAppSync({
        userApn: details.userId,
        id: details.notificationDetails.id,
        ...rest,
        new: false,
      }, getEmailNotificationStruct)
    }
  ));

  return items;
};

export const fetchUserEmailNotifications = async (user) => {
  const { data: { listEmailNotifications: { items }}} = await API.graphql(
    graphqlOperation(listEmailNotifications, {
      userApn: user.id,
    })
  );
  return items;
};

export const fetchUserScheduledEmailNotifications = async (user) => {
  let whileNextToken = 'initial';
  let allNotifications = [];

  /* eslint-disable no-await-in-loop */
  while (whileNextToken) {
    const {data: {listEmailNotifications: {items, nextToken}}} = await API.graphql(
        graphqlOperation(listEmailNotifications, {
          limit: 100,
          userApn: user.id,
          filter: {
            processedTimestamp: {
              eq: "1970-01-01T00:00:00.000Z"
            }
          },
          nextToken: whileNextToken === 'initial' ? null : whileNextToken
        })
    );
    allNotifications = allNotifications.concat(items);
    whileNextToken = nextToken;
  }
  return allNotifications;
};


export const fetchUserSentEmailNotifications = async (user, olderThanDate) => {
  let whileNextToken = 'initial';
  let allNotifications = [];

  /* eslint-disable no-await-in-loop */
  while (whileNextToken) {
    const {data: {listEmailNotifications: {items, nextToken}}} = await API.graphql(
        graphqlOperation(listEmailNotifications, {
          limit: 100,
          userApn: user.id,
          filter: {
            sendTimestamp: {
              lt: olderThanDate
            }
          },
          nextToken: whileNextToken === 'initial' ? null : whileNextToken
        })
    );
    allNotifications = allNotifications.concat(items);
    whileNextToken = nextToken;
  }
  return allNotifications;
};

export const fetchEmailNotificationsByEntity = async (userApn, entityApn) => {
  return new Promise(async resolve => {
    let all = [];
    let whileToken = 'initial';

    while (whileToken) {
      const {data: {listEmailNotifications: {items, nextToken}}} = await API.graphql(
        graphqlOperation(listEmailNotifications, {
          userApn,
          filter: {
            entityApn: {
              eq: entityApn
            }
          },
          limit: 1000,
          nextToken: whileToken === 'initial' ? null : whileToken
        })
      );

      all = all.concat(items);
      whileToken = nextToken;
    }

    resolve(
      all.length
        ? all.map(notification => getEmailNotificationStruct(notification))
        : []
    );
  });
}

export const deleteRemoteEmailNotification = async ({ id, userApn }) => {
  await API.graphql(
    graphqlOperation(deleteEmailNotification, {
        input: { id, userApn }
      }
    ));
};
