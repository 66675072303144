import React, { useState} from 'react';
import {useDispatch} from "react-redux";
import PropTypes from 'prop-types';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { getJournalStruct } from '../../structs/journal';
import {addJournalAction} from "../../redux/actions/journal";

function JournalAdd ({ trackerId, onComplete, focus }) {
  const dispatch = useDispatch();
  const [entry, setEntry] = useState('');

  const handleNewEntryChange = event => {
    setEntry(event.target.value);
  };

  const handleSubmit = () => {
    dispatch(
      addJournalAction(
        getJournalStruct({
          entry,
          trackerId
        })
      )
    );
    setEntry('');
    onComplete();
  };

  return (
    <List>
      <ListItem>
        <TextField
          style={{ width: '100%' }}
          key="newEntry"
          name="newEntry"
          multiline
          autoFocus={focus}
          fullWidth
          value={entry}
          placeholder="Add current progress or status as Journal Entry"
          onChange={handleNewEntryChange}
        />
      </ListItem>
      <ListItem>
        <Button
          onClick={handleSubmit}
          fullWidth
          color="primary"
          variant="contained"
          size="small"
          disabled={!entry}
        >
          Save
        </Button>
      </ListItem>
    </List>
  );
}

JournalAdd.defaultProps = {
  focus: false
}

JournalAdd.propTypes = {
  trackerId: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  focus: PropTypes.bool
};

export default JournalAdd;
