import React from 'react';
import PropTypes from 'prop-types';
import {MenuItem} from "@material-ui/core";
import {TRACKER_ACTION_TEMPLATE} from "../../../structs/trackers";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {ListAlt} from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";

const CreateTemplateMenuItem = ({ onDialog }) => {

  const handleClick = () => {
    onDialog(TRACKER_ACTION_TEMPLATE);
  };

  return (
    <MenuItem
      variant="text"
      color="secondary"
      onClick={handleClick}
    >
      <ListItemIcon>
        <ListAlt fontSize={"small"}/>
      </ListItemIcon>
      <ListItemText>Create Template</ListItemText>
    </MenuItem>
  )
};

CreateTemplateMenuItem.defaultProps = {};

CreateTemplateMenuItem.propTypes = {
  onDialog: PropTypes.func.isRequired
};

export default CreateTemplateMenuItem;
