import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import {COMMITMENT_DEFAULT_COLOR, getCommitmentStruct} from '../../structs/commitments';
import Tour from '../../containers/Tour';
import { convertPickerDateToDate } from '../../utils/date';
import DateHelper from '../../containers/Commitment/DateHelper';
import ColorSelectorHelper from '../Ui/ColorSelectorHelper';
import TimeSelectorHelper from '../Ui/TimeSelectorHelper';
import Calendar from '../../containers/Planner/Calendar';
import RoleButton from "../Role/Button";
import CommitmentTrayButton from "../CommitmentTray/Button";
import Reminder from "./Reminder";
import CommitInviteButton from "../CommitInvite/Button";
import {getContactStruct} from "../../structs/contact";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import {getCommitInviteStruct} from "../../structs/commitInvite";
import DialogTitle from "@material-ui/core/DialogTitle";

export const style = () => ({
  colorLabel: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    marginTop: '.5em'
  }
});

class CommitmentForm extends Component {
  state = {
    commitment: getCommitmentStruct(),
    touched: false,
    reset: false,
    inviteContact: getContactStruct()
  };

  componentDidMount() {
    this.initializeCommitment();
  }

  componentDidUpdate() {
    this.initializeCommitment();
  }

  initializeCommitment = () => {
    const { commitment, reset, touched } = this.state;
    const { user, activeContext: { apn: contextApn, description: contextDescription, tracker, commitmentDescription }} = this.props;

    if (reset) {
      this.setState({ commitment: getCommitmentStruct(), reset: false });
      return;
    }

    const newCommitment = {...commitment};
    let needsStateUpdate = false;

    if (commitment === null || tracker === null) return;
  
    if (!commitment.contextDescription && contextDescription) {
      newCommitment.contextDescription = contextDescription;
    
      needsStateUpdate = true;
    }

    if (!commitment.description && commitmentDescription) {
      newCommitment.description = commitmentDescription;

      needsStateUpdate = true;
    }

    if (!commitment.contextApn && contextApn) {
      newCommitment.contextApn = contextApn;

      needsStateUpdate = true;
    }

    if (!commitment.userId && user.id) {
      newCommitment.userId = user.id;
      needsStateUpdate = true;
    }

    if (commitment.date === '') {
      newCommitment.date = moment()
        .minutes(Math.floor(moment().minutes() / 15) * 15)
        .seconds(0)
        .format();

      needsStateUpdate = true;
    }

    if (needsStateUpdate === true) {
      this.setState({
        commitment: {
          ...newCommitment,
          description: newCommitment.description.trim() ? newCommitment.description : ((contextDescription && !touched) ? `Work on: ${contextDescription}` : '')
        }
      });
    }
  };

  handleSetDate = dateVal => {
    const { commitment } = this.state;
    const passedDate = moment(dateVal);
    const newDate = moment(commitment.date);
    newDate.set('year', passedDate.get('year'));
    newDate.set('month', passedDate.get('month'));
    newDate.set('date', passedDate.get('date'));
    this.setState({
      commitment: {
        ...commitment,
        date: moment(newDate).format()
      }
    });
  };

  handleSetDateStamp = dateVal => {
    const { commitment } = this.state;
    this.setState({
      commitment: {
        ...commitment,
        date: moment(dateVal).format()
      }
    });
  };

  handleSetTime = timeCommitment => {
    const { commitment } = this.state;
    this.setState({ commitment: {
      ...commitment,
      timeCommitment
    }});
  };

  handleChange = event => {
    const { commitment } = this.state;

    this.setState({
      commitment: {
        ...commitment,
        touched: true,
        [event.target.name]: event.target.name === 'date'
          ? moment(convertPickerDateToDate(commitment.date)).format()
          : event.target.value
      }
    });
  };

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.handleSubmit();
    }
  };

  handleDialogClose = () => {
    const { resetActiveContext } = this.props;
    this.setState({ reset: true }, () => {
      resetActiveContext();
    });
  };

  handleColorSelect = highlightColor => {
    const { commitment } = this.state;
    this.setState({ commitment: {
      ...commitment,
      highlightColor
    }});
  };

  handleRoleChange = role => {
    const { commitment } = this.state;
    this.setState({ commitment: {
        ...commitment,
        role
      }});
  };

  handleTrayChange = trays => {
    const { commitment } = this.state;
    this.setState({ commitment: {
        ...commitment,
        trays
      }});
  };

  handleInviteChange = inviteContact => {
    this.setState({ inviteContact });
  };

  handleNotesChange = notes => {
    const { commitment } = this.state;
    this.setState({ commitment: {
        ...commitment,
        notes
      }});
  };

  handleReminderChange = reminders => {
    const { commitment } = this.state;
    this.setState({ commitment: {
        ...commitment,
        reminders
      }});
  };

  handleSubmit = () => {
    const { commitment } = this.state;
    const { addCommitment, activeContext: { tracker: { id: trackerId, role: trackerRole } }} = this.props;
    
    this.setState({ commitment: getCommitmentStruct() }, () => {
      addCommitment(getCommitmentStruct({
        ...commitment,
        trackerId,
        role: commitment.role.name ? commitment.role : trackerRole,
        highlightColor: commitment.highlightColor === COMMITMENT_DEFAULT_COLOR ? trackerRole.color : commitment.highlightColor
      }));
      this.handleDialogClose();
    });
  };

  handleSubmitInvite = () => {
    const {
      commitment: {
        description, contextDescription, notes
      },
      inviteContact: {
        friendApn: toUserApn, friendName: toUserName
      }
    } = this.state;
    const { addCommitInvite, activeContext: { tracker: { id: trackerId }, apn: contextApn }} = this.props;

    this.setState({ commitment: getCommitmentStruct() }, () => {
      addCommitInvite(getCommitInviteStruct({
        description,
        notes,
        contextDescription,
        contextApn,
        toUserApn,
        toUserName,
        trackerId,
      }));
      this.handleDialogClose();
    });
  };

  handleFocus = event => {
    event.target.select();
  };

  render() {
    const { commitment, commitment: { role }, inviteContact } = this.state;
    const { activeContext: { apn: contextApn, description: contextDescription, tracker: { role: trackerRole }, tracker }} = this.props;
    return (
      <Dialog
        open={Boolean(contextApn)}
        onClose={this.handleDialogClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        PaperProps={{
          style: {
            overflow: 'visible',
            width: 'calc(75% - 64px)'
          }
        }}
      >
        <DialogTitle>{inviteContact.id ? 'Invite to Help' : 'Add Commitment'}</DialogTitle>
        {inviteContact.id ? (
          <DialogContent className="tour-commitinvite-intro">
            <IconButton
              style={{ position: 'absolute', right: 0, top: 0 }}
              onClick={this.handleDialogClose}
            >
              <CloseIcon/>
            </IconButton>
            <div>
              <CommitInviteButton onChange={this.handleInviteChange} selectedContact={inviteContact} tracker={tracker} />
            </div>
            <TextField
              margin="dense"
              id="commitmentDescription"
              label="Invite Description"
              name="description"
              multiline
              autoFocus
              value={commitment.description}
              fullWidth
              onFocus={this.handleFocus}
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
              InputProps={{
                disableUnderline: true
              }}
            />
            <TextField
              margin="dense"
              id="contextDescription"
              label="Context"
              name="contextDescription"
              value={commitment.contextDescription || contextDescription}
              multiline
              fullWidth
              onChange={this.handleChange}
              InputProps={{
                disableUnderline: true
              }}
            />
            <TextField
              label="Add Message"
              value={commitment.notes}
              fullWidth
              multiline
              rows={4}
              placeholder={`Message for ${inviteContact.friendName} (included in email notification)`}
              onChange={(event) => this.handleNotesChange(event.target.value)}
            />
          </DialogContent>
        ) : (
          <DialogContent className="tour-commitment-intro">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'baseline',
                marginBottom: '.5em'
              }}
            >
              <IconButton
                style={{ position: 'absolute', right: 0, top: 0 }}
                onClick={this.handleDialogClose}
              >
                <CloseIcon/>
              </IconButton>
              <div
                style={{
                  marginRight: '.75em'
                }}
              >
                <ColorSelectorHelper
                  color={commitment.highlightColor === COMMITMENT_DEFAULT_COLOR ? trackerRole.color : commitment.highlightColor}
                  onSelect={this.handleColorSelect}
                  buttonStyles={{ margin: 0 }}
                />
              </div>
              <TextField
                margin="dense"
                id="commitmentDescription"
                label="Commitment Description"
                name="description"
                multiline
                autoFocus
                value={commitment.description}
                fullWidth
                onFocus={this.handleFocus}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
                InputProps={{
                  disableUnderline: true
                }}
              />
            </div>
            <div>
              <TextField
                margin="dense"
                id="contextDescription"
                label="Context"
                name="contextDescription"
                value={commitment.contextDescription || contextDescription}
                multiline
                fullWidth
                onChange={this.handleChange}
                InputProps={{
                  disableUnderline: true
                }}
              />
            </div>
            <div>
              <CommitInviteButton onChange={this.handleInviteChange} selectedContact={inviteContact} tracker={tracker} />
              <RoleButton onChange={this.handleRoleChange} role={role.name ? role : trackerRole} />
              <CommitmentTrayButton onChange={this.handleTrayChange} trayIds={commitment.trays} />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  marginRight: '.75em'
                }}
              >
                {commitment.date && (
                  <DateHelper
                    commitment={commitment}
                    onSelect={this.handleSetDate}
                  />
                )}
                <div style={{display: 'flex'}}>
                  <TimeSelectorHelper
                    minutes={commitment.timeCommitment}
                    onSelect={this.handleSetTime}
                  />
                  <Reminder
                    reminders={commitment.reminders}
                    onChange={this.handleReminderChange}
                  />
                </div>
                <Calendar
                  onSelect={this.handleSetDateStamp}
                  activeDateStamp={moment(commitment.date).format()}
                  duration={commitment.timeCommitment}
                  color={commitment.highlightColor}
                />
              </div>
            </div>
            {contextApn ? (
              <Tour
                id="commitment-intro-v1.0"
                continuous
                zIndex={1500}
                steps={[
                  {
                    target: '.tour-commitment-intro',
                    content:
                      'Making and keeping commitments is critical to making progress ' +
                      'towards your dream. As you honestly consider the things that ' +
                      'it takes to get you where you want to be, keeping commitments ' +
                      'will make the biggest impact.'
                  },
                  {
                    target: '.tour-commitment-date',
                    content:
                      'You can choose today or tomorrow, or any day from the calendar.' +
                      ' Here, you can also choose the time box, and a highlight color.'
                  }
                ]}
              />
            ) : null}
          </DialogContent>
        )}
        <DialogActions>
          {inviteContact.id
            ? (
              <Button
                style={{ marginLeft: 'auto' }}
                onClick={this.handleSubmitInvite}
                disabled={!commitment.description}
                color="primary"
                variant="contained"
              >
                Send Invite
              </Button>
            ) : (
              <Button
                style={{ marginLeft: 'auto' }}
                onClick={this.handleSubmit}
                disabled={!commitment.description}
                color="primary"
                variant="contained"
              >
                Save
              </Button>
            )
          }
        </DialogActions>
      </Dialog>
    );
  }
}

CommitmentForm.defaultProps = {};

CommitmentForm.propTypes = {
  resetActiveContext: PropTypes.func.isRequired,
  addCommitment: PropTypes.func.isRequired,
  addCommitInvite: PropTypes.func.isRequired,
  user: PropTypes.any.isRequired,
  activeContext: PropTypes.object.isRequired
};

export default withStyles(style)(CommitmentForm);
