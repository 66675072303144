import {API, graphqlOperation} from "aws-amplify";
import * as queries from "../../graphql/queries";
import {stringifyApn} from "../apn/v2";
import moment from "moment/moment";
import * as mutations from "../../graphql/mutations";
import {getJournalStruct} from "../../structs/journal";

export const fetchRemoteJournals = async userId => {
  let allJournals = [];
  let whileNextToken = 'initial';

  /* eslint-disable no-await-in-loop */
  while (whileNextToken) {
    const { data: { listJournalv2s: { items, nextToken }}} = await API.graphql(
      graphqlOperation(queries.listJournalv2s, {
        ownerApn: stringifyApn({ userId }),
        limit: 500,
        filter: {
          datetime: {
            ge: moment()
              .subtract(3, 'day')
              .startOf('day')
              .format()
          }
        },
        nextToken: whileNextToken === 'initial' ? null : whileNextToken
      })
    );
    allJournals = allJournals.concat(items);
    whileNextToken = nextToken;
  }
  /* eslint-enable no-await-in-loop */

  return allJournals.length
    ? allJournals.map(journal => postFetchProcessJournal(journal))
    : [];
};

export const fetchRemoteLegacyJournals = async userId => {
  let allJournals = [];
  let whileNextToken = 'initial';

  /* eslint-disable no-await-in-loop */
  while (whileNextToken) {
    const GQL = await API.graphql(
      graphqlOperation(queries.listJournals, {
        ownerApn: stringifyApn({ userId }),
        limit: 500,
        nextToken: whileNextToken === 'initial' ? null : whileNextToken
      })
    );
    allJournals = allJournals.concat(GQL.data.listJournals.items);
    whileNextToken = GQL.data.listJournals.nextToken;
  }
  /* eslint-enable no-await-in-loop */

  return allJournals.length
    ? allJournals.map(journal => postFetchProcessJournal(journal))
    : [];
};


/*
 *
 * Journal Functions
 *
 */
export const fetchRemoteJournalsByTracker = async (trackerId, token) => {
  const {data: {Journalv2byTrackerId: {items, nextToken}}} = await API.graphql(
    graphqlOperation(queries.journalv2byTrackerId, {
      limit: 10,
      trackerId,
      sortDirection: 'DESC',
      nextToken: token
    })
  );

  return {
    nextToken,
    items: items.length > 0 ? items.map(item => postFetchProcessJournal(item)) : []
  }
};

export const upsertRemoteJournal = async journal => {
  // We fetch the latest from the API prior to sending it in, for some DynamoDB conditional checks
  const remoteJournal = await fetchRemoteJournal(journal.ownerApn, journal.id);

  if (!remoteJournal.id) {
    const input = prepAndPruneJournalForAppSync(Object.assign({}, journal));

    const { data: { createJournalv2: entry }} = await API.graphql(
      graphqlOperation(mutations.createJournalv2, {
        input
      })
    );

    return postFetchProcessJournal(entry);
  }

  // If the remote is behind the local, then
  if (moment(remoteJournal.updatedAt).isSameOrAfter(journal.updatedAt)) {
    // No need to send the Journal
    return postFetchProcessJournal(remoteJournal);
  }

  const input = prepAndPruneJournalForAppSync(
    Object.assign({}, remoteJournal, journal)
  );

  const { data: { updateJournalv2: entry }} = await API.graphql(
    graphqlOperation(mutations.updateJournalv2, {
      input
    })
  );

  return postFetchProcessJournal(entry);
};

export const fetchRemoteJournal = async (ownerApn, id) => {
  const { data: { getJournalv2: entry }} = await API.graphql(
    graphqlOperation(queries.getJournalv2, {
      ownerApn,
      id
    })
  );

  if (!entry) return getJournalStruct();
  return postFetchProcessJournal(entry);
};

export const removeRemoteJournal = async journal => {
  await API.graphql(
    graphqlOperation(mutations.deleteJournalv2, {
      input: { id: journal.id, ownerApn: journal.ownerApn }
    })
  ).catch(err => console.log(err));
};

export const removeLegacyJournal = async journal => {
  await API.graphql(
    graphqlOperation(mutations.deleteJournal, {
      input: { id: journal.id, ownerApn: journal.ownerApn }
    })
  ).catch(err => console.log(err));
};

const postFetchProcessJournal = journal => getJournalStruct(journal);

export const prepAndPruneJournalForAppSync = journal => {
  // Prune any keys that are not in the schema
  const journalKeys = Object.keys(getJournalStruct());
  const pruned = getJournalStruct(journal);
  const prunedKeys = Object.keys(pruned);
  const propertiesToPrune = prunedKeys.filter(
    key => !journalKeys.includes(key)
  );
  if (propertiesToPrune.length)
    propertiesToPrune.map(key => delete pruned[key]);

  // Prep any empty strings
  journalKeys.map(key => {
    if (typeof pruned[key] === 'string' && pruned[key] === '') {
      pruned[key] = ' ';
    }
    return true;
  });
  delete pruned.apn;
  return pruned;
};
