import moment from 'moment';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ScheduleIcon from '@material-ui/icons/Schedule';
import HoverButton from '../../Ui/HoverButton';
import { convertPickerDateToDate, formatDateForPicker } from '../../../utils/date';
import {getReviewCycleStruct} from '../../../structs/reviewCycle';
import {debounce} from '@material-ui/core';
import {getReviewDueDate} from "../../../models/tracker";

const useStyles = makeStyles({
  reviewCycleButton: {
    minWidth: '100%'
  },
  reviewFormElement: {
    marginBottom: '1em'
  },
  dialog: {
    minWidth: '50%',
    maxWidth: '62%'
  }
});

const Manage = ({ reviewCycle: cycleProp, onChange }) => {
  const classes = useStyles();
  const [reviewCycle, setReviewCycle] = useState(getReviewCycleStruct());
  const [open, setOpen] = useState(false);
  const [persist, setPersist] = useState(false);
  const [{ weekDays, monthDays }, setDays] = useState({ weekDays:[], monthDays:[] });

  const [ reviewDate, setReviewDate ] = useState(moment());
  const pluralize = reviewCycle.interval > 1;
  const formattedStartDate = formatDateForPicker(
    reviewCycle.startReviewDate === ' ' ? '' : reviewCycle.startReviewDate
  );

  useEffect(() => {
    const weekStart = new Date('2019/01/05');
    const monthStart = new Date('2018/12/31');
  
    const weekDays = range(0, 7).map(() => {
      weekStart.setDate(weekStart.getDate() + 1);
      return new Date(weekStart.toString());
    });
    const monthDays = range(0, 31).map(() => {
      monthStart.setDate(monthStart.getDate() + 1);
      return new Date(monthStart.toString());
    });
    
    setDays({ weekDays, monthDays });
  }, []);
  
  useEffect(() => {
    if (cycleProp.updatedAt !== reviewCycle.updatedAt) {
      const wetReviewCycle = getReviewCycleStruct(cycleProp);
      if (wetReviewCycle.startReviewDate === '') {
        wetReviewCycle.startReviewDate = moment().format();
      }
      setReviewCycle(wetReviewCycle);
      setReviewDate(getReviewDueDate(wetReviewCycle).startOf("day"));
    }
  
  },[cycleProp, cycleProp.updatedAt, reviewCycle.updatedAt]);

  const debounceSave = debounce(() => {
    if (persist) {
      onChange(reviewCycle);
      setPersist(false);
    }
  });
  
  useEffect(() => {
    debounceSave();
  }, [debounceSave, persist]);

  const handleChange = event => {
    const changeReviewCycle = {
      ...reviewCycle,
      lastReviewedDate: moment().format(),
      [event.target.name]: event.target.value
    };
    if (event.target.name === 'frequency') {
      changeReviewCycle.positions = [];
    }
    if (event.target.name === 'startReviewDate') {
      changeReviewCycle.startReviewDate = convertPickerDateToDate(
        event.target.value
      ).toString();
    }
    
    setReviewCycle(changeReviewCycle);
    setPersist(true);
  };

  const handlePositionChangeClick = (value, checked) => () => {
    const { positions } = reviewCycle;
    if (checked) {
      positions.push(String(value));
    } else {
      // or remove the value from the unchecked checkbox from the array
      const index = positions.indexOf(String(value));
      positions.splice(index, 1);
    }
    setReviewCycle({
      ...reviewCycle,
      positions
    });
    setPersist(true);
  };
  
  const handleOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  const frequencyLabels = {
    daily: `Repeats every ${pluralize ? reviewCycle.interval : ''} day${
      pluralize ? 's' : ''
    }`,
    weekly: `Repeats every ${pluralize ? reviewCycle.interval : ''} week${
      pluralize ? 's' : ''
    }`,
    monthly: `Repeats every ${pluralize ? reviewCycle.interval : ''} month${
      pluralize ? 's' : ''
    }`
  };
  
  return (
    <React.Fragment>

      <HoverButton
        className={classes.reviewCycleButton}
        size="small"
        variant="text"
        onClick={handleOpen}
        startIcon={<ScheduleIcon/>}
        hoverContent="Customize"
      >
        {frequencyLabels[reviewCycle.frequency]}
      </HoverButton>

      <Dialog
        open={open}
        PaperProps={{
          className: classes.dialog
        }}
      >
        <DialogContent>
          <Typography variant="caption">
            When would you like to start reviewing this?
          </Typography>
          <TextField
            className={classes.reviewFormElement}
            id="startReview"
            name="startReviewDate"
            value={formattedStartDate}
            type="date"
            fullWidth
            onChange={handleChange}
          />
          <Typography variant="caption">
            Choose the frequency of review
          </Typography>
          <Select
            className={classes.reviewFormElement}
            value={reviewCycle.frequency}
            onChange={handleChange}
            name="reviewCycle"
            inputProps={{
              name: 'frequency',
              id: 'review-cycle-frequency'
            }}
          >
            <MenuItem value="daily">Every Day</MenuItem>
            <MenuItem value="weekly">Every Week</MenuItem>
            <MenuItem value="monthly">Every Month</MenuItem>
          </Select>
          <Typography variant="caption">
            Repeat Interval ({frequencyLabels[reviewCycle.frequency]})
          </Typography>
          <TextField
            name="interval"
            fullWidth
            className={classes.reviewFormElement}
            onChange={handleChange}
            value={reviewCycle.interval}
          />
          {reviewCycle.frequency === 'weekly' ? (
            <div>
              <Typography variant="caption">
                Which days each week will this be reviewed?
              </Typography>
              {weekDays.map(weekday => {
                const checked = reviewCycle.positions.includes(
                  String(weekday.getDay())
                );
                return (
                  <Button
                    size="small"
                    key={`position_${weekday.getDay()}`}
                    variant={checked ? 'contained' : 'outlined'}
                    onClick={handlePositionChangeClick(
                      weekday.getDay(),
                      !checked
                    )}
                  >
                    {Intl.DateTimeFormat('en-US', {
                      weekday: 'long'
                    }).format(weekday)}
                  </Button>
                );
              })}
            </div>
          ) : null}
          {reviewCycle.frequency === 'monthly' ? (
            <div>
              <Typography variant="caption">
                Choose day of the month
              </Typography>
              {monthDays.map(day => {
                const dayDate = Intl.DateTimeFormat('en-US', {
                  day: 'numeric'
                }).format(day);
                const checked = reviewCycle.positions.includes(
                  String(dayDate)
                );
                return (
                  <Button
                    size="small"
                    key={`position_${dayDate}`}
                    variant={checked ? 'contained' : 'outlined'}
                    onClick={handlePositionChangeClick(
                      dayDate,
                      !checked
                    )}
                  >
                    {dayDate}
                  </Button>
                );
              })}
            </div>
          ) : null}
        </DialogContent>
        <DialogActions>
          <div style={{ marginRight: 'auto' }}>
            {`Next Review: ${reviewDate.format('dddd, MMMM Do YYYY')}`}
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

Manage.defaultProps = {};

Manage.propTypes = {
  reviewCycle: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Manage;
