import React from 'react';
import PropTypes from 'prop-types';
import {TRACKER_ACTION_ARCHIVE, TRACKER_STATUS_ACTIVE} from '../../../structs/trackers';
import {MenuItem} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {Archive} from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";

const ArchiveMenuItem = ({
  tracker,
  onDialog
}) => {

  const handleDialog = () => {
    onDialog(TRACKER_ACTION_ARCHIVE);
  };

  return tracker.status === TRACKER_STATUS_ACTIVE ? (
    <MenuItem
      variant="text"
      color="secondary"
      onClick={handleDialog}
    >
      <ListItemIcon>
        <Archive fontSize={"small"}/>
      </ListItemIcon>
      <ListItemText>Archive</ListItemText>
    </MenuItem>
  ) : null;
};

ArchiveMenuItem.defaultProps = {};

ArchiveMenuItem.propTypes = {
  tracker: PropTypes.object.isRequired,
  onDialog: PropTypes.func.isRequired
};

export default ArchiveMenuItem;
