import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setActiveRoleAction} from "../../redux/actions/roles";
import {ROLE_NO_ROLE_NAME} from "../../structs/roles";
import Chip from "@material-ui/core/Chip";
import PropType from "prop-types";

const RolePills = ({ roles }) => {
  const dispatch = useDispatch();
  const { all, active } = useSelector(store => store.roles);
  const pills = Object.entries(roles).filter(([,ids]) => ids.length);

  const roleByName = (name) => all.find(role => role.name === name || (role.name === ROLE_NO_ROLE_NAME && name === ''));

  return (
    <div style={{display: 'flex', justifyContent: pills.length < 4 ? 'center' : 'start', overflowY: 'scroll'}}>
      {pills.map(([name, ids]) => {
        const role = roleByName(name);
        if (!role) return null
        return (
          <Chip
            key={`${name || ROLE_NO_ROLE_NAME} - ${ids.length}`}
            label={`${name || ROLE_NO_ROLE_NAME} - ${ids.length}`}
            size={"small"}
            style={{
              marginLeft: 5,
              backgroundColor: active.name === name ? role.color : null,
              borderColor: role.color
            }}
            variant={active.name === name ? "contained" : "outlined"}
            onClick={() => dispatch(setActiveRoleAction(role))}
          />
        )
      })}
    </div>
  );
};

RolePills.defaultProps = {};

RolePills.propTypes = {
  roles: PropType.object.isRequired
};

export default RolePills;
