import moment from 'moment';
import {v4 as uuidv4} from "uuid";
import { getLearnItemStruct } from './learn';
import { getMetricStruct, getMetricValueStruct } from './measure';
import { stringifyApn as stringifyApnV2 } from '../utils/apn/v2';
import {getTrackerShareStruct} from './trackerShare';
import {getReviewCycleStruct, ReviewCycleStruct} from './reviewCycle';
import {getRoleStruct, RoleStruct} from "./roles";

export const TRACKER_STATUS_ACTIVE = 'active';
export const TRACKER_STATUS_ARCHIVED = 'archived';

export const TRACKER_ACTION_COPY = 'copy';
export const TRACKER_ACTION_SHARE = 'share';
export const TRACKER_ACTION_ARCHIVE = 'archive';
export const TRACKER_ACTION_TEMPLATE = 'template';
export const TRACKER_ACTION_DELETE = 'delete';

export const MilestoneStruct = {
  schemaVersion: '1.3.0',
  id: '',
  trackerApn: '',
  trackerId: '',
  description: '',
  notes: '',
  reached: false,
  status: 'active',
  seq: 0,
  actionItems: [],
  planApn: '',
  targetDate: null,
  createdAt: '',
  updatedAt: ''
};

export const ActionItemStruct = {
  schemaVersion: '1.0.0',
  apnPart: '',
  seq: 0,
  action: '',
  checked: false
};

export const ActiveMilestoneStruct = {
  milestone: MilestoneStruct,
  requestedId: ''
};

export const SuccessStruct = {
  schemaVersion: '1.0.0',
  apnPart: '',
  seq: 0,
  item: ' ',
  checked: false
};

export const TrackerStruct = {
  schemaVersion: '1.8.0',
  id: '',
  ownerApn: '',
  dream: '',
  learn: [],
  success: [],
  metrics: [],
  milestones: [],
  notes: '',
  reviewCycle: ReviewCycleStruct,
  status: TRACKER_STATUS_ACTIVE, // active, archived
  createdAt: '',
  updatedAt: '',
  plans: [],
  role: RoleStruct
};

export const PlanStruct = {
  apn: '',
  title: 'Plan',
  seq: 1,
  milestoneIds: [],
  open: true
};

export const ActiveTrackerStruct = {
  tracker: TrackerStruct,
  requestedId: ' '
};

export const TrackerDocumentStruct = {
  entity: {},
  reviewCycle: {},
  reviewDate: ' ',
  activeCommitments: [],
  share: {},
  role: {},
  shares: []
};

export const getTrackerStruct = (tracker = TrackerStruct) => {
  const { milestones, reviewCycle, plans, role } = tracker;
  return Object.assign({}, TrackerStruct, tracker, {
    plans: plans && plans.length ? plans.map(plan => getPlanStruct(plan)) : [getPlanStruct({apn: uuidv4()})],
    milestones: milestones && milestones.length
            ? milestones.map(milestone => getMilestoneStruct(milestone))
            : [],
    reviewCycle: getReviewCycleStruct(reviewCycle),
    role: getRoleStruct(role),
    learn:
      typeof tracker.learn === 'object' && tracker.learn.length
        ? tracker.learn.map(item => getLearnItemStruct(item))
        : [],
    success:
      typeof tracker.success === 'object' && tracker.success.length
        ? tracker.success.map(item => getSuccessStruct(item))
        : [],
    metrics:
      typeof tracker.metrics === 'object' && tracker.metrics.length
        ? tracker.metrics.map(item => getMetricStruct(item))
        : []
  });
};

export const getTrackerDocumentStruct = (
  trackerDoc = TrackerDocumentStruct
) => {
  const { entity } = trackerDoc;
  return Object.assign({}, TrackerDocumentStruct, trackerDoc, {
    entity: getTrackerStruct(entity),
    reviewCycle: getReviewCycleStruct(trackerDoc.reviewCycle),
    activeCommitments: [...(trackerDoc.activeCommitments || [])],
    share: getTrackerShareStruct(trackerDoc.share),
    role: getRoleStruct(trackerDoc.role)
  });
};

export const getPlanStruct = (plan = PlanStruct) => Object.assign({}, {...PlanStruct}, {
  ...plan,
  apn: plan.apn || uuidv4(),
  milestoneIds: [...(plan.milestoneIds || [])]
});

export const getSuccessStruct = (item = SuccessStruct) =>
  Object.assign({}, SuccessStruct, {
    ...item,
    apnPart: item.apnPart || uuidv4()
  });

export const getActionItemStruct = (item = ActionItemStruct) =>
  Object.assign({}, ActionItemStruct, {
    ...item,
    apnPart: item.apnPart || uuidv4()
  });

export const getMilestoneStruct = (milestone = MilestoneStruct) => {
  const { actionItems } = milestone;
  return Object.assign({}, MilestoneStruct, milestone, {
    actionItems: actionItems && actionItems.length
        ? actionItems.map(item => getActionItemStruct(item))
        : [],
  });
};

export const requiresMigration = (entity, struct = TrackerStruct) => {
  const { schemaVersion } = struct;
  return entity.schemaVersion !== schemaVersion;
};

export const evaluateTrackers = (trackers) => {
  const valid = [];
  const migrate = [];
  
  trackers.map(tracker => {
    if (requiresMigration(tracker)) {
      migrate.push(tracker);
    } else {
      valid.push(tracker);
    }
    
    return tracker;
  });
  
  return [valid, migrate];
};

export const migrateTrackers = (trackers, user) => {
  const { schemaVersion } = TrackerStruct;
  const valid = [];
  const migrated = [];

  trackers.map(tracker => {
    if (tracker.schemaVersion === schemaVersion) {
      valid.push(tracker);
    } else {
      migrated.push(migrateTracker(tracker, user));
    }
    
    return tracker;
  });
  
  return [valid, migrated];
};

export const migrateTracker = (tracker, user, toVersion = TrackerStruct.schemaVersion) => {
  const versions = [
    'undefined',
    '1.0.0',
    '1.1.0',
    '1.2.0',
    '1.3.0',
    '1.4.0',
    '1.5.0',
    '1.6.0',
    '1.7.0',
    '1.8.0'
  ];

  const newTracker = getTrackerStruct(tracker);

  if (!tracker.schemaVersion) newTracker.schemaVersion = 'undefined';

  while (newTracker.schemaVersion !== toVersion) {
    const whereAreWeNow = versions.indexOf(newTracker.schemaVersion);
    const whereAreWeGoing = versions.indexOf(toVersion);

    let targetVersion = toVersion;

    if (whereAreWeGoing - whereAreWeNow > 1)
      targetVersion = versions[whereAreWeNow + 1];

    // Version transitions
    if (`${newTracker.schemaVersion}_${targetVersion}` === 'undefined_1.0.0') {
      // We don't need to do anything for this version
    } else if (
      `${newTracker.schemaVersion}_${targetVersion}` === '1.0.0_1.1.0'
    ) {
      let sequence = 0;
      newTracker.success =
        typeof tracker.success === 'string'
          ? tracker.success.split('\n').map(item => {
              const newItem = { item };
              newItem.seq = sequence;
              sequence += 1;
              return getSuccessStruct(newItem);
            })
          : [];
    } else if (
      `${newTracker.schemaVersion}_${targetVersion}` === '1.1.0_1.2.0'
    ) {
      // 1.2.0 added milestone sequence, so we need to populate starting sequence
      let sequence = 1;
      newTracker.plan.milestones = tracker.plan.milestones.map(milestone => {
        const newMilestone = getMilestoneStruct(milestone);
        newMilestone.seq = sequence;
        newMilestone.schemaVersion = MilestoneStruct.schemaVersion;
        sequence += 1;
        return newMilestone;
      });
    } else if (
      `${newTracker.schemaVersion}_${targetVersion}` === '1.2.0_1.3.0'
    ) {
      // 1.3.0 added tracker status, no additional migration needed
    } else if (
      `${newTracker.schemaVersion}_${targetVersion}` === '1.3.0_1.4.0'
    ) {
      let frequency = 'daily';
      if (tracker.reviewCycle === '7') frequency = 'weekly';
      else if (tracker.reviewCycle === '30') frequency = 'monthly';
      newTracker.reviewCycle = getReviewCycleStruct({
        frequency,
        lastReviewedDate: tracker.lastReviewedDate || tracker.updatedAt,
        startReviewDate: tracker.lastReviewedDate || tracker.updatedAt,
        lastUpdatedAt: tracker.updatedAt
      });
      delete newTracker.lastReviewedDate;
    } else if (
      `${newTracker.schemaVersion}_${targetVersion}` === '1.4.0_1.5.0'
    ) {
      if (tracker.learn)
        newTracker.learn.push(
          getLearnItemStruct({
            type: 'note',
            seq: 1,
            item: {
              content: tracker.learn
            }
          })
        );
    } else if (
      `${newTracker.schemaVersion}_${targetVersion}` === '1.5.0_1.6.0'
    ) {
      // Nothing to do here, the measurement/metrics section is new
    } else if (
      `${newTracker.schemaVersion}_${targetVersion}` === '1.6.0_1.7.0'
    ) {
      // Upgrade APN and timestamps for AppSync Data Service
      newTracker.ownerApn = stringifyApnV2({ userId: user.id });

      delete newTracker.apn;
      delete newTracker.tracker;

      if (newTracker.notes === '') newTracker.notes = ' ';

      newTracker.createdAt = moment(newTracker.createdAt).format();
      newTracker.updatedAt = moment(newTracker.updatedAt).format();

      newTracker.reviewCycle.interval = parseInt(
        newTracker.reviewCycle.interval,
        10
      );
      newTracker.reviewCycle.startReviewDate = moment(
        newTracker.reviewCycle.startReviewDate
      ).format();
      newTracker.reviewCycle.lastReviewedDate = moment(
        newTracker.reviewCycle.lastReviewedDate
      ).format();
      newTracker.reviewCycle.updatedAt = moment(
        newTracker.reviewCycle.updatedAt
      ).format();
      delete newTracker.reviewCycle.lastUpdatedAt;

      newTracker.milestones = tracker.plan.milestones.map(milestone => {
        const newMilestone = getMilestoneStruct(milestone);
        newMilestone.schemaVersion = MilestoneStruct.schemaVersion;
        newMilestone.trackerApn = stringifyApnV2({
          userId: user.id,
          trackerId: tracker.id
        });
        newMilestone.trackerId = tracker.id;
        delete newMilestone.apn;

        newMilestone.createdAt = moment(newMilestone.createdAt).format();
        newMilestone.updatedAt = moment(newMilestone.updatedAt).format();

        if (newMilestone.notes === '') newMilestone.notes = ' ';

        delete newMilestone.work;

        return newMilestone;
      });

      delete newTracker.plan;

      newTracker.metrics = tracker.metrics.map(metric => {
        const newMetric = getMetricStruct(metric);

        newMetric.item.values = metric.item.values.map(value => {
          const newMetricValue = getMetricValueStruct(value);
          if (newMetricValue.datetime)
            newMetricValue.datetime = moment(value.datetime).format();

          delete newMetricValue.metric;

          return newMetricValue;
        });

        return newMetric;
      });

      newTracker.learn = tracker.learn.map(item => {
        const newLearnItem = getLearnItemStruct(item);

        if (newLearnItem.item.title === '') newLearnItem.item.title = ' ';
        if (newLearnItem.item.content === '') newLearnItem.item.content = ' ';
        if (newLearnItem.item.text === '') newLearnItem.item.text = ' ';
        if (newLearnItem.item.question === '') newLearnItem.item.question = ' ';
        if (newLearnItem.item.answer === '') newLearnItem.item.answer = ' ';
        if (newLearnItem.item.url === '') newLearnItem.item.url = ' ';

        return newLearnItem;
      });
    } else if (
      `${newTracker.schemaVersion}_${targetVersion}` === '1.7.0_1.8.0'
    ) {
      // Success Get an ApnPart
      newTracker.success = tracker.success.map(item => ({...item, apnPart: uuidv4()}));
      
      // Milestones are added to Plan
      const { plans, milestones } = tracker;
  
      newTracker.plans = plans.map(plan => {
        plan.milestoneIds = [];
        milestones.map(m => {
          const milestone = getMilestoneStruct(m);
          if (milestone.planApn && milestone.planApn === plan.apn){
            if (plan.milestoneIds.indexOf(milestone.id) === -1) {
              plan.milestoneIds.push(milestone.id);
            }
          } else if (!milestone.planApn && plan.seq === 1) {
            if (plan.milestoneIds.indexOf(milestone.id) === -1) {
              plan.milestoneIds.push(milestone.id);
            }
          }
          return milestone;
        });
    
        return plan;
      });
  
    }

    // Version Up the Tracker Schema
    newTracker.schemaVersion = targetVersion;
  }

  return getTrackerStruct(newTracker);
};

export const migrateMilestone = (milestone, tracker, toVersion = MilestoneStruct.schemaVersion) => {
  const versions = [
    '1.2.0',
    '1.3.0'
  ];
  
  const newMilestone = getMilestoneStruct(milestone);
  
  while (newMilestone.schemaVersion !== toVersion) {
    const whereAreWeNow = versions.indexOf(newMilestone.schemaVersion);
    const whereAreWeGoing = versions.indexOf(toVersion);
    
    let targetVersion = toVersion;
    
    if (whereAreWeGoing - whereAreWeNow > 1)
      targetVersion = versions[whereAreWeNow + 1];
    
    // Version transitions
    if (
      `${newMilestone.schemaVersion}_${targetVersion}` === '1.2.0_1.3.0'
    ) {
      const { plans } = tracker;
  
      const planWithMilestone = plans.find(plan => {
        return plan.milestoneIds.indexOf(milestone.id) !== -1;
      });
      newMilestone.planApn = planWithMilestone.apn;
    }
    
    // Version Up the Tracker Schema
    newMilestone.schemaVersion = targetVersion;
  }
  
  return getMilestoneStruct(newMilestone);
};
