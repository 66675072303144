import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  copyTrackerAction,
  deleteTrackerAction,
  saveTrackerAction
} from '../redux/actions/trackers';
import {
  requestActiveTrackerAction,
  resetActiveTrackerAction
} from '../redux/actions/activeTracker';
import Tracker from '../components/Tracker/Tracker';
import {saveTrackerShareAction} from "../redux/actions/trackerShares";

export class TrackerPageContainer extends Component {
  render() {
    return <Tracker />;
  }
}

const mapStateToProps = state => ({
  user: state.user,
  activeTrackerDoc: state.activeTracker,
  trackerIndex: state.trackerIndex
});

const mapDispatchToProps = dispatch => ({
  requestActiveTracker: trackerId => {
    dispatch(requestActiveTrackerAction(trackerId));
  },
  resetActiveTracker: tracker => {
    dispatch(resetActiveTrackerAction(tracker));
  },
  saveTracker: tracker => {
    dispatch(saveTrackerAction(tracker));
  },
  saveTrackerShare: share => {
    dispatch(saveTrackerShareAction(share));
  },
  deleteTracker: async tracker => {
    dispatch(deleteTrackerAction(tracker));
  },
  copyTracker: async tracker => {
    dispatch(copyTrackerAction(tracker));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Tracker);
