import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import MetricNumberValues from './Number/Values';
import { getMetricNumberStruct } from '../../../structs/measure';

export const style = () => ({
  input: {
    color: '#393939',
    flex: 1,
    width: '100%'
  },
  textField: {
    fontSize: 12,
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    width: '100%'
  },
  headerContent: {
    fontSize: '1rem'
  },
  headerRoot: {
    padding: '1rem',
    paddingBottom: 0
  }
});

class MeasureMetricNumber extends Component {
  state = {
    metric: getMetricNumberStruct(),
    edit: false
  };

  componentDidMount() {
    const { item } = this.props;
    const { metric } = this.state;

    if (!item.name) {
      this.deferredCommands.push(() => {
        this.setState({ edit: true });
      });
    }

    if (JSON.stringify(item) !== JSON.stringify(metric)) {
      this.hydrateState(item);
    }
  }

  componentDidUpdate() {
    this.checkDeferredCommands();
  }

  // List of commands to run after render.
  deferredCommands = [];

  /*
   *
   * The Deferred Commands model is to allow us to run functions once the
   * state has been set.
   * This is useful when we want to persist data via a redux action, or run
   * multiple steps to record progress.
   *
   */
  checkDeferredCommands = () => {
    if (!this.deferredCommands.length) return;

    this.deferredCommands = this.deferredCommands.filter(command => {
      command();
      return false;
    });
  };

  hydrateState = item => {
    this.setState({ metric: getMetricNumberStruct(item) });
  };

  handleSubmit = () => {
    const { metric } = this.state;
    const { onChange } = this.props;
    this.deferredCommands.push(() => {
      onChange(metric);
    });
    this.handleCancel();
  };

  handleDelete = () => {
    const { metric } = this.state;
    const { onDelete } = this.props;
    onDelete(metric);
  };

  handleChange = event => {
    const { metric } = this.state;
    metric[event.target.name] = event.target.value;
    this.setState({ metric });
  };

  handleValueChange = values => {
    const { metric } = this.state;
    const { onChange } = this.props;
    metric.values = [...values];
    this.setState(metric);
    onChange(metric);
  };

  handleClick = () => {
    this.setState({ edit: true });
  };

  handleCancel = () => {
    this.setState({ edit: false });
  };

  render() {
    const { classes, item, readOnly } = this.props;
    const { metric, edit } = this.state;

    return (
      <React.Fragment>
        <ListItem>
          <Card
            elevation={0}
            style={{ borderLeft: '3px solid lightgray', marginBottom: 4 }}
          >
            <CardHeader
              classes={{ root: classes.headerRoot }}
              titleTypographyProps={{
                classes: { h5: classes.headerContent }
              }}
              title={item.name}
            />
            <CardContent style={{ fontSize: '.8rem', whiteSpace: 'pre-wrap' }}>
              <MetricNumberValues
                values={metric.values}
                readOnly={readOnly}
                onChange={this.handleValueChange}
              />
            </CardContent>
            <CardActions>
              {!readOnly && (<Button onClick={this.handleClick}>Edit</Button>)}
            </CardActions>
          </Card>
        </ListItem>
        <Dialog open={edit}>
          <DialogContent>
            <TextField
              id="name"
              name="name"
              value={metric.name === ' ' ? '' : metric.name}
              placeholder="Metric Name"
              margin="normal"
              className={classes.textField}
              InputProps={{
                className: classes.input
              }}
              onChange={this.handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              style={{ marginRight: 'auto' }}
              color="secondary"
              onClick={this.handleDelete}
            >
              Delete
            </Button>
            <Button color="secondary" onClick={this.handleCancel}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

MeasureMetricNumber.propTypes = {
  classes: PropTypes.any.isRequired,
  item: PropTypes.any.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default withStyles(style)(MeasureMetricNumber);
