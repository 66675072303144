import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import UserIcon from "@material-ui/icons/AccountCircle";
import {acceptTrackerShareAction, declineTrackerShareAction} from "../../redux/actions/trackerShares";

const RespondDialog = ({ trackerShare, onReset }) => {
  const dispatch = useDispatch();
  const [decline, setDecline] = useState(false);

  const handleDecline = () => {
    setDecline(true);
  };

  const handleDeclineConfirm = () => {
    dispatch(declineTrackerShareAction(trackerShare));
    onReset();
  };

  const handleAccept = () => {
    dispatch(acceptTrackerShareAction(trackerShare));
    onReset();
  };

  return (
    <Dialog open={!!trackerShare.id} fullWidth>
      <DialogTitle>
        Respond to Invite
        <IconButton
          style={{ position: 'absolute', right: 0, top: 0 }}
          onClick={onReset}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>{<Chip avatar={<UserIcon fontSize={"small"} />} variant={"outlined"} label={trackerShare.ownerName}/>} has asked you to help with {trackerShare.shareDescription}.</Typography>

        <hr/>

        {decline ? (
          <>
            <Typography style={{ padding: '1em' }}>
              Are you sure that you want to decline the invitation to share?
            </Typography>
          </>
        ) : (
          <>
            <Typography style={{ padding: '1em' }}>
              Would like you like to accept the invitation to share a Tracker?
            </Typography>
            <Typography variant={"caption"}>
              By accepting you will be given access to shared information.
            </Typography>
            <br/>
            <Typography variant={"caption"}>
              They will be added to your contacts and you will be added to theirs.
            </Typography>
          </>
        )}

      </DialogContent>
      <DialogActions>
        {decline ? (
          <>
            <Button
              onClick={() => setDecline(false)}
              variant={"outlined"}
              style={{ marginRight: 'auto' }}
            >
              Cancel
            </Button>
            <Button
              color={"primary"}
              variant={"contained"}
              onClick={handleDeclineConfirm}
            >
              Confirm
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={handleDecline}
              variant={"outlined"}
              style={{ marginRight: 'auto' }}
            >
              Decline
            </Button>
            <Button
              color={"primary"}
              variant={"contained"}
              onClick={handleAccept}
            >
              Accept
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

RespondDialog.defaultProps = {};

RespondDialog.propTypes = {
  trackerShare: PropTypes.object.isRequired
};

export default RespondDialog;
