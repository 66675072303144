import React, { Component } from 'react';
import {v4 as uuidv4} from "uuid";
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Style from '../Style';
import { getSuccessStruct, getTrackerStruct } from '../../../structs/trackers';
import SuccessItem from './Item';

const reorderSuccessSeq = items => {
  let seq = 1;
  return items.map(item => {
    const success = getSuccessStruct(item);
    success.seq = seq;
    seq += 1;
    return success;
  });
};

const reorderSuccess = (success, startIndex, endIndex) => {
  const result = Array.from(success);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class SuccessList extends Component {
  state = { success: [], newItem: getSuccessStruct(), selected: '' };

  componentDidMount() {
    const { tracker } = this.props;
    const { success } = this.state;

    if (tracker.success !== success) {
      this.hydrateState(tracker.success);
    }
  }

  componentDidUpdate() {
    const { tracker } = this.props;
    const { success, selected, newItem } = this.state;

    if (tracker.success !== success) {
      this.hydrateState(tracker.success);
    }

    if (
      selected === newItem.seq &&
      document.activeElement !== this.newItemField
    ) {
      this.newItemField.focus();
    }
  }

  newItemField;

  hydrateState = success => {
    const { newItem } = this.state;
    newItem.seq = this.getNewItemSequence(success);
    this.setState({ success, newItem });
  };

  handleNewChange = event => {
    const { newItem } = this.state;
    if (event.target.name === 'checked') {
      newItem[event.target.name] = event.target.checked;
    } else {
      if (event.target.value.length === 1 && !event.target.value.trim()) {
        return;
      }
      newItem[event.target.name] = event.target.value;
    }

    this.setState({ newItem });
  };

  handleItemChange = itemToChange => {
    const { success } = this.state;
    const { onChange } = this.props;
    const newSuccess = success.map(item => {
      if (item.seq === itemToChange.seq) {
        return itemToChange;
      }
      return item;
    });
    this.setState({
      success: newSuccess
    });
    // Persist to Tracker onChange
    onChange(newSuccess);
  };

  handleRemove = itemToRemove => {
    const { success } = this.state;
    const { onChange } = this.props;
    let newSuccess = success.filter(item => item.seq !== itemToRemove.seq);
    newSuccess = reorderSuccessSeq(newSuccess);
    this.setState({
      success: newSuccess
    });
    // Persist to Tracker
    onChange(newSuccess);
  };

  handleAdd = event => {
    if (event.key !== 'Enter') return;

    event.target.blur();
  };

  handleAddBlur = event => {
    const { success, newItem } = this.state;
    const { onChange } = this.props;

    if (!newItem[event.target.name].trim()) {
      this.setState({ selected: '' });
      return;
    }

    success.push(getSuccessStruct({
      ...newItem,
      apnPart: uuidv4()
    }));
    const cleanItem = getSuccessStruct({
      seq: this.getNewItemSequence(success)
    });
    this.setState({
      success,
      newItem: cleanItem,
      selected: cleanItem.seq
    });

    // Persist to Tracker
    onChange(success);
  };

  getNewItemSequence = success => {
    let focusSeq = 1;
    if (success.length)
      success.map(item => {
        focusSeq += 1;
        return item;
      });
    return focusSeq;
  };

  handleAddFocus = () => {
    const { success, newItem } = this.state;
    const selected = this.getNewItemSequence(success);
    newItem.seq = selected;
    this.setState({ selected, newItem });
  };

  handleAddKeydown = event => {
    const { newItem } = this.state;

    // Support Up Arrow Navigation
    if (event.keyCode === 38) {
      this.setState({ selected: newItem.seq - 1 });
    }
  };

  handleItemFocus = id => {
    this.setState({ selected: id });
  };

  handleItemBlur = id => {
    const { selected } = this.state;
    if (selected === id) this.setState({ selected: '' });
  };

  handleListKeys = event => {
    const { selected, success } = this.state;

    // Handle Up Arrow
    if (event.keyCode === 38) {
      if (selected === 1) return;
      this.setState({ selected: selected - 1 });
    }

    // Handle Down Arrow
    if (event.keyCode === 40) {
      if (selected < success.length + 1)
        this.setState({ selected: selected + 1 });
    }
  };

  onDragEnd = result => {
    const { success } = this.state;
    const { onChange } = this.props;

    // dropped outside the list
    if (!result.destination) return;

    let newSuccess = reorderSuccess(
      success,
      result.source.index,
      result.destination.index
    );

    newSuccess = reorderSuccessSeq(newSuccess);

    this.setState({
      success: newSuccess
    });
    // Persist to Tracker
    onChange(newSuccess);
  };

  render() {
    const { success, newItem, selected } = this.state;
    const { classes, editable } = this.props;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <List onKeyDown={this.handleListKeys}>
          <Droppable droppableId="droppable">
            {provided => (
              <div ref={provided.innerRef}>
                {success.length
                  ? success.map((item, index) => (
                      <Draggable
                        draggableId={String(item.seq)}
                        key={item.seq}
                        index={index}
                        isDragDisabled={!editable}
                      >
                        {innerProvided => (
                          <div
                            ref={innerProvided.innerRef}
                            {...innerProvided.draggableProps}
                            {...innerProvided.dragHandleProps}
                          >
                            <SuccessItem
                              key={item.seq}
                              item={item}
                              onRemove={this.handleRemove}
                              onChange={this.handleItemChange}
                              onInputFocus={this.handleItemFocus}
                              onInputBlur={this.handleItemBlur}
                              selectedItem={selected}
                              editable={editable}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))
                  : null}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {editable && (<ListItem
            key="newSuccessItem"
            selected={selected === newItem.seq}
            className={classes.listPadding}
          >
            <Checkbox
              checked={false}
              tabIndex={-1}
              disableRipple
              disabled
              style={{ marginLeft: 4 }}
              icon={
                <CheckBoxOutlineBlankIcon
                  style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                />
              }
            />
            <TextField
              key={newItem.seq}
              name="item"
              style={{ width: '90%' }}
              placeholder="Enter a success statement"
              onChange={this.handleNewChange}
              onKeyPress={this.handleAdd}
              onBlur={this.handleAddBlur}
              onFocus={this.handleAddFocus}
              onKeyDown={this.handleAddKeydown}
              multiline
              disabled={!editable}
              value={newItem.item === ' ' ? '' : newItem.item}
              InputProps={{
                disableUnderline: true,
                style: {
                  padding: '3px 0 0'
                }
              }}
              inputRef={field => {
                this.newItemField = field;
              }}
            />
          </ListItem>)}
        </List>
      </DragDropContext>
    );
  }
}

SuccessList.defaultProps = {
  tracker: getTrackerStruct(),
  editable: true
};

SuccessList.propTypes = {
  tracker: PropTypes.any,
  editable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(Style)(SuccessList);
