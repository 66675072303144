import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import {prepAndPruneForAppSync} from "../appSync";
import {getTrackerTemplateStruct} from "../../structs/trackerTemplate";

export const fetchRemoteTrackerTemplates = async ownerApn => {
  const { data: { listTrackerTemplates: { items }}} = await API.graphql(
    graphqlOperation(queries.listTrackerTemplates, {
      ownerApn,
      limit: 100,
    })
  );

  return items.length ? items.map(item => getTrackerTemplateStruct(item)) : [];
}

export const createRemoteTrackerTemplate = async template => {
  const input = prepAndPruneForAppSync(template, getTrackerTemplateStruct);
  delete input.id;
  const { data: { createTrackerTemplate: item }} = await API.graphql(
    graphqlOperation(mutations.createTrackerTemplate, {
      input
    })
  );

  return getTrackerTemplateStruct(item);
};

export const deleteRemoteTrackerTemplate = async (ownerApn, id) => {
  await API.graphql(
    graphqlOperation(mutations.deleteTrackerTemplate, {
      input: {
        id,
        ownerApn
      }
    })
  );
};
