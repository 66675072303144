import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import LensIcon from '@material-ui/icons/Lens';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import colors from '../../constants/highlightColors.json';

export const style = () => ({
  colorIconButton: {
    padding: 0,
    marginLeft: -6
  }
});

class ColorSelectorHelper extends Component {
  state = {
    value: this.props.color,
    open: false
  };

  componentDidMount() {
    this.populateValue();
  }

  componentDidUpdate() {
    this.populateValue();
  }

  componentWillUnmount() {
    this.clearTimeouts();
  }

  anchorEl;

  cachedColorProp;

  hoverTimeout;

  blurTimeout;

  populateValue = () => {
    const { value } = this.state;
    let { color } = this.props;

    if (color === null || color === '') {
      [color] = colors;
    }

    if (value === color) return;

    if (color === this.cachedColorProp) return;

    this.cachedColorProp = color;

    this.setState({ value: color });
  };

  handleColorClick = () => {
    const { value } = this.state;
    const { onSelect } = this.props;
    let currentColorIndex = colors.indexOf(value);

    this.clearTimeouts();

    if (currentColorIndex === -1) currentColorIndex = 0;

    const newColorIndex =
      currentColorIndex > colors.length ? 0 : currentColorIndex + 1;

    const newColor = colors[newColorIndex];

    this.setState({ value: newColor });

    onSelect(newColor);
  };

  handleHover = () => {
    this.clearTimeouts();
    this.hoverTimeout = setTimeout(() => {
      this.setState({ open: true });
    }, 850);
  };

  handleBlur = () => {
    this.clearTimeouts();
    this.blurTimeout = setTimeout(() => {
      this.setState({ open: false });
    }, 250);
  };

  clearTimeouts = () => {
    if (this.blurTimeout) clearTimeout(this.blurTimeout);
    if (this.hoverTimeout) clearTimeout(this.hoverTimeout);
  };

  handleMenuClose = (value = null) => () => {
    const { onSelect } = this.props;
    this.clearTimeouts();
    if (value !== null) {
      this.setState({ open: false, value });
      onSelect(value);
    } else {
      this.setState({ open: false });
    }
  };

  render() {
    const { classes, iconStyles, buttonStyles, onSelect } = this.props;
    const { value, open } = this.state;

    if (onSelect === null) {
      return (
        <IconButton
          className={classes.colorIconButton}
          style={Object.assign({}, buttonStyles, { color: value })}
        >
          <LensIcon style={iconStyles} />
        </IconButton>
      );
    }

    return (
      <React.Fragment>
        <Tooltip title="Click to change" placement="right-start">
          <IconButton
            buttonRef={node => {
              this.anchorEl = node;
            }}
            className={classes.colorIconButton}
            onClick={this.handleColorClick}
            onMouseEnter={this.handleHover}
            onFocus={this.handleHover}
            onMouseLeave={this.handleBlur}
            onBlur={this.handleBlur}
            aria-owns={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            style={Object.assign({}, buttonStyles, { color: value })}
          >
            <LensIcon style={iconStyles} />
          </IconButton>
        </Tooltip>
        <Popper
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          style={{ zIndex: 2 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper
                elevation={1}
                onMouseEnter={this.handleHover}
                onFocus={this.handleHover}
                onMouseLeave={this.handleBlur}
                onBlur={this.handleBlur}
              >
                <ClickAwayListener onClickAway={this.handleMenuClose()}>
                  <MenuList>
                    {colors.map(color => (
                      <MenuItem
                        key={color}
                        onClick={this.handleMenuClose(color)}
                      >
                        <ListItemIcon style={{ color }}>
                          <LensIcon />
                        </ListItemIcon>
                      </MenuItem>
                    ))}
                    <MenuItem key="close" onClick={this.handleMenuClose()}>
                      Close
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
    );
  }
}

ColorSelectorHelper.defaultProps = {
  color: colors[0],
  onSelect: null,
  iconStyles: {},
  buttonStyles: {}
};

ColorSelectorHelper.propTypes = {
  classes: PropTypes.any.isRequired,
  color: PropTypes.any,
  onSelect: PropTypes.any,
  iconStyles: PropTypes.any,
  buttonStyles: PropTypes.any
};

export default withStyles(style)(ColorSelectorHelper);
