import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listUserStoredNotificationAction,
  updateUserNotificationAction,
} from "../redux/actions/notifications";

import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  notificationElementContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  accordionContainer: {
    marginTop: "5px",
  },
  accordionChild: {
    width: "100%",
  },
  typography: {
    // margin: "0 0.5rem",
  },
  typographyHeading: {
    fontSize: "0.75rem",
    color: "gray",
  },
}));

export default function GeneratedNotificationPage() {
  const [notificationList, setNotificationList] = useState([]);
  const dispatch = useDispatch();
  const userEmailPreference = useSelector((state) => state.userEmailPreference);
  const classes = useStyles();
  
  useEffect(() => {
    dispatch(listUserStoredNotificationAction());
  }, [dispatch]);

  useEffect(() => {
    if (userEmailPreference)
      setNotificationList(userEmailPreference.userNotificationList);
  }, [userEmailPreference]);

  const handleAccordionClick = (notification) => {
    dispatch(updateUserNotificationAction({...notification, new: false}));
  };

  return (
    <>
      <div className={classes.notificationElementContainer}>
        <div>
          <Typography variant="h5">Notifications</Typography>
        </div>

        <div className={classes.accordionContainer}>
          {notificationList &&
            notificationList.length > 0 &&
            notificationList.filter(notification => notification.processedTimestamp !== "1970-01-01T00:00:00.000Z").map((notification) => {
              return (
                <>
                  <Box m={1} pt={2}>
                    <Accordion className={classes.accordionChild}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onClick={() => {
                          handleAccordionClick(notification);
                        }}
                      >
                        <Box>
                          <Typography>{notification.summaryText}</Typography>
                          <Box display="block" mr={2}>
                            <Badge
                              badgeContent={notification.new ? "New" : "Seen"}
                              color="primary"
                            />
                          </Box>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          display="flex"
                          width={"100%"}
                          flex={1}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <div>
                            <Typography className={classes.typographyHeading}>
                              Email
                            </Typography>
                            <Typography className={classes.typography}>
                              {notification.email}
                            </Typography>
                          </div>
                          <div>
                            <Typography className={classes.typographyHeading}>
                              Sent
                            </Typography>
                            <Typography className={classes.typography}>
                              {notification.processedTimestamp}
                            </Typography>
                          </div>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </>
              );
            })}
        </div>
      </div>
    </>
  );
}
