import React, { useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import PropTypes from 'prop-types';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import JournalItem from '../../../containers/Journal/Item';
import { getJournalStruct } from '../../../structs/journal';
import {addJournalAction, fetchTrackerJournalAction} from "../../../redux/actions/journal";

function JournalList ({ trackerId }) {
  const dispatch = useDispatch();
  const { items, nextToken } = useSelector(store => store.journal);
  const [entry, setEntry] = useState('');

  useEffect(() => {
    // Load Activity
    if (trackerId) {
      dispatch(fetchTrackerJournalAction({
        trackerId,
        nextToken: null
      }));
    }
  }, [dispatch, trackerId]);

  const handleMore = () => {
    // Load the next page
    dispatch(fetchTrackerJournalAction({
      trackerId,
      nextToken
    }));
  };

  const handleNewEntryChange = event => {
    setEntry(event.target.value);
  };

  const handleSubmit = () => {
    dispatch(
      addJournalAction(
        getJournalStruct({
          entry,
          trackerId
        })
      )
    );
    setEntry('');
  };

  return (
      <div>
        <List>
          <ListItem>
            <TextField
              style={{ width: '100%' }}
              key="newEntry"
              name="newEntry"
              multiline
              fullWidth
              value={entry}
              onChange={handleNewEntryChange}
            />
          </ListItem>
          <ListItem>
            <Button
              onClick={handleSubmit}
              fullWidth
              color="primary"
              variant="contained"
              size="small"
              disabled={!entry}
            >
              Save
            </Button>
          </ListItem>

          {items.filter(item => item.trackerId === trackerId).sort((a,b) => a.datetime > b.datetime ? -1 : 1).map(item => {
              return <JournalItem key={item.id} item={item} />;
          })}

          {!!nextToken ? (
            <ListItem>
              <Button
                onClick={handleMore}
                size="small"
                fullWidth
              >
                Load More
              </Button>
            </ListItem>
          ) : null}

        </List>
      </div>
    );
}

JournalList.propTypes = {
  trackerId: PropTypes.any.isRequired,
};

export default JournalList;
