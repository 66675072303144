import React, { Component } from 'react';
import { connect } from 'react-redux';
import Commitments from '../../components/ListsPage/Commitments';
import {
  addCommitmentAction,
  saveCommitmentAction
} from '../../redux/actions/commitments';

export class CommitmentsContainer extends Component {
  render() {
    return <Commitments />;
  }
}

function mapStateToProps(state) {
  return {
    commitmentIndex: state.commitmentIndex,
    commitments: state.commitments,
    user: state.user
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    createCommitment: commitment => {
      dispatch(addCommitmentAction(commitment));
    },
    updateCommitment: commitment => dispatch(saveCommitmentAction(commitment))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Commitments);
