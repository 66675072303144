import range from 'lodash/range';
import moment from 'moment';

export const ONE_DAY = 1000 * 60 * 60 * 24;

export const getReviewDueDate = (reviewCycle, skipCalculation = false) => {
  let nextReviewDate = moment(getStartReviewDate(reviewCycle));
  const lastReviewedDate = moment(getLastReviewedDate(reviewCycle));

  // Get Start Date, today unless Last Reviewed Date is set

  // Review Tracker Daily
  // reviewCycle = {
  //   frequency: 'daily',
  //   interval: 1,
  //   positions: []
  // };

  // Review Tracker Every Weekday
  // reviewCycle = {
  //   frequency: 'weekly',
  //   interval: 1,
  //   positions: ['monday','tuesday','wednesday','thursday','friday'] Sunday = 0
  // };

  // Review Tracker every Sunday
  // reviewCycle = {
  //   frequency: 'weekly',
  //   interval: 1,
  //   positions: ['sunday']
  // };

  // Review Tracker every 1st of the month
  // reviewCycle = {
  //   frequency: 'monthly',
  //   interval: 1,
  //   positions: [1]
  // };

  // Review Tracker every 1st of day of the quarter
  // reviewCycle = {
  //   frequency: 'monthly',
  //   interval: 3,
  //   positions: [1]
  // };

  // We initialize the nextReviewDate if the tracker has never been reviewed
  if (nextReviewDate && nextReviewDate > lastReviewedDate) {
    // We are snoozing for the future.
  } else if (lastReviewedDate) {
    nextReviewDate = moment(lastReviewedDate);
  } else {
    nextReviewDate.subtract(1, 'day');
  }

  if (skipCalculation) {
    // Skip the calculation
  } else if (reviewCycle.frequency === 'daily') {
    nextReviewDate.add(parseInt(reviewCycle.interval, 10), 'day');
  } else if (reviewCycle.frequency === 'weekly') {
    if (!reviewCycle.positions.length) {
      nextReviewDate.add(reviewCycle.interval * 7, 'day');
    } else {
      const potentialReviewDates = reviewCycle.positions.map(position => {
        let { interval } = reviewCycle;
        const posInt = parseInt(position, 10);
        const potentialDate = moment(nextReviewDate);

        if (nextReviewDate.day() < posInt) interval -= 1;

        const intervalAdjustment = interval * 7;
        const weekday = potentialDate.day();
        const totalAdjustment = intervalAdjustment - weekday + posInt;
        potentialDate.add(totalAdjustment, 'day');
        return potentialDate;
      });
      potentialReviewDates.sort((a, b) => b.isAfter(a) ? 1 : -1);
      nextReviewDate = potentialReviewDates.pop()
    }
  } else if (reviewCycle.frequency === 'monthly') {
    if (!reviewCycle.positions.length) {
      nextReviewDate.add(reviewCycle.interval, 'month');
    } else {
      const potentialReviewDates = reviewCycle.positions.map(position => {
        let { interval } = reviewCycle;
        let intervalAdjustment = 0;
        const stepDate = moment(nextReviewDate);
        const posInt = parseInt(position, 10);
        const potentialDate = moment(nextReviewDate);

        if (nextReviewDate.date() < posInt) interval -= 1;

        if (interval > 0)
          range(0, interval).map(() => {
            stepDate.add(1, 'month');
            intervalAdjustment += getNumberOfDaysInMonth(stepDate);
            return intervalAdjustment;
          });

        const monthDay = potentialDate.date();
        const totalAdjustment = intervalAdjustment - monthDay + posInt;
        potentialDate.add(totalAdjustment, 'day');
        return potentialDate;
      });
      potentialReviewDates.sort((a, b) => b.isAfter(a) ? 1 : -1);
      nextReviewDate = potentialReviewDates.pop();
    }
  }

  return nextReviewDate;
};

export const getDaysUntilReview = reviewDate => {
  const now = moment().startOf('day');
  return Math.floor((reviewDate - now) / ONE_DAY);
};

export const getLastReviewedDate = reviewCycle => {
  return new Date(reviewCycle.lastReviewedDate);
};

export const getStartReviewDate = reviewCycle => {
  return new Date(reviewCycle.startReviewDate
      ? moment(reviewCycle.startReviewDate).subtract(1,"day")
      : getLastReviewedDate(reviewCycle));
};

export const getNumberOfDaysInMonth = date => {
  return moment(date).endOf('month').date();
};
