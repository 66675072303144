import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import {useDispatch, useSelector} from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import {deleteCommitmentTrayAction, saveCommitmentTrayAction} from "../../redux/actions/commitmentTray";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List/List";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";
import CommitmentItem from "../../containers/Commitment/Item";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import {makeStyles} from "@material-ui/core/styles";
import {ROLE_NO_ROLE_NAME} from "../../structs/roles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {Inbox} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  infoTooltip: {
    fontSize: '1rem',
    textAlign: 'center'
  },
  infoPopper: {
    padding: 10,
    margin: 10
  }
}));

const CommitmentTrayList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const allTrays = useSelector(store => store.trays);
  const activeRole = useSelector(store => store.roles.active);
  const commitmentsByTray = useSelector(store => store.commitmentIndex.byTray);
  const [ edit, setEdit] = useState(null);

  return (
    <>
      <Typography
        variant={"overline"}
        color="secondary"
        style={{display: 'inline-block', textAlign: 'center', width: '100%'}}
      >
        <Inbox style={{ marginBottom: -4, marginRight: 5, fontSize: '1.5em' }} />
        Commitment Trays
      </Typography>
      {allTrays.map((tray) => {
        const trayCommitments = commitmentsByTray[tray.id] ? commitmentsByTray[tray.id] : [];
        const filteredCommitments = activeRole.name
          ? trayCommitments.filter(c => (c.entity.role.name === activeRole.name) || (activeRole.name === ROLE_NO_ROLE_NAME && c.entity.role.name === ''))
          : trayCommitments;
        return (
          <Accordion key={tray.id} variant={'outlined'}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{tray.name}</Typography>
              <Chip
                size={"small"}
                style={{marginLeft: 7}}
                variant={"outlined"}
                label={filteredCommitments.length ? `${filteredCommitments.length} Commitments` : 'Empty'}
              />
              {tray.description.trim() ? (
                <Tooltip
                  classes={{
                    popper: classes.infoPopper,
                    tooltip: classes.infoTooltip
                  }}
                  title={tray.description}
                >
                  <InfoIcon style={{ color: "lightgray"}} />
                </Tooltip>
              ) : null}
            </AccordionSummary>
            <AccordionDetails style={{padding: 0}}>
              <Card elevation={0} style={{width: '100%', padding: 0}}>
                <CardContent style={{padding: 8}}>
                  <List style={{padding: 0}}>
                    {filteredCommitments.length > 0
                      ? filteredCommitments.map((commitmentDoc) => (
                        <CommitmentItem
                          key={commitmentDoc.entity.id}
                          commitment={commitmentDoc}
                          showDate
                        />))
                      : null}
                  </List>
                </CardContent>
                <CardActions>
                  <IconButton
                    onClick={() => setEdit(tray)}
                    style={{ marginLeft: 'auto' }}
                  >
                    <EditIcon color={"primary"}/>
                  </IconButton>
                </CardActions>
              </Card>
            </AccordionDetails>
          </Accordion>
        )
      })}
      <Dialog
        open={!!(edit && edit.id)}
        fullWidth
      >
        <DialogTitle>Edit Tray</DialogTitle>
        <DialogContent
          style={{ margin: "auto" }}
        >
          {edit ? (
            <>
              <TextField
                fullWidth
                placeholder="Enter Tray Name"
                value={edit.name}
                onChange={({ target: { value }}) => setEdit({...edit, name: value})}
              />
              <TextField
                fullWidth
                placeholder="Enter Description (optional)"
                value={edit.description}
                onChange={({ target: { value }}) => setEdit({...edit, description: value})}
              />
            </>
          ) : null}
        </DialogContent>
        <DialogActions>
          <>
            {edit && !commitmentsByTray[edit.id] ? (
              <Button
                style={{ marginRight: "auto" }}
                onClick={() => { dispatch(deleteCommitmentTrayAction(edit)); setEdit(null); }}
              >
                Delete Tray
              </Button>
            ) : null}
            <Button
              onClick={() => setEdit(null)}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => { dispatch(saveCommitmentTrayAction(edit)); setEdit(null); }}
            >
              Save
            </Button>
          </>
        </DialogActions>
      </Dialog>
    </>
  );
};

CommitmentTrayList.defaultProps = {};

CommitmentTrayList.propTypes = {};

export default CommitmentTrayList;
