module.exports = () => ({
  container: {
    width: 'calc(100vw - 68px)',
    display: 'flex',
    flexDirection: 'row',
    marginTop: 23
  },
  containerCenter: {
    width: '100%'
  },
  contentContainer: {
    marginRight: '1em',
    display: 'flex'
  },
  trackersList: {
    width: '60%',
    flexGrow: 1
  },
  heading: {
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontSize: 27,
    letterSpacing: 0,
    color: '#73bb86'
  },
  logo: {
    width: 35,
    height: 35,
  }
});
