import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  confirmUserAction,
  resendCodeAction,
  signUpAction
} from '../redux/actions/auth';
import ConfirmUser from '../components/Register/ConfirmUser';

export class ConfirmPageContainer extends Component {
  render() {
    return <ConfirmUser />;
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  signUp: user => dispatch(signUpAction(user)),
  confirmUser: user => dispatch(confirmUserAction(user)),
  resendCode: username => dispatch(resendCodeAction(username))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmUser);
