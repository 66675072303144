import { UPDATE_SEARCH_INDEX } from '../actions/search';
import { SearchIndexDataStruct } from '../../structs/indexers';

export default function searchIndexReducer(
  oldstate = Object.assign({}, SearchIndexDataStruct),
  action
) {
  switch (action.type) {
    case UPDATE_SEARCH_INDEX:
      action.payload.all.sort(
        (a, b) => new Date(b.entity.updatedAt) - new Date(a.entity.updatedAt)
      );
      return action.payload;

    default:
      return oldstate;
  }
}
