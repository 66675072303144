import { put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import {
  APPLICATION_DATA_CLEAR_REQUEST_ACTION,
  APPLICATION_INIT_REQUEST_ACTION,
  APPLICATION_INIT_SUCCESS_ACTION,
  applicationDataSuccessAction, clearApplicationDataAction,
  setDesiredRouteAction
} from '../actions/app';
import {
  loadUserFromDiskAction,
  SET_USER,
  setUserAction, USER_ACCEPT_LOGIN_SUCCESS_ACTION,
  USER_ACCEPT_REGISTRATION_SUCCESS_ACTION
} from '../actions/user';
import { loadFromStorage } from '../../utils/localStorage';
import {
  AUTH_GET_CURRENT_USER_FAILURE_ACTION,
  AUTH_GET_CURRENT_USER_SUCCESS_ACTION,
  AUTH_SIGN_IN_FAILURE_ACTION,
  AUTH_SIGN_UP_FAILURE_ACTION,
  AUTH_SIGN_OUT_SUCCESS_ACTION,
  AUTH_SIGN_UP_SUCCESS_ACTION, AUTH_SIGN_IN_SUCCESS_ACTION, AUTH_CONFIRM_USER_SUCCESS_ACTION
} from '../actions/auth';
import {
  getUserStruct,
  USER_STATUS_CONFIRMED,
  USER_STATUS_LOGGED_IN,
  USER_STATUS_LOGGED_OUT,
  USER_STATUS_REGISTERED,
  UserCredentialsStruct
} from '../../structs/user';
import sync from '../../utils/backup/sync';
import routes from '../../constants/routes.json';
import {
  addEmailNotificationAction,
  INITIALIZE_EMAIL_PREFERENCE_REQUEST_ACTION,
  INITIALIZE_EMAIL_PREFERENCE_SUCCESS_ACTION,
  showErrorAction
} from '../actions/notifications';
import {
  fetchMembershipStatusAction,
  MEMBERSHIP_FETCH_STATUS_SUCCESS_ACTION,
  setMembershipStatusAction
} from '../actions/membership';
import {getMembershipStatusStruct} from '../../structs/membership';
import {NOTIFICATION_TOPIC_REGISTRATION_CONFIRM} from '../../structs/notification';
import {
  STRIPE_CHECK_REQUEST_ACTION, STRIPE_CHECK_SUCCESS_ACTION,
  SUBSCRIPTION_START_REQUEST_ACTION,
  SUBSCRIPTION_START_SUCCESS_ACTION
} from '../actions/payment';
import {
  APPLICATION_FULL_SYNC_SUCCESS_ACTION,
  applicationFullSyncAction,
  VERIFY_APP_SYNC_SUCCESS_ACTION
} from '../actions/appSync';
import {setBackupProfileAction} from '../actions/backupProfile';

function* handleApplicationInitRequest() {
  // Perform Application Initiation
  const userState = yield select(store => store.user);

  // Call the Util Local Storage loadState function which pulls data
  // from the local storage if cached there, if not it pulls from the disk
  const user = yield loadFromStorage('user', userState);

  yield put(loadUserFromDiskAction(user));

  yield put(applicationDataSuccessAction('user'));
  
  yield put({ type: APPLICATION_INIT_SUCCESS_ACTION });

  sync();
}

function* handleAcceptUserLogin(action) {
  const authenticatedUser = action.payload;

  const localUser = yield select(store => store.user);

  const user = Object.assign({}, localUser, {
    id: authenticatedUser.attributes.sub,
    attributes: {
      username: authenticatedUser.username,
      givenName: authenticatedUser.attributes.given_name,
      familyName: authenticatedUser.attributes.family_name,
      email: authenticatedUser.attributes.email,
      emailVerified: authenticatedUser.attributes.email_verified
    },
    credentials: UserCredentialsStruct,
    lastLoginTime: moment().format(),
    status: USER_STATUS_LOGGED_IN,
    updatedAt: moment().format()
  });

  yield put(setUserAction(user));

  // Fetch the latest membership status from remote data store
  yield put(fetchMembershipStatusAction());
  yield take(MEMBERSHIP_FETCH_STATUS_SUCCESS_ACTION);
  yield put({ type: USER_ACCEPT_LOGIN_SUCCESS_ACTION });
}

function* handleAcceptGetUser({ payload: authenticatedUser }) {
  const localUser = yield select(store => store.user);
  
  const user = Object.assign({}, localUser, {
    id: authenticatedUser.attributes.sub,
    attributes: {
      username: authenticatedUser.username,
      givenName: authenticatedUser.attributes.given_name,
      familyName: authenticatedUser.attributes.family_name,
      email: authenticatedUser.attributes.email,
      emailVerified: authenticatedUser.attributes.email_verified
    },
    status: USER_STATUS_LOGGED_IN,
    updatedAt: moment().format()
  });
  
  yield put(setUserAction(user));
  
  // Fetch the latest membership status from remote data store
  yield put(fetchMembershipStatusAction());
  yield take(MEMBERSHIP_FETCH_STATUS_SUCCESS_ACTION);
}

function* handleAcceptUserRegistration(action) {
  const userRegistration = action.payload;
  const localUser = yield select(store => store.user);

  let status = USER_STATUS_REGISTERED;
  if (userRegistration.userConfirmed) {
    status = USER_STATUS_CONFIRMED;
  }

  const user = Object.assign({}, localUser, {
    id: userRegistration.userSub,
    registrationDate: moment().format(),
    status,
    updatedAt: moment().format(),
    attributes: {
      ...localUser.attributes,
      username: userRegistration.user.username,
      userConfirmed: userRegistration.userConfirmed
    }
  });

  yield put(setUserAction(user));

  if (userRegistration.userConfirmed) {
    yield put({ type: USER_ACCEPT_REGISTRATION_SUCCESS_ACTION });
  } else {
    yield put(setDesiredRouteAction(routes.CONFIRM));
  }
}

function* handleRejectUser(action) {
  const localUser = yield select(store => store.user);
  const error = action.payload;
  
  if (error && error.code && error.code === 'UserNotConfirmedException') {
    
    yield put(
      showErrorAction(
        'You have started to create an account, but we still need to confirm your email.'
      )
    );

    const user = Object.assign({}, localUser, {
      status: USER_STATUS_REGISTERED,
      updatedAt: moment().format(),
      registrationDate: localUser.registrationDate,
      credentials: { ...localUser.credentials, verificationCode: '' }
    });

    yield put(setUserAction(user));
    yield put(setDesiredRouteAction(routes.CONFIRM));
  } else {
    const user = Object.assign({}, localUser, {
      status: USER_STATUS_LOGGED_OUT,
      updatedAt: moment().format()
    });
  
    yield put(setUserAction(user));
  }
  
}

function* handleStateChange() {
  const type = 'user';

  // Select data from the store
  const data = yield select(store => store[type]);

  const dataString = JSON.stringify(data);

  // As well as in the local storage.
  localStorage.setItem(type, dataString);
}

function* handleApplicationDataClear() {
  yield put(setUserAction(getUserStruct()));
  yield put(setMembershipStatusAction(getMembershipStatusStruct()));
  yield put(setDesiredRouteAction(routes.LOGIN));
}

function* handleTriggerDataClear() {
  yield put(clearApplicationDataAction());
}

function* handleUserAccountInit() {
  // When someone Creates an account the first time, we need to setup their account
  yield put({type: SUBSCRIPTION_START_REQUEST_ACTION});
  yield take(SUBSCRIPTION_START_SUCCESS_ACTION);

  yield put({type: INITIALIZE_EMAIL_PREFERENCE_REQUEST_ACTION});
  yield take(INITIALIZE_EMAIL_PREFERENCE_SUCCESS_ACTION);
  
  const user = yield select(store => store.user);
  
  yield put(addEmailNotificationAction({
    topic: NOTIFICATION_TOPIC_REGISTRATION_CONFIRM,
    summaryText: `Welcome to Formigio, your account was created, it's official you are a member!`,
    payload: `
         <h1>Welcome to Formigio&trade;</h1>
         <p>Hello ${user.attributes.givenName},</p>
         <p>We are sure excited that you decided to give Formigio&trade; a try!</p>
         <a href="https://app.formigio.com/">Link to get back to Formigio&trade;</a>
         <p>~~</p>
         <p>We think you'll like having a helper like Formigio&trade;.</p>
         <p>~~</p>
         <p>Happy Accomplishing!</p>
         <p>Let us know if you have any questions or need any help!</p>
         <p>~ The Formigio&trade; Team</p>
      `,
  }));
  
  const backupProfile = yield select(store => store.backupProfile);
  // Set backup profile
  yield put(setBackupProfileAction({
    ...backupProfile,
    verifiedAt: '',
    autoSyncEnabled: true
  }));
  
  yield take(VERIFY_APP_SYNC_SUCCESS_ACTION);
  
  yield put(applicationFullSyncAction());
  yield take(APPLICATION_FULL_SYNC_SUCCESS_ACTION);

  yield put({type: STRIPE_CHECK_REQUEST_ACTION});
  yield take(STRIPE_CHECK_SUCCESS_ACTION);
}

export default function* userSaga() {
  yield takeEvery(
    APPLICATION_INIT_REQUEST_ACTION,
    handleApplicationInitRequest
  );
  yield takeEvery(
    APPLICATION_DATA_CLEAR_REQUEST_ACTION,
    handleApplicationDataClear
  );
  yield takeEvery(SET_USER, handleStateChange);
  yield takeEvery(AUTH_SIGN_UP_SUCCESS_ACTION, handleAcceptUserRegistration);
  yield takeEvery(AUTH_SIGN_IN_SUCCESS_ACTION, handleAcceptUserLogin);
  yield takeEvery(AUTH_GET_CURRENT_USER_SUCCESS_ACTION, handleAcceptGetUser);
  yield takeEvery(AUTH_GET_CURRENT_USER_FAILURE_ACTION, handleRejectUser);
  yield takeEvery(AUTH_SIGN_IN_FAILURE_ACTION, handleRejectUser);
  yield takeEvery(AUTH_SIGN_UP_FAILURE_ACTION, handleRejectUser);
  yield takeEvery(AUTH_SIGN_OUT_SUCCESS_ACTION, handleTriggerDataClear);
  yield takeLatest([
    AUTH_CONFIRM_USER_SUCCESS_ACTION,
    USER_ACCEPT_REGISTRATION_SUCCESS_ACTION
  ], handleUserAccountInit);
  
  
}
