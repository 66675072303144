import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Input from '@material-ui/core/Input/Input';
import { getCommitmentStruct } from '../../structs/commitments';
import { getToday } from '../../utils/date';
import ColorSelectorHelper from '../Ui/ColorSelectorHelper';
import { stringifyApn } from '../../utils/apn/v2';

const style = () => ({
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '80%',
    minHeight: '75%',
    maxHeight: '75%'
  },
  colorLabel: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    marginTop: '.5em'
  },
  input: {
    width: '100%',
    height: 37,
    paddingLeft: 58,
    paddingTop: 1,
    fontSize: '1rem',
    paddingBottom: 0,
    '&:before': {
      borderBottomColor: 'rgba(0, 0, 0, 0.12)'
    }
  },
  colorWrapper: {
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    zIndex: 3
  }
});

class QuickAdd extends Component {
  state = {
    commitment: getCommitmentStruct()
  };

  componentDidMount() {
    this.initializeCommitment();
  }

  componentDidUpdate() {
    this.initializeCommitment();
  }

  initializeCommitment = () => {
    const { commitment } = this.state;
    const { user } = this.props;
    let needsStateUpdate = false;

    commitment.userId = user.id;
    commitment.contextApn = stringifyApn({
      userId: user.id
    });
    
    commitment.contextDescription = 'Added from Homepage';
    
    if (commitment.date === '') {
      commitment.date = moment(getToday()).format();
      needsStateUpdate = true;
    }

    if (needsStateUpdate === true) {
      this.setState({ commitment });
    }
  };

  handleChange = event => {
    const { commitment } = this.state;
    commitment.description = event.target.value;
    this.setState({ commitment });
  };

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.handleSubmit();
    }
  };

  handleColorSelect = color => {
    const { commitment } = this.state;
    commitment.highlightColor = color;
    this.setState({ commitment });
  };

  handleSubmit = () => {
    const { commitment } = this.state;
    const { addCommitment } = this.props;
    if (commitment.description.length > 0) {
      addCommitment(getCommitmentStruct(commitment));
      this.setState({ commitment: getCommitmentStruct() });
    }
  };

  render() {
    const { commitment } = this.state;
    const { classes } = this.props;

    return (
      <div style={{ position: 'relative' }}>
        <div className={classes.colorWrapper}>
          <ColorSelectorHelper
            color={commitment.highlightColor}
            onSelect={this.handleColorSelect}
            buttonStyles={{ margin: 0 }}
          />
        </div>
        <Input
          value={commitment.description}
          onChange={this.handleChange}
          placeholder="Add new commitment"
          className={classes.input}
          onKeyPress={this.handleKeyPress}
          onBlur={this.handleSubmit}
        />
      </div>
    );
  }
}

QuickAdd.propTypes = {
  classes: PropTypes.any.isRequired,
  addCommitment: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default withStyles(style)(QuickAdd);
