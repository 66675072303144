import {MembershipStatusStruct} from '../../structs/membership';
import {SET_MEMBERSHIP_STATUS} from '../actions/membership';

export default function membershipReducer(
  oldstate = Object.assign({}, MembershipStatusStruct),
  action
) {

  switch (action.type) {
    case SET_MEMBERSHIP_STATUS:
      return Object.assign({}, MembershipStatusStruct, oldstate, action.payload);
      
    default:
      return oldstate;
  }
}
