import React, { Component } from 'react';
import { connect } from 'react-redux';
import {saveTrackerAction, searchArchiveAction} from '../../redux/actions/trackers';
import Trackers from '../../components/ListsPage/Trackers';

export class TrackersContainer extends Component {
  render() {
    return <Trackers />;
  }
}

function mapStateToProps(state) {
  return {
    trackerIndex: state.trackerIndex
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    saveTracker: tracker => dispatch(saveTrackerAction(tracker)),
    searchArchive: term => dispatch(searchArchiveAction(term))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Trackers);
