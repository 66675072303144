import { SyncCheckerStruct } from '../../structs/sync';
import { TOGGLE_SYNC_CHECKER_ACTION } from '../actions/sync';

export default function SyncCheckerReducer(
  oldState = { ...SyncCheckerStruct },
  action
) {
  switch (action.type) {
    case TOGGLE_SYNC_CHECKER_ACTION:
      return {
        ...oldState,
        opened: action.payload
      };

    default:
      return oldState;
  }
}
