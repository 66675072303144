import React from "react";
import Typography from "@material-ui/core/Typography";
import { take, put, select, takeLatest, all, takeEvery } from "redux-saga/effects";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import {
  ADD_LOCAL_TRACKER,
  REMOVE_LOCAL_TRACKER,
  SET_LOCAL_TRACKERS,
  setLocalTrackersAction,
  SAVE_LOCAL_TRACKER,
  TRACKER_CREATE_REQUEST_ACTION,
  TRACKER_CREATE_SUCCESS_ACTION,
  TRACKER_DATA_LOAD_REQUEST_ACTION,
  TRACKER_DATA_LOAD_SUCCESS_ACTION,
  TRACKER_DELETE_REQUEST_ACTION,
  TRACKER_DELETE_SUCCESS_ACTION,
  TRACKER_FULL_SYNC_REQUEST_ACTION,
  TRACKER_FULL_SYNC_SUCCESS_ACTION,
  TRACKER_QUICK_SYNC_REQUEST_ACTION,
  TRACKER_QUICK_SYNC_SUCCESS_ACTION,
  TRACKER_COPY_REQUEST_ACTION,
  TRACKER_COPY_SUCCESS_ACTION,
  TRACKER_UPDATE_REQUEST_ACTION,
  TRACKER_UPDATE_SUCCESS_ACTION,
  UPSERT_LOCAL_TRACKER,
  upsertLocalTrackerAction,
  addTrackerAction,
  TRACKER_COPY_FAILURE_ACTION,
  TRACKER_UPDATE_FAILURE_ACTION,
  TRACKER_SEARCH_REQUEST_ACTION,
  updateTrackerIndexAction,
  saveTrackerAction,
  TRACKER_MIGRATE_SUCCESS_ACTION,
  TRACKER_MIGRATE_REQUEST_ACTION,
  migrateTrackerAction,
  TRACKER_CREATE_FROM_SCAFFOLD_REQUEST_ACTION,
  createTrackerFromScaffoldAction,
  TRACKER_CREATE_FROM_SCAFFOLD_SUCCESS_ACTION
} from '../actions/trackers';
import routes from '../../constants/routes.json';
import {fetchRemoteTrackers, searchRemoteTrackers} from '../../utils/appSync/tracker';
import {
  evaluateTrackers, getActionItemStruct, getPlanStruct, getSuccessStruct,
  getTrackerStruct, migrateTracker,
  TRACKER_STATUS_ACTIVE,
  TRACKER_STATUS_ARCHIVED,
} from "../../structs/trackers";
import { loadFromStorage } from "../../utils/localStorage";
import {
  addNotificationAction, setLoadingAction,
  setProcessingAction,
} from "../actions/notifications";
import {
  addSyncEventAction,
  SYNC_EVENT_SYNC_SUCCESS_ACTION,
  syncAllSyncEventsAction,
} from "../actions/syncEvents";
import { getSyncEventStruct } from "../../structs/sync";
import {
  APPLICATION_DATA_CLEAR_REQUEST_ACTION,
  APPLICATION_DATA_REQUEST_ACTION,
  setDesiredRouteAction,
} from "../actions/app";
import {
  MILESTONE_UPSERT_REMOTE_ACTION,
  MILESTONE_UPSERT_REMOTE_SUCCESS_ACTION,
  TRACKER_UPSERT_REMOTE_ACTION,
  TRACKER_UPSERT_REMOTE_SUCCESS_ACTION,
} from "../actions/appSync";
import ROUTES from "../../constants/routes";
import {
  payGateCheckAction,
  PAYMENT_GATE_ALLOW_ACTION,
  PAYMENT_GATE_REJECT_ACTION,
} from "../actions/payment";
import { getSystemNotificationStruct } from "../../structs/notification";
import {SET_ACTIVE_TRACKER, setActiveTrackerAction} from "../actions/activeTracker";
import { TrackerIndexStruct } from "../../structs/indexers";
import trackerTemplateScaffolds from '../../templates/trackers';
import {addMilestoneAction} from '../actions/milestones';
import {stringifyApn} from '../../utils/apn/v2';
import {getReviewCycleStruct} from '../../structs/reviewCycle';
import {fetchMigration, saveMigration} from "../../utils/appSync/migration";
import {createScaffoldFromTracker} from "../../structs/trackerTemplate";
import {getMetricStruct} from "../../structs/measure";

function* handleCreateTrackerRequest({ payload: trackerData }) {
  const user = yield select(store => store.user);
  // Persist Tracker to Local Data Store
  const now = moment().format();
  const tracker = getTrackerStruct({
    ...trackerData,
    id: uuidv4(),
    ownerApn: stringifyApn({ userId: user.id }),
    createdAt: now,
    updatedAt: now,
    reviewCycle: getReviewCycleStruct({
      ...trackerData.reviewCycle,
      updatedAt: now,
    })
  });
  
  yield put({
    type: ADD_LOCAL_TRACKER,
    payload: tracker,
  });

  // Signal Complete
  yield put({
    type: TRACKER_CREATE_SUCCESS_ACTION,
    payload: tracker,
  });
  
  yield put(
    addNotificationAction(
      getSystemNotificationStruct({
        message: "New Tracker Created.",
        level: "success",
      })
    )
  );
}

function* handleCopyTrackerRequest(action) {
  const sourceTracker = action.payload;

  yield put(payGateCheckAction());

  const { type } = yield take([
    PAYMENT_GATE_ALLOW_ACTION,
    PAYMENT_GATE_REJECT_ACTION,
  ]);
  if (type === PAYMENT_GATE_REJECT_ACTION) {
    yield put({ type: TRACKER_COPY_FAILURE_ACTION });
    return 0;
  }

  const template = createScaffoldFromTracker(sourceTracker);

  yield put(createTrackerFromScaffoldAction(template));

  const { payload: newTracker } = yield take(TRACKER_CREATE_FROM_SCAFFOLD_SUCCESS_ACTION);

  yield put(setDesiredRouteAction(`${routes.TRACKER_BASE}${newTracker.id}`));

  // Signal Complete
  yield put({
    type: TRACKER_COPY_SUCCESS_ACTION,
    payload: {
      sourceTracker,
      newTracker,
    },
  });

  yield put(
    addNotificationAction(
      getSystemNotificationStruct({
        message: `Here is your new Tracker: ${newTracker.dream}`,
        level: "success",
      })
    )
  );
}

function* handleUpdateTrackerRequest(action) {
  // Persist Tracker to Local Data Store
  const tracker = getTrackerStruct(action.payload);

  // Check for un-archiving against limits
  const activeTrackerDoc = yield select((store) => store.activeTracker);
  const { tracker: activeTracker } = activeTrackerDoc;
  if (
    activeTracker.status === TRACKER_STATUS_ARCHIVED &&
    tracker.status === TRACKER_STATUS_ARCHIVED
  ) {
    yield put(
      addNotificationAction(
        getSystemNotificationStruct({
          message: (
            <div>
              <Typography>
                {`Hmm... you can't update an archived tracker. If you want to change the tracker
                need make it active first. Any changes made will not be saved.`}
              </Typography>
            </div>
          ),
          type: "dialog",
          level: "warning",
        })
      )
    );
    yield put(setActiveTrackerAction(activeTracker));
    return 0;
  } else if (
    activeTracker.status === TRACKER_STATUS_ARCHIVED &&
    tracker.status === TRACKER_STATUS_ACTIVE
  ) {
    // If the user is trying to un-archive a tracker we check to see if they have enough credits
    yield put(payGateCheckAction());

    const { type } = yield take([
      PAYMENT_GATE_ALLOW_ACTION,
      PAYMENT_GATE_REJECT_ACTION,
    ]);
    if (type === PAYMENT_GATE_REJECT_ACTION) {
      yield put({ type: TRACKER_UPDATE_FAILURE_ACTION });
      return 0;
    }
  }

  tracker.updatedAt = moment().format();
  tracker.reviewCycle.updatedAt = tracker.updatedAt;

  yield put({
    type: SAVE_LOCAL_TRACKER,
    payload: tracker,
  });

  // Signal Complete
  yield put({
    type: TRACKER_UPDATE_SUCCESS_ACTION,
    payload: tracker,
  });
}

function* handleDeleteTrackerRequest(action) {
  // Persist Tracker to Local Data Store
  yield put({
    type: REMOVE_LOCAL_TRACKER,
    payload: action.payload,
  });

  // Signal Complete
  yield put({
    type: TRACKER_DELETE_SUCCESS_ACTION,
    payload: action.payload,
  });

  yield put(setDesiredRouteAction(ROUTES.HOME));

  yield put(
    addNotificationAction(
      getSystemNotificationStruct({
        message: "Tracker Removed.",
        level: "success",
      })
    )
  );
}

function* handleTrackerDataLoadRequest(action) {
  // Persist Tracker to Local Data Store
  yield put({
    type: SET_LOCAL_TRACKERS,
    payload: action.payload,
  });

  // Signal Complete
  yield put({ type: TRACKER_DATA_LOAD_SUCCESS_ACTION });
}

function* handleLoadApplicationDataRequest() {
  // Perform Application Data Load
  const trackersState = yield select(store => store.trackers);

  // Call the Util Local Storage loadState function which pulls data
  // from the local storage if cached there, if not it pulls from the disk
  const trackers = yield loadFromStorage("trackers", trackersState);
  
  yield put(setLocalTrackersAction(trackers));
}

function* handleStateChange() {
  const type = "trackers";

  // Select data from the store
  const data = yield select((store) => store[type]);
  const dataString = JSON.stringify(data);

  // As well as in the local storage.
  localStorage.setItem(type, dataString);
}

function* handleApplicationDataClear() {
  yield put(setLocalTrackersAction([]));
  yield put(updateTrackerIndexAction(TrackerIndexStruct));
}

function* handleFullSyncRequest() {
  const user = yield select((store) => store.user);

  yield put(setProcessingAction("Fetching trackers from Formigio Cloud"));

  // Pull Trackers down from cloud
  const remoteTrackers = yield fetchRemoteTrackers(user.id);

  const [valid, migrate] = evaluateTrackers(remoteTrackers);
  
  // Set Local Trackers
  yield put(setLocalTrackersAction(valid));
  
  if (migrate.length) {
    yield put(setLoadingAction('Updating data for the latest version...'));
    for (const tracker of migrate) {
      yield put(migrateTrackerAction(tracker));
      yield take(TRACKER_MIGRATE_SUCCESS_ACTION);
    }
    yield put(setLoadingAction(''));
  }

  const migration = yield fetchMigration(user.id, 'training_tracker_1.0');

  if (!migration.completed) {
    yield put(createTrackerFromScaffoldAction({key: 'trainingTracker'}));

    yield saveMigration(user.id, 'training_tracker_1.0');
  }


  yield put({ type: TRACKER_FULL_SYNC_SUCCESS_ACTION });
}

function* handleQuickSyncRequest() {
  const user = yield select((store) => store.user);
  let trackerSyncIndex = {};
  const localTrackers = yield select((store) => store.trackers);

  yield put(setProcessingAction("Checking for tracker changes..."));

  if (localTrackers.length) {
    localTrackers.map((tracker) => {
      trackerSyncIndex[tracker.id] = { local: tracker };
      return true;
    });
  }

  // Push and Pull Trackers
  const remoteTrackers = yield fetchRemoteTrackers(user.id);

  if (remoteTrackers.length) {
    remoteTrackers.map((tracker) => {
      if (trackerSyncIndex[tracker.id])
        trackerSyncIndex[tracker.id].remote = tracker;
      else trackerSyncIndex[tracker.id] = { remote: tracker };
      return true;
    });
  }

  if (Object.keys(trackerSyncIndex).length) {
    let localTrackersToPush = [];
    let remoteTrackersToPull = [];

    Object.values(trackerSyncIndex).map((trackerToSync) => {
      const { local, remote } = trackerToSync;
      if (local && !remote && local.status === TRACKER_STATUS_ACTIVE)
        localTrackersToPush.push(local);
      if (!local && remote) remoteTrackersToPull.push(remote);
      if (local && remote) {
        if (
          moment(local.updatedAt).isAfter(remote.updatedAt) &&
          local.status === TRACKER_STATUS_ACTIVE
        )
          localTrackersToPush.push(local);
        else if (moment(remote.updatedAt).isAfter(local.updatedAt))
          remoteTrackersToPull.push(remote);
      }
      return true;
    });

    yield all(
      remoteTrackersToPull.map((tracker) =>
        put(upsertLocalTrackerAction(tracker))
      )
    );

    yield all(
      localTrackersToPush.map((tracker) =>
        put(
          addSyncEventAction(
            getSyncEventStruct({
              action: TRACKER_UPSERT_REMOTE_ACTION,
              success: TRACKER_UPSERT_REMOTE_SUCCESS_ACTION,
              data: tracker,
            })
          )
        )
      )
    );

    const trackerMilestones = localTrackersToPush.map(
      (tracker) => tracker.milestones
    );

    // Clean up for memory
    localTrackersToPush = [];
    remoteTrackersToPull = [];

    if (trackerMilestones.length) {
      const localMilestones = trackerMilestones.reduce((arr, list) =>
        arr.concat(list)
      );

      if (localMilestones && localMilestones.length)
        yield all(
          localMilestones.map((milestone) =>
            put(
              addSyncEventAction(
                getSyncEventStruct({
                  action: MILESTONE_UPSERT_REMOTE_ACTION,
                  success: MILESTONE_UPSERT_REMOTE_SUCCESS_ACTION,
                  data: milestone,
                })
              )
            )
          )
        );
    }
  }

  yield put(syncAllSyncEventsAction());
  yield take(SYNC_EVENT_SYNC_SUCCESS_ACTION);

  // Clean up for memory
  trackerSyncIndex = {};
  yield put({ type: TRACKER_QUICK_SYNC_SUCCESS_ACTION });
  yield put(setProcessingAction(""));
}

function* handleSearchRequest({ payload: term }) {
  const user = yield select((store) => store.user);
  const searchResults = yield searchRemoteTrackers(user.id, term);
  yield all(
    searchResults.map((tracker) =>
      put({
        type: ADD_LOCAL_TRACKER,
        payload: tracker,
      })
    )
  );
}

function* handleMigrationRequest({ payload: tracker }) {
  const user = yield select(store => store.user);
  const migratedTracker = migrateTracker(tracker, user);
  yield put(saveTrackerAction(migratedTracker));
  const { payload: savedTracker } = yield take(TRACKER_UPSERT_REMOTE_SUCCESS_ACTION);
  yield put({ type: TRACKER_MIGRATE_SUCCESS_ACTION, payload: savedTracker });
}

function* handleCreateFromTrackerScaffold({ payload: scaffold }) {
  const template = trackerTemplateScaffolds[scaffold.key] || scaffold;

  yield put(setLoadingAction('Cloning Tracker...'));

  let successSeq = 1;
  let planSeq = 1;
  let metricSeq = 1;

  yield put(addTrackerAction({
    ...template,
    plans: template.plans.map(plan => getPlanStruct({...plan, seq: planSeq++})),
    success: template.success.map(item => getSuccessStruct({item, seq: successSeq++})),
    metrics: template.metrics.map(metric => getMetricStruct({type: metric.type, item: metric.item, seq: metricSeq++})),
    reviewCycle: {
      ...template.reviewCycle,
      lastReviewedDate: moment().subtract(2, 'days').format(),
      startReviewDate: moment().subtract(2, 'days').format(),
    },
  }));
  const { payload: savedTracker } = yield take(TRACKER_UPSERT_REMOTE_SUCCESS_ACTION);

  yield put({ type: SET_ACTIVE_TRACKER, payload: savedTracker });

  yield put(setLoadingAction('Copying all the other good stuff...'));

  for (const [index, plan] of template.plans.entries()) {
    const trackerPlan = savedTracker.plans[index];
    for (const milestone of plan.milestones) {
      let actionItemSeq = 1;
      yield put(addMilestoneAction({
        ...milestone,
        planApn: trackerPlan.apn,
        actionItems: milestone.actionItems && milestone.actionItems.map(action => getActionItemStruct({action, seq: actionItemSeq++}))
      }, savedTracker));
      const { payload: newMilestone } = yield take(MILESTONE_UPSERT_REMOTE_SUCCESS_ACTION);
      savedTracker.plans[index].milestoneIds.push(newMilestone.id);
    }
  }

  yield put(saveTrackerAction({
    ...savedTracker
  }));
  const { payload: finalTracker } = yield take(TRACKER_UPSERT_REMOTE_SUCCESS_ACTION);

  yield put(setLoadingAction(''));

  yield put({ type: TRACKER_CREATE_FROM_SCAFFOLD_SUCCESS_ACTION, payload: finalTracker });
}

function* handleAfterSyncCleanup() {
  const localTrackers = yield select((store) => store.trackers);
  const archivedTrackers = localTrackers.filter(
    (tracker) => tracker.status === TRACKER_STATUS_ARCHIVED
  );
  if (archivedTrackers.length)
    yield all(
      archivedTrackers.map((tracker) =>
        put({ type: REMOVE_LOCAL_TRACKER, payload: tracker })
      )
    );
}

export default function* trackerSaga() {
  // Handle the data request
  yield takeEvery(TRACKER_SEARCH_REQUEST_ACTION, handleSearchRequest);
  yield takeEvery(TRACKER_CREATE_REQUEST_ACTION, handleCreateTrackerRequest);
  yield takeEvery(TRACKER_UPDATE_REQUEST_ACTION, handleUpdateTrackerRequest);
  yield takeEvery(TRACKER_DELETE_REQUEST_ACTION, handleDeleteTrackerRequest);
  yield takeLatest(TRACKER_COPY_REQUEST_ACTION, handleCopyTrackerRequest);
  yield takeEvery(TRACKER_MIGRATE_REQUEST_ACTION, handleMigrationRequest);
  yield takeLatest(
    TRACKER_DATA_LOAD_REQUEST_ACTION,
    handleTrackerDataLoadRequest
  );
  yield takeLatest(
    APPLICATION_DATA_REQUEST_ACTION,
    handleLoadApplicationDataRequest
  );
  yield takeLatest(
    APPLICATION_DATA_CLEAR_REQUEST_ACTION,
    handleApplicationDataClear
  );
  yield takeLatest(TRACKER_FULL_SYNC_REQUEST_ACTION, handleFullSyncRequest);
  yield takeLatest(TRACKER_QUICK_SYNC_REQUEST_ACTION, handleQuickSyncRequest);
  yield takeLatest(TRACKER_FULL_SYNC_SUCCESS_ACTION, handleAfterSyncCleanup);

  // Push tracker to storage
  yield takeLatest(TRACKER_CREATE_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(TRACKER_UPDATE_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(UPSERT_LOCAL_TRACKER, handleStateChange);
  yield takeLatest(TRACKER_DELETE_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(TRACKER_COPY_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(TRACKER_DATA_LOAD_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(TRACKER_CREATE_FROM_SCAFFOLD_REQUEST_ACTION, handleCreateFromTrackerScaffold);
}
