import moment from 'moment';
import { parseApn } from '../utils/apn/v1';
import { stringifyApn as stringifyApnV2 } from '../utils/apn/v2';

export const ChecklistStruct = {
  schemaVersion: '1.1.0',
  ownerApn: '',
  apn: '',
  id: '',
  name: '',
  description: '',
  items: [],
  status: 'active', // active archived
  createdAt: '',
  updatedAt: ''
};

export const ChecklistItemStruct = {
  schemaVersion: '1.0.0',
  seq: 0,
  text: '',
  checked: false
};

export const getChecklistItemStruct = (item = ChecklistItemStruct) =>
  Object.assign({}, ChecklistItemStruct, item);

export const getChecklistStruct = (list = ChecklistStruct) =>
  Object.assign({}, ChecklistStruct, list, {
    items: [...(list.items || [])]
  });

export const migrateChecklists = (checklists, user) => {
  const { schemaVersion } = ChecklistStruct;

  return checklists.map(checklist => {
    if (checklist.schemaVersion !== schemaVersion) {
      return migrateChecklist(checklist, schemaVersion, user);
    }
    return checklist;
  });
};

export const migrateChecklist = (checklist, toVersion, user) => {
  const versions = ['undefined', '1.0.0', '1.1.0'];
  const newChecklist = getChecklistStruct(checklist);

  if (!checklist.schemaVersion) newChecklist.schemaVersion = 'undefined';

  while (newChecklist.schemaVersion !== toVersion) {
    const whereAreWeNow = versions.indexOf(newChecklist.schemaVersion);
    const whereAreWeGoing = versions.indexOf(toVersion);

    let targetVersion = toVersion;

    if (whereAreWeGoing - whereAreWeNow > 1)
      targetVersion = versions[whereAreWeNow + 1];

    // Version transitions
    if (
      `${newChecklist.schemaVersion}_${targetVersion}` === 'undefined_1.0.0'
    ) {
      // We don't need to do anything for this version
    } else if (
      `${newChecklist.schemaVersion}_${targetVersion}` === '1.0.0_1.1.0'
    ) {
      // Update APN
      newChecklist.ownerApn = stringifyApnV2({
        userId: user.id
      });
      if (checklist.milestoneApn) {
        const { trackerId, milestoneId } = parseApn(checklist.milestoneApn);
        newChecklist.apn = stringifyApnV2({
          userId: user.id,
          trackerId,
          milestoneId
        });
        delete newChecklist.milestoneApn;
      }
      newChecklist.createdAt = moment(checklist.createdAt).format();
      newChecklist.updatedAt = moment(checklist.updatedAt).format();
    }

    // Version Up the Tracker Schema
    newChecklist.schemaVersion = targetVersion;
  }

  return getChecklistStruct(newChecklist);
};
