import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { getLearnItemHeadingStruct } from '../../../structs/learn';

export const style = () => ({
  input: {
    color: '#393939',
    flex: 1,
    width: '100%'
  },
  textField: {
    fontSize: 12,
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    width: '100%'
  }
});

class LearnItemHeading extends Component {
  state = {
    heading: getLearnItemHeadingStruct(),
    edit: false
  };

  componentDidMount() {
    const { item } = this.props;
    const { heading } = this.state;

    if (!item.text) {
      this.deferredCommands.push(() => {
        this.setState({ edit: true });
      });
    }

    if (JSON.stringify(item) !== JSON.stringify(heading)) {
      this.hydrateState(item);
    }
  }

  componentDidUpdate() {
    this.checkDeferredCommands();
  }

  // List of commands to run after render.
  deferredCommands = [];

  /*
   *
   * The Deferred Commands model is to allow us to run functions once the
   * state has been set.
   * This is useful when we want to persist data via a redux action, or run
   * multiple steps to record progress.
   *
   */
  checkDeferredCommands = () => {
    if (!this.deferredCommands.length) return;

    this.deferredCommands = this.deferredCommands.filter(command => {
      command();
      return false;
    });
  };

  hydrateState = item => {
    this.setState({ heading: getLearnItemHeadingStruct(item) });
  };

  handleSubmit = () => {
    const { heading } = this.state;
    const { onChange } = this.props;
    this.deferredCommands.push(() => {
      onChange(heading);
    });
    this.setState({ edit: false });
  };

  handleDelete = () => {
    const { heading } = this.state;
    const { onDelete } = this.props;
    onDelete(heading);
  };

  handleChange = event => {
    const { heading } = this.state;
    heading[event.target.name] = event.target.value;
    this.setState({ heading });
  };

  handleClick = () => {
    this.setState({ edit: true });
  };

  handleCancel = () => {
    this.setState({ edit: false });
  };

  render() {
    const { classes, item } = this.props;
    const { heading, edit } = this.state;

    return (
      <React.Fragment>
        <ListItem button onClick={this.handleClick}>
          <Typography variant="h5">{item.text}</Typography>
        </ListItem>
        <Dialog open={edit}>
          <DialogContent>
            <TextField
              id="text"
              name="text"
              value={heading.text === ' ' ? '' : heading.text}
              placeholder="Header Text"
              required
              margin="normal"
              className={classes.textField}
              InputProps={{
                className: classes.input
              }}
              onChange={this.handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              style={{ marginRight: 'auto' }}
              color="secondary"
              onClick={this.handleDelete}
            >
              Delete
            </Button>
            <Button color="secondary" onClick={this.handleCancel}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

LearnItemHeading.propTypes = {
  classes: PropTypes.any.isRequired,
  item: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default withStyles(style)(LearnItemHeading);
