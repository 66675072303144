import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import { getMetricValueStruct } from '../../../../structs/measure';
import {
  convertPickerDateToDate,
  formatDateForPicker
} from '../../../../utils/date';

export const style = () => ({
  input: {
    color: '#393939',
    flex: 1,
    width: '100%'
  },
  textField: {
    fontSize: 12,
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    width: '100%'
  }
});

class MetricNumberValue extends Component {
  state = {
    value: getMetricValueStruct(),
    edit: false
  };

  componentDidMount() {
    const { item } = this.props;
    const { value } = this.state;

    if (!item.value) {
      this.deferredCommands.push(() => {
        this.setState({ edit: true });
      });
    }

    if (JSON.stringify(item) !== JSON.stringify(value)) {
      this.hydrateState(item);
    }
  }

  componentDidUpdate() {
    this.checkDeferredCommands();
  }

  // List of commands to run after render.
  deferredCommands = [];

  /*
   *
   * The Deferred Commands model is to allow us to run functions once the
   * state has been set.
   * This is useful when we want to persist data via a redux action, or run
   * multiple steps to record progress.
   *
   */
  checkDeferredCommands = () => {
    if (!this.deferredCommands.length) return;

    this.deferredCommands = this.deferredCommands.filter(command => {
      command();
      return false;
    });
  };

  hydrateState = item => {
    this.setState({ value: getMetricValueStruct(item) });
  };

  handleSubmit = () => {
    const { value } = this.state;
    const { onChange } = this.props;
    this.deferredCommands.push(() => {
      onChange(value);
    });
    this.handleCancel();
  };

  handleDelete = () => {
    const { value } = this.state;
    const { onDelete } = this.props;
    onDelete(value);
  };

  handleChange = event => {
    const { value } = this.state;
    value[event.target.name] = event.target.value;
    if (event.target.name === 'datetime') {
      value.datetime = convertPickerDateToDate(value.datetime).toISOString();
    }

    this.setState({ value });
  };

  handleClick = () => {
    this.setState({ edit: true });
  };

  handleCancel = () => {
    this.setState({ edit: false });
  };

  render() {
    const { classes, item, readOnly } = this.props;
    const { value, edit } = this.state;

    return (
      <React.Fragment>
        <ListItem button disabled={readOnly} onClick={this.handleClick}>
          <ListItemText
            primary={item.value}
            secondary={new Date(item.datetime).toDateString()}
          />
        </ListItem>
        <Dialog open={edit}>
          <DialogContent>
            <TextField
              id="value"
              name="value"
              value={value.value === ' ' ? '' : value.value}
              placeholder="Value"
              margin="normal"
              className={classes.textField}
              InputProps={{
                className: classes.input
              }}
              onChange={this.handleChange}
            />
            <TextField
              type="date"
              name="datetime"
              label="Date"
              defaultValue={formatDateForPicker(new Date())}
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              style={{ marginRight: 'auto' }}
              color="secondary"
              onClick={this.handleDelete}
            >
              Delete
            </Button>
            <Button color="secondary" onClick={this.handleCancel}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

MetricNumberValue.propTypes = {
  classes: PropTypes.any.isRequired,
  item: PropTypes.any.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default withStyles(style)(MetricNumberValue);
