module.exports = () => ({
  container: {
    width: 'calc(100vw - 68px)',
    display: 'flex',
    flexDirection: 'row',
    marginTop: 23
  },
  containerCenter: {
    width: '100%'
  },
  contentContainer: {
    marginRight: '1em',
    display: 'flex'
  },
  dreamListLabel: {
    marginTop: 20,
    fontSize: 18,
    fontWeight: '300',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: 'rgba(57, 57, 57, 0.67)'
  },
  button: {
    marginTop: 45,
    marginBottom: '2em',
    width: 140,
    minHeight: 30,
    padding: 0,
    borderRadius: 0,
    backgroundColor: '#212b50',
    letterSpacing: 0.6,
    fontSize: 12,
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    marginRight: '20px'
  },
  buttonOutline: {
    marginTop: 45,
    marginBottom: '2em',
    width: 140,
    minHeight: 30,
    padding: 0,
    borderRadius: 0,
    marginRight: '20px',
    color: '#212b50',
    backgroundColor: '#e0e0e0',
    fontSize: 12,
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    borderColor: '#212b50 !important',
    borderWidth: 1
  },
  buttonWrap: {
    marginBottom: '1em',
    marginTop: '1em'
  },
  input: {
    color: '#393939',
    flex: 1
  },
  textField: {
    fontSize: 12,
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal'
  },
  logo: {
    width: 35,
    height: 35,
    marginLeft: '1em'
  }
});
