import {
  SET_USER,
  LOAD_USER_FROM_DISK,
  SAVE_USER_NOTES
} from '../actions/user';
import {getUserStruct} from '../../structs/user';

export default function userReducer(
  state = getUserStruct(),
  action
) {

  switch (action.type) {
    case SET_USER:
      return getUserStruct({...state, ...action.payload});

    case LOAD_USER_FROM_DISK:
      return getUserStruct(action.payload);

    case SAVE_USER_NOTES:
      return {
        ...state,
        notes: action.payload
      };

    default:
      return state;
  }
}
