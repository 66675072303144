/* *
 * On Tracker entity:
 *   - OwnerAPN - apn:user:<User UUID> (in the future we will use teams as owners as well)
 * On Milestone/Journal entity:
 *   - TrackerAPN - apn:user:<User UUID>:tracker:<Tracker UUID>
 * On Commitment entity:
 *   - MilestoneAPN - apn:user:<User UUID>:tracker:<Tracker UUID>:milestone:<Milestone UUID>
 * */

export const stringifyApn = apn => {
  const { teamId, userId, trackerId, milestoneId, actionItemId } = apn;

  let ownerType = 'user';
  let ownerId = userId;

  if (teamId) {
    ownerType = 'team';
    ownerId = teamId;
  }
  
  if (actionItemId) return `apn:${ownerType}:${ownerId}:tracker:${trackerId}:milestone:${milestoneId}:action:${actionItemId}`;
  
  if (milestoneId) return `apn:${ownerType}:${ownerId}:tracker:${trackerId}:milestone:${milestoneId}`;
  
  if (trackerId) return `apn:${ownerType}:${ownerId}:tracker:${trackerId}`;
  
  return `apn:${ownerType}:${ownerId}`;
};

export const parseApn = apn => {
  const [
    apnType,
    ownerType,
    ownerId,
    trackerLabel,
    trackerId,
    milestoneLabel,
    milestoneId,
    actionLabel,
    actionItemId
  ] = apn.split(':');
  let userId;
  let teamId;

  if (ownerType === 'team') teamId = ownerId;
  else userId = ownerId;

  return {
    apnType,
    teamId,
    userId,
    trackerLabel,
    trackerId,
    milestoneLabel,
    milestoneId,
    actionLabel,
    actionItemId
  };
};

export const getTrackerApn = (tracker = { ownerApn: '', id: '' }) => {
  const { teamId, userId } = parseApn(tracker.ownerApn);
  return stringifyApn({ teamId, userId, trackerId: tracker.id });
};

export const getMilestoneApn = (milestone = { trackerApn: '', id: '' }) => {
  const { teamId, userId, trackerId } = parseApn(milestone.trackerApn);
  return stringifyApn({ teamId, userId, trackerId, milestoneId: milestone.id });
};

export const getActionItemApn = (actionItem = { milestoneApn: '', apnPart: '' }) => {
  const { teamId, userId, trackerId, milestoneId } = parseApn(actionItem.milestoneApn);
  return stringifyApn({ teamId, userId, trackerId, milestoneId, actionItemId: actionItem.apnPart });
};

export const getCommitmentApn = (commitment) => {
  return `${stringifyApn({ userId: commitment.userId })}:commitment:${commitment.id}`;
}