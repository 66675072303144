import {
  COMMIT_INVITE_REMOVE_LOCAL_ACTION,
  COMMIT_INVITE_SET_ACTION,
  COMMIT_INVITE_UPSERT_LOCAL_ACTION
} from "../actions/commitInvite";

export default function commitInviteReducer(state = [], action) {
  switch (action.type) {

    case COMMIT_INVITE_UPSERT_LOCAL_ACTION:
      if (!action.payload) return state;
      if (
        state.filter(item => action.payload.id === item.id)
          .length
      )
        return state.map(item => {
          if (action.payload.id === item.id) {
            return action.payload;
          }
          return item;
        });

      return [...state, action.payload];

    case COMMIT_INVITE_REMOVE_LOCAL_ACTION:
      if (!action.payload) return state;
      return state.filter(item => (action.payload.id !== item.id));

    case COMMIT_INVITE_SET_ACTION:
      return action.payload;

    default:
      return state;
  }
}
