import { all, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {fetchContactByEmail, fetchContactsByUser, upsertRemoteContact} from "../../utils/appSync/contact";
import {
  CONTACT_AFTER_SHARE_SUCCESS_ACTION,
  CONTACT_CREATE_REQUEST_ACTION, CONTACT_CREATE_SUCCESS_ACTION,
  createContactAction,
  upsertLocalContactAction
} from "../actions/contacts";
import {stringifyApn} from "../../utils/apn/v2";
import {TRACKER_SHARE_ACCEPT_SUCCESS_ACTION} from "../actions/trackerShares";
import {APPLICATION_FULL_SYNC_REQUEST_ACTION} from "../actions/appSync";

function* handleCreateContactRequest({ payload: contact }) {
  yield upsertRemoteContact(contact);
  yield put({ type: CONTACT_CREATE_SUCCESS_ACTION });
}

function* handleTrackerShareAcceptSuccess({ payload: trackerShare }) {
  // When a Tracker Share is accepted, we add Contacts Both Ways
  const user = yield select(store => store.user);
  const { id: userId, attributes: { email, familyName, givenName }} = user;
  const userApn = stringifyApn({ userId });

  const { ownerApn, ownerName, ownerEmailAddress } = trackerShare;

  // Create Friend on the Tracker Owner's side
  const ownersFriend = yield fetchContactByEmail(ownerApn, trackerShare.emailAddress);
  if (!ownersFriend) {
    yield put(createContactAction({
      userApn: ownerApn,
      friendApn: userApn,
      friendName: `${givenName} ${familyName}`,
      friendEmail: email
    }));
  }

  // Create Friend on User's Side
  const usersFriend = yield fetchContactByEmail(userApn, ownerEmailAddress);
  if (!usersFriend) {
    yield put(createContactAction({
      userApn: userApn,
      friendApn: ownerApn,
      friendName: ownerName,
      friendEmail: ownerEmailAddress
    }));
  }

  yield put({ type: CONTACT_AFTER_SHARE_SUCCESS_ACTION });
}

function* handleFetchUserContactsRequest() {
  const user = yield select(store => store.user);
  const { id: userId } = user;
  const userApn = stringifyApn({ userId });
  const contacts = yield fetchContactsByUser(userApn);

  yield(all(contacts.map(contact => put(upsertLocalContactAction(contact)))));
}

export default function* contactSaga() {
  // Handle Actions
  yield takeEvery(CONTACT_CREATE_REQUEST_ACTION, handleCreateContactRequest);
  yield takeLatest(APPLICATION_FULL_SYNC_REQUEST_ACTION, handleFetchUserContactsRequest);
  yield takeEvery(TRACKER_SHARE_ACCEPT_SUCCESS_ACTION, handleTrackerShareAcceptSuccess);
}
