/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCommitmentInvite = /* GraphQL */ `
  query GetCommitmentInvite($toUserApn: String!, $id: ID!) {
    getCommitmentInvite(toUserApn: $toUserApn, id: $id) {
      id
      status
      description
      fromUserName
      fromUserApn
      toUserName
      toUserApn
      contextApn
      contextDescription
      trackerId
      notes
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const listCommitmentInvites = /* GraphQL */ `
  query ListCommitmentInvites(
    $toUserApn: String
    $id: ModelIDKeyConditionInput
    $filter: ModelCommitmentInviteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCommitmentInvites(
      toUserApn: $toUserApn
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        status
        description
        fromUserName
        fromUserApn
        toUserName
        toUserApn
        contextApn
        contextDescription
        trackerId
        notes
        createdAt
        updatedAt
        schemaVersion
      }
      nextToken
    }
  }
`;
export const getJournal = /* GraphQL */ `
  query GetJournal($ownerApn: String!, $id: String!) {
    getJournal(ownerApn: $ownerApn, id: $id) {
      id
      ownerApn
      trackerId
      datetime
      entry
      schemaVersion
      createdBy
      updatedAt
      createdAt
    }
  }
`;
export const listJournals = /* GraphQL */ `
  query ListJournals(
    $ownerApn: String
    $id: ModelStringKeyConditionInput
    $filter: ModelJournalFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listJournals(
      ownerApn: $ownerApn
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ownerApn
        trackerId
        datetime
        entry
        schemaVersion
        createdBy
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const getJournalv2 = /* GraphQL */ `
  query GetJournalv2($ownerApn: String!, $id: String!) {
    getJournalv2(ownerApn: $ownerApn, id: $id) {
      id
      ownerApn
      trackerId
      datetime
      entry
      schemaVersion
      createdBy
      updatedAt
      createdAt
    }
  }
`;
export const listJournalv2s = /* GraphQL */ `
  query ListJournalv2s(
    $ownerApn: String
    $id: ModelStringKeyConditionInput
    $filter: ModelJournalv2FilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listJournalv2s(
      ownerApn: $ownerApn
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ownerApn
        trackerId
        datetime
        entry
        schemaVersion
        createdBy
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const getCommitmentv3 = /* GraphQL */ `
  query GetCommitmentv3($userId: String!, $id: String!) {
    getCommitmentv3(userId: $userId, id: $id) {
      id
      userId
      milestoneApn
      milestoneId
      trackerId
      contextApn
      contextDescription
      description
      notes
      date
      originalDate
      createdAt
      updatedAt
      highlightColor
      timeCommitment
      status
      role {
        name
        color
      }
      trays
      reminders {
        email
        sms
      }
      schemaVersion
    }
  }
`;
export const listCommitmentv3s = /* GraphQL */ `
  query ListCommitmentv3s(
    $userId: String
    $id: ModelStringKeyConditionInput
    $filter: ModelCommitmentv3FilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCommitmentv3s(
      userId: $userId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        milestoneApn
        milestoneId
        trackerId
        contextApn
        contextDescription
        description
        notes
        date
        originalDate
        createdAt
        updatedAt
        highlightColor
        timeCommitment
        status
        role {
          name
          color
        }
        trays
        reminders {
          email
          sms
        }
        schemaVersion
      }
      nextToken
    }
  }
`;
export const getMilestonev3 = /* GraphQL */ `
  query GetMilestonev3($id: ID!) {
    getMilestonev3(id: $id) {
      id
      trackerApn
      trackerId
      description
      notes
      reached
      seq
      status
      targetDate
      actionItems {
        apnPart
        action
        seq
        checked
        schemaVersion
      }
      planApn
      commitments {
        items {
          id
          userId
          milestoneApn
          milestoneId
          trackerId
          contextApn
          contextDescription
          description
          notes
          date
          originalDate
          createdAt
          updatedAt
          highlightColor
          timeCommitment
          status
          role {
            name
            color
          }
          trays
          reminders {
            email
            sms
          }
          schemaVersion
        }
        nextToken
      }
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const listMilestonev3s = /* GraphQL */ `
  query ListMilestonev3s(
    $filter: ModelMilestonev3FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMilestonev3s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        trackerApn
        trackerId
        description
        notes
        reached
        seq
        status
        targetDate
        actionItems {
          apnPart
          action
          seq
          checked
          schemaVersion
        }
        planApn
        commitments {
          items {
            id
            userId
            milestoneApn
            milestoneId
            trackerId
            contextApn
            contextDescription
            description
            notes
            date
            originalDate
            createdAt
            updatedAt
            highlightColor
            timeCommitment
            status
            role {
              name
              color
            }
            trays
            reminders {
              email
              sms
            }
            schemaVersion
          }
          nextToken
        }
        createdAt
        updatedAt
        schemaVersion
      }
      nextToken
    }
  }
`;
export const getTrackerv3 = /* GraphQL */ `
  query GetTrackerv3($id: String!, $ownerApn: String!) {
    getTrackerv3(id: $id, ownerApn: $ownerApn) {
      id
      ownerApn
      dream
      learn {
        id
        seq
        type
        item {
          title
          text
          pageId
          content
          url
          question
          answer
          schemaVersion
        }
        schemaVersion
      }
      success {
        apnPart
        item
        seq
        checked
        schemaVersion
      }
      metrics {
        id
        seq
        type
        item {
          name
          values {
            id
            metric
            value
            datetime
            schemaVersion
          }
          schemaVersion
        }
        schemaVersion
      }
      milestones {
        items {
          id
          trackerApn
          trackerId
          description
          notes
          reached
          seq
          status
          targetDate
          actionItems {
            apnPart
            action
            seq
            checked
            schemaVersion
          }
          planApn
          commitments {
            items {
              id
              userId
              milestoneApn
              milestoneId
              trackerId
              contextApn
              contextDescription
              description
              notes
              date
              originalDate
              createdAt
              updatedAt
              highlightColor
              timeCommitment
              status
              role {
                name
                color
              }
              trays
              reminders {
                email
                sms
              }
              schemaVersion
            }
            nextToken
          }
          createdAt
          updatedAt
          schemaVersion
        }
        nextToken
      }
      plans {
        apn
        title
        seq
        milestoneIds
        open
      }
      notes
      reviewCycle {
        frequency
        interval
        positions
        lastReviewedDate
        startReviewDate
        updatedAt
        schemaVersion
      }
      status
      createdAt
      updatedAt
      role {
        name
        color
      }
      schemaVersion
    }
  }
`;
export const listTrackerv3s = /* GraphQL */ `
  query ListTrackerv3s(
    $id: String
    $ownerApn: ModelStringKeyConditionInput
    $filter: ModelTrackerv3FilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTrackerv3s(
      id: $id
      ownerApn: $ownerApn
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ownerApn
        dream
        learn {
          id
          seq
          type
          item {
            title
            text
            pageId
            content
            url
            question
            answer
            schemaVersion
          }
          schemaVersion
        }
        success {
          apnPart
          item
          seq
          checked
          schemaVersion
        }
        metrics {
          id
          seq
          type
          item {
            name
            values {
              id
              metric
              value
              datetime
              schemaVersion
            }
            schemaVersion
          }
          schemaVersion
        }
        milestones {
          items {
            id
            trackerApn
            trackerId
            description
            notes
            reached
            seq
            status
            targetDate
            actionItems {
              apnPart
              action
              seq
              checked
              schemaVersion
            }
            planApn
            commitments {
              items {
                id
                userId
                milestoneApn
                milestoneId
                trackerId
                contextApn
                contextDescription
                description
                notes
                date
                originalDate
                createdAt
                updatedAt
                highlightColor
                timeCommitment
                status
                role {
                  name
                  color
                }
                trays
                reminders {
                  email
                  sms
                }
                schemaVersion
              }
              nextToken
            }
            createdAt
            updatedAt
            schemaVersion
          }
          nextToken
        }
        plans {
          apn
          title
          seq
          milestoneIds
          open
        }
        notes
        reviewCycle {
          frequency
          interval
          positions
          lastReviewedDate
          startReviewDate
          updatedAt
          schemaVersion
        }
        status
        createdAt
        updatedAt
        role {
          name
          color
        }
        schemaVersion
      }
      nextToken
    }
  }
`;
export const getChangeLogv2 = /* GraphQL */ `
  query GetChangeLogv2($changeId: String!, $ownerApn: String!) {
    getChangeLogv2(changeId: $changeId, ownerApn: $ownerApn) {
      ownerApn
      timestamp
      changeId
      action
      data
      source
      createdAt
      updatedAt
    }
  }
`;
export const listChangeLogv2s = /* GraphQL */ `
  query ListChangeLogv2s(
    $changeId: String
    $ownerApn: ModelStringKeyConditionInput
    $filter: ModelChangeLogv2FilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChangeLogv2s(
      changeId: $changeId
      ownerApn: $ownerApn
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ownerApn
        timestamp
        changeId
        action
        data
        source
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTour = /* GraphQL */ `
  query GetTour($ownerApn: String!, $id: String!) {
    getTour(ownerApn: $ownerApn, id: $id) {
      ownerApn
      id
      status
      createdAt
      updatedAt
    }
  }
`;
export const listTours = /* GraphQL */ `
  query ListTours(
    $ownerApn: String
    $id: ModelStringKeyConditionInput
    $filter: ModelTourFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTours(
      ownerApn: $ownerApn
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ownerApn
        id
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChecklist = /* GraphQL */ `
  query GetChecklist($ownerApn: String!, $id: String!) {
    getChecklist(ownerApn: $ownerApn, id: $id) {
      ownerApn
      id
      apn
      name
      description
      items {
        schemaVersion
        seq
        text
        checked
      }
      status
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const listChecklists = /* GraphQL */ `
  query ListChecklists(
    $ownerApn: String
    $id: ModelStringKeyConditionInput
    $filter: ModelChecklistFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChecklists(
      ownerApn: $ownerApn
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ownerApn
        id
        apn
        name
        description
        items {
          schemaVersion
          seq
          text
          checked
        }
        status
        createdAt
        updatedAt
        schemaVersion
      }
      nextToken
    }
  }
`;
export const getMembershipStatus = /* GraphQL */ `
  query GetMembershipStatus($id: String!) {
    getMembershipStatus(id: $id) {
      id
      subscriptionLevel
      subscriptionCode
      subscriptionId
      lastLoginTime
      stripeSubscription {
        schemaVersion
        stripeSubscriptionType
        stripeSubscriptionId
        cancelServiceDate
        stripeCurrentPeriodEnd
        cancelAtPeriodEnd
        stripeCancelDate
        updatedAt
      }
      paymentVerified
      schemaVersion
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listMembershipStatuss = /* GraphQL */ `
  query ListMembershipStatuss(
    $id: String
    $filter: ModelMembershipStatusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMembershipStatuss(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        subscriptionLevel
        subscriptionCode
        subscriptionId
        lastLoginTime
        stripeSubscription {
          schemaVersion
          stripeSubscriptionType
          stripeSubscriptionId
          cancelServiceDate
          stripeCurrentPeriodEnd
          cancelAtPeriodEnd
          stripeCancelDate
          updatedAt
        }
        paymentVerified
        schemaVersion
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getFormigioSubscription = /* GraphQL */ `
  query GetFormigioSubscription($id: ID!) {
    getFormigioSubscription(id: $id) {
      id
      name
      label
      description
      stripePlanId
      active
      level
      sort
      price
      code
      createdAt
      updatedAt
    }
  }
`;
export const listFormigioSubscriptions = /* GraphQL */ `
  query ListFormigioSubscriptions(
    $filter: ModelFormigioSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFormigioSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        label
        description
        stripePlanId
        active
        level
        sort
        price
        code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmailPreferences = /* GraphQL */ `
  query GetEmailPreferences($userApn: String!) {
    getEmailPreferences(userApn: $userApn) {
      userApn
      emailAddress
      emailTopics
      unsubscribedTopics
      createdAt
      updatedAt
    }
  }
`;
export const listEmailPreferencess = /* GraphQL */ `
  query ListEmailPreferencess(
    $userApn: String
    $filter: ModelEmailPreferencesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmailPreferencess(
      userApn: $userApn
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userApn
        emailAddress
        emailTopics
        unsubscribedTopics
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmailNotification = /* GraphQL */ `
  query GetEmailNotification($userApn: String!, $id: String!) {
    getEmailNotification(userApn: $userApn, id: $id) {
      id
      userApn
      sendTimestamp
      topic
      email
      summaryText
      payload
      new
      processedTimestamp
      entityApn
      createdAt
      updatedAt
    }
  }
`;
export const listEmailNotifications = /* GraphQL */ `
  query ListEmailNotifications(
    $userApn: String
    $id: ModelStringKeyConditionInput
    $filter: ModelEmailNotificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmailNotifications(
      userApn: $userApn
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userApn
        sendTimestamp
        topic
        email
        summaryText
        payload
        new
        processedTimestamp
        entityApn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTrackerShare = /* GraphQL */ `
  query GetTrackerShare($trackerId: String!, $id: ID!) {
    getTrackerShare(trackerId: $trackerId, id: $id) {
      id
      trackerId
      ownerApn
      ownerEmailAddress
      ownerName
      emailAddress
      userId
      status
      permissionLevel
      notifications
      reviewCycle {
        frequency
        interval
        positions
        lastReviewedDate
        startReviewDate
        updatedAt
        schemaVersion
      }
      role {
        name
        color
      }
      shareDescription
      schemaVersion
      createdAt
      updatedAt
    }
  }
`;
export const listTrackerShares = /* GraphQL */ `
  query ListTrackerShares(
    $trackerId: String
    $id: ModelIDKeyConditionInput
    $filter: ModelTrackerShareFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTrackerShares(
      trackerId: $trackerId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        trackerId
        ownerApn
        ownerEmailAddress
        ownerName
        emailAddress
        userId
        status
        permissionLevel
        notifications
        reviewCycle {
          frequency
          interval
          positions
          lastReviewedDate
          startReviewDate
          updatedAt
          schemaVersion
        }
        role {
          name
          color
        }
        shareDescription
        schemaVersion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTrackerActivity = /* GraphQL */ `
  query GetTrackerActivity($trackerId: String!, $createdAt: AWSDateTime!) {
    getTrackerActivity(trackerId: $trackerId, createdAt: $createdAt) {
      trackerId
      createdAt
      userId
      byUser
      summary
      entityType
      entityId
      schemaVersion
      updatedAt
    }
  }
`;
export const listTrackerActivitys = /* GraphQL */ `
  query ListTrackerActivitys(
    $trackerId: String
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelTrackerActivityFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTrackerActivitys(
      trackerId: $trackerId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        trackerId
        createdAt
        userId
        byUser
        summary
        entityType
        entityId
        schemaVersion
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCommitmentTray = /* GraphQL */ `
  query GetCommitmentTray($ownerApn: String!, $id: String!) {
    getCommitmentTray(ownerApn: $ownerApn, id: $id) {
      schemaVersion
      ownerApn
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const listCommitmentTrays = /* GraphQL */ `
  query ListCommitmentTrays(
    $ownerApn: String
    $id: ModelStringKeyConditionInput
    $filter: ModelCommitmentTrayFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCommitmentTrays(
      ownerApn: $ownerApn
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        schemaVersion
        ownerApn
        id
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMigration = /* GraphQL */ `
  query GetMigration($userId: String!, $migrationKey: String!) {
    getMigration(userId: $userId, migrationKey: $migrationKey) {
      userId
      migrationKey
      completed
      schemaVersion
      createdAt
      updatedAt
    }
  }
`;
export const listMigrations = /* GraphQL */ `
  query ListMigrations(
    $userId: String
    $migrationKey: ModelStringKeyConditionInput
    $filter: ModelMigrationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMigrations(
      userId: $userId
      migrationKey: $migrationKey
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        migrationKey
        completed
        schemaVersion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSMSPreferences = /* GraphQL */ `
  query GetSMSPreferences($userApn: String!) {
    getSMSPreferences(userApn: $userApn) {
      userApn
      number
      verifiedAt
      unsubscribedTopics
      createdAt
      updatedAt
    }
  }
`;
export const listSMSPreferencess = /* GraphQL */ `
  query ListSMSPreferencess(
    $userApn: String
    $filter: ModelSMSPreferencesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSMSPreferencess(
      userApn: $userApn
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userApn
        number
        verifiedAt
        unsubscribedTopics
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSMSNotification = /* GraphQL */ `
  query GetSMSNotification($userApn: String!, $id: String!) {
    getSMSNotification(userApn: $userApn, id: $id) {
      id
      userApn
      sendTimestamp
      topic
      number
      summaryText
      new
      processedTimestamp
      entityApn
      createdAt
      updatedAt
    }
  }
`;
export const listSMSNotifications = /* GraphQL */ `
  query ListSMSNotifications(
    $userApn: String
    $id: ModelStringKeyConditionInput
    $filter: ModelSMSNotificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSMSNotifications(
      userApn: $userApn
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userApn
        sendTimestamp
        topic
        number
        summaryText
        new
        processedTimestamp
        entityApn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($userApn: String!, $id: ID!) {
    getContact(userApn: $userApn, id: $id) {
      id
      userApn
      friendApn
      friendName
      friendEmail
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $userApn: String
    $id: ModelIDKeyConditionInput
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContacts(
      userApn: $userApn
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userApn
        friendApn
        friendName
        friendEmail
        createdAt
        updatedAt
        schemaVersion
      }
      nextToken
    }
  }
`;
export const getTrackerTemplate = /* GraphQL */ `
  query GetTrackerTemplate($ownerApn: String!, $id: ID!) {
    getTrackerTemplate(ownerApn: $ownerApn, id: $id) {
      id
      ownerApn
      name
      description
      scaffold {
        dream
        reviewCycle {
          frequency
          interval
          positions
        }
        success
        learn {
          type
          item {
            title
            content
            url
            question
            answer
          }
        }
        metrics {
          type
          item {
            name
            values {
              value
              datetime
            }
          }
        }
        notes
        plans {
          title
          open
          milestones {
            description
            notes
            actionItems
          }
        }
      }
      variables {
        name
        type
        token
      }
      sourceTrackerId
      schemaVersion
      createdAt
      updatedAt
    }
  }
`;
export const listTrackerTemplates = /* GraphQL */ `
  query ListTrackerTemplates(
    $ownerApn: String
    $id: ModelIDKeyConditionInput
    $filter: ModelTrackerTemplateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTrackerTemplates(
      ownerApn: $ownerApn
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ownerApn
        name
        description
        scaffold {
          dream
          reviewCycle {
            frequency
            interval
            positions
          }
          success
          learn {
            type
            item {
              title
              content
              url
              question
              answer
            }
          }
          metrics {
            type
            item {
              name
              values {
                value
                datetime
              }
            }
          }
          notes
          plans {
            title
            open
            milestones {
              description
              notes
              actionItems
            }
          }
        }
        variables {
          name
          type
          token
        }
        sourceTrackerId
        schemaVersion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const commitmentInvitesByTracker = /* GraphQL */ `
  query CommitmentInvitesByTracker(
    $trackerId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommitmentInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commitmentInvitesByTracker(
      trackerId: $trackerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        description
        fromUserName
        fromUserApn
        toUserName
        toUserApn
        contextApn
        contextDescription
        trackerId
        notes
        createdAt
        updatedAt
        schemaVersion
      }
      nextToken
    }
  }
`;
export const journalv2byTrackerId = /* GraphQL */ `
  query Journalv2byTrackerId(
    $trackerId: String
    $datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJournalv2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    Journalv2byTrackerId(
      trackerId: $trackerId
      datetime: $datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerApn
        trackerId
        datetime
        entry
        schemaVersion
        createdBy
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const fetchCommitmentsByUserv3 = /* GraphQL */ `
  query FetchCommitmentsByUserv3(
    $userId: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommitmentv3FilterInput
    $limit: Int
    $nextToken: String
  ) {
    fetchCommitmentsByUserv3(
      userId: $userId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        milestoneApn
        milestoneId
        trackerId
        contextApn
        contextDescription
        description
        notes
        date
        originalDate
        createdAt
        updatedAt
        highlightColor
        timeCommitment
        status
        role {
          name
          color
        }
        trays
        reminders {
          email
          sms
        }
        schemaVersion
      }
      nextToken
    }
  }
`;
export const fetchCommitmentsByLatestDatev3 = /* GraphQL */ `
  query FetchCommitmentsByLatestDatev3(
    $userId: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommitmentv3FilterInput
    $limit: Int
    $nextToken: String
  ) {
    fetchCommitmentsByLatestDatev3(
      userId: $userId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        milestoneApn
        milestoneId
        trackerId
        contextApn
        contextDescription
        description
        notes
        date
        originalDate
        createdAt
        updatedAt
        highlightColor
        timeCommitment
        status
        role {
          name
          color
        }
        trays
        reminders {
          email
          sms
        }
        schemaVersion
      }
      nextToken
    }
  }
`;
export const trackersByUpdatedDatev3 = /* GraphQL */ `
  query TrackersByUpdatedDatev3(
    $ownerApn: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTrackerv3FilterInput
    $limit: Int
    $nextToken: String
  ) {
    trackersByUpdatedDatev3(
      ownerApn: $ownerApn
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerApn
        dream
        learn {
          id
          seq
          type
          item {
            title
            text
            pageId
            content
            url
            question
            answer
            schemaVersion
          }
          schemaVersion
        }
        success {
          apnPart
          item
          seq
          checked
          schemaVersion
        }
        metrics {
          id
          seq
          type
          item {
            name
            values {
              id
              metric
              value
              datetime
              schemaVersion
            }
            schemaVersion
          }
          schemaVersion
        }
        milestones {
          items {
            id
            trackerApn
            trackerId
            description
            notes
            reached
            seq
            status
            targetDate
            actionItems {
              apnPart
              action
              seq
              checked
              schemaVersion
            }
            planApn
            commitments {
              items {
                id
                userId
                milestoneApn
                milestoneId
                trackerId
                contextApn
                contextDescription
                description
                notes
                date
                originalDate
                createdAt
                updatedAt
                highlightColor
                timeCommitment
                status
                role {
                  name
                  color
                }
                trays
                reminders {
                  email
                  sms
                }
                schemaVersion
              }
              nextToken
            }
            createdAt
            updatedAt
            schemaVersion
          }
          nextToken
        }
        plans {
          apn
          title
          seq
          milestoneIds
          open
        }
        notes
        reviewCycle {
          frequency
          interval
          positions
          lastReviewedDate
          startReviewDate
          updatedAt
          schemaVersion
        }
        status
        createdAt
        updatedAt
        role {
          name
          color
        }
        schemaVersion
      }
      nextToken
    }
  }
`;
export const changeLogsByOwnerv2 = /* GraphQL */ `
  query ChangeLogsByOwnerv2(
    $ownerApn: String
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChangeLogv2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    changeLogsByOwnerv2(
      ownerApn: $ownerApn
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ownerApn
        timestamp
        changeId
        action
        data
        source
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const fetchEmailNotificationByTimestamp = /* GraphQL */ `
  query FetchEmailNotificationByTimestamp(
    $processedTimestamp: String
    $sendTimestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmailNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fetchEmailNotificationByTimestamp(
      processedTimestamp: $processedTimestamp
      sendTimestamp: $sendTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userApn
        sendTimestamp
        topic
        email
        summaryText
        payload
        new
        processedTimestamp
        entityApn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const trackerSharesByUserId = /* GraphQL */ `
  query TrackerSharesByUserId(
    $userId: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTrackerShareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    trackerSharesByUserId(
      userId: $userId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        trackerId
        ownerApn
        ownerEmailAddress
        ownerName
        emailAddress
        userId
        status
        permissionLevel
        notifications
        reviewCycle {
          frequency
          interval
          positions
          lastReviewedDate
          startReviewDate
          updatedAt
          schemaVersion
        }
        role {
          name
          color
        }
        shareDescription
        schemaVersion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const trackerActivityByUserId = /* GraphQL */ `
  query TrackerActivityByUserId(
    $userId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTrackerActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    trackerActivityByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        trackerId
        createdAt
        userId
        byUser
        summary
        entityType
        entityId
        schemaVersion
        updatedAt
      }
      nextToken
    }
  }
`;
export const fetchSMSNotificationByTimestamp = /* GraphQL */ `
  query FetchSMSNotificationByTimestamp(
    $processedTimestamp: String
    $sendTimestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSMSNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fetchSMSNotificationByTimestamp(
      processedTimestamp: $processedTimestamp
      sendTimestamp: $sendTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userApn
        sendTimestamp
        topic
        number
        summaryText
        new
        processedTimestamp
        entityApn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
