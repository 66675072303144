import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import LearnItemNote from './Note';
import LearnItemQuestion from './Question';
import LearnItemLink from './Link';
import LearnItemPage from './Page';
import LearnItemHeading from './Heading';
import LearnItemUnknown from './Unknown';
import LearnItemImage from './Image';
import LearnItemFile from './File';

export const style = () => ({});

class LearnItem extends Component {
  state = {};

  handleChange = changedItem => {
    const { item, onChange } = this.props;
    item.item = changedItem;
    onChange(item);
  };

  handleDelete = changedItem => {
    const { item, onDelete } = this.props;
    item.item = changedItem;
    onDelete(item);
  };

  render() {
    const { item, readOnly } = this.props;

    switch (item.type) {
      case 'note':
        return (
          <LearnItemNote
            item={item.item}
            readOnly={readOnly}
            onChange={this.handleChange}
            onDelete={this.handleDelete}
          />
        );
      case 'question':
        return (
          <LearnItemQuestion
            item={item.item}
            readOnly={readOnly}
            onChange={this.handleChange}
            onDelete={this.handleDelete}
          />
        );
      case 'link':
        return (
          <LearnItemLink
            item={item.item}
            readOnly={readOnly}
            onChange={this.handleChange}
            onDelete={this.handleDelete}
          />
        );
      case 'page':
        return (
          <LearnItemPage
            item={item.item}
            readOnly={readOnly}
            onChange={this.handleChange}
            onDelete={this.handleDelete}
          />
        );
      case 'heading':
        return (
          <LearnItemHeading
            item={item.item}
            readOnly={readOnly}
            onChange={this.handleChange}
            onDelete={this.handleDelete}
          />
        );
      case 'image':
        return (
          <LearnItemImage
            item={item.item}
            readOnly={readOnly}
            onChange={this.handleChange}
            onDelete={this.handleDelete}
          />
        );
      case 'file':
        return (
          <LearnItemFile
            item={item.item}
            readOnly={readOnly}
            onChange={this.handleChange}
            onDelete={this.handleDelete}
          />
        );
      default:
        return (
          <LearnItemUnknown
            item={item.item}
            readOnly={readOnly}
            onChange={this.handleChange}
            onDelete={this.handleDelete}
          />
        );
    }
  }
}

LearnItem.propTypes = {
  item: PropTypes.any.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default withStyles(style)(LearnItem);
