import { SET_LOADING } from '../actions/notifications';

export default function loadingReducer(oldstate = '', action) {
  switch (action.type) {
    case SET_LOADING:
      return action.payload;

    default:
      return oldstate;
  }
}
