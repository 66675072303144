import React, { Component } from 'react';
import { connect } from 'react-redux';
import JournalItem from '../../components/Tracker/Journal/Item';
import { saveJournalAction } from '../../redux/actions/journal';

export class JournalItemContainer extends Component {
  render() {
    return <JournalItem />;
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  saveJournalEntry: entry => {
    dispatch(saveJournalAction(entry));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(JournalItem);
