import { put, select, takeLatest } from 'redux-saga/effects';
import moment from 'moment';

import {
  ADD_LEARN_PAGE,
  DELETE_LEARN_PAGE,
  SAVE_LEARN_PAGE,
  LEARN_PAGE_CREATE_REQUEST_ACTION,
  LEARN_PAGE_CREATE_SUCCESS_ACTION,
  LEARN_PAGE_DELETE_REQUEST_ACTION,
  LEARN_PAGE_DELETE_SUCCESS_ACTION,
  LEARN_PAGE_UPDATE_REQUEST_ACTION,
  LEARN_PAGE_UPDATE_SUCCESS_ACTION,
  loadLearnPagesFromDiskAction,
  LEARN_PAGE_DATA_LOAD_SUCCESS_ACTION,
  LOAD_LEARN_PAGES_FROM_DISK,
  LEARN_PAGE_DATA_LOAD_REQUEST_ACTION
} from '../actions/learnPages';
import { getLearnPageStruct } from '../../structs/learn';
import { loadFromStorage } from '../../utils/localStorage';
import {
  APPLICATION_DATA_CLEAR_REQUEST_ACTION,
  APPLICATION_DATA_REQUEST_ACTION
} from '../actions/app';
import { stringifyApn } from '../../utils/apn/v2';

function* handleCreateLearnPageRequest(action) {
  const user = yield select(store => store.user);
  // Persist LearnPage to Local Data Store
  const learnPage = getLearnPageStruct(action.payload);
  learnPage.updatedAt = moment().format();
  learnPage.createdAt = moment().format();
  learnPage.ownerApn = stringifyApn({ userId: user.id });

  yield put({
    type: ADD_LEARN_PAGE,
    payload: learnPage
  });

  if (action.callback) {
    action.callback(learnPage);
  }

  // Signal Complete
  yield put({
    type: LEARN_PAGE_CREATE_SUCCESS_ACTION
  });
}

function* handleUpdateLearnPageRequest(action) {
  // Persist LearnPage to Local Data Store
  const learnPage = getLearnPageStruct(action.payload);
  learnPage.updatedAt = moment().format();

  yield put({
    type: SAVE_LEARN_PAGE,
    payload: learnPage
  });

  // Signal Complete
  yield put({
    type: LEARN_PAGE_UPDATE_SUCCESS_ACTION
  });
}

function* handleDeleteLearnPageRequest(action) {
  // Persist LearnPage to Local Data Store
  yield put({
    type: DELETE_LEARN_PAGE,
    payload: action.payload
  });

  // Signal Complete
  yield put({
    type: LEARN_PAGE_DELETE_SUCCESS_ACTION
  });
}

function* handleLearnPageDataLoadRequest(action) {
  // Persist LearnPage to Local Data Store
  yield put({
    type: LOAD_LEARN_PAGES_FROM_DISK,
    payload: action.payload
  });

  // Signal Complete
  yield put({
    type: LEARN_PAGE_DATA_LOAD_SUCCESS_ACTION
  });
}

function* handleLoadApplicationDataRequest() {
  const type = 'learnPages';
  // Perform Application Data Load
  const learnPagesState = yield select(store => store.learnPages);

  // Call the Util Local Storage loadState function which pulls data
  // from the local storage if cached there, if not it pulls from the disk
  const learnPages = yield loadFromStorage(type, learnPagesState);

  yield put(loadLearnPagesFromDiskAction(learnPages));
}

function* handleStateChange() {
  const type = 'learnPages';

  // Select data from the store
  const data = yield select(store => store[type]);
  const dataString = JSON.stringify(data);

  // As well as in the local storage.
  localStorage.setItem(type, dataString);
}

function* handleApplicationDataClear() {
  yield put(loadLearnPagesFromDiskAction([]));
}

export default function* learnPageSaga() {
  yield takeLatest(
    LEARN_PAGE_CREATE_REQUEST_ACTION,
    handleCreateLearnPageRequest
  );
  yield takeLatest(
    LEARN_PAGE_UPDATE_REQUEST_ACTION,
    handleUpdateLearnPageRequest
  );
  yield takeLatest(
    LEARN_PAGE_DELETE_REQUEST_ACTION,
    handleDeleteLearnPageRequest
  );
  yield takeLatest(
    LEARN_PAGE_DATA_LOAD_REQUEST_ACTION,
    handleLearnPageDataLoadRequest
  );
  yield takeLatest(LEARN_PAGE_CREATE_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(LEARN_PAGE_UPDATE_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(LEARN_PAGE_DELETE_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(
    APPLICATION_DATA_REQUEST_ACTION,
    handleLoadApplicationDataRequest
  );
  yield takeLatest(LEARN_PAGE_DATA_LOAD_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(
    APPLICATION_DATA_CLEAR_REQUEST_ACTION,
    handleApplicationDataClear
  );
}
