import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { getHoursFromMinutes } from '../../utils/date';
import {COMMITMENT_STATUS_COMPLETE, COMMITMENT_STATUS_MOVED} from '../../structs/commitments';
import Chip from "@material-ui/core/Chip";
import {ROLE_NO_ROLE_NAME} from "../../structs/roles";
import CommitmentTrayList from "../CommitmentTray/List";
import Typography from "@material-ui/core/Typography";

const style = () => ({
  item: {
    paddingLeft: 50,
    paddingRight: 10
  },
  secondaryAction: {
    left: 0,
    right: 'auto'
  }
});

class CommitmentList extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    commitments: PropTypes.array.isRequired,
    handleDoneChange: PropTypes.func.isRequired,
    setActiveCommitment: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired
  };

  handleClick = commitment => () => {
    const { setActiveCommitment } = this.props;
    setActiveCommitment(commitment);
  };

  render() {
    const { classes, commitments, handleDoneChange, type } = this.props;
    return (
      <List dense disablePadding>
        <CommitmentTrayList/>
        <Typography
          variant={"overline"}
          color="secondary"
          style={{display: 'inline-block', textAlign: 'center', width: '100%', marginTop: '1em'}}
        >
          Today's Commitments
        </Typography>
        {commitments.map(commitmentDoc => {
          const { entity: commitment } = commitmentDoc;
          return (
            <ListItem
              button
              disableGutters
              divider
              key={commitment.id}
              className={classes.item}
              onClick={this.handleClick(commitment)}
            >
              <ListItemText>
                <Chip label={commitment.role.name || ROLE_NO_ROLE_NAME} style={{backgroundColor: commitment.role.color}} size="small"/>
                {commitment.description}
                {commitment.contextDescription && (
                  <div style={{ fontSize: 12 }}>
                    {commitment.contextDescription}
                  </div>
                )}

              </ListItemText>
              <span>{getHoursFromMinutes(commitment.timeCommitment)}</span>
              <ListItemSecondaryAction className={classes.secondaryAction}>
                {commitment.status !== COMMITMENT_STATUS_MOVED && (
                  <Checkbox
                    onChange={handleDoneChange(commitment, type)}
                    checked={commitment.status === COMMITMENT_STATUS_COMPLETE}
                  />
                )}
                {commitment.status === COMMITMENT_STATUS_MOVED && (
                  <IconButton disabled>
                    <ArrowRightAltIcon />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    );
  }
}

export default withRouter(withStyles(style)(CommitmentList));
