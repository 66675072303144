import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import routes from '../../constants/routes.json';
import { getDaysUntilReview } from '../../models/tracker';
import { isSameDate } from '../../utils/date';
import Style from './Style';
import {TRACKER_STATUS_ACTIVE} from '../../structs/trackers';
import Chip from "@material-ui/core/Chip";
import {ROLE_NO_ROLE_NAME} from "../../structs/roles";

class TrackersList extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    dateFormatter: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    trackersToReview: PropTypes.array.isRequired
  };

  onClick = trackerId => () => {
    const { history } = this.props;
    history.push(`${routes.TRACKER_BASE}${trackerId}`);
  };

  render() {
    const {
      classes,
      dateFormatter,
      trackersToReview
    } = this.props;
    const now = new Date();
    return (
      <List dense disablePadding>
        {trackersToReview.map(trackerDoc => {
          const { entity: tracker, reviewDate, reviewCycle, share, role } = trackerDoc;
          const isReviewed = isSameDate(
            new Date(reviewCycle.lastReviewedDate),
            now
          );
          return (
            <ListItem
              className={classes.item}
              button
              disableGutters
              divider
              key={tracker.id}
              onClick={this.onClick(tracker.id)}
            >
              <ListItemText>
                <span className={classes.listItemWrapper}>
                  <span>
                    <span className={classes.name}>{tracker.dream}</span>
                    <span
                      style={{
                        color: tracker.status === TRACKER_STATUS_ACTIVE ? '#2c9c52' : '#000'
                      }}
                    >
                      <Chip label={tracker.status} variant="outlined" color="primary" size="small"/>
                      <Chip label={role.name || ROLE_NO_ROLE_NAME} style={{backgroundColor: role.color}} size="small"/>
                      {share.id
                        ? <Chip label="Shared" variant="outlined" color="secondary" size="small"/>
                        : null
                      }
                    </span>
                  </span>
                  <span className={classes.additionalInfo}>
                    {getDaysUntilReview(reviewDate) < 1 ? (
                      <span>Review Due</span>
                    ) : (
                      <span>
                        Next Review {dateFormatter.format(reviewDate)}
                      </span>
                    )}
                  </span>
                </span>
              </ListItemText>
              <ListItemSecondaryAction className={classes.secondaryAction}>
                <Checkbox
                  onChange={!isReviewed ? this.onClick(tracker.id) : () => {}}
                  checked={isReviewed}
                />
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    );
  }
}

export default withRouter(withStyles(Style)(TrackersList));
