import { put, select, takeLatest, take } from 'redux-saga/effects';

import moment from 'moment';
import {
  SET_BACKUP_PROFILE,
  BACKUP_PROFILE_UPDATE_REQUEST_ACTION,
  BACKUP_PROFILE_UPDATE_SUCCESS_ACTION,
  loadBackupProfileFromDiskAction,
  BACKUP_PROFILE_DATA_LOAD_SUCCESS_ACTION,
  LOAD_BACKUP_PROFILE_FROM_DISK,
  BACKUP_PROFILE_DATA_LOAD_REQUEST_ACTION,
  setBackupProfileAction
} from '../actions/backupProfile';
import { getBackupProfileStruct } from '../../structs/sync';
import { loadFromStorage } from '../../utils/localStorage';
import {
  APPLICATION_DATA_REQUEST_ACTION,
  applicationDataSuccessAction
} from '../actions/app';
import { showSuccessAction } from '../actions/notifications';
import {
  VERIFY_APP_SYNC_SUCCESS_ACTION,
  verifyAppSyncAction
} from '../actions/appSync';

function* handleUpdateBackupProfileRequest(action) {
  // Persist BackupProfile to Local Data Store
  const backupProfile = getBackupProfileStruct(action.payload);
  backupProfile.updatedAt = moment().format();

  yield put({
    type: SET_BACKUP_PROFILE,
    payload: backupProfile
  });

  // Signal Complete
  yield put({
    type: BACKUP_PROFILE_UPDATE_SUCCESS_ACTION
  });

  yield put(verifyAppSyncAction());

  yield take(VERIFY_APP_SYNC_SUCCESS_ACTION);

  backupProfile.verifiedAt = moment().toISOString();

  yield put(showSuccessAction('Connection Verified!'));

  yield put(setBackupProfileAction(backupProfile));
}

function* handleBackupProfileDataLoadRequest(action) {
  // Persist BackupProfile to Local Data Store
  yield put({
    type: LOAD_BACKUP_PROFILE_FROM_DISK,
    payload: action.payload
  });

  // Signal Complete
  yield put({ type: BACKUP_PROFILE_DATA_LOAD_SUCCESS_ACTION });
}

function* handleLoadApplicationDataRequest() {
  const type = 'backupProfile';
  // Perform Application Data Load
  const backupProfilesState = yield select(store => store.backupProfile);

  // Call the Util Local Storage loadState function which pulls data
  // from the local storage if cached there, if not it pulls from the disk
  const backupProfiles = yield loadFromStorage(type, backupProfilesState);

  yield put(loadBackupProfileFromDiskAction(backupProfiles));

  yield take(BACKUP_PROFILE_DATA_LOAD_SUCCESS_ACTION);

  yield put(applicationDataSuccessAction(type));
}

function* handleStateChange() {
  const type = 'backupProfile';

  // Select data from the store
  const data = yield select(store => store[type]);
  const dataString = JSON.stringify(data);

  // As well as in the local storage.
  localStorage.setItem(type, dataString);
}

export default function* backupProfileSaga() {
  yield takeLatest(
    BACKUP_PROFILE_UPDATE_REQUEST_ACTION,
    handleUpdateBackupProfileRequest
  );
  yield takeLatest(BACKUP_PROFILE_UPDATE_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(
    BACKUP_PROFILE_DATA_LOAD_REQUEST_ACTION,
    handleBackupProfileDataLoadRequest
  );
  yield takeLatest(
    APPLICATION_DATA_REQUEST_ACTION,
    handleLoadApplicationDataRequest
  );
  yield takeLatest(BACKUP_PROFILE_DATA_LOAD_SUCCESS_ACTION, handleStateChange);
}
