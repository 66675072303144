// Tracker Actions

export const CHANGE_LOG_ADD = 'CHANGE_LOG_ADD';
export const CHANGE_LOG_REMOVE = 'CHANGE_LOG_REMOVE';
export const CHANGE_LOG_LOAD_ALL = 'CHANGE_LOG_LOAD_ALL';

export const CHANGE_LOG_ADD_REQUEST_ACTION = 'CHANGE_LOG_ADD_REQUEST';
export const CHANGE_LOG_ADD_SUCCESS_ACTION = 'CHANGE_LOG_ADD_SUCCESS';
export const CHANGE_LOG_REMOVE_REQUEST_ACTION = 'CHANGE_LOG_REMOVE_REQUEST';
export const CHANGE_LOG_REMOVE_SUCCESS_ACTION = 'CHANGE_LOG_REMOVE_SUCCESS';
export const CHANGE_LOG_SYNC_REQUEST_ACTION = 'CHANGE_LOG_SYNC_REQUEST';
export const CHANGE_LOG_SYNC_SUCCESS_ACTION = 'CHANGE_LOG_SYNC_SUCCESS';
export const CHANGE_LOG_SYNC_DISABLED_ACTION = 'CHANGE_LOG_SYNC_DISABLED';
export const CHANGE_LOG_PERSIST_REQUEST_ACTION = 'CHANGE_LOG_PERSIST_REQUEST';
export const CHANGE_LOG_PERSIST_SUCCESS_ACTION = 'CHANGE_LOG_PERSIST_SUCCESS';
export const CHANGE_LOG_REALTIME_REQUEST_ACTION = 'CHANGE_LOG_REALTIME_REQUEST';
export const CHANGE_LOG_DATA_LOAD_REQUEST_ACTION =
  'CHANGE_LOG_DATA_LOAD_REQUEST';
export const CHANGE_LOG_DATA_LOAD_SUCCESS_ACTION =
  'CHANGE_LOG_DATA_LOAD_SUCCESS';

export const addChangeLogAction = item => ({
  type: CHANGE_LOG_ADD,
  payload: item
});

export const removeChangeLogAction = item => ({
  type: CHANGE_LOG_REMOVE,
  payload: item
});

export const loadChangeLogAction = items => ({
  type: CHANGE_LOG_DATA_LOAD_REQUEST_ACTION,
  payload: items
});

export const processRealTimeChangeLog = item => ({
  type: CHANGE_LOG_REALTIME_REQUEST_ACTION,
  payload: item
});

export const syncChangeLogAction = () => ({
  type: CHANGE_LOG_SYNC_REQUEST_ACTION
});
