import {getToday} from '../utils/date';

export const APP_STATUS_DATA_PENDING = 'APP_STATUS_DATA_PENDING';
export const APP_STATUS_DATA_LOADED = 'APP_STATUS_DATA_LOADED';
export const APP_STATUS_DATA_ERROR = 'APP_STATUS_DATA_ERROR';

export const AppStruct = {
  dataStatus: {
    backupProfile: APP_STATUS_DATA_PENDING,
    user: APP_STATUS_DATA_PENDING,
    syncVersion: APP_STATUS_DATA_PENDING
  },
  desiredRoute: '',
  error: '',
  userCheck: true,
  paymentKey: '',
  subscriptions: [],
  payGated: false,
  today: getToday(true),
  syncing: false
};
