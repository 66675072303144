import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import LearnItem from '../Items/Item';
import { getLearnItemStruct } from '../../../structs/learn';

export const style = () => ({});

class LearnPageItems extends Component {
  state = { parts: [] };

  componentDidMount() {
    const { page } = this.props;
    const { parts } = this.state;

    if (page.parts !== parts) {
      this.hydrateState(page.parts);
    }
  }

  componentDidUpdate() {
    const { page } = this.props;
    const { parts } = this.state;

    if (page.parts !== parts) {
      this.hydrateState(page.parts);
    }
  }

  hydrateState = parts => {
    this.setState({ parts });
  };

  handleItemChange = itemToChange => {
    const { parts } = this.state;
    const { onChange } = this.props;
    const newParts = this.reSequenceItems(
      parts.map(item => {
        if (item.seq === itemToChange.seq) {
          return itemToChange;
        }
        return item;
      })
    );
    this.setState({
      parts: newParts
    });
    // Persist to Page onChange
    onChange(newParts);
  };

  handleAdd = type => () => {
    this.addLearnItem({ type });
  };

  addLearnItem = itemToAdd => {
    const { parts } = this.state;
    const { onChange } = this.props;

    parts.push(getLearnItemStruct(itemToAdd));

    onChange(this.reSequenceItems(parts));
  };

  handleItemRemove = itemToRemove => {
    const { parts } = this.state;
    const { onChange } = this.props;

    const newParts = parts.filter(item => item.id !== itemToRemove.id);

    onChange(this.reSequenceItems(newParts));
  };

  reSequenceItems = items => {
    let seq = 1;
    const list = items.map(item => {
      const newItem = item;
      newItem.seq = seq;
      seq += 1;
      return newItem;
    });
    return list;
  };

  render() {
    const { parts } = this.state;

    return (
      <List>
        {parts.length
          ? parts.map(item => (
              <LearnItem
                key={item.id}
                item={item}
                onChange={this.handleItemChange}
                onDelete={this.handleItemRemove}
              />
            ))
          : null}
        <ListItem>
          +
          <Button size="small" onClick={this.handleAdd('note')}>
            Note
          </Button>
          <Button size="small" onClick={this.handleAdd('link')}>
            Link
          </Button>
          <Button size="small" onClick={this.handleAdd('question')}>
            Question
          </Button>
          <Button size="small" onClick={this.handleAdd('page')}>
            Page
          </Button>
          <Button size="small" onClick={this.handleAdd('heading')}>
            Header
          </Button>
        </ListItem>
      </List>
    );
  }
}

LearnPageItems.propTypes = {
  page: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
};

export default withStyles(style)(LearnPageItems);
