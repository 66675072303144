export const ChangeLogStruct = {
  // schemaVersion: '1.0.0',
  ownerApn: '',
  timestamp: '',
  changeId: '',
  action: '',
  data: {},
  source: ''
};

export const getChangeLogStruct = (item = ChangeLogStruct) =>
  Object.assign({}, ChangeLogStruct, item);
