import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import DialogActions from "@material-ui/core/DialogActions";
import routes from '../../constants/routes.json';
import {resetCompletedCommitmentAction} from "../../redux/actions/activeCommitment";
import ConfettiExplosion from 'react-confetti-explosion';
import DialogTitle from "@material-ui/core/DialogTitle";
import JournalAdd from "../Journal/Add";
import CommitButton from "../../containers/Commitment/CommitButton";
import PostAddIcon from "@material-ui/icons/PostAdd";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import {getTrackerDocumentStruct} from "../../structs/trackers";

const useStyles = makeStyles(() => ({
  dialogPaper: {
    position: "absolute",
    bottom: 0,
    overflow: "visible",
    minHeight: '33%'
  }
}));

const Celebration = ({onComplete}) => {
  const [celebration, setCelebration] = useState(null);

  useEffect(() => {
    let timer;
    setCelebration(<ConfettiExplosion duration={3000} />);
    timer = setTimeout(() => {
      setCelebration(null);
      onComplete();
    }, 2000);
    return () => clearTimeout(timer);
  }, [onComplete])

  return celebration;
}

const CompletedDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const completedCommitment = useSelector(store => store.activeCommitment.completed);
  const trackersById = useSelector(store => store.trackerIndex.byId);
  const history = useHistory();
  const [journalMessage, setJournalMessage] = useState('');
  const [showJournal, setShowJournal] = useState(false);
  const [ready, setReady] = useState(false);
  const { entity: tracker } = trackersById[completedCommitment.trackerId] || getTrackerDocumentStruct()

  const clear = () => {
    setJournalMessage('');
    setShowJournal(false);
    setReady(false)
    dispatch(resetCompletedCommitmentAction());
  };

  const onReady = useCallback(() => setReady(true),[]);

  return (
    <Dialog
      open={!!completedCommitment.id}
      onClose={() => clear()}
      fullWidth
      classes={{
        paper: classes.dialogPaper
      }}
    >
      <Celebration onComplete={onReady}/>
      <DialogTitle>
        <Typography>You just marked: {completedCommitment.description} as complete!</Typography>
        <Typography variant={"caption"}>Context: {completedCommitment.contextDescription}</Typography>
        {tracker.id ? <Typography variant={"caption"}>, Tracker: {tracker.dream}</Typography> : null}
        <Divider/>
        <Typography color={"secondary"} style={{padding: '1em 0'}}>Great work getting that done{journalMessage ? ` & ${journalMessage}` : '!'}</Typography>
        <Divider/>
      </DialogTitle>
      <DialogContent>
        Add an update to the Journal

        {showJournal ? (
          <JournalAdd
            focus
            trackerId={completedCommitment.trackerId}
            onComplete={() => {
              setJournalMessage('good job recording progress.');
              setShowJournal(false);
            }}
          />
        ) : (
          <Button
            color={"primary"}
            variant={"outlined"}
            fullWidth
            disabled={!ready}
            onClick={() => setShowJournal(true)}
          >
            Record Progress
          </Button>
        )}
        <Divider style={{ margin: '1em 0' }}/>
        Still more to do?
        <CommitButton
          fullWidth
          style={{margin: 'auto'}}
          disabled={!ready}
          context={{
            apn: completedCommitment.contextApn,
            description: completedCommitment.contextDescription,
            tracker
          }}
          startIcon={<PostAddIcon/>}
        >
          Commit Again
        </CommitButton>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            clear();
          }}
          style={{ marginRight: 'auto' }}
        >
          Close
        </Button>
        {!!completedCommitment.trackerId && !!tracker.id ? (
          <Button
            color={"secondary"}
            variant={"outlined"}
            disabled={!ready}
            onClick={() => {
              history.push(`${routes.TRACKER_BASE}${completedCommitment.trackerId}`);
              clear();
            }}
          >
            Review Tracker
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

CompletedDialog.defaultProps = {};

CompletedDialog.propTypes = {};

export default CompletedDialog;
