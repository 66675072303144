export const ContactStruct = {
  schemaVersion: '1.0.0',
  id: '',
  userApn: '',
  friendApn: '',
  friendName: '',
  friendEmail: '',
  createdAt: '',
  updatedAt: ''
};

export const getContactStruct = (contact = ContactStruct) => ({
  ...ContactStruct,
  ...contact
})
