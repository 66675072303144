
// Load State is called from the App component, passing in the
export const loadFromStorage = (type, defaultValue) =>
  new Promise((resolve, reject) => {
    try {
      // Try to pull the state from the localStorage
      const serializedState = localStorage.getItem(type);

      if (serializedState !== null) {
        // If the localStorage value was there resolve with state from local storage
        resolve(JSON.parse(serializedState));
      }

    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
