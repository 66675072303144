import {API, graphqlOperation} from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import {
  getTrackerActivityStruct
} from '../../structs/trackerActivity';

/*
 *
 * TrackerActivity Functions
 *
 */
export const fetchRemoteTrackerActivityActiveByUser = async (userId,token) =>
  new Promise(async resolve => {
    const { data: { trackerActivityByUserId: { items, nextToken }}} = await API.graphql(
      graphqlOperation(queries.trackerActivityByUserId, {
        userId: userId,
        limit: 25,
        sortDirection: 'DESC',
        nextToken: token
      })
    );

    resolve({
      nextToken,
      items: items.length
        ? items.map(trackerActivity => postFetchProcessTrackerActivity(trackerActivity))
        : []
    });
  });

export const fetchRemoteTrackerActivityByTracker = async (trackerId,token) =>
  new Promise(async resolve => {
    const { data: { listTrackerActivitys: { items, nextToken }}} = await API.graphql(
      graphqlOperation(queries.listTrackerActivitys, {
        trackerId: trackerId,
        limit: 25,
        sortDirection: 'DESC',
        nextToken: token
      })
    );

    resolve({
      nextToken,
      items: items.length
        ? items.map(trackerActivity => postFetchProcessTrackerActivity(trackerActivity))
        : []
    });
  });

export const createRemoteTrackerActivity = async trackerActivity => {
  const input = prepAndPruneTrackerActivityForAppSync(
    Object.assign({}, trackerActivity)
  );

  const { data: { createTrackerActivity }} = await API.graphql(
    graphqlOperation(mutations.createTrackerActivity, {
      input
    })
  );
  return postFetchProcessTrackerActivity(createTrackerActivity);
};

const postFetchProcessTrackerActivity = trackerActivity => {
  // Pass fetched value through the Struct, never trust input. :)
  return getTrackerActivityStruct(trackerActivity);
};

export const prepAndPruneTrackerActivityForAppSync = trackerActivity => {
  // Prune any keys that are not in the schema
  const keys = Object.keys(getTrackerActivityStruct());
  const pruned = getTrackerActivityStruct(trackerActivity);
  const prunedKeys = Object.keys(pruned);
  const propertiesToPrune = prunedKeys.filter(key => !keys.includes(key));
  if (propertiesToPrune.length)
    propertiesToPrune.map(key => delete pruned[key]);
  
  // Prep any empty strings
  keys.map(key => {
    if (typeof pruned[key] === 'string' && pruned[key] === '') {
      pruned[key] = ' ';
    }
    return true;
  });

  return pruned;
};
