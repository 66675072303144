import React from 'react';
import Typography from '@material-ui/core/Typography';
import { put, takeLatest, select, take } from 'redux-saga/effects';
import { stringifyApn } from '../../utils/apn/v2';
import {
  APPLICATION_DATA_CLEAR_REQUEST_ACTION, APPLICATION_DATA_REQUEST_ACTION, setDesiredRouteAction,
} from '../actions/app';
import {addNotificationAction, addEmailNotificationAction} from '../actions/notifications';
import {TRACKER_STATUS_ARCHIVED} from '../../structs/trackers';
import {
  getSystemNotificationStruct,
  NOTIFICATION_TOPIC_TRACKER_SHARE
} from '../../structs/notification';
import {setActiveTrackerAction} from '../actions/activeTracker';
import {
  getTrackerShareStruct,
  TRACKER_SHARE_STATUS_ACTIVE,
  TRACKER_SHARE_STATUS_DECLINED
} from '../../structs/trackerShare';
import {
  REMOVE_LOCAL_TRACKER_SHARE_ACTION,
  saveTrackerShareAction,
  setTrackerSharesAction, TRACKER_SHARE_ACCEPT_REQUEST_ACTION, TRACKER_SHARE_ACCEPT_SUCCESS_ACTION,
  TRACKER_SHARE_CREATE_REQUEST_ACTION,
  TRACKER_SHARE_CREATE_SUCCESS_ACTION,
  TRACKER_SHARE_DATA_LOAD_SUCCESS_ACTION, TRACKER_SHARE_DECLINE_REQUEST_ACTION, TRACKER_SHARE_DECLINE_SUCCESS_ACTION,
  TRACKER_SHARE_DELETE_REQUEST_ACTION,
  TRACKER_SHARE_DELETE_SUCCESS_ACTION,
  TRACKER_SHARE_FULL_SYNC_REQUEST_ACTION,
  TRACKER_SHARE_FULL_SYNC_SUCCESS_ACTION,
  TRACKER_SHARE_UPDATE_REQUEST_ACTION,
  TRACKER_SHARE_UPDATE_SUCCESS_ACTION, UPSERT_LOCAL_TRACKER_SHARE_ACTION, upsertLocalTrackerShareAction
} from '../actions/trackerShares';
import {
  deleteRemoteTrackerShare,
  fetchRemoteTrackerSharesByTracker,
  fetchRemoteTrackerSharesActiveByUser,
  upsertRemoteTrackerShare, fetchRemoteTrackerSharesPendingByUser
} from '../../utils/appSync/trackerShare';
import {TRACKER_FETCH_SUCCESS_ACTION, upsertLocalTrackerAction} from '../actions/trackers';
import routes from '../../constants/routes.json';
import {fetchRemoteTracker} from '../../utils/appSync/tracker';

function* handleCreateTrackerShareRequest(action) {
  // Check for un-archiving against limits
  const activeTrackerDoc = yield select(store => store.activeTracker);
  const { tracker: activeTracker } = activeTrackerDoc;
  if (activeTracker.status === TRACKER_STATUS_ARCHIVED) {
    yield put(
      addNotificationAction(
        getSystemNotificationStruct({
          message: (
            <div>
              <Typography>
                {`Hmm... you can't update an archived tracker. If you want to change the tracker
                need make it active first. Any changes made will not be saved.`}
              </Typography>
            </div>
          ),
          type: 'dialog',
          level: 'warning'
        })
      )
    );
    yield put(setActiveTrackerAction(activeTracker));
    return 0;
  }
  
  const user = yield select(store => store.user);
  const { attributes: { email: userEmail, familyName, givenName }} = user;
  const userApn = stringifyApn({ userId: user.id });

  // Persist TrackerShare to Local Data Store
  const trackerShare = getTrackerShareStruct({
    ...action.payload,
    ownerApn: userApn,
    ownerEmailAddress: userEmail,
    ownerName: `${givenName} ${familyName}`,
    trackerId: activeTracker.id,
    reviewCycle: activeTracker.reviewCycle,
    shareDescription: activeTracker.dream
  });

  // Check for existing share
  const existingTrackerShares = yield fetchRemoteTrackerSharesByTracker(activeTracker);

  const existingTrackerShare = existingTrackerShares.find(s => s.emailAddress === trackerShare.emailAddress);

  const remoteTrackerShare = yield upsertRemoteTrackerShare({
    ...trackerShare,
    ...(existingTrackerShare || {})
  });

  yield put({
    type: UPSERT_LOCAL_TRACKER_SHARE_ACTION,
    payload: remoteTrackerShare
  });

  if (!existingTrackerShare) {
    // If the share has already been created we don't need to notify

    yield put(
      addEmailNotificationAction({
        topic: NOTIFICATION_TOPIC_TRACKER_SHARE,
        payload: `
      <h1>Formigio Planner</h1>
      <p>You have sent an invitation to share your Tracker: "${activeTracker.dream}" with ${remoteTrackerShare.emailAddress}</p>
      <p>Once they accept the invitation, you both will have the capability to work together.</p>
      <br/>
      <p>Stay Tuned!</p>
    `,
        summaryText: `You have started to share your Tracker: "${activeTracker.dream}"`
      })
    );

    yield put(
      addEmailNotificationAction({
        topic: NOTIFICATION_TOPIC_TRACKER_SHARE,
        payload: `
      <h1>Formigio Planner</h1>
      <p>You have been invited to share a Formigio Accomplishment Tracker: "${activeTracker.dream}" by ${user.attributes.givenName} ${user.attributes.familyName}</p>
      <p><a href="https://app.formigio.com/share/accept/${activeTracker.id}/${remoteTrackerShare.id}">Open Share Invite</a></p>
      <br/>
      <p>Once you open, you can accept or reject.</p>
      <br/>
      <p>You will need to create a free Formigio account to work together.</p>
    `,
        summaryText: "Invitation to Share an Accomplishment Tracker with Formigio",
        email: remoteTrackerShare.emailAddress
      })
    );
  }

  // Signal Complete
  yield put({
    type: TRACKER_SHARE_CREATE_SUCCESS_ACTION,
    payload: remoteTrackerShare
  });
}

function* handleUpdateTrackerShareRequest(action) {
  // Persist TrackerShare to Local Data Store
  const trackerShare = getTrackerShareStruct(action.payload);
  
  const remoteTrackerShare = yield upsertRemoteTrackerShare(trackerShare);
  
  yield put({
    type: UPSERT_LOCAL_TRACKER_SHARE_ACTION,
    payload: remoteTrackerShare
  });

  // Signal Complete
  yield put({
    type: TRACKER_SHARE_UPDATE_SUCCESS_ACTION,
    payload: remoteTrackerShare
  });
}

function* handleDeleteTrackerShareRequest({ payload }) {
  // Persist TrackerShare to Local Data Store
  
  yield deleteRemoteTrackerShare(payload);
  
  yield put({
    type: REMOVE_LOCAL_TRACKER_SHARE_ACTION,
    payload
  });

  // Signal Complete
  yield put({
    type: TRACKER_SHARE_DELETE_SUCCESS_ACTION,
    payload
  });
}

function* handleTrackerShareAcceptRequest({ payload: trackerShare }) {
  
  const user = yield select(store => store.user);
  
  yield put(saveTrackerShareAction({
    ...trackerShare,
    userId: user.id,
    status: TRACKER_SHARE_STATUS_ACTIVE
  }));

  yield take(TRACKER_SHARE_UPDATE_SUCCESS_ACTION);
  
  const remoteTracker = yield fetchRemoteTracker(
    trackerShare.ownerApn,
    trackerShare.trackerId
  );

  yield put(upsertLocalTrackerAction(remoteTracker));
  
  yield put(setDesiredRouteAction(`${routes.TRACKER_BASE}${remoteTracker.id}`));
  
  yield put(addNotificationAction(getSystemNotificationStruct({
    message: 'Tracker share complete, you now have access.'
  })));

  yield put(
    addEmailNotificationAction({
      topic: NOTIFICATION_TOPIC_TRACKER_SHARE,
      payload: `
        <h1>Formigio Planner</h1>
        <p>Your invitation to share <b>${trackerShare.shareDescription}</b> has been accepted by ${user.attributes.givenName} ${user.attributes.familyName}</p>
        <br/>
        <p>Now get to work! :)</p>
      `,
      summaryText: `Invitation to Share "${trackerShare.shareDescription}" has been accepted!`,
      email: trackerShare.ownerEmailAddress
    })
  );

  // Signal Complete
  yield put({
    type: TRACKER_SHARE_ACCEPT_SUCCESS_ACTION,
    payload: trackerShare
  });
  
}

function* handleTrackerShareDeclineRequest({ payload: trackerShare }) {

  const user = yield select(store => store.user);

  yield put(saveTrackerShareAction({
    ...trackerShare,
    status: TRACKER_SHARE_STATUS_DECLINED
  }));
  
  yield put(setDesiredRouteAction(routes.HOME));
  
  yield take(TRACKER_SHARE_UPDATE_SUCCESS_ACTION);
  
  yield put(addNotificationAction(getSystemNotificationStruct({
    message: 'Share Invite Declined.'
  })));

  yield put(
    addEmailNotificationAction({
      topic: NOTIFICATION_TOPIC_TRACKER_SHARE,
      payload: `
        <h1>Formigio Planner</h1>
        <p>FYI. Your invitation to share <b>${trackerShare.shareDescription}</b> has been declined by ${user.attributes.givenName} ${user.attributes.familyName}</p>
      `,
      summaryText: `Invitation to Share "${trackerShare.shareDescription}" has been declined.`,
      email: trackerShare.ownerEmailAddress
    })
  );

  // Signal Complete
  yield put({
    type: TRACKER_SHARE_DECLINE_SUCCESS_ACTION,
    payload: trackerShare
  });
  
}

function* handleTrackerShareDataLoadRequest({ payload: tracker }) {

  // Pull shares for the active tracker
  const trackerShares = yield fetchRemoteTrackerSharesByTracker(tracker);

  for (const trackerShare of trackerShares) {
    yield put(upsertLocalTrackerShareAction(trackerShare));
  }

  // Signal Complete
  yield put({
    type: TRACKER_SHARE_DATA_LOAD_SUCCESS_ACTION
  });
}

function* handleApplicationDataClear() {
  yield put(setTrackerSharesAction([]));
}

function* handleFullSyncRequest() {
  const user = yield select(store => store.user);
  
  if (!user.id) return;
  
  // Pull Trackers Shared with Me
  const activeTrackerShares = yield fetchRemoteTrackerSharesActiveByUser(user.id);

  // Fetch shared trackers
  for (const trackerShare of activeTrackerShares) {
    const remoteTracker = yield fetchRemoteTracker(
      trackerShare.ownerApn,
      trackerShare.trackerId
    );
    yield put(upsertLocalTrackerShareAction(trackerShare));
    yield put(upsertLocalTrackerAction(remoteTracker));
  }
  
  // Pull any pending shares by my email
  const pendingTrackerShares = yield fetchRemoteTrackerSharesPendingByUser(user.attributes.email);
  
  for (const pendingShare of pendingTrackerShares) {
    yield put(upsertLocalTrackerShareAction(pendingShare));
  }

  yield put({ type: TRACKER_SHARE_FULL_SYNC_SUCCESS_ACTION });
}

export default function* trackerShareSaga() {
  // Handle Actions
  yield takeLatest(TRACKER_SHARE_CREATE_REQUEST_ACTION, handleCreateTrackerShareRequest);
  yield takeLatest(TRACKER_SHARE_UPDATE_REQUEST_ACTION, handleUpdateTrackerShareRequest);
  yield takeLatest(TRACKER_SHARE_ACCEPT_REQUEST_ACTION, handleTrackerShareAcceptRequest);
  yield takeLatest(TRACKER_SHARE_DECLINE_REQUEST_ACTION, handleTrackerShareDeclineRequest);
  yield takeLatest(TRACKER_SHARE_DELETE_REQUEST_ACTION, handleDeleteTrackerShareRequest);
  yield takeLatest(
    TRACKER_FETCH_SUCCESS_ACTION,
    handleTrackerShareDataLoadRequest
  );

  // Update Storage State
  yield takeLatest(
    APPLICATION_DATA_CLEAR_REQUEST_ACTION,
    handleApplicationDataClear
  );
  yield takeLatest([
    TRACKER_SHARE_FULL_SYNC_REQUEST_ACTION,
    APPLICATION_DATA_REQUEST_ACTION
  ], handleFullSyncRequest);
}
