export const MigrationStruct = {
  schemaVersion: '1.0.0',
  userId: '',
  migrationKey: '',
  completed: ''
};

export const getMigrationStruct = (
  migration = MigrationStruct
) => ({ ...MigrationStruct, ...migration });

export default MigrationStruct;
