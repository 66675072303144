import { API, graphqlOperation } from "aws-amplify";
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { getContactStruct } from "../../structs/contact";
import {prepAndPruneForAppSync} from "../appSync";
import moment from "moment";

export const fetchContactsByUser = async userApn =>
  new Promise(async resolve => {
    let all = [];
    let whileToken = 'initial';
    
    /* eslint-disable no-await-in-loop */
    while (whileToken) {
      const { data: { listContacts: { items, nextToken }}} = await API.graphql(
        graphqlOperation(queries.listContacts, {
          userApn,
          limit: 100,
          sortDirection: 'DESC',
          nextToken: whileToken === 'initial' ? null : whileToken
        })
      );
      all = all.concat(items);
      whileToken = nextToken;
    }
    /* eslint-enable no-await-in-loop */
    
    resolve(
      all.length
        ? all.map(contact => getContactStruct(contact))
        : []
    );
  });

export const fetchContactByEmail = async (userApn, friendEmail) =>
  new Promise(async resolve => {
    let all = [];
    let whileToken = 'initial';

    /* eslint-disable no-await-in-loop */
    while (whileToken) {
      const { data: { listContacts: { items, nextToken }}} = await API.graphql(
        graphqlOperation(queries.listContacts, {
          userApn,
          filter: {
            friendEmail: {
              eq: friendEmail
            }
          },
          limit: 100,
          sortDirection: 'DESC',
          nextToken: whileToken === 'initial' ? null : whileToken
        })
      );
      all = all.concat(items);
      whileToken = nextToken;
    }
    /* eslint-enable no-await-in-loop */

    resolve(
      all.length
        ? all[0]
        : null
    );
  });

export const upsertRemoteContact = async contact => {

  const input = prepAndPruneForAppSync({
    ...contact,
    createdAt: moment().toISOString(),
    updatedAt: moment().toISOString(),
  }, getContactStruct);

  delete input.id;

  const { data: { createContact: item }} = await API.graphql(
    graphqlOperation(mutations.createContact, {
      input
    })
  );
  return getContactStruct(item);
};
