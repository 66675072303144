import React, { Component } from 'react';
import { connect } from 'react-redux';
import CommitmentsList from '../../components/ListsPage/CommitmentList';
import { requestActiveCommitmentAction } from '../../redux/actions/activeCommitment';

export class CommitmentsListContainer extends Component {
  render() {
    return <CommitmentsList />;
  }
}

function mapStateToProps() {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    setActiveCommitment: commitment => {
      dispatch(requestActiveCommitmentAction(commitment));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommitmentsList);
