import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import WarningIcon from '@material-ui/icons/Warning';
import {deleteTrackerShareAction, saveTrackerShareAction} from '../../../redux/actions/trackerShares';
import {getPermissionLevelLabel} from '../../../models/trackerShare';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {TRACKER_SHARE_PERMISSION_CONTRIBUTE, TRACKER_SHARE_PERMISSION_READONLY} from "../../../structs/trackerShare";
import List from "@material-ui/core/List/List";
import {ListItemAvatar} from "@material-ui/core";

const ShareItem = ({item, readOnly, isOwner}) => {
  const contacts = useSelector(store => store.contacts);
  const { attributes: { email }} = useSelector(store => store.user);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [permissionLevel, setPermission] = useState(item.permissionLevel);
  const contact = contacts.find(c => c.friendEmail === item.emailAddress);
  const userName = contact ? contact.friendName : (email === item.emailAddress ? 'Shared with You' : item.emailAddress);

  return (
    <ListItem key={item.id}>

      <Dialog open={open}>
        <DialogTitle>Share Settings</DialogTitle>
        <DialogContent>
          <ButtonGroup>
            <Button
              color="primary"
              variant={permissionLevel === TRACKER_SHARE_PERMISSION_READONLY ? 'contained' : 'outlined'}
              onClick={() => setPermission(TRACKER_SHARE_PERMISSION_READONLY)}
            >
              Read-Only
            </Button>
            <Button
              color="primary"
              variant={permissionLevel === TRACKER_SHARE_PERMISSION_CONTRIBUTE ? 'contained' : 'outlined'}
              onClick={() => setPermission(TRACKER_SHARE_PERMISSION_CONTRIBUTE)}
            >
              Contributor
            </Button>
          </ButtonGroup>
          {(permissionLevel === TRACKER_SHARE_PERMISSION_CONTRIBUTE && item.permissionLevel !== TRACKER_SHARE_PERMISSION_CONTRIBUTE) ? (
            <List>
              <ListItem>
                <ListItemAvatar><WarningIcon/></ListItemAvatar>
                <ListItemText primary="Granting Contributor access will allow this user to edit and save this Tracker."/>
              </ListItem>
            </List>
          ) : null}
          {(permissionLevel === TRACKER_SHARE_PERMISSION_READONLY && item.permissionLevel !== TRACKER_SHARE_PERMISSION_READONLY) ? (
            <List>
              <ListItem>
                <ListItemAvatar><WarningIcon/></ListItemAvatar>
                <ListItemText primary="Assigning Read-only access will revoke edit privileges."/>
              </ListItem>
            </List>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => setOpen(false)}
          >
            {permissionLevel === item.permissionLevel ? 'Close' : 'Cancel'}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={permissionLevel === item.permissionLevel}
            onClick={() => { dispatch(saveTrackerShareAction({...item, permissionLevel})); setOpen(false); }}
          >
            Save Share
          </Button>
        </DialogActions>
      </Dialog>

      <ListItemText
        primary={`${userName} as ${getPermissionLevelLabel(item.permissionLevel)}`}
        secondary={`Status: ${item.status}`}
      />
      {!readOnly && (
        <ListItemSecondaryAction>
          <IconButton onClick={() => setOpen(true)}>
            <EditIcon />
          </IconButton>
          {isOwner && <IconButton onClick={() => dispatch(deleteTrackerShareAction(item))}>
            <DeleteIcon />
          </IconButton>}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

ShareItem.defaultProps = {};

ShareItem.propTypes = {
  item: PropTypes.object.isRequired
};

export default ShareItem;
