import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fade from '@material-ui/core/Fade';

export default function LogoutDialog({ onConfirm }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    // Clear all application data
    onConfirm();

    setOpen(false);
  };

  return (
    <div>
      <div style={{ marginTop: 20 }}>
        <Fade in>
          <Button
            variant="outlined"
            fullWidth={true}
            color="primary"
            onClick={handleClickOpen}
          >
            Log Out
          </Button>
        </Fade>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Log Out?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to logout? The data on this device will be
            removed, but will remain in the Formigio Cloud for when you log in again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            autoFocus
            variant="outlined"
          >
            No
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

LogoutDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired
};
