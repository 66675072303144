import {
  APPLICATION_DATA_LOAD_FAILURE_ACTION,
  APPLICATION_DATA_LOAD_SUCCESS_ACTION,
  APPLICATION_SET_DESIRED_ROUTE_ACTION,
  APPLICATION_SET_ERROR_ACTION, APPLICATION_SET_PAY_GATED_ACTION,
  APPLICATION_SET_PAYMENT_KEY_ACTION,
  APPLICATION_SET_SUBSCRIPTIONS_ACTION, APPLICATION_SET_SYNCING_ACTION,
  APPLICATION_SET_TODAY_ACTION,
  APPLICATION_SET_USER_CHECK_ACTION
} from '../actions/app';
import {
  APP_STATUS_DATA_ERROR,
  APP_STATUS_DATA_LOADED,
  AppStruct
} from '../../structs/app';

export default function appReducer(
  state = Object.assign({}, AppStruct),
  action
) {
  switch (action.type) {
    case APPLICATION_DATA_LOAD_SUCCESS_ACTION:
      return {
        ...state,
        dataStatus: {
          ...state.dataStatus,
          [action.payload]: APP_STATUS_DATA_LOADED
        }
      };

    case APPLICATION_DATA_LOAD_FAILURE_ACTION:
      return {
        ...state,
        dataStatus: {
          ...state.dataStatus,
          [action.payload]: APP_STATUS_DATA_ERROR
        }
      };

    case APPLICATION_SET_DESIRED_ROUTE_ACTION:
      return {
        ...state,
        desiredRoute: action.payload
      };

    case APPLICATION_SET_ERROR_ACTION:
      return {
        ...state,
        error: action.payload
      };

    case APPLICATION_SET_USER_CHECK_ACTION:
      return {
        ...state,
        userCheck: action.payload
      };

    case APPLICATION_SET_PAYMENT_KEY_ACTION:
      return {
        ...state,
        paymentKey: action.payload
      };

    case APPLICATION_SET_SUBSCRIPTIONS_ACTION:
      return {
        ...state,
        subscriptions: action.payload
      };

    case APPLICATION_SET_PAY_GATED_ACTION:
      return {
        ...state,
        payGated: action.payload
      };
      
    case APPLICATION_SET_TODAY_ACTION:
      return {
        ...state,
        today: action.payload
      };

    case APPLICATION_SET_SYNCING_ACTION:
      return {
        ...state,
        syncing: action.payload
      };

    default:
      return state;
  }
}
