import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from "@material-ui/core/IconButton/IconButton";
import {MoreVert} from "@material-ui/icons";
import Menu from "@material-ui/core/Menu";
import ArchiveMenuItem from "./Actions/ArchiveMenuItem";
import DeleteMenuItem from "./Actions/DeleteMenuItem";
import CopyMenuItem from "./Actions/CopyMenuItem";
import CreateTemplateMenuItem from "./Actions/CreateTemplateMenuItem";
import TemplateDialog from "./Actions/TemplateDialog";
import DeleteDialog from "./Actions/DeleteDialog";
import ArchiveDialog from "./Actions/ArchiveDialog";
import {
  TRACKER_ACTION_ARCHIVE,
  TRACKER_ACTION_COPY,
  TRACKER_ACTION_DELETE, TRACKER_ACTION_SHARE,
  TRACKER_ACTION_TEMPLATE
} from "../../structs/trackers";
import CopyDialog from "./Actions/CopyDialog";
import ShareDialog from "./Actions/ShareDialog";
import ShareMenuItem from "./Actions/ShareMenuItem";

const TrackerMenu = ({ tracker, readOnly, isOwner }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialog, setDialog] = useState('');

  const handleOpenMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  }

  const handleDialog = useCallback((dialog) => {
    setDialog(dialog);
    handleMenuClose();
  }, [setDialog]);

  return (
    <>
      <IconButton
        onClick={handleOpenMenuClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <CopyMenuItem onDialog={handleDialog} />
        <CreateTemplateMenuItem onDialog={handleDialog} />
        {(!readOnly && isOwner) && (
          <ArchiveMenuItem tracker={tracker} onDialog={handleDialog} />
        )}
        {(!readOnly && isOwner) && (
          <ShareMenuItem onDialog={handleDialog} />
        )}
        {(!readOnly && isOwner) && (
          <DeleteMenuItem onDialog={handleDialog} />
        )}
      </Menu>
      <CopyDialog tracker={tracker} onDialog={handleDialog} open={ dialog === TRACKER_ACTION_COPY } />
      <DeleteDialog tracker={tracker} onDialog={handleDialog} open={ dialog === TRACKER_ACTION_DELETE } />
      <ArchiveDialog tracker={tracker} onDialog={handleDialog} open={ dialog === TRACKER_ACTION_ARCHIVE } />
      <TemplateDialog tracker={tracker} onDialog={handleDialog} open={ dialog === TRACKER_ACTION_TEMPLATE } />
      <ShareDialog tracker={tracker} onDialog={handleDialog} open={ dialog === TRACKER_ACTION_SHARE } />
    </>
  );
};

TrackerMenu.defaultProps = {};

TrackerMenu.propTypes = {
  tracker: PropTypes.any.isRequired
};

export default TrackerMenu;
