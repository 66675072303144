import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import CalendarCarousel from '../Ui/CalendarCarousel';
import { JournalIndexStruct } from '../../structs/indexers';

export const style = () => ({
  journalEntryItem: {
    borderLeft: 'solid grey 4px',
    borderRadius: 5,
    marginBottom: 5
  },
  container: {
    width: 'calc(100vw - 68px)',
    display: 'flex',
    flexDirection: 'row',
    marginTop: 23
  },
  contentContainer: {
    marginRight: '1em',
    display: 'flex'
  }
});

class JournalPage extends Component {
  state = {
    currentDate: new Date()
  };

  handleDateSelection = date => {
    this.setState({ currentDate: date });
  };

  render() {
    const { currentDate } = this.state;
    const { journalIndex, classes } = this.props;
    const docs = journalIndex.byDate[currentDate.toDateString()] || [];
    const pageDate = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: '2-digit'
    }).format(currentDate);

    return (
      <div className={classes.container}>
        <div style={{ width: '100%' }}>
          <Typography variant="h5">Journal Entries for: {pageDate}</Typography>
          <CalendarCarousel
            selectedDate={currentDate}
            onSelect={this.handleDateSelection}
          />
          <div className={classes.contentContainer}>
            <List>
              {docs.length ? (
                docs.map(doc => {
                  const { entity: entry } = doc;
                  return (
                    <ListItem
                      key={entry.id}
                      className={classes.journalEntryItem}
                    >
                      <ListItemText
                        style={{ whiteSpace: 'pre-line' }}
                        primary={entry.entry}
                        secondary={`${new Date(
                          entry.datetime
                        ).toLocaleString()} - Tracker: ${doc.dream}`}
                      />
                    </ListItem>
                  );
                })
              ) : (
                <ListItem key="no-entries">
                  <ListItemText primary="There are no Journal Entries for this date" />
                </ListItem>
              )}
            </List>
          </div>
        </div>
      </div>
    );
  }
}

JournalPage.defaultProps = {
  journalIndex: JournalIndexStruct
};

JournalPage.propTypes = {
  classes: PropTypes.any.isRequired,
  journalIndex: PropTypes.any
};

export default withStyles(style)(JournalPage);
