import trackerSaga from "./tracker";
import changeLogSaga from "./changeLog";
import dataSyncSaga from "./dataSync";
import appSyncSaga from "./appSync";
import syncEventSaga from "./syncEvent";
import activeTrackerSaga from "./activeTracker";
import activeMilestoneSaga from "./activeMilestone";
import activeMilestonesSaga from "./activeMilestones";
import activeCommitmentSaga from "./activeCommitment";
import commitmentSaga from "./commitment";
import journalSaga from "./journal";
import checklistSaga from "./checklist";
import userSaga from "./user";
import timerSaga from "./timer";
import backupProfileSaga from "./backupProfile";
import tourSaga from "./tour";
import syncSaga from "./sync";
import learnPageSaga from "./learnPage";
import authSaga from "./auth";
import paymentSaga from "./payment";
import diagnosticsSaga from "./diagnostics";
import deviceSyncSaga from "./deviceSync";
import searchSaga from "./search";
import notificationSaga from "./notification";
import trackerShareSaga from './trackerShare';
import trackerActivitySaga from "./trackerActivity";
import commitmentTraySaga from "./commitmentTray";
import contactSaga from "./contact";
import commitmentNotificationsSaga from "./commitmentNotifications";
import trackerTemplateSaga from "./trackerTemplate";
import commitInviteSaga from "./commitInvite";

const sagaList = [
  paymentSaga,
  authSaga,
  userSaga,
  trackerSaga,
  commitmentSaga,
  journalSaga,
  learnPageSaga,
  checklistSaga,
  timerSaga,
  backupProfileSaga,
  tourSaga,
  syncSaga,
  changeLogSaga,
  dataSyncSaga,
  appSyncSaga,
  syncEventSaga,
  activeTrackerSaga,
  activeMilestoneSaga,
  activeMilestonesSaga,
  activeCommitmentSaga,
  diagnosticsSaga,
  deviceSyncSaga,
  searchSaga,
  notificationSaga,
  trackerShareSaga,
  trackerActivitySaga,
  commitmentTraySaga,
  contactSaga,
  commitmentNotificationsSaga,
  trackerTemplateSaga,
  commitInviteSaga
];
export default sagaList;
