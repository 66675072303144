import React from 'react';
import PropTypes from 'prop-types';
import {MenuItem} from "@material-ui/core";
import {TRACKER_ACTION_COPY} from "../../../structs/trackers";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {FilterNone} from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";

const CopyMenuItem = ({ onDialog }) => {
  
  const handleCopy = () => {
    onDialog(TRACKER_ACTION_COPY)
  };

  return (
    <MenuItem
      variant="text"
      color="secondary"
      onClick={handleCopy}
    >
      <ListItemIcon>
        <FilterNone fontSize={"small"}/>
      </ListItemIcon>
      <ListItemText>Copy</ListItemText>
    </MenuItem>
  )
};

CopyMenuItem.defaultProps = {};

CopyMenuItem.propTypes = {
  tracker: PropTypes.object.isRequired
};

export default CopyMenuItem;
