export const ROLE_NO_ROLE_NAME = 'No role';

export const RoleStruct = {
  name: '',
  color: ''
}

export const getRoleStruct = (role = RoleStruct) => ({
  ...RoleStruct,
  ...role
});

