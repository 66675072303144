import {getReviewCycleStruct, ReviewCycleStruct} from './reviewCycle';
import {getRoleStruct, RoleStruct} from "./roles";

export const TRACKER_SHARE_STATUS_PENDING = 'pending';
export const TRACKER_SHARE_STATUS_ACTIVE = 'active';
export const TRACKER_SHARE_STATUS_DECLINED = 'declined';

export const TRACKER_SHARE_PERMISSION_READONLY = 'readonly';
export const TRACKER_SHARE_PERMISSION_CONTRIBUTE = 'contribute';

export const TRACKER_SHARE_PERMISSIONS = {
  [TRACKER_SHARE_PERMISSION_READONLY]: 'Read-only User',
  [TRACKER_SHARE_PERMISSION_CONTRIBUTE]: 'Contributor (Edit Access)',
};

export const TrackerShare = {
  schemaVersion: '1.0.0',
  id: '',
  trackerId: '',
  ownerApn: '',
  ownerEmailAddress: '',
  ownerName: '',
  emailAddress: '',
  userId: '',
  status: TRACKER_SHARE_STATUS_PENDING,
  permissionLevel: TRACKER_SHARE_PERMISSION_READONLY,
  notifications: [],
  reviewCycle: ReviewCycleStruct,
  role: RoleStruct,
  shareDescription: ''
};

export const getTrackerShareStruct = (trackerShare = TrackerShare) =>
  Object.assign({}, TrackerShare, {
    ...trackerShare,
    reviewCycle: getReviewCycleStruct(trackerShare.reviewCycle || ReviewCycleStruct),
    role: getRoleStruct(trackerShare.role)
  });
