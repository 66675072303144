import { API, graphqlOperation } from 'aws-amplify';
import { getMembershipStatus } from '../../graphql/queries';
import {
  createMembershipStatus,
  updateMembershipStatus
} from '../../graphql/mutations';
import {
  getMembershipStatusStruct,
  MembershipStatusStruct
} from '../../structs/membership';
import {
  getStripeSubscriptionStruct,
  StripeSubscriptionStruct
} from '../../structs/stripe';

/*
 *
 * Membership Status Functions
 *
 */
export const fetchRemoteMembershipStatus = async user => {
  const {
    data: { getMembershipStatus: status }
  } = await API.graphql(
    graphqlOperation(getMembershipStatus, {
      id: user.id
    })
  );
  return postFetchProcessMembershipStatus(status);
};

export const upsertRemoteMembershipStatus = async membershipStatus => {
  const currentStatus = await fetchRemoteMembershipStatus(membershipStatus);

  if (!currentStatus.id) {
    const input = prepAndPruneMembershipStatusForAppSync(membershipStatus);
    const {
      data: { createMembershipStatus: status }
    } = await API.graphql(
      graphqlOperation(createMembershipStatus, {
        input
      })
    );
    return postFetchProcessMembershipStatus(status);
  }

  const input = prepAndPruneMembershipStatusForAppSync(membershipStatus);
  const {
    data: { updateMembershipStatus: status }
  } = await API.graphql(
    graphqlOperation(updateMembershipStatus, {
      input
    })
  );
  return postFetchProcessMembershipStatus(status);
};

/*
 *
 * Post Process Functions
 *
 */
const postFetchProcessMembershipStatus = status => {
  // Pass fetched value through the Struct, never trust input. :)
  const processedStatus = getMembershipStatusStruct(status);

  return processedStatus;
};

/*
 *
 * Pre-Process Functions
 *
 */
export const prepAndPruneMembershipStatusForAppSync = membershipStatus => {
  // Prune any keys that are not in the schema
  const keys = Object.keys(MembershipStatusStruct);
  const pruned = getMembershipStatusStruct(membershipStatus);

  const prunedKeys = Object.keys(pruned);
  const propertiesToPrune = prunedKeys.filter(key => !keys.includes(key));
  if (propertiesToPrune.length)
    propertiesToPrune.map(key => delete pruned[key]);

  // Prep any empty strings
  keys.map(key => {
    if (typeof pruned[key] === 'string' && pruned[key] === '') {
      pruned[key] = ' ';
    }
    return true;
  });

  pruned.stripeSubscription = prepAndPruneStripeSubscriptionForAppSync(
    pruned.stripeSubscription
  );

  return pruned;
};

export const prepAndPruneStripeSubscriptionForAppSync = stripeSubscription => {
  // Prune any keys that are not in the schema
  const keys = Object.keys(StripeSubscriptionStruct);
  const pruned = getStripeSubscriptionStruct(stripeSubscription);

  const prunedKeys = Object.keys(pruned);
  const propertiesToPrune = prunedKeys.filter(key => !keys.includes(key));
  if (propertiesToPrune.length)
    propertiesToPrune.map(key => delete pruned[key]);

  // Prep any empty strings
  keys.map(key => {
    if (typeof pruned[key] === 'string' && pruned[key] === '') {
      pruned[key] = ' ';
    }
    return true;
  });

  return pruned;
};
