import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import DragHandle from '@material-ui/icons/DragHandle';
import CloseIcon from '@material-ui/icons/Close';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PostAddIcon from '@material-ui/icons/PostAdd';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {getActionItemStruct} from '../../../../structs/trackers';
import CommitButton from '../../../../containers/Commitment/CommitButton';
import debounce from 'lodash/debounce';
import Button from '@material-ui/core/Button';
import {getContextStruct} from "../../../../structs/context";

const style = () => ({
  dragHandle: {
    margin: '15px -9px 12px -27px',
    color: 'lightgray'
  },
  checkboxButton: {
    padding: '12px 10px',
    margin: '4px 4px 0 0px'
  }
});

class ActionItemItem extends Component {
  state = { item: getActionItemStruct(), deleteConfirm: false };

  componentDidMount() {
    const { item: itemProp } = this.props;
    const { item } = this.state;

    if ((item && item.action !== itemProp.action) || !item) {
      this.hydrateState(itemProp);
    }
  }

  componentDidUpdate() {
    this.focusSelected();
  }

  inputField;

  focusSelected = () => {
    const { selectedItem, item } = this.props;

    if (
      selectedItem === item.seq &&
      document.activeElement !== this.inputField
    ) {
      this.inputField.focus();
    }
  };

  hydrateState = item => {
    this.setState({
      item: getActionItemStruct(item)
    });
  };
  
  debounceSave = debounce(() => {
    const { item } = this.state;
    const { onChange } = this.props;
    onChange(item);
  }, 650);
  
  handleChange = event => {
    const { item } = this.state;
    if (event.target.name === 'checked') {
      item[event.target.name] = event.target.checked;
    } else {
      item[event.target.name] = event.target.value;
    }

    this.setState({ item: {...item} }, () => {
      this.debounceSave();
    });
  };

  handleStageRemove = () => {
    this.setState({deleteConfirm: true});
  };

  handleRemove = () => {
    const { onRemove, item } = this.props;
    onRemove(item);
  };

  handleFocus = () => {
    const { onInputFocus } = this.props;
    const { item } = this.state;
    if (item) {
      onInputFocus(item.seq);
    }
  };

  handleBlur = () => {
    const { onInputBlur } = this.props;
    const { item } = this.state;
    onInputBlur(item.seq);
  };

  render() {
    const { selectedItem, classes, commitmentContext: { description: contextDescription, apn: contextApn, tracker }, readOnly } = this.props;
    const { item, deleteConfirm } = this.state;
    const selected = selectedItem === item.seq;

    return (
      <ListItem
        selected={selected}
        className={classes.listPadding}
        classes={{ root: classes.lessPadding }}
      >
        <DragHandle className={classes.dragHandle} />
        <Checkbox
          name="checked"
          className={classes.checkboxButton}
          checked={item.checked}
          disabled={readOnly}
          tabIndex={-1}
          disableRipple
          onChange={this.handleChange}
          icon={
            <CheckBoxOutlineBlankIcon
              style={{ color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
        />
        <TextField
          multiline
          style={{ width: '90%' }}
          name="action"
          disabled={readOnly}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          margin="normal"
          value={item.action}
          InputProps={{
            disableUnderline: true
          }}
          inputRef={field => {
            this.inputField = field;
          }}
        />
        {deleteConfirm
          ? <ListItemSecondaryAction>
            <IconButton size="small" onClick={() => this.setState({deleteConfirm: false})}>
              <CloseIcon/>
            </IconButton>
            <Button size="small" variant="outlined" color="primary" onClick={this.handleRemove}>
              DELETE
            </Button>
          </ListItemSecondaryAction>
          : <ListItemSecondaryAction>
            {selected && !readOnly
              ? <IconButton size="small" onClick={this.handleStageRemove}>
                <DeleteForeverIcon/>
              </IconButton>
              : <CommitButton
                icon
                context={{
                  apn: contextApn,
                  description: `${contextDescription} - ${item.action}`,
                  tracker
                }}
              >
                <PostAddIcon/>
              </CommitButton>
            }
          </ListItemSecondaryAction>
        }
      </ListItem>
    );
  }
}

ActionItemItem.defaultProps = {
  selectedItem: null,
  commitmentContext: getContextStruct(),
};

ActionItemItem.propTypes = {
  classes: PropTypes.any.isRequired,
  item: PropTypes.any.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onInputFocus: PropTypes.func.isRequired,
  onInputBlur: PropTypes.func.isRequired,
  selectedItem: PropTypes.any,
  commitmentContext: PropTypes.object
};

export default withStyles(style)(ActionItemItem);
