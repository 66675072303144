// Membership Actions

import {getMembershipStatusStruct} from '../../structs/membership';

export const SET_MEMBERSHIP_STATUS = 'SET_MEMBERSHIP_STATUS';
export const MEMBERSHIP_FETCH_STATUS_ACTION =
  'MEMBERSHIP_FETCH_STATUS';
export const MEMBERSHIP_FETCH_STATUS_SUCCESS_ACTION =
  'MEMBERSHIP_FETCH_STATUS_SUCCESS';
export const MEMBERSHIP_UPSERT_STATUS_ACTION =
  'MEMBERSHIP_UPSERT_STATUS';
export const MEMBERSHIP_UPSERT_STATUS_SUCCESS_ACTION =
  'MEMBERSHIP_UPSERT_STATUS_SUCCESS';

export const setMembershipStatusAction = status => ({
  type: SET_MEMBERSHIP_STATUS,
  payload: getMembershipStatusStruct(status)
});

export const fetchMembershipStatusAction = () => ({
  type: MEMBERSHIP_FETCH_STATUS_ACTION
});

export const upsertMembershipStatusAction = () => ({
  type: MEMBERSHIP_UPSERT_STATUS_ACTION
});
