import SubscriptionStruct, {getSubscriptionStruct} from './subscription';
import StripeSubscriptionStruct, {getStripeSubscriptionStruct} from "./stripe";

// User is not logged in
export const USER_STATUS_LOGGED_OUT = '';

// User is Logged in (Normal status of active user)
export const USER_STATUS_LOGGED_IN = 'loggedIn';

// User started registration, but needs to confirm with code
export const USER_STATUS_REGISTERED = 'registered';

// User has finished registration
export const USER_STATUS_CONFIRMED = 'confirmed';

// User has submitted for a new password
export const USER_STATUS_NOT_CONFIRMED = 'notConfirmed';
export const USER_STATUS_FORGOT = 'forgot';

// User has finished the password reset, and needs to login
export const USER_STATUS_LOGIN_REQUIRED = 'forceLogin';

export const UserCredentialsStruct = {
  username: '',
  password: '',
  verificationCode: ''
};

export const UserStruct = {
  schemaVersion: '1.1.0',
  id: '',
  name: '',
  credentials: UserCredentialsStruct,
  notes: '',
  attributes: {
    username: '',
    familyName: '',
    email: '',
    givenName: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    emailVerified: false,
    userConfirmed: false
  },
  registrationDate: '',
  status: USER_STATUS_LOGGED_OUT, // registered, confirmed, loggedIn
  activeSubscription: SubscriptionStruct,
  stripeSubscription: StripeSubscriptionStruct,
  memberStatus: {
    status: '',
    label: '',
    endDate: ''
  },
  lastLoginTime: '',
  updatedAt: ''
};

export const getUserStruct = (user = UserStruct) =>
  ({
    ...UserStruct,
    ...user,
    attributes: {...user.attributes},
    credentials: {...user.credentials},
    stripeSubscription: getStripeSubscriptionStruct(user.stripeSubscription),
    activeSubscription: getSubscriptionStruct(user.activeSubscription)
  });

export default UserStruct;
