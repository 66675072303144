import { StripeSubscriptionStruct } from './stripe';

export const MembershipStatusStruct = {
  schemaVersion: '1.0.0',
  id: '',
  subscriptionLevel: '',
  subscriptionCode: '',
  subscriptionId: 'none',
  paymentVerified: false,
  lastLoginTime: '',
  stripeSubscription: StripeSubscriptionStruct
};

export const getMembershipStatusStruct = (
  membershipStatus = MembershipStatusStruct
) => ({ ...MembershipStatusStruct, ...membershipStatus });

export default MembershipStatusStruct;
