import { all, take, put, takeEvery} from 'redux-saga/effects';
import moment from 'moment';

import {
  ADD_EMAIL_NOTIFICATION_ACTION,
  ADD_SMS_NOTIFICATION_ACTION,
} from '../actions/notifications';
import { NOTIFICATION_TOPIC_COMMITMENT_REMIND } from '../../structs/notification';
import {COMMITMENT_DELETE_REMOTE_SUCCESS_ACTION, COMMITMENT_UPSERT_REMOTE_SUCCESS_ACTION} from "../actions/appSync";
import {getCommitmentApn} from "../../utils/apn/v2";
import {
  deleteRemoteSMSNotification,
  fetchSMSNotificationsByEntity
} from "../../utils/appSync/smsNotification";
import {deleteRemoteEmailNotification, fetchEmailNotificationsByEntity} from "../../utils/appSync/emailNotification";
import {
  COMMITMENT_CLEAR_NOTIFICATIONS_REQUEST_ACTION,
  COMMITMENT_CLEAR_NOTIFICATIONS_SUCCESS_ACTION,
  COMMITMENT_CREATE_NOTIFICATIONS_REQUEST_ACTION,
  COMMITMENT_CREATE_NOTIFICATIONS_SUCCESS_ACTION, COMMITMENT_CREATE_SUCCESS_ACTION,
  COMMITMENT_UPDATE_SUCCESS_ACTION
} from "../actions/commitments";
import {COMMITMENT_STATUS_PENDING} from "../../structs/commitments";

function* handleCreateCommitmentSuccess({ payload: { commitment }}) {

  yield take(COMMITMENT_UPSERT_REMOTE_SUCCESS_ACTION);

  yield put({
    type: COMMITMENT_CREATE_NOTIFICATIONS_REQUEST_ACTION,
    payload: commitment
  });
}

function* handleCreateCommitmentNotificationsRequest({ payload: commitment }) {
  const { reminders: { email, sms }} = commitment;

  if (sms) {
    yield(put({
      type: ADD_SMS_NOTIFICATION_ACTION,
      payload: {
        summaryText: `It is time! Reminder about: ${commitment.description}, you asked to be reminded at ${moment(commitment.date).format('LT')}`,
        sendTimestamp: moment(commitment.date).subtract(3, 'minutes'),
        topic: [NOTIFICATION_TOPIC_COMMITMENT_REMIND],
        entityApn: getCommitmentApn(commitment)
      }
    }));
  }

  if (email) {
    yield(put({
      type: ADD_EMAIL_NOTIFICATION_ACTION,
      payload: {
        summaryText: `Commitment Reminder: ${commitment.description}`,
        payload: `<h1>It is time!</h1>
            <p>Reminder about: ${commitment.description}. You asked to be reminded at ${moment(commitment.date).format('LT')}</p>
            <p></p>
            <p>Sincerly,</p>
            <p>Your friendly helper - Formigio :)</p>
        `,
        sendTimestamp: moment(commitment.date).subtract(3, 'minutes'),
        topic: [NOTIFICATION_TOPIC_COMMITMENT_REMIND],
        entityApn: getCommitmentApn(commitment)
      }
    }));
  }

  yield put({
    type: COMMITMENT_CREATE_NOTIFICATIONS_SUCCESS_ACTION
  });
}

function* handleDeleteCommitmentSuccess({ payload: commitment }) {
  // Clean up commitment reminder notifications
  const { userId: userApn, reminders: { sms, email }} = commitment;

  if (sms) {
    const notifications = yield fetchSMSNotificationsByEntity(userApn, getCommitmentApn(commitment));
    yield all(notifications.map(notification => deleteRemoteSMSNotification(notification)));
  }

  if (email) {
    const notifications = yield fetchEmailNotificationsByEntity(userApn, getCommitmentApn(commitment));
    yield all(notifications.map(notification => deleteRemoteEmailNotification(notification)));
  }

  yield put({
    type: COMMITMENT_CLEAR_NOTIFICATIONS_SUCCESS_ACTION
  });
}

function* handleUpdateCommitmentSuccess({ payload: { commitment, previousCommitment }}) {

  yield take(COMMITMENT_UPSERT_REMOTE_SUCCESS_ACTION);

  if (previousCommitment.description !== commitment.description
    || previousCommitment.date !== commitment.date
    || previousCommitment.reminders.email !== commitment.reminders.email
    || previousCommitment.reminders.sms !== commitment.reminders.sms
    || previousCommitment.status !== commitment.status
  ) {
    yield put({
      type: COMMITMENT_CLEAR_NOTIFICATIONS_REQUEST_ACTION,
      payload: commitment
    });

    yield take(COMMITMENT_CLEAR_NOTIFICATIONS_SUCCESS_ACTION);

    if (commitment.status === COMMITMENT_STATUS_PENDING) {
      yield put({
        type: COMMITMENT_CREATE_NOTIFICATIONS_REQUEST_ACTION,
        payload: commitment
      });
    }
  }
}

export default function* commitmentNotificationsSaga() {
  yield takeEvery(
    COMMITMENT_CREATE_SUCCESS_ACTION,
    handleCreateCommitmentSuccess
  );

  yield takeEvery(
    COMMITMENT_CREATE_NOTIFICATIONS_REQUEST_ACTION,
    handleCreateCommitmentNotificationsRequest
  );

  yield takeEvery(
    [
      COMMITMENT_DELETE_REMOTE_SUCCESS_ACTION,
      COMMITMENT_CLEAR_NOTIFICATIONS_REQUEST_ACTION
    ],
    handleDeleteCommitmentSuccess
  );

  yield takeEvery(
    COMMITMENT_UPDATE_SUCCESS_ACTION,
    handleUpdateCommitmentSuccess
  );

}
