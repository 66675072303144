import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import Amplify from 'aws-amplify';
import awsConfig from '../aws-exports';
import { createBrowserHistory } from 'history';
import Routes from '../Routes';
import { configureStore } from '../store/configureStore';

Amplify.configure(awsConfig);

export default class Root extends Component {
  render() {
    const history = createBrowserHistory();
    const store = configureStore(history);
    
    history.listen(location => {
      window.gtag("page_view", {
        page_title: location.pathname,
        page_location: location.pathname,
      })
    });

    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
      </Provider>
    );
  }
}
