import { v4 as uuidv4 } from 'uuid';
import { put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import debounce from 'lodash/debounce';

import {
  ADD_SYNC_EVENT,
  LOAD_ALL_SYNC_EVENTS,
  loadAllSyncEventsAction,
  REMOVE_SYNC_EVENT,
  removeSyncEventAction,
  SYNC_EVENT_CREATE_REQUEST_ACTION,
  SYNC_EVENT_CREATE_SUCCESS_ACTION,
  SYNC_EVENT_DATA_LOAD_REQUEST_ACTION,
  SYNC_EVENT_DATA_LOAD_SUCCESS_ACTION,
  SYNC_EVENT_SYNC_REQUEST_ACTION,
  SYNC_EVENT_SYNC_SUCCESS_ACTION,
  syncAllSyncEventsAction
} from '../actions/syncEvents';
import { getSyncEventStruct } from '../../structs/sync';
import { loadFromStorage } from '../../utils/localStorage';
import {
  APPLICATION_DATA_CLEAR_REQUEST_ACTION,
  APPLICATION_DATA_REQUEST_ACTION
} from '../actions/app';
import { INTERNET_STATUS_CONNECTED } from '../../structs/notification';
import { setProcessingAction } from '../actions/notifications';

function* handleCreateSyncEventRequest(action) {
  // Persist SyncEvent to Remote Data Store
  const syncEvent = getSyncEventStruct(action.payload);
  syncEvent.id = uuidv4();

  yield put({ type: ADD_SYNC_EVENT, payload: syncEvent });

  yield put({ type: SYNC_EVENT_CREATE_SUCCESS_ACTION });
}

function* handleSyncEventDataLoadRequest(action) {
  // Load data in to the store
  yield put({
    type: LOAD_ALL_SYNC_EVENTS,
    payload: action.payload
  });

  yield put({ type: SYNC_EVENT_DATA_LOAD_SUCCESS_ACTION });
}

function* handleLoadApplicationDataRequest() {
  // Perform Application Data Load
  const syncEventsState = yield select(store => store.syncEvents);

  // Call the Util Local Storage loadState function which pulls data
  // from the local storage if cached there, if not it pulls from the disk
  const syncEvents = yield loadFromStorage('syncEvents', syncEventsState);

  yield put(loadAllSyncEventsAction(syncEvents));
}

function* handleStateChange() {
  const type = 'syncEvents';

  // Select data from the store
  const data = yield select(store => store[type]);
  debounceSave(type, data);
}

const debounceSave = debounce(
  (type, data) => {
    const dataString = JSON.stringify(data);

    // As well as in the local storage.
    localStorage.setItem(type, dataString);
  },
  3000,
  { leading: false, trailing: true, maxWait: 10000 }
);

function* handleApplicationDataClear() {
  yield put(loadAllSyncEventsAction([]));
}

function* handleOfflineSyncRequest() {
  // If there is still no internet skip syncing
  const internetStatus = yield select(store => store.internetStatus);
  if (internetStatus.status !== INTERNET_STATUS_CONNECTED) return;

  // Process Sync Events
  const syncEvents = yield select(store => store.syncEvents);

  if (syncEvents.length) {
    yield put(setProcessingAction(`Processing ${syncEvents.length} events...`));
    const firstSyncEvent = syncEvents.shift();
    yield put({ type: firstSyncEvent.action, payload: firstSyncEvent.data });
    yield take(firstSyncEvent.success);
    yield put(removeSyncEventAction(firstSyncEvent));
    if (syncEvents.length) {
      yield put(syncAllSyncEventsAction());
    } else {
      yield put({ type: SYNC_EVENT_SYNC_SUCCESS_ACTION });
      yield put(setProcessingAction(''));
    }
  } else {
    yield put({ type: SYNC_EVENT_SYNC_SUCCESS_ACTION });
  }
}

export default function* syncEventSaga() {
  yield takeEvery(
    SYNC_EVENT_CREATE_REQUEST_ACTION,
    handleCreateSyncEventRequest
  );
  yield takeLatest(
    SYNC_EVENT_DATA_LOAD_REQUEST_ACTION,
    handleSyncEventDataLoadRequest
  );
  yield takeLatest(SYNC_EVENT_CREATE_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(
    APPLICATION_DATA_REQUEST_ACTION,
    handleLoadApplicationDataRequest
  );
  yield takeLatest(SYNC_EVENT_DATA_LOAD_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(REMOVE_SYNC_EVENT, handleStateChange);
  yield takeLatest(
    APPLICATION_DATA_CLEAR_REQUEST_ACTION,
    handleApplicationDataClear
  );
  yield takeLatest(SYNC_EVENT_SYNC_REQUEST_ACTION, handleOfflineSyncRequest);
}
