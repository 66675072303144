import React from 'react';
import {v4 as uuidv4} from "uuid";
import Typography from '@material-ui/core/Typography';
import { put, select, all, takeEvery, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import {
  ADD_LOCAL_MILESTONE,
  REMOVE_LOCAL_MILESTONE,
  SAVE_LOCAL_MILESTONE,
  MILESTONE_CREATE_REQUEST_ACTION,
  MILESTONE_CREATE_SUCCESS_ACTION,
  MILESTONE_DELETE_REQUEST_ACTION,
  MILESTONE_DELETE_SUCCESS_ACTION,
  MILESTONE_UPDATE_REQUEST_ACTION,
  MILESTONE_UPDATE_SUCCESS_ACTION,
  updateMilestoneAction,
  removeMilestoneAction,
  UPSERT_LOCAL_MILESTONE,
  MILESTONE_UPSERT_SUCCESS_ACTION,
  MILESTONE_UPSERT_REQUEST_ACTION,
  setMilestonesAction,
  addMilestoneAction, updateMilestoneIndexAction
} from '../actions/milestones';
import {
  getMilestoneStruct,
  migrateMilestone,
  MilestoneStruct,
  requiresMigration,
  TRACKER_STATUS_ARCHIVED
} from '../../structs/trackers';
import {
  saveTrackerAction,
  TRACKER_COPY_SUCCESS_ACTION,
  TRACKER_DELETE_SUCCESS_ACTION, TRACKER_MIGRATE_SUCCESS_ACTION,
  upsertLocalTrackerAction
} from '../actions/trackers';
import {
  RESET_ACTIVE_TRACKER,
  SET_ACTIVE_TRACKER, setActiveTrackerAction
} from '../actions/activeTracker';
import {addNotificationAction} from '../actions/notifications';
import {getSystemNotificationStruct} from '../../structs/notification';
import {TRACKER_UPSERT_REMOTE_SUCCESS_ACTION} from '../actions/appSync';

function* handleCreateMilestoneRequest(action) {
  
  // Check for un-archiving against limits
  const activeTrackerDoc = yield select(store => store.activeTracker);
  const { tracker: activeTracker } = activeTrackerDoc;
  if (activeTracker.status === TRACKER_STATUS_ARCHIVED) {
    yield put(
      addNotificationAction(
        getSystemNotificationStruct({
          message: (
            <div>
              <Typography>
                {`Hmm... you can't update an archived tracker. If you want to change the tracker
                need make it active first. Any changes made will not be saved.`}
              </Typography>
            </div>
          ),
          type: 'dialog',
          level: 'warning'
        })
      )
    );
    yield put(setActiveTrackerAction(activeTracker));
    return 0;
  }
  
  // Persist Milestone to Local Data Store
  const milestone = getMilestoneStruct(action.payload);
  milestone.id = uuidv4();
  milestone.createdAt = moment().format();
  milestone.updatedAt = milestone.createdAt;

  yield put({
    type: ADD_LOCAL_MILESTONE,
    payload: milestone
  });

  // Signal Complete
  yield put({
    type: MILESTONE_CREATE_SUCCESS_ACTION,
    payload: milestone
  });
}

function* handleUpdateMilestoneRequest(action) {
  // Persist Milestone to Local Data Store
  const milestone = getMilestoneStruct(action.payload);
  milestone.updatedAt = moment().format();

  yield put({
    type: SAVE_LOCAL_MILESTONE,
    payload: milestone
  });

  // Signal Complete
  yield put({
    type: MILESTONE_UPDATE_SUCCESS_ACTION,
    payload: milestone
  });
}

function* handleUpsertMilestoneRequest(action) {
  // Persist Milestone to Local Data Store
  const milestone = getMilestoneStruct(action.payload);

  yield put({
    type: UPSERT_LOCAL_MILESTONE,
    payload: milestone
  });

  // Signal Complete
  yield put({
    type: MILESTONE_UPSERT_SUCCESS_ACTION,
    payload: milestone
  });
}

function* handleDeleteMilestoneRequest({ payload: milestone }) {
  // Persist Milestone to Local Data Store
  yield put({
    type: REMOVE_LOCAL_MILESTONE,
    payload: milestone
  });

  // Signal Complete
  yield put({
    type: MILESTONE_DELETE_SUCCESS_ACTION,
    payload: milestone
  });
}

function* handleTrackerMilestoneChange() {
  const milestones = yield select(store => store.activeMilestones);
  const { tracker } = yield select(store => store.activeTracker);
  
  if (!tracker.id) return;
  
  const trackerMilestones = milestones.filter(milestone => milestone.trackerId === tracker.id);
  
  const trackerObj = Object.assign({}, tracker, {
    milestones: trackerMilestones,
    updatedAt: moment().format()
  });
  
  yield put(upsertLocalTrackerAction(trackerObj));
}

function* handleTrackerMilestoneDelete({ payload: milestone }) {
  const milestones = yield select(store => store.activeMilestones);
  const { tracker } = yield select(store => store.activeTracker);
  const { plans } = tracker;
  const trackerMilestones = milestones.filter(m => m.trackerId === tracker.id);
  
  const trackerPlans = plans.map(plan => {
    if (plan.apn === milestone.planApn && tracker.id === milestone.trackerId) {
      const milestoneIds = plan.milestoneIds.filter(milestoneId => milestoneId !== milestone.id);
      return {...plan, milestoneIds};
    }
    return plan;
  });
  
  const trackerObj = Object.assign({}, tracker, {
    milestones: trackerMilestones,
    plans: trackerPlans,
    updatedAt: moment().format()
  });
  
  yield put(saveTrackerAction(trackerObj));
}

function* handleTrackerMilestoneCreate({ payload: milestone }) {
  const milestones = yield select(store => store.activeMilestones);
  const { tracker } = yield select(store => store.activeTracker);
  const { plans } = tracker;
  const trackerMilestones = milestones.filter(m => m.trackerId === tracker.id);
  
  const trackerPlans = plans.map(plan => {
    if (plan.apn === milestone.planApn && tracker.id === milestone.trackerId) {
      plan.milestoneIds.push(milestone.id);
    }

    // Check for milestone plan integrity
    trackerMilestones.map(mc => {
      if (plan.apn === mc.planApn && tracker.id === mc.trackerId && plan.milestoneIds.indexOf(mc.id) === -1) {
        plan.milestoneIds.push(mc.id);
      }
      return mc;
    });
  
    return plan;
  });
  
  const trackerObj = Object.assign({}, tracker, {
    milestones: trackerMilestones,
    plans: trackerPlans,
    updatedAt: moment().format()
  });
  
  yield put(saveTrackerAction(trackerObj));
}

function* handleDeleteTrackerMilestones(action) {
  const tracker = action.payload;
  const { milestones } = tracker;
  if (milestones && milestones.length)
    yield all(
      milestones.map(milestone => put(removeMilestoneAction(milestone)))
    );
}

function* handleCopyTrackerMilestones(action) {
  const { sourceTracker, newTracker } = action.payload;
  const { milestones } = sourceTracker;
  if (milestones && milestones.length)
    yield all(
      milestones.map(milestone =>
        put(addMilestoneAction(milestone, newTracker))
      )
    );
}

function* handleMigrateTrackerMilestones({ payload: tracker }) {
  const { milestones } = tracker;
  if (milestones && milestones.length){
    const milestonesToMigrate = milestones
      .filter(m => requiresMigration(m, MilestoneStruct));
  
    yield all(
      milestonesToMigrate
        .map(milestone => {
          const migratedMilestone = migrateMilestone(milestone, tracker);
          return put(updateMilestoneAction(migratedMilestone));
        })
    );
  }
}

function* handleUpdateTrackerPlanMilestones({ payload: tracker }) {
  const { milestones, plans } = tracker;
  if (milestones && milestones.length){
    yield all(
      milestones
        .filter(milestone => {
          const plan = plans.find(p => p.milestoneIds.includes(milestone.id));
          const planApn = plan ? plan.apn : plans[0].apn;
          return planApn !== milestone.planApn;
        })
        .map(milestone => {
          const plan = plans.find(p => p.milestoneIds.includes(milestone.id));
          const planApn = plan ? plan.apn : plans[0].apn;
          return put(updateMilestoneAction({...milestone, planApn}));
        })
    );
  }
}

function* handleSetActiveMilestones({ payload: tracker }) {
  yield put(setMilestonesAction(tracker.milestones));
}

function* handleUpdateMilestoneIndex({ payload: milestone }) {
  const milestoneIndex = yield select(store => store.milestoneIndex);
  yield put(updateMilestoneIndexAction({
    ...milestoneIndex,
    byId: {
      ...milestoneIndex.byId,
      [milestone.id]: {...milestone}
    }
  }));
}

function* handleResetActiveTrackerRequest() {
  // Clear all related data when active tracker is reset
  yield put(setMilestonesAction([]));
}

export default function* activeMilestonesSaga() {
  yield takeEvery(RESET_ACTIVE_TRACKER, handleResetActiveTrackerRequest);
  yield takeEvery(SET_ACTIVE_TRACKER, handleSetActiveMilestones);
  yield takeEvery(UPSERT_LOCAL_MILESTONE, handleUpdateMilestoneIndex);
  yield takeEvery(
    MILESTONE_CREATE_REQUEST_ACTION,
    handleCreateMilestoneRequest
  );
  yield takeEvery(
    MILESTONE_UPDATE_REQUEST_ACTION,
    handleUpdateMilestoneRequest
  );
  yield takeEvery(
    MILESTONE_UPSERT_REQUEST_ACTION,
    handleUpsertMilestoneRequest
  );
  yield takeEvery(
    MILESTONE_DELETE_REQUEST_ACTION,
    handleDeleteMilestoneRequest
  );
  yield takeLatest(
    MILESTONE_UPDATE_SUCCESS_ACTION,
    handleTrackerMilestoneChange
  );
  yield takeEvery(
    MILESTONE_CREATE_SUCCESS_ACTION,
    handleTrackerMilestoneCreate
  );
  yield takeEvery(
    MILESTONE_DELETE_SUCCESS_ACTION,
    handleTrackerMilestoneDelete
  );
  
  // Handle Tracker Delete with Milestones
  yield takeEvery(TRACKER_DELETE_SUCCESS_ACTION, handleDeleteTrackerMilestones);
  
  // Handle Tracker Update Plan with Milestones
  yield takeEvery(TRACKER_UPSERT_REMOTE_SUCCESS_ACTION, handleUpdateTrackerPlanMilestones);

  // Handle Copy Tracker with Milestones
  yield takeEvery(TRACKER_COPY_SUCCESS_ACTION, handleCopyTrackerMilestones);

  // Handle post tracker migration milestone migration
  yield takeEvery(TRACKER_MIGRATE_SUCCESS_ACTION, handleMigrateTrackerMilestones);
  
}
