import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';

export const style = () => ({});

class LearnItemUnknown extends Component {
  state = {};

  handleDelete = () => {
    const { note } = this.state;
    const { onDelete } = this.props;
    onDelete(note);
  };

  handleClick = () => {
    this.setState({ edit: true });
  };

  handleCancel = () => {
    this.setState({ edit: false });
  };

  render() {
    const { item } = this.props;
    const { edit } = this.state;

    return (
      <React.Fragment>
        <ListItem onClick={this.handleClick}>
          <Card
            elevation={0}
            style={{ borderLeft: '3px solid lightgray', marginBottom: 4 }}
          >
            <CardContent style={{ fontSize: '.8rem', whiteSpace: 'pre-wrap' }}>
              Unknown Learn Item Type ({item.type})
            </CardContent>
          </Card>
        </ListItem>
        <Dialog open={edit}>
          <DialogActions>
            <Button
              style={{ marginRight: 'auto' }}
              color="secondary"
              onClick={this.handleDelete}
            >
              Delete
            </Button>
            <Button color="secondary" onClick={this.handleCancel}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

LearnItemUnknown.propTypes = {
  item: PropTypes.any.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default withStyles(style)(LearnItemUnknown);
