import {put, takeLatest, take, select, takeEvery} from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import React from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import {
  AUTH_CHECK_CURRENT_USER_FAILURE_ACTION,
  AUTH_CHECK_CURRENT_USER_SUCCESS_ACTION,
  AUTH_CONFIRM_EMAIL_FAILURE_ACTION,
  AUTH_CONFIRM_EMAIL_REQUEST_ACTION,
  AUTH_CONFIRM_EMAIL_SUCCESS_ACTION,
  AUTH_CONFIRM_USER_FAILURE_ACTION,
  AUTH_CONFIRM_USER_REQUEST_ACTION,
  AUTH_CONFIRM_USER_SUCCESS_ACTION, AUTH_CONFIRMATION_REQUEST_ACTION,
  AUTH_FORGOT_PASSWORD_FAILURE_ACTION,
  AUTH_FORGOT_PASSWORD_REQUEST_ACTION,
  AUTH_FORGOT_PASSWORD_SUCCESS_ACTION,
  AUTH_GET_CURRENT_USER_FAILURE_ACTION,
  AUTH_GET_CURRENT_USER_REQUEST_ACTION,
  AUTH_GET_CURRENT_USER_SUCCESS_ACTION,
  AUTH_RESEND_CODE_FAILURE_ACTION,
  AUTH_RESEND_CODE_REQUEST_ACTION,
  AUTH_RESEND_CODE_SUCCESS_ACTION,
  AUTH_SIGN_IN_FAILURE_ACTION,
  AUTH_SIGN_IN_REQUEST_ACTION,
  AUTH_SIGN_IN_SUCCESS_ACTION,
  AUTH_SIGN_OUT_FAILURE_ACTION,
  AUTH_SIGN_OUT_REQUEST_ACTION,
  AUTH_SIGN_OUT_SUCCESS_ACTION,
  AUTH_SIGN_UP_FAILURE_ACTION,
  AUTH_SIGN_UP_REQUEST_ACTION,
  AUTH_SIGN_UP_SUCCESS_ACTION,
  AUTH_USER_ATTRIBUTE_UPDATE_FAILURE_ACTION,
  AUTH_USER_ATTRIBUTE_UPDATE_REQUEST_ACTION,
  AUTH_USER_ATTRIBUTE_UPDATE_SUCCESS_ACTION,
  getCurrentUserAction
} from '../actions/auth';
import routes from '../../constants/routes.json';
import {
  addNotificationAction,
  setProcessingAction,
  setLoadingAction,
  showErrorAction,
  showSuccessAction
} from '../actions/notifications';
import { setUserAction } from '../actions/user';
import {
  getUserStruct,
  USER_STATUS_FORGOT, USER_STATUS_LOGGED_IN,
  USER_STATUS_LOGIN_REQUIRED
} from '../../structs/user';
import {
  APPLICATION_DATA_REQUEST_ACTION,
  setDesiredRouteAction
} from '../actions/app';
import {
  getSystemNotificationStruct,
  INTERNET_STATUS_CONNECTED,
} from '../../structs/notification';
import {DEVICE_SYNC_WAKE_UP} from '../actions/deviceSync';
import {USER_MEMBERSHIP_STATUS_ACTIVE} from '../../models/user';
import ROUTES from '../../constants/routes.json';

function* handleSignIn(action) {
  yield put(setLoadingAction('Working...'));
  const { username, password } = action.payload;
  try {

    const result = yield Auth.signIn(username, password);

    yield put({ type: AUTH_SIGN_IN_SUCCESS_ACTION, payload: result });

    yield put(showSuccessAction('Sign In Successful!'));
    
    yield put(getCurrentUserAction());
  } catch (err) {
    if (err.code === 'UserNotConfirmedException') {
      yield put(
        showErrorAction(
          'You have started to create an account, but we still need to confirm your email.'
        )
      );
  
      const user = yield select(store => store.user);

      yield put(setUserAction({
        ...user,
        credentials: {
          username,
          password
        }
      }));
  
      yield put(setDesiredRouteAction(routes.CONFIRM));
    } else {
      yield put(
        showErrorAction(
          'Sorry, seems like the username or password, may be wrong.'
        )
      );
    }
    yield put({ type: AUTH_SIGN_IN_FAILURE_ACTION, payload: err });
  }

  yield put(setLoadingAction(''));
}

function* handleSignUp(action) {
  yield put(setLoadingAction('Working...'));

  try {
    const { credentials, attributes } = action.payload;
    const params = {
      username: credentials.username,
      password: credentials.password,
      attributes: {
        family_name: attributes.familyName,
        given_name: attributes.givenName,
        email: attributes.email
      }
    };
    const result = yield Auth.signUp(params);

    yield put(setUserAction(getUserStruct(action.payload)));

    yield put({ type: AUTH_SIGN_UP_SUCCESS_ACTION, payload: result });
  } catch (err) {
    yield put(
      addNotificationAction(
        getSystemNotificationStruct({
          message: (
            <div>
              <Typography>Looks like there is a little issue...</Typography>
              <Typography>Error: {err.message}</Typography>
            </div>
          ),
          type: 'dialog',
          level: 'error'
        })
      )
    );
    yield put({ type: AUTH_SIGN_UP_FAILURE_ACTION, payload: err });
  }
  yield put(setLoadingAction(''));
}

function* handleForgotPassword(action) {
  const { username, verificationCode, password } = action.payload;

  yield put(setLoadingAction('Loading...'));

  const user = yield select(store => store.user);

  try {
    if (verificationCode && password) {
      yield Auth.forgotPasswordSubmit(username, verificationCode, password);

      yield put(showSuccessAction('Password Reset Successful! Please login.'));
      yield put(setUserAction({ ...user, status: USER_STATUS_LOGIN_REQUIRED }));
    } else {
      yield Auth.forgotPassword(username);

      yield put(setUserAction({ ...user, status: USER_STATUS_FORGOT }));
      yield put(showSuccessAction('Password Reset Request Successful!'));
    }
    yield put({ type: AUTH_FORGOT_PASSWORD_SUCCESS_ACTION });
  } catch (err) {
    yield put({ type: AUTH_FORGOT_PASSWORD_FAILURE_ACTION, payload: err });
    if (err.message) yield put(showErrorAction(`Looks like ${err.message}`));
    else
      yield put(showErrorAction('Forgot password failed!, Please try again'));
  }

  yield put(setLoadingAction(''));
}

function* handleSignOut() {
  try {
    yield Auth.signOut();
    yield put(getCurrentUserAction());

    yield put(showSuccessAction('Sign Out Successful!'));

    yield put({ type: AUTH_SIGN_OUT_SUCCESS_ACTION });
  } catch (err) {
    yield put({ type: AUTH_SIGN_OUT_FAILURE_ACTION, payload: err });
  }
}

function* handleConfirm(action) {
  yield put(setLoadingAction('Confirming Code...'));
  try {
    const { credentials } = action.payload;
    const { username, password, verificationCode } = credentials;
    const user = yield select(store => store.user);
  
    yield Auth.confirmSignUp(username, verificationCode);
  
    yield put(setUserAction({
      ...user,
      credentials: {
        username,
        password
      }
    }));
  
    yield put(setDesiredRouteAction(routes.CONFIRM_CONFIRMATION));
  
  } catch (err) {
    yield put(showErrorAction('User confirmation failed!, Please try again'));
    yield put({ type: AUTH_CONFIRM_USER_FAILURE_ACTION, payload: err });
  }
  yield put(setLoadingAction(''));
}

function* handleConfirmEmail(action) {
  yield put(setLoadingAction('Confirming...'));
  try {
    const user = yield Auth.currentAuthenticatedUser({ bypassCache: true });
    const { credentials } = action.payload;
    const { verificationCode } = credentials;

    if (!verificationCode) {
      yield Auth.verifyUserAttribute(user, 'email');
      yield put(showSuccessAction('Code sent to email!'));
    } else {
      const result = yield Auth.verifyUserAttributeSubmit(
        user,
        'email',
        verificationCode
      );
      yield put(showSuccessAction('Email confirmed, thanks!'));

      yield put({ type: AUTH_GET_CURRENT_USER_REQUEST_ACTION });
      yield take(AUTH_GET_CURRENT_USER_SUCCESS_ACTION);

      yield put({ type: AUTH_CONFIRM_EMAIL_SUCCESS_ACTION, payload: result });
    }
  } catch (err) {
    yield put(showErrorAction('Email confirmation failed!, Please try again'));

    yield put({ type: AUTH_CONFIRM_EMAIL_FAILURE_ACTION, payload: err });
  }
  yield put(setLoadingAction(''));
}

function* handleLoginAfterConfirm({ payload: credentials }) {
  const { username, password } = credentials;
  
  yield put({
    type: AUTH_SIGN_IN_REQUEST_ACTION,
    payload: { username, password }
  });
  yield take(AUTH_SIGN_IN_SUCCESS_ACTION);
  yield put({ type: AUTH_CONFIRM_USER_SUCCESS_ACTION });
}

function* handleResendCode() {
  yield put(setLoadingAction('Sending Code...'));
  try {
    const user = yield select(store => store.user);
    const { credentials, attributes } = user;
    const username = credentials.username || attributes.username;

    yield Auth.resendSignUp(username);

    yield put(showSuccessAction('Code resent! Please check your email.'));
    yield put({ type: AUTH_RESEND_CODE_SUCCESS_ACTION });
  } catch (err) {
    yield put(showErrorAction('Code resend failed!, Please try again'));
    yield put({ type: AUTH_RESEND_CODE_FAILURE_ACTION, payload: err });
  }
  yield put(setLoadingAction(''));
}

function* handleGetCurrentUser() {
  const user = yield select(store => store.user);

  if (user.status === USER_STATUS_LOGGED_IN
    && moment(user.updatedAt).startOf('day') === moment().startOf('day')) {
    yield put({ type: AUTH_GET_CURRENT_USER_SUCCESS_ACTION, payload: user });
    return;
  }

  yield put(setProcessingAction('Loading...'));
  try {
    const user = yield Auth.currentAuthenticatedUser({ bypassCache: true });

    yield put({ type: AUTH_GET_CURRENT_USER_SUCCESS_ACTION, payload: user });
  } catch (err) {
    yield put({ type: AUTH_GET_CURRENT_USER_FAILURE_ACTION, payload: err });
  }
  yield put(setProcessingAction(''));
}

function* handleCheckCurrentUser(action) {
  const internetStatus = yield select(store => store.internetStatus);
  const userCheck = yield select(store => store.app.userCheck);
  const user = yield select(store => store.user);
  const currentPath = yield select(store => store.router.location.pathname);
  
  if (!user.id)
    return;
  
  if (user.status !== USER_STATUS_LOGGED_IN)
    return;
  
  if (!userCheck || currentPath === '/account')
    return;
  
  if (internetStatus.status !== INTERNET_STATUS_CONNECTED)
    return;
  
  yield put(setProcessingAction('Loading...'));
  
  if (user.memberStatus.status !== USER_MEMBERSHIP_STATUS_ACTIVE) {
    console.log('Subscription Not Valid');
    yield put(setDesiredRouteAction(ROUTES.PAYMENT));
    yield put(setProcessingAction(''));
    return 0;
  }

  yield put(setProcessingAction(''));

  if (user.status === USER_STATUS_LOGGED_IN
    && moment(user.updatedAt).startOf('day') === moment().startOf('day')) {
    yield put({ type: AUTH_CHECK_CURRENT_USER_SUCCESS_ACTION, payload: {initialAction: action, user} });
    return;
  }

  try {
    yield put(setProcessingAction('Verifying account...'));
    const authenticatedUser = yield Auth.currentAuthenticatedUser({bypassCache: true});
    yield put({ type: AUTH_CHECK_CURRENT_USER_SUCCESS_ACTION, payload: {initialAction: action, authenticatedUser} });
  } catch (err) {
    yield put({ type: AUTH_CHECK_CURRENT_USER_FAILURE_ACTION, payload: err });
    yield put(setUserAction(getUserStruct()));
  }
  yield put(setProcessingAction(''));
}

function* handleUpdateUserAttributes(action) {
  yield put(setLoadingAction('Saving...'));
  try {
    const user = action.payload;
    const { attributes } = user;
    const authenticatedUser = yield Auth.currentAuthenticatedUser({
      bypassCache: true
    });
    const updateAttributes = {
      family_name: attributes.familyName,
      given_name: attributes.givenName,
      email: attributes.email
    };

    yield Auth.updateUserAttributes(authenticatedUser, updateAttributes);
    yield put(showSuccessAction('User update successful!'));

    yield put({ type: AUTH_GET_CURRENT_USER_REQUEST_ACTION });
    yield take(AUTH_GET_CURRENT_USER_SUCCESS_ACTION);

    yield put({ type: AUTH_USER_ATTRIBUTE_UPDATE_SUCCESS_ACTION });
  } catch (err) {
    yield put(showErrorAction('User update failed!, Please try again'));
    yield put({
      type: AUTH_USER_ATTRIBUTE_UPDATE_FAILURE_ACTION,
      payload: err
    });
  }
  yield put(setLoadingAction(''));
}

export default function* authSaga() {
  yield takeLatest(AUTH_SIGN_IN_REQUEST_ACTION, handleSignIn);
  yield takeLatest(AUTH_SIGN_UP_REQUEST_ACTION, handleSignUp);
  yield takeLatest(AUTH_SIGN_OUT_REQUEST_ACTION, handleSignOut);
  yield takeLatest(AUTH_CONFIRM_USER_REQUEST_ACTION, handleConfirm);
  yield takeLatest(AUTH_CONFIRM_EMAIL_REQUEST_ACTION, handleConfirmEmail);
  yield takeLatest(AUTH_CONFIRMATION_REQUEST_ACTION, handleLoginAfterConfirm);
  yield takeLatest(AUTH_RESEND_CODE_REQUEST_ACTION, handleResendCode);
  yield takeLatest(AUTH_FORGOT_PASSWORD_REQUEST_ACTION, handleForgotPassword);
  yield takeLatest([AUTH_GET_CURRENT_USER_REQUEST_ACTION, APPLICATION_DATA_REQUEST_ACTION], handleGetCurrentUser);
  yield takeLatest(
    AUTH_USER_ATTRIBUTE_UPDATE_REQUEST_ACTION,
    handleUpdateUserAttributes
  );
  yield takeEvery(['@@router/LOCATION_CHANGE', DEVICE_SYNC_WAKE_UP], handleCheckCurrentUser);
}
