import React, { Component } from 'react';
import { connect } from 'react-redux';
import Login from '../components/Login/Login';
import {
  addNotificationAction,
  setInternetStatusAction
} from '../redux/actions/notifications';
import {
  confirmUserAction,
  resendCodeAction,
  signInAction
} from '../redux/actions/auth';

export class LoginPageContainer extends Component {
  render() {
    return <Login />;
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  addNotification: notification =>
    dispatch(addNotificationAction(notification)),
  setInternetStatus: status => dispatch(setInternetStatusAction(status)),
  signIn: credentials => dispatch(signInAction(credentials)),
  confirmUser: user => dispatch(confirmUserAction(user)),
  resendCode: username => dispatch(resendCodeAction(username))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
