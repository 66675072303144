import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input/Input';

const style = () => ({
  input: {
    width: '100%',
    height: 37,
    paddingLeft: 50,
    fontSize: 14,
    '&:before': {
      borderBottomColor: 'rgba(0, 0, 0, 0.12)'
    }
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    position: 'relative'
  }
});

class AddCommitment extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    commitmentDescription: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired
  };

  onKeyDown = event => {
    const { handleSave } = this.props;
    if (event.keyCode === 13) {
      handleSave();
    }
  };

  render() {
    const {
      classes,
      commitmentDescription,
      handleChange,
      handleSave
    } = this.props;
    return (
      <div className={classes.wrapper}>
        <Input
          value={commitmentDescription}
          onChange={handleChange}
          placeholder="Add new commitment"
          className={classes.input}
          onKeyDown={this.onKeyDown}
          onBlur={handleSave}
        />
      </div>
    );
  }
}

export default withStyles(style)(AddCommitment);
