import { v4 as uuidv4 } from 'uuid';
import { getCommitmentStruct } from '../../structs/commitments';

// Commitment Actions
export const ADD_LOCAL_COMMITMENT = 'ADD_LOCAL_COMMITMENT';
export const SET_LOCAL_COMMITMENTS = 'SET_LOCAL_COMMITMENTS';
export const SAVE_LOCAL_COMMITMENT = 'SAVE_LOCAL_COMMITMENT';
export const UPSERT_LOCAL_COMMITMENT = 'UPSERT_LOCAL_COMMITMENT';
export const REMOVE_LOCAL_COMMITMENT = 'REMOVE_LOCAL_COMMITMENT';
export const COMMITMENT_CREATE_REQUEST_ACTION = 'COMMITMENT_CREATE_REQUEST';
export const COMMITMENT_CREATE_SUCCESS_ACTION = 'COMMITMENT_CREATE_SUCCESS';
export const COMMITMENT_UPDATE_REQUEST_ACTION = 'COMMITMENT_UPDATE_REQUEST';
export const COMMITMENT_UPDATE_SUCCESS_ACTION = 'COMMITMENT_UPDATE_SUCCESS';
export const COMMITMENT_DELETE_REQUEST_ACTION = 'COMMITMENT_DELETE_REQUEST';
export const COMMITMENT_DELETE_SUCCESS_ACTION = 'COMMITMENT_DELETE_SUCCESS';
export const COMMITMENT_MIGRATE_REQUEST_ACTION = 'COMMITMENT_MIGRATE_REQUEST';
export const COMMITMENT_MIGRATE_SUCCESS_ACTION = 'COMMITMENT_MIGRATE_SUCCESS';
export const COMMITMENT_DATA_LOAD_REQUEST_ACTION =
  'COMMITMENT_DATA_LOAD_REQUEST';
export const COMMITMENT_DATA_LOAD_SUCCESS_ACTION =
  'COMMITMENT_DATA_LOAD_SUCCESS';
export const COMMITMENT_FULL_SYNC_REQUEST_ACTION =
  'COMMITMENT_FULL_SYNC_REQUEST';
export const COMMITMENT_FULL_SYNC_SUCCESS_ACTION =
  'COMMITMENT_FULL_SYNC_SUCCESS';
export const COMMITMENT_QUICK_SYNC_REQUEST_ACTION =
  'COMMITMENT_QUICK_SYNC_REQUEST';
export const COMMITMENT_QUICK_SYNC_SUCCESS_ACTION =
  'COMMITMENT_QUICK_SYNC_SUCCESS';
export const COMMITMENT_CLEAR_NOTIFICATIONS_REQUEST_ACTION = 'COMMITMENT_CLEAR_NOTIFICATIONS_REQUEST'
export const COMMITMENT_CLEAR_NOTIFICATIONS_SUCCESS_ACTION = 'COMMITMENT_CLEAR_NOTIFICATIONS_SUCCESS'
export const COMMITMENT_CREATE_NOTIFICATIONS_REQUEST_ACTION = 'COMMITMENT_CREATE_NOTIFICATIONS_REQUEST'
export const COMMITMENT_CREATE_NOTIFICATIONS_SUCCESS_ACTION = 'COMMITMENT_CREATE_NOTIFICATIONS_SUCCESS'


// Commitment Index Actions
export const UPDATE_COMMITMENT_INDEX = 'UPDATE_COMMITMENT_INDEX';

export const addCommitmentAction = commitment => ({
  type: COMMITMENT_CREATE_REQUEST_ACTION,
  payload: Object.assign({}, getCommitmentStruct(), commitment, {
    id: uuidv4()
  })
});

export const setLocalCommitmentsAction = commitments => ({
  type: COMMITMENT_DATA_LOAD_REQUEST_ACTION,
  payload: commitments
});

export const deleteCommitmentAction = commitment => ({
  type: COMMITMENT_DELETE_REQUEST_ACTION,
  payload: commitment
});

export const saveCommitmentAction = commitment => ({
  type: COMMITMENT_UPDATE_REQUEST_ACTION,
  payload: commitment
});

export const upsertLocalCommitmentAction = commitment => ({
  type: UPSERT_LOCAL_COMMITMENT,
  payload: commitment
});

export const updateCommitmentIndexAction = doc => ({
  type: UPDATE_COMMITMENT_INDEX,
  payload: doc
});

export const migrateCommitmentAction = commitment => ({
  type: COMMITMENT_MIGRATE_REQUEST_ACTION,
  payload: commitment
});
