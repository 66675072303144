import {API, graphqlOperation} from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import {getCommitmentTrayStruct} from "../../structs/commitmentTray";

/*
 *
 * Commitment Tray Functions
 *
 */
export const fetchRemoteCommitmentTraysByUser = async (ownerApn) => {
  const { data: { listCommitmentTrays: { items }}} = await API.graphql(
    graphqlOperation(queries.listCommitmentTrays, {
      ownerApn,
      limit: 10,
      sortDirection: 'DESC'
    })
  );

  return items.length
        ? items.map(item => postFetchProcess(item))
        : []
}

export const createRemoteCommitmentTray = async tray => {
  const input = prepAndPruneCommitmentTrayForAppSync(tray);

  const { data: { createCommitmentTray }} = await API.graphql(
    graphqlOperation(mutations.createCommitmentTray, {
      input
    })
  );
  return postFetchProcess(createCommitmentTray);
};

export const updateRemoteCommitmentTray = async tray => {
  const input = prepAndPruneCommitmentTrayForAppSync(tray);

  const { data: { updateCommitmentTray }} = await API.graphql(
    graphqlOperation(mutations.updateCommitmentTray, {
      input
    })
  );
  return postFetchProcess(updateCommitmentTray);
};

export const fetchRemoteCommitmentTray = async (ownerApn, id) => {
  const { data: { getCommitmentTray }} = await API.graphql(
    graphqlOperation(queries.getCommitmentTray, {
      ownerApn,
      id
    })
  );
  return postFetchProcess(getCommitmentTray);
};

export const deleteRemoteCommitmentTray = async (ownerApn,id) => {
  await API.graphql(
    graphqlOperation(mutations.deleteCommitmentTray, {
      input: {
        ownerApn,
        id
      }
    })
  );
}

const postFetchProcess = item => {
  // Pass fetched value through the Struct, never trust input. :)
  return getCommitmentTrayStruct(item);
};

export const prepAndPruneCommitmentTrayForAppSync = item => {
  // Prune any keys that are not in the schema
  const keys = Object.keys(getCommitmentTrayStruct());
  const pruned = getCommitmentTrayStruct(item);
  const prunedKeys = Object.keys(pruned);
  const propertiesToPrune = prunedKeys.filter(key => !keys.includes(key));
  if (propertiesToPrune.length)
    propertiesToPrune.map(key => delete pruned[key]);
  
  // Prep any empty strings
  keys.map(key => {
    if (typeof pruned[key] === 'string' && pruned[key] === '') {
      pruned[key] = ' ';
    }
    return true;
  });

  return pruned;
};
