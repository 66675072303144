/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateTrackerv3Filtered = /* GraphQL */ `
  subscription OnUpdateTrackerv3Filtered($id: String!, $ownerApn: String!) {
    onUpdateTrackerv3Filtered(id: $id, ownerApn: $ownerApn) {
      id
      ownerApn
      dream
      learn {
        id
        seq
        type
        item {
          title
          text
          pageId
          content
          url
          question
          answer
          schemaVersion
        }
        schemaVersion
      }
      success {
        apnPart
        item
        seq
        checked
        schemaVersion
      }
      metrics {
        id
        seq
        type
        item {
          name
          values {
            id
            metric
            value
            datetime
            schemaVersion
          }
          schemaVersion
        }
        schemaVersion
      }
      milestones {
        items {
          id
          trackerApn
          trackerId
          description
          notes
          reached
          seq
          status
          targetDate
          actionItems {
            apnPart
            action
            seq
            checked
            schemaVersion
          }
          planApn
          commitments {
            items {
              id
              userId
              milestoneApn
              milestoneId
              trackerId
              contextApn
              contextDescription
              description
              notes
              date
              originalDate
              createdAt
              updatedAt
              highlightColor
              timeCommitment
              status
              role {
                name
                color
              }
              trays
              reminders {
                email
                sms
              }
              schemaVersion
            }
            nextToken
          }
          createdAt
          updatedAt
          schemaVersion
        }
        nextToken
      }
      plans {
        apn
        title
        seq
        milestoneIds
        open
      }
      notes
      reviewCycle {
        frequency
        interval
        positions
        lastReviewedDate
        startReviewDate
        updatedAt
        schemaVersion
      }
      status
      createdAt
      updatedAt
      role {
        name
        color
      }
      schemaVersion
    }
  }
`;
export const onCreateChangeLogv2Filtered = /* GraphQL */ `
  subscription OnCreateChangeLogv2Filtered($ownerApn: String!) {
    onCreateChangeLogv2Filtered(ownerApn: $ownerApn) {
      ownerApn
      timestamp
      changeId
      action
      data
      source
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCommitmentInvite = /* GraphQL */ `
  subscription OnCreateCommitmentInvite {
    onCreateCommitmentInvite {
      id
      status
      description
      fromUserName
      fromUserApn
      toUserName
      toUserApn
      contextApn
      contextDescription
      trackerId
      notes
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const onUpdateCommitmentInvite = /* GraphQL */ `
  subscription OnUpdateCommitmentInvite {
    onUpdateCommitmentInvite {
      id
      status
      description
      fromUserName
      fromUserApn
      toUserName
      toUserApn
      contextApn
      contextDescription
      trackerId
      notes
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const onDeleteCommitmentInvite = /* GraphQL */ `
  subscription OnDeleteCommitmentInvite {
    onDeleteCommitmentInvite {
      id
      status
      description
      fromUserName
      fromUserApn
      toUserName
      toUserApn
      contextApn
      contextDescription
      trackerId
      notes
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const onCreateJournal = /* GraphQL */ `
  subscription OnCreateJournal {
    onCreateJournal {
      id
      ownerApn
      trackerId
      datetime
      entry
      schemaVersion
      createdBy
      updatedAt
      createdAt
    }
  }
`;
export const onUpdateJournal = /* GraphQL */ `
  subscription OnUpdateJournal {
    onUpdateJournal {
      id
      ownerApn
      trackerId
      datetime
      entry
      schemaVersion
      createdBy
      updatedAt
      createdAt
    }
  }
`;
export const onDeleteJournal = /* GraphQL */ `
  subscription OnDeleteJournal {
    onDeleteJournal {
      id
      ownerApn
      trackerId
      datetime
      entry
      schemaVersion
      createdBy
      updatedAt
      createdAt
    }
  }
`;
export const onCreateJournalv2 = /* GraphQL */ `
  subscription OnCreateJournalv2 {
    onCreateJournalv2 {
      id
      ownerApn
      trackerId
      datetime
      entry
      schemaVersion
      createdBy
      updatedAt
      createdAt
    }
  }
`;
export const onUpdateJournalv2 = /* GraphQL */ `
  subscription OnUpdateJournalv2 {
    onUpdateJournalv2 {
      id
      ownerApn
      trackerId
      datetime
      entry
      schemaVersion
      createdBy
      updatedAt
      createdAt
    }
  }
`;
export const onDeleteJournalv2 = /* GraphQL */ `
  subscription OnDeleteJournalv2 {
    onDeleteJournalv2 {
      id
      ownerApn
      trackerId
      datetime
      entry
      schemaVersion
      createdBy
      updatedAt
      createdAt
    }
  }
`;
export const onCreateCommitmentv3 = /* GraphQL */ `
  subscription OnCreateCommitmentv3 {
    onCreateCommitmentv3 {
      id
      userId
      milestoneApn
      milestoneId
      trackerId
      contextApn
      contextDescription
      description
      notes
      date
      originalDate
      createdAt
      updatedAt
      highlightColor
      timeCommitment
      status
      role {
        name
        color
      }
      trays
      reminders {
        email
        sms
      }
      schemaVersion
    }
  }
`;
export const onUpdateCommitmentv3 = /* GraphQL */ `
  subscription OnUpdateCommitmentv3 {
    onUpdateCommitmentv3 {
      id
      userId
      milestoneApn
      milestoneId
      trackerId
      contextApn
      contextDescription
      description
      notes
      date
      originalDate
      createdAt
      updatedAt
      highlightColor
      timeCommitment
      status
      role {
        name
        color
      }
      trays
      reminders {
        email
        sms
      }
      schemaVersion
    }
  }
`;
export const onDeleteCommitmentv3 = /* GraphQL */ `
  subscription OnDeleteCommitmentv3 {
    onDeleteCommitmentv3 {
      id
      userId
      milestoneApn
      milestoneId
      trackerId
      contextApn
      contextDescription
      description
      notes
      date
      originalDate
      createdAt
      updatedAt
      highlightColor
      timeCommitment
      status
      role {
        name
        color
      }
      trays
      reminders {
        email
        sms
      }
      schemaVersion
    }
  }
`;
export const onCreateMilestonev3 = /* GraphQL */ `
  subscription OnCreateMilestonev3 {
    onCreateMilestonev3 {
      id
      trackerApn
      trackerId
      description
      notes
      reached
      seq
      status
      targetDate
      actionItems {
        apnPart
        action
        seq
        checked
        schemaVersion
      }
      planApn
      commitments {
        items {
          id
          userId
          milestoneApn
          milestoneId
          trackerId
          contextApn
          contextDescription
          description
          notes
          date
          originalDate
          createdAt
          updatedAt
          highlightColor
          timeCommitment
          status
          role {
            name
            color
          }
          trays
          reminders {
            email
            sms
          }
          schemaVersion
        }
        nextToken
      }
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const onUpdateMilestonev3 = /* GraphQL */ `
  subscription OnUpdateMilestonev3 {
    onUpdateMilestonev3 {
      id
      trackerApn
      trackerId
      description
      notes
      reached
      seq
      status
      targetDate
      actionItems {
        apnPart
        action
        seq
        checked
        schemaVersion
      }
      planApn
      commitments {
        items {
          id
          userId
          milestoneApn
          milestoneId
          trackerId
          contextApn
          contextDescription
          description
          notes
          date
          originalDate
          createdAt
          updatedAt
          highlightColor
          timeCommitment
          status
          role {
            name
            color
          }
          trays
          reminders {
            email
            sms
          }
          schemaVersion
        }
        nextToken
      }
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const onDeleteMilestonev3 = /* GraphQL */ `
  subscription OnDeleteMilestonev3 {
    onDeleteMilestonev3 {
      id
      trackerApn
      trackerId
      description
      notes
      reached
      seq
      status
      targetDate
      actionItems {
        apnPart
        action
        seq
        checked
        schemaVersion
      }
      planApn
      commitments {
        items {
          id
          userId
          milestoneApn
          milestoneId
          trackerId
          contextApn
          contextDescription
          description
          notes
          date
          originalDate
          createdAt
          updatedAt
          highlightColor
          timeCommitment
          status
          role {
            name
            color
          }
          trays
          reminders {
            email
            sms
          }
          schemaVersion
        }
        nextToken
      }
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const onCreateTrackerv3 = /* GraphQL */ `
  subscription OnCreateTrackerv3 {
    onCreateTrackerv3 {
      id
      ownerApn
      dream
      learn {
        id
        seq
        type
        item {
          title
          text
          pageId
          content
          url
          question
          answer
          schemaVersion
        }
        schemaVersion
      }
      success {
        apnPart
        item
        seq
        checked
        schemaVersion
      }
      metrics {
        id
        seq
        type
        item {
          name
          values {
            id
            metric
            value
            datetime
            schemaVersion
          }
          schemaVersion
        }
        schemaVersion
      }
      milestones {
        items {
          id
          trackerApn
          trackerId
          description
          notes
          reached
          seq
          status
          targetDate
          actionItems {
            apnPart
            action
            seq
            checked
            schemaVersion
          }
          planApn
          commitments {
            items {
              id
              userId
              milestoneApn
              milestoneId
              trackerId
              contextApn
              contextDescription
              description
              notes
              date
              originalDate
              createdAt
              updatedAt
              highlightColor
              timeCommitment
              status
              role {
                name
                color
              }
              trays
              reminders {
                email
                sms
              }
              schemaVersion
            }
            nextToken
          }
          createdAt
          updatedAt
          schemaVersion
        }
        nextToken
      }
      plans {
        apn
        title
        seq
        milestoneIds
        open
      }
      notes
      reviewCycle {
        frequency
        interval
        positions
        lastReviewedDate
        startReviewDate
        updatedAt
        schemaVersion
      }
      status
      createdAt
      updatedAt
      role {
        name
        color
      }
      schemaVersion
    }
  }
`;
export const onUpdateTrackerv3 = /* GraphQL */ `
  subscription OnUpdateTrackerv3 {
    onUpdateTrackerv3 {
      id
      ownerApn
      dream
      learn {
        id
        seq
        type
        item {
          title
          text
          pageId
          content
          url
          question
          answer
          schemaVersion
        }
        schemaVersion
      }
      success {
        apnPart
        item
        seq
        checked
        schemaVersion
      }
      metrics {
        id
        seq
        type
        item {
          name
          values {
            id
            metric
            value
            datetime
            schemaVersion
          }
          schemaVersion
        }
        schemaVersion
      }
      milestones {
        items {
          id
          trackerApn
          trackerId
          description
          notes
          reached
          seq
          status
          targetDate
          actionItems {
            apnPart
            action
            seq
            checked
            schemaVersion
          }
          planApn
          commitments {
            items {
              id
              userId
              milestoneApn
              milestoneId
              trackerId
              contextApn
              contextDescription
              description
              notes
              date
              originalDate
              createdAt
              updatedAt
              highlightColor
              timeCommitment
              status
              role {
                name
                color
              }
              trays
              reminders {
                email
                sms
              }
              schemaVersion
            }
            nextToken
          }
          createdAt
          updatedAt
          schemaVersion
        }
        nextToken
      }
      plans {
        apn
        title
        seq
        milestoneIds
        open
      }
      notes
      reviewCycle {
        frequency
        interval
        positions
        lastReviewedDate
        startReviewDate
        updatedAt
        schemaVersion
      }
      status
      createdAt
      updatedAt
      role {
        name
        color
      }
      schemaVersion
    }
  }
`;
export const onDeleteTrackerv3 = /* GraphQL */ `
  subscription OnDeleteTrackerv3 {
    onDeleteTrackerv3 {
      id
      ownerApn
      dream
      learn {
        id
        seq
        type
        item {
          title
          text
          pageId
          content
          url
          question
          answer
          schemaVersion
        }
        schemaVersion
      }
      success {
        apnPart
        item
        seq
        checked
        schemaVersion
      }
      metrics {
        id
        seq
        type
        item {
          name
          values {
            id
            metric
            value
            datetime
            schemaVersion
          }
          schemaVersion
        }
        schemaVersion
      }
      milestones {
        items {
          id
          trackerApn
          trackerId
          description
          notes
          reached
          seq
          status
          targetDate
          actionItems {
            apnPart
            action
            seq
            checked
            schemaVersion
          }
          planApn
          commitments {
            items {
              id
              userId
              milestoneApn
              milestoneId
              trackerId
              contextApn
              contextDescription
              description
              notes
              date
              originalDate
              createdAt
              updatedAt
              highlightColor
              timeCommitment
              status
              role {
                name
                color
              }
              trays
              reminders {
                email
                sms
              }
              schemaVersion
            }
            nextToken
          }
          createdAt
          updatedAt
          schemaVersion
        }
        nextToken
      }
      plans {
        apn
        title
        seq
        milestoneIds
        open
      }
      notes
      reviewCycle {
        frequency
        interval
        positions
        lastReviewedDate
        startReviewDate
        updatedAt
        schemaVersion
      }
      status
      createdAt
      updatedAt
      role {
        name
        color
      }
      schemaVersion
    }
  }
`;
export const onCreateChangeLogv2 = /* GraphQL */ `
  subscription OnCreateChangeLogv2 {
    onCreateChangeLogv2 {
      ownerApn
      timestamp
      changeId
      action
      data
      source
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChangeLogv2 = /* GraphQL */ `
  subscription OnUpdateChangeLogv2 {
    onUpdateChangeLogv2 {
      ownerApn
      timestamp
      changeId
      action
      data
      source
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChangeLogv2 = /* GraphQL */ `
  subscription OnDeleteChangeLogv2 {
    onDeleteChangeLogv2 {
      ownerApn
      timestamp
      changeId
      action
      data
      source
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTour = /* GraphQL */ `
  subscription OnCreateTour {
    onCreateTour {
      ownerApn
      id
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTour = /* GraphQL */ `
  subscription OnUpdateTour {
    onUpdateTour {
      ownerApn
      id
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTour = /* GraphQL */ `
  subscription OnDeleteTour {
    onDeleteTour {
      ownerApn
      id
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChecklist = /* GraphQL */ `
  subscription OnCreateChecklist {
    onCreateChecklist {
      ownerApn
      id
      apn
      name
      description
      items {
        schemaVersion
        seq
        text
        checked
      }
      status
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const onUpdateChecklist = /* GraphQL */ `
  subscription OnUpdateChecklist {
    onUpdateChecklist {
      ownerApn
      id
      apn
      name
      description
      items {
        schemaVersion
        seq
        text
        checked
      }
      status
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const onDeleteChecklist = /* GraphQL */ `
  subscription OnDeleteChecklist {
    onDeleteChecklist {
      ownerApn
      id
      apn
      name
      description
      items {
        schemaVersion
        seq
        text
        checked
      }
      status
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const onCreateMembershipStatus = /* GraphQL */ `
  subscription OnCreateMembershipStatus($owner: String!) {
    onCreateMembershipStatus(owner: $owner) {
      id
      subscriptionLevel
      subscriptionCode
      subscriptionId
      lastLoginTime
      stripeSubscription {
        schemaVersion
        stripeSubscriptionType
        stripeSubscriptionId
        cancelServiceDate
        stripeCurrentPeriodEnd
        cancelAtPeriodEnd
        stripeCancelDate
        updatedAt
      }
      paymentVerified
      schemaVersion
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateMembershipStatus = /* GraphQL */ `
  subscription OnUpdateMembershipStatus($owner: String!) {
    onUpdateMembershipStatus(owner: $owner) {
      id
      subscriptionLevel
      subscriptionCode
      subscriptionId
      lastLoginTime
      stripeSubscription {
        schemaVersion
        stripeSubscriptionType
        stripeSubscriptionId
        cancelServiceDate
        stripeCurrentPeriodEnd
        cancelAtPeriodEnd
        stripeCancelDate
        updatedAt
      }
      paymentVerified
      schemaVersion
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteMembershipStatus = /* GraphQL */ `
  subscription OnDeleteMembershipStatus($owner: String!) {
    onDeleteMembershipStatus(owner: $owner) {
      id
      subscriptionLevel
      subscriptionCode
      subscriptionId
      lastLoginTime
      stripeSubscription {
        schemaVersion
        stripeSubscriptionType
        stripeSubscriptionId
        cancelServiceDate
        stripeCurrentPeriodEnd
        cancelAtPeriodEnd
        stripeCancelDate
        updatedAt
      }
      paymentVerified
      schemaVersion
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateFormigioSubscription = /* GraphQL */ `
  subscription OnCreateFormigioSubscription {
    onCreateFormigioSubscription {
      id
      name
      label
      description
      stripePlanId
      active
      level
      sort
      price
      code
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFormigioSubscription = /* GraphQL */ `
  subscription OnUpdateFormigioSubscription {
    onUpdateFormigioSubscription {
      id
      name
      label
      description
      stripePlanId
      active
      level
      sort
      price
      code
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFormigioSubscription = /* GraphQL */ `
  subscription OnDeleteFormigioSubscription {
    onDeleteFormigioSubscription {
      id
      name
      label
      description
      stripePlanId
      active
      level
      sort
      price
      code
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEmailPreferences = /* GraphQL */ `
  subscription OnCreateEmailPreferences {
    onCreateEmailPreferences {
      userApn
      emailAddress
      emailTopics
      unsubscribedTopics
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEmailPreferences = /* GraphQL */ `
  subscription OnUpdateEmailPreferences {
    onUpdateEmailPreferences {
      userApn
      emailAddress
      emailTopics
      unsubscribedTopics
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEmailPreferences = /* GraphQL */ `
  subscription OnDeleteEmailPreferences {
    onDeleteEmailPreferences {
      userApn
      emailAddress
      emailTopics
      unsubscribedTopics
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEmailNotification = /* GraphQL */ `
  subscription OnCreateEmailNotification {
    onCreateEmailNotification {
      id
      userApn
      sendTimestamp
      topic
      email
      summaryText
      payload
      new
      processedTimestamp
      entityApn
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEmailNotification = /* GraphQL */ `
  subscription OnUpdateEmailNotification {
    onUpdateEmailNotification {
      id
      userApn
      sendTimestamp
      topic
      email
      summaryText
      payload
      new
      processedTimestamp
      entityApn
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEmailNotification = /* GraphQL */ `
  subscription OnDeleteEmailNotification {
    onDeleteEmailNotification {
      id
      userApn
      sendTimestamp
      topic
      email
      summaryText
      payload
      new
      processedTimestamp
      entityApn
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTrackerShare = /* GraphQL */ `
  subscription OnCreateTrackerShare {
    onCreateTrackerShare {
      id
      trackerId
      ownerApn
      ownerEmailAddress
      ownerName
      emailAddress
      userId
      status
      permissionLevel
      notifications
      reviewCycle {
        frequency
        interval
        positions
        lastReviewedDate
        startReviewDate
        updatedAt
        schemaVersion
      }
      role {
        name
        color
      }
      shareDescription
      schemaVersion
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTrackerShare = /* GraphQL */ `
  subscription OnUpdateTrackerShare {
    onUpdateTrackerShare {
      id
      trackerId
      ownerApn
      ownerEmailAddress
      ownerName
      emailAddress
      userId
      status
      permissionLevel
      notifications
      reviewCycle {
        frequency
        interval
        positions
        lastReviewedDate
        startReviewDate
        updatedAt
        schemaVersion
      }
      role {
        name
        color
      }
      shareDescription
      schemaVersion
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTrackerShare = /* GraphQL */ `
  subscription OnDeleteTrackerShare {
    onDeleteTrackerShare {
      id
      trackerId
      ownerApn
      ownerEmailAddress
      ownerName
      emailAddress
      userId
      status
      permissionLevel
      notifications
      reviewCycle {
        frequency
        interval
        positions
        lastReviewedDate
        startReviewDate
        updatedAt
        schemaVersion
      }
      role {
        name
        color
      }
      shareDescription
      schemaVersion
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTrackerActivity = /* GraphQL */ `
  subscription OnCreateTrackerActivity {
    onCreateTrackerActivity {
      trackerId
      createdAt
      userId
      byUser
      summary
      entityType
      entityId
      schemaVersion
      updatedAt
    }
  }
`;
export const onUpdateTrackerActivity = /* GraphQL */ `
  subscription OnUpdateTrackerActivity {
    onUpdateTrackerActivity {
      trackerId
      createdAt
      userId
      byUser
      summary
      entityType
      entityId
      schemaVersion
      updatedAt
    }
  }
`;
export const onDeleteTrackerActivity = /* GraphQL */ `
  subscription OnDeleteTrackerActivity {
    onDeleteTrackerActivity {
      trackerId
      createdAt
      userId
      byUser
      summary
      entityType
      entityId
      schemaVersion
      updatedAt
    }
  }
`;
export const onCreateCommitmentTray = /* GraphQL */ `
  subscription OnCreateCommitmentTray {
    onCreateCommitmentTray {
      schemaVersion
      ownerApn
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCommitmentTray = /* GraphQL */ `
  subscription OnUpdateCommitmentTray {
    onUpdateCommitmentTray {
      schemaVersion
      ownerApn
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCommitmentTray = /* GraphQL */ `
  subscription OnDeleteCommitmentTray {
    onDeleteCommitmentTray {
      schemaVersion
      ownerApn
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMigration = /* GraphQL */ `
  subscription OnCreateMigration {
    onCreateMigration {
      userId
      migrationKey
      completed
      schemaVersion
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMigration = /* GraphQL */ `
  subscription OnUpdateMigration {
    onUpdateMigration {
      userId
      migrationKey
      completed
      schemaVersion
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMigration = /* GraphQL */ `
  subscription OnDeleteMigration {
    onDeleteMigration {
      userId
      migrationKey
      completed
      schemaVersion
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSMSPreferences = /* GraphQL */ `
  subscription OnCreateSMSPreferences {
    onCreateSMSPreferences {
      userApn
      number
      verifiedAt
      unsubscribedTopics
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSMSPreferences = /* GraphQL */ `
  subscription OnUpdateSMSPreferences {
    onUpdateSMSPreferences {
      userApn
      number
      verifiedAt
      unsubscribedTopics
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSMSPreferences = /* GraphQL */ `
  subscription OnDeleteSMSPreferences {
    onDeleteSMSPreferences {
      userApn
      number
      verifiedAt
      unsubscribedTopics
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSMSNotification = /* GraphQL */ `
  subscription OnCreateSMSNotification {
    onCreateSMSNotification {
      id
      userApn
      sendTimestamp
      topic
      number
      summaryText
      new
      processedTimestamp
      entityApn
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSMSNotification = /* GraphQL */ `
  subscription OnUpdateSMSNotification {
    onUpdateSMSNotification {
      id
      userApn
      sendTimestamp
      topic
      number
      summaryText
      new
      processedTimestamp
      entityApn
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSMSNotification = /* GraphQL */ `
  subscription OnDeleteSMSNotification {
    onDeleteSMSNotification {
      id
      userApn
      sendTimestamp
      topic
      number
      summaryText
      new
      processedTimestamp
      entityApn
      createdAt
      updatedAt
    }
  }
`;
export const onCreateContact = /* GraphQL */ `
  subscription OnCreateContact {
    onCreateContact {
      id
      userApn
      friendApn
      friendName
      friendEmail
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const onUpdateContact = /* GraphQL */ `
  subscription OnUpdateContact {
    onUpdateContact {
      id
      userApn
      friendApn
      friendName
      friendEmail
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const onDeleteContact = /* GraphQL */ `
  subscription OnDeleteContact {
    onDeleteContact {
      id
      userApn
      friendApn
      friendName
      friendEmail
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const onCreateTrackerTemplate = /* GraphQL */ `
  subscription OnCreateTrackerTemplate {
    onCreateTrackerTemplate {
      id
      ownerApn
      name
      description
      scaffold {
        dream
        reviewCycle {
          frequency
          interval
          positions
        }
        success
        learn {
          type
          item {
            title
            content
            url
            question
            answer
          }
        }
        metrics {
          type
          item {
            name
            values {
              value
              datetime
            }
          }
        }
        notes
        plans {
          title
          open
          milestones {
            description
            notes
            actionItems
          }
        }
      }
      variables {
        name
        type
        token
      }
      sourceTrackerId
      schemaVersion
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTrackerTemplate = /* GraphQL */ `
  subscription OnUpdateTrackerTemplate {
    onUpdateTrackerTemplate {
      id
      ownerApn
      name
      description
      scaffold {
        dream
        reviewCycle {
          frequency
          interval
          positions
        }
        success
        learn {
          type
          item {
            title
            content
            url
            question
            answer
          }
        }
        metrics {
          type
          item {
            name
            values {
              value
              datetime
            }
          }
        }
        notes
        plans {
          title
          open
          milestones {
            description
            notes
            actionItems
          }
        }
      }
      variables {
        name
        type
        token
      }
      sourceTrackerId
      schemaVersion
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTrackerTemplate = /* GraphQL */ `
  subscription OnDeleteTrackerTemplate {
    onDeleteTrackerTemplate {
      id
      ownerApn
      name
      description
      scaffold {
        dream
        reviewCycle {
          frequency
          interval
          positions
        }
        success
        learn {
          type
          item {
            title
            content
            url
            question
            answer
          }
        }
        metrics {
          type
          item {
            name
            values {
              value
              datetime
            }
          }
        }
        notes
        plans {
          title
          open
          milestones {
            description
            notes
            actionItems
          }
        }
      }
      variables {
        name
        type
        token
      }
      sourceTrackerId
      schemaVersion
      createdAt
      updatedAt
    }
  }
`;
