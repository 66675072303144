import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import React, {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import {getTrackerShareStruct, TRACKER_SHARE_STATUS_PENDING} from "../../structs/trackerShare";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import moment from "moment";
import RespondDialog from "../TrackerShare/RespondDialog";
import {ListItemAvatar} from "@material-ui/core";
import {Announcement} from "@material-ui/icons";

const PendingShareList = () => {
  const trackerShares = useSelector(store => store.trackerShares);
  const user = useSelector(store => store.user);
  const [ share, setShare ] = useState(getTrackerShareStruct());
  const pendingShares = trackerShares.filter(share => share.status === TRACKER_SHARE_STATUS_PENDING && share.emailAddress === user.attributes.email)

  const handleReset = useCallback(() => {
    setShare(getTrackerShareStruct());
  }, []);

  return (
    pendingShares.length ? (
      <List className="tour-pending-shares">
        {pendingShares.map(trackerShare => (
            <ListItem key={trackerShare.id} onClick={() => setShare(trackerShare)} button>
              <ListItemAvatar><Announcement color={"disabled"} /></ListItemAvatar>
              <ListItemText
                primary={`Tracker Share: ${trackerShare.shareDescription}`}
                secondary={`To: You by ${trackerShare.ownerName} | When: ${moment(trackerShare.createdAt).calendar()} | Status: ${trackerShare.status}`}
              />
            </ListItem>
          )
        )}
        <RespondDialog trackerShare={share} onReset={handleReset} />
      </List>
    ) : null
  );
};

export default PendingShareList;
