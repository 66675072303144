import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import debounce from 'lodash/debounce';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import CalendarIcon from '@material-ui/icons/Event';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import {
  COMMITMENT_STATUS_COMPLETE,
  COMMITMENT_STATUS_MOVED,
  COMMITMENT_STATUS_PENDING,
  getCommitmentStruct
} from '../../structs/commitments';
import {getDateFromString, getHoursFromMinutes} from '../../utils/date';
import ColorSelectorHelper from '../Ui/ColorSelectorHelper';

const style = () => ({
  listItemText: {
    padding: 0
  },
  listItemPrimary: {
    fontSize: '.85rem',
    lineHeight: 1.5
  },
  listItemSecondary: {
    lineHeight: 1
  },
  context: {
    fontSize: '.675rem',
    color: 'grey'
  },
  description: {
    display: 'inline'
  },
  delayChipRoot: {
    height: '20px',
    marginLeft: '.75em',
    background: 'rgba(253,122,108,0.56)'
  },
  delayChipRootActive: {
    height: '20px',
    marginLeft: '.75em',
    background: 'rgba(159, 221, 156, 0.56)'
  },
  timeChipRoot: {
    height: '20px',
    marginLeft: '.75em'
  },
  timeChip: {
    paddingLeft: 9,
    paddingRight: 9
  },
  delayChip: {
    paddingLeft: 9,
    paddingRight: 9,
  },
  checkbox: {
    padding: '10px 5px'
  },
  listPadding: {
    padding: '0.25em 5em 0.25em 6px',
    margin: '0.25em 0 0.25em 0',
    minHeight: 48
  }
});

class CommitmentItem extends Component {
  state = {
    commitment: getCommitmentStruct()
  };

  componentDidMount() {
    const { commitment: document } = this.props;
    const { entity: commitmentProp } = document;
    const { commitment } = this.state;
    if (
      commitment.id !== commitmentProp.id ||
      commitment.updatedAt !== commitmentProp.updatedAt
    ) {
      this.hydrate(commitmentProp);
    }
  }

  componentDidUpdate() {
    const { commitment: document } = this.props;
    const { entity: commitmentProp } = document;
    const { commitment } = this.state;
    if (
      commitment.id !== commitmentProp.id ||
      commitment.updatedAt !== commitmentProp.updatedAt
    ) {
      this.hydrate(commitmentProp);
    }
  }

  hydrate = commitment => this.setState({ commitment });

  handleChange = event => {
    const { commitment } = this.state;
    const newCommitment = {
      ...commitment
    };
    newCommitment[event.target.name] = event.target.value;
    if (event.target.name === 'status') {
      newCommitment[event.target.name] = event.target.checked
        ? COMMITMENT_STATUS_COMPLETE
        : COMMITMENT_STATUS_PENDING;
    }
    this.setState({
      commitment: newCommitment
    });
    this.persistChange();
  };

  persistChange = debounce(() => {
    const { saveCommitment } = this.props;
    const { commitment } = this.state;
    saveCommitment(commitment);
  }, 650);

  handleDelete = () => {
    const { commitment } = this.state;
    const { deleteCommitment } = this.props;
    deleteCommitment(commitment);
  };

  handleClick = () => {
    const { setActiveCommitment } = this.props;
    const { commitment } = this.state;
    setActiveCommitment(commitment);
  };

  handleColorChange = color => {
    const { commitment } = this.state;
    commitment.highlightColor = color;

    this.setState({ commitment });

    this.persistChange();
  };

  render() {
    const { commitment } = this.state;
    const { showDate, classes, now } = this.props;
    const commitmentDate = new Date(commitment.date);
    const commitmentDay  = moment(getDateFromString(commitmentDate.toString(), true).toString());
    let delay, delayLabel;
    if (now) {
      const today = moment(now.toString());
      delay = Math.round(moment.duration(today.diff(commitmentDay)).asDays());
      delayLabel = delay > 0 ? `+${delay}` : 'Today';
    }

    return (
      <ListItem key={commitment.id} dense className={classes.listPadding}>
        <ColorSelectorHelper
          color={
            commitment.status === COMMITMENT_STATUS_PENDING ? commitment.highlightColor : null
          }
          onSelect={this.handleColorChange}
          buttonStyles={{
            paddingRight: 0
          }}
        />
        {commitment.status === COMMITMENT_STATUS_MOVED ? (
          <IconButton disabled>
            <ArrowRightAltIcon />
          </IconButton>
        ) : (
          <Checkbox
            key={`${commitment.id}_complete`}
            id={`${commitment.id}_complete`}
            name="status"
            checked={Boolean(commitment.status === COMMITMENT_STATUS_COMPLETE)}
            className={`tour-commitment-complete ${classes.checkbox}`}
            tabIndex={-1}
            disableRipple
            onChange={this.handleChange}
            color={commitment.status === COMMITMENT_STATUS_COMPLETE ? 'secondary' : 'default'}
          />
        )}
        <ListItemText
          onClick={this.handleClick}
          style={{ cursor: 'pointer' }}
          primary={
            <Typography className={classes.description} component="span">
              {commitment.description}
            </Typography>
          }
          primaryTypographyProps={{ className: classes.listItemPrimary }}
          secondary={
            <React.Fragment>
              <Typography component="span" className={classes.context}>
                {showDate ? (<><CalendarIcon color="inherit" fontSize="inherit" />{' '}{moment(commitmentDate).calendar()}{' | '}</>) : null}
              </Typography>
              {commitment.role.name ? (
                <Typography component="span" className={classes.context}>
                  {commitment.role.name}{' | '}
                </Typography>
              ) : null}
              {commitment.contextDescription ? (
                <Typography component="span" className={classes.context}>
                  {commitment.contextDescription}
                </Typography>
              ) : null}
            </React.Fragment>
          }
          secondaryTypographyProps={{ className: classes.listItemSecondary }}
          className={`tour-commitment-focus ${classes.listItemText}`}
        />
        <ListItemSecondaryAction>
          {commitment.timeCommitment > 0 && commitment.status === COMMITMENT_STATUS_PENDING ? (
              <Chip
                  classes={{
                    root: classes.timeChipRoot,
                    label: classes.timeChip
                  }}
                  component="span"
                  label={getHoursFromMinutes(commitment.timeCommitment)}
              />
          ) : null}
          {delay >= 0 && (
              <Chip
                  component="span"
                  label={delayLabel}
                  classes={{
                    root: delay > 0 ? classes.delayChipRoot : classes.delayChipRootActive,
                    label: classes.delayChip
                  }}
              />
          )}
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

CommitmentItem.defaultProps = {
  showDate: false
};

CommitmentItem.propTypes = {
  classes: PropTypes.any.isRequired,
  commitment: PropTypes.object.isRequired,
  deleteCommitment: PropTypes.func.isRequired,
  saveCommitment: PropTypes.func.isRequired,
  setActiveCommitment: PropTypes.func.isRequired,
  showDate: PropTypes.bool
};

export default withStyles(style)(CommitmentItem);
