import {
  RESET_ACTIVE_TRACKER,
  SET_ACTIVE_TRACKER,
  INIT_ACTIVE_TRACKER
} from '../actions/activeTracker';
import { ActiveTrackerStruct, getTrackerStruct } from '../../structs/trackers';

export default function activeTrackerReducer(
  oldstate = Object.assign({}, ActiveTrackerStruct),
  action
) {
  let activeTracker = getTrackerStruct();
  switch (action.type) {
    case INIT_ACTIVE_TRACKER:
      return { tracker: action.payload, requestedId: '' };

    case SET_ACTIVE_TRACKER:
      activeTracker = getTrackerStruct(action.payload);
      return { tracker: activeTracker, requestedId: activeTracker.id };

    case RESET_ACTIVE_TRACKER:
      return { tracker: activeTracker, requestedId: '' };

    default:
      return oldstate;
  }
}
