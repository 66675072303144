import React, {useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import Style from './Style';
import logo from '../images/fwing.png';
import routes from '../../constants/routes.json';
import { getTrackerStruct } from '../../structs/trackers';
import { TrackerIndexStruct } from '../../structs/indexers';
import { getDaysUntilReview } from '../../models/tracker';
import CustomToolbarSelect from './CustomToolbarSelect';
import RoleFilter from "../Role/Filter";
import {ROLE_NO_ROLE_NAME} from "../../structs/roles";
import {useDispatch, useSelector} from "react-redux";
import {setDesiredRouteAction} from "../../redux/actions/app";

const Trackers = ({ classes }) => {
  const dispatch = useDispatch();
  const trackers = useSelector(state => state.trackerIndex.all);
  const activeRole = useSelector(state => state.roles.active);
  const [tableData, setTableData] = useState([]);

  const sortTableData = (a, b) => {
    const first = a[3];
    const second = b[3];
    if (first < second) {
      return -1;
    }
    return 1;
  };

  useEffect(() => {
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit'
    });
    const tableData = trackers
      .filter(t => (activeRole.name && t.role.name === activeRole.name) || (t.role.name === '' && activeRole.name === ROLE_NO_ROLE_NAME) || activeRole.name === '')
      .map(trackerDocument => {
        const { entity: tracker } = trackerDocument;
        const wetTracker = getTrackerStruct(tracker);
        const nextReviewDate = new Date(trackerDocument.reviewDate);
        return [
          wetTracker.id,
          wetTracker.dream,
          dateFormatter.format(nextReviewDate),
          getDaysUntilReview(nextReviewDate),
          wetTracker.status
        ];
      });
    tableData.sort(sortTableData);
    setTableData(tableData);
  }, [trackers, activeRole.name]);

  const tableColumns = [
    { name: 'id', options: { display: 'excluded', filter: false } },
    { name: 'Dream Statement', options: { filter: false } },
    { name: 'Review Date', options: { filter: false } },
    { name: 'Review Due', options: { filter: false, sortDirection: 'asc' } },
    { name: 'Status', options: { filter: true } }
  ];

  const tableOptions = {
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbarSelect
        selectedRows={selectedRows}
        displayData={displayData}
        setSelectedRows={setSelectedRows}
      />
    ),
    download: false,
    print: false,
    filter: true,
    selectableRows: true,
    viewColumns: false,
    responsive: 'scroll',
    onRowClick: rowData => {
      dispatch(setDesiredRouteAction(`${routes.TRACKER_BASE}${rowData[0]}`));
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.containerCenter}>
        <div>
          <RoleFilter />
        </div>
        <div className={classes.contentContainer}>
          <div className={classes.trackersList}>
            <MUIDataTable
              title="Trackers"
              data={tableData}
              columns={tableColumns}
              options={tableOptions}
            />
          </div>
        </div>
      </div>
      <div className={classes.heading}>
        <img src={logo} alt="" className={classes.logo} />
      </div>
    </div>
  );
}

Trackers.defaultProps = {
  trackerIndex: TrackerIndexStruct
};

Trackers.propTypes = {
  trackerIndex: PropTypes.any,
  activeRole: PropTypes.object.isRequired,
  resetActiveTracker: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(Style)(Trackers);
