import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {createTemplateFromTrackerAction} from "../../../redux/actions/trackerTemplate";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {collectVariablesFromTracker} from "../../../structs/trackerTemplate";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import {ListItem} from "@material-ui/core";
import List from "@material-ui/core/List/List";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const TemplateDialog = (
  {
    tracker,
    onDialog,
    open
  }
) => {
  const dispatch = useDispatch();
  const [ name, setName ] = useState('');
  const [ description, setDescription ] = useState('');

  const handleClose = () => {
    onDialog('');
  };

  const handleSubmit = () => {
    dispatch(createTemplateFromTrackerAction({ tracker, template: { name, description, sourceTrackerId: tracker.id, variables }}));
    onDialog('');
  };

  const variables = useMemo(() => {
    return collectVariablesFromTracker(tracker);
  }, [tracker]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Create Template from Tracker
        <IconButton
          style={{ position: 'absolute', right: 0, top: 0 }}
          onClick={handleClose}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Template Name"
          value={name}
          fullWidth
          onChange={(event) => setName(event.target.value)}
        />
        <TextField
          label="Template Description"
          multiline
          fullWidth
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />

        <List>
          <ListItem>
            <ListItemText>Template Variables</ListItemText>
          </ListItem>
          {variables.length > 0 ? variables.map(variable => (
            <ListItem>
              <ListItemText>Name: {variable.name} Type: {variable.type}</ListItemText>
            </ListItem>
          )) : (
            <ListItem>
              <ListItemText>No Variables Found. To Add Variables use {'{{name:type}}'} notations.</ListItemText>
            </ListItem>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          autoFocus
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
};

TemplateDialog.defaultProps = {};

TemplateDialog.propTypes = {
  tracker: PropTypes.object.isRequired,
  onDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default TemplateDialog;
