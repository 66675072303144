import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import DragHandle from '@material-ui/icons/DragHandle';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { getChecklistItemStruct } from '../../../structs/checklists';

const style = () => ({
  dragHandle: {
    margin: '16px 0px 12px -12px;',
    color: 'lightgray'
  },
  checkboxButton: {
    padding: '12px 0px',
    margin: '4px 4px 0 0px'
  },
  listPadding: {
    minHeight: 48
  }
});

class ListsItem extends Component {
  state = { item: null };

  componentDidMount() {
    const { item: itemProp } = this.props;
    const { item } = this.state;

    if (
      (item && JSON.stringify(item.item) !== JSON.stringify(itemProp.item)) ||
      !item
    ) {
      this.hydrateState(itemProp);
    }
  }

  componentDidUpdate() {
    const { item: itemProp } = this.props;
    const { item } = this.state;

    if ((item && item.item !== itemProp.item) || !item) {
      this.hydrateState(itemProp);
    }

    this.focusSelected();
  }

  inputField;

  focusSelected = () => {
    const { selectedItem, item } = this.props;

    if (
      selectedItem === item.seq &&
      document.activeElement !== this.inputField
    ) {
      this.inputField.focus();
    }
  };

  hydrateState = item => {
    this.setState({
      item: getChecklistItemStruct(item)
    });
  };

  handleChange = event => {
    const { onChange } = this.props;
    const { item } = this.state;
    if (event.target.name === 'checked') {
      item[event.target.name] = event.target.checked;
    } else {
      item[event.target.name] = event.target.value;
    }

    onChange(item);
    this.setState({ item });
  };

  handleRemove = () => {
    const { item } = this.state;
    const { onRemove } = this.props;
    onRemove(item);
  };

  handleFocus = () => {
    const { onInputFocus } = this.props;
    const { item } = this.state;
    if (item) {
      onInputFocus(item.seq);
    }
  };

  handleBlur = () => {
    const { onInputBlur } = this.props;
    const { item } = this.state;
    onInputBlur(item.seq);
  };

  render() {
    const { item, selectedItem, classes } = this.props;

    return (
      <ListItem
        selected={selectedItem === item.seq}
        className={classes.listPadding}
      >
        <DragHandle className={classes.dragHandle} />
        <Checkbox
          name="checked"
          className={classes.checkboxButton}
          checked={item.checked}
          tabIndex={-1}
          disableRipple
          onChange={this.handleChange}
        />
        <TextField
          style={{ width: '90%' }}
          name="text"
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          margin="normal"
          value={item.text}
          InputProps={{
            disableUnderline: true
          }}
          inputRef={field => {
            this.inputField = field;
          }}
        />
        <ListItemSecondaryAction>
          <IconButton onClick={this.handleRemove}>
            <DeleteForeverIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

ListsItem.defaultProps = {
  selectedItem: null
};

ListsItem.propTypes = {
  classes: PropTypes.any.isRequired,
  item: PropTypes.any.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onInputFocus: PropTypes.func.isRequired,
  onInputBlur: PropTypes.func.isRequired,
  selectedItem: PropTypes.any
};

export default withStyles(style)(ListsItem);
