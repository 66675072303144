import {getTrackerActivityStruct} from '../../structs/trackerActivity';

// Tracker Activity Actions
export const UPSERT_LOCAL_TRACKER_ACTIVITY_ACTION = 'UPSERT_LOCAL_TRACKER_ACTIVITY';
export const SET_TRACKER_ACTIVITY_NEXT_TOKEN_ACTION = 'SET_TRACKER_ACTIVITY_NEXT_TOKEN';

// Saga Actions
export const TRACKER_ACTIVITY_FETCH_REQUEST_ACTION = 'TRACKER_ACTIVITY_FETCH_REQUEST';
export const TRACKER_ACTIVITY_FETCH_SUCCESS_ACTION = 'TRACKER_ACTIVITY_FETCH_SUCCESS';
export const TRACKER_ACTIVITY_CREATE_REQUEST_ACTION = 'TRACKER_ACTIVITY_CREATE_REQUEST';
export const TRACKER_ACTIVITY_CREATE_SUCCESS_ACTION = 'TRACKER_ACTIVITY_CREATE_SUCCESS';

export const fetchTrackerActivityByTrackerAction = payload => ({
  type: TRACKER_ACTIVITY_FETCH_REQUEST_ACTION,
  payload
});

export const addTrackerActivityAction = trackerActivity => ({
  type: TRACKER_ACTIVITY_CREATE_REQUEST_ACTION,
  payload: getTrackerActivityStruct(trackerActivity)
});

export const upsertLocalTrackerActivityAction = trackerActivity => ({
  type: UPSERT_LOCAL_TRACKER_ACTIVITY_ACTION,
  payload: getTrackerActivityStruct(trackerActivity)
});

export const setTrackerActivityNextTokenAction = token => ({
  type: SET_TRACKER_ACTIVITY_NEXT_TOKEN_ACTION,
  payload: token
});
