import React, { Component } from 'react';
import { connect } from 'react-redux';
import CalendarItem from '../../components/Commitment/CalendarItem';
import { saveCommitmentAction } from '../../redux/actions/commitments';
import { requestActiveCommitmentAction } from '../../redux/actions/activeCommitment';

export class CalendarItemContainer extends Component {
  render() {
    return <CalendarItem />;
  }
}

const mapDispatchToProps = dispatch => ({
  saveCommitment: commitment => {
    dispatch(saveCommitmentAction(commitment));
  },
  setActiveCommitment: commitment => {
    dispatch(requestActiveCommitmentAction(commitment));
  }
});

export default connect(null, mapDispatchToProps)(CalendarItem);
