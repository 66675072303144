import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import CommitmentList from '../../containers/ListsPage/CommitmentsList';
import AddCommitment from './AddCommitment';
import { getToday } from '../../utils/date';
import { stringifyApn } from '../../utils/apn/v2';
import {
  COMMITMENT_STATUS_COMPLETE,
  COMMITMENT_STATUS_MOVED,
  COMMITMENT_STATUS_PENDING,
  getCommitmentStruct
} from '../../structs/commitments';
import Style from './Style';

class Commitments extends Component {
  static propTypes = {
    createCommitment: PropTypes.func.isRequired,
    commitmentIndex: PropTypes.object.isRequired,
    updateCommitment: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
  };

  state = {
    commitmentDescription: '',
    commitments: []
  };

  componentDidMount() {
    const {
      commitmentIndex: { active }
    } = this.props;
    if (!active.length) return;

    this.indexDate();
  }

  componentDidUpdate(prevProps) {
    const {
      commitmentIndex: { updatedAt }
    } = this.props;

    if (updatedAt !== prevProps.commitmentIndex.updatedAt) {
      this.indexDate();
    }
  }

  prepareCommitments = commitments => {
    const withTracker = commitments
      .filter(el => el.dream.length > 0)
      .sort(this.sortByOriginalDate);
    const withoutTracker = commitments
      .filter(el => el.dream.length === 0)
      .sort(this.sortByOriginalDate);

    return [...withTracker, ...withoutTracker];
  };

  sortByOriginalDate = (a, b) => {
    const aSortDate = new Date(a.entity.createdAt);
    const bSortDate = new Date(b.entity.createdAt);
    if (aSortDate < bSortDate) {
      return -1;
    }
    return 1;
  };

  handleChange = event => {
    const text = event.target.value;
    this.setState({
      commitmentDescription: text
    });
  };

  handleDoneChange = (commitment) => () => {
    const { updateCommitment } = this.props;
    if (commitment.status !== COMMITMENT_STATUS_MOVED) {
      updateCommitment({
        ...commitment,
        status: commitment.status === COMMITMENT_STATUS_COMPLETE ? COMMITMENT_STATUS_PENDING : COMMITMENT_STATUS_COMPLETE
      });
    }
  };

  handleSave = () => {
    const { commitmentDescription } = this.state;
    const { createCommitment, user } = this.props;
    if (commitmentDescription.length > 0) {
      const commitment = {};
      commitment.userId = user.id;
      commitment.date = moment(getToday(true)).format();
      commitment.milestoneApn = stringifyApn({
        userId: user.id
      });
      commitment.description = commitmentDescription;
      const commitmentStruct = getCommitmentStruct(commitment);
      createCommitment(commitmentStruct);
    }
    this.setState({
      commitmentDescription: ''
    });
  };

  indexDate = (past = true) => {
    const {
      commitmentIndex: { active }
    } = this.props;
    
    this.setState({
      commitments: active
    });
  };

  render() {
    const { commitmentDescription, commitments } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <span className={classes.heading}>Active Commitments</span>
        {commitments.length > 0 && (
          <CommitmentList
            commitments={commitments}
            handleDoneChange={this.handleDoneChange}
            type="today"
          />
        )}
        {(commitments.length === 0) && (
          <span className={classes.noItems}>No items</span>
        )}
        <AddCommitment
          commitmentDescription={commitmentDescription}
          handleChange={this.handleChange}
          handleSave={this.handleSave}
        />
      </div>
    );
  }
}

export default withRouter(withStyles(Style)(Commitments));
