import React from 'react';
import Typography from '@material-ui/core/Typography';
import { put, takeLatest, takeEvery, select, all, take } from 'redux-saga/effects';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {
  REMOVE_LOCAL_JOURNAL,
  SAVE_LOCAL_JOURNAL,
  JOURNAL_CREATE_REQUEST_ACTION,
  JOURNAL_CREATE_SUCCESS_ACTION,
  JOURNAL_DELETE_REQUEST_ACTION,
  JOURNAL_DELETE_SUCCESS_ACTION,
  JOURNAL_UPDATE_REQUEST_ACTION,
  JOURNAL_UPDATE_SUCCESS_ACTION,
  setLocalJournalsAction,
  JOURNAL_DATA_LOAD_SUCCESS_ACTION,
  JOURNAL_DATA_LOAD_REQUEST_ACTION,
  SET_LOCAL_JOURNALS,
  UPSERT_LOCAL_JOURNAL,
  JOURNAL_FULL_SYNC_REQUEST_ACTION,
  JOURNAL_FULL_SYNC_SUCCESS_ACTION,
  updateJournalIndexAction,
  migrateJournalAction,
  JOURNAL_MIGRATE_SUCCESS_ACTION,
  saveJournalAction,
  JOURNAL_MIGRATE_REQUEST_ACTION,
  JOURNAL_TRACKER_FETCH_REQUEST_ACTION,
  upsertLocalJournalAction,
  setJournalNextTokenAction
} from '../actions/journal';
import {
  fetchRemoteJournalsByTracker,
  fetchRemoteLegacyJournals, removeLegacyJournal
} from '../../utils/appSync/journal';
import {evaluateJournals, getJournalStruct, migrateJournalEntry} from '../../structs/journal';
import { stringifyApn } from '../../utils/apn/v2';
import {
  APPLICATION_DATA_CLEAR_REQUEST_ACTION,
} from '../actions/app';
import {addNotificationAction, setLoadingAction, setProcessingAction} from '../actions/notifications';
import {TRACKER_STATUS_ARCHIVED} from '../../structs/trackers';
import {getSystemNotificationStruct} from '../../structs/notification';
import {setActiveTrackerAction} from '../actions/activeTracker';
import {JournalIndexStruct} from '../../structs/indexers';
import {JOURNAL_UPSERT_REMOTE_SUCCESS_ACTION} from "../actions/appSync";
import {fetchMigration, saveMigration} from "../../utils/appSync/migration";

function* handleCreateJournalRequest({ payload: journal }) {
  // Check for un-archiving against limits
  const activeTrackerDoc = yield select(store => store.activeTracker);
  const { tracker: activeTracker } = activeTrackerDoc;
  if (activeTracker.status === TRACKER_STATUS_ARCHIVED) {
    yield put(
      addNotificationAction(
        getSystemNotificationStruct({
          message: (
            <div>
              <Typography>
                {`Hmm... you can't update an archived tracker. If you want to change the tracker
                need make it active first. Any changes made will not be saved.`}
              </Typography>
            </div>
          ),
          type: 'dialog',
          level: 'warning'
        })
      )
    );
    yield put(setActiveTrackerAction(activeTracker));
    return 0;
  }
  
  const user = yield select(store => store.user);
  // Persist Journal to Local Data Store
  const newJournal = getJournalStruct({
    ...journal,
    id: uuidv4(),
    ownerApn: stringifyApn({ userId: user.id }),
    datetime: moment().toISOString(),
    updatedAt: moment().toISOString(),
    createdBy: `${user.attributes.givenName} ${user.attributes.familyName}`
  });

  yield put({
    type: UPSERT_LOCAL_JOURNAL,
    payload: newJournal
  });

  // Signal Complete
  yield put({
    type: JOURNAL_CREATE_SUCCESS_ACTION,
    payload: newJournal
  });
}

function* handleUpdateJournalRequest(action) {
  // Persist Journal to Local Data Store
  const journal = getJournalStruct(action.payload);
  journal.updatedAt = moment().format();

  yield put({
    type: SAVE_LOCAL_JOURNAL,
    payload: journal
  });

  // Signal Complete
  yield put({
    type: JOURNAL_UPDATE_SUCCESS_ACTION,
    payload: journal
  });
}

function* handleDeleteJournalRequest(action) {
  // Persist Journal to Local Data Store
  yield put({
    type: REMOVE_LOCAL_JOURNAL,
    payload: action.payload
  });

  // Signal Complete
  yield put({
    type: JOURNAL_DELETE_SUCCESS_ACTION,
    payload: action.payload
  });
}

function* handleJournalDataLoadRequest(action) {
  // Persist Journal to Local Data Store
  yield put({
    type: SET_LOCAL_JOURNALS,
    payload: action.payload
  });

  // Signal Complete
  yield put({
    type: JOURNAL_DATA_LOAD_SUCCESS_ACTION
  });
}

function* handleApplicationDataClear() {
  yield put(setLocalJournalsAction([]));
  yield put(updateJournalIndexAction(JournalIndexStruct));
}

function* handleFullSyncRequest() {
  const user = yield select(store => store.user);
  
  yield put(setProcessingAction('Fetching journals from Formigio Cloud'));
  
  // Push and Pull Journals
  const migration = yield fetchMigration(user.id, 'journal_1.2.0');

  if (!migration.completed) {
    const legacyJournals = yield fetchRemoteLegacyJournals(user.id);
    if (legacyJournals.length > 0) {
      const { valid, migrate } = evaluateJournals(legacyJournals);

      yield put(setLoadingAction('Updating data for the latest version...'));

      if (valid.length > 0) {
        for (const journal of valid) {
          yield put(migrateJournalAction(journal));
          yield take(JOURNAL_MIGRATE_SUCCESS_ACTION);
        }
      }

      if (migrate.length > 0) {
        for (const journal of migrate) {
          yield put(migrateJournalAction(journal));
          yield take(JOURNAL_MIGRATE_SUCCESS_ACTION);
        }
      }

      yield saveMigration(user.id, 'journal_1.2.0');
      yield put(setLoadingAction(''));
    }
  }

  yield put({ type: JOURNAL_FULL_SYNC_SUCCESS_ACTION });
}

function* handleMigrationRequest({ payload: journal }) {
  const user = yield select(store => store.user);
  const migratedJournal = migrateJournalEntry(journal, user);
  yield put(saveJournalAction(migratedJournal));
  const { payload: savedJournal } = yield take(JOURNAL_UPSERT_REMOTE_SUCCESS_ACTION);
  yield removeLegacyJournal(journal);
  yield put({ type: JOURNAL_MIGRATE_SUCCESS_ACTION, payload: savedJournal });
}

function* handleTrackerJournalFetchRequest({ payload: { trackerId, nextToken: token }}) {
  const { items, nextToken } = yield fetchRemoteJournalsByTracker(trackerId, token);
  yield all(items.map(journal => put(upsertLocalJournalAction(journal))));
  yield put(setJournalNextTokenAction(nextToken));
}

function* handleAfterSyncCleanup() {
  const localJournals = yield select(store => store.journal.items);
  const olderJournals = localJournals.filter(journal =>
    moment(journal.datetime).isBefore(
      moment()
        .subtract(3, 'day')
        .startOf('day')
    )
  );
  if (olderJournals.length)
    yield all(
      olderJournals.map(journal =>
        put({ type: REMOVE_LOCAL_JOURNAL, payload: journal })
      )
    );
}

export default function* journalSaga() {
  // Handle Actions
  yield takeEvery(JOURNAL_CREATE_REQUEST_ACTION, handleCreateJournalRequest);
  yield takeEvery(JOURNAL_MIGRATE_REQUEST_ACTION, handleMigrationRequest);
  yield takeLatest(JOURNAL_UPDATE_REQUEST_ACTION, handleUpdateJournalRequest);
  yield takeLatest(JOURNAL_DELETE_REQUEST_ACTION, handleDeleteJournalRequest);
  yield takeLatest(
    JOURNAL_DATA_LOAD_REQUEST_ACTION,
    handleJournalDataLoadRequest
  );
  yield takeLatest(
    JOURNAL_TRACKER_FETCH_REQUEST_ACTION,
    handleTrackerJournalFetchRequest
  )

  yield takeLatest(
    APPLICATION_DATA_CLEAR_REQUEST_ACTION,
    handleApplicationDataClear
  );
  yield takeLatest(JOURNAL_FULL_SYNC_REQUEST_ACTION, handleFullSyncRequest);
  yield takeLatest(JOURNAL_FULL_SYNC_SUCCESS_ACTION, handleAfterSyncCleanup);
}
