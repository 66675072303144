import React, { Component } from 'react';
import { connect } from 'react-redux';
import { removeNotificationAction } from '../redux/actions/notifications';
import Notifications from '../components/Notification/Notifications';

export class NotificationsContainer extends Component {
  render() {
    return <Notifications />;
  }
}

const mapStateToProps = state => ({
  notifications: state.notifications,
  loading: state.loading,
  processing: state.processing,
  appError: state.app.error
});

const mapDispatchToProps = dispatch => ({
  dismissNotification: notification => {
    dispatch(removeNotificationAction(notification));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
