import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider/Divider';
import Grid from '@material-ui/core/Grid';
import {getEmailTopicKeys, EmailTopicMapping} from '../../../structs/notification';
import Checkbox from '@material-ui/core/Checkbox';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchEmailPreferenceAction, saveUserEmailPreferencesAction} from '../../../redux/actions/notifications';

const EmailPreferencesPanel = () => {
  const dispatch = useDispatch();
  const userEmailPreference = useSelector(store => store.userEmailPreference);
  const [changed, setChanged] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [unsubscribedTopics, setUnsubscribedTopics] = useState([]);
  const emailTopics = getEmailTopicKeys();
  
  useEffect(() => {
    dispatch(fetchEmailPreferenceAction());
  }, [dispatch]);

  useEffect(() => {
    if (changed) {
      dispatch(saveUserEmailPreferencesAction({
        unsubscribedTopics,
      }));
    }
  }, [unsubscribedTopics, dispatch, changed]);
  
  const toggleEnabled = () => {
    setChanged(true);
    setEnabled(!enabled);
    setUnsubscribedTopics(!enabled ? [] : emailTopics);
    
  };
  
  const toggleEmailTopics = (topic, value) => {
    setChanged(true);
    setUnsubscribedTopics(emailTopics.filter(emailTopic => {
      if (emailTopic === topic && value === false)
        return false;
      if (emailTopic === topic && value)
        return true;
  
      return unsubscribedTopics.indexOf(emailTopic) !== -1;
    }));
  };
  
  const handleTopicLoad = () => {
    const { userPreference: { unsubscribedTopics: propTopics } } = userEmailPreference;
    
    setUnsubscribedTopics(propTopics);
    setEnabled(propTopics.length !== emailTopics.length);
  };
  
  return (
    <ExpansionPanel onChange={handleTopicLoad}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>Email Notification Preferences</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Card>
          <CardHeader
            title="Email Notification Preferences"
            subheader="Toggle your email notifications"
          />
          <CardContent>
            <FormControlLabel
              onClick={toggleEnabled}
              control={<Switch checked={enabled} />}
              label={
                enabled
                  ? "Email Notifications - Enabled"
                  : "Email Notifications - Disabled"
              }
            />
            <Divider />
            {enabled && (
              <>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  {Object.keys(EmailTopicMapping).map(topicKey => {
                    const checked = unsubscribedTopics.indexOf(topicKey) === -1;
                    const description = EmailTopicMapping[topicKey];
                    return (
                      <Grid item xs={6}>
                        <FormControlLabel
                          onClick={() =>
                            toggleEmailTopics(
                              topicKey,
                              checked
                            )
                          }
                          control={
                            <Checkbox checked={checked} />
                          }
                          label={description}
                        />{" "}
                      </Grid>
                    )
                  })}
                </Grid>
              </>
            )}
          </CardContent>
        </Card>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default EmailPreferencesPanel;
