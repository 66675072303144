import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ListsItem from './Item';
import {
  getChecklistItemStruct,
  getChecklistStruct
} from '../../../structs/checklists';

export const style = () => ({
  checkboxButton: {
    padding: '12px 2px 12px 12px',
    margin: '-4px 4px 0 0px'
  },
  listPadding: {
    minHeight: 48
  }
});

const reorderSeq = items => {
  let seq = 1;
  return items.map(listItem => {
    const item = getChecklistItemStruct(listItem);
    item.seq = seq;
    seq += 1;
    return item;
  });
};

const reorderList = (items, startIndex, endIndex) => {
  const result = Array.from(items);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class ListsList extends Component {
  state = { items: [], newItem: getChecklistItemStruct(), selected: '' };

  componentDidMount() {
    const { list } = this.props;
    const { items } = this.state;

    if (list.items !== items) {
      this.hydrateState(list.items);
    }
  }

  componentDidUpdate() {
    const { list } = this.props;
    const { items, selected, newItem } = this.state;

    if (JSON.stringify(list.items) !== JSON.stringify(items)) {
      this.hydrateState(list.items);
    }

    if (
      selected === newItem.seq &&
      document.activeElement !== this.newItemField
    ) {
      this.newItemField.focus();
    }
  }

  newItemField;

  hydrateState = items => {
    const { newItem } = this.state;
    newItem.seq = this.getNewItemSequence(items);
    this.setState({ items: [...items], newItem });
  };

  handleNewChange = event => {
    const { newItem } = this.state;
    if (event.target.name === 'checked') {
      newItem[event.target.name] = event.target.checked;
    } else {
      if (event.target.value.length === 1 && !event.target.value.trim()) {
        return;
      }
      newItem[event.target.name] = event.target.value;
    }

    this.setState({ newItem });
  };

  handleItemChange = itemToChange => {
    const { items } = this.state;
    const { saveList, list } = this.props;
    const newItems = items.map(item => {
      if (item.seq === itemToChange.seq) {
        return itemToChange;
      }
      return item;
    });
    this.setState({
      items: newItems
    });

    saveList(Object.assign({}, list, { items: newItems }));
  };

  handleRemove = itemToRemove => {
    const { items } = this.state;
    const { saveList, list } = this.props;
    let newItems = items.filter(item => item.seq !== itemToRemove.seq);
    newItems = reorderSeq(newItems);
    this.setState({
      items: newItems
    });

    saveList(Object.assign({}, list, { items: newItems }));
  };

  handleAdd = event => {
    if (event.key !== 'Enter') return;

    event.target.blur();
  };

  handleAddBlur = event => {
    const { items, newItem } = this.state;
    const { saveList, list } = this.props;

    if (!newItem[event.target.name].trim()) {
      this.setState({ selected: '' });
      return;
    }

    items.push(getChecklistItemStruct(newItem));
    const cleanItem = getChecklistItemStruct({
      seq: this.getNewItemSequence(items)
    });
    this.setState({
      items,
      newItem: cleanItem,
      selected: cleanItem.seq
    });

    saveList(Object.assign({}, list, { items }));
  };

  getNewItemSequence = items => {
    let focusSeq = 1;
    if (items.length)
      items.map(item => {
        focusSeq += 1;
        return item;
      });
    return focusSeq;
  };

  handleAddFocus = () => {
    const { items, newItem } = this.state;
    const selected = this.getNewItemSequence(items);
    newItem.seq = selected;
    this.setState({ selected, newItem });
  };

  handleAddKeydown = event => {
    const { newItem } = this.state;

    // Support Up Arrow Navigation
    if (event.keyCode === 38) {
      this.setState({ selected: newItem.seq - 1 });
    }
  };

  handleItemFocus = id => {
    this.setState({ selected: id });
  };

  handleItemBlur = id => {
    const { selected } = this.state;
    if (selected === id) this.setState({ selected: '' });
  };

  handleListKeys = event => {
    const { selected, items } = this.state;

    // Handle Up Arrow
    if (event.keyCode === 38) {
      if (selected === 1) return;
      this.setState({ selected: selected - 1 });
    }

    // Handle Down Arrow
    if (event.keyCode === 40) {
      if (selected < items.length + 1)
        this.setState({ selected: selected + 1 });
    }
  };

  onDragEnd = result => {
    const { items } = this.state;
    const { saveList, list } = this.props;

    // dropped outside the list
    if (!result.destination) return;

    let newItems = reorderList(
      items,
      result.source.index,
      result.destination.index
    );

    newItems = reorderSeq(newItems);

    this.setState({
      items: newItems
    });

    saveList(Object.assign({}, list, { items: newItems }));
  };

  render() {
    const { items, newItem, selected } = this.state;
    const { classes } = this.props;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <List onKeyDown={this.handleListKeys}>
          <Droppable droppableId="droppable">
            {provided => (
              <div ref={provided.innerRef}>
                {items.length
                  ? items.map((item, index) => (
                      <Draggable
                        draggableId={item.seq}
                        key={item.seq}
                        index={index}
                      >
                        {innerProvided => (
                          <div
                            ref={innerProvided.innerRef}
                            {...innerProvided.draggableProps}
                            {...innerProvided.dragHandleProps}
                          >
                            <ListsItem
                              key={item.seq}
                              item={item}
                              onRemove={this.handleRemove}
                              onChange={this.handleItemChange}
                              onInputFocus={this.handleItemFocus}
                              onInputBlur={this.handleItemBlur}
                              selectedItem={selected}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))
                  : null}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <ListItem
            key="newListItem"
            selected={selected === newItem.seq}
            className={classes.listPadding}
          >
            <Checkbox
              checked={false}
              tabIndex={-1}
              disableRipple
              disabled
              className={classes.checkboxButton}
            />
            <TextField
              key={newItem.seq}
              name="text"
              style={{ width: '90%' }}
              placeholder="Add Item"
              onChange={this.handleNewChange}
              onKeyPress={this.handleAdd}
              onBlur={this.handleAddBlur}
              onFocus={this.handleAddFocus}
              onKeyDown={this.handleAddKeydown}
              value={newItem.item === ' ' ? '' : newItem.item}
              InputProps={{
                disableUnderline: true
              }}
              inputRef={field => {
                this.newItemField = field;
              }}
            />
          </ListItem>
        </List>
      </DragDropContext>
    );
  }
}

ListsList.defaultProps = {
  list: getChecklistStruct()
};

ListsList.propTypes = {
  classes: PropTypes.any.isRequired,
  list: PropTypes.any,
  saveList: PropTypes.func.isRequired
};

export default withStyles(style)(ListsList);
