import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import MilestoneList from "../../../containers/Milestone/List";
import TextField from '@material-ui/core/TextField/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListItem from '@material-ui/core/ListItem';
import DragHandle from '@material-ui/icons/DragHandle';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const Style = () => ({
  details: {
    width: '100%',
    '& > ul': {
      width: '100%'
    }
  },
  dragHandle: {
    margin: '15px 0px 12px -27px',
    color: 'lightgray'
  }
});

class PlanItem extends Component {
  
  state = {
    editedTitle: ''
  };
  
  handleKeyPress = event => {
    if (event.key !== 'Enter') return;
    
    event.target.blur();
  };
  
  handleSetMilestoneIds = seq => milestoneIds => {
    const { tracker: { plans }, onChange } = this.props;
    const plan = plans.find(plan => plan.seq === seq);
    
    onChange({...plan, milestoneIds});
  };
  
  handlePlanChange = (event, expanded) => {
    const { onChange, plan } = this.props;
    onChange({...plan, open: expanded});
  };
  
  handleTitleBlur = (event) => {
    const { onChange, plan } = this.props;
    onChange({...plan, title: event.target.value});
    this.setState({editedTitle: ''});
  };
  
  handleTitleChange = (event) => {
    this.setState({editedTitle: event.target.value});
  };
  
  handleRemove = () => {
    const { onRemove, plan } = this.props;
    onRemove(plan);
  };
  
  render() {
    const { plan, tracker, classes, listItem, readOnly } = this.props;
    const { editedTitle } = this.state;
    
    if (listItem) return (
      <ListItem>
        <DragHandle className={classes.dragHandle} />
        <TextField
          style={{ width: '90%', marginTop: 10 }}
          id="title"
          name="title"
          onChange={this.handleTitleChange}
          onBlur={this.handleTitleBlur}
          margin="normal"
          disabled={readOnly}
          value={editedTitle || plan.title}
          InputProps={{
            startAdornment: <InputAdornment position="start" style={{color: 'lightgray'}}>{plan.seq}</InputAdornment>,
            disableUnderline: true
          }}
        />
        {!readOnly && (
          <ListItemSecondaryAction>
            <IconButton onClick={this.handleRemove}>
              <DeleteForeverIcon />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
    
    return (
      <Accordion key={plan.seq} expanded={plan.open} onChange={this.handlePlanChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {plan.title}
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <MilestoneList
            plan={plan}
            readOnly={readOnly}
            onChange={this.handleSetMilestoneIds(plan.seq)}
            tracker={tracker}
          />
        </AccordionDetails>
      </Accordion>
    )
  }
}

PlanItem.propTypes = {
  classes: PropTypes.any.isRequired,
  plan: PropTypes.any.isRequired,
  tracker: PropTypes.any.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  listItem: PropTypes.bool
};

PlanItem.defaultProps = {
  listItem: false
};

export default withStyles(Style)(PlanItem);
