import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import UserIcon from "@material-ui/icons/AccountCircle";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import {ListItem, ListItemAvatar} from "@material-ui/core";
import List from "@material-ui/core/List/List";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {getContactStruct} from "../../structs/contact";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import HelpIcon from "../Tour/HelpIcon";

const ChooseDialog = ({ onSelect, selectedContact, open, setOpen, allowedContacts }) => {
  const contacts = useSelector(store => store.contacts);
  const [ term, setTerm ] = useState('');
  const [ contactList, setContactList ] = useState(contacts);

  useEffect(() => {
    if (term) {
      const filteredContacts = contacts.filter(c => (
        c.friendName.toLowerCase().includes(term.toLowerCase())
        || c.friendEmail.toLowerCase().includes(term.toLowerCase())
      ))
      setContactList(filteredContacts);
    } else {
      setContactList(contacts);
    }
  }, [contacts, term]);

  return (
    <Dialog
      open={open}
      fullWidth
    >
      <DialogTitle>
        Select Contact
        <IconButton
          style={{ position: 'absolute', right: 0, top: 0 }}
          onClick={() => setOpen(false)}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{ margin: "auto" }}
      >
          <>
            <Typography>Search</Typography>
            <TextField
              fullWidth
              autoFocus
              placeholder="Enter Contact Name or Email"
              value={term}
              onChange={({ target: { value }}) => setTerm(value)}
            />
            <List>
              {contactList.length > 0 ? contactList.map(contact => (
                <ListItem
                  key={contact.id}
                  selected={selectedContact.id === contact.id}
                  disabled={allowedContacts && !allowedContacts.includes(contact.friendEmail)}
                  button
                  onClick={() => {onSelect(contact); setOpen(false);}}
                >
                  <ListItemAvatar><UserIcon /></ListItemAvatar>
                  <ListItemText
                    primary={contact.friendName}
                    secondary={`${contact.friendEmail}`}
                  />
                  {allowedContacts && !allowedContacts.includes(contact.friendEmail) ? (
                    <ListItemSecondaryAction>
                      <HelpIcon title="Cannot share with those not added to the Tracker" />
                    </ListItemSecondaryAction>
                  ) : null}
                </ListItem>
              )) : null}
            </List>
          </>
      </DialogContent>
    </Dialog>
  );
};

ChooseDialog.defaultProps = {
  selectedContact: getContactStruct()
};

ChooseDialog.propTypes = {
  onSelect: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  selectedContact: PropTypes.object
};

export default ChooseDialog;
