import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import * as PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withRouter } from 'react-router';
import routes from '../../constants/routes.json';
import addTrackers from '../../components/Navgation/images/addtracker.png';
import {setActiveCommitmentAction} from '../../redux/actions/activeCommitment';

const style = () => ({
  dialog: {
    minWidth: '75%',
    maxWidth: '75%',
    minHeight: '75%',
    maxHeight: '75%',
    display: 'flex',
    flexDirection: 'column'
  },
  formControl: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '8px'
  },
  list: {
    overflow: 'auto',
    padding: '0'
  },
  closeButton: {
    float: 'right'
  },
  shape: {
    height: 22
  }
});

export const SearchParserEnum = {
  't:': 'tracker',
  'j:': 'journal',
  'm:': 'milestone',
  'c:': 'commitment',
  'l:': 'list'
};

class GlobalSearch extends Component {
  state = {
    query: '',
    results: [],
    activate: false,
    selectedIndex: 0
  };

  componentDidUpdate() {
    if (!this.subscribed) {
      window.addEventListener('keydown', this.handleGlobalKeydown);
      window.addEventListener('keyup', this.handleGlobalKeyup);
      this.subscribed = true;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleGlobalKeydown);
    window.removeEventListener('keyup', this.handleGlobalKeyup);
    this.subscribed = false;
  }

  navigateToCreateDream = () => {
    const { history } = this.props;
    history.push(routes.CREATEDREAM);
    this.handleCloseModal();
  };

  subscribed = false;

  cmdKey = false;
  
  handleGlobalKeyup = event => {
    // Clear the CMD key
    if (event.keyCode === 91 || event.keyCode === 93) {
      this.cmdKey = false;
    }
  };

  handleGlobalKeydown = event => {
    // Store the CMD key for Mac
    if (event.keyCode === 91 || event.keyCode === 93) {
      this.cmdKey = true;
    }

    // ESC closes modal
    if (event.keyCode === 27) {
      this.handleCloseModal();
    }

    // If the keys are in an existing focused text box, we stop listening
    if (
      document.activeElement.type === 'text' ||
      document.activeElement.type === 'textarea'
    )
      return;

    // Cmd/Ctrl + F for Find, opens the Search Box
    if ((event.ctrlKey || this.cmdKey) && event.keyCode === 70) {
      this.handleOpenModal();
    }
  };

  handleSearchBoxKeyDown = event => {
    const { results, selectedIndex } = this.state;

    // Handle Up Arrow
    if (event.keyCode === 38) {
      if (selectedIndex === 0) return;
      this.setState({ selectedIndex: selectedIndex - 1 });
    }

    // Handle Down Arrow
    if (event.keyCode === 40) {
      if (selectedIndex < results.length + 1)
        this.setState({ selectedIndex: selectedIndex + 1 });
    }

    // Enter when tracker selected in the search box, to navigate to tracker
    if (event.key === 'Enter') {
      this.handleCloseModal(() => {
        const item = results[selectedIndex];
        this.navigateToItem(item);
      });
    }
  };

  handleQueryChange = event => {
    this.setState({ query: event.target.value }, () => this.debounceSearch());
  };

  debounceSearch = debounce(() => {
    let { query } = this.state;
    const { searchIndex } = this.props;
    let searchType;
    if (query.length < 2) return;

    if (SearchParserEnum[query.slice(0, 2)]) {
      searchType = SearchParserEnum[query.slice(0, 2)];
      query = query.slice(2).trim();
    }

    const results = searchIndex.all.filter(item => {
      if (!searchType)
        return item.text.toLowerCase().match(query.toLowerCase(), 'igm');
      return (
        item.text.toLowerCase().match(query.toLowerCase(), 'igm') &&
        item.type === searchType
      );
    });

    this.setState({ results });
  }, 250);

  handleItemClick = item => () => {
    this.navigateToItem(item);
    this.handleCloseModal();
  };

  navigateToItem = item => {
    const { history, trackerIndex, setActiveCommitment } = this.props;
    const { entity } = item;
    if (!item) return;
    switch (item.type) {
      case 'list':
        history.push(`${routes.CHECKLISTS}/${entity.id}`);
        break;
      case 'commitment':
        this.handleCloseModal(() => setActiveCommitment(entity));
        break;
      default:
        history.push(
          `${routes.TRACKER_BASE}${trackerIndex.byId[item.id].entity.id}`
        );
    }
  };

  handleCloseModal = (callback) => {
    this.setState({
      activate: false,
      selectedIndex: 0,
      query: '',
      results: []
    }, (callback || null));
  };

  handleOpenModal = () => {
    this.setState({ activate: true });
  };

  capitalizeFirstLetter = string =>
    string.charAt(0).toUpperCase() + string.slice(1);

  render() {
    const { query, results, activate, selectedIndex } = this.state;
    const { classes } = this.props;
    let seq = -1;
    return (
      <React.Fragment>
        <Dialog
          open={activate}
          PaperProps={{
            className: classes.dialog
          }}
        >
          <DialogTitle>
            Search
            <IconButton
              className={classes.closeButton}
              onClick={this.handleCloseModal}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              className={classes.formControl}
              value={query}
              onChange={this.handleQueryChange}
              onKeyDown={this.handleSearchBoxKeyDown}
            />
            <List className={classes.list}>
              {results.length ? (
                results.map(item => {
                  seq += 1;
                  return (
                    <ListItem
                      selected={selectedIndex === seq}
                      key={seq}
                      button
                      name={item.entity.id}
                      onClick={this.handleItemClick(item)}
                    >
                      <ListItemText
                        primary={`${this.capitalizeFirstLetter(item.type)}: ${
                          item.text
                        }`}
                        secondary={item.context}
                      />
                    </ListItem>
                  );
                })
              ) : (
                <ListItem>
                  <ListItemText primary="No Items" />
                </ListItem>
              )}
            </List>
            <List className={classes.list}>
              <ListItem button>
                <ListItemIcon>
                  <img className={classes.shape} alt="" src={addTrackers} />
                </ListItemIcon>
                <ListItemText
                  primary="Add a Tracker"
                  onClick={this.navigateToCreateDream}
                />
              </ListItem>
            </List>
          </DialogContent>
        </Dialog>
        <FormControlLabel
          className={classes.searchLabel}
          onClick={this.handleOpenModal}
          control={
            <Tooltip title="CTRL+F / CMD+F">
              <SearchIcon className={classes.searchIcon} color="secondary" />
            </Tooltip>
          }
        />
      </React.Fragment>
    );
  }
}

GlobalSearch.propTypes = {
  classes: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  trackerIndex: PropTypes.any.isRequired,
  searchIndex: PropTypes.any.isRequired
};

const mapStateToProps = state => ({
  trackerIndex: state.trackerIndex,
  searchIndex: state.searchIndex
});

const mapDispatchToProps = dispatch => ({
  setActiveCommitment: commitment => dispatch(setActiveCommitmentAction(commitment))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(style)(GlobalSearch)));
