import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import TrackersList from './TrackersList';
import Style from './Style';
import Button from '@material-ui/core/Button';
import {TRACKER_STATUS_ACTIVE} from '../../structs/trackers';

class Trackers extends Component {
  static propTypes = {
    dateFormatter: PropTypes.object.isRequired,
    saveTracker: PropTypes.func.isRequired,
    searchArchive: PropTypes.func.isRequired,
    searchResults: PropTypes.array.isRequired,
    searchValue: PropTypes.string.isRequired,
    trackerIndex: PropTypes.object.isRequired,
    typing: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired
  };
  
  state = {
    includeArchive: false
  };

  handleSearchArchive = () => {
    const {
      searchValue,
      searchArchive
    } = this.props;
    this.setState({ includeArchive: true }, () => {
      searchArchive(searchValue);
    });
  };

  render() {
    const {
      dateFormatter,
      searchResults,
      searchValue,
      trackerIndex: { allToday: trackersToReview },
      typing,
      classes
    } = this.props;
    const { includeArchive } = this.state;
    const searched = includeArchive
      ? searchResults
      : searchResults.filter(tracker => tracker.entity.status === TRACKER_STATUS_ACTIVE);
    
    return (
      <div>
        {((searchValue && searchValue.length > 0) || typing) && (
          <span className={classes.heading}>Search results</span>
        )}
        {typing && (
          <div className={classes.loadingItem}>
            <CircularProgress size={20} />
            <span className={classes.loadingText}>Loading</span>
          </div>
        )}
        {searched && searched.length > 0 && !typing && (
          <TrackersList
            trackersToReview={searched}
            dateFormatter={dateFormatter}
          />
        )}
        {searchValue &&
          searched &&
          searched.length === 0 &&
          !typing && <span className={classes.noItems}>No results</span>}
        {searchValue && !typing && !includeArchive ? (
          <Button onClick={this.handleSearchArchive}>Search Archive</Button>
        ) : null}
        <span className={classes.heading}>Trackers to Review</span>
        {trackersToReview && trackersToReview.length > 0 && (
          <TrackersList
            trackersToReview={trackersToReview}
            dateFormatter={dateFormatter}
          />
        )}
        {(!trackersToReview || trackersToReview.length === 0) && (
          <span className={classes.noItems}>No items</span>
        )}
      </div>
    );
  }
}

export default withStyles(Style)(Trackers);
