export const NO_PAYMENT_REQUEST_ACTION = 'NO_PAYMENT_REQUEST';
export const NO_PAYMENT_SUCCESS_ACTION = 'NO_PAYMENT_SUCCESS';
export const STRIPE_PAYMENT_REQUEST_ACTION = 'STRIPE_PAYMENT_REQUEST';
export const STRIPE_PAYMENT_SUCCESS_ACTION = 'STRIPE_PAYMENT_SUCCESS';
export const STRIPE_PAYMENT_FAILURE_ACTION = 'STRIPE_PAYMENT_FAILURE';
export const STRIPE_CANCEL_REQUEST_ACTION = 'STRIPE_CANCEL_REQUEST';
export const STRIPE_CANCEL_SUCCESS_ACTION = 'STRIPE_CANCEL_SUCCESS';
export const STRIPE_CANCEL_FAILURE_ACTION = 'STRIPE_CANCEL_FAILURE';
export const STRIPE_CHECK_REQUEST_ACTION = 'STRIPE_CHECK_REQUEST';
export const STRIPE_CHECK_SUCCESS_ACTION = 'STRIPE_CHECK_SUCCESS';
export const STRIPE_CHANGE_REQUEST_ACTION = 'STRIPE_CHANGE_REQUEST';
export const STRIPE_CHANGE_FAILURE_ACTION = 'STRIPE_CHANGE_FAILURE';
export const STRIPE_CHANGE_SUCCESS_ACTION = 'STRIPE_CHANGE_SUCCESS';
export const STRIPE_CHECK_FAILURE_ACTION = 'STRIPE_CHECK_FAILURE';
export const PAYMENT_GATE_CHECK_ACTION = 'PAYMENT_GATE_CHECK';
export const PAYMENT_GATE_ALLOW_ACTION = 'PAYMENT_GATE_ALLOW';
export const PAYMENT_GATE_REJECT_ACTION = 'PAYMENT_GATE_REJECT';
export const SUBSCRIPTION_START_REQUEST_ACTION = 'SUBSCRIPTION_START_REQUEST';
export const SUBSCRIPTION_START_SUCCESS_ACTION = 'SUBSCRIPTION_START_SUCCESS';

export const paymentAction = user => ({
  type: STRIPE_PAYMENT_REQUEST_ACTION,
  payload: user
});

export const noPaymentAction = user => ({
  type: NO_PAYMENT_REQUEST_ACTION,
  payload: user
});

export const paymentCancelAction = planId => ({
  type: STRIPE_CANCEL_REQUEST_ACTION,
  payload: planId
});

export const paymentCheckAction = user => ({
  type: STRIPE_CHECK_REQUEST_ACTION,
  payload: user
});

export const paymentChangeAction = planId => ({
  type: STRIPE_CHANGE_REQUEST_ACTION,
  payload: planId
});

export const payGateCheckAction = action => ({
  type: PAYMENT_GATE_CHECK_ACTION,
  payload: action
});
