import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Style from './Style';
import routes from '../../constants/routes.json';
import { USER_STATUS_LOGGED_IN, UserStruct } from '../../structs/user';
import Trademarked from '../Ui/Trademarked';

class Welcome extends Component {
  state = {
    activeStep: 0,
    showStepper: false,
    showHeader: false,
    waitTime: 4000,
    showContinue: false,
  };

  componentDidMount() {
    this.progressTimeout = setTimeout(this.progressShowHeader, 1500);
  }

  componentWillUnmount() {
    clearTimeout(this.progressTimeout);
    clearInterval(this.stepTimer);
  }

  stepTimer;
  progressTimeout;

  welcomeSteps = {
    1: {
      step: '1',
      title: 'Create a Tracker page',
      content: 'Describe the end goal',
    },
    2: {
      step: '2',
      title: 'Plan it out',
      content: 'Build out the accomplishment path.',
    },
    3: {
      step: '3',
      title: 'Commit to something',
      content: 'What can you do to progress along the path?',
    },
    4: {
      step: '4',
      title: 'Start working',
      content: 'Using the daily commitments and journal.',
    },
    5: {
      step: '5',
      title: 'Review & repeat',
      content: 'Review your trackers and recommit.',
    },
  };

  progressShowHeader = () => {
    this.setState({ showHeader: true });
    setTimeout(this.progressShowStepper, 1000);
  };

  progressShowStepper = () => {
    const { waitTime } = this.state;
    this.stepTimer = setInterval(this.progressWelcomeStep, waitTime);
    this.setState({ showStepper: true, showContinue: true, waitTime: 3200 });
  };

  progressWelcomeStep = () => {
    const { activeStep } = this.state;
    if (activeStep < Object.keys(this.welcomeSteps).length) {
      this.setState({ activeStep: activeStep + 1 });
    } else if (activeStep === this.welcomeSteps.length) {
      clearInterval(this.stepTimer);
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleNavigateToHome = () => {
    const { history } = this.props;
    history.push(routes.HOME);
  };

  handleNavigateToLogin = () => {
    const { history } = this.props;
    history.push(routes.LOGIN);
  };

  render() {
    const { user } = this.props;
    const { activeStep, showStepper, showHeader, showContinue } = this.state;

    // If we already have a user record.
    const greeting =
      user.id === '' ? <div>Hello and welcome!</div> : <div>Welcome back!</div>;

    const action =
      user.status === USER_STATUS_LOGGED_IN ? (
        <Fade in={showContinue}>
          <Button
            variant="contained"
            color="primary"
            fullWidth={true}
            onClick={this.handleNavigateToHome}
          >
            Continue to your Home Page
          </Button>
        </Fade>
      ) : (
        <Fade in={showContinue}>
          <Button
            variant="contained"
            color="primary"
            fullWidth={true}
            onClick={this.handleNavigateToLogin}
          >
            Continue to Login
          </Button>
        </Fade>
      );

    return (
      <Grid item data-tid="container" xs={12} sm={10} md={8} lg={6}>
        <Fade in>
          <Typography variant="h5" color="secondary">
            {greeting}
          </Typography>
        </Fade>
        <Fade in={showHeader}>
          <Typography variant="h6" color="secondary">
            Let&apos;s review how to be successful with the <Trademarked>Formigio</Trademarked> Planner
          </Typography>
        </Fade>
        <Fade in={showStepper}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {Object.keys(this.welcomeSteps).map((stepNumber) => {
              const step = this.welcomeSteps[stepNumber];
              return (
                <Step key={step.step}>
                  <StepLabel>{step.title}</StepLabel>
                  <StepContent transitionDuration={650}>
                    {step.content}
                  </StepContent>
                </Step>
              );
            })}
          </Stepper>
        </Fade>
        <div style={{ marginTop: 20 }}>{action}</div>
      </Grid>
    );
  }
}

Welcome.defaultProps = {
  user: UserStruct,
};

Welcome.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object.isRequired,
};

const WelcomePage = withStyles(Style)(Welcome);

export default WelcomePage;
