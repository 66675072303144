import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import SomethingWentWrong from '../Errors/SomethingWentWrong';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const variantColor = {
  success: green[600],
  warning: amber[700],
  error: red[600],
  info: blue[500]
};

export const style = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: red[600]
  },
  info: {
    backgroundColor: blue[500]
  },
  warning: {
    backgroundColor: amber[700]
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: 20
  },
  bigIcon: {
    fontSize: 50
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  }
});

class Notifications extends Component {
  state = {
    dialogs: [],
    snackbars: []
  };

  componentDidMount() {
    this.indexNotifications();
  }

  componentDidUpdate() {
    this.indexNotifications();
  }

  indexNotifications = () => {
    const { notifications } = this.props;
    const { dialogs, snackbars } = this.state;
    let altDialogs = [...dialogs];
    let altSnackbars = [...snackbars];

    // Clean up removed notifications from the local state.
    altDialogs = dialogs.length
      ? dialogs.filter(notification =>
          Boolean(notifications.filter(n => n.id === notification.id).length)
        )
      : [];
    altSnackbars = snackbars.length
      ? snackbars.filter(notification =>
          Boolean(notifications.filter(n => n.id === notification.id).length)
        )
      : [];

    if (
      notifications.length &&
      dialogs.length + snackbars.length !== notifications.length
    ) {
      const newDialogs = notifications.filter(
        notification =>
          notification.type === 'dialog' &&
          !dialogs.filter(n => n.id === notification.id).length
      );
      const newSnackbars = notifications.filter(
        notification =>
          notification.type === 'snackbar' &&
          !snackbars.filter(n => n.id === notification.id).length
      );
      if (newDialogs.length) altDialogs = dialogs.concat(newDialogs);
      if (newSnackbars.length) altSnackbars = snackbars.concat(newSnackbars);
    }

    if (
      JSON.stringify(altDialogs) !== JSON.stringify(dialogs) ||
      JSON.stringify(altSnackbars) !== JSON.stringify(snackbars)
    ) {
      this.setState({ dialogs: altDialogs, snackbars: altSnackbars });
    }
  };

  handleDismiss = notification => (event, reason) => {
    const { dismissNotification } = this.props;
    if (reason === 'clickaway') return;
    dismissNotification(notification);
  };

  render() {
    const { dialogs, snackbars } = this.state;
    const { classes, loading, processing, appError } = this.props;

    if (appError) {
      return (
        <Dialog open>
          <SomethingWentWrong error={appError} />
        </Dialog>
      );
    }

    return (
      <React.Fragment>
        <Dialog open={Boolean(loading)}>
          <DialogContent>{loading || 'Loading...'}</DialogContent>
        </Dialog>

        {processing ? (
          <Snackbar
            open={Boolean(processing)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <SnackbarContent
              message={processing}
              className={classNames(classes.info)}
            />
          </Snackbar>
        ) : null}

        {dialogs.length
          ? dialogs.map(notification => {
              const Icon = variantIcon[notification.level || 'info'];
              return (
                <Dialog
                  open={Boolean(notification.message)}
                  key={notification.id}
                >
                  <DialogContent style={{ textAlign: 'center' }}>
                    <Icon
                      className={classNames(
                        classes.bigIcon,
                        classes.iconVariant
                      )}
                      style={{ color: variantColor[notification.level] }}
                    />
                    <div>{notification.message}</div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleDismiss(notification)}
                    >
                      {notification.level === 'success'
                        ? 'Ok, thanks'
                        : 'Dismiss'}
                    </Button>
                  </DialogActions>
                </Dialog>
              );
            })
          : null}

        {snackbars.length
          ? snackbars.map(notification => {
              const Icon = variantIcon[notification.level || 'info'];
              return (
                <Snackbar
                  open={Boolean(notification.message)}
                  key={notification.id}
                  autoHideDuration={notification.autoHideDuration}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  onClose={this.handleDismiss(notification)}
                >
                  <SnackbarContent
                    className={classNames(
                      classes[notification.level || 'info']
                    )}
                    message={
                      <span id="client-snackbar" className={classes.message}>
                        <Icon
                          className={classNames(
                            classes.icon,
                            classes.iconVariant
                          )}
                        />
                        {notification.message}
                      </span>
                    }
                    action={[
                      <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={this.handleDismiss(notification)}
                      >
                        <CloseIcon />
                      </IconButton>
                    ]}
                  />
                </Snackbar>
              );
            })
          : null}
      </React.Fragment>
    );
  }
}

Notifications.propTypes = {
  classes: PropTypes.any.isRequired,
  notifications: PropTypes.any.isRequired,
  loading: PropTypes.any.isRequired,
  processing: PropTypes.any.isRequired,
  dismissNotification: PropTypes.func.isRequired,
  appError: PropTypes.any.isRequired
};

export default withStyles(style)(Notifications);
