import React from 'react';
import Button from '@material-ui/core/Button';
import {useDispatch, useSelector} from "react-redux";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {Brightness1, CheckCircle, ClearOutlined} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import {setActiveRoleAction} from "../../redux/actions/roles";
import {RoleStruct} from "../../structs/roles";

const RoleFilter = () => {
  const dispatch = useDispatch();
  const { all, active } = useSelector(store => store.roles);

  return (
    <>
      <Typography
        variant={"overline"}
        color="secondary"
        style={{display: 'inline-block', textAlign: 'center', width: '100%', marginTop: '1em'}}
      >
        Filter by Role
      </Typography>
      <div style={{display: 'flex', justifyContent: all.length < 4 ? 'center' : 'start', overflowY: 'scroll'}}>
        <ButtonGroup variant={"outlined"}>
          {all.map((existingRole) => (
            <Button
              key={existingRole.name}
              size="small"
              disableElevation
              onClick={() => {dispatch(setActiveRoleAction(existingRole))}}
            >
              {active.name === existingRole.name
                ? <CheckCircle style={{ color: existingRole.color, marginRight: 5 }} />
                : <Brightness1 style={{ color: existingRole.color, marginRight: 5 }} />}
              {existingRole.name}
            </Button>
          ))}
          {active.name ? (
            <Button
              size="small"
              disableElevation
              onClick={() => {dispatch(setActiveRoleAction(RoleStruct))}}
            >
              <ClearOutlined />
              All
            </Button>
          ) : null}
        </ButtonGroup>
      </div>

    </>
  );
};

RoleFilter.defaultProps = {};

RoleFilter.propTypes = {};

export default RoleFilter;
