import { getCommitmentStruct } from '../../structs/commitments';
import {
  RESET_ACTIVE_COMMITMENT, RESET_COMPLETED_COMMITMENT,
  SET_ACTIVE_COMMITMENT, SET_COMPLETED_COMMITMENT
} from '../actions/activeCommitment';

export default function activeCommitmentReducer(
  state = {
    active: getCommitmentStruct(),
    completed: getCommitmentStruct()
  },
  action
) {
  switch (action.type) {
    case SET_ACTIVE_COMMITMENT:
      return {
        ...state,
        active: getCommitmentStruct(action.payload)
      };

    case RESET_ACTIVE_COMMITMENT:
      return {
        ...state,
        active: getCommitmentStruct()
      };

    case SET_COMPLETED_COMMITMENT:
      return {
        ...state,
        completed: getCommitmentStruct(action.payload)
      };

    case RESET_COMPLETED_COMMITMENT:
      return {
        ...state,
        completed: getCommitmentStruct()
      };

    default:
      return state;
  }
}
