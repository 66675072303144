import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import { Storage } from 'aws-amplify';
import { Typography } from '@material-ui/core';
import { getLearnItemImageStruct } from '../../../structs/learn';

export const style = () => ({
  input: {
    color: '#393939',
    flex: 1,
    width: '100%'
  },
  textField: {
    fontSize: 12,
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    width: '100%'
  },
  ImageHeaderContent: {
    fontSize: '1rem'
  },
  ImageHeaderRoot: {
    padding: '1rem',
    paddingBottom: 0
  },
  media: {
    margin: '10px 10px 10px',
    width: 300,
    height: 250,
    borderRadius: '4px',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)',
    position: 'relative',
    zIndex: 1000
  }
});

class LearnItemImage extends Component {
  state = {
    file: null,
    image: getLearnItemImageStruct(),
    error: '',
    edit: false,
    liveUrl: '',
    tempFileUrl: ''
  };

  componentDidMount() {
    this.hydrateState();
  }

  componentDidUpdate() {
    this.checkDeferredCommands();

    const { item } = this.props;
    const { liveUrl } = this.state;

    if (!liveUrl && item.url && item.url === item.content) {
      this.deferredCommands.push(async () => {
        Storage.configure({ level: 'private' });
        const downloadLink = await Storage.get(item.url);
        this.setState({ liveUrl: downloadLink });
      });
    }
  }

  // List of commands to run after render.
  deferredCommands = [];

  /*
   *
   * The Deferred Commands model is to allow us to run functions once the
   * state has been set.
   * This is useful when we want to persist data via a redux action, or run
   * multiple steps to record progress.
   *
   */
  checkDeferredCommands = () => {
    if (!this.deferredCommands.length) return;

    this.deferredCommands = this.deferredCommands.filter(command => {
      command();
      return false;
    });
  };

  hydrateState = () => {
    const { item } = this.props;
    const { image, edit } = this.state;

    if (!item.content && !edit) {
      this.setState({ edit: true });
    }

    if (JSON.stringify(item) !== JSON.stringify(image)) {
      this.setState({ image: getLearnItemImageStruct(item) });
    }
  };

  handleSubmit = () => {
    const { image, file } = this.state;
    const { onChange } = this.props;

    this.setState({ working: 'Uploading...' });

    Storage.put(file.name, file, {
      level: 'private',
      progressCallback: this.handleUploadProgress
    })
      .then(({ key }) => {
        this.deferredCommands.push(async () => {
          onChange({ ...image, url: key });
          Storage.configure({ level: 'private' });
          const downloadLink = await Storage.get(key);
          this.setState({ liveUrl: downloadLink, working: '' });
        });

        this.handleCancel();

        return key;
      })
      .catch(err => {
        this.setState({ error: err });
      });
  };

  handleDelete = () => {
    const { image } = this.state;
    const { onDelete } = this.props;

    Storage.remove(image.url, { level: 'private' })
      .then(() => onDelete(image))
      .catch(err => console.log(err));
  };

  handleChange = event => {
    const { image } = this.state;
    image[event.target.name] = event.target.value;
    this.setState({ image });
  };

  handleFile = e => {
    const input = e.target;
    const fileObject = input.files[0];
    // this.handleSendFileToS3(input.files[0]);
    const { name } = fileObject;
    const reader = new FileReader();
    reader.onload = () => {
      const { result } = reader;
      const { image } = this.state;
      image.content = name;
      this.setState({ image, tempFileUrl: result });
    };
    reader.readAsDataURL(fileObject);
    this.setState({ file: fileObject });
  };

  handleClick = () => {
    this.setState({ edit: true });
  };

  handleCancel = () => {
    this.setState({ edit: false });
  };

  handleS3Download = async () => {
    const { image } = this.state;
    Storage.configure({ level: 'private' });
    const downloadLink = await Storage.get(image.url);
    fetch(downloadLink)
      .then(response => {
        response
          .blob()
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = image.url;
            a.click();
            return null;
          })
          .catch(err => console.log(err));
        return null;
      })
      .catch(err => console.log(err));
  };

  handleUploadProgress = progress => {
    this.setState({
      working: `Uploading: ${(progress.loaded / 1000 / 1000).toFixed(
        2
      )} MB / ${(progress.total / 1000 / 1000).toFixed(2)} MB`
    });
  };

  render() {
    const { classes, item } = this.props;
    const {
      image,
      edit,
      working,
      filePath,
      error,
      liveUrl,
      tempFileUrl
    } = this.state;

    return (
      <React.Fragment>
        <ListItem button onClick={this.handleClick}>
          <Card
            elevation={0}
            style={{ borderLeft: '3px solid lightgray', marginBottom: 4 }}
          >
            {item.title ? (
              <CardHeader
                classes={{ root: classes.ImageHeaderRoot }}
                titleTypographyProps={{
                  classes: { h5: classes.ImageHeaderContent }
                }}
                title={item.title}
              />
            ) : null}
            {liveUrl ? (
              <CardMedia className={classes.media} image={liveUrl} />
            ) : null}
            <CardContent style={{ fontSize: '0.8rem', whiteSpace: 'pre-wrap' }}>
              {image.content}
            </CardContent>
          </Card>
        </ListItem>
        <Dialog open={edit}>
          <DialogContent>
            <Typography>{error}</Typography>
            <Typography>{filePath}</Typography>
            <TextField
              multiline
              id="title"
              name="title"
              value={image.title}
              placeholder="Image Title (optional)"
              margin="normal"
              className={classes.textField}
              InputProps={{
                className: classes.input
              }}
              onChange={this.handleChange}
            />
            <Card className={classes.textField}>
              {tempFileUrl ? (
                <CardMedia className={classes.media} image={tempFileUrl} />
              ) : null}
              <CardContent>
                <List>
                  <ListItem>
                    {working ? (
                      <Typography>{working}</Typography>
                    ) : (
                      <input
                        type="file"
                        id="path"
                        accept="image/*"
                        className={classes.textField}
                        onChange={this.handleFile}
                      />
                    )}
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ marginRight: 'auto' }}
              color="secondary"
              onClick={this.handleDelete}
            >
              Delete
            </Button>
            {item.url ? (
              <Button
                style={{ marginRight: 'auto' }}
                color="secondary"
                onClick={this.handleS3Download}
              >
                Download
              </Button>
            ) : null}

            <Button color="secondary" onClick={this.handleCancel}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={Boolean(working)}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

LearnItemImage.propTypes = {
  classes: PropTypes.any.isRequired,
  item: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default withStyles(style)(LearnItemImage);
