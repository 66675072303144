import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { applicationFullSyncAction } from '../../redux/actions/appSync';
import { toggleSyncCheckerAction } from '../../redux/actions/sync';

const SyncChecker = ({ opened, performFullSync, toggleSyncChecker }) => {
  const doPerformFullSync = () => {
    performFullSync();
    toggleSyncChecker(false);
  };

  if (opened)
    return (
      <Dialog open={opened}>
        <DialogTitle>Full Sync Needed</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Formigio automatically syncs your data to the Formigio Cloud. This
            is important is you would like to use the other Formigio apps on
            your phone or desktop.
            <br />
            <br />
            Looks like, this is the first time you have logged in.
            <br />
            <br />
            We need to do a first sync now to get things started.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={doPerformFullSync}
            variant="contained"
            color="primary"
          >
            Ok, let's do it
          </Button>
        </DialogActions>
      </Dialog>
    );

  return <React.Fragment />;
};

SyncChecker.propTypes = {
  opened: PropTypes.bool.isRequired,
  performFullSync: PropTypes.any.isRequired,
  toggleSyncChecker: PropTypes.any.isRequired
};

const mapStateToProps = state => ({
  opened: state.syncChecker.opened
});

const mapDispatchToProps = dispatch => ({
  performFullSync: () => dispatch(applicationFullSyncAction()),
  toggleSyncChecker: () => dispatch(toggleSyncCheckerAction(false))
});

export default connect(mapStateToProps, mapDispatchToProps)(SyncChecker);
