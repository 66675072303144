import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CalendarIcon from '@material-ui/icons/DateRange';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import { convertPickerDateToDate, formatDateForPicker } from '../../utils/date';

export const style = () => ({
  calendarDayLabel: {
    display: 'inline-block'
  },
  calendarDayMonth: {
    color: 'gray',
    fontSize: '.8em',
    display: 'block'
  },
  calendarDayDay: {
    fontSize: '1.25em',
    display: 'block'
  }
});

class CalendarCarousel extends Component {
  state = {
    dates: [],
    custom: false
  };

  componentDidMount() {
    this.populateDates();
  }

  componentDidUpdate() {
    this.populateDates();
  }

  selectedDate = new Date();

  customDateRef;

  populateDates = () => {
    const { dates } = this.state;
    const { selectedDate } = this.props;

    let rangeSize = 3;

    if (window.innerWidth <= 446) {
      rangeSize = 0;
    } else if (window.innerWidth <= 574) {
      rangeSize = 1;
    } else if (window.innerWidth <= 750) {
      rangeSize = 2;
    }

    if (
      dates.length &&
      this.selectedDate.toDateString() === selectedDate.toDateString()
    )
      return;

    this.selectedDate = new Date(selectedDate.toString());

    // const startDate = new Date();
    const endDate = new Date(selectedDate.toString());
    const pointerDate = new Date(selectedDate.toString());
    const dateRange = [];

    pointerDate.setDate(selectedDate.getDate() - rangeSize);
    endDate.setDate(selectedDate.getDate() + rangeSize);

    dateRange.push(new Date(pointerDate.toString()));

    while (pointerDate.toDateString() !== endDate.toDateString()) {
      pointerDate.setDate(pointerDate.getDate() + 1);
      dateRange.push(new Date(pointerDate.toString()));
    }

    this.setState({ dates: dateRange });
  };

  handleSetDate = date => () => {
    const { onSelect } = this.props;
    onSelect(date);
  };

  handleBack = () => {
    const { onSelect, selectedDate } = this.props;
    selectedDate.setDate(selectedDate.getDate() - 1);
    onSelect(selectedDate);
  };

  handleForward = () => {
    const { onSelect, selectedDate } = this.props;
    selectedDate.setDate(selectedDate.getDate() + 1);
    onSelect(selectedDate);
  };

  handleCustomDate = () => {
    const { onSelect } = this.props;
    this.setState({ custom: false });
    onSelect(convertPickerDateToDate(this.customDateRef.value));
  };

  handleShowCustom = () => {
    this.setState({ custom: true });
  };

  render() {
    const { dates, custom } = this.state;
    const { selectedDate, classes } = this.props;

    return (
      <div>
        <div>
          <IconButton onClick={this.handleShowCustom}>
            <CalendarIcon />
          </IconButton>
          <Button onClick={this.handleBack}>{'<'}</Button>
          {dates.map(date => (
            <Button
              key={date.toDateString()}
              size="small"
              variant={
                selectedDate.toDateString() === date.toDateString()
                  ? 'outlined'
                  : 'text'
              }
              onClick={this.handleSetDate(date)}
              classes={{ label: classes.calendarDayLabel }}
            >
              <span className={classes.calendarDayMonth}>
                {new Intl.DateTimeFormat('en-US', {
                  month: 'short'
                }).format(date)}
              </span>
              <span className={classes.calendarDayDay}>
                {new Intl.DateTimeFormat('en-US', {
                  day: 'numeric'
                }).format(date)}
              </span>
            </Button>
          ))}
          <Button onClick={this.handleForward}>{'>'}</Button>
        </div>
        <Dialog open={custom}>
          <DialogContent>
            <TextField
              type="date"
              label="Choose Another Date"
              defaultValue={formatDateForPicker(selectedDate)}
              inputRef={ref => {
                this.customDateRef = ref;
              }}
              InputLabelProps={{ shrink: true }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCustomDate}>Go</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

CalendarCarousel.propTypes = {
  classes: PropTypes.any.isRequired,
  selectedDate: PropTypes.any.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default withStyles(style)(CalendarCarousel);
