export const SET_SYNC_VERSION = 'SET_SYNC_VERSION';
export const SET_LAST_SYNC = 'SET_LAST_SYNC';
export const LOAD_SYNC_VERSION_FROM_DISK = 'LOAD_SYNC_VERSION_FROM_DISK';
export const SYNC_VERSION_UPDATE_REQUEST_ACTION = 'SYNC_VERSION_UPDATE_REQUEST';
export const SYNC_VERSION_UPDATE_SUCCESS_ACTION = 'SYNC_VERSION_UPDATE_SUCCESS';
export const LAST_SYNC_UPDATE_REQUEST_ACTION = 'LAST_SYNC_UPDATE_REQUEST';
export const LAST_SYNC_UPDATE_SUCCESS_ACTION = 'LAST_SYNC_UPDATE_SUCCESS';
export const SYNC_DATA_LOAD_REQUEST_ACTION = 'SYNC_DATA_LOAD_REQUEST';
export const SYNC_DATA_LOAD_SUCCESS_ACTION = 'SYNC_DATA_LOAD_SUCCESS';
export const TOGGLE_SYNC_CHECKER_ACTION = 'TOGGLE_SYNC_CHECKER';
export const OFFER_SYNC_ACTION = 'OFFER_SYNC';

export const setSyncVersionAction = version => ({
  type: SYNC_VERSION_UPDATE_REQUEST_ACTION,
  payload: version
});

export const setLastSyncAction = datetime => ({
  type: LAST_SYNC_UPDATE_REQUEST_ACTION,
  payload: datetime
});

export const loadSyncVersionFromDiskAction = metadata => ({
  type: SYNC_DATA_LOAD_REQUEST_ACTION,
  payload: metadata
});

export const toggleSyncCheckerAction = payload => ({
  type: TOGGLE_SYNC_CHECKER_ACTION,
  payload
});

export const offerSyncAction = () => ({
  type: OFFER_SYNC_ACTION
});
