import React, { Component } from 'react';
import { connect } from 'react-redux';
import DateHelper from '../../components/Commitment/DateHelper';

export class DateHelperContainer extends Component {
    render() {
        return <DateHelper />;
    }
}

const mapStateToProps = state => ({
    commitmentIndex: state.commitmentIndex
});

export default connect(mapStateToProps)(DateHelper);
