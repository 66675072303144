import React, { Component } from 'react';
import { connect } from 'react-redux';
import ListDetail from '../components/Checklists/Detail';

export class ListsPageContainer extends Component {
  render() {
    return <ListDetail />;
  }
}

const mapStateToProps = state => ({
  checklists: state.checklists
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ListDetail);
