import { getTrackerStruct } from '../../structs/trackers';

// Tracker Actions
export const ADD_LOCAL_TRACKER = 'ADD_LOCAL_TRACKER';
export const SAVE_LOCAL_TRACKER = 'SAVE_LOCAL_TRACKER';
export const UPSERT_LOCAL_TRACKER = 'UPSERT_LOCAL_TRACKER';
export const REMOVE_LOCAL_TRACKER = 'REMOVE_LOCAL_TRACKER';
export const SET_LOCAL_TRACKERS = 'SET_LOCAL_TRACKERS';
export const UPDATE_TRACKER_INDEX = 'UPDATE_TRACKER_INDEX';

// Saga Actions
export const TRACKER_FETCH_REQUEST_ACTION = 'TRACKER_FETCH_REQUEST';
export const TRACKER_FETCH_SUCCESS_ACTION = 'TRACKER_FETCH_SUCCESS';
export const TRACKER_SEARCH_REQUEST_ACTION = 'TRACKER_SEARCH_REQUEST';
export const TRACKER_CREATE_REQUEST_ACTION = 'TRACKER_CREATE_REQUEST';
export const TRACKER_CREATE_SUCCESS_ACTION = 'TRACKER_CREATE_SUCCESS';
export const TRACKER_UPDATE_REQUEST_ACTION = 'TRACKER_UPDATE_REQUEST';
export const TRACKER_UPDATE_SUCCESS_ACTION = 'TRACKER_UPDATE_SUCCESS';
export const TRACKER_UPDATE_FAILURE_ACTION = 'TRACKER_UPDATE_FAILURE';
export const TRACKER_DELETE_REQUEST_ACTION = 'TRACKER_DELETE_REQUEST';
export const TRACKER_DELETE_SUCCESS_ACTION = 'TRACKER_DELETE_SUCCESS';
export const TRACKER_CREATE_FROM_SCAFFOLD_REQUEST_ACTION = 'TRACKER_CREATE_FROM_SCAFFOLD_REQUEST';
export const TRACKER_CREATE_FROM_SCAFFOLD_SUCCESS_ACTION = 'TRACKER_CREATE_FROM_SCAFFOLD_SUCCESS';
export const TRACKER_COPY_REQUEST_ACTION = 'TRACKER_COPY_REQUEST';
export const TRACKER_COPY_SUCCESS_ACTION = 'TRACKER_COPY_SUCCESS';
export const TRACKER_COPY_FAILURE_ACTION = 'TRACKER_COPY_FAILURE';
export const TRACKER_MIGRATE_REQUEST_ACTION = 'TRACKER_MIGRATE_REQUEST';
export const TRACKER_MIGRATE_SUCCESS_ACTION = 'TRACKER_MIGRATE_SUCCESS';
export const TRACKER_MIGRATE_FAILURE_ACTION = 'TRACKER_MIGRATE_FAILURE';
export const TRACKER_DATA_LOAD_REQUEST_ACTION = 'TRACKER_DATA_LOAD_REQUEST';
export const TRACKER_DATA_LOAD_SUCCESS_ACTION = 'TRACKER_DATA_LOAD_SUCCESS';
export const TRACKER_FULL_SYNC_REQUEST_ACTION = 'TRACKER_FULL_SYNC_REQUEST';
export const TRACKER_FULL_SYNC_SUCCESS_ACTION = 'TRACKER_FULL_SYNC_SUCCESS';
export const TRACKER_QUICK_SYNC_REQUEST_ACTION = 'TRACKER_QUICK_SYNC_REQUEST';
export const TRACKER_QUICK_SYNC_SUCCESS_ACTION = 'TRACKER_QUICK_SYNC_SUCCESS';

export const fetchTrackerAction = tracker => ({
  type: TRACKER_FETCH_REQUEST_ACTION,
  payload: getTrackerStruct(tracker)
});

export const addTrackerAction = tracker => ({
  type: TRACKER_CREATE_REQUEST_ACTION,
  payload: getTrackerStruct(tracker)
});

export const setLocalTrackersAction = trackers => ({
  type: TRACKER_DATA_LOAD_REQUEST_ACTION,
  payload: trackers
});

export const saveTrackerAction = tracker => ({
  type: TRACKER_UPDATE_REQUEST_ACTION,
  payload: getTrackerStruct(tracker)
});

export const upsertLocalTrackerAction = tracker => ({
  type: UPSERT_LOCAL_TRACKER,
  payload: getTrackerStruct(tracker)
});

export const deleteTrackerAction = tracker => ({
  type: TRACKER_DELETE_REQUEST_ACTION,
  payload: tracker
});

export const copyTrackerAction = tracker => ({
  type: TRACKER_COPY_REQUEST_ACTION,
  payload: tracker
});

export const updateTrackerIndexAction = tracker => ({
  type: UPDATE_TRACKER_INDEX,
  payload: tracker
});

export const searchArchiveAction = term => ({
  type: TRACKER_SEARCH_REQUEST_ACTION,
  payload: term
});

export const migrateTrackerAction = tracker => ({
  type: TRACKER_MIGRATE_REQUEST_ACTION,
  payload: tracker
});

export const createTrackerFromScaffoldAction = payload => ({
  type: TRACKER_CREATE_FROM_SCAFFOLD_REQUEST_ACTION,
  payload
});
