import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import red from '@material-ui/core/colors/red';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Style from './Style';
import { USER_STATUS_LOGGED_OUT } from '../../structs/user';
import ROUTES from '../../constants/routes.json';
import PaymentForm from './PaymentForm';
import {
  USER_MEMBERSHIP_STATUS_ACTIVE, USER_MEMBERSHIP_STATUS_CANCELLED,
  USER_MEMBERSHIP_STATUS_LAPSED
} from '../../models/user';
import { getCancelDate } from '../../structs/stripe';
import {useHistory} from "react-router";
import TextField from "@material-ui/core/TextField";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {LockOpen} from "@material-ui/icons";
import {SUBSCRIPTION_CODE_PUBLIC} from "../../structs/subscription";

const Payment = (
  {
    user,
    paymentKey,
    fetchSubscriptions,
    checkSubscription,
    membershipStatus,
    subscriptions,
    submitPayment,
    saveSubscription,
    cancelSubscription,
    changePlan,
    classes
  }
) => {
  const history = useHistory();
  const [code, setCode] = useState('');
  const [unlockCode, setUnlockCode] = useState('');
  const [stripePromise, setStripePromise] = useState(null);
  const [paymentError, setPaymentError] = useState('');
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [activeSubscription, setActiveSubscription] = useState(user.activeSubscription);
  const { activeSubscription: activeSubscriptionProp, memberStatus } = user;
  const { subscriptionId: currentSubscriptionId, stripeSubscription } = membershipStatus;

  useEffect(() => {
    fetchSubscriptions();
    checkSubscription();
  }, [fetchSubscriptions, checkSubscription]);

  useEffect(() => {
    fetchSubscriptions(code);
  }, [code, fetchSubscriptions]);

  useEffect(() => {
    if (paymentKey)
      setStripePromise(loadStripe(paymentKey))
  }, [paymentKey]);

  useEffect(() => {
    if (activeSubscriptionProp)
      setActiveSubscription(activeSubscriptionProp);
  }, [activeSubscriptionProp]);

  useEffect(() => {
    if (user.status === USER_STATUS_LOGGED_OUT) {
      history.replace(ROUTES.LOGIN);
    }
  }, [user.status, history])

  if (!activeSubscriptionProp)
    return <React.Fragment/>;

  const currentlyFree = !activeSubscriptionProp.price;
  const chosenFree = !activeSubscription.price;

  const paymentNeeded =
    ((memberStatus.status === USER_MEMBERSHIP_STATUS_LAPSED || memberStatus.status === USER_MEMBERSHIP_STATUS_CANCELLED ) &&
      !chosenFree) ||
    (currentlyFree && !chosenFree);

  const handleClick = subscriptionId => () => {
    const activeSubscription = subscriptions.find(subscription => subscription.id === subscriptionId);
    setActiveSubscription(activeSubscription);
  };

  const handleCodeSubmit = () => {
    setCode(unlockCode);
    setUnlockCode('');
  };

  const handleSave = () => {
    saveSubscription(activeSubscription);
  };

  const handlePaymentSubmit = id => {
    // Pass the payment method id for processing the subscription
    submitPayment({
      user: {
        ...user,
        activeSubscription
      },
      id
    });
  };

  const handleCancel = () => {
    const { stripePlanId } = activeSubscription;
    cancelSubscription(stripePlanId);
    setCancelDialogOpen(false);
  };

  const handlePlanChange = () => {
    const { stripePlanId } = activeSubscription;
    changePlan(stripePlanId);
  };

  const handlePaymentError = paymentError => {
    setPaymentError(paymentError);
  };

  const handleContinue = () => {
    history.replace(ROUTES.HOME);
  };
  
  const handleCloseCancelDialog = () => {
    setCancelDialogOpen(false);
  };

  const handleOpenCancelDialog = () => {
    setCancelDialogOpen(true);
  };

  const getAction = () => {

    if (currentSubscriptionId === activeSubscription.id) return 'continue';

    // If the price is the same and it's a different subscription we save
    if (
      activeSubscription.price ===
      activeSubscriptionProp.price || (activeSubscription.price === 0 && memberStatus.status === USER_MEMBERSHIP_STATUS_LAPSED)
    )
      return 'save';

    // If free subscription then we let them continue
    if (
      !activeSubscription.price &&
      !activeSubscriptionProp.price
    )
      return 'start';

    // If the selected subscription is more than the current, we need to upgrade
    if (
      activeSubscriptionProp.price < activeSubscription.price
    )
      return 'upgrade';

    // If the current subscription is paid, and chosen is free, then we cancel
    if (
      activeSubscriptionProp.price > 0 &&
      !activeSubscription.price
    )
      return 'cancel';
  
    // If the selected subscription is less than the current, we need to downgrade
    if (
      activeSubscriptionProp.price > activeSubscription.price
    )
      return 'downgrade';
  };

  const getActionButton = () => {
    const action = getAction();

    if (action === 'save')
      return (
        <Button
          variant="contained"
          fullWidth
          color="primary"
          onClick={handleSave}
        >
          Save
        </Button>
      );

    if (action === 'continue')
      return (
        <Button
          variant="contained"
          fullWidth
          color="primary"
          onClick={handleContinue}
        >
          Done, take me home
        </Button>
      );

    if (action === 'upgrade')
      return (
        <Button
          variant="contained"
          fullWidth
          color="primary"
          onClick={handlePlanChange}
        >
          {`Upgrade to ${activeSubscription.name} membership`}
        </Button>
      );

    if (action === 'downgrade')
      return (
        <Button
          variant="contained"
          fullWidth
          color="primary"
          onClick={handlePlanChange}
        >
          {`Downgrade to a ${activeSubscription.name} membership`}
        </Button>
      );

    if (action === 'cancel')
      return (
        <Button
          variant="contained"
          fullWidth
          onClick={handleOpenCancelDialog}
        >
          {
            stripeSubscription.stripeCancelDate
              ? 'Downgrade to free membership'
              : 'Cancel your paid membership'
          }
        </Button>
      );
  };

  return (
    <Grid
      container
      item
      data-tid="container"
      xs={12}
      sm={8}
      md={6}
      direction="column"
    >
      <Typography variant="h6" color="primary" align="left">
        Your membership details
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={`Membership: ${activeSubscriptionProp.name}`}
            secondary={`Membership Status: ${memberStatus.label}`}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              memberStatus.endDate !== 'Forever'
                ? (memberStatus.status === USER_MEMBERSHIP_STATUS_ACTIVE
                    ? `Next Payment: ${memberStatus.endDate}`
                    : `Subscribed Until: ${memberStatus.endDate}`
                )
                : ''
            }
            secondary={
              memberStatus.endDate !== 'Forever' &&
              stripeSubscription.stripeCancelDate
                ? `Membership Cancelled: ${getCancelDate(stripeSubscription)}`
                : ''
            }
          />
        </ListItem>
      </List>
      {stripeSubscription.cancelAtPeriodEnd
      && memberStatus.status === USER_MEMBERSHIP_STATUS_ACTIVE ? (
        <React.Fragment>
          <Typography variant="h6" color="primary" align="left">
            Your subscription has been cancelled.
          </Typography>
          <Typography align="left">
            You will have the same membership access until{' '}
            {memberStatus.endDate}. After that you can decide to revert to
            a basic membership.
          </Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <List subheader={<ListSubheader>Choose a new membership level</ListSubheader>}>
            {subscriptions.map(
              ({id, label, description, active, code}) => (
                <ListItem
                  button
                  onClick={handleClick(id)}
                  disabled={!active}
                  key={id}
                  selected={activeSubscription.id === id}
                >
                  <ListItemIcon>
                    {activeSubscription.id === id
                      ? <RadioButtonCheckedIcon color="secondary"/>
                      : <RadioButtonUncheckedIcon/>}
                  </ListItemIcon>
                  <ListItemText primary={label} secondary={`${code !== SUBSCRIPTION_CODE_PUBLIC ? (`Code: ${code} - `) : ''}${description}`} />
                </ListItem>
              )
            )}
            <ListItem>
              <ListItemIcon>
                <LockOpen/>
              </ListItemIcon>
              <ListItemText
                primary={
                  <TextField
                    placeholder="Enter Unlock Code"
                    fullWidth
                    onChange={event => setUnlockCode(event.target.value)}
                  />
                }
              />
              <ListItemSecondaryAction>
                {unlockCode
                  ? <Button
                      onClick={handleCodeSubmit}
                      size="small"
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </Button>
                  : null}
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </React.Fragment>
      )}
      {paymentNeeded ? (
        <div>
          <Typography variant="h6" color="primary" align="left">
            Enter your payment information to subscribe
          </Typography>
          <div className={classes.textLabel}>
            Subscriptions are securely processed through the Stripe Payments
            gateway
          </div>
          <div className={classes.textLabel}>
            <i>
              Formigio doesn&apos;t transmit or store any payment information
            </i>
          </div>
          <div>
            <Typography style={{ color: red[500] }}>
              {paymentError}
            </Typography>
            {stripePromise ? (
              <Elements stripe={stripePromise}>
                <PaymentForm
                  onSubmit={handlePaymentSubmit}
                  onError={handlePaymentError}
                />
              </Elements>
            ) : null}
          </div>
        </div>
      ) : (
        getActionButton()
      )}{' '}
      <Dialog
        open={cancelDialogOpen}
        onClose={handleCloseCancelDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {activeSubscription.level === 'basic' ? 'Downgrade Subscription' : 'Change Subscription'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to move to the {activeSubscription.name} membership?
          </DialogContentText>
          <DialogContentText>
            Just want to make sure this is what you want.{' '}
            {activeSubscription.level === 'basic'
              ? 'If you downgrade you will be limited to just 3 active trackers.'
              : 'Changing Subscriptions may affect your access to trackers and other resources.'
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancel}
            color="secondary"
            variant="outlined"
          >
            Yes, I understand
          </Button>
          <Button
            onClick={handleCloseCancelDialog}
            color="primary"
            variant="contained"
            autoFocus
          >
            No, never mind
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

Payment.propTypes = {
  cancelSubscription: PropTypes.func.isRequired,
  submitPayment: PropTypes.func.isRequired,
  fetchSubscriptions: PropTypes.func.isRequired,
  checkSubscription: PropTypes.func.isRequired,
  changePlan: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  membershipStatus: PropTypes.object.isRequired,
  paymentKey: PropTypes.string.isRequired,
  subscriptions: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(Style)(Payment);
