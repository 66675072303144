import React from 'react';
import PropTypes from 'prop-types';
import {MenuItem} from "@material-ui/core";
import {TRACKER_ACTION_SHARE} from "../../../structs/trackers";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {FolderShared} from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";

const ShareMenuItem = ({ onDialog }) => {
  
  const handleShare = () => {
    onDialog(TRACKER_ACTION_SHARE)
  };

  return (
    <MenuItem
      variant="text"
      color="secondary"
      onClick={handleShare}
    >
      <ListItemIcon>
        <FolderShared fontSize={"small"}/>
      </ListItemIcon>
      <ListItemText>Share</ListItemText>
    </MenuItem>
  )
};

ShareMenuItem.defaultProps = {};

ShareMenuItem.propTypes = {
  tracker: PropTypes.object.isRequired
};

export default ShareMenuItem;
