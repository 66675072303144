import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ROUTES from '../../constants/routes.json';
import Style from './Style';
import {
  USER_STATUS_LOGGED_IN,
  USER_STATUS_CONFIRMED
} from '../../structs/user';
import SignUp from './SignUp';

class Register extends Component {
  componentDidMount() {
    this.updateUser();
  }

  componentDidUpdate() {
    this.updateUser();
  }

  updateUser = () => {
    const { user, history } = this.props;

    if (
      user.status === USER_STATUS_LOGGED_IN ||
      user.status === USER_STATUS_CONFIRMED
    ) {
      history.replace(ROUTES.PAYMENT);
    }
  };

  render() {
    const { signUp, user, history } = this.props;

    return (
      <Grid item data-tid="container" xs={8} sm={6} md={4}>
        {!user.registrationDate ? (
          <SignUp user={user} signUp={signUp} history={history} />
        ) : null}
      </Grid>
    );
  }
}

Register.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  signUp: PropTypes.func.isRequired
};

export default withStyles(Style)(Register);
