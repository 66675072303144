import {
  SET_SYNC_VERSION,
  LOAD_SYNC_VERSION_FROM_DISK,
  SET_LAST_SYNC
} from '../actions/sync';
import { SyncMetaDataStruct } from '../../structs/sync';

export default function syncReducer(
  oldstate = Object.assign({}, SyncMetaDataStruct),
  action
) {
  switch (action.type) {
    case SET_SYNC_VERSION:
      return action.payload;

    case SET_LAST_SYNC:
      return action.payload;

    case LOAD_SYNC_VERSION_FROM_DISK:
      return Object.assign({}, SyncMetaDataStruct, action.payload);

    default:
      return oldstate;
  }
}
