import moment from 'moment';

import { UPDATE_CHECKLIST_INDEX } from '../actions/checklists';
import { ChecklistIndexStruct } from '../../structs/indexers';

export default function checklistIndexReducer(
  oldstate = Object.assign({}, ChecklistIndexStruct),
  action
) {
  switch (action.type) {
    case UPDATE_CHECKLIST_INDEX:
      return Object.assign({}, action.payload, {
        updatedAt: moment().format()
      });

    default:
      return oldstate;
  }
}
