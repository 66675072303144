import React, { Component } from 'react';
import { connect } from 'react-redux';
import Detail from '../../components/Tracker/Milestone/Detail';
import { addJournalAction } from '../../redux/actions/journal';
import {
  requestActiveTrackerAction,
  resetActiveTrackerAction,
  setActiveTrackerAction
} from '../../redux/actions/activeTracker';
import {
  requestActiveMilestoneAction,
  resetActiveMilestoneAction,
  saveActiveMilestoneAction
} from '../../redux/actions/activeMilestone';
import { saveTrackerAction } from '../../redux/actions/trackers';
import { resetActiveCommitmentAction } from '../../redux/actions/activeCommitment';
import { updateMilestoneAction } from '../../redux/actions/milestones';

export class MilestoneDetailContainer extends Component {
  render() {
    return <Detail />;
  }
}

const mapStateToProps = state => ({
  activeTrackerDoc: state.activeTracker,
  activeMilestoneDoc: state.activeMilestone,
  activeMilestones: state.activeMilestones
});

const mapDispatchToProps = dispatch => ({
  setActiveTracker: tracker => {
    dispatch(setActiveTrackerAction(tracker));
  },
  requestActiveTracker: trackerId => {
    dispatch(requestActiveTrackerAction(trackerId));
  },
  resetActiveTracker: () => {
    dispatch(resetActiveTrackerAction());
  },
  resetActiveCommitment: () => {
    dispatch(resetActiveCommitmentAction());
  },
  resetActiveMilestone: () => {
    dispatch(resetActiveMilestoneAction());
  },
  requestActiveMilestone: milestoneId => {
    dispatch(requestActiveMilestoneAction(milestoneId));
  },
  addJournalEntry: item => {
    dispatch(addJournalAction(item));
  },
  saveActiveMilestone: milestone => {
    dispatch(saveActiveMilestoneAction(milestone));
  },
  saveTracker: tracker => {
    dispatch(saveTrackerAction(tracker));
  },
  updateMilestone: milestone => {
    dispatch(updateMilestoneAction(milestone));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
