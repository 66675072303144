import React, { Component } from 'react';
import { connect } from 'react-redux';
import Pages from '../../components/Learn/Pages';

export class LearnPagesContainer extends Component {
  render() {
    return <Pages />;
  }
}

const mapStateToProps = state => ({
  learnPages: state.learnPages
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Pages);
