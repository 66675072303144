
export const TRACKER_ACTIVITY_TYPE_TRACKER = 'tracker';
export const TRACKER_ACTIVITY_TYPE_COMMITMENT = 'commitment';
export const TRACKER_ACTIVITY_TYPE_MILESTONE = 'milestone';
export const TRACKER_ACTIVITY_TYPE_JOURNAL = 'journal';
export const TRACKER_ACTIVITY_TYPE_SHARE = 'share';

export const TrackerActivityTypeMap = {
  [TRACKER_ACTIVITY_TYPE_TRACKER]: 'Tracker',
  [TRACKER_ACTIVITY_TYPE_COMMITMENT]: 'Commitment',
  [TRACKER_ACTIVITY_TYPE_MILESTONE]: 'Milestone',
  [TRACKER_ACTIVITY_TYPE_JOURNAL]: 'Journal',
  [TRACKER_ACTIVITY_TYPE_SHARE]: 'Tracker Share',
}

export const TrackerActivity = {
  schemaVersion: '1.0.0',
  trackerId: '',
  createdAt: '',
  userId: '',
  byUser: '',
  summary: '',
  entityType: '',
  entityId: '',
};

export const getTrackerActivityStruct = (trackerActivity = TrackerActivity) =>
  Object.assign({}, TrackerActivity, trackerActivity);
