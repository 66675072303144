import React, { Component } from 'react';
import { connect } from 'react-redux';
import DeviceSync from '../../components/Settings/DeviceSync';
import { loadUserFromDiskAction } from '../../redux/actions/user';
import {
  setLocalTrackersAction
} from '../../redux/actions/trackers';
import { setLocalJournalsAction } from '../../redux/actions/journal';
import { setLocalCommitmentsAction } from '../../redux/actions/commitments';
import { setSyncVersionAction } from '../../redux/actions/sync';
import { loadTourFromDiskAction } from '../../redux/actions/tour';
import { loadTimersFromDiskAction } from '../../redux/actions/timers';
import { loadChecklistsFromDiskAction } from '../../redux/actions/checklists';
import { setInternetStatusAction } from '../../redux/actions/notifications';
import {processRealTimeChangeLog, syncChangeLogAction} from '../../redux/actions/changeLogs';
import { syncAllSyncEventsAction } from '../../redux/actions/syncEvents';
import { deviceSyncWakeUpAction } from '../../redux/actions/deviceSync';

export class BackupSyncContainer extends Component {
  render() {
    return <DeviceSync />;
  }
}

const mapStateToProps = state => ({
  backupProfile: state.backupProfile,
  syncVersion: state.syncVersion,
  internetStatus: state.internetStatus,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  loadUser: user => {
    dispatch(loadUserFromDiskAction(user));
  },
  loadTour: tour => {
    dispatch(loadTourFromDiskAction(tour));
  },
  loadTrackers: trackers => {
    dispatch(setLocalTrackersAction(trackers));
  },
  loadJournal: journal => {
    dispatch(setLocalJournalsAction(journal));
  },
  loadCommitments: commitments => {
    dispatch(setLocalCommitmentsAction(commitments));
  },
  loadTimers: timers => {
    dispatch(loadTimersFromDiskAction(timers));
  },
  loadChecklists: lists => {
    dispatch(loadChecklistsFromDiskAction(lists));
  },
  setSyncVersion: version => {
    dispatch(setSyncVersionAction(version));
  },
  setInternetStatus: status => {
    dispatch(setInternetStatusAction(status));
  },
  syncChangeLogs: () => {
    dispatch(syncChangeLogAction());
  },
  syncEvents: () => {
    dispatch(syncAllSyncEventsAction());
  },
  handleWakeUp: () => {
    dispatch(deviceSyncWakeUpAction());
  },
  processChangeLogAction: (item) => {
    dispatch(processRealTimeChangeLog(item));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSync);
