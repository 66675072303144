import moment from 'moment';

import {
  ADD_CHECKLIST,
  DELETE_CHECKLIST,
  LOAD_CHECKLISTS_FROM_DISK,
  SAVE_CHECKLIST,
  UPSERT_LOCAL_CHECKLIST
} from '../actions/checklists';
import { getChecklistStruct } from '../../structs/checklists';

export default function checklistReducer(
  oldstate = getChecklistStruct(),
  action
) {
  let state;
  let newList = getChecklistStruct();

  switch (action.type) {
    case ADD_CHECKLIST:
      newList = getChecklistStruct(action.payload);
      newList.updatedAt = moment().format();
      newList.createdAt = moment().format();
      return [...oldstate, newList];

    case LOAD_CHECKLISTS_FROM_DISK:
      return action.payload;

    case DELETE_CHECKLIST:
      state = oldstate.filter(list => list.id !== action.payload.id);
      return state;

    case SAVE_CHECKLIST:
      state = oldstate.map(list => {
        if (action.payload.id === list.id) {
          newList = getChecklistStruct(action.payload);
          newList.updatedAt = moment().format();
          return newList;
        }
        return list;
      });
      return state;

    case UPSERT_LOCAL_CHECKLIST:
      if (!action.payload) return oldstate;
      if (
        oldstate.filter(checklist => action.payload.id === checklist.id).length
      )
        return oldstate.map(checklist => {
          if (action.payload.id === checklist.id) {
            return action.payload;
          }
          return checklist;
        });

      return [...oldstate, action.payload];

    default:
      return oldstate;
  }
}
