export const CommitmentIndexStruct = {
  all: [],
  active: [],
  byDate: {},
  byRole: {},
  byTray: {},
  updatedAt: ''
};

export const CommitmentIndexByDateStruct = {
  commitments: [],
  committedTime: 0
};

export const TrackerIndexStruct = {
  today: [],
  byId: {},
  all: [],
  activeByRole: {}
};

export const SearchIndexDataStruct = {
  all: []
};

export const SearchIndexStruct = {
  entity: {},
  text: '',
  context: '',
  type: '',
  id: ''
};

export const JournalIndexStruct = {
  byDate: {}
};

export const ChecklistIndexStruct = {
  all: [],
  byMilestone: {}
};

export const MilestoneIndexStruct = {
  byId: {}
}

export const getCommitmentIndexByDateStruct = (
  commitmentIndex = CommitmentIndexByDateStruct
) =>
  Object.assign({}, CommitmentIndexByDateStruct, commitmentIndex, {
    commitments: [...(commitmentIndex.commitments || [])]
  });

export const getSearchIndexStruct = (searchIndex = SearchIndexStruct) =>
  Object.assign({}, SearchIndexStruct, searchIndex);

export const getMilestoneIndexStruct = (milestoneIndex = MilestoneIndexStruct) =>
    Object.assign({}, MilestoneIndexStruct, milestoneIndex)
