import moment from 'moment';

export const StripeSubscriptionStruct = {
  schemaVersion: '1.0.0',
  stripeSubscriptionType: '',
  stripeSubscriptionId: 'none',
  cancelServiceDate: '',
  stripeCurrentPeriodEnd: 0,
  cancelAtPeriodEnd: false,
  stripeCancelDate: '',
  updatedAt: ''
};

export const getStripeSubscriptionStruct = (
  subscription = StripeSubscriptionStruct
) => Object.assign({}, StripeSubscriptionStruct, subscription);

export const getCancelDate = subscription => {
  if (!subscription.stripeCancelDate) {
    return '';
  }

  const cancelDate = new Date(
    parseInt(subscription.stripeCancelDate, 10) * 1000
  );
  return moment(cancelDate).calendar();
};

export default StripeSubscriptionStruct;
