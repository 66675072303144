import React, { Component } from 'react';
import { connect } from 'react-redux';
import ListsPage from '../components/Checklists/Page';
import { addChecklistAction } from '../redux/actions/checklists';

export class ListsPageContainer extends Component {
  render() {
    return <ListsPage />;
  }
}

const mapStateToProps = state => ({
  checklists: state.checklists
});

const mapDispatchToProps = dispatch => ({
  addList: list => {
    dispatch(addChecklistAction(list));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ListsPage);
