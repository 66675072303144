import { getSyncEventStruct } from '../../structs/sync';

// SyncEvent Actions
export const ADD_SYNC_EVENT = 'ADD_SYNC_EVENT';
export const REMOVE_SYNC_EVENT = 'REMOVE_SYNC_EVENT';
export const LOAD_ALL_SYNC_EVENTS = 'LOAD_ALL_SYNC_EVENTS';

// Saga Actions
export const SYNC_EVENT_CREATE_REQUEST_ACTION = 'SYNC_EVENT_CREATE_REQUEST';
export const SYNC_EVENT_CREATE_SUCCESS_ACTION = 'SYNC_EVENT_CREATE_SUCCESS';
export const SYNC_EVENT_SYNC_REQUEST_ACTION = 'SYNC_EVENT_SYNC_REQUEST';
export const SYNC_EVENT_SYNC_SUCCESS_ACTION = 'SYNC_EVENT_SYNC_SUCCESS';
export const SYNC_EVENT_DATA_LOAD_REQUEST_ACTION =
  'SYNC_EVENT_DATA_LOAD_REQUEST';
export const SYNC_EVENT_DATA_LOAD_SUCCESS_ACTION =
  'SYNC_EVENT_DATA_LOAD_SUCCESS';

export const addSyncEventAction = syncEvent => ({
  type: SYNC_EVENT_CREATE_REQUEST_ACTION,
  payload: getSyncEventStruct(syncEvent)
});

export const removeSyncEventAction = syncEvent => ({
  type: REMOVE_SYNC_EVENT,
  payload: syncEvent
});

export const loadAllSyncEventsAction = syncEvents => ({
  type: SYNC_EVENT_DATA_LOAD_REQUEST_ACTION,
  payload: syncEvents
});

export const syncAllSyncEventsAction = () => ({
  type: SYNC_EVENT_SYNC_REQUEST_ACTION
});
