export const UserToursStruct = {
  schemaVersion: '1.0.0',
  tours: {},
  updatedAt: ''
};

export const UserTourStruct = {
  schemaVersion: '1.0.0',
  id: '',
  ownerApn: '',
  status: 'pending' // pending, complete
};

export const getUserToursStruct = (userTours = UserToursStruct) =>
  Object.assign({}, UserToursStruct, userTours, {
    tours: Object.assign({}, userTours.tours)
  });

export const getUserTourStruct = (userTour = {}) =>
  Object.assign({}, UserTourStruct, userTour);
