module.exports = () => ({
  container: {
    marginTop: 44,
    marginLeft: 75,
    width: '100%'
  },
  input: {
    color: '#393939'
  },
  textField: {
    fontSize: 12,
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal'
  },
  textLabel: {
    margin: 15,
    fontSize: '1rem',
    fontStyle: 'normal'
  }
});
