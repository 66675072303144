import {
  ADD_LEARN_PAGE,
  DELETE_LEARN_PAGE,
  LOAD_LEARN_PAGES_FROM_DISK,
  SAVE_LEARN_PAGE
} from '../actions/learnPages';

export default function learnPageReducer(oldstate = [], action) {
  let state;

  switch (action.type) {
    case ADD_LEARN_PAGE:
      return [...oldstate, action.payload];

    case LOAD_LEARN_PAGES_FROM_DISK:
      return action.payload;

    case DELETE_LEARN_PAGE:
      state = oldstate.filter(page => page.id !== action.payload);
      return state;

    case SAVE_LEARN_PAGE:
      state = oldstate.map(page => {
        if (action.payload.id === page.id) {
          return action.payload;
        }
        return page;
      });
      return state;

    default:
      return oldstate;
  }
}
