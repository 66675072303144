import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Style from './Style';
import { USER_STATUS_LOGGED_OUT, UserStruct } from '../../structs/user';
import ROUTES from '../../constants/routes.json';
import LogoutDialog from './LogoutDialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {getCancelDate} from '../../structs/stripe';
import {USER_MEMBERSHIP_STATUS_ACTIVE} from '../../models/user';

class MyAccount extends Component {
  state = {
    user: UserStruct
  };

  componentDidMount() {
    const { setUserCheck } = this.props;
    setUserCheck(false);
    this.updateUser();
  }

  componentDidUpdate() {
    this.updateUser();
  }

  updateUser = () => {
    const { appUser, history } = this.props;
    const { user } = this.state;

    if (appUser.status === USER_STATUS_LOGGED_OUT) {
      history.replace(ROUTES.LOGIN);
    }

    if (
      user.id === appUser.id &&
      user.email === appUser.email &&
      user.attributes.emailVerified === appUser.attributes.emailVerified
    )
      return;

    this.setState({
      user: {
        ...appUser,
        credentials: {
          ...appUser.credentials,
          username: appUser.attributes.username
        }
      }
    });
  };

  handleAttributeChange = name => event => {
    const { user } = this.state;
    user.attributes[name] = event.target.value;
    this.setState({ user });
  };

  handleCredentialsChange = name => event => {
    const { user } = this.state;
    user.credentials[name] = event.target.value;
    this.setState({ user });
  };

  handleUpdateInformation = () => {
    const { updateUser } = this.props;
    const { user } = this.state;

    updateUser(user);
  };

  handleConfirmEmail = () => {
    const { confirmEmail, showError } = this.props;
    const { user } = this.state;

    if (!user.credentials.verificationCode) {
      showError('You will need to enter the code from the email.');
    } else {
      confirmEmail({
        ...user,
        credentials: {
          ...user.credentials,
          username: user.attributes.username
        }
      });
    }
  };

  handleResendCode = () => {
    const { confirmEmail } = this.props;
    const { user } = this.state;

    confirmEmail({
      ...user,
      credentials: {
        ...user.credentials,
        username: user.attributes.username,
        verificationCode: ''
      }
    });
  };

  handleLogout = () => {
    const { signOut } = this.props;
    signOut();
  };

  render() {
    const { appUser, classes, history, membership } = this.props;
    const { user } = this.state;
    const { attributes, credentials, activeSubscription, memberStatus } = user;
    const { stripeSubscription } = membership;
    const { username, givenName, familyName, email } = attributes;
    const { verificationCode } = credentials;

    return (
      <Grid
        container
        item
        style={{ marginTop: '1em' }}
        direction="column"
        xs={12}
        sm={8}
        md={6}
      >
        <Grid row>
          <Grid>
            <Typography variant="h5" color="secondary">
              My Account
            </Typography>
            <Typography variant="h6" color="primary" align="left">
              Your membership details
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary={`Membership: ${activeSubscription.name}`}
                  secondary={`Membership Status: ${memberStatus.label}`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    memberStatus.endDate !== 'Forever'
                      ? (memberStatus.status === USER_MEMBERSHIP_STATUS_ACTIVE
                        ? `Next Payment: ${memberStatus.endDate}`
                        : `Subscribed Until: ${memberStatus.endDate}`
                      )
                      : ''
                  }
                  secondary={
                    memberStatus.endDate !== 'Forever' &&
                    stripeSubscription.stripeCancelDate
                      ? `Membership Cancelled: ${getCancelDate(stripeSubscription)}`
                      : ''
                  }
                />
              </ListItem>
              <ListItem button onClick={() => history.push(ROUTES.PAYMENT)}>
                <ListItemText primary={`CHANGE MEMBERSHIP`} />
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Grid row>
          <Grid>
            {attributes.emailVerified ? (
              <div>
                <Typography variant="h6" color="primary" align="left">
                  Account Information
                </Typography>
                <TextField
                  id="Username"
                  label="Username"
                  disabled
                  className={classes.textField}
                  InputProps={{
                    className: classes.input
                  }}
                  value={username}
                  onChange={this.handleAttributeChange('username')}
                  margin="normal"
                  fullWidth
                />
                <TextField
                  id="email"
                  label="Email"
                  className={classes.textField}
                  InputProps={{
                    className: classes.input
                  }}
                  value={email}
                  onChange={this.handleAttributeChange('email')}
                  margin="normal"
                  fullWidth
                />
                <TextField
                  id="firstName"
                  label="First Name"
                  className={classes.textField}
                  InputProps={{
                    className: classes.input
                  }}
                  value={givenName}
                  onChange={this.handleAttributeChange('givenName')}
                  margin="normal"
                  fullWidth
                />
                <TextField
                  id="lastName"
                  label="Last Name"
                  className={classes.textField}
                  InputProps={{
                    className: classes.input
                  }}
                  value={familyName}
                  onChange={this.handleAttributeChange('familyName')}
                  margin="normal"
                  fullWidth
                />
                <div style={{ marginTop: 20 }}>
                  <Fade in>
                    <Button
                      variant="contained"
                      fullWidth={true}
                      color="primary"
                      onClick={this.handleUpdateInformation}
                    >
                      Save
                    </Button>
                  </Fade>
                </div>
                <div style={{ marginTop: 20 }}>
                  <Fade in>
                    <div>
                      <LogoutDialog onConfirm={this.handleLogout} />
                    </div>
                  </Fade>
                </div>
              </div>
            ) : (
              <div>
                <div className={classes.textLabel}>
                  Looks like we need to verify your email address.
                </div>
                <div className={classes.textLabel}>
                  When you saved a new email address, you should have received a
                  code in your email.
                </div>
                <div>
                  <TextField
                    id="Username"
                    label="Username"
                    disabled
                    className={classes.textField}
                    InputProps={{
                      className: classes.input
                    }}
                    value={username}
                    onChange={this.handleAttributeChange('username')}
                    margin="normal"
                    fullWidth
                  />
                  <TextField
                    id="email"
                    label="Email"
                    disabled
                    className={classes.textField}
                    InputProps={{
                      className: classes.input
                    }}
                    value={email}
                    onChange={this.handleAttributeChange('email')}
                    margin="normal"
                    fullWidth
                  />
                  {appUser.attributes.email === attributes.email && (
                    <TextField
                      id="verificationCode"
                      label="Verification Code"
                      className={classes.textField}
                      InputProps={{
                        className: classes.input
                      }}
                      value={verificationCode}
                      onChange={this.handleCredentialsChange(
                        'verificationCode'
                      )}
                      margin="normal"
                      fullWidth
                    />
                  )}
                </div>
                {appUser.attributes.email !== attributes.email ? (
                  <div style={{ marginTop: 20 }}>
                    <Fade in>
                      <Button
                        variant="contained"
                        fullWidth={true}
                        color="primary"
                        onClick={this.handleUpdateInformation}
                      >
                        Update Email Address
                      </Button>
                    </Fade>
                  </div>
                ) : (
                  <div style={{ marginTop: 20 }}>
                    <Fade in>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        onClick={this.handleConfirmEmail}
                      >
                        Submit Code
                      </Button>
                    </Fade>
                  </div>
                )}
                <div style={{ marginTop: 20 }}>
                  <Fade in>
                    <Button
                      variant="outlined"
                      fullWidth
                      color="primary"
                      onClick={this.handleResendCode}
                    >
                      Resend Code
                    </Button>
                  </Fade>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

MyAccount.propTypes = {
  classes: PropTypes.any.isRequired,
  showError: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  setUserCheck: PropTypes.func.isRequired,
  confirmEmail: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  appUser: PropTypes.object.isRequired,
  membership: PropTypes.object.isRequired,
  history: PropTypes.any.isRequired
};

export default withRouter(withStyles(Style)(MyAccount));
