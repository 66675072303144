import {
  SAVE_LOCAL_JOURNAL,
  SET_LOCAL_JOURNALS,
  UPSERT_LOCAL_JOURNAL,
  REMOVE_LOCAL_JOURNAL, SET_JOURNAL_NEXT_TOKEN_ACTION
} from '../actions/journal';

export default function journalReducer(state = {items: [], nextToken: null}, action) {
  switch (action.type) {

    case SET_LOCAL_JOURNALS:
      return {
        ...state,
        items: action.payload
      };

    case SAVE_LOCAL_JOURNAL:
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        })
      };

    case UPSERT_LOCAL_JOURNAL:
      if (!action.payload) return state;
      if (state.items.filter(journal => action.payload.id === journal.id).length > 0)
        return {
          ...state,
          items: state.items.map(journal => {
            if (action.payload.id === journal.id) {
              return action.payload;
            }
            return journal;
          })
        };

      return {
        ...state,
        items: [action.payload, ...state.items]
      };

    case REMOVE_LOCAL_JOURNAL:
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload.id)
      };

    case SET_JOURNAL_NEXT_TOKEN_ACTION:
      return {
        ...state,
        nextToken: action.payload
      };

    default:
      return state;
  }
}
