import React, {useCallback, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import {addCommitmentTrayAction} from "../../redux/actions/commitmentTray";
import {Inbox, MoveToInbox} from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

const CommitmentTrayButton = ({ trayIds = [], onChange }) => {
  const dispatch = useDispatch();
  const allTrays = useSelector(store => store.trays);
  const [ open, setOpen ] = useState(false);
  const [ create, setCreate ] = useState(false);
  const [ name, setName ] = useState('');
  const [ description, setDescription ] = useState('');
  const [ trays, setTrays ] = useState((trayIds && trayIds.length) ? allTrays.filter(tray => trayIds.includes(tray.id)) : []);

  const clearForm = () => {
    // Clear tray name and description
    setName('');
    setDescription('');
  };

  const toggleTray = useCallback((tray) => {
    if (trays.find(t => tray.id === t.id)) {
      setTrays(trays.filter(t => tray.id !== t.id))
    } else {
      setTrays([...trays, tray]);
    }
  }, [trays]);

  const finalizeTrays = () => {
    onChange(trays.map(tray => tray.id));
    setOpen(false);
  };

  useEffect(() => {
    // Look for newly added tray and auto select it.
    if (name) {
      const newTray = allTrays.find(t => t.name === name)
      if (newTray) {
        toggleTray(newTray);
        clearForm();
      }
    }
  }, [allTrays, toggleTray, name]);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
      >
        {trays.length ? (
          <>
            <Inbox style={{ marginRight: 5 }} />
            {trays.map(tray => tray.name).join(',')}
          </>
        ) : (
          <>
            <MoveToInbox style={{ marginRight: 5 }} />
            Add to tray
          </>
        )}
      </Button>
      <Dialog
        open={open}
        fullWidth
      >
        <DialogTitle>
          Add to Trays
          <IconButton
            style={{ position: 'absolute', right: 0, top: 0 }}
            onClick={() => setOpen(false)}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{ margin: "auto" }}
        >
          {!create ? (
            <>
              <Typography>Select trays</Typography>
              <ButtonGroup orientation="vertical">
                {allTrays.map((existingTray) => (
                  <Button
                    key={existingTray.name}
                    size="small"
                    disableElevation
                    color={trays.find(t => existingTray.id === t.id) ? 'primary' : 'default'}
                    variant={trays.find(t => existingTray.id === t.id) ? 'contained' : 'outlined'}
                    onClick={() => toggleTray(existingTray)}
                  >
                    {existingTray.name}
                  </Button>
                ))}
                <Button
                  key={'new'}
                  size="small"
                  color={"secondary"}
                  disableElevation
                  onClick={() => setCreate(true)}
                >
                  Add New
                </Button>
              </ButtonGroup>
            </>
          ) : (
            <>
              <Typography>Create new tray</Typography>
              <TextField
                fullWidth
                autoFocus
                placeholder="Enter Tray Name"
                value={name}
                onChange={({ target: { value }}) => setName(value)}
              />
              <TextField
                fullWidth
                placeholder="Enter Description (optional)"
                value={description}
                onChange={({ target: { value }}) => setDescription(value)}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          {create ? (
            <>
              <Button
                onClick={() => {
                  setCreate(false);
                  clearForm();
                }}
              >
                Back
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => { dispatch(addCommitmentTrayAction({name, description})); setCreate(false); }}
              >
                Create Tray
              </Button>
            </>
          ) : (
            <>
              <Button
                color="primary"
                variant="contained"
                onClick={() => finalizeTrays()}
              >
                Save Trays
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

CommitmentTrayButton.defaultProps = {};

CommitmentTrayButton.propTypes = {
  trayIds: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CommitmentTrayButton;
