import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Style from './Style';
import { UserStruct } from '../../structs/user';
import {Redirect} from 'react-router';
import ROUTES from '../../constants/routes';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';

class ConfirmUser extends Component {
  state = {
    user: { ...UserStruct }
  };

  componentDidMount() {
    this.updateUser();
  }

  componentDidUpdate() {
    this.updateUser();
  }

  updateUser = () => {
    const { user: userProp } = this.props;
    const { user } = this.state;

    if (user.updatedAt !== userProp.updatedAt) {
      this.setState({ user: userProp });
    }
  };

  handleCredentialsChange = name => event => {
    const { user } = this.state;
    user.credentials[name] = event.target.value;
    this.setState({ user });
  };

  handleConfirm = () => {
    const { confirmUser } = this.props;
    const { user } = this.state;

    confirmUser(user);
  };

  handleResend = () => {
    const { resendCode } = this.props;

    resendCode();
  };

  render() {
    const { classes } = this.props;
    const { user } = this.state;
    const { credentials, attributes } = user;
    const { verificationCode } = credentials;
  
    if (user.attributes.emailVerified) {
      return <Redirect to={ROUTES.LOGIN} />
    }

    return (
      <Grid item data-tid="container" xs={8} sm={6} md={4}>
        <div className={classes.heading}>
          <Fade in>
            <Typography
              variant="h5"
              color="secondary"
              style={{ marginBottom: 15 }}
            >
              Confirm your email address
            </Typography>
          </Fade>
          <Typography>Almost there...</Typography>
          <Typography style={{ paddingTop: 20 }}>
            An email with a confirmation code has been sent to the email:
            <br />
            {attributes.email}
          </Typography>
          <Typography style={{ paddingTop: 20, fontWeight: 700 }}>
            Please enter that 6-digit code to finalize the sign up process.
          </Typography>
        </div>
        <Card style={{ marginTop: '1em' }}>
          <CardContent>
            <TextField
              id="code"
              label="Code"
              autoFocus
              fullWidth={true}
              className={classes.textField}
              InputProps={{
                className: classes.input
              }}
              value={verificationCode}
              onChange={this.handleCredentialsChange('verificationCode')}
              margin="normal"
            />
          </CardContent>
        </Card>
        <Fade in>
          <div style={{ marginTop: 20, width: '100%' }}>
            <Button
              variant="contained"
              fullWidth={true}
              disabled={!verificationCode}
              color="primary"
              onClick={this.handleConfirm}
            >
              Confirm
            </Button>
          </div>
        </Fade>
        <Fade in>
          <div style={{ marginTop: 20, width: '100%' }}>
            <Button
              variant="outlined"
              fullWidth={true}
              color="primary"
              onClick={this.handleResend}
            >
              Re-send Code
            </Button>
          </div>
        </Fade>
      </Grid>
    );
  }
}

ConfirmUser.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  confirmUser: PropTypes.func.isRequired,
  resendCode: PropTypes.func.isRequired
};

export default withStyles(Style)(ConfirmUser);
