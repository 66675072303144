import {
  SET_USER_SMS_PREFERENCE_ACTION, SET_USER_SMS_VERIFICATION_CODE_ACTION
} from "../actions/notifications";
import {getSMSPreferenceStruct} from '../../structs/notification';

export const initialState = {
  preference: getSMSPreferenceStruct(),
  verification_code: ''
};

export default function userSMSPreferenceReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case SET_USER_SMS_PREFERENCE_ACTION:
      return {
        ...state,
        preference: action.payload,
      };

    case SET_USER_SMS_VERIFICATION_CODE_ACTION:
      return {
        ...state,
        verification_code: action.payload,
      };

    default:
      return state;
  }
}
