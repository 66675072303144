import { put, select, takeLatest } from 'redux-saga/effects';
import debounce from 'lodash/debounce';

import { v4 as uuidv4 } from 'uuid';
import { loadFromStorage } from '../../utils/localStorage';
import {
  APPLICATION_DATA_CLEAR_REQUEST_ACTION,
  APPLICATION_DATA_REQUEST_ACTION,
  applicationDataSuccessAction
} from '../actions/app';
import {
  LAST_SYNC_UPDATE_REQUEST_ACTION,
  LAST_SYNC_UPDATE_SUCCESS_ACTION,
  LOAD_SYNC_VERSION_FROM_DISK,
  loadSyncVersionFromDiskAction,
  SET_LAST_SYNC,
  SET_SYNC_VERSION,
  SYNC_DATA_LOAD_REQUEST_ACTION,
  SYNC_DATA_LOAD_SUCCESS_ACTION,
  SYNC_VERSION_UPDATE_REQUEST_ACTION,
  SYNC_VERSION_UPDATE_SUCCESS_ACTION
} from '../actions/sync';
import { BackupProfileStruct, SyncMetaDataStruct } from '../../structs/sync';
import packageJson from '../../../package.json';
import { loadBackupProfileFromDiskAction } from '../actions/backupProfile';

function* handleUpdateSyncVersionRequest(action) {
  // Persist BackupProfile to Local Data Store
  const syncVersionState = yield select(store => store.syncVersion);
  const syncVersion = Object.assign({}, SyncMetaDataStruct, syncVersionState, {
    deviceId: !syncVersionState.deviceId ? uuidv4() : syncVersionState.deviceId,
    currentVersion: action.payload,
    applicationVersion: packageJson.version
  });

  yield put({
    type: SET_SYNC_VERSION,
    payload: syncVersion
  });

  // Signal Complete
  yield put({
    type: SYNC_VERSION_UPDATE_SUCCESS_ACTION
  });
}

function* handleUpdateLastSyncRequest(action) {
  // Persist BackupProfile to Local Data Store
  const syncVersionState = yield select(store => store.syncVersion);
  const syncVersion = Object.assign({}, SyncMetaDataStruct, syncVersionState, {
    deviceId: !syncVersionState.deviceId ? uuidv4() : syncVersionState.deviceId,
    lastSync: action.payload,
    applicationVersion: packageJson.version
  });

  yield put({
    type: SET_LAST_SYNC,
    payload: syncVersion
  });

  // Signal Complete
  yield put({
    type: LAST_SYNC_UPDATE_SUCCESS_ACTION
  });
}

function* handleSyncVersionDataLoadRequest(action) {
  // Load data into the store
  yield put({
    type: LOAD_SYNC_VERSION_FROM_DISK,
    payload: action.payload
  });

  yield put({
    type: SYNC_DATA_LOAD_SUCCESS_ACTION
  });
}

function* handleLoadApplicationDataRequest() {
  const type = 'syncVersion';

  // Perform Application Data Load
  const syncState = yield select(store => store.syncVersion);

  // Call the Util Local Storage loadState function which pulls data
  // from the local storage if cached there, if not it pulls from the disk
  const sync = yield loadFromStorage(type, syncState);

  yield put(loadSyncVersionFromDiskAction(sync));
  yield put(applicationDataSuccessAction(type));
}

function* handleStateChange() {
  const type = 'syncVersion';

  // Select data from the store
  const data = yield select(store => store[type]);

  debounceSave(type, data);
}

const debounceSave = debounce(
  (type, data) => {
    const dataString = JSON.stringify(data);

    // As well as in the local storage.
    localStorage.setItem(type, dataString);
  },
  3000,
  { leading: false, trailing: true, maxWait: 10000 }
);

function* handleApplicationDataClear() {
  yield put(loadSyncVersionFromDiskAction(SyncMetaDataStruct));
  yield put(loadBackupProfileFromDiskAction(BackupProfileStruct));
}

export default function* syncSaga() {
  yield takeLatest(
    SYNC_VERSION_UPDATE_REQUEST_ACTION,
    handleUpdateSyncVersionRequest
  );
  yield takeLatest(
    LAST_SYNC_UPDATE_REQUEST_ACTION,
    handleUpdateLastSyncRequest
  );
  yield takeLatest(
    SYNC_DATA_LOAD_REQUEST_ACTION,
    handleSyncVersionDataLoadRequest
  );
  yield takeLatest(SYNC_VERSION_UPDATE_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(LAST_SYNC_UPDATE_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(
    APPLICATION_DATA_REQUEST_ACTION,
    handleLoadApplicationDataRequest
  );
  yield takeLatest(SYNC_DATA_LOAD_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(
    APPLICATION_DATA_CLEAR_REQUEST_ACTION,
    handleApplicationDataClear
  );
}
