import {API, graphqlOperation} from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import {
  getPlanStruct,
  getSuccessStruct,
  getTrackerStruct,
  TRACKER_STATUS_ACTIVE,
  TRACKER_STATUS_ARCHIVED
} from '../../structs/trackers';
import moment from 'moment';
import {stringifyApn} from '../apn/v2';

/*
 *
 * Tracker Functions
 *
 */
export const fetchRemoteTrackers = async userId =>
  new Promise(async resolve => {
    let allTrackers = [];
    let whileNextToken = 'initial';
    
    /* eslint-disable no-await-in-loop */
    while (whileNextToken && allTrackers.length < 100) {
      const GQL = await API.graphql(
        graphqlOperation(queries.trackersByUpdatedDatev3, {
          ownerApn: stringifyApn({ userId }),
          limit: 1000,
          sortDirection: 'DESC',
          filter: {
            status: {
              eq: TRACKER_STATUS_ACTIVE
            }
          },
          nextToken: whileNextToken === 'initial' ? null : whileNextToken
        })
      );
      allTrackers = allTrackers.concat(GQL.data.trackersByUpdatedDatev3.items);
      whileNextToken = GQL.data.trackersByUpdatedDatev3.nextToken;
    }
    /* eslint-enable no-await-in-loop */
    
    resolve(
      allTrackers.length
        ? allTrackers.map(tracker => postFetchProcessTracker(tracker))
        : []
    );
  });

export const searchRemoteTrackers = async (userId, term) =>
  new Promise(async resolve => {
    let allTrackers = [];
    let whileNextToken = 'initial';
    
    /* eslint-disable no-await-in-loop */
    while (whileNextToken && allTrackers.length < 100) {
      const GQL = await API.graphql(
        graphqlOperation(queries.trackersByUpdatedDatev3, {
          ownerApn: stringifyApn({ userId }),
          limit: 1000,
          sortDirection: 'DESC',
          filter: {
            status: {
              eq: TRACKER_STATUS_ARCHIVED
            }
          },
          nextToken: whileNextToken === 'initial' ? null : whileNextToken
        })
      );
      allTrackers = allTrackers.concat(GQL.data.trackersByUpdatedDatev3.items);
      whileNextToken = GQL.data.trackersByUpdatedDatev3.nextToken;
    }
    /* eslint-enable no-await-in-loop */
    
    resolve(
      allTrackers.length
        ? allTrackers.map(tracker => postFetchProcessTracker(tracker))
        : []
    );
  });

export const fetchRemoteTrackerMostRecent = async userId => {
  const GQL = await API.graphql(
    graphqlOperation(queries.trackersByUpdatedDatev3, {
      ownerApn: stringifyApn({ userId }),
      limit: 1,
      sortDirection: 'DESC'
    })
  );
  
  if (!GQL.data.trackersByUpdatedDatev3.items.length) return getTrackerStruct();
  
  return postFetchProcessTracker(GQL.data.trackersByUpdatedDatev3.items.pop());
};

export const upsertRemoteTracker = async tracker => {
  if (!tracker.id) return;
  // We fetch the latest from the API prior to sending it in, for some DynamoDB conditional checks
  const remoteTracker = await fetchRemoteTracker(tracker.ownerApn, tracker.id);
  
  if (!remoteTracker.id) {
    const input = prepAndPruneTrackerForAppSync(
      Object.assign({}, remoteTracker, tracker)
    );
    
    const GQL = await API.graphql(
      graphqlOperation(mutations.createTrackerv3, {
        input
      })
    );
    return postFetchProcessTracker(GQL.data.createTrackerv3);
  }
  
  // If the remote is behind the local, then
  if (moment(remoteTracker.updatedAt).isSameOrAfter(tracker.updatedAt)) {
    // No need to send the Tracker
    return postFetchProcessTracker(remoteTracker);
  }
  
  const input = prepAndPruneTrackerForAppSync(
    Object.assign({}, remoteTracker, tracker)
  );
  
  const GQL = await API.graphql(
    graphqlOperation(mutations.updateTrackerv3, {
      input
    })
  );
  return postFetchProcessTracker(GQL.data.updateTrackerv3);
};

export const fetchRemoteTracker = async (ownerApn, id) => {
  const getTrackerGQL = await API.graphql(
    graphqlOperation(queries.getTrackerv3, {
      id,
      ownerApn
    })
  );
  
  if (!getTrackerGQL.data.getTrackerv3) return getTrackerStruct();
  
  return postFetchProcessTracker(getTrackerGQL.data.getTrackerv3);
};

export const removeRemoteTracker = async tracker => {
  await API.graphql(
    graphqlOperation(mutations.deleteTrackerv3, {
      input: { id: tracker.id, ownerApn: tracker.ownerApn }
    })
  ).catch(err => console.log(err));
};

const postFetchProcessTracker = tracker => {
  // Pass fetched value through the Struct, never trust input. :)
  const { milestones } = tracker;

  if (milestones && milestones.items && milestones.items.length)
    return getTrackerStruct({...tracker, milestones: milestones.items});
  
  return getTrackerStruct(tracker);
};

export const prepAndPruneTrackerForAppSync = tracker => {
  // Prune any keys that are not in the schema
  const keys = Object.keys(getTrackerStruct());
  const pruned = getTrackerStruct(tracker);
  const prunedKeys = Object.keys(pruned);
  const propertiesToPrune = prunedKeys.filter(key => !keys.includes(key));
  if (propertiesToPrune.length)
    propertiesToPrune.map(key => delete pruned[key]);
  
  // Prep any empty strings
  keys.map(key => {
    if (typeof pruned[key] === 'string' && pruned[key] === '') {
      pruned[key] = ' ';
    }
    return true;
  });
  
  if (tracker.success.length)
    pruned.success = tracker.success.map(success =>
      prepAndPruneSuccessForAppSync(success)
    );

  if (tracker.plans.length)
    pruned.plans = tracker.plans.map(plan =>
      prepAndPrunePlanForAppSync(plan)
    );
  
  delete pruned.milestones;
  return pruned;
};

const prepAndPruneSuccessForAppSync = success => {
  // Prune any keys that are not in the schema
  const keys = Object.keys(getSuccessStruct());
  const pruned = getSuccessStruct(success);
  const prunedKeys = Object.keys(pruned);
  const propertiesToPrune = prunedKeys.filter(key => !keys.includes(key));
  if (propertiesToPrune.length)
    propertiesToPrune.map(key => delete pruned[key]);
  
  // Prep any empty strings
  keys.map(key => {
    if (typeof pruned[key] === 'string' && pruned[key] === '') {
      pruned[key] = ' ';
    }
    return true;
  });
  
  return pruned;
};

const prepAndPrunePlanForAppSync = plan => {
  // Prune any keys that are not in the schema
  const keys = Object.keys(getPlanStruct());
  const pruned = getPlanStruct(plan);
  const prunedKeys = Object.keys(pruned);
  const propertiesToPrune = prunedKeys.filter(key => !keys.includes(key));
  if (propertiesToPrune.length)
    propertiesToPrune.map(key => delete pruned[key]);
  
  // Prep any empty strings
  keys.map(key => {
    if (typeof pruned[key] === 'string' && pruned[key] === '') {
      pruned[key] = ' ';
    }
    return true;
  });
  
  return pruned;
};
