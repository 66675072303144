import { put, takeEvery, select, takeLatest, all } from 'redux-saga/effects';
import {
  COMMITMENT_TRAY_CREATE_REQUEST_ACTION,
  COMMITMENT_TRAY_CREATE_SUCCESS_ACTION,
  COMMITMENT_TRAY_DELETE_REQUEST_ACTION,
  COMMITMENT_TRAY_DELETE_SUCCESS_ACTION,
  COMMITMENT_TRAY_UPDATE_REQUEST_ACTION,
  COMMITMENT_TRAY_UPDATE_SUCCESS_ACTION, REMOVE_LOCAL_COMMITMENT_TRAY,
  UPSERT_LOCAL_COMMITMENT_TRAY
} from "../actions/commitmentTray";
import {getCommitmentTrayStruct} from "../../structs/commitmentTray";
import {stringifyApn} from "../../utils/apn/v2";
import { v4 as uuidv4 } from "uuid";
import {
  createRemoteCommitmentTray, deleteRemoteCommitmentTray,
  fetchRemoteCommitmentTray,
  fetchRemoteCommitmentTraysByUser, updateRemoteCommitmentTray
} from "../../utils/appSync/commitmentTray";
import {APPLICATION_DATA_CLEAR_REQUEST_ACTION, APPLICATION_DATA_REQUEST_ACTION} from "../actions/app";
import {addNotificationAction} from "../actions/notifications";
import {getSystemNotificationStruct} from "../../structs/notification";
import Typography from "@material-ui/core/Typography";
import React from "react";

function* handleCreateTrayRequest({ payload: tray }) {

  const trays = yield select(store => store.trays);

  if (trays.length >= 10) {
    yield put(
      addNotificationAction(
        getSystemNotificationStruct({
          message: (
            <div>
              <Typography>
                {`Sorry there is a limit of 10 Commitment Trays, if would like to add new, please remove others.`}
              </Typography>
            </div>
          ),
          type: "dialog",
          level: "warning",
        })
      )
    );
    return 0;
  }

  const user = yield select(store => store.user);
  const { id: userId } = user;
  const ownerApn = stringifyApn({ userId });

  const commitmentTray = getCommitmentTrayStruct({
    ...tray,
    id: uuidv4(),
    ownerApn
  });

  const remoteTray = yield createRemoteCommitmentTray(commitmentTray);

  yield put({
    type: UPSERT_LOCAL_COMMITMENT_TRAY,
    payload: remoteTray
  });

  // Signal Complete
  yield put({
    type: COMMITMENT_TRAY_CREATE_SUCCESS_ACTION,
    payload: remoteTray
  });
}

function* handleUpdateTrayRequest({ payload: tray }) {
  const user = yield select(store => store.user);
  const { id: userId } = user;
  const ownerApn = stringifyApn({ userId });

  const existingTray = yield fetchRemoteCommitmentTray(ownerApn, tray.id)

  const commitmentTray = getCommitmentTrayStruct({
    ...existingTray,
    ...tray
  });

  const remoteTray = yield updateRemoteCommitmentTray(commitmentTray);

  yield put({
    type: UPSERT_LOCAL_COMMITMENT_TRAY,
    payload: remoteTray
  });

  // Signal Complete
  yield put({
    type: COMMITMENT_TRAY_UPDATE_SUCCESS_ACTION,
    payload: remoteTray
  });
}

function* handleDeleteTrayRequest ({ payload: tray }) {
  const { ownerApn, id } = tray;
  yield deleteRemoteCommitmentTray(ownerApn, id);

  yield put({
    type: REMOVE_LOCAL_COMMITMENT_TRAY,
    payload: tray
  });

  // Signal Complete
  yield put({
    type: COMMITMENT_TRAY_DELETE_SUCCESS_ACTION
  });
}

function* handleApplicationDataClear () {
  const trays = yield select(store => store.trays);
  yield all(trays.map((tray) => put({ type: REMOVE_LOCAL_COMMITMENT_TRAY, payload: tray })))
}

function* handleFullSyncRequest () {
  const { id: userId } = yield select(store => store.user);
  const ownerApn = stringifyApn({ userId });
  const remoteTrays = yield fetchRemoteCommitmentTraysByUser(ownerApn);
  yield all(remoteTrays.map((tray) => put({ type: UPSERT_LOCAL_COMMITMENT_TRAY, payload: tray })))
}

export default function* commitmentTraySaga() {
  // Handle Actions
  yield takeEvery(COMMITMENT_TRAY_CREATE_REQUEST_ACTION, handleCreateTrayRequest);
  yield takeEvery(COMMITMENT_TRAY_UPDATE_REQUEST_ACTION, handleUpdateTrayRequest);
  yield takeEvery(COMMITMENT_TRAY_DELETE_REQUEST_ACTION, handleDeleteTrayRequest);

  yield takeLatest(APPLICATION_DATA_CLEAR_REQUEST_ACTION, handleApplicationDataClear);
  yield takeLatest(APPLICATION_DATA_REQUEST_ACTION, handleFullSyncRequest);
}
