import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/es/List/List';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CommitmentCalendarItem from '../../containers/Commitment/CalendarItem';
import { getCommitmentIndexByDateStruct } from '../../structs/indexers';
import { getDateFromString } from '../../utils/date';

const style = theme => ({
  card: {
    marginRight: '17px',
    marginBottom: '1em'
  },
  badge: {
    top: 0,
    right: 0
  },
  welcome: {
    color: theme.palette.secondary.main
  },
  timeSlot: {
    '&:hover': {
      backgroundColor: '#d3d3d3',
      borderRadius: 5
    }
  },
  checkbox: {
    padding: 0,
    marginTop: -1,
    marginRight: 2
  },
  override: {
    display: 'inherit'
  },
  listPadding: {
    padding: 0,
    paddingLeft: '.5em',
    paddingTop: 1
  }
});

class Calendar extends Component {
  state = {
    startDate: moment()
      .startOf('day')
      .toDate(),
    timeSlots: [],
    timeSlotIndex: {},
    dailyCommitments: {
      today: getCommitmentIndexByDateStruct(),
      past: [],
      future: []
    }
  };

  componentDidMount() {
    const { startDate } = this.state;
    const { commitmentIndex, activeDateStamp } = this.props;
    const { byDate } = commitmentIndex;

    this.startTimer();

    if (!Object.keys(byDate).length) return;

    if (
      startDate !== this.selectedDate ||
      this.activeDate !== activeDateStamp
    ) {
      this.indexDate(activeDateStamp ? moment(activeDateStamp).toDate() : null);
    }
  }

  componentDidUpdate() {
    const { startDate } = this.state;
    const { commitmentIndex, activeDateStamp } = this.props;
    const { byDate } = commitmentIndex;

    if (!Object.keys(byDate).length) return;

    if (
      startDate !== this.selectedDate ||
      commitmentIndex.updatedAt !== this.commitmentIndexDate ||
      this.activeDate !== activeDateStamp
    ) {
      this.indexDate(activeDateStamp ? moment(activeDateStamp).toDate() : null);
      this.activeDate = activeDateStamp;
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    clearTimeout(this.timeout);
  }

  commitmentIndexDate;

  selectedDate;

  activeDate;

  timer;

  timeout;

  calendarRef;

  scrolled;

  startTimer = () => {
    if (!this.timer) {
      this.timer = setInterval(this.handleSetCurrentTime, 60 * 1000);
    }
    if (!this.timeout) {
      this.timeout = setTimeout(this.handleSetCurrentTime, 1000);
    }
  };

  handleSetCurrentTime = () => {
    if (!this.scrolled) {
      const midnight = getDateFromString(new Date(), true);
      const scrollAdjustment =
        this.calendarRef.scrollHeight *
        (Math.abs(midnight - new Date()) / (1000 * 60 * 60 * 24));
      let visualAdjustment = this.calendarRef.offsetHeight * 0.5;
      if (
        scrollAdjustment >
        this.calendarRef.scrollHeight - this.calendarRef.offsetHeight * 0.5
      ) {
        visualAdjustment = 0;
      }
      this.calendarRef.scrollTop = scrollAdjustment - visualAdjustment;

      this.scrolled = true;
    }
  };

  indexDate = (forceStartDate = null) => {
    const { commitmentIndex } = this.props;
    const { byDate } = commitmentIndex;
    let { startDate, dailyCommitments } = this.state;
    startDate = forceStartDate || startDate;

    const timeSlots = range(0, 24 * 4).map(slotIndex => {
      const time = getDateFromString(startDate.toString(), true);

      // Add 15 minutes for our calendar
      time.setTime(time.getTime() + slotIndex * 15 * 60 * 1000);

      return time;
    });

    const timeSlotIndex = {};
    timeSlots.map(timeSlot => {
      timeSlotIndex[timeSlot.toLocaleString()] = {
        commitments: []
      };
      return timeSlot;
    });

    // Daily Commitment Indexing
    if (
      byDate[startDate.toDateString()] &&
      byDate[startDate.toDateString()].commitments.length
    ) {
      byDate[startDate.toDateString()].commitments.map(document => {
        const { entity: commitment } = document;
        const { date } = commitment;
        const commitmentDate = new Date(date);
        let commitmentDateString = commitmentDate.toLocaleString();
        const { [commitmentDateString]: commitmentTimeSlot } = timeSlotIndex;

        // If the commitment has a non-standard time of day, we need to find a
        // valid time slot
        if (!commitmentTimeSlot) {
          const adjustedTimeSlot = timeSlots
            .filter(timeSlot => timeSlot < commitmentDate)
            .pop();
          commitmentDateString = adjustedTimeSlot.toLocaleString();
        }
        timeSlotIndex[commitmentDateString].commitments.push(commitment);

        return document;
      });
    }

    dailyCommitments = {
      today: getCommitmentIndexByDateStruct(),
      past: [],
      future: []
    };

    if (byDate[startDate.toDateString()]) {
      dailyCommitments[
        startDate.toDateString()
      ] = getCommitmentIndexByDateStruct(byDate[startDate.toDateString()]);
    } else {
      dailyCommitments[
        startDate.toDateString()
      ] = getCommitmentIndexByDateStruct();
    }

    if (byDate && startDate && byDate[startDate.toDateString()]) {
      dailyCommitments.today = getCommitmentIndexByDateStruct(
        byDate[startDate.toDateString()]
      );
    }

    this.selectedDate = startDate;
    this.commitmentIndexDate = commitmentIndex.updatedAt;

    this.setState({
      startDate,
      timeSlots,
      timeSlotIndex,
      dailyCommitments
    });
  };

  handleSetActiveDateStamp = activeTimeStamp => () => {
    const { onSelect } = this.props;
    onSelect(activeTimeStamp);
  };

  render() {
    const { classes, duration, color } = this.props;
    const { startDate, timeSlots, timeSlotIndex } = this.state;

    if (startDate.toString() === 'Invalid Date') {
      return null;
    }

    const now = new Date();
    const nowTimeSlot = timeSlots.filter(timeSlot => timeSlot < now).pop();

    const startDateLabel = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: '2-digit'
    }).format(startDate);

    return (
      <div
        style={{
          display: 'flex',
          height: '35vh',
          marginTop: '1.75em'
        }}
      >
        {/* Calendar View */}
        <div
          className="tour-calendar"
          style={{ flex: 'auto', overflow: 'hidden' }}
        >
          <Card
            className={classes.card}
            style={{ height: '35vh' }}
            elevation={0}
          >
            <CardHeader
              title={startDateLabel}
              subheader={moment(startDate).calendar()}
            />
            <div
              ref={el => {
                this.calendarRef = el;
              }}
              style={{
                height: '100%',
                overflow: 'auto',
                paddingBottom: '5em'
              }}
            >
              <CardContent>
                {timeSlots.map(time => (
                  <div
                    role="button"
                    tabIndex="0"
                    key={time.toString()}
                    className={classes.timeSlot}
                    style={{
                      borderTop:
                        time.getMinutes() === 0 ? '1px dashed gray' : '',
                      marginLeft: '2em',
                      height: '1.75em',
                      position: 'relative'
                    }}
                    onClick={this.handleSetActiveDateStamp(time)}
                    onKeyPress={this.handleSetActiveDateStamp(time)}
                  >
                    <Typography
                      style={{
                        position: 'absolute',
                        top: '-.75em',
                        left: '-3em',
                        color: 'gray'
                      }}
                    >
                      {time.getMinutes() === 0
                        ? new Intl.DateTimeFormat('en-US', {
                            hour: 'numeric'
                          }).format(time)
                        : ''}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: '0.7em',
                        marginLeft: '-4em',
                        borderBottom:
                          nowTimeSlot === time ? '1px solid lightpink' : '',
                        position: 'absolute',
                        top: 0,
                        backgroundColor: 'white'
                      }}
                    >
                      {nowTimeSlot === time
                        ? new Intl.DateTimeFormat('en-US', {
                            hour: 'numeric',
                            minute: 'numeric'
                          }).format(new Date())
                        : ''}
                    </Typography>
                    <List style={{ padding: 0 }}>
                      {time.toString() === startDate.toString() ? (
                        <div
                          style={{
                            height: '1.75em',
                            display: 'flex'
                          }}
                        >
                          <div
                            style={{
                              flex: 'auto'
                            }}
                          >
                            <ListItem
                              dense
                              className={[
                                classes.listPadding,
                                classes.override
                              ].join(' ')}
                              style={{
                                backgroundColor: color,
                                minHeight: '1.85em',
                                height: `${Math.ceil(duration / 15) * 1.75}rem`,
                                borderRadius: 8,
                                borderColor: 'rbga(0,0,0,0.54)',
                                borderWidth: 2,
                                borderStyle: 'solid'
                              }}
                            >
                              <ListItemText
                                primaryTypographyProps={{
                                  style: {
                                    fontSize: '.75rem',
                                    overflow: 'hidden',
                                    height: '1.75em',
                                    marginTop: -2
                                  }
                                }}
                                primary={
                                  <React.Fragment>
                                    <Checkbox
                                      checked={false}
                                      className={classes.checkbox}
                                      disabled
                                      icon={
                                        <CheckBoxOutlineBlankIcon
                                          style={{ fontSize: '1rem' }}
                                        />
                                      }
                                      checkedIcon={
                                        <CheckBoxIcon
                                          style={{ fontSize: '1rem' }}
                                        />
                                      }
                                    />
                                    New Commitment
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                          </div>
                        </div>
                      ) : null}

                      <div
                        style={{
                          height: '1.75em',
                          display: 'flex',
                          marginLeft: '25%'
                        }}
                      >
                        {timeSlotIndex[time.toLocaleString()].commitments.length
                          ? timeSlotIndex[
                              time.toLocaleString()
                            ].commitments.map(commitment => (
                              <div
                                key={commitment.id}
                                style={{
                                  flex: 'auto'
                                }}
                              >
                                <CommitmentCalendarItem
                                  commitment={commitment}
                                  focusable={false}
                                />
                              </div>
                            ))
                          : null}
                      </div>
                    </List>
                  </div>
                ))}
              </CardContent>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

Calendar.propTypes = {
  classes: PropTypes.object.isRequired,
  commitmentIndex: PropTypes.object.isRequired,
  activeDateStamp: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  duration: PropTypes.any.isRequired,
  color: PropTypes.string.isRequired
};
export default withStyles(style)(Calendar);
