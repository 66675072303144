import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import TextField from '@material-ui/core/TextField';
import {useDispatch} from 'react-redux';
import {addTrackerShareAction} from '../../../redux/actions/trackerShares';
import SelectContactButton from "../../Contacts/SelectContactButton";
import {getContactStruct} from "../../../structs/contact";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {validEmail} from "../../../utils/validate";
import {parseApn} from "../../../utils/apn/v2";

const ShareDialog = ({tracker, onDialog, open}) => {
  
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState(false);
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState(getContactStruct());
  const valid = validEmail(email);
  const { userId } = parseApn(contact.friendApn);

  const handleClose = () => {
    onDialog('');
  };

  const handleShare = () => {
    dispatch(addTrackerShareAction({
      trackerId: tracker.id,
      emailAddress: email,
      userId
    }));
    setConfirm(false);
    setEmail('');
    handleClose();
  };
  
  const handleEmailChange = (event) => {
    const email = event.target.value;
    setEmail(email);
    setConfirm(false);
  };

  const handleContactSelect = (contact) => {
    setContact(contact);
    setEmail(contact.friendEmail);
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-share-dialog-title"
      aria-describedby="alert-share-dialog-description"
    >
      <DialogTitle id="alert-share-dialog-title">
        Share Tracker
        <IconButton
          style={{ position: 'absolute', right: 0, top: 0 }}
          onClick={handleClose}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent id="alert-share-dialog-description">
        <SelectContactButton onSelect={handleContactSelect} selectedContact={contact} tracker={tracker} />
        {contact.id ? null : <Typography style={{ paddingTop: '1.5em' }}>- OR -</Typography>}
        <TextField
          id="email"
          error={!!email && !valid}
          label={!valid
            ? 'Enter an email for someone new'
            : 'Email to Share With'
          }
          fullWidth={true}
          value={email}
          onChange={handleEmailChange}
          margin="normal"
          helperText={
            email && valid
              ? 'Awesome. Email address looks great!'
              : email ? 'That email is not looking valid, keep going...' : ''
          }
        />
        {confirm && valid
          ? (
            <React.Fragment>
              <DialogContentText>
                You are about to share this tracker.
                When you share, the entire tracker will be visible to the person you share with.
              </DialogContentText>
              <DialogContentText>
                When the other person accepts the share, you both will be added to each other's contacts.
              </DialogContentText>
              <DialogContentText>
                Do you really want to share this tracker?
              </DialogContentText>
            </React.Fragment>
          )
          : null}
      </DialogContent>
      <DialogActions>
        {confirm && valid
          ? <Button
            onClick={handleShare}
            color="primary"
            variant="contained"
            disabled={!confirm || !valid}
            autoFocus
          >
            Confirm
          </Button>
          : <Button
            onClick={() => setConfirm(true)}
            color="primary"
            variant="contained"
            disabled={!valid}
            autoFocus
          >
            Share
          </Button>}
      </DialogActions>
    </Dialog>
  );
};

ShareDialog.defaultProps = {};

ShareDialog.propTypes = {
  tracker: PropTypes.object.isRequired,
  onDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default ShareDialog;
