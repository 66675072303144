import {getTrackerTemplateStruct} from "../../structs/trackerTemplate";

export const TRACKER_TEMPLATE_UPSERT_LOCAL_ACTION = 'TRACKER_TEMPLATE_UPSERT_LOCAL';
export const TRACKER_TEMPLATE_REMOVE_LOCAL_ACTION = 'TRACKER_TEMPLATE_REMOVE_LOCAL';
export const TRACKER_TEMPLATE_FETCH_REQUEST_ACTION = 'TRACKER_TEMPLATE_FETCH_REQUEST';
export const TRACKER_TEMPLATE_CREATE_REQUEST_ACTION = 'TRACKER_TEMPLATE_CREATE_REQUEST';
export const TRACKER_TEMPLATE_CREATE_SUCCESS_ACTION = 'TRACKER_TEMPLATE_CREATE_SUCCESS';
export const TRACKER_TEMPLATE_DELETE_REQUEST_ACTION = 'TRACKER_TEMPLATE_DELETE_REQUEST';
export const TRACKER_TEMPLATE_DELETE_SUCCESS_ACTION = 'TRACKER_TEMPLATE_DELETE_SUCCESS';
export const TRACKER_TEMPLATE_FROM_TRACKER_REQUEST_ACTION = 'TRACKER_TEMPLATE_FROM_TRACKER_REQUEST_ACTION';
export const TRACKER_TEMPLATE_FROM_TRACKER_SUCCESS_ACTION = 'TRACKER_TEMPLATE_FROM_TRACKER_SUCCESS_ACTION';

export const fetchTrackerTemplate = () => ({
  type: TRACKER_TEMPLATE_FETCH_REQUEST_ACTION
});

export const upsertLocalTrackerTemplateAction = template => ({
  type: TRACKER_TEMPLATE_UPSERT_LOCAL_ACTION,
  payload: getTrackerTemplateStruct(template)
});

export const removeLocalTrackerTemplateAction = template => ({
  type: TRACKER_TEMPLATE_REMOVE_LOCAL_ACTION,
  payload: getTrackerTemplateStruct(template)
});

export const createTrackerTemplateAction = template => ({
  type: TRACKER_TEMPLATE_CREATE_REQUEST_ACTION,
  payload: getTrackerTemplateStruct(template)
});

export const deleteTrackerTemplateAction = template => ({
  type: TRACKER_TEMPLATE_DELETE_REQUEST_ACTION,
  payload: getTrackerTemplateStruct(template)
});

export const createTemplateFromTrackerAction = item => ({
  type: TRACKER_TEMPLATE_FROM_TRACKER_REQUEST_ACTION,
  payload: item
});
