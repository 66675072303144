import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import ListsCard from '../../containers/Checklists/Card';
import routes from '../../constants/routes.json';

export const style = () => ({
  listCard: {
    margin: '.5em',
    border: 'lightgray 1px solid',
    minWidth: '12em',
    maxWidth: '18em'
  },
  listCardFlexContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  container: {
    width: 'calc(100vw - 68px)',
    display: 'flex',
    flexDirection: 'row',
    marginTop: 23
  },
  contentContainer: {
    marginRight: '1em',
    display: 'flex'
  }
});

class ListDetail extends Component {
  state = {};

  handleBack = () => {
    const { history } = this.props;
    history.push(routes.CHECKLISTS);
  };

  render() {
    const { checklists, classes, match } = this.props;
    const { params } = match;
    const { id } = params;

    return (
      <div className={classes.container}>
        <div style={{ width: '100%' }}>
          <Button onClick={this.handleBack}>{'< Back to Checklists'}</Button>
          <div className={classes.contentContainer}>
            <div className={classes.listCardFlexContainer}>
              {checklists.length ? (
                checklists.map(list =>
                  list.id === id ? (
                    <ListsCard key={list.id} list={list} />
                  ) : null
                )
              ) : (
                <Card key="no-lists" elevation={0} className={classes.listCard}>
                  <CardContent>No List with ID: {id}</CardContent>
                </Card>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ListDetail.defaultProps = {
  checklists: [],
  match: { params: { id: '' } }
};

ListDetail.propTypes = {
  classes: PropTypes.any.isRequired,
  checklists: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any.isRequired
};

export default withRouter(withStyles(style)(ListDetail));
