import React, { Component } from 'react';
import { connect } from 'react-redux';
import Home from '../components/Home/Home';
import { saveCommitmentAction } from '../redux/actions/commitments';
import {setTodayAction, setUserCheckAction} from '../redux/actions/app';

export class HomePageContainer extends Component {
  render() {
    return <Home />;
  }
}

const mapStateToProps = state => ({
  user: state.user,
  commitmentIndex: state.commitmentIndex,
  today: state.app.today,
  trackerIndex: state.trackerIndex
});

const mapDispatchToProps = dispatch => ({
  saveCommitment: commitment => {
    dispatch(saveCommitmentAction(commitment));
  },
  setUserCheck: userCheck => dispatch(setUserCheckAction(userCheck)),
  setToday: date => dispatch(setTodayAction(date))
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
