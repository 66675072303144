import React, { Component } from 'react';
import { connect } from 'react-redux';
import CommitmentForm from '../../components/Commitment/Form';
import { addCommitmentAction } from '../../redux/actions/commitments';
import {resetActiveContextAction} from '../../redux/actions/activeContext';
import {createCommitInviteAction} from "../../redux/actions/commitInvite";

export class CommitmentFormContainer extends Component {
  render() {
    return <CommitmentForm />;
  }
}

const mapStateToProps = state => ({
  user: state.user,
  activeContext: state.activeContext
});

const mapDispatchToProps = dispatch => ({
  addCommitment: commitment => {
    dispatch(addCommitmentAction(commitment));
  },
  addCommitInvite: invite => {
    dispatch(createCommitInviteAction(invite));
  },
  resetActiveContext: () => {
    dispatch(resetActiveContextAction())
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CommitmentForm);
