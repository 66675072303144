import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import DragHandle from '@material-ui/icons/DragHandle';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { getSuccessStruct } from '../../../structs/trackers';

const style = () => ({
  dragHandle: {
    margin: '15px -9px 12px -27px',
    color: 'lightgray'
  },
  checkboxButton: {
    padding: '12px 10px',
    margin: '4px 4px 0 0px'
  }
});

class SuccessItem extends Component {
  state = { item: null };

  componentDidMount() {
    const { item: itemProp } = this.props;
    const { item } = this.state;

    if ((item && item.item !== itemProp.item) || !item) {
      this.hydrateState(itemProp);
    }
  }

  componentDidUpdate() {
    const { item: itemProp } = this.props;
    const { item } = this.state;

    if ((item && item.item !== itemProp.item) || !item) {
      this.hydrateState(itemProp);
    }

    this.focusSelected();
  }

  inputField;

  focusSelected = () => {
    const { selectedItem, item } = this.props;

    if (
      selectedItem === item.seq &&
      document.activeElement !== this.inputField
    ) {
      this.inputField.focus();
    }
  };

  hydrateState = item => {
    this.setState({
      item: getSuccessStruct(item)
    });
  };

  handleChange = event => {
    const { onChange } = this.props;
    const { item } = this.state;
    if (event.target.name === 'checked') {
      item[event.target.name] = event.target.checked;
    } else {
      item[event.target.name] = event.target.value;
    }

    onChange(item);
    this.setState({ item });
  };

  handleRemove = () => {
    const { item } = this.state;
    const { onRemove } = this.props;
    onRemove(item);
  };

  handleFocus = () => {
    const { onInputFocus } = this.props;
    const { item } = this.state;
    if (item) {
      onInputFocus(item.seq);
    }
  };

  handleBlur = () => {
    const { onInputBlur } = this.props;
    const { item } = this.state;
    onInputBlur(item.seq);
  };

  render() {
    const { item, selectedItem, classes, editable } = this.props;
    const selected = selectedItem === item.seq;
    
    return (
      <ListItem
        selected={selected}
        className={classes.listPadding}
        classes={{ root: classes.lessPadding }}
      >
        <DragHandle className={classes.dragHandle} />
        <Checkbox
          name="checked"
          className={classes.checkboxButton}
          checked={item.checked}
          disabled={!editable}
          tabIndex={-1}
          disableRipple
          onChange={this.handleChange}
          icon={
            <CheckBoxOutlineBlankIcon
              style={{ color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
        />
        <TextField
          multiline
          style={{ width: '90%' }}
          name="item"
          disabled={!editable}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          margin="normal"
          value={item.item}
          InputProps={{
            disableUnderline: true
          }}
          inputRef={field => {
            this.inputField = field;
          }}
        />
        {selected && editable ? <ListItemSecondaryAction>
          <IconButton size="small" onClick={this.handleRemove}>
            <DeleteForeverIcon />
          </IconButton>
        </ListItemSecondaryAction> : null}
      </ListItem>
    );
  }
}

SuccessItem.defaultProps = {
  selectedItem: null,
  editable: true
};

SuccessItem.propTypes = {
  classes: PropTypes.any.isRequired,
  item: PropTypes.any.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onInputFocus: PropTypes.func.isRequired,
  onInputBlur: PropTypes.func.isRequired,
  selectedItem: PropTypes.any,
  editable: PropTypes.bool
};

export default withStyles(style)(SuccessItem);
