module.exports = () => ({
  noItems: {
    display: 'block',
    fontSize: 14,
    padding: '20px 15px',
    textAlign: 'center'
  },
  heading: {
    color: '#fff',
    background: '#5d7bb0',
    minHeight: 45,
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px'
  },
  input: {
    width: '100%',
    '& .MuiInputBase-root': {
      width: '100%',
      paddingRight: 50
    }
  },
  formControl: {
    minWidth: 200,
    maxWidth: 400,
    marginBottom: 10
  },
  progress: {
    position: 'absolute',
    bottom: 5,
    right: 5
  },
  loadingItem: {
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loadingText: {
    display: 'block',
    marginLeft: 15,
    marginRight: 35,
    fontSize: 14
  },
  item: {
    paddingLeft: 50,
    paddingRight: 0
  },
  secondaryAction: {
    left: 0,
    right: 'auto'
  },
  listItemWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  name: {
    display: 'block',
    fontWeight: 700
  },
  additionalInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    fontSize: 12
  }
});
