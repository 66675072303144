import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router';
import { getLearnPageStruct } from '../../structs/learn';
import LearnPageItems from './Page/Items';
import routes from '../../constants/routes.json';

export const style = () => ({
  input: {
    color: '#393939',
    flex: 1,
    width: '100%'
  },
  textField: {
    fontSize: 12,
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    width: '100%'
  },
  container: {
    width: 'calc(100vw - 68px)',
    display: 'flex',
    flexDirection: 'row',
    marginTop: 23
  }
});

class LearnPage extends Component {
  state = {
    page: getLearnPageStruct()
  };

  componentDidMount() {
    const { match } = this.props;
    const { page } = this.state;
    const { params } = match;
    const { id } = params;

    if (!page) return;

    if (id && page.id !== id) {
      this.hydratePage(id);
    }
  }

  componentDidUpdate() {
    const { match } = this.props;
    const { page } = this.state;
    const { params } = match;
    const { id } = params;

    if (!page) return;

    if (id && page.id !== id) {
      this.hydratePage(id);
    }
    this.checkDeferredCommands();
  }

  // List of commands to run after render.
  deferredCommands = [];

  /*
   *
   * The Deferred Commands model is to allow us to run functions once the
   * state has been set.
   * This is useful when we want to persist data via a redux action, or run
   * multiple steps to record progress.
   *
   */
  checkDeferredCommands = () => {
    if (!this.deferredCommands.length) return;

    this.deferredCommands = this.deferredCommands.filter(command => {
      command();
      return false;
    });
  };

  debounceSave = debounce(() => {
    const { page } = this.state;
    const { saveLearnPage } = this.props;
    saveLearnPage(page);
  }, 650);

  hydratePage = id => {
    const { learnPages } = this.props;
    const page = learnPages.filter(item => item.id === id).pop();
    if (page) this.setState({ page });
  };

  handleLearnChange = parts => {
    const { page } = this.state;
    page.parts = parts.map(m => m);
    this.deferredCommands.push(() => {
      const { saveLearnPage } = this.props;
      saveLearnPage(page);
    });
    this.setState({ page });
  };

  handleChange = event => {
    const { page } = this.state;
    page[event.target.name] = event.target.value;
    this.deferredCommands.push(() => {
      this.debounceSave();
    });
    this.setState({ page });
  };

  handleDelete = () => {
    const { page } = this.state;
    const { history, deleteLearnPage } = this.props;
    deleteLearnPage(page.id);
    history.push(routes.LEARN_PAGE_BASE);
  };

  render() {
    const { classes } = this.props;
    const { page } = this.state;

    if (!page) return <div />;

    return (
      <div className={classes.container}>
        <div>
          <TextField
            id="title"
            name="title"
            value={page.title === ' ' ? '' : page.title}
            placeholder="Page Title"
            required
            fullWidth
            margin="normal"
            onChange={this.handleChange}
            className={classes.textField}
            InputProps={{
              style: {
                fontSize: '1.75rem'
              },
              className: classes.input,
              disableUnderline: true
            }}
          />
        </div>
        <div>
          <LearnPageItems page={page} onChange={this.handleLearnChange} />
        </div>
        <div>
          <Button onClick={this.handleDelete}>Delete Page</Button>
        </div>
      </div>
    );
  }
}

LearnPage.defaultProps = {
  match: { params: { id: '' } }
};

LearnPage.propTypes = {
  history: PropTypes.any.isRequired,
  classes: PropTypes.any.isRequired,
  learnPages: PropTypes.any.isRequired,
  match: PropTypes.any,
  saveLearnPage: PropTypes.func.isRequired,
  deleteLearnPage: PropTypes.func.isRequired
};

export default withRouter(withStyles(style)(LearnPage));
