import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import {CloudDownload, DeleteForever} from "@material-ui/icons";
import {useDispatch} from "react-redux";
import {deleteTrackerTemplateAction} from "../../redux/actions/trackerTemplate";
import triggerDownload from "../../utils/backup/download";

const TrackerTemplateItem = ({ template, onSetActiveTemplate }) => {
  const dispatch = useDispatch();

  const handleAddClick = () => {
    onSetActiveTemplate(template);
  };

  const handleDeleteClick = () => {
    dispatch(deleteTrackerTemplateAction(template));
  };

  const handleDownloadClick = () => {
    const jsonString = JSON.stringify({
      name: template.name,
      description: template.description,
      scaffold: template.scaffold
    }, null, 2);

    triggerDownload(jsonString, template.name);
  };

  return (
    <ListItem>
      <ListItemText primary={template.name} secondary={template.description} />
      <ListItemSecondaryAction>
        <IconButton
          onClick={handleDownloadClick}
        >
          <CloudDownload/>
        </IconButton>
        <IconButton
          onClick={handleAddClick}
        >
          <AddToPhotosIcon/>
        </IconButton>
        <IconButton
          onClick={handleDeleteClick}
        >
          <DeleteForever/>
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default TrackerTemplateItem;
