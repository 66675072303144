// Active Context Actions
export const SET_ACTIVE_CONTEXT = 'SET_ACTIVE_CONTEXT';
export const RESET_ACTIVE_CONTEXT = 'RESET_ACTIVE_CONTEXT';

export const setActiveContextAction = context => ({
  type: SET_ACTIVE_CONTEXT,
  payload: context
});

export const resetActiveContextAction = () => ({
  type: RESET_ACTIVE_CONTEXT,
  payload: null
});
