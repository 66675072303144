import { getContextStruct } from '../../structs/context';
import {
  RESET_ACTIVE_CONTEXT,
  SET_ACTIVE_CONTEXT
} from '../actions/activeContext';

export default function activeContextReducer(
  state = getContextStruct(),
  action
) {
  switch (action.type) {
    case SET_ACTIVE_CONTEXT:
      return getContextStruct(action.payload);

    case RESET_ACTIVE_CONTEXT:
      return getContextStruct();

    default:
      return state;
  }
}
