import React, { Component } from 'react';
import { connect } from 'react-redux';
import PlanList from '../../components/Tracker/Plan/List';

export class PlanListContainer extends Component {
    render() {
        return <PlanList />;
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PlanList);
