// Journal Actions
export const SAVE_LOCAL_JOURNAL = 'SAVE_LOCAL_JOURNAL';
export const UPSERT_LOCAL_JOURNAL = 'UPSERT_LOCAL_JOURNAL';
export const REMOVE_LOCAL_JOURNAL = 'REMOVE_LOCAL_JOURNAL';
export const SET_LOCAL_JOURNALS = 'SET_LOCAL_JOURNALS';
export const UPDATE_JOURNAL_INDEX = 'UPDATE_JOURNAL_INDEX';
export const SET_JOURNAL_NEXT_TOKEN_ACTION = 'SET_JOURNAL_NEXT_TOKEN'
export const JOURNAL_CREATE_REQUEST_ACTION = 'JOURNAL_CREATE_REQUEST';
export const JOURNAL_CREATE_SUCCESS_ACTION = 'JOURNAL_CREATE_SUCCESS';
export const JOURNAL_DELETE_REQUEST_ACTION = 'JOURNAL_DELETE_REQUEST';
export const JOURNAL_DELETE_SUCCESS_ACTION = 'JOURNAL_DELETE_SUCCESS';
export const JOURNAL_UPDATE_REQUEST_ACTION = 'JOURNAL_UPDATE_REQUEST';
export const JOURNAL_UPDATE_SUCCESS_ACTION = 'JOURNAL_UPDATE_SUCCESS';
export const JOURNAL_MIGRATE_REQUEST_ACTION = 'JOURNAL_MIGRATE_REQUEST';
export const JOURNAL_MIGRATE_SUCCESS_ACTION = 'JOURNAL_MIGRATE_SUCCESS';
export const JOURNAL_TRACKER_FETCH_REQUEST_ACTION = 'JOURNAL_TRACKER_FETCH_REQUEST';
export const JOURNAL_DATA_LOAD_REQUEST_ACTION = 'JOURNAL_DATA_LOAD_REQUEST';
export const JOURNAL_DATA_LOAD_SUCCESS_ACTION = 'JOURNAL_DATA_LOAD_SUCCESS';
export const JOURNAL_FULL_SYNC_REQUEST_ACTION = 'JOURNAL_FULL_SYNC_REQUEST';
export const JOURNAL_FULL_SYNC_SUCCESS_ACTION = 'JOURNAL_FULL_SYNC_SUCCESS';

export const addJournalAction = journal => ({
  type: JOURNAL_CREATE_REQUEST_ACTION,
  payload: journal
});

export const setLocalJournalsAction = journalItems => ({
  type: JOURNAL_DATA_LOAD_REQUEST_ACTION,
  payload: journalItems
});

export const saveJournalAction = journal => ({
  type: JOURNAL_UPDATE_REQUEST_ACTION,
  payload: journal
});

export const upsertLocalJournalAction = journal => ({
  type: UPSERT_LOCAL_JOURNAL,
  payload: journal
});

export const updateJournalIndexAction = journal => ({
  type: UPDATE_JOURNAL_INDEX,
  payload: journal
});

export const migrateJournalAction = journal => ({
  type: JOURNAL_MIGRATE_REQUEST_ACTION,
  payload: journal
});

export const fetchTrackerJournalAction = ({ trackerId, nextToken }) => ({
  type: JOURNAL_TRACKER_FETCH_REQUEST_ACTION,
  payload: {
    trackerId,
    nextToken
  }
});

export const setJournalNextTokenAction = token => ({
  type: SET_JOURNAL_NEXT_TOKEN_ACTION,
  payload: token
});
