import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { initializeApplicationAction } from '../redux/actions/app';

export class App extends React.Component {
  // Here we fire off a one time per app load data fetch.
  componentDidMount() {
    // Fetch function from properties
    const { initializeApplication } = this.props;
    initializeApplication();
  }
  
  render() {
    const { children } = this.props;
    return <React.Fragment>{children}</React.Fragment>;
  }
}

// Define Default Props for ESLint
App.defaultProps = {};

// Define Default Prop Type for ESLint
App.propTypes = {
  initializeApplication: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired
};

// Map the functions to dispatch for actions
const mapDispatchToProps = dispatch => ({
  initializeApplication: () => {
    dispatch(initializeApplicationAction());
  }
});

const mapStateToProps = () => ({});

// Connect the dots for the App component
export default connect(mapStateToProps, mapDispatchToProps)(App);
