
// Role Actions
export const ROLES_SET_ACTIVE_ACTION = 'ROLES_SET_ACTIVE';
export const ROLES_SET_ROLES_ACTION = 'ROLES_SET_ROLES';

export const setRolesAction = (roles) => ({
  type: ROLES_SET_ROLES_ACTION,
  payload: roles
});

export const setActiveRoleAction = (role) => ({
  type: ROLES_SET_ACTIVE_ACTION,
  payload: role
});