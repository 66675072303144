import {getTrackerShareStruct} from '../../structs/trackerShare';

// Tracker Share Actions
export const UPSERT_LOCAL_TRACKER_SHARE_ACTION = 'UPSERT_LOCAL_TRACKER_SHARE';
export const REMOVE_LOCAL_TRACKER_SHARE_ACTION = 'REMOVE_LOCAL_TRACKER_SHARE';
export const SET_LOCAL_TRACKER_SHARE_ACTION = 'SET_LOCAL_TRACKER_SHARE';

// Saga Actions
export const TRACKER_SHARE_FETCH_REQUEST_ACTION = 'TRACKER_SHARE_FETCH_REQUEST';
export const TRACKER_SHARE_FETCH_SUCCESS_ACTION = 'TRACKER_SHARE_FETCH_SUCCESS';
export const TRACKER_SHARE_CREATE_REQUEST_ACTION = 'TRACKER_SHARE_CREATE_REQUEST';
export const TRACKER_SHARE_CREATE_SUCCESS_ACTION = 'TRACKER_SHARE_CREATE_SUCCESS';
export const TRACKER_SHARE_ACCEPT_REQUEST_ACTION = 'TRACKER_SHARE_ACCEPT_REQUEST';
export const TRACKER_SHARE_ACCEPT_SUCCESS_ACTION = 'TRACKER_SHARE_ACCEPT_SUCCESS';
export const TRACKER_SHARE_DECLINE_REQUEST_ACTION = 'TRACKER_SHARE_DECLINE_REQUEST';
export const TRACKER_SHARE_DECLINE_SUCCESS_ACTION = 'TRACKER_SHARE_DECLINE_SUCCESS';
export const TRACKER_SHARE_UPDATE_REQUEST_ACTION = 'TRACKER_SHARE_UPDATE_REQUEST';
export const TRACKER_SHARE_UPDATE_SUCCESS_ACTION = 'TRACKER_SHARE_UPDATE_SUCCESS';
export const TRACKER_SHARE_UPDATE_FAILURE_ACTION = 'TRACKER_SHARE_UPDATE_FAILURE';
export const TRACKER_SHARE_DELETE_REQUEST_ACTION = 'TRACKER_SHARE_DELETE_REQUEST';
export const TRACKER_SHARE_DELETE_SUCCESS_ACTION = 'TRACKER_SHARE_DELETE_SUCCESS';
export const TRACKER_SHARE_DATA_LOAD_REQUEST_ACTION = 'TRACKER_SHARE_DATA_LOAD_REQUEST';
export const TRACKER_SHARE_DATA_LOAD_SUCCESS_ACTION = 'TRACKER_SHARE_DATA_LOAD_SUCCESS';
export const TRACKER_SHARE_FULL_SYNC_REQUEST_ACTION = 'TRACKER_SHARE_FULL_SYNC_REQUEST';
export const TRACKER_SHARE_FULL_SYNC_SUCCESS_ACTION = 'TRACKER_SHARE_FULL_SYNC_SUCCESS';

export const fetchTrackerShareByTrackerAction = () => ({
  type: TRACKER_SHARE_DATA_LOAD_REQUEST_ACTION
});

export const addTrackerShareAction = trackerShare => ({
  type: TRACKER_SHARE_CREATE_REQUEST_ACTION,
  payload: getTrackerShareStruct(trackerShare)
});

export const saveTrackerShareAction = trackerShare => ({
  type: TRACKER_SHARE_UPDATE_REQUEST_ACTION,
  payload: getTrackerShareStruct(trackerShare)
});

export const upsertLocalTrackerShareAction = trackerShare => ({
  type: UPSERT_LOCAL_TRACKER_SHARE_ACTION,
  payload: getTrackerShareStruct(trackerShare)
});

export const deleteTrackerShareAction = trackerShare => ({
  type: TRACKER_SHARE_DELETE_REQUEST_ACTION,
  payload: trackerShare
});

export const setTrackerSharesAction = trackerShares => ({
  type: SET_LOCAL_TRACKER_SHARE_ACTION,
  payload: trackerShares
});

export const acceptTrackerShareAction = trackerShare => ({
  type: TRACKER_SHARE_ACCEPT_REQUEST_ACTION,
  payload: trackerShare
});

export const declineTrackerShareAction = trackerShare => ({
  type: TRACKER_SHARE_DECLINE_REQUEST_ACTION,
  payload: trackerShare
});
