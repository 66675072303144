import {
  ADD_TIMER,
  DELETE_TIMER,
  LOAD_TIMERS_FROM_DISK,
  SAVE_TIMER
} from '../actions/timers';

export default function timerReducer(oldstate = [], action) {
  let state;

  switch (action.type) {
    case ADD_TIMER:
      return [...oldstate, action.payload];

    case LOAD_TIMERS_FROM_DISK:
      return action.payload;

    case DELETE_TIMER:
      state = oldstate.filter(timer => timer.id !== action.payload);
      return state;

    case SAVE_TIMER:
      state = oldstate.map(timer => {
        if (action.payload.id === timer.id) {
          return action.payload;
        }
        return timer;
      });
      return state;

    default:
      return oldstate;
  }
}
