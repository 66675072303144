import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import {useDispatch} from 'react-redux';
import {TRACKER_STATUS_ACTIVE, TRACKER_STATUS_ARCHIVED} from '../../../structs/trackers';
import {saveTrackerAction} from '../../../redux/actions/trackers';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const ArchiveDialog = ({
  tracker,
  onDialog,
  open
}) => {

  const dispatch = useDispatch();

  const handleClose = () => {
    onDialog('');
  };

  const handleArchive = () => {
    onDialog('');
    dispatch(saveTrackerAction({
      ...tracker,
      status: TRACKER_STATUS_ARCHIVED
    }));
  };
  
  return tracker.status === TRACKER_STATUS_ACTIVE ? (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-archive-dialog-title"
      aria-describedby="alert-archive-dialog-description"
    >
      <DialogTitle id="alert-archive-dialog-title">
        Archive Tracker
        <IconButton
          style={{ position: 'absolute', right: 0, top: 0 }}
          onClick={handleClose}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent id="alert-archive-dialog-description">
        <DialogContentText>
          You are about to archive this tracker.
          Archived trackers don’t show on your Trackers to Review list on the Home Page or Trackers List.
          They also don’t count against your active trackers, so that is good.
        </DialogContentText>
        <DialogContentText>
          Do you really want to archive this tracker?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleArchive}
          color="primary"
          variant="contained"
          autoFocus
        >
          Archive
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

ArchiveDialog.defaultProps = {};

ArchiveDialog.propTypes = {
  tracker: PropTypes.object.isRequired,
  onDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default ArchiveDialog;
