import { takeLatest, put } from 'redux-saga/effects';
import {
  DIAGNOSTICS_HANDLE_ERROR_ACTION,
  DIAGNOSTICS_TRIGGER_ERROR_ACTION
} from '../actions/diagnostics';
import { setErrorAction } from '../actions/app';

function throwErrorForDiagnostics() {
  throw new Error('Error');
}

function* handleTriggerError() {
  yield throwErrorForDiagnostics();
}

function* handleError({ payload: error }) {
  console.log(error);
  yield put(setErrorAction(error));
}

export default function* diagnosticsSaga() {
  yield takeLatest(DIAGNOSTICS_TRIGGER_ERROR_ACTION, handleTriggerError);
  yield takeLatest(DIAGNOSTICS_HANDLE_ERROR_ACTION, handleError);
}
