import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import Chip from "@material-ui/core/Chip";
import UserIcon from "@material-ui/icons/AccountCircle";
import ChooseDialog from "./ChooseDialog";
import {getContactStruct} from "../../structs/contact";
import {getTrackerStruct} from "../../structs/trackers";
import {
  TRACKER_SHARE_PERMISSION_CONTRIBUTE,
  TRACKER_SHARE_STATUS_ACTIVE
} from "../../structs/trackerShare";
import {stringifyApn} from "../../utils/apn/v2";

const SelectContactButton = ({ onSelect, selectedContact, tracker }) => {
  const user = useSelector(store => store.user);
  const trackerShares = useSelector(store => store.trackerShares);
  const [open, setOpen] = useState(false);
  const [allowedContacts, setAllowedContacts] = useState([]);

  useEffect(() => {
    if (tracker.id && trackerShares.length > 0) {
      const isTrackerOwner = tracker.ownerApn === stringifyApn({ userId: user.id });
      const share = trackerShares.find(s => tracker.id === s.userId === user.id);
      const canContribute = share && share.id && share.permissionLevel === TRACKER_SHARE_PERMISSION_CONTRIBUTE;
      setAllowedContacts(trackerShares
        .filter(s => (tracker.id === s.trackerId && s.status === TRACKER_SHARE_STATUS_ACTIVE) || isTrackerOwner || canContribute)
        .map(s => s.emailAddress)
      );
    }
  }, [tracker.id, trackerShares, user.id, tracker.ownerApn]);

  return (
    <>
      <Chip
        variant={"outlined"}
        onClick={() => setOpen(true)}
        avatar={<UserIcon fontSize={"small"} />}
        label={!selectedContact.id ? `Select Exist Contact` : selectedContact.friendName }
        onDelete={selectedContact.id ? () => onSelect(getContactStruct()) : null}
      />
      <ChooseDialog setOpen={setOpen} open={open} onSelect={onSelect} selectedContact={selectedContact} allowedContacts={allowedContacts} />
    </>
  );
};

SelectContactButton.defaultProps = {
  selectedContact: getContactStruct(),
  tracker: getTrackerStruct()
};

SelectContactButton.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selectedContact: PropTypes.object,
  tracker: PropTypes.object
};

export default SelectContactButton;
