import moment from 'moment';

import { UPDATE_COMMITMENT_INDEX } from '../actions/commitments';
import { CommitmentIndexStruct } from '../../structs/indexers';

export default function commitmentIndexReducer(
  oldstate = Object.assign({}, CommitmentIndexStruct),
  action
) {
  switch (action.type) {
    case UPDATE_COMMITMENT_INDEX:
      return Object.assign({}, action.payload, {
        updatedAt: moment().format()
      });

    default:
      return oldstate;
  }
}
