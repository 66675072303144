import React, { Component } from 'react';
import { connect } from 'react-redux';
import CommitButton from '../../components/Commitment/CommitButton';
import {setActiveContextAction} from '../../redux/actions/activeContext';

export class CommitButtonContainer extends Component {
  render() {
    return <CommitButton />;
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  setActiveContext: context => {
    dispatch(setActiveContextAction(context));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CommitButton);
