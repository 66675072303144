import {
  all,
  take,
  put,
  select,
  takeEvery,
  takeLatest,
  delay,
  debounce
} from 'redux-saga/effects';

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import sync from '../../utils/backup/sync';
import {
  addChangeLogAction,
  CHANGE_LOG_ADD_REQUEST_ACTION,
  CHANGE_LOG_ADD_SUCCESS_ACTION,
  CHANGE_LOG_DATA_LOAD_REQUEST_ACTION,
  CHANGE_LOG_DATA_LOAD_SUCCESS_ACTION,
  CHANGE_LOG_LOAD_ALL,
  CHANGE_LOG_PERSIST_REQUEST_ACTION,
  CHANGE_LOG_PERSIST_SUCCESS_ACTION,
  CHANGE_LOG_REALTIME_REQUEST_ACTION,
  CHANGE_LOG_REMOVE_REQUEST_ACTION,
  CHANGE_LOG_REMOVE_SUCCESS_ACTION,
  CHANGE_LOG_SYNC_DISABLED_ACTION,
  CHANGE_LOG_SYNC_REQUEST_ACTION,
  CHANGE_LOG_SYNC_SUCCESS_ACTION,
  loadChangeLogAction,
  removeChangeLogAction, syncChangeLogAction
} from '../actions/changeLogs';
import { setProcessingAction } from '../actions/notifications';
import {parseApn, stringifyApn} from '../../utils/apn/v2';
import {
  createRemoteChangeLog,
  fetchRemoteChangeLogs,
  prepAndPruneCommitmentForAppSync, prepAndPruneForAppSync,
} from '../../utils/appSync';
import {offerSyncAction, setLastSyncAction} from '../actions/sync';
import { INTERNET_STATUS_CONNECTED } from '../../structs/notification';
import {
  APPLICATION_DATA_CLEAR_REQUEST_ACTION,
  APPLICATION_DATA_REQUEST_ACTION, APPLICATION_SET_DESIRED_ROUTE_ACTION, setSyncingAction
} from '../actions/app';
import { loadFromStorage } from '../../utils/localStorage';
import { USER_STATUS_LOGGED_IN } from '../../structs/user';
import {
  COMMITMENT_DELETE_REMOTE_SUCCESS_ACTION,
  COMMITMENT_UPSERT_REMOTE_SUCCESS_ACTION,
  JOURNAL_DELETE_REMOTE_SUCCESS_ACTION,
  JOURNAL_UPSERT_REMOTE_SUCCESS_ACTION,
  MILESTONE_DELETE_REMOTE_SUCCESS_ACTION,
  MILESTONE_UPSERT_REMOTE_SUCCESS_ACTION,
  TRACKER_DELETE_REMOTE_SUCCESS_ACTION,
  TRACKER_UPSERT_REMOTE_SUCCESS_ACTION
} from '../actions/appSync';
import { getChangeLogStruct } from '../../structs/changeLogs';
import {
  REMOVE_LOCAL_TRACKER,
  UPSERT_LOCAL_TRACKER
} from '../actions/trackers';
import {
  REMOVE_LOCAL_MILESTONE,
  UPSERT_LOCAL_MILESTONE
} from '../actions/milestones';
import {
  REMOVE_LOCAL_COMMITMENT,
  UPSERT_LOCAL_COMMITMENT
} from '../actions/commitments';
import { REMOVE_LOCAL_JOURNAL, UPSERT_LOCAL_JOURNAL } from '../actions/journal';
import {prepAndPruneMilestoneForAppSync} from '../../utils/appSync/milestone';
import {prepAndPruneTrackerForAppSync} from '../../utils/appSync/tracker';
import {prepAndPruneJournalForAppSync} from "../../utils/appSync/journal";
import {
  REMOVE_LOCAL_TRACKER_SHARE_ACTION, TRACKER_SHARE_CREATE_SUCCESS_ACTION, TRACKER_SHARE_DELETE_SUCCESS_ACTION,
  TRACKER_SHARE_UPDATE_SUCCESS_ACTION, UPSERT_LOCAL_TRACKER_SHARE_ACTION
} from "../actions/trackerShares";
import {prepAndPruneTrackerShareForAppSync} from "../../utils/appSync/trackerShare";
import routes from '../../constants/routes.json';
import {
  COMMIT_INVITE_CREATE_SUCCESS_ACTION,
  COMMIT_INVITE_DELETE_SUCCESS_ACTION, COMMIT_INVITE_REMOVE_LOCAL_ACTION,
  COMMIT_INVITE_UPSERT_LOCAL_ACTION, COMMIT_INVITE_UPSERT_SUCCESS_ACTION
} from "../actions/commitInvite";
import {getCommitInviteStruct} from "../../structs/commitInvite";

function* handleRealTimeChangeLogRequest({ payload: changeLog }) {
  const syncVersion = yield select(store => store.syncVersion);

  // Handle Change Logs that come from Real-time Data Updates

  if (changeLog.source !== syncVersion.deviceId) {
    yield put({
      type: changeLog.action,
      payload: JSON.parse(changeLog.data)
    });
  }

  yield put(setLastSyncAction(moment(changeLog.timestamp).toISOString()));
}

function* handleCreateChangeLogRequest({payload: changeLog}) {
  const backupProfile = yield select(store => store.backupProfile);

  // If there is no verified backup profile, do nothing.
  if (!backupProfile.verifiedAt || !backupProfile.autoSyncEnabled) {
    // Signal Disabled
    yield put({
      type: CHANGE_LOG_SYNC_DISABLED_ACTION
    });
    return;
  }

  const user = yield select(store => store.user);
  const syncVersion = yield select(store => store.syncVersion);


  // Persist ChangeLog to Local Data Store
  // Add delay to make sure that the timestamps are not the same
  yield delay(10);
  const changeId = uuidv4();
  const timestamp = moment().toISOString();
  yield put(
    addChangeLogAction(
      Object.assign({}, changeLog, {
        changeId,
        source: syncVersion.deviceId,
        timestamp,
        ownerApn: changeLog.ownerApn || stringifyApn({ userId: user.id })
      })
    )
  );

  // Trigger Sync Event
  sync();

  // Signal Complete
  yield put({
    type: CHANGE_LOG_ADD_SUCCESS_ACTION
  });
}

function* handleRemoveChangeLogRequest(action) {
  // Persist ChangeLog to Local Data Store
  yield put(removeChangeLogAction(action.payload));

  // Signal Complete
  yield put({
    type: CHANGE_LOG_REMOVE_SUCCESS_ACTION
  });
}

function* handleSyncChangeLogRequest() {
  // Perform Sync Operations

  // Check for Internet Connection
  const internetStatus = yield select(store => store.internetStatus);
  if (internetStatus.status !== INTERNET_STATUS_CONNECTED) return;

  const user = yield select(store => store.user);
  if (user.status !== USER_STATUS_LOGGED_IN) return;
  
  const syncVersion = yield select(store => store.syncVersion);
  if (syncVersion.lastSync === 'initial') {
    yield put(offerSyncAction());
  }
  
  // If there is no verified backup profile, do nothing.
  const backupProfile = yield select(store => store.backupProfile);
  if (!backupProfile.verifiedAt || !backupProfile.autoSyncEnabled) {
    // Signal Disabled
    yield put({
      type: CHANGE_LOG_SYNC_DISABLED_ACTION
    });
    return;
  }
  const syncing = yield select(store => store.app.syncing);

  if (syncing) {
    return;
  }

  yield put(setSyncingAction(true));

  yield put(setProcessingAction('Checking for latest data...'));

  // Fetch all remote Change Logs
  const remoteChangeLogs = yield fetchRemoteChangeLogs(
    stringifyApn({ userId: user.id }),
    syncVersion.lastSync
  );

  for (const changeLog of remoteChangeLogs) {
    // If the source is this device, then acknowledge and delete changelog
    if (changeLog.source === syncVersion.deviceId) {
      yield put({
        type: CHANGE_LOG_REMOVE_REQUEST_ACTION,
        payload: changeLog
      });

      yield take(CHANGE_LOG_REMOVE_SUCCESS_ACTION);
    } else {
      yield put({
        type: changeLog.action,
        payload: JSON.parse(changeLog.data)
      });
    }
  }

  const mutableList = [...remoteChangeLogs];
  const lastRemoteChangeLog = mutableList.pop();

  yield put({ type: CHANGE_LOG_PERSIST_REQUEST_ACTION });

  const lastLocalChangeLog = yield take(CHANGE_LOG_PERSIST_SUCCESS_ACTION);

  if (
    lastRemoteChangeLog &&
    moment(lastRemoteChangeLog.timestamp).isAfter(syncVersion.lastSync)
  )
    yield put(
      setLastSyncAction(moment(lastRemoteChangeLog.timestamp).toISOString())
    );
  else if (
    lastLocalChangeLog &&
    moment(lastLocalChangeLog.timestamp).isAfter(syncVersion.lastSync)
  )
    yield put(setLastSyncAction(moment(lastLocalChangeLog.timestamp).toISOString()));
  else if (
    lastLocalChangeLog &&
    moment(lastLocalChangeLog.timestamp).isBefore(syncVersion.lastSync)
  )
    yield put(loadChangeLogAction([]));

  // If we had remote set, let's run the sync one more time to catch anything else
  if (remoteChangeLogs.length) {
    yield put(setSyncingAction(false));
    yield put(syncChangeLogAction());

    return;
  }

  // Signal Complete
  yield put({
    type: CHANGE_LOG_SYNC_SUCCESS_ACTION
  });


  yield put(setSyncingAction(false));

  yield put(setProcessingAction(''));
}

function* handleChangeLogDataLoadRequest(action) {
  // Load all change logs to the store
  yield put({
    type: CHANGE_LOG_LOAD_ALL,
    payload: action.payload
  });

  yield put({ type: CHANGE_LOG_DATA_LOAD_SUCCESS_ACTION });
}

function* handleChangeLogPersist() {
  // Fetch all local Change Logs
  const localChangeLogs = yield select(store => store.changeLogs);
  const mutableList = [...localChangeLogs];

  if (!localChangeLogs.length) {
    yield put({ type: CHANGE_LOG_PERSIST_SUCCESS_ACTION, payload: null });
    return;
  }

  for (const changeLog of localChangeLogs) {
    const newLog = yield createRemoteChangeLog(changeLog);
    if (newLog) {
      yield put(removeChangeLogAction(changeLog));
    }
  }

  const lastLog = mutableList.pop();

  yield put({ type: CHANGE_LOG_PERSIST_SUCCESS_ACTION, payload: lastLog });
}

function* handleLoadApplicationDataRequest() {
  const type = 'changeLogs';
  // Perform Application Data Load
  const changeLogsState = yield select(store => store.changeLogs);

  // Call the Util Local Storage loadState function which pulls data
  // from the local storage if cached there, if not it pulls from the disk
  const changeLogs = yield loadFromStorage(type, changeLogsState);

  yield put(loadChangeLogAction(changeLogs || []));
}

function* handleStateChange() {
  const type = 'changeLogs';

  // Select data from the store
  const data = yield select(store => store[type]);
  const dataString = JSON.stringify(data);

  // As well as in the local storage.
  localStorage.setItem(type, dataString);
}

function* handleApplicationDataClear() {
  yield put(loadChangeLogAction([]));
}

function* handleTrackerUpsert({payload: tracker}) {
  const trackerShares = yield select(store => store.trackerShares);
  const user = yield select(store => store.user);
  const shares = trackerShares.filter(share => share.trackerId === tracker.id);

  // Persist Change Log for other my other devices
  const changeLogs = [];
  changeLogs.push(put({
    type: CHANGE_LOG_ADD_REQUEST_ACTION,
    payload: getChangeLogStruct({
      action: UPSERT_LOCAL_TRACKER,
      data: prepAndPruneTrackerForAppSync(tracker)
    })
  }));

  // Change logs for those that I am sharing the tracker with
  shares.forEach(share => {
    changeLogs.push(put({
      type: CHANGE_LOG_ADD_REQUEST_ACTION,
      payload: getChangeLogStruct({
        action: UPSERT_LOCAL_TRACKER,
        data: prepAndPruneTrackerForAppSync(tracker),
        ownerApn: stringifyApn({ userId: share.userId })
      })
    }));
  });

  // Change log for those the owner of the tracker if it's someone else
  if (tracker.ownerApn !== stringifyApn({ userId: user.id })) {
    changeLogs.push(put({
      type: CHANGE_LOG_ADD_REQUEST_ACTION,
      payload: getChangeLogStruct({
        action: UPSERT_LOCAL_TRACKER,
        data: prepAndPruneTrackerForAppSync(tracker),
        ownerApn: tracker.ownerApn
      })
    }));
  }

  yield all(changeLogs);
}

function* handleTrackerDelete({payload: tracker}) {
  const trackerShares = yield select(store => store.trackerShares);
  const shares = trackerShares.filter(share => share.trackerId === tracker.id);
  // Persist Change Log
  const changeLogs = [];
  changeLogs.push(put({
    type: CHANGE_LOG_ADD_REQUEST_ACTION,
    payload: getChangeLogStruct({
      action: REMOVE_LOCAL_TRACKER,
      data: prepAndPruneTrackerForAppSync(tracker)
    })
  }));

  shares.forEach(share => {
    changeLogs.push(put({
      type: CHANGE_LOG_ADD_REQUEST_ACTION,
      payload: getChangeLogStruct({
        action: REMOVE_LOCAL_TRACKER,
        data: prepAndPruneTrackerForAppSync(tracker),
        ownerApn: stringifyApn({ userId: share.userId })
      })
    }));
  });

  yield all(changeLogs);
}

function* handleMilestoneUpsert({ payload: milestone }) {
  const user = yield select(store => store.user);
  const trackerShares = yield select(store => store.trackerShares);
  const shares = trackerShares.filter(share => share.trackerId === milestone.trackerId);
  // Persist Change Log
  const changeLogs = [];
  changeLogs.push(put({
    type: CHANGE_LOG_ADD_REQUEST_ACTION,
    payload: getChangeLogStruct({
      action: UPSERT_LOCAL_MILESTONE,
      data: prepAndPruneMilestoneForAppSync(milestone)
    })
  }));

  shares.forEach(share => {
    changeLogs.push(put({
      type: CHANGE_LOG_ADD_REQUEST_ACTION,
      payload: getChangeLogStruct({
        action: UPSERT_LOCAL_MILESTONE,
        data: prepAndPruneMilestoneForAppSync(milestone),
        ownerApn: stringifyApn({ userId: share.userId })
      })
    }));
  });

  // Change log for those the owner of the tracker if it's someone else
  const { userId } = parseApn(milestone.trackerApn)
  if (userId !== user.id) {
    changeLogs.push(put({
      type: CHANGE_LOG_ADD_REQUEST_ACTION,
      payload: getChangeLogStruct({
        action: UPSERT_LOCAL_MILESTONE,
        data: prepAndPruneMilestoneForAppSync(milestone),
        ownerApn: stringifyApn({ userId })
      })
    }));
  }

  yield all(changeLogs);
}

function* handleMilestoneDelete({ payload: milestone }) {
  const user = yield select(store => store.user);
  const trackerShares = yield select(store => store.trackerShares);
  const shares = trackerShares.filter(share => share.trackerId === milestone.trackerId);

  // Persist Change Log
  const changeLogs = [];
  changeLogs.push(put({
    type: CHANGE_LOG_ADD_REQUEST_ACTION,
    payload: getChangeLogStruct({
      action: REMOVE_LOCAL_MILESTONE,
      data: prepAndPruneMilestoneForAppSync(milestone)
    })
  }));

  shares.forEach(share => {
    changeLogs.push(put({
      type: CHANGE_LOG_ADD_REQUEST_ACTION,
      payload: getChangeLogStruct({
        action: REMOVE_LOCAL_MILESTONE,
        data: prepAndPruneMilestoneForAppSync(milestone),
        ownerApn: stringifyApn({ userId: share.userId })
      })
    }));
  });

  // Change log for those the owner of the tracker if it's someone else
  const { userId } = parseApn(milestone.trackerApn)
  if (userId !== user.id) {
    changeLogs.push(put({
      type: CHANGE_LOG_ADD_REQUEST_ACTION,
      payload: getChangeLogStruct({
        action: UPSERT_LOCAL_MILESTONE,
        data: prepAndPruneMilestoneForAppSync(milestone),
        ownerApn: stringifyApn({ userId })
      })
    }));
  }

  yield all(changeLogs);
}

function* handleCommitmentUpsert(action) {
  // Persist Change Log
  yield put({
    type: CHANGE_LOG_ADD_REQUEST_ACTION,
    payload: getChangeLogStruct({
      action: UPSERT_LOCAL_COMMITMENT,
      data: prepAndPruneCommitmentForAppSync(action.payload)
    })
  });
}

function* handleCommitmentDelete(action) {
  // Persist Change Log
  yield put({
    type: CHANGE_LOG_ADD_REQUEST_ACTION,
    payload: getChangeLogStruct({
      action: REMOVE_LOCAL_COMMITMENT,
      data: prepAndPruneCommitmentForAppSync(action.payload)
    })
  });
}

function* handleJournalUpsert({ payload: journal }) {
  const user = yield select(store => store.user);
  const trackerShares = yield select(store => store.trackerShares);
  const shares = trackerShares.filter(share => share.trackerId === journal.trackerId);
  const { byId: { [journal.trackerId]: trackerDoc }} = yield select(store => store.trackerIndex);

  const changeLogs = [];

  // Persist Change Log
  changeLogs.push(put({
    type: CHANGE_LOG_ADD_REQUEST_ACTION,
    payload: getChangeLogStruct({
      action: UPSERT_LOCAL_JOURNAL,
      data: prepAndPruneJournalForAppSync(journal)
    })
  }));

  // Change logs for those that I am sharing the tracker with, but not me if I am one.
  shares.forEach(share => {
    if (share.userId !== user.id) {
      changeLogs.push(put({
        type: CHANGE_LOG_ADD_REQUEST_ACTION,
        payload: getChangeLogStruct({
          action: UPSERT_LOCAL_JOURNAL,
          data: prepAndPruneJournalForAppSync(journal),
          ownerApn: stringifyApn({ userId: share.userId })
        })
      }));
    }
  });

  if (trackerDoc) {
    const { entity: tracker } = trackerDoc;
    // Change log for those the owner of the tracker if it's someone else
    if (tracker.ownerApn !== stringifyApn({ userId: user.id })) {
      changeLogs.push(put({
        type: CHANGE_LOG_ADD_REQUEST_ACTION,
        payload: getChangeLogStruct({
          action: UPSERT_LOCAL_JOURNAL,
          data: prepAndPruneJournalForAppSync(journal),
          ownerApn: tracker.ownerApn
        })
      }));
    }
  }

  yield all(changeLogs);
}

function* handleJournalDelete(action) {
  // Persist Change Log
  yield put({
    type: CHANGE_LOG_ADD_REQUEST_ACTION,
    payload: getChangeLogStruct({
      action: REMOVE_LOCAL_JOURNAL,
      data: prepAndPruneJournalForAppSync(action.payload)
    })
  });
}

function* handleShareUpdate({ payload: share }) {
  const user = yield select(store => store.user);
  // Persist Change Log
  yield put({
    type: CHANGE_LOG_ADD_REQUEST_ACTION,
    payload: getChangeLogStruct({
      action: UPSERT_LOCAL_TRACKER_SHARE_ACTION,
      data: prepAndPruneTrackerShareForAppSync(share)
    })
  });

  // Push update to shared user
  if (share.userId.trim() && user.id !== share.userId) {
    yield put({
      type: CHANGE_LOG_ADD_REQUEST_ACTION,
      payload: getChangeLogStruct({
        action: UPSERT_LOCAL_TRACKER_SHARE_ACTION,
        data: prepAndPruneTrackerShareForAppSync(share),
        ownerApn: stringifyApn({ userId: share.userId })
      })
    });
  }

  // Push update to owner
  if (share.userId.trim() && user.id === share.userId) {
    yield put({
      type: CHANGE_LOG_ADD_REQUEST_ACTION,
      payload: getChangeLogStruct({
        action: UPSERT_LOCAL_TRACKER_SHARE_ACTION,
        data: prepAndPruneTrackerShareForAppSync(share),
        ownerApn: share.ownerApn
      })
    });
  }
}

function* handleShareDelete({ payload: share }) {
  const { byId: { [share.trackerId]: trackerDoc }} = yield select(store => store.trackerIndex);
  const user = yield select(store => store.user);

  // Persist Change Log
  yield put({
    type: CHANGE_LOG_ADD_REQUEST_ACTION,
    payload: getChangeLogStruct({
      action: REMOVE_LOCAL_TRACKER_SHARE_ACTION,
      data: prepAndPruneTrackerShareForAppSync(share)
    })
  });

  if (share.userId.trim() && user.id !== share.userId) {
    yield put({
      type: CHANGE_LOG_ADD_REQUEST_ACTION,
      payload: getChangeLogStruct({
        action: REMOVE_LOCAL_TRACKER_SHARE_ACTION,
        data: prepAndPruneTrackerShareForAppSync(share),
        ownerApn: stringifyApn({ userId: share.userId })
      })
    });

    // Remove the tracker from the users local data store
    if (trackerDoc) {
      const { entity: tracker } = trackerDoc;
      yield put({
        type: CHANGE_LOG_ADD_REQUEST_ACTION,
        payload: getChangeLogStruct({
          action: REMOVE_LOCAL_TRACKER,
          data: prepAndPruneTrackerForAppSync(tracker),
          ownerApn: stringifyApn({ userId: share.userId })
        })
      });
      yield put({
        type: CHANGE_LOG_ADD_REQUEST_ACTION,
        payload: getChangeLogStruct({
          action: APPLICATION_SET_DESIRED_ROUTE_ACTION,
          data: routes.HOME,
          ownerApn: stringifyApn({ userId: share.userId })
        })
      });
    }
  }
}

function* handleInviteUpdate({ payload: invite }) {
  const user = yield select(store => store.user);
  const userApn = stringifyApn({ userId: user.id });
  const data = prepAndPruneForAppSync(invite, getCommitInviteStruct);

  // Persist Change Log
  yield put({
    type: CHANGE_LOG_ADD_REQUEST_ACTION,
    payload: getChangeLogStruct({
      action: COMMIT_INVITE_UPSERT_LOCAL_ACTION,
      data
    })
  });

  // Push update to invited user
  if (userApn !== invite.toUserApn) {
    yield put({
      type: CHANGE_LOG_ADD_REQUEST_ACTION,
      payload: getChangeLogStruct({
        action: COMMIT_INVITE_UPSERT_LOCAL_ACTION,
        data,
        ownerApn: invite.toUserApn
      })
    });
  }

  // Push update to owner
  if (userApn === invite.toUserApn) {
    yield put({
      type: CHANGE_LOG_ADD_REQUEST_ACTION,
      payload: getChangeLogStruct({
        action: COMMIT_INVITE_UPSERT_LOCAL_ACTION,
        data,
        ownerApn: invite.fromUserApn
      })
    });
  }
}

function* handleInviteDelete({ payload: invite }) {
  const user = yield select(store => store.user);
  const userApn = stringifyApn({ userId: user.id });
  const data = prepAndPruneForAppSync(invite, getCommitInviteStruct);

  // Persist Change Log
  yield put({
    type: CHANGE_LOG_ADD_REQUEST_ACTION,
    payload: getChangeLogStruct({
      action: COMMIT_INVITE_REMOVE_LOCAL_ACTION,
      data
    })
  });

  if (userApn !== invite.toUserApn) {
    yield put({
      type: CHANGE_LOG_ADD_REQUEST_ACTION,
      payload: getChangeLogStruct({
        action: COMMIT_INVITE_REMOVE_LOCAL_ACTION,
        data,
        ownerApn: invite.toUserApn
      })
    });
  }
}

function* handleCreateChangeLogSuccess() {
  yield put(syncChangeLogAction())
}

export default function* changeLogSaga() {
  yield takeEvery(CHANGE_LOG_REALTIME_REQUEST_ACTION, handleRealTimeChangeLogRequest);
  yield takeEvery(CHANGE_LOG_ADD_REQUEST_ACTION, handleCreateChangeLogRequest);
  yield takeEvery(
    CHANGE_LOG_REMOVE_REQUEST_ACTION,
    handleRemoveChangeLogRequest
  );
  yield takeEvery(
    CHANGE_LOG_DATA_LOAD_REQUEST_ACTION,
    handleChangeLogDataLoadRequest
  );
  yield takeEvery(CHANGE_LOG_SYNC_REQUEST_ACTION, handleSyncChangeLogRequest);
  yield takeLatest(CHANGE_LOG_PERSIST_REQUEST_ACTION, handleChangeLogPersist);
  yield debounce(250, CHANGE_LOG_ADD_SUCCESS_ACTION, handleCreateChangeLogSuccess);
  yield takeLatest(CHANGE_LOG_ADD_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(CHANGE_LOG_REMOVE_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(
    APPLICATION_DATA_REQUEST_ACTION,
    handleLoadApplicationDataRequest
  );
  yield takeLatest(CHANGE_LOG_DATA_LOAD_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(
    APPLICATION_DATA_CLEAR_REQUEST_ACTION,
    handleApplicationDataClear
  );

  // Tracker Change Logs
  yield takeEvery(TRACKER_UPSERT_REMOTE_SUCCESS_ACTION, handleTrackerUpsert);
  yield takeEvery(TRACKER_DELETE_REMOTE_SUCCESS_ACTION, handleTrackerDelete);

  // Milestone Change Logs
  yield takeEvery(
    MILESTONE_UPSERT_REMOTE_SUCCESS_ACTION,
    handleMilestoneUpsert
  );
  yield takeEvery(
    MILESTONE_DELETE_REMOTE_SUCCESS_ACTION,
    handleMilestoneDelete
  );

  // Commitment Change Logs
  yield takeEvery(
    COMMITMENT_UPSERT_REMOTE_SUCCESS_ACTION,
    handleCommitmentUpsert
  );
  yield takeEvery(
    COMMITMENT_DELETE_REMOTE_SUCCESS_ACTION,
    handleCommitmentDelete
  );

  // Journal Change Logs
  yield takeEvery(JOURNAL_UPSERT_REMOTE_SUCCESS_ACTION, handleJournalUpsert);
  yield takeEvery(JOURNAL_DELETE_REMOTE_SUCCESS_ACTION, handleJournalDelete);

  // Tracker Share Logs
  yield takeEvery(TRACKER_SHARE_CREATE_SUCCESS_ACTION, handleShareUpdate);
  yield takeEvery(TRACKER_SHARE_UPDATE_SUCCESS_ACTION, handleShareUpdate);
  yield takeEvery(TRACKER_SHARE_DELETE_SUCCESS_ACTION, handleShareDelete);

  // Invites
  yield takeEvery(COMMIT_INVITE_CREATE_SUCCESS_ACTION, handleInviteUpdate);
  yield takeEvery(COMMIT_INVITE_UPSERT_SUCCESS_ACTION, handleInviteUpdate);
  yield takeEvery(COMMIT_INVITE_DELETE_SUCCESS_ACTION, handleInviteDelete);
}
