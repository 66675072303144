import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTourCompleteAction } from '../redux/actions/tour';
import Tour from '../components/Tour/Tour';

export class TourContainer extends Component {
  render() {
    return <Tour />;
  }
}

const mapStateToProps = state => ({
  tour: state.tour
});

const mapDispatchToProps = dispatch => ({
  setTourComplete: tour => {
    dispatch(setTourCompleteAction(tour));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Tour);
