import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import routes from '../../constants/routes.json';

export const style = () => ({
  container: {
    width: 'calc(100vw - 68px)',
    display: 'flex',
    flexDirection: 'row',
    marginTop: 23
  }
});

class LearnPages extends Component {
  state = {
    tableData: []
  };

  componentDidMount() {
    const { learnPages } = this.props;
    const { tableData } = this.state;
    if (learnPages.length !== tableData.length) {
      this.indexPages();
    }
  }

  componentDidUpdate() {
    const { learnPages } = this.props;
    const { tableData } = this.state;
    if (learnPages.length !== tableData.length) {
      this.indexPages();
    }
  }

  indexPages = () => {
    const { learnPages } = this.props;
    const tableData = learnPages.map(page => [
      page.id,
      page.title ? page.title : 'No Title'
    ]);

    this.setState({
      tableData
    });
  };

  handleNavigate = pageId => {
    const { history } = this.props;
    history.push(`${routes.LEARN_PAGE_BASE}${pageId}`);
  };

  render() {
    const { tableData } = this.state;
    const { classes } = this.props;

    const tableColumns = [
      { name: 'id', options: { display: 'excluded', filter: false } },
      { name: 'Page Title', options: { filter: false } }
    ];

    const tableOptions = {
      download: false,
      print: false,
      filter: true,
      selectableRows: false,
      viewColumns: false,
      responsive: 'scroll',
      onRowClick: rowData => {
        this.handleNavigate(rowData[0]);
      }
    };

    return (
      <div className={classes.container}>
        <MUIDataTable
          title="Learn Pages"
          data={tableData}
          columns={tableColumns}
          options={tableOptions}
        />
      </div>
    );
  }
}

LearnPages.propTypes = {
  history: PropTypes.any.isRequired,
  learnPages: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(style)(LearnPages));
