import moment from 'moment';

import { UPDATE_MILESTONE_INDEX } from '../actions/milestones';
import { MilestoneIndexStruct } from '../../structs/indexers';

export default function milestoneIndexReducer(
    oldstate = Object.assign({}, MilestoneIndexStruct),
    action
) {
    switch (action.type) {
        case UPDATE_MILESTONE_INDEX:
            return Object.assign({}, action.payload, {
                updatedAt: moment().format()
            });
        
        default:
            return oldstate;
    }
}
