import { UserStruct } from '../../../structs/user';

export const stringifyApn = apn => {
  const { userId, trackerId, milestoneId } = apn;
  if (milestoneId)
    return `apn:${userId}:tracker:${trackerId}:milestone/${milestoneId}`;
  return `apn:${userId}:tracker:${trackerId}`;
};

export const parseApn = apn => {
  const [apnType, userId, trackerLabel, trackerId, path] = apn.split(':');

  let milestoneId;
  let milestoneLabel;

  if (path) [milestoneLabel, milestoneId] = path.split('/');

  return {
    apnType,
    userId,
    trackerLabel,
    trackerId,
    path,
    milestoneId,
    milestoneLabel
  };
};

export const getTrackerAPN = (id, user = UserStruct) => `apn:${user.id}`;

export const getMilestoneAPN = (id, tracker = { apn: '' }) =>
  `${tracker.apn}:milestone/${id}`;
