// Sync Actions
export const APPLICATION_FULL_SYNC_REQUEST_ACTION = 'APP_FULL_SYNC_REQUEST';
export const APPLICATION_FULL_SYNC_SUCCESS_ACTION = 'APP_FULL_SYNC_SUCCESS';
export const APPLICATION_QUICK_SYNC_REQUEST_ACTION = 'APP_QUICK_SYNC_REQUEST';
export const APPLICATION_QUICK_SYNC_SUCCESS_ACTION = 'APP_QUICK_SYNC_SUCCESS';
export const VERIFY_APP_SYNC_REQUEST_ACTION = 'VERIFY_APP_SYNC_REQUEST';
export const VERIFY_APP_SYNC_SUCCESS_ACTION = 'VERIFY_APP_SYNC_SUCCESS';
export const VERIFY_APP_SYNC_FAILURE_ACTION = 'VERIFY_APP_SYNC_FAILURE';
export const TRACKER_UPSERT_REMOTE_ACTION = 'TRACKER_UPSERT_REMOTE';
export const TRACKER_UPSERT_REMOTE_SUCCESS_ACTION =
  'TRACKER_UPSERT_REMOTE_SUCCESS';
export const TRACKER_DELETE_REMOTE_ACTION = 'TRACKER_DELETE_REMOTE';
export const TRACKER_DELETE_REMOTE_SUCCESS_ACTION =
  'TRACKER_DELETE_REMOTE_SUCCESS';
export const MILESTONE_UPSERT_REMOTE_ACTION = 'MILESTONE_UPSERT_REMOTE';
export const MILESTONE_UPSERT_REMOTE_SUCCESS_ACTION =
  'MILESTONE_UPSERT_REMOTE_SUCCESS';
export const MILESTONE_DELETE_REMOTE_ACTION = 'MILESTONE_DELETE_REMOTE';
export const MILESTONE_DELETE_REMOTE_SUCCESS_ACTION =
  'MILESTONE_DELETE_REMOTE_SUCCESS';
export const COMMITMENT_UPSERT_REMOTE_ACTION = 'COMMITMENT_UPSERT_REMOTE';
export const COMMITMENT_UPSERT_REMOTE_SUCCESS_ACTION =
  'COMMITMENT_UPSERT_REMOTE_SUCCESS';
export const COMMITMENT_DELETE_REMOTE_ACTION = 'COMMITMENT_DELETE_REMOTE';
export const COMMITMENT_DELETE_REMOTE_SUCCESS_ACTION =
  'COMMITMENT_DELETE_REMOTE_SUCCESS';
export const JOURNAL_UPSERT_REMOTE_ACTION = 'JOURNAL_UPSERT_REMOTE';
export const JOURNAL_UPSERT_REMOTE_SUCCESS_ACTION =
  'JOURNAL_UPSERT_REMOTE_SUCCESS';
export const JOURNAL_DELETE_REMOTE_ACTION = 'JOURNAL_DELETE_REMOTE';
export const JOURNAL_DELETE_REMOTE_SUCCESS_ACTION =
  'JOURNAL_DELETE_REMOTE_SUCCESS';
export const TOUR_UPSERT_REMOTE_ACTION = 'TOUR_UPSERT_REMOTE';
export const TOUR_UPSERT_REMOTE_SUCCESS_ACTION = 'TOUR_UPSERT_REMOTE_SUCCESS';
export const CHECKLIST_UPSERT_REMOTE_ACTION = 'CHECKLIST_UPSERT_REMOTE';
export const CHECKLIST_UPSERT_REMOTE_SUCCESS_ACTION =
  'CHECKLIST_UPSERT_REMOTE_SUCCESS';
export const CHECKLIST_DELETE_REMOTE_ACTION = 'CHECKLIST_DELETE_REMOTE';
export const CHECKLIST_DELETE_REMOTE_SUCCESS_ACTION =
  'CHECKLIST_DELETE_REMOTE_SUCCESS';

export const applicationFullSyncAction = () => ({
  type: APPLICATION_FULL_SYNC_REQUEST_ACTION
});

export const applicationQuickSyncAction = () => ({
  type: APPLICATION_QUICK_SYNC_REQUEST_ACTION
});

export const verifyAppSyncAction = () => ({
  type: VERIFY_APP_SYNC_REQUEST_ACTION
});

export const triggerFullSyncAction = () => ({
  type: APPLICATION_FULL_SYNC_REQUEST_ACTION
});

export const upsertRemoteTrackerAction = tracker => ({
  type: TRACKER_UPSERT_REMOTE_ACTION,
  payload: tracker
});

export const deleteRemoteTrackerAction = tracker => ({
  type: TRACKER_DELETE_REMOTE_ACTION,
  payload: tracker
});

export const upsertRemoteMilestoneAction = milestone => ({
  type: MILESTONE_UPSERT_REMOTE_ACTION,
  payload: milestone
});

export const deleteRemoteMilestoneAction = milestone => ({
  type: MILESTONE_DELETE_REMOTE_ACTION,
  payload: milestone
});

export const deleteRemoteCommitmentAction = commitment => ({
  type: COMMITMENT_DELETE_REMOTE_ACTION,
  payload: commitment
});

export const upsertRemoteCommitmentAction = commitment => ({
  type: COMMITMENT_UPSERT_REMOTE_ACTION,
  payload: commitment
});

export const deleteRemoteJournalAction = journal => ({
  type: JOURNAL_DELETE_REMOTE_ACTION,
  payload: journal
});

export const upsertRemoteJournalAction = journal => ({
  type: JOURNAL_UPSERT_REMOTE_ACTION,
  payload: journal
});

export const upsertRemoteTourAction = tour => ({
  type: TOUR_UPSERT_REMOTE_ACTION,
  payload: tour
});

export const deleteRemoteChecklistAction = checklist => ({
  type: CHECKLIST_DELETE_REMOTE_ACTION,
  payload: checklist
});

export const upsertRemoteChecklistAction = checklist => ({
  type: CHECKLIST_UPSERT_REMOTE_ACTION,
  payload: checklist
});
