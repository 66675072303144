import React from 'react';
import { JssProvider } from 'react-jss';
import { render } from 'react-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import * as FullStory from '@fullstory/browser';
import TagManager from 'react-gtm-module'
import Root from './containers/Root';
import './app.global.css';

if (process.env.NODE_ENV === 'production') {
  const tagManagerArgs = {
    gtmId: 'GTM-MR3LDRD'
  };
  TagManager.initialize(tagManagerArgs);
  
  FullStory.init({orgId: '14C7KS'});
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00148B',
      dark: '#000B4B',
      light: '#001FD8',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#35BA62',
      dark: '#2C9C52',
      light: '#3EDB73',
      contrastText: '#FFFFFF'
    }
  },
  typography: {
    useNextVariants: true,
    htmlFontSize: 16,
    fontSize: 14,
    h1: {
      fontSize: '3rem'
    },
    h2: {
      fontSize: '2.75rem'
    },
    h3: {
      fontSize: '2.5rem'
    },
    h4: {
      fontSize: '2.25rem'
    },
    h5: {
      fontSize: '2rem'
    },
    h6: {
      fontSize: '1.5rem'
    }

  },
  overrides: {
    MuiCardContent: {
      root: {
        padding: '16px',
        '&:last-child': {
          paddingBottom: 16
        },
        '@media (min-width: 600px)': {
          padding: 16
        }
      }
    }
  }
});

render(
  <JssProvider>
    {/*<AppContainer>*/}
      <MuiThemeProvider theme={theme}>
        <Root />
      </MuiThemeProvider>
    {/*</AppContainer>*/}
  </JssProvider>,
  document.getElementById('root')
);

// if (module.hot) {
//   module.hot.accept('./containers/Root', () => {
//     const NextRoot = require('./containers/Root'); // eslint-disable-line global-require
//     render(
//       <JssProvider>
//         {/*<AppContainer>*/}
//           <MuiThemeProvider theme={theme}>
//             <NextRoot store={store} history={history} />
//           </MuiThemeProvider>
//         {/*</AppContainer>*/}
//       </JssProvider>,
//       document.getElementById('root')
//     );
//   });
// }
