import { parseApn } from '../utils/apn/v1';
import { stringifyApn as stringifyApnV2 } from '../utils/apn/v2';

export const TimerStruct = {
  schemaVersion: '1.2.0',
  ownerApn: '',
  id: '',
  label: '',
  commitmentId: '',
  milestoneApn: '',
  startTime: '',
  endTime: '',
  updatedAt: ''
};

export const getTimerStruct = (timer = TimerStruct) =>
  Object.assign({}, TimerStruct, timer);

export const migrateTimers = (timers, user) => {
  const { schemaVersion } = TimerStruct;

  return timers.map(timer => {
    if (timer.schemaVersion !== schemaVersion) {
      return migrateTimer(timer, schemaVersion, user);
    }
    return timer;
  });
};

export const migrateTimer = (timer, toVersion, user) => {
  const versions = ['undefined', '1.1.0', '1.2.0'];
  const newTimer = getTimerStruct(timer);

  if (!timer.schemaVersion) newTimer.schemaVersion = 'undefined';

  while (newTimer.schemaVersion !== toVersion) {
    const whereAreWeNow = versions.indexOf(newTimer.schemaVersion);
    const whereAreWeGoing = versions.indexOf(toVersion);

    let targetVersion = toVersion;

    if (whereAreWeGoing - whereAreWeNow > 1)
      targetVersion = versions[whereAreWeNow + 1];

    // Version transitions
    if (`${newTimer.schemaVersion}_${targetVersion}` === 'undefined_1.1.0') {
      // Update APN
      const { trackerId, milestoneId } = parseApn(timer.milestoneApn);
      newTimer.milestoneApn = stringifyApnV2({
        userId: user.id,
        trackerId,
        milestoneId
      });
      newTimer.ownerApn = stringifyApnV2({ userId: user.id });
    }

    // Version Up the Timer Schema
    newTimer.schemaVersion = targetVersion;
  }

  return getTimerStruct(newTimer);
};
