import {getCommitInviteStruct} from "../../structs/commitInvite";

export const COMMIT_INVITE_UPSERT_LOCAL_ACTION = 'COMMIT_INVITE_UPSERT_LOCAL';
export const COMMIT_INVITE_REMOVE_LOCAL_ACTION = 'COMMIT_INVITE_REMOVE_LOCAL';
export const COMMIT_INVITE_CREATE_REQUEST_ACTION = 'COMMIT_INVITE_CREATE_REQUEST';
export const COMMIT_INVITE_CREATE_SUCCESS_ACTION = 'COMMIT_INVITE_CREATE_SUCCESS';
export const COMMIT_INVITE_UPSERT_REQUEST_ACTION = 'COMMIT_INVITE_UPSERT_REQUEST';
export const COMMIT_INVITE_UPSERT_SUCCESS_ACTION = 'COMMIT_INVITE_UPSERT_SUCCESS';
export const COMMIT_INVITE_DELETE_REQUEST_ACTION = 'COMMIT_INVITE_DELETE_REQUEST';
export const COMMIT_INVITE_DELETE_SUCCESS_ACTION = 'COMMIT_INVITE_DELETE_SUCCESS';
export const COMMIT_INVITE_ACCEPT_REQUEST_ACTION = 'COMMIT_INVITE_ACCEPT_REQUEST';
export const COMMIT_INVITE_ACCEPT_SUCCESS_ACTION = 'COMMIT_INVITE_ACCEPT_SUCCESS';
export const COMMIT_INVITE_DECLINE_REQUEST_ACTION = 'COMMIT_INVITE_DECLINE_REQUEST';
export const COMMIT_INVITE_DECLINE_SUCCESS_ACTION = 'COMMIT_INVITE_DECLINE_SUCCESS';
export const COMMIT_INVITE_DATA_LOAD_SUCCESS_ACTION = 'COMMIT_INVITE_DATA_LOAD_SUCCESS';
export const COMMIT_INVITE_FULL_SYNC_REQUEST_ACTION = 'COMMIT_INVITE_FULL_SYNC_REQUEST';
export const COMMIT_INVITE_FULL_SYNC_SUCCESS_ACTION = 'COMMIT_INVITE_FULL_SYNC_SUCCESS';
export const COMMIT_INVITE_SET_ACTION = 'COMMIT_INVITE_SET';

export const upsertLocalCommitInviteAction = invite => ({
  type: COMMIT_INVITE_UPSERT_LOCAL_ACTION,
  payload: getCommitInviteStruct(invite)
});

export const removeLocalCommitInviteAction = invite => ({
  type: COMMIT_INVITE_REMOVE_LOCAL_ACTION,
  payload: getCommitInviteStruct(invite)
});

export const createCommitInviteAction = invite => ({
  type: COMMIT_INVITE_CREATE_REQUEST_ACTION,
  payload: getCommitInviteStruct(invite)
});

export const upsertCommitInviteAction = invite => ({
  type: COMMIT_INVITE_UPSERT_REQUEST_ACTION,
  payload: getCommitInviteStruct(invite)
});

export const acceptCommitInviteAction = invite => ({
  type: COMMIT_INVITE_ACCEPT_REQUEST_ACTION,
  payload: getCommitInviteStruct(invite)
});

export const declineCommitInviteAction = invite => ({
  type: COMMIT_INVITE_DECLINE_REQUEST_ACTION,
  payload: getCommitInviteStruct(invite)
});

export const deleteCommitInviteAction = invite => ({
  type: COMMIT_INVITE_DELETE_REQUEST_ACTION,
  payload: getCommitInviteStruct(invite)
});

export const setCommitInvitesAction = invites => ({
  type: COMMIT_INVITE_SET_ACTION,
  payload: invites
});