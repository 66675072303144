import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as PropTypes from 'prop-types';
import { setDesiredRouteAction } from '../redux/actions/app';

class NavigationHandler extends Component {
  componentDidUpdate() {
    const { desiredRoute, history, setDesiredRoute } = this.props;
    if (desiredRoute && history.location.pathname !== desiredRoute) {
      history.push(desiredRoute);
      setDesiredRoute('');
    }
    if (desiredRoute === history.location.pathname) {
      setDesiredRoute('');
    }
  }

  render() {
    return <React.Fragment />;
  }
}

NavigationHandler.propTypes = {
  desiredRoute: PropTypes.string.isRequired,
  setDesiredRoute: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  desiredRoute: state.app.desiredRoute
});

const mapDispatchToProps = dispatch => ({
  setDesiredRoute: route => dispatch(setDesiredRouteAction(route))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavigationHandler)
);
