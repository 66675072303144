import moment from "moment";
import { put, takeEvery, select } from 'redux-saga/effects';
import {
  addTrackerActivityAction,
  setTrackerActivityNextTokenAction,
  TRACKER_ACTIVITY_CREATE_REQUEST_ACTION, TRACKER_ACTIVITY_CREATE_SUCCESS_ACTION, TRACKER_ACTIVITY_FETCH_REQUEST_ACTION,
  TRACKER_ACTIVITY_FETCH_SUCCESS_ACTION, UPSERT_LOCAL_TRACKER_ACTIVITY_ACTION, upsertLocalTrackerActivityAction
} from "../actions/trackerActivity";
import {
  getTrackerActivityStruct,
  TRACKER_ACTIVITY_TYPE_COMMITMENT, TRACKER_ACTIVITY_TYPE_MILESTONE, TRACKER_ACTIVITY_TYPE_SHARE,
  TRACKER_ACTIVITY_TYPE_TRACKER
} from "../../structs/trackerActivity";
import {createRemoteTrackerActivity, fetchRemoteTrackerActivityByTracker} from "../../utils/appSync/trackerActivity";
import {TRACKER_CREATE_SUCCESS_ACTION, TRACKER_UPDATE_SUCCESS_ACTION} from "../actions/trackers";
import {COMMITMENT_CREATE_SUCCESS_ACTION, COMMITMENT_UPDATE_SUCCESS_ACTION} from "../actions/commitments";
import {MILESTONE_CREATE_SUCCESS_ACTION, MILESTONE_UPDATE_SUCCESS_ACTION} from "../actions/milestones";
import {COMMITMENT_STATUS_COMPLETE, COMMITMENT_STATUS_PENDING} from "../../structs/commitments";
import {
  TRACKER_SHARE_ACCEPT_SUCCESS_ACTION,
  TRACKER_SHARE_CREATE_SUCCESS_ACTION,
  TRACKER_SHARE_DECLINE_SUCCESS_ACTION, TRACKER_SHARE_DELETE_SUCCESS_ACTION, TRACKER_SHARE_UPDATE_SUCCESS_ACTION
} from "../actions/trackerShares";
import {maskEmail} from "../../utils/mask";
import {TRACKER_SHARE_PERMISSIONS} from "../../structs/trackerShare";

function* handleCreateTrackerActivityRequest({ payload: activity }) {

  const user = yield select(store => store.user);
  const { id: userId, attributes: { givenName, familyName } } = user;
  
  const trackerActivity = getTrackerActivityStruct({
    ...activity,
    userId,
    byUser: `${givenName} ${familyName}`,
    createdAt: moment().toISOString()
  });

  const remoteTrackerActivity = yield createRemoteTrackerActivity(trackerActivity);

  yield put({
    type: UPSERT_LOCAL_TRACKER_ACTIVITY_ACTION,
    payload: remoteTrackerActivity
  });

  // Signal Complete
  yield put({
    type: TRACKER_ACTIVITY_CREATE_SUCCESS_ACTION,
    payload: remoteTrackerActivity
  });
}

function* handleTrackerActivityFetchRequest({ payload: { trackerId, token }}) {
  // Pull activity for the active tracker
  const { items, nextToken } = yield fetchRemoteTrackerActivityByTracker(trackerId, token);

  for (const activity of items) {
    yield put(upsertLocalTrackerActivityAction(activity));
  }

  yield put(setTrackerActivityNextTokenAction(nextToken));

  // Signal Complete
  yield put({
    type: TRACKER_ACTIVITY_FETCH_SUCCESS_ACTION
  });
}

function* handleCreateTracker({payload: tracker}) {
  yield put(addTrackerActivityAction({
    trackerId: tracker.id,
    entityId: tracker.id,
    entityType: TRACKER_ACTIVITY_TYPE_TRACKER,
    summary: `Tracker Created: ${tracker.dream}`
  }));
}

function* handleUpdateTracker({payload: tracker}) {
  yield put(addTrackerActivityAction({
    trackerId: tracker.id,
    entityId: tracker.id,
    entityType: TRACKER_ACTIVITY_TYPE_TRACKER,
    summary: `Tracker Updated: ${tracker.dream}`
  }));
}

function* handleCreateCommitment({payload: { commitment }}) {
  yield put(addTrackerActivityAction({
    trackerId: commitment.trackerId,
    entityId: commitment.id,
    entityType: TRACKER_ACTIVITY_TYPE_COMMITMENT,
    summary: `Commitment Created: ${commitment.description}`
  }));
}

function* handleUpdateCommitment({payload: { commitment, previousCommitment }}) {
  // Compare Commitments, and make the activity more meaningful
  const changes = [];

  changes.push(`Commitment Updated: ${commitment.description}`);

  if (previousCommitment.status === COMMITMENT_STATUS_PENDING && commitment.status === COMMITMENT_STATUS_COMPLETE) {
    changes.push(`Completed!`);
  }

  if (previousCommitment.description !== commitment.description) {
    changes.push(`Description Changed`);
  }

  yield put(addTrackerActivityAction({
    trackerId: commitment.trackerId,
    entityId: commitment.id,
    entityType: TRACKER_ACTIVITY_TYPE_COMMITMENT,
    summary: changes.join(', ')
  }));
}

function* handleCreateMilestone({payload: milestone}) {
  yield put(addTrackerActivityAction({
    trackerId: milestone.trackerId,
    entityId: milestone.id,
    entityType: TRACKER_ACTIVITY_TYPE_MILESTONE,
    summary: `Milestone Created: ${milestone.description}`
  }));
}

function* handleUpdateMilestone({payload: milestone}) {
  yield put(addTrackerActivityAction({
    trackerId: milestone.trackerId,
    entityId: milestone.id,
    entityType: TRACKER_ACTIVITY_TYPE_MILESTONE,
    summary: `Milestone Updated: ${milestone.description}`
  }));
}

function* handleTrackerShareCreate({payload: trackerShare}) {
  yield put(addTrackerActivityAction({
    trackerId: trackerShare.trackerId,
    entityId: trackerShare.id,
    entityType: TRACKER_ACTIVITY_TYPE_SHARE,
    summary: `Share created for ${maskEmail(trackerShare.emailAddress)}`
  }));
}

function* handleTrackerShareUpdate({payload: trackerShare}) {
  yield put(addTrackerActivityAction({
    trackerId: trackerShare.trackerId,
    entityId: trackerShare.id,
    entityType: TRACKER_ACTIVITY_TYPE_SHARE,
    summary: `Share permissions changed to ${TRACKER_SHARE_PERMISSIONS[trackerShare.permissionLevel]} for ${maskEmail(trackerShare.emailAddress)}`
  }));
}

function* handleTrackerShareDelete({payload: trackerShare}) {
  yield put(addTrackerActivityAction({
    trackerId: trackerShare.trackerId,
    entityId: trackerShare.id,
    entityType: TRACKER_ACTIVITY_TYPE_SHARE,
    summary: `Share permissions removed for ${maskEmail(trackerShare.emailAddress)}`
  }));
}

function* handleTrackerShareAccept({payload: trackerShare}) {
  yield put(addTrackerActivityAction({
    trackerId: trackerShare.trackerId,
    entityId: trackerShare.id,
    entityType: TRACKER_ACTIVITY_TYPE_SHARE,
    summary: `Share accepted by ${maskEmail(trackerShare.emailAddress)}`
  }));
}

function* handleTrackerShareDecline({payload: trackerShare}) {
  yield put(addTrackerActivityAction({
    trackerId: trackerShare.trackerId,
    entityId: trackerShare.id,
    entityType: TRACKER_ACTIVITY_TYPE_SHARE,
    summary: `Share declined by ${maskEmail(trackerShare.emailAddress)}`
  }));
}

export default function* trackerActivitySaga() {
  // Handle Actions
  yield takeEvery(TRACKER_ACTIVITY_CREATE_REQUEST_ACTION, handleCreateTrackerActivityRequest);
  yield takeEvery(TRACKER_ACTIVITY_FETCH_REQUEST_ACTION, handleTrackerActivityFetchRequest);

  // Handle Entity Events
  yield takeEvery(TRACKER_CREATE_SUCCESS_ACTION, handleCreateTracker);
  yield takeEvery(TRACKER_UPDATE_SUCCESS_ACTION, handleUpdateTracker);
  yield takeEvery(COMMITMENT_CREATE_SUCCESS_ACTION, handleCreateCommitment);
  yield takeEvery(COMMITMENT_UPDATE_SUCCESS_ACTION, handleUpdateCommitment);
  yield takeEvery(MILESTONE_CREATE_SUCCESS_ACTION, handleCreateMilestone);
  yield takeEvery(MILESTONE_UPDATE_SUCCESS_ACTION, handleUpdateMilestone);
  yield takeEvery(TRACKER_SHARE_CREATE_SUCCESS_ACTION, handleTrackerShareCreate);
  yield takeEvery(TRACKER_SHARE_UPDATE_SUCCESS_ACTION, handleTrackerShareUpdate);
  yield takeEvery(TRACKER_SHARE_DELETE_SUCCESS_ACTION, handleTrackerShareDelete);
  yield takeEvery(TRACKER_SHARE_ACCEPT_SUCCESS_ACTION, handleTrackerShareAccept);
  yield takeEvery(TRACKER_SHARE_DECLINE_SUCCESS_ACTION, handleTrackerShareDecline);
}
