import React, { Component } from 'react';
import TrackerTemplatePage from '../components/TrackerTemplate/Page';

export class TrackerTemplatePageContainer extends Component {
  render() {
    return <TrackerTemplatePage/>;
  }
}

export default TrackerTemplatePage;
