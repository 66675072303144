import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReviewCycle from '../../components/Tracker/ReviewCycle';
import {withRouter} from 'react-router';

export class TrackerReviewCycleContainer extends Component {
  render() {
    return <ReviewCycle />;
  }
}

const mapStateToProps = state => ({
  trackerIndex: state.trackerIndex
});

const mapDispatchToProps = () => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReviewCycle));
