import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import MeasureMetricNumber from './Metric/Number';
import MeasureMetricUnknown from './Metric/Unknown';

export const style = () => ({});

class MeasureMetric extends Component {
  state = {};

  handleChange = changedItem => {
    const { item, onChange } = this.props;
    item.item = changedItem;
    onChange(item);
  };

  handleDelete = changedItem => {
    const { item, onDelete } = this.props;
    item.item = changedItem;
    onDelete(item);
  };

  render() {
    const { item, readOnly } = this.props;

    switch (item.type) {
      case 'number':
        return (
          <MeasureMetricNumber
            item={item.item}
            readOnly={readOnly}
            onChange={this.handleChange}
            onDelete={this.handleDelete}
          />
        );
      default:
        return (
          <MeasureMetricUnknown
            item={item.item}
            readOnly={readOnly}
            onChange={this.handleChange}
            onDelete={this.handleDelete}
          />
        );
    }
  }
}

MeasureMetric.propTypes = {
  item: PropTypes.any.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default withStyles(style)(MeasureMetric);
