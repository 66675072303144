import {
  LOAD_ALL_SYNC_EVENTS,
  ADD_SYNC_EVENT,
  REMOVE_SYNC_EVENT
} from '../actions/syncEvents';

export default function syncEventReducer(oldstate = [], action) {
  switch (action.type) {
    case ADD_SYNC_EVENT:
      return [...oldstate, action.payload];

    case REMOVE_SYNC_EVENT:
      return oldstate.filter(event => event.id !== action.payload.id);

    case LOAD_ALL_SYNC_EVENTS:
      return action.payload;

    default:
      return oldstate;
  }
}
