import { v4 as uuidv4 } from 'uuid';

export const LEARN_ITEM_TYPE_PAGE = 'page';
export const LEARN_ITEM_TYPE_LINK = 'link';
export const LEARN_ITEM_TYPE_HEADING = 'heading';
export const LEARN_ITEM_TYPE_QUESTION = 'question';
export const LEARN_ITEM_TYPE_IMAGE = 'image';
export const LEARN_ITEM_TYPE_FILE = 'file';
export const LEARN_ITEM_TYPE_NOTE = 'note';

export const LearnPageStruct = {
  schemaVersion: '1.0.0',
  id: ' ',
  title: ' ',
  parts: [],
  createdAt: ' ',
  updatedAt: ' '
};

export const LearnItemStruct = {
  schemaVersion: '1.0.0',
  id: ' ',
  seq: 1,
  type: ' ',
  item: {}
};

export const LearnItemPageStruct = {
  schemaVersion: '1.0.0',
  title: ' ',
  pageId: ' '
};

export const LearnItemNoteStruct = {
  schemaVersion: '1.0.0',
  title: ' ',
  content: ' '
};

export const LearnItemImageStruct = {
  schemaVersion: '1.0.0',
  title: '',
  content: ''
};

export const LearnItemFileStruct = {
  schemaVersion: '1.0.0',
  title: '',
  content: ''
};

export const LearnItemLinkStruct = {
  schemaVersion: '1.0.0',
  title: ' ',
  url: ' '
};

export const LearnItemHeadingStruct = {
  schemaVersion: '1.0.0',
  text: ' '
};

export const LearnItemQuestionStruct = {
  schemaVersion: '1.0.0',
  question: ' ',
  answer: ' '
};

export const getLearnPageStruct = (page = LearnPageStruct) =>
  Object.assign({}, LearnPageStruct, page, {
    parts: page.parts.map(part => getLearnItemStruct(part)) || []
  });

export const getLearnItemPageStruct = (item = LearnItemPageStruct) =>
  Object.assign({}, LearnItemPageStruct, item);

export const getLearnItemNoteStruct = (item = LearnItemNoteStruct) =>
  Object.assign({}, LearnItemNoteStruct, item);

export const getLearnItemImageStruct = (item = LearnItemImageStruct) =>
  Object.assign({}, LearnItemImageStruct, item);

export const getLearnItemFileStruct = (item = LearnItemFileStruct) =>
  Object.assign({}, LearnItemFileStruct, item);

export const getLearnItemLinkStruct = (item = LearnItemLinkStruct) =>
  Object.assign({}, LearnItemLinkStruct, item);

export const getLearnItemHeadingStruct = (item = LearnItemHeadingStruct) =>
  Object.assign({}, LearnItemHeadingStruct, item);

export const getLearnItemQuestionStruct = (item = LearnItemQuestionStruct) =>
  Object.assign({}, LearnItemQuestionStruct, item);

export const getLearnItemStruct = (item = LearnItemStruct) =>
  Object.assign({}, LearnItemStruct, item, {
    id: item.id ? item.id : uuidv4(),
    item: getLearnItemType(item)
  });

export const getLearnItemType = (item = LearnItemStruct) => {
  switch (item.type) {
    case LEARN_ITEM_TYPE_PAGE:
      return getLearnItemPageStruct(item.item);
    case LEARN_ITEM_TYPE_LINK:
      return getLearnItemLinkStruct(item.item);
    case LEARN_ITEM_TYPE_HEADING:
      return getLearnItemHeadingStruct(item.item);
    case LEARN_ITEM_TYPE_QUESTION:
      return getLearnItemQuestionStruct(item.item);
    case LEARN_ITEM_TYPE_IMAGE:
      return getLearnItemImageStruct(item.item);
    case LEARN_ITEM_TYPE_FILE:
      return getLearnItemFileStruct(item.item);
    case LEARN_ITEM_TYPE_NOTE:
      return getLearnItemNoteStruct(item.item);
    default:
      return getLearnItemNoteStruct(item.item);
  }
};
