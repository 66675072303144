import { API, graphqlOperation } from 'aws-amplify';
import {
  createSMSNotification,
  createSMSPreferences, deleteSMSNotification, smsImmediate,
  updateSMSPreferences,
} from '../../graphql/mutations';
import {
  getSMSPreferences, listSMSNotifications,
} from '../../graphql/queries';

import { getSMSNotificationStruct, getSMSPreferenceStruct } from '../../structs/notification';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {prepAndPruneForAppSync} from "../appSync";

export const saveSMSPreference = async (preference) => {
  const { userApn } = await fetchSMSPreference({
    id: preference.userApn
  });
  if (!!userApn) {
    const { data: { updateSMSPreferences: userPreference }} = await API.graphql(
      graphqlOperation(updateSMSPreferences, {
        input: getSMSPreferenceStruct(preference),
      })
    );
    return userPreference;
  }

  const { data: { createEmailPreferences: userPreference }} = await API.graphql(
    graphqlOperation(createSMSPreferences, {
      input: getSMSPreferenceStruct(preference),
    })
  );
  
  return getSMSPreferenceStruct(userPreference);
};

export const fetchSMSPreference = async (user) => {
  const { data: { getSMSPreferences: preference }} = await API.graphql(
    graphqlOperation(getSMSPreferences, {
      userApn: user.id
    })
  );
  return getSMSPreferenceStruct(preference);
};

export const saveSMSNotification = async (notificationDetails) => {
  const { data: { createSMSNotification: notification }} = await API.graphql(
    graphqlOperation(createSMSNotification, {
      input: prepAndPruneForAppSync({
        ...notificationDetails,
        id: uuidv4(),
        sendTimestamp: notificationDetails.sendTimestamp
          ? notificationDetails.sendTimestamp
          : moment().toISOString(),
        processedTimestamp: new Date(null),
      }, getSMSNotificationStruct)
    })
  );

  return notification;
};

export const sendImmediateSMS = async (message, number) => {
  const { data: { smsImmediate: response }} = await API.graphql(
    graphqlOperation(smsImmediate, {
      message,
      number: `1${number}`
    })
  );

  return response;
};

export const fetchSMSNotificationsByEntity = async (userApn, entityApn) => {
  return new Promise(async resolve => {
    let all = [];
    let whileToken = 'initial';

    while (whileToken) {
      const {data: {listSMSNotifications: {items, nextToken}}} = await API.graphql(
        graphqlOperation(listSMSNotifications, {
          userApn,
          filter: {
            entityApn: {
              eq: entityApn
            }
          },
          limit: 1000,
          nextToken: whileToken === 'initial' ? null : whileToken
        })
      );

      all = all.concat(items);
      whileToken = nextToken;
    }

    resolve(
      all.length
        ? all.map(notification => getSMSNotificationStruct(notification))
        : []
    );
  });
}

export const deleteRemoteSMSNotification = async ({ id, userApn }) => {
  await API.graphql(
    graphqlOperation(deleteSMSNotification, {
        input: { id, userApn }
      }
    ));
};
