import { getContactStruct } from "../../structs/contact";

export const CONTACT_FETCH_REQUEST_ACTION = 'CONTACT_FETCH_REQUEST';
export const CONTACT_CREATE_REQUEST_ACTION = 'CONTACT_CREATE_REQUEST';
export const CONTACT_CREATE_SUCCESS_ACTION = 'CONTACT_CREATE_SUCCESS';
export const CONTACT_UPSERT_LOCAL_ACTION = 'CONTACT_UPSERT_LOCAL';
export const CONTACT_AFTER_SHARE_SUCCESS_ACTION = 'CONTACT_AFTER_SHARE_SUCCESS';

export const fetchContactAction = contact => ({
  type: CONTACT_FETCH_REQUEST_ACTION,
  payload: getContactStruct(contact)
});

export const upsertLocalContactAction = contact => ({
  type: CONTACT_UPSERT_LOCAL_ACTION,
  payload: getContactStruct(contact)
});

export const createContactAction = contact => ({
  type: CONTACT_CREATE_REQUEST_ACTION,
  payload: getContactStruct(contact)
});
