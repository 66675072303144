import React, {useCallback, useState} from 'react';
import {useSelector} from "react-redux";
import List from "@material-ui/core/List/List";
import InviteItem from "./InviteItem";
import {COMMIT_INVITE_STATUS_PENDING, getCommitInviteStruct} from "../../structs/commitInvite";
import RespondDialog from "./RespondDialog";
import {stringifyApn} from "../../utils/apn/v2";

const MyInvitesList = () => {
  const user = useSelector(store => store.user);
  const commitInvites = useSelector(store => store.commitInvites);
  const [respond, setRespond] = useState(getCommitInviteStruct());
  const userApn = stringifyApn({ userId: user.id });
  const activeInvites = commitInvites.length > 0
    ? commitInvites.filter(invite => invite.toUserApn === userApn && invite.status === COMMIT_INVITE_STATUS_PENDING)
    : [];

  const resetRespond = useCallback(() => {
    setRespond(getCommitInviteStruct());
  }, []);

  return (
    <>
      {activeInvites && activeInvites.length ? (
        <>
          <List>
            {activeInvites.map(invite => (
              <InviteItem
                key={invite.id}
                invite={invite}
                onRespond={setRespond}
              />
            ))}
          </List>
          <RespondDialog invite={respond} onReset={resetRespond} />
        </>
      ) : null}
    </>
  );
};

MyInvitesList.defaultProps = {};

MyInvitesList.propTypes = {};

export default MyInvitesList;
