import { select, put, takeLatest } from 'redux-saga/effects';
import React from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import {
  STRIPE_PAYMENT_REQUEST_ACTION,
  STRIPE_PAYMENT_SUCCESS_ACTION,
  STRIPE_PAYMENT_FAILURE_ACTION,
  STRIPE_CANCEL_REQUEST_ACTION,
  STRIPE_CANCEL_SUCCESS_ACTION,
  STRIPE_CHECK_SUCCESS_ACTION,
  STRIPE_CHANGE_FAILURE_ACTION,
  STRIPE_CHANGE_REQUEST_ACTION,
  STRIPE_CHANGE_SUCCESS_ACTION,
  STRIPE_CHECK_REQUEST_ACTION,
  PAYMENT_GATE_CHECK_ACTION,
  PAYMENT_GATE_ALLOW_ACTION,
  PAYMENT_GATE_REJECT_ACTION,
  SUBSCRIPTION_START_REQUEST_ACTION,
  SUBSCRIPTION_START_SUCCESS_ACTION,
  NO_PAYMENT_REQUEST_ACTION,
  NO_PAYMENT_SUCCESS_ACTION
} from '../actions/payment';
import {
  addNotificationAction, addEmailNotificationAction,
  setLoadingAction
} from '../actions/notifications';
import {
  getSystemNotificationStruct,
  INTERNET_STATUS_CONNECTED, NOTIFICATION_TOPIC_SUBSCRIPTION_CHANGED,
  NOTIFICATION_TOPIC_SUBSCRIPTION_STARTED
} from '../../structs/notification';
import {
  setUserAction,
} from '../actions/user';
import {
  APPLICATION_DATA_REQUEST_ACTION,
  APPLICATION_SUBSCRIPTIONS_REQUEST_ACTION, APPLICATION_SUBSCRIPTIONS_SUCCESS_ACTION,
  setDesiredRouteAction, setPayGateAction,
  setPaymentKeyAction, setSubscriptionsAction
} from '../actions/app';
import {
  postSubscription,
  fetchSubscription,
  cancelSubscription, getInfo, postSubscriptionChange
} from '../../utils/appSync/stripe';
import ROUTES from '../../constants/routes.json';
import {getCancelDate, getStripeSubscriptionStruct} from '../../structs/stripe';
import {fetchRemoteSubscriptions} from '../../utils/appSync/subscriptions';
import {take} from '@redux-saga/core/effects';
import {getMembershipStatus, USER_MEMBERSHIP_STATUS_ACTIVE, USER_MEMBERSHIP_STATUS_LAPSED} from '../../models/user';
import {trackerAddAllowed} from '../../models/usage';
import {TRACKER_STATUS_ACTIVE} from '../../structs/trackers';
import {
  fetchMembershipStatusAction, MEMBERSHIP_FETCH_STATUS_SUCCESS_ACTION,
  setMembershipStatusAction,
  upsertMembershipStatusAction
} from '../actions/membership';
import Trademarked from '../../components/Ui/Trademarked';
import {SUBSCRIPTION_CODE_PUBLIC} from "../../structs/subscription";

function* handleCheckStripePayment() {
  const internetStatus = yield select(store => store.internetStatus);
  if (internetStatus.status !== INTERNET_STATUS_CONNECTED) return;
  
  const user = yield select(store => store.user);
  let membership = yield select(store => store.membership);
  
  if (!membership.id) {
    yield put(fetchMembershipStatusAction());
    yield take(MEMBERSHIP_FETCH_STATUS_SUCCESS_ACTION);
    membership = yield select(store => store.membership);
  }
  
  // If the user status is unknown, then we don't need to check the subscription
  if (!user.status) {
    console.log('No User Status, not checking subscription');
    return 0;
  }

  if (!membership.subscriptionId || membership.subscriptionId === 'none') {
    yield put({ type: SUBSCRIPTION_START_REQUEST_ACTION });
    yield take(SUBSCRIPTION_START_SUCCESS_ACTION);
  }
  
  let subscriptions = yield select(store => store.app.subscriptions);
  
  if (!subscriptions.length) {
    yield put({ type: APPLICATION_SUBSCRIPTIONS_REQUEST_ACTION });
    yield take(APPLICATION_SUBSCRIPTIONS_SUCCESS_ACTION);
    subscriptions = yield select(store => store.app.subscriptions);
  }
  
  const now = new Date();
  const currentTimeStamp = Math.floor(now.getTime() / 1000);
  const {
    stripeSubscription: { stripeSubscriptionId }
  } = membership;
  
  // If the membership doesn't have a stripe subscription, then we don't need to verify
  if (stripeSubscriptionId === 'none' || !stripeSubscriptionId.trim()) {
    
    // If there is a membership record, then we load up the activeSubscription
    if (membership.subscriptionId) {
      const activeSubscription = subscriptions.find(subscription => subscription.id === membership.subscriptionId);
      if (activeSubscription){
        yield put(setUserAction({
          ...user,
          memberStatus: getMembershipStatus(activeSubscription),
          activeSubscription
        }));
      }
    }
  
    console.log('stripeSubscriptionId === none, so not checking subscription');
    yield put({ type: STRIPE_CHECK_SUCCESS_ACTION });
  
    return 0;
  }

  yield put(setLoadingAction('Verifying Account...'));
  
  // Fetch the current subscription status from Stripe
  let stripeSubscription = yield fetchSubscription(stripeSubscriptionId);
  
  let activeSubscription = subscriptions.find(subscription => subscription.stripePlanId === stripeSubscription?.plan?.id);
  
  // No Subscription was found, so we throw an error
  if (!activeSubscription) {
    yield put(
      addNotificationAction(
        getSystemNotificationStruct({
          message: (
            <div>
              <Typography>Subscription Error!</Typography>
              <Typography>
                {`Your Formigio subscription is not pulling up correctly. Please select a new subscription.
                If you have a current paid subscription, then something is definitely not right.
                Please let us know, so we can help get things set right.`}
              </Typography>
            </div>
          ),
          type: 'dialog',
          level: 'error'
        })
      )
    );
  
    yield put(setLoadingAction(''));
  
    yield put(setDesiredRouteAction(ROUTES.PAYMENT));
    
    return 0;
  }
  
  if (activeSubscription.id !== membership.subscriptionId) {
    yield put(
      addNotificationAction(
        getSystemNotificationStruct({
          message: (
            <div>
              <Typography>Subscription Changed!</Typography>
              <Typography>
                {`Heads up, when validating your subscription, we are seeing a subscription change.
                This can happen if you changed your subscription on a different device.
                If you didn't make this change, please let us know.`}
              </Typography>
            </div>
          ),
          type: 'dialog',
          level: 'warning'
        })
      )
    );
  }
  
  const stagedUser = {
    ...user,
    activeSubscription
  };
  
  const paymentVerified = stripeSubscription.current_period_end > currentTimeStamp;
  
  if (!activeSubscription.id) return;
  
  const stagedMembership = {
    id: user.id,
    paymentVerified,
    subscriptionId: activeSubscription.id,
    subscriptionLevel: activeSubscription.level,
    stripeSubscription: {
      ...membership.stripeSubscription,
      stripeCurrentPeriodEnd: stripeSubscription.current_period_end,
      cancelAtPeriodEnd: stripeSubscription.cancel_at_period_end,
      stripeCancelDate: stripeSubscription.canceled_at,
      updatedAt: moment().format()
    }
  };
  
  yield put(
    setUserAction({
      ...stagedUser,
      memberStatus: getMembershipStatus(activeSubscription,stagedMembership.stripeSubscription),
      updatedAt: moment().format()
    })
  );
  
  yield put(
    setMembershipStatusAction({
      ...stagedMembership,
      updatedAt: moment().format()
    })
  );
  
  // If there is a change to the subscription, push this change to the remote membership status
  if (membership.paymentVerified !== paymentVerified
    || membership.subscriptionId !== activeSubscription.id
    || membership.stripeCurrentPeriodEnd !== stripeSubscription.current_period_end
  ) {
    yield put(setLoadingAction('Updating Member Status...'));
    yield put(upsertMembershipStatusAction());
  }
  
  const currentUser = yield select(store => store.user);
  
  if (currentUser.memberStatus.status === USER_MEMBERSHIP_STATUS_ACTIVE) {
    console.log('Subscription Still Valid');
    // yield put(setDesiredRouteAction(ROUTES.WELCOME));
  }
  
  if (currentUser.memberStatus.status === USER_MEMBERSHIP_STATUS_LAPSED) {
    console.log('No Subscription');
    // No Subscription in place
    yield put(setDesiredRouteAction(ROUTES.PAYMENT));
  }
  
  yield put({ type: STRIPE_CHECK_SUCCESS_ACTION });

  yield put(setLoadingAction(''));
}

function* handleCancelStripePayment({ payload: subscriptionPlanId }) {
  const user = yield select(store => store.user);
  const membership = yield select(store => store.membership);
  const subscriptions = yield select(store => store.app.subscriptions);
  let { stripeSubscription } = membership;
  const { stripeCurrentPeriodEnd, cancelAtPeriodEnd } = stripeSubscription;
  const now = new Date();
  const currentTimeStamp = Math.floor(now.getTime() / 1000);
  
  if ((stripeSubscription.stripeCancelDate && !stripeSubscription.stripeCancelDate.trim()) || !stripeSubscription.stripeCancelDate) {
    yield put(setLoadingAction('Submitting Cancel Request...'));

    const response = yield cancelSubscription(
      stripeSubscription.stripeSubscriptionId
    );

    console.log(response);

    stripeSubscription = {
      ...membership.stripeSubscription,
      cancelAtPeriodEnd: response.cancel_at_period_end,
      stripeCancelDate: response.canceled_at,
    };
  }
  
  if (currentTimeStamp > stripeCurrentPeriodEnd && cancelAtPeriodEnd) {
    const activeSubscription = subscriptions.find(subscription => subscription.stripePlanId === subscriptionPlanId);
  
    yield put(
      setMembershipStatusAction({
        ...membership,
        subscriptionId: activeSubscription.id,
        subscriptionLevel: activeSubscription.level,
        stripeSubscription: getStripeSubscriptionStruct()
      })
    );
  
    yield put(setUserAction({
      ...user,
      memberStatus: getMembershipStatus(activeSubscription,stripeSubscription),
      activeSubscription
    }));
  
    yield put(
      addNotificationAction(
        getSystemNotificationStruct({
          message: (
            <div>
              <Typography>Subscription Downgraded!</Typography>
              <Typography>
                {`Your Formigio subscription has been fully cancelled and downgraded.`}
              </Typography>
              <Typography>
                {`Subscribe at any time in the future to get more done.`}
              </Typography>
            </div>
          ),
          type: 'dialog',
          level: 'success'
        })
      )
    );
  
  } else {
  
    yield put(
      setMembershipStatusAction({
        ...membership,
        stripeSubscription
      })
    );
  
    const cancelServiceDate = getCancelDate(stripeSubscription);
  
    yield put(
      addNotificationAction(
        getSystemNotificationStruct({
          message: (
            <div>
              <Typography>Subscription Cancel Successful!</Typography>
              <Typography>
                {`Your Formigio subscription has been cancelled.
              You will have access to your account until ${cancelServiceDate}.
              After that date your account will revert to a free account.`}
              </Typography>
            </div>
          ),
          type: 'dialog',
          level: 'success'
        })
      )
    );
  }
  
  yield put(upsertMembershipStatusAction());

  yield put(setLoadingAction(''));

  yield put({ type: STRIPE_CANCEL_SUCCESS_ACTION });
}

function* handleSubscriptionStart() {
  let subscriptions = yield select(store => store.app.subscriptions);
  
  if (!subscriptions.length) {
    yield put({ type: APPLICATION_SUBSCRIPTIONS_REQUEST_ACTION });
    yield take(APPLICATION_SUBSCRIPTIONS_SUCCESS_ACTION);
    subscriptions = yield select(store => store.app.subscriptions);
  }
  
  const user = yield select(store => store.user);
  
  const activeSubscription = subscriptions[0];
  
  // Store the Subscription and payment status in the app
  yield put(
    setMembershipStatusAction({
      subscriptionId: activeSubscription.id,
      subscriptionLevel: activeSubscription.level
    })
  );
  
  yield put(setUserAction({
    ...user,
    memberStatus: getMembershipStatus(activeSubscription),
    activeSubscription
  }));
  
  // Store the Subscription and payment status in the remote data store
  yield put(upsertMembershipStatusAction());
  
  yield put(
    addNotificationAction(
      getSystemNotificationStruct({
        message: (
          <div>
            <Typography>Welcome to the <Trademarked>Formigio</Trademarked> family!</Typography>
            <Typography>
              You are now a Formigio Member -{' '}
              {activeSubscription.name}. Happy Accomplishing!
              Congratulations!
            </Typography>
            <Typography>
              This is the free plan. You will have just a few active Trackers at a time
              so you try it out here with us.
            </Typography>
            <Typography>
              Feel free to upgrade to a paid plan at any time.
            </Typography>
          </div>
        ),
        type: 'dialog',
        level: 'success'
      })
    )
  );
  
  yield put({ type: SUBSCRIPTION_START_SUCCESS_ACTION });
}

function* handleNoPaymentNeeded({ payload: subscription }) {
  const user = yield select(store => store.user);

  // Store the Subscription and payment status in the app
  yield put(
    setMembershipStatusAction({
      subscriptionId: subscription.id,
      subscriptionLevel: subscription.level,
      subscriptionCode: subscription.code !== SUBSCRIPTION_CODE_PUBLIC ? subscription.code : '',
      paymentVerified: true
    })
  );

  yield put(setUserAction({
    ...user,
    memberStatus: getMembershipStatus(subscription),
    activeSubscription: subscription
  }));

  // Store the Subscription and payment status in the remote data store
  yield put(upsertMembershipStatusAction());
  yield put(addEmailNotificationAction({
    topic: NOTIFICATION_TOPIC_SUBSCRIPTION_STARTED,
    summaryText: 'Subscription Successful',
    payload: `Congratulations, You are now a Formigio Member on the ${subscription.name} plan. Happy Accomplishing! Congratulations!`,
  }));

  yield put(
    addNotificationAction(
      getSystemNotificationStruct({
        message: (
          <div>
            <Typography>Subscription Save Successful!</Typography>
            <Typography>
              You are now a Formigio Member -{' '}
              {subscription.name}. Happy Accomplishing!
              Congratulations!
            </Typography>
          </div>
        ),
        type: 'dialog',
        level: 'success'
      })
    )
  );

  yield put({ type: NO_PAYMENT_SUCCESS_ACTION });
}

function* handleStripePayment({ payload: { user, id } }) {
  
  const membership = yield select(store => store.membership);
  
  const { activeSubscription } = user;
  
  yield put(setLoadingAction('Securely Processing Payment with Stripe...'));

  try {
    const response = yield postSubscription(user, id);

    yield put(setLoadingAction(''));

    // Disqualify payment request due to bad information coming from Stripe
    if (!response || (response && response.plan.active !== true)) {
      yield put({
        type: STRIPE_PAYMENT_FAILURE_ACTION,
        payload: 'There was an error processing the payment'
      });
      yield put(
        setMembershipStatusAction({
          ...membership,
          paymentVerified: false
        })
      );
      yield put(
        addNotificationAction(
          getSystemNotificationStruct({
            message:
              'The payment was unsuccessful. Please try again! (Bad response from Stripe)',
            type: 'snackbar',
            level: 'error'
          })
        )
      );

      return;
    }
  
    const stripeSubscription = {
      ...membership.stripeSubscription,
      stripeSubscriptionType: response.plan.id,
      stripeSubscriptionId: response.id,
      stripeCurrentPeriodEnd: response.current_period_end
    };
    
    // Store the Subscription and payment status in the app
    yield put(
      setMembershipStatusAction({
        subscriptionId: activeSubscription.id,
        subscriptionLevel: activeSubscription.level,
        subscriptionCode: activeSubscription.code !== SUBSCRIPTION_CODE_PUBLIC ? activeSubscription.code : '',
        stripeSubscription,
        paymentVerified: true
      })
    );
    
    yield put(setUserAction({
      ...user,
      memberStatus: getMembershipStatus(activeSubscription,stripeSubscription),
      activeSubscription
    }));
  
    // Store the Subscription and payment status in the remote data store
    yield put(upsertMembershipStatusAction());
    yield put(addEmailNotificationAction({
      topic: NOTIFICATION_TOPIC_SUBSCRIPTION_STARTED,
      summaryText: 'Subscription Payment Successful',
      payload: `Congratulations, You are now a Formigio Member on the ${activeSubscription.name} plan. Happy Accomplishing! Congratulations!`,
    }));

    yield put(
      addNotificationAction(
        getSystemNotificationStruct({
          message: (
            <div>
              <Typography>Subscription Payment Successful!</Typography>
              <Typography>
                You are now a Formigio Member -{' '}
                {activeSubscription.name}. Happy Accomplishing!
                Congratulations!
              </Typography>
            </div>
          ),
          type: 'dialog',
          level: 'success'
        })
      )
    );

    yield put({ type: STRIPE_PAYMENT_SUCCESS_ACTION });
    
  } catch (err) {
    
    yield put({ type: STRIPE_PAYMENT_FAILURE_ACTION, payload: err });
    yield put(setLoadingAction(''));
    yield put(
      addNotificationAction(
        getSystemNotificationStruct({
          message:
            'The payment was unsuccessful. Please try again! (Unknown Error)',
          type: 'snackbar',
          level: 'error'
        })
      )
    );
  }
}

function* handleStripeChange({ payload: subscriptionPlanId }) {
  const user = yield select(store => store.user);
  const membership = yield select(store => store.membership);
  const subscriptions = yield select(store => store.app.subscriptions);
  const { stripeSubscription: { stripeSubscriptionId } } = membership;
  
  yield put(setLoadingAction('Sending Subscription Change to Stripe...'));
  
  try {
    const response = yield postSubscriptionChange(stripeSubscriptionId, subscriptionPlanId);
  
    const activeSubscription = subscriptions.find(subscription => subscription.stripePlanId === subscriptionPlanId);
    
    const stripeSubscription = {
      ...membership.stripeSubscription,
      stripeSubscriptionType: response.plan.id,
      stripeSubscriptionId: response.id,
      stripeCurrentPeriodEnd: response.current_period_end
    };
    
    // Store the Subscription and payment status in the app
    yield put(
      setMembershipStatusAction({
        subscriptionId: activeSubscription.id,
        subscriptionLevel: activeSubscription.level,
        stripeSubscription,
        paymentVerified: true
      })
    );
    
    yield put(setUserAction({
      ...user,
      memberStatus: getMembershipStatus(activeSubscription,stripeSubscription),
      activeSubscription
    }));
  
    // Store the Subscription and payment status in the remote data store
    yield put(upsertMembershipStatusAction());
  
    yield put(
      addEmailNotificationAction({
        topic: NOTIFICATION_TOPIC_SUBSCRIPTION_CHANGED,
        payload: `Congratulations, You are now a Formigio Member on the ${activeSubscription.name} plan. Happy Accomplishing! Congratulations!`,
        summaryText: "Subscription Change Successful!",
      })
    );
  
    yield put(
      addNotificationAction(
        getSystemNotificationStruct({
          message: (
            <div>
              <Typography>Subscription Change Successful!</Typography>
              <Typography>
                You are now a Formigio Member on the {' '}
                {activeSubscription.name} plan. Happy Accomplishing!
                Congratulations!
              </Typography>
            </div>
          ),
          type: 'dialog',
          level: 'success'
        })
      )
    );
    
    yield put(setLoadingAction(''));
  
    yield put({ type: STRIPE_CHANGE_SUCCESS_ACTION });
    
  } catch(err) {
    yield put({ type: STRIPE_CHANGE_FAILURE_ACTION, payload: err });
    yield put(setLoadingAction(''));
    yield put(
      addNotificationAction(
        getSystemNotificationStruct({
          message:
            'The payment was unsuccessful. Please try again! (Unknown Error)',
          type: 'snackbar',
          level: 'error'
        })
      )
    );
  }
}

function* handleSubscriptionFetch({ payload: code }) {
  const user = yield select(store => store.user);
  
  // If the user is unknown, then we don't need to load the subscriptions
  if (!user.status) {
    return 0;
  }
  
  yield put(setLoadingAction('Setting up...'));
  
  const paymentKey = yield select(store => store.app.paymentKey);
  if (!paymentKey) {
    const stripeInfo = yield getInfo();
    yield put(setPaymentKeyAction(stripeInfo.pub));
  }

  let membership = yield select(store => store.membership);

  if (!membership.id) {
    yield put(fetchMembershipStatusAction());
    yield take(MEMBERSHIP_FETCH_STATUS_SUCCESS_ACTION);
  }

  const { subscriptionCode } = yield select(store => store.membership);

  const subscriptions = yield fetchRemoteSubscriptions(code || subscriptionCode);
  yield put(setSubscriptionsAction(subscriptions));

  if (membership.subscriptionId !== user.activeSubscription.id) {
    const activeSubscription = subscriptions.find(subscription => subscription.id === membership.subscriptionId);
    yield put(setUserAction({
      ...user,
      memberStatus: getMembershipStatus(activeSubscription),
      activeSubscription: activeSubscription
    }));
  }

  yield put(setLoadingAction(''));
  
  yield put({type: APPLICATION_SUBSCRIPTIONS_SUCCESS_ACTION});
}

function* handlePayGateCheck() {
  const activeSubscription = yield select(store => store.user.activeSubscription);
  const trackers = yield select(store => store.trackers);
  const activeTrackers = trackers.filter(tracker => tracker.status === TRACKER_STATUS_ACTIVE);
  const allowed = trackerAddAllowed(activeSubscription.level, activeTrackers.length);
  // Check if the user is allowed to add more data
  yield put(setPayGateAction(!allowed));
  
  yield put({type: allowed ? PAYMENT_GATE_ALLOW_ACTION : PAYMENT_GATE_REJECT_ACTION});
}

export default function* paymentSaga() {
  yield takeLatest(NO_PAYMENT_REQUEST_ACTION, handleNoPaymentNeeded);
  yield takeLatest(STRIPE_PAYMENT_REQUEST_ACTION, handleStripePayment);
  yield takeLatest(STRIPE_CANCEL_REQUEST_ACTION, handleCancelStripePayment);
  yield takeLatest(STRIPE_CHANGE_REQUEST_ACTION, handleStripeChange);
  yield takeLatest(STRIPE_CHECK_REQUEST_ACTION, handleCheckStripePayment);
  yield takeLatest([
    APPLICATION_DATA_REQUEST_ACTION,
    APPLICATION_SUBSCRIPTIONS_REQUEST_ACTION
  ], handleSubscriptionFetch);
  yield takeLatest(PAYMENT_GATE_CHECK_ACTION, handlePayGateCheck);
  yield takeLatest(SUBSCRIPTION_START_REQUEST_ACTION, handleSubscriptionStart);
}
