import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader/CardHeader';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import IconButton from '@material-ui/core/IconButton/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import InfoIcon from '@material-ui/icons/Info';
import PostAddIcon from '@material-ui/icons/PostAdd';
import List from '@material-ui/core/List';
import CardActions from '@material-ui/core/CardActions';
import { withStyles } from '@material-ui/core/styles';
import Masonry from 'react-masonry-css';
import Grid from '@material-ui/core/Grid';
import Style from './Style';
import {
  ActiveTrackerStruct,
  getTrackerDocumentStruct,
  getTrackerStruct, TRACKER_STATUS_ACTIVE, TRACKER_STATUS_ARCHIVED
} from '../../structs/trackers';
import { TrackerIndexStruct } from '../../structs/indexers';
import JournalList from './Journal/List';
import SuccessList from './Success/List';
import Tour from '../../containers/Tour';
import HoverButton from '../Ui/HoverButton';
import ReviewCycle from '../../containers/Tracker/ReviewCycle';
import { getStartReviewDate } from '../../models/tracker';
import CommitmentItem from '../../containers/Commitment/Item';
import TrackerLearnItems from './Learn/Items';
import TrackerMeasureMetrics from './Measure/Metrics';
import PlanList from "../../containers/Plan/List";
import CommitButton from '../../containers/Commitment/CommitButton';
import {getTrackerApn} from '../../utils/apn/v2';
import ShareList from './Share/List';
import {getPermissionLevelLabel, isReadOnly} from '../../models/trackerShare';
import Manage from "./ReviewCycle/Manage";
import ReviewNextButton from "./ReviewNextButton";
import Timeline from "./Activity/Timeline";
import {stringifyApn} from "../../utils/apn/v2";
import RoleButton from "../Role/Button";
import TrackerMenu from "./TrackerMenu";
import ActiveInvitesList from "../CommitInvite/ActiveInvitesList";
import Chip from "@material-ui/core/Chip";

class Tracker extends Component {
  state = {
    tracker: getTrackerStruct()
  };

  componentDidMount() {
    const { activeTrackerDoc, match, resetActiveTracker } = this.props;
    const { tracker: requestedActiveTracker } = activeTrackerDoc;
    const { tracker } = this.state;
    const { params } = match;
    const { id } = params;

    if (!requestedActiveTracker) {
      resetActiveTracker();
      return;
    }

    if (
      this.indexPopulated() &&
      ((!requestedActiveTracker.id && !activeTrackerDoc.requestedId) ||
        (activeTrackerDoc.requestedId &&
          requestedActiveTracker.id !== activeTrackerDoc.requestedId) ||
        activeTrackerDoc.requestedId !== id)
    ) {
      this.handleRequest(id);
      return;
    }

    if (requestedActiveTracker.id && tracker.id !== requestedActiveTracker.id) {
      this.hydrateState(requestedActiveTracker);
    }
  }

  componentDidUpdate() {
    const { activeTrackerDoc, match, resetActiveTracker } = this.props;
    const { tracker: requestedActiveTracker } = activeTrackerDoc;
    const { tracker } = this.state;
    const { params } = match;
    const { id } = params;

    if (!requestedActiveTracker) {
      resetActiveTracker();
      return;
    }
    
    if (!this.indexPopulated() &&
      !this.existsInIndex(id)) {
      return;
    }

    if (!requestedActiveTracker.id && !activeTrackerDoc.requestedId && tracker.id) {
      this.hydrateState(requestedActiveTracker);
    }
    
    if (
      ((activeTrackerDoc.requestedId &&
          requestedActiveTracker.id !== activeTrackerDoc.requestedId) ||
        activeTrackerDoc.requestedId !== id)
    ) {
      this.handleRequest(id);
      return;
    }

    if (
      tracker.id !== requestedActiveTracker.id ||
      tracker.updatedAt !== requestedActiveTracker.updatedAt
    ) {
       this.hydrateState(requestedActiveTracker);
    }
  }
  
  hydrateState = activeTracker => {
    const tracker = getTrackerStruct(activeTracker);
    this.setState({
      tracker,
      requestedId: tracker.id
    });
  };

  handleRequest = id => {
    const { requestedId } = this.state;
    const { requestActiveTracker } = this.props;
    
    if (requestedId !== id) {
      this.setState(
        {
          requestedId: id
        },
        () => requestActiveTracker(id)
      );
    }
  };

  debounceSave = debounce(() => {
    const { tracker } = this.state;
    const { saveTracker } = this.props;
    saveTracker(tracker);
  }, 650);

  indexPopulated = () => {
    const { trackerIndex } = this.props;
    return Boolean(trackerIndex.all.length);
  };

  existsInIndex = id => {
    const { trackerIndex } = this.props;
    return Boolean(trackerIndex.byId[id]);
  };

  handleChange = event => {
    const { tracker } = this.state;
    tracker[event.target.name] = event.target.value;
    this.setState({ tracker }, () => {
      this.debounceSave();
    });
  };

  handleSuccessChange = success => {
    const { tracker } = this.state;
    tracker.success = success.map(m => m);
    this.setState({ tracker },() => {
      this.debounceSave();
    });
  };

  handleLearnChange = learn => {
    const { tracker } = this.state;
    tracker.learn = learn.map(m => m);
    this.setState({ tracker },() => {
      this.debounceSave();
    });
  };

  handleMetricsChange = metrics => {
    const { tracker } = this.state;
    tracker.metrics = metrics.map(m => m);
    this.setState({ tracker }, () => {
      this.debounceSave();
    });
  };

  handlePlanChange = plans => {
    const { tracker } = this.state;
    this.setState({
      tracker: {
        ...tracker,
        plans: [...plans]
      }
    }, () => {
      this.debounceSave()
    });
  };

  handleReviewCycleChange = reviewCycle => {
    const { tracker } = this.state;
    const { trackerIndex, saveTrackerShare } = this.props;
    const { byId } = trackerIndex;
    const trackerDoc = getTrackerDocumentStruct(byId[tracker.id]);
    const { share } = trackerDoc;

    if (share && share.id) {
      saveTrackerShare({
        ...share,
        reviewCycle
      });
    } else {
      this.setState({ tracker: {...tracker, reviewCycle} },() => {
        this.debounceSave();
      });
    }
  };

  handleRoleChange = (role) => {
    const { tracker } = this.state;
    const { trackerIndex, saveTrackerShare } = this.props;
    const { byId } = trackerIndex;
    const trackerDoc = getTrackerDocumentStruct(byId[tracker.id]);
    const { share } = trackerDoc;

    if (share && share.id) {
      saveTrackerShare({
        ...share,
        role
      });
    } else {
      this.setState({ tracker: {...tracker, role }},() => {
        this.debounceSave();
      });
    }
  };

  handleUnArchive = () => {
    const { saveTracker } = this.props;
    const { tracker } = this.state;
    const archivedTracker = Object.assign({}, tracker, { status: TRACKER_STATUS_ACTIVE });
    saveTracker(archivedTracker);
  };

  getSuccessProgress = () => {
    const { tracker } = this.state;
    const completed = tracker.success.filter(item => item.checked);
    return Math.ceil(Number(completed.length / tracker.success.length) * 100);
  };

  getMilestoneProgress = () => {
    const { tracker } = this.state;
    const completed = tracker.milestones.filter(item => item.reached);
    return Math.ceil(
      Number(completed.length / tracker.milestones.length) * 100
    );
  };

  render() {
    const { classes, trackerIndex, user: { id: userId }} = this.props;
    const { tracker } = this.state;
    const { byId } = trackerIndex;
    const trackerDoc = getTrackerDocumentStruct(byId[tracker.id]);
    const { activeCommitments, share, reviewCycle, role } = trackerDoc;
    const reviewStartDate = getStartReviewDate(reviewCycle);
    const readOnly = isReadOnly(share);
    const isOwner = stringifyApn({userId}) === tracker.ownerApn;
    
    return (
      <Grid item container>
        <Grid row container direction="row">
          <Grid item className={classes.heading} xs={12} md={8}>
            <TextField
              id="dream"
              key={tracker.id}
              name="dream"
              multiline
              fullWidth
              defaultValue={tracker.dream}
              margin="normal"
              label="Dream Statement"
              disabled={readOnly}
              className={classes.textField}
              InputProps={{
                style: {
                  fontSize: '1.5rem'
                },
                className: classes.input,
                disableUnderline: true
              }}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item className="tour-review" xs={12} md={4}>
            {tracker.status === TRACKER_STATUS_ARCHIVED ? (
              <HoverButton
                variant="text"
                color="secondary"
                onClick={this.handleUnArchive}
                className={classes.button}
                disabled={readOnly}
                hoverContent="Make Active"
              >
                Archived
              </HoverButton>
            ) : (
              <>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginTop: '1em', position: 'relative', flexGrow: 1 }}>
                    <ReviewCycle
                      reviewCycle={reviewCycle}
                      onChange={this.handleReviewCycleChange}
                    />
                  </div>
                  <div style={{ marginTop: '.5em', position: 'relative' }}>
                    <TrackerMenu tracker={tracker} isOwner={isOwner} readOnly={readOnly} />
                  </div>
                </div>
                <Manage
                  reviewCycle={reviewCycle}
                  onChange={this.handleReviewCycleChange}
                />
              </>
            )}
            <ReviewNextButton tracker={tracker} />
          </Grid>
          <RoleButton role={role} onChange={this.handleRoleChange}/>
          {share.id ? <Chip avatar={<InfoIcon fontSize={"small"} />} label={`Shared with you as ${getPermissionLevelLabel(share.permissionLevel)}`} /> : null}
        </Grid>
        <Grid row>
          <LinearProgress
            variant="determinate"
            value={this.getSuccessProgress()}
          />
          <LinearProgress
            variant="determinate"
            value={this.getMilestoneProgress()}
          />
        </Grid>
        <Grid row style={{ width: '100%' }}>
          <Masonry
            breakpointCols={{
              default: 3,
              1330: 2,
              960: 1
            }}
            className={classes.grid}
            columnClassName={classes.gridColumn}
          >
            <div>
              {/** Success Card */}
              <Card elevation={0}>
                <CardHeader
                  classes={{ root: classes.cardHeader }}
                  className={classes.success}
                  title="Success"
                  disableTypography
                />
                <CardContent>
                  <Typography gutterBottom variant="caption">
                    List of things that need to be in place for you to feel
                    satisfied in the end.
                  </Typography>
                  <SuccessList
                    tracker={tracker}
                    onChange={this.handleSuccessChange}
                    editable={!readOnly}
                  />
                </CardContent>
              </Card>
            </div>

            <div>
              <Card
                elevation={0}
                classes={{ root: classes.activeCommitments }}
              >
                <CardHeader
                  classes={{ root: classes.cardHeader }}
                  title="Active Commitments"
                  style={{
                    color: '#fd7a6c'
                  }}
                  disableTypography
                />
                {activeCommitments && activeCommitments.length ? (
                  <CardContent
                    style={{
                      border: '#fd7a6c solid 1px',
                      borderRadius: 5,
                      padding: 0
                    }}
                  >
                    <List>
                      {activeCommitments.map(commitmentDoc => (
                        <CommitmentItem
                          key={commitmentDoc.entity.id}
                          showDate
                          commitment={commitmentDoc}
                        />
                      ))}
                    </List>
                  </CardContent>
                ) : null}

                <ActiveInvitesList tracker={tracker} isOwner={isOwner} isReadOnly={readOnly} />

                <CardActions>
                  <CommitButton
                    style={{margin: 'auto'}}
                    context={{
                      apn: getTrackerApn(tracker),
                      description: tracker.dream,
                      tracker
                    }}
                    startIcon={<PostAddIcon/>}
                  >
                    Commit
                  </CommitButton>
                </CardActions>
              </Card>

              {/** Plan Card */}
              <Card elevation={0}>
                <CardHeader
                  classes={{ root: classes.cardHeader }}
                  className={`${classes.plan}`}
                  title="Plan"
                  disableTypography
                />
                <CardContent className="tour-milestone-summary">
                  <Typography gutterBottom variant="caption">
                    Milestones & Outcomes
                    <Tooltip
                      classes={{
                        popper: classes.infoPopper,
                        tooltip: classes.infoTooltip
                      }}
                      title="Remember that focusing on outcomes brings better results. Milestones should describe the outcome, not what you need to do to get the outcome."
                    >
                      <IconButton size="small" style={{ padding: 0 }}>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                  <PlanList
                    tracker={tracker}
                    readOnly={readOnly}
                    onChange={this.handlePlanChange}
                  />
                </CardContent>
              </Card>
            </div>

            <div>
              {/** Learn Card */}
              <Card className="tour-learn" elevation={0}>
                <CardHeader
                  classes={{ root: classes.cardHeader }}
                  className={`${classes.learn}`}
                  title="Learn"
                  disableTypography
                />
                <CardContent>
                  <Typography gutterBottom variant="caption">
                    Information about what it takes to accomplish your dream.
                  </Typography>
                  <TrackerLearnItems
                    tracker={tracker}
                    readOnly={readOnly}
                    onChange={this.handleLearnChange}
                  />
                </CardContent>
              </Card>
            </div>

            <div>
              {/** Progress Card */}
              <Card elevation={0}>
                <CardHeader
                  classes={{ root: classes.cardHeader }}
                  className={classes.progress}
                  title="Progress"
                  disableTypography
                />
                <CardContent>
                  <Typography
                    className={`${classes.itemTitle} tour-measure-tracker`}
                    gutterBottom
                    variant="h5"
                    component="h2"
                  >
                    Measure
                  </Typography>
                  <TrackerMeasureMetrics
                    tracker={tracker}
                    readOnly={readOnly}
                    onChange={this.handleMetricsChange}
                  />
                  <hr />
                  <Typography
                    className={`${classes.itemTitle} tour-journal-tracker`}
                    gutterBottom
                    variant="h5"
                    component="h2"
                  >
                    Journal
                  </Typography>
                  <JournalList
                    trackerId={tracker.id}
                  />
                </CardContent>
              </Card>
            </div>

            <div>
              <div className={`${classes.notes} tour-notes`}>
                <span className={classes.notesLabel}>Notes / Scratch Pad</span>
                <TextField
                  id="notes"
                  name="notes"
                  key={tracker.id}
                  disabled={readOnly}
                  multiline
                  defaultValue={tracker.notes === ' ' ? '' : tracker.notes}
                  margin="normal"
                  placeholder={readOnly ? '' : 'Makes some notes'}
                  className={classes.textField}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true
                  }}
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div>
              <ShareList classes={classes} trackerDoc={trackerDoc} isOwner={isOwner} />
              <Card elevation={0} style={{marginBottom: '1em'}}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="caption">
                    Created: {new Date(tracker.createdAt).toLocaleString()}
                  </Typography>
                  <Typography variant="caption">
                    Updated: {new Date(tracker.updatedAt).toLocaleString()}
                  </Typography>
                  <Typography variant="caption">
                    Reviewed:{' '}
                    {reviewCycle.lastReviewedDate
                      ? new Date(reviewCycle.lastReviewedDate).toLocaleString()
                      : 'Not Reviewed'}
                  </Typography>
                  <Typography variant="caption">
                    Repeat Start: {reviewStartDate.toDateString()}
                  </Typography>
                  <Typography variant="caption">
                    Next Review:{' '}
                    {new Date(trackerDoc.reviewDate).toDateString()}
                  </Typography>
                </CardContent>
              </Card>
              <Timeline key={tracker.id} tracker={tracker}/>
            </div>
          </Masonry>
        </Grid>

        <Tour
          id="tracker-into-v1.1"
          continuous
          disableScrolling={false}
          steps={[
            {
              target: '.tour-welcome',
              placement: 'top-start',
              content:
                'Tracker pages are the life blood of The Accomplishment System. ' +
                'The trick to accomplishment is regular review of your dream ' +
                'to re-focus and re-energize you. It also helps to see your ' +
                'progress over time.'
            },
            {
              target: '.tour-review',
              content:
                'Each time you review your Tracker page, you will need to ' +
                'mark the Tracker page as "reviewed" so the system can know ' +
                'to reset the schedule for that cycle.'
            },
            {
              target: '.tour-notes',
              content:
                'Here you can make some notes about the tracker. This is a ' +
                'simple sticky note type thing, just to jot something quickly. ' +
                'If you want to make official progress notes you would use the ' +
                'Journal entries, and if you want to capture links or information ' +
                'about resources, those normally go in the Learn section.'
            },
            {
              target: '.tour-learn',
              content:
                'Tracker pages have a Learn section. This is an area to ' +
                'make and keep notes about what it takes to accomplish your dream.'
            },
            {
              target: '.tour-milestone-summary',
              content:
                'In the plan section we list desired outcomes along the ' +
                'accomplishment path. We call these outcomes, Milestones. We like ' +
                'to think of these as things that mark our progress along the path.'
            },
            {
              target: '.tour-measure-tracker',
              content:
                'The Measure Section holds Metrics that you define, and the values ' +
                'that you can record over time.'
            },
            {
              target: '.tour-journal-tracker',
              content:
                'Journal entries are a great way to keep notes about progress ' +
                'towards a dream. They will be a great thing to review if ' +
                'things get a little hard or discouraging. Remember, if you ' +
                'want things to change, you need to do things differently.'
            }
          ]}
        />
      </Grid>
    );
  }
}

Tracker.defaultProps = {
  activeTrackerDoc: ActiveTrackerStruct,
  trackerIndex: TrackerIndexStruct,
  saveTracker: () => null,
  deleteTracker: () => null,
  copyTracker: () => null,
  match: { params: { id: '' } }
};

Tracker.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.any.isRequired,
  activeTrackerDoc: PropTypes.any,
  trackerIndex: PropTypes.any,
  resetActiveTracker: PropTypes.func.isRequired,
  requestActiveTracker: PropTypes.func.isRequired,
  saveTracker: PropTypes.func,
  copyTracker: PropTypes.func,
  match: PropTypes.any
};

export default withStyles(Style)(Tracker);
