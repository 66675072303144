import { put, select, takeLatest } from 'redux-saga/effects';
import moment from 'moment';

import {
  ADD_TIMER,
  DELETE_TIMER,
  SAVE_TIMER,
  TIMER_CREATE_REQUEST_ACTION,
  TIMER_CREATE_SUCCESS_ACTION,
  TIMER_DELETE_REQUEST_ACTION,
  TIMER_DELETE_SUCCESS_ACTION,
  TIMER_UPDATE_REQUEST_ACTION,
  TIMER_UPDATE_SUCCESS_ACTION,
  loadTimersFromDiskAction,
  TIMER_DATA_LOAD_SUCCESS_ACTION,
  LOAD_TIMERS_FROM_DISK,
  TIMER_DATA_LOAD_REQUEST_ACTION
} from '../actions/timers';
import { getTimerStruct, migrateTimers } from '../../structs/timers';
import { loadFromStorage } from '../../utils/localStorage';
import {
  APPLICATION_DATA_CLEAR_REQUEST_ACTION,
  APPLICATION_DATA_REQUEST_ACTION
} from '../actions/app';
import { stringifyApn } from '../../utils/apn/v2';

function* handleCreateTimerRequest(action) {
  const user = yield select(store => store.user);
  // Persist Timer to Local Data Store
  const timer = getTimerStruct(action.payload);
  timer.updatedAt = moment().format();
  timer.ownerApn = stringifyApn({ userId: user.id });

  yield put({
    type: ADD_TIMER,
    payload: timer
  });

  // Signal Complete
  yield put({
    type: TIMER_CREATE_SUCCESS_ACTION
  });
}

function* handleUpdateTimerRequest(action) {
  // Persist Timer to Local Data Store
  const timer = getTimerStruct(action.payload);
  timer.updatedAt = moment().format();
  timer.date = moment(timer.date).format();

  yield put({
    type: SAVE_TIMER,
    payload: timer
  });

  // Signal Complete
  yield put({
    type: TIMER_UPDATE_SUCCESS_ACTION
  });
}

function* handleDeleteTimerRequest(action) {
  // Persist Timer to Local Data Store
  yield put({
    type: DELETE_TIMER,
    payload: action.payload
  });

  // Signal Complete
  yield put({
    type: TIMER_DELETE_SUCCESS_ACTION
  });
}

function* handleTimerDataLoadRequest(action) {
  // Persist Timer to Local Data Store
  yield put({
    type: LOAD_TIMERS_FROM_DISK,
    payload: action.payload
  });

  // Signal Complete
  yield put({
    type: TIMER_DATA_LOAD_SUCCESS_ACTION
  });
}

function* handleLoadApplicationDataRequest() {
  // Perform Application Data Load
  const timersState = yield select(store => store.timers);
  const user = yield select(store => store.user);

  // Call the Util Local Storage loadState function which pulls data
  // from the local storage if cached there, if not it pulls from the disk
  const timers = yield loadFromStorage('timers', timersState);
  const migratedTimers = yield migrateTimers(timers, user);

  yield put(loadTimersFromDiskAction(migratedTimers));

  yield put({ type: TIMER_DATA_LOAD_SUCCESS_ACTION });
}

function* handleStateChange() {
  const type = 'timers';

  // Select data from the store
  const data = yield select(store => store[type]);
  const dataString = JSON.stringify(data);

  // As well as in the local storage.
  localStorage.setItem(type, dataString);
}

function* handleApplicationDataClear() {
  yield put(loadTimersFromDiskAction([]));
}

export default function* timerSaga() {
  yield takeLatest(TIMER_CREATE_REQUEST_ACTION, handleCreateTimerRequest);
  yield takeLatest(TIMER_UPDATE_REQUEST_ACTION, handleUpdateTimerRequest);
  yield takeLatest(TIMER_DELETE_REQUEST_ACTION, handleDeleteTimerRequest);
  yield takeLatest(TIMER_DATA_LOAD_REQUEST_ACTION, handleTimerDataLoadRequest);
  yield takeLatest(TIMER_CREATE_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(TIMER_UPDATE_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(TIMER_DELETE_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(
    APPLICATION_DATA_REQUEST_ACTION,
    handleLoadApplicationDataRequest
  );
  yield takeLatest(
    APPLICATION_DATA_CLEAR_REQUEST_ACTION,
    handleApplicationDataClear
  );

  yield takeLatest(TIMER_DATA_LOAD_SUCCESS_ACTION, handleStateChange);
}
