// User Actions
import { getUserStruct } from '../../structs/user';

export const SET_USER = 'SET_USER';
export const LOAD_USER_FROM_DISK = 'LOAD_USER_FROM_DISK';
export const SAVE_USER_NOTES = 'SAVE_USER_NOTES';
export const USER_ACCEPT_LOGIN_SUCCESS_ACTION = 'USER_ACCEPT_LOGIN_SUCCESS';
export const USER_ACCEPT_REGISTRATION_SUCCESS_ACTION =
  'USER_ACCEPT_REGISTRATION_SUCCESS';
export const USER_ACCOUNT_INITIALIZE_REQUEST_ACTION = 'USER_ACCOUNT_INITIALIZE_REQUEST';
export const USER_ACCOUNT_INITIALIZE_SUCCESS_ACTION = 'USER_ACCOUNT_INITIALIZE_SUCCESS';

export const setUserAction = user => ({
  type: SET_USER,
  payload: getUserStruct(user)
});

export const loadUserFromDiskAction = user => ({
  type: LOAD_USER_FROM_DISK,
  payload: user
});

export const saveUserNotesAction = notes => ({
  type: SAVE_USER_NOTES,
  payload: notes
});
