import moment from 'moment';
import { select, put, takeLatest, takeEvery, all, take } from 'redux-saga/effects';

import {
  SAVE_USER_EMAIL_PREFERENCE,
  FETCH_EMAIL_PREFERENCE,
  SET_USER_NOTIFICATIONS,
  LIST_USER_NOTIFICATION,
  UPDATE_USER_NOTIFICATION,
  SET_USER_EMAIL_PREFERENCE,
  EMAIL_NOTIFICATIONS_FETCH_SUCCESS_ACTION,
  FETCH_EMAIL_PREFERENCE_SUCCESS_ACTION,
  INITIALIZE_EMAIL_PREFERENCE_REQUEST_ACTION,
  INITIALIZE_EMAIL_PREFERENCE_SUCCESS_ACTION,
  addNotificationAction,
  SET_USER_SMS_PREFERENCE_ACTION,
  SAVE_USER_SMS_PREFERENCE_ACTION,
  FETCH_SMS_PREFERENCE_SUCCESS_ACTION,
  FETCH_SMS_PREFERENCE_ACTION,
  ADD_SMS_NOTIFICATION_ACTION,
  showErrorAction, VERIFY_SMS_ACTION, ADD_EMAIL_NOTIFICATION_ACTION, addEmailNotificationAction,
} from '../actions/notifications';

import {
  saveEmailPreference,
  fetchEmailPreferences,
  createRemoteEmailNotification,
  fetchUserEmailNotifications,
  updateNotificationById,
  fetchUserScheduledEmailNotifications,
  fetchUserSentEmailNotifications,
  deleteRemoteEmailNotification,
} from '../../utils/appSync/emailNotification';
import {
  getEmailPreferenceStruct, getSMSNotificationStruct,
  getSystemNotificationStruct, NOTIFICATION_TOPIC_SMS_VERIFY,
} from '../../structs/notification';
import {SET_USER} from '../actions/user';
import {fetchSMSPreference, saveSMSNotification, saveSMSPreference} from "../../utils/appSync/smsNotification";
import {APPLICATION_FULL_SYNC_REQUEST_ACTION} from "../actions/appSync";


function* handleSMSNotificationPreferenceSave({ payload: { number, verifiedAt, unsubscribedTopics } }) {
  const user = yield select((store) => store.user);

  try {
    const preference = yield saveSMSPreference({
      number,
      verifiedAt,
      unsubscribedTopics,
      userApn: user.id
    });

    yield put({
      type: SET_USER_SMS_PREFERENCE_ACTION,
      payload: preference,
    });

  } catch (err) {
    yield put(
      addNotificationAction(
        getSystemNotificationStruct({
          message: 'SMS Preferences Failed to Save',
        })
      )
    );
  }
}

function* handleEmailNotificationPreferenceSave({ payload: { unsubscribedTopics }}) {
  const user = yield select((store) => store.user);

  try {
    const preference = yield saveEmailPreference({
      userApn: user.id,
      emailAddress: user.attributes.email,
      unsubscribedTopics,
    });
    yield put({
      type: SET_USER_EMAIL_PREFERENCE,
      payload: preference,
    });
  
  } catch (err) {
    yield put(
      addNotificationAction(
        getSystemNotificationStruct({
          message: 'Email Preferences Failed to Save',
        })
      )
    );
  }
}

function* handleFetchEmailPreference() {
  const user = yield select((store) => store.user);
  
  if(!user.id) {
    yield take(SET_USER);
  }

  try {
    let preferences = yield fetchEmailPreferences(user);

    if (!preferences) {
      yield put({
        type: INITIALIZE_EMAIL_PREFERENCE_REQUEST_ACTION
      });
      yield take(INITIALIZE_EMAIL_PREFERENCE_SUCCESS_ACTION);
      preferences = yield fetchEmailPreferences(user);
    }

    yield put({
      type: SET_USER_EMAIL_PREFERENCE,
      payload: preferences,
    });
    yield put({
      type: FETCH_EMAIL_PREFERENCE_SUCCESS_ACTION
    });
  } catch (e) {
    console.log('', e);
  }
}

function* handleFetchSMSPreference() {
  const user = yield select((store) => store.user);

  let preferences = yield fetchSMSPreference(user);

  yield put({
    type: SET_USER_SMS_PREFERENCE_ACTION,
    payload: preferences,
  });
  yield put({
    type: FETCH_SMS_PREFERENCE_SUCCESS_ACTION
  });
}

function* handleEmailNotificationCreate({ payload: notification }) {
  const user = yield select((store) => store.user);

  yield createRemoteEmailNotification({
    ...notification,
    userApn: user.id,
    email: notification.email || user.attributes.email,
    sendTimestamp: notification.sendTimestamp
      ? notification.sendTimestamp
      : moment().toISOString(),
  });
}

function* handleCreateSMSNotification({ payload: notification }) {
  const user = yield select((store) => store.user);
  const preference = yield select((store) => store.userSMSPreference.preference);

  if (notification.topic.find(topic => preference.unsubscribedTopics.includes(topic))) {
    // if the user has unsubscribed, we don't send.
    return 0;
  }

  if (preference.number || notification.number) {
    yield saveSMSNotification(getSMSNotificationStruct({
      ...notification,
      userApn: user.id,
      number: notification.number || preference.number,
      sendTimestamp: notification.sendTimestamp
        ? notification.sendTimestamp
        : moment().toISOString(),
    }));
  }

}

function* listUserCreatedNotification() {
  const user = yield select((store) => store.user);
  try {
    const response = yield fetchUserEmailNotifications(user);

    yield put({
      type: SET_USER_NOTIFICATIONS,
      payload: response,
    });
    yield put({
      type: EMAIL_NOTIFICATIONS_FETCH_SUCCESS_ACTION
    });
  } catch (e) {
    yield put(
      addNotificationAction(
        getSystemNotificationStruct({
          message: 'Fetch Notification Failed',
        })
      )
    );
  }
}

function* updateUserNotificationById(notificationDetails) {
  const users = yield select((store) => store.user);
  try {
    yield updateNotificationById({
      userId: users.id,
      notificationDetails: notificationDetails.payload,
    });
  } catch (e) {}
}

function* handleCreateScheduledNotifications() {
  const user  = yield select(store => store.user);
  const scheduledNotifications = yield fetchUserScheduledEmailNotifications(user);

  const next4days = [];
  for (let i = 0; i < 4; i++) {
    const dateTime = new Date().setDate(new Date().getDate() + i + 1);
    
    // Notifications to send at 8am in the users timezone
    const timeStamp = new Date(dateTime).setHours(8, 0, 0, 0);
    // Convert to UTC
    const utcDate = new Date(timeStamp).toISOString();
    next4days.push(utcDate);
  }
  
  const existing = scheduledNotifications.filter(
    (list) => list.topic[0] === 'review_reminder'
  );
  
  const needed = next4days.filter((date) => {
    return !existing.some((item) => item.sendTimestamp === date);
  });

  yield all(needed.map((date) => {
    return put(addEmailNotificationAction({
      topic: 'review_reminder',
      payload: `
         <h1>It's time</h1>
         <p>Good day to you!</p>
         <p>It's time to review and check on your trackers and commitments!</p>
         <a href="https://app.formigio.com/">Review Now</a>
         <p>~~</p>
         <p>We know that accomplishment is most likely reached when we review our dreams and plans regularly.</p>
         <p>~~</p>
         <p>Happy Accomplishing!</p>
         <p>Let us know if you need any help! ~ The Formigio Team</p>
      `,
      summaryText: 'Formigio Planner - Time to Review your Trackers and Commitments!',
      sendTimestamp: date,
    }));
  }));

  // Clean up sent notifications older than 14 days
  const fourteenDaysAgo = moment().subtract(14, "days").utc();
  const oldNotifications = yield fetchUserSentEmailNotifications(user, fourteenDaysAgo);
  yield all(oldNotifications.map((item) => deleteRemoteEmailNotification(item)));
}

function* handlePreferencesInitialize() {
  const user = yield select(store => store.user);
  if(!user.id) {
    yield take(SET_USER);
  }
  
  const preferences = yield fetchEmailPreferences(user);
  
  if (preferences && preferences.userApn)
    return;
  
  yield saveEmailPreference(getEmailPreferenceStruct({
    userApn: user.id,
    emailAddress: user.attributes.email,
    unsubscribedTopics: []
  }));
  
  yield put({ type: INITIALIZE_EMAIL_PREFERENCE_SUCCESS_ACTION });
}

function* handleSMSVerification({ payload: { number, code }}) {
  const smsPreferences = yield select((store) => store.userSMSPreference);
  const { preference, verification_code } = smsPreferences;
  const fullNumber = `1${number}`;

  // Check to see if the verification code entered is the one that we sent.
  if (String(code) === String(verification_code)) {

    yield put({
      type: SAVE_USER_SMS_PREFERENCE_ACTION,
      payload: {
        unsubscribedTopics: preference.unsubscribedTopics,
        number: fullNumber,
        verifiedAt: moment().toISOString()
      },
    });

    yield put({
      type: ADD_SMS_NOTIFICATION_ACTION,
      payload: {
        number: fullNumber,
        topic: [NOTIFICATION_TOPIC_SMS_VERIFY],
        summaryText: `Good News! You have verified your number to receive notifications. It may be good to add this number as a contact in your phone! BTW, you can unsubscribe in Formigio > Settings`,
      }
    });
  } else {
    yield put(showErrorAction('The code that was entered does not match, please try again.'));
  }
}


export default function* notificationSaga() {
  yield takeEvery(
    SAVE_USER_EMAIL_PREFERENCE,
    handleEmailNotificationPreferenceSave
  );
  yield takeEvery(
    SAVE_USER_SMS_PREFERENCE_ACTION,
    handleSMSNotificationPreferenceSave
  );
  yield takeLatest(FETCH_EMAIL_PREFERENCE, handleFetchEmailPreference);
  yield takeLatest(FETCH_SMS_PREFERENCE_ACTION, handleFetchSMSPreference);

  yield takeLatest(ADD_EMAIL_NOTIFICATION_ACTION, handleEmailNotificationCreate);
  yield takeLatest(LIST_USER_NOTIFICATION, listUserCreatedNotification);
  yield takeEvery(UPDATE_USER_NOTIFICATION, updateUserNotificationById);
  yield takeEvery(
    ADD_SMS_NOTIFICATION_ACTION,
    handleCreateSMSNotification
  );
  yield takeEvery(
    VERIFY_SMS_ACTION,
    handleSMSVerification
  );
  yield takeLatest(APPLICATION_FULL_SYNC_REQUEST_ACTION, handleFetchEmailPreference);
  yield takeLatest(APPLICATION_FULL_SYNC_REQUEST_ACTION, handleFetchSMSPreference);
  yield takeEvery(FETCH_EMAIL_PREFERENCE_SUCCESS_ACTION, handleCreateScheduledNotifications);
  yield takeLatest(INITIALIZE_EMAIL_PREFERENCE_REQUEST_ACTION, handlePreferencesInitialize);
}
