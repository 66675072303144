import { getTrackerApn } from '../../utils/apn/v2';
import { getMilestoneStruct } from '../../structs/trackers';

// Milestone Actions
export const ADD_LOCAL_MILESTONE = 'ADD_LOCAL_MILESTONE';
export const SAVE_LOCAL_MILESTONE = 'SAVE_LOCAL_MILESTONE';
export const UPSERT_LOCAL_MILESTONE = 'UPSERT_LOCAL_MILESTONE';
export const REMOVE_LOCAL_MILESTONE = 'REMOVE_LOCAL_MILESTONE';
export const SET_MILESTONES = 'SET_MILESTONES';
export const MILESTONE_CREATE_REQUEST_ACTION = 'MILESTONE_CREATE_REQUEST';
export const MILESTONE_CREATE_SUCCESS_ACTION = 'MILESTONE_CREATE_SUCCESS';
export const MILESTONE_UPDATE_REQUEST_ACTION = 'MILESTONE_UPDATE_REQUEST';
export const MILESTONE_UPDATE_SUCCESS_ACTION = 'MILESTONE_UPDATE_SUCCESS';
export const MILESTONE_UPSERT_REQUEST_ACTION = 'MILESTONE_UPSERT_REQUEST';
export const MILESTONE_UPSERT_SUCCESS_ACTION = 'MILESTONE_UPSERT_SUCCESS';
export const MILESTONE_DELETE_REQUEST_ACTION = 'MILESTONE_DELETE_REQUEST';
export const MILESTONE_DELETE_SUCCESS_ACTION = 'MILESTONE_DELETE_SUCCESS';
export const UPDATE_MILESTONE_INDEX = 'UPDATE_MILESTONE_INDEX';

export const addMilestoneAction = (milestone, tracker) => {
  const trackerApn = getTrackerApn(tracker);
  
  return {
    type: MILESTONE_CREATE_REQUEST_ACTION,
    payload: Object.assign({}, getMilestoneStruct(milestone), {
      trackerApn,
      trackerId: tracker.id
    })
  };
};

export const updateMilestoneAction = milestone => ({
  type: MILESTONE_UPDATE_REQUEST_ACTION,
  payload: milestone
});

export const removeMilestoneAction = milestone => ({
  type: MILESTONE_DELETE_REQUEST_ACTION,
  payload: milestone
});

export const setMilestonesAction = milestones => ({
  type: SET_MILESTONES,
  payload: milestones
});

export const updateMilestoneIndexAction = doc => ({
  type: UPDATE_MILESTONE_INDEX,
  payload: doc
});
