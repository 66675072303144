import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  trade: {
    fontWeight: 400,
    position: 'absolute',
    top: -2,
    right: 4
  },
  tradeContainer: {
    display: 'inline',
    position: 'relative',
    paddingRight: '1rem'
  }
}));

/**
 * Component to display the "Learn More" link
 *
 * @param {Object} props
 */
const Trademarked = props => {
  const {
    children,
    styles
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.tradeContainer}>
      {children}
      <Typography component="span" className={classes.trade} style={styles}>&reg;</Typography>
    </div>
  );
};

Trademarked.defaultProps = {
  styles: {}
};

Trademarked.propTypes = {
  styles: PropTypes.object
};

export default Trademarked;
