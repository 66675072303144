import { put, takeEvery } from "redux-saga/effects";
import {updateSearchIndexAction} from '../actions/search';
import {APPLICATION_DATA_CLEAR_REQUEST_ACTION} from '../actions/app';
import {SearchIndexDataStruct} from '../../structs/indexers';

function* handleDataClear(action) {
    yield put(updateSearchIndexAction(SearchIndexDataStruct));
}

export default function* searchSaga() {
    yield takeEvery(APPLICATION_DATA_CLEAR_REQUEST_ACTION, handleDataClear)
}
