import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import colors from '../../constants/highlightColors.json';
import DialogActions from "@material-ui/core/DialogActions";
import {Brightness1, Brightness1Outlined, CheckCircle} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

const RoleButton = ({ role, onChange }) => {
  const { all } = useSelector(store => store.roles);
  const [ open, setOpen] = useState(false);
  const [ name, setName] = useState('');
  const [ color, setColor] = useState('');

  return (
    <>
      {role.name ? <Button
        onClick={() => {setName(role.name); setColor(role.color); setOpen(true);}}
      >
        <Brightness1 style={{ color: role.color, marginRight: 5 }}/>{' '}
        {role.name}
      </Button> : <Button
        onClick={() => {setName(''); setColor(''); setOpen(true); }}
      >
        <Brightness1Outlined style={{ marginRight: 5 }} />
        Add Role
      </Button>}
      <Dialog
        open={open}
      >
        <DialogTitle>
          Set Role
          <IconButton
            style={{ position: 'absolute', right: 0, top: 0 }}
            onClick={() => setOpen(false)}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {all.length > 0 ? (
            <>
              <Typography>Your roles</Typography>
              <ButtonGroup>
                {all.map((existingRole) => (
                  <Button
                    key={existingRole.name}
                    size="small"
                    disableElevation
                    onClick={() => {setName(existingRole.name); setColor(existingRole.color);}}
                  >
                    {name === existingRole.name
                      ? <CheckCircle style={{ color: existingRole.color, marginRight: 5 }} />
                      : <Brightness1 style={{ color: existingRole.color, marginRight: 5 }} />}
                    {existingRole.name}
                  </Button>
                ))}
              </ButtonGroup>
            </>
          ) : null}
          <Typography>Choose new role</Typography>
          <TextField
            fullWidth
            placeholder="Enter Role Name"
            value={name}
            onChange={({ target: { value }}) => setName(value)}
          />
          <ButtonGroup>
            {colors.map((highlight) => (
              <IconButton
                key={highlight}
                size="small"
                onClick={() => setColor(highlight)}
              >
                {color === highlight ? <CheckCircle style={{ color: highlight }} /> : <Brightness1 style={{ color: highlight }} />}
              </IconButton>
            ))}
          </ButtonGroup>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => { onChange({ name, color }); setOpen(false); }}
          >
            Set Role
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

RoleButton.defaultProps = {};

RoleButton.propTypes = {
  role: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
};

export default RoleButton;
