import { v4 as uuidv4 } from 'uuid';
import { getChecklistStruct } from '../../structs/checklists';

export const ADD_CHECKLIST = 'ADD_CHECKLIST';
export const LOAD_CHECKLISTS_FROM_DISK = 'LOAD_CHECKLISTS_FROM_DISK';
export const DELETE_CHECKLIST = 'DELETE_CHECKLIST';
export const SAVE_CHECKLIST = 'SAVE_CHECKLIST';
export const UPSERT_LOCAL_CHECKLIST = 'UPDATE_CHECKLIST';
export const UPDATE_CHECKLIST_INDEX = 'UPDATE_CHECKLIST_INDEX';
export const CHECKLIST_CREATE_REQUEST_ACTION = 'CHECKLIST_CREATE_REQUEST';
export const CHECKLIST_CREATE_SUCCESS_ACTION = 'CHECKLIST_CREATE_SUCCESS';
export const CHECKLIST_DELETE_REQUEST_ACTION = 'CHECKLIST_DELETE_REQUEST';
export const CHECKLIST_DELETE_SUCCESS_ACTION = 'CHECKLIST_DELETE_SUCCESS';
export const CHECKLIST_UPDATE_REQUEST_ACTION = 'CHECKLIST_UPDATE_REQUEST';
export const CHECKLIST_UPDATE_SUCCESS_ACTION = 'CHECKLIST_UPDATE_SUCCESS';
export const CHECKLIST_DATA_LOAD_REQUEST_ACTION = 'CHECKLIST_DATA_LOAD_REQUEST';
export const CHECKLIST_DATA_LOAD_SUCCESS_ACTION = 'CHECKLIST_DATA_LOAD_SUCCESS';

export const addChecklistAction = list => ({
  type: CHECKLIST_CREATE_REQUEST_ACTION,
  payload: Object.assign({}, getChecklistStruct(list), {
    id: uuidv4()
  })
});

export const loadChecklistsFromDiskAction = lists => ({
  type: CHECKLIST_DATA_LOAD_REQUEST_ACTION,
  payload: lists
});

export const deleteChecklistAction = checklist => ({
  type: CHECKLIST_DELETE_REQUEST_ACTION,
  payload: checklist
});

export const saveChecklistAction = list => ({
  type: CHECKLIST_UPDATE_REQUEST_ACTION,
  payload: list
});

export const upsertLocalChecklistAction = list => ({
  type: UPSERT_LOCAL_CHECKLIST,
  payload: list
});

export const updateChecklistIndexAction = doc => ({
  type: UPDATE_CHECKLIST_INDEX,
  payload: doc
});
