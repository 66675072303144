import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  addTrackerAction,
  deleteTrackerAction,
  saveTrackerAction
} from '../redux/actions/trackers';
import { resetActiveTrackerAction } from '../redux/actions/activeTracker';
import CreateDream from '../components/CreateDream/CreateDream';
import {payGateCheckAction} from '../redux/actions/payment';

export class CreateDreamPageContainer extends Component {
  render() {
    return <CreateDream />;
  }
}

const mapStateToProps = state => ({
  activeTracker: state.activeTracker
});

const mapDispatchToProps = dispatch => ({
  createTracker: tracker => dispatch(addTrackerAction(tracker)),
  saveTracker: tracker => dispatch(saveTrackerAction(tracker)),
  resetActiveTracker: () => dispatch(resetActiveTrackerAction()),
  deleteTracker: tracker => dispatch(deleteTrackerAction(tracker)),
  checkPayGate: () => dispatch(payGateCheckAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateDream);
