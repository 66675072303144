import React, {useCallback, useState} from 'react';
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import List from "@material-ui/core/List/List";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import InviteItem from "./InviteItem";
import {COMMIT_INVITE_STATUS_PENDING, getCommitInviteStruct} from "../../structs/commitInvite";
import EditDialog from "./EditDialog";
import RespondDialog from "./RespondDialog";

const ActiveInvitesList = ({ tracker, isOwner, isReadOnly }) => {
  const commitInvites = useSelector(store => store.commitInvites);
  const [edit, setEdit] = useState(getCommitInviteStruct());
  const [respond, setRespond] = useState(getCommitInviteStruct());
  const activeInvites = commitInvites.length > 0
    ? commitInvites.filter(invite => invite.trackerId === tracker.id && invite.status === COMMIT_INVITE_STATUS_PENDING)
    : [];

  const resetEdit = useCallback(() => {
    setEdit(getCommitInviteStruct());
  }, []);

  const resetRespond = useCallback(() => {
    setRespond(getCommitInviteStruct());
  }, []);

  return (
    <>
      {activeInvites && activeInvites.length ? (
        <>
          <CardHeader
            title="Active Invites"
            style={{
              color: '#fd7a6c',
              paddingTop: '8px',
              paddingBottom: '8px',
              fontWeight: 'bold',
              fontSize: '14px'
            }}
            disableTypography
          />
          <CardContent
            style={{
              border: '#fd7a6c solid 1px',
              borderRadius: 5,
              padding: 0
            }}
          >
            <List>
              {activeInvites.map(invite => (
                <InviteItem
                  key={invite.id}
                  invite={invite}
                  isReadOnly={isReadOnly}
                  isOwner={isOwner}
                  onEdit={setEdit}
                  onRespond={setRespond}
                />
              ))}
            </List>
          </CardContent>
          <EditDialog invite={edit} onReset={resetEdit} />
          <RespondDialog invite={respond} onReset={resetRespond} />
        </>
      ) : null}
    </>
  );
};

ActiveInvitesList.defaultProps = {};

ActiveInvitesList.propTypes = {
  tracker: PropTypes.object.isRequired
};

export default ActiveInvitesList;
