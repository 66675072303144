import React, { Component } from 'react';
import { connect } from 'react-redux';
import JournalPage from '../components/Journal/Page';

export class JournalPageContainer extends Component {
  render() {
    return <JournalPage />;
  }
}

const mapStateToProps = state => ({
  journalIndex: state.journalIndex
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(JournalPage);
