import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { getMetricValueStruct } from '../../../../structs/measure';
import MetricNumberValue from './Value';

export const style = () => ({});

class MetricNumberValues extends Component {
  state = { list: [] };

  componentDidMount() {
    const { values } = this.props;
    const { list } = this.state;

    if (JSON.stringify(values) !== JSON.stringify(list)) {
      this.hydrateState(values);
    }
  }

  componentDidUpdate() {
    const { values } = this.props;
    const { list } = this.state;

    if (JSON.stringify(values) !== JSON.stringify(list)) {
      this.hydrateState(values);
    }
  }

  hydrateState = values => {
    this.setState({ list: [...values] });
  };

  handleItemChange = itemToChange => {
    const { list } = this.state;
    const { onChange } = this.props;
    const newValues = list.map(item => {
      if (item.id === itemToChange.id) {
        return itemToChange;
      }
      return item;
    });
    this.setState({
      list: newValues
    });
    // Persist to Metrics onChange
    onChange(newValues);
  };

  handleAdd = () => {
    this.addValue();
  };

  addValue = () => {
    const { list } = this.state;
    const { onChange } = this.props;

    list.push(getMetricValueStruct());
    onChange(list);
  };

  handleItemRemove = itemToRemove => {
    const { list } = this.state;
    const { onChange } = this.props;

    const newList = list.filter(item => item.id !== itemToRemove.id);

    onChange(newList);
  };

  render() {
    const { readOnly } = this.props;
    const { list } = this.state;

    return (
      <List>
        {list.length
          ? list.map(item => (
              <MetricNumberValue
                key={item.id}
                item={item}
                readOnly={readOnly}
                onChange={this.handleItemChange}
                onDelete={this.handleItemRemove}
              />
            ))
          : null}
        {!readOnly && (<ListItem>
          <Button size="small" onClick={this.handleAdd}>
            Record Value
          </Button>
        </ListItem>)}
      </List>
    );
  }
}

MetricNumberValues.propTypes = {
  values: PropTypes.any.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default withStyles(style)(MetricNumberValues);
