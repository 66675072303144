export const AUTH_SIGN_IN_REQUEST_ACTION = 'AUTH_SIGN_IN_REQUEST';
export const AUTH_SIGN_IN_SUCCESS_ACTION = 'AUTH_SIGN_IN_SUCCESS';
export const AUTH_SIGN_IN_FAILURE_ACTION = 'AUTH_SIGN_IN_FAILURE';
export const AUTH_SIGN_OUT_REQUEST_ACTION = 'AUTH_SIGN_OUT_REQUEST';
export const AUTH_SIGN_OUT_SUCCESS_ACTION = 'AUTH_SIGN_OUT_SUCCESS';
export const AUTH_SIGN_OUT_FAILURE_ACTION = 'AUTH_SIGN_OUT_FAILURE';
export const AUTH_SIGN_UP_REQUEST_ACTION = 'AUTH_SIGN_UP_REQUEST';
export const AUTH_SIGN_UP_SUCCESS_ACTION = 'AUTH_SIGN_UP_SUCCESS';
export const AUTH_SIGN_UP_FAILURE_ACTION = 'AUTH_SIGN_UP_FAILURE';
export const AUTH_CONFIRM_USER_REQUEST_ACTION = 'AUTH_CONFIRM_USER_REQUEST';
export const AUTH_CONFIRM_USER_SUCCESS_ACTION = 'AUTH_CONFIRM_USER_SUCCESS';
export const AUTH_CONFIRM_USER_FAILURE_ACTION = 'AUTH_CONFIRM_USER_FAILURE';
export const AUTH_CONFIRM_EMAIL_REQUEST_ACTION = 'AUTH_CONFIRM_EMAIL_REQUEST';
export const AUTH_CONFIRM_EMAIL_SUCCESS_ACTION = 'AUTH_CONFIRM_EMAIL_SUCCESS';
export const AUTH_CONFIRM_EMAIL_FAILURE_ACTION = 'AUTH_CONFIRM_EMAIL_FAILURE';
export const AUTH_CONFIRMATION_REQUEST_ACTION = 'AUTH_CONFIRMATION_REQUEST';
export const AUTH_CONFIRMATION_SUCCESS_ACTION = 'AUTH_CONFIRMATION_SUCCESS';
export const AUTH_CONFIRMATION_FAILURE_ACTION = 'AUTH_CONFIRMATION_FAILURE';
export const AUTH_RESEND_CODE_REQUEST_ACTION = 'AUTH_RESEND_CODE_REQUEST';
export const AUTH_RESEND_CODE_SUCCESS_ACTION = 'AUTH_RESEND_CODE_SUCCESS';
export const AUTH_RESEND_CODE_FAILURE_ACTION = 'AUTH_RESEND_CODE_FAILURE';
export const AUTH_FORGOT_PASSWORD_REQUEST_ACTION =
  'AUTH_FORGOT_PASSWORD_REQUEST';
export const AUTH_FORGOT_PASSWORD_SUCCESS_ACTION =
  'AUTH_FORGOT_PASSWORD_SUCCESS';
export const AUTH_FORGOT_PASSWORD_FAILURE_ACTION =
  'AUTH_FORGOT_PASSWORD_FAILURE';
export const AUTH_GET_CURRENT_USER_REQUEST_ACTION =
  'AUTH_GET_CURRENT_USER_REQUEST';
export const AUTH_GET_CURRENT_USER_SUCCESS_ACTION =
  'AUTH_GET_CURRENT_USER_SUCCESS';
export const AUTH_GET_CURRENT_USER_FAILURE_ACTION =
  'AUTH_GET_CURRENT_USER_FAILURE';
export const AUTH_CHECK_CURRENT_USER_REQUEST_ACTION =
    'AUTH_CHECK_CURRENT_USER_REQUEST';
export const AUTH_CHECK_CURRENT_USER_SUCCESS_ACTION =
    'AUTH_CHECK_CURRENT_USER_SUCCESS';
export const AUTH_CHECK_CURRENT_USER_FAILURE_ACTION =
    'AUTH_CHECK_CURRENT_USER_FAILURE';
export const AUTH_USER_ATTRIBUTE_UPDATE_REQUEST_ACTION =
  'AUTH_USER_ATTRIBUTE_UPDATE_REQUEST';
export const AUTH_USER_ATTRIBUTE_UPDATE_SUCCESS_ACTION =
  'AUTH_USER_ATTRIBUTE_UPDATE_SUCCESS';
export const AUTH_USER_ATTRIBUTE_UPDATE_FAILURE_ACTION =
  'AUTH_USER_ATTRIBUTE_UPDATE_FAILURE';

export const signInAction = credentials => ({
  type: AUTH_SIGN_IN_REQUEST_ACTION,
  payload: credentials
});

export const signUpAction = user => ({
  type: AUTH_SIGN_UP_REQUEST_ACTION,
  payload: user
});

export const signOutAction = () => ({
  type: AUTH_SIGN_OUT_REQUEST_ACTION
});

export const confirmUserAction = user => ({
  type: AUTH_CONFIRM_USER_REQUEST_ACTION,
  payload: user
});

export const confirmEmailAction = user => ({
  type: AUTH_CONFIRM_EMAIL_REQUEST_ACTION,
  payload: user
});

export const loginAfterConfirmAction = credentials => ({
  type: AUTH_CONFIRMATION_REQUEST_ACTION,
  payload: credentials
});

export const resendCodeAction = username => ({
  type: AUTH_RESEND_CODE_REQUEST_ACTION,
  payload: username
});

export const forgotPasswordAction = user => ({
  type: AUTH_FORGOT_PASSWORD_REQUEST_ACTION,
  payload: user
});

export const getCurrentUserAction = () => ({
  type: AUTH_GET_CURRENT_USER_REQUEST_ACTION
});

export const checkCurrentUserAction = () => ({
  type: AUTH_CHECK_CURRENT_USER_REQUEST_ACTION
});

export const updateUserAttributesAction = user => ({
  type: AUTH_USER_ATTRIBUTE_UPDATE_REQUEST_ACTION,
  payload: user
});
