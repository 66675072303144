import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Style from './Style';
import { UserStruct } from '../../structs/user';
import ROUTES from '../../constants/routes.json';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Trademarked from "../Ui/Trademarked";

class SignUp extends Component {
  state = {
    user: { ...UserStruct },
    touched: [],
    terms: false
  };

  componentDidMount() {
    this.updateUser();
  }

  componentDidUpdate() {
    this.updateUser();
  }

  updateUser = () => {
    const { user: userProp } = this.props;
    const { user } = this.state;

    if (user.updatedAt !== userProp.updatedAt) {
      this.setState({ user: userProp });
    }
  };

  handleAttributeChange = name => event => {
    const { user } = this.state;
    user.attributes[name] = event.target.value;
    this.setState({ user });
  };

  handleAttributeBlur = name => event => {
    const { touched } = this.state;
    touched[name] = true;
    this.setState({ touched });
  };

  handleCredentialsChange = name => event => {
    const { user } = this.state;
    user.credentials[name] = event.target.value;
    this.setState({ user });
  };

  handleTermsCheckbox = event => {
    this.setState({ terms: event.target.checked });
  };

  handleRegister = () => {

  };

  handleBack = () => {
    const { history } = this.props;
    history.push(ROUTES.LOGIN);
  };

  render() {
    const { classes } = this.props;
    const { user, touched, terms } = this.state;
    const { attributes, credentials } = user;
    const { givenName, familyName, email } = attributes;
    const { username, password } = credentials;
    const passwordValid = password.length > 8;
    const firstNameValid = givenName.length;
    const lastNameValid = familyName.length;
    const usernameValid = username.length > 4;
    const emailValid = attributes.email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    );
    const dataValid =
      passwordValid &&
      usernameValid &&
      emailValid &&
      firstNameValid &&
      lastNameValid;
    const formValid =
      dataValid && terms;

    return (
      <div>
        <div className={classes.heading}>
          <Fade in>
            <Typography
              variant="h5"
              color="secondary"
              style={{ marginBottom: 15 }}
            >
              <Trademarked>Formigio</Trademarked>
            </Typography>
          </Fade>
        </div>

        <Card style={{ marginTop: '1em' }}>
          <CardContent>
            <Typography variant="h5">
              Registration Disabled
            </Typography>

            <Typography style={{ color: 'gray' }}>
              Unfortunately, we not accepting any new users at this time. Please try back soon, as we may have a new product to try soon.
            </Typography>

          </CardContent>
        </Card>

        {dataValid ? <Card style={{ marginTop: '1em' }}>
          <CardContent>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={this.handleTermsCheckbox}
                  checked={terms}
                />
              }
              label={`By checking this box, you hereby state and confirm that you have read the Terms & Conditions and that you will abide by the rules and will not do anything nefarious or malicious with any Formigio applications.`}
            />
          </CardContent>
          <CardActions>
            <Button variant="text" size="small" component="a" href="https://www.formigio.com/company-terms/index.html" target="_blank" rel="noreferrer">
              Read Terms & Conditions
            </Button>
          </CardActions>
        </Card> : null}

        <Fade in>
          <div style={{ marginTop: 20 }}>
            <Button
              variant="outlined"
              fullWidth={true}
              color="primary"
              onClick={this.handleBack}
            >
              Back
            </Button>
          </div>
        </Fade>
      </div>
    );
  }
}

SignUp.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  signUp: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(Style)(SignUp);
