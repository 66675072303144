import { put, takeLatest, select } from 'redux-saga/effects';
import {
  REQUEST_ACTIVE_COMMITMENT,
  setActiveCommitmentAction
} from '../actions/activeCommitment';
import { COMMITMENT_UPDATE_SUCCESS_ACTION } from '../actions/commitments';

function* handleActiveCommitmentRequest(action) {
  // Set Active Commitment to the store for Commitment Focus

  yield put(setActiveCommitmentAction(action.payload));
}

function* handleUpdateCommitmentSuccess({ payload: { commitment }}) {
  // Set Active Commitment to the store after successful update
  const activeCommitment = yield select(store => store.activeCommitment.active);
  
  if (activeCommitment.id)
    yield put(setActiveCommitmentAction(commitment));
}

export default function* activeCommitmentSaga() {
  yield takeLatest(REQUEST_ACTIVE_COMMITMENT, handleActiveCommitmentRequest);
  yield takeLatest(
    COMMITMENT_UPDATE_SUCCESS_ACTION,
    handleUpdateCommitmentSuccess
  );
}
