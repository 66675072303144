import {prepAndPruneForAppSync} from "../utils/appSync";

export const LearnItemScaffold = {
  title: '',
  content: '',
  url: '',
  question: '',
  answer: ''
}

export const LearnScaffold = {
  type: '',
  item: LearnItemScaffold
}

export const MetricTypeScaffold = {
  name: '',
  values: []
}

export const MetricScaffold = {
  type: '',
  item: MetricTypeScaffold
}

export const MilestoneScaffold = {
  description: '',
  notes: '',
  actionItems: []
};

export const ReviewCycleScaffold = {
  frequency: 'daily', // Daily, Weekly, Monthly
  interval: 1,
  positions: [],
}

export const TemplateScaffold = {
  dream: '',
  reviewCycle: ReviewCycleScaffold,
  learn: [],
  success: [],
  metrics: [],
  notes: '',
  plans: [],
};

export const PlanScaffold = {
  title: 'Plan',
  open: false,
  milestones: []
};

export const VariableStruct = {
  name: '',
  type: '',
  token: ''
}

export const TrackerTemplateStruct = {
  schemaVersion: '1.0.0',
  id: '',
  ownerApn: '',
  name: '',
  description: '',
  scaffold: TemplateScaffold,
  variables: [],
  sourceTrackerId: ''
};

export const getTrackerTemplateScaffold = (trackerScaffold = TemplateScaffold) => {
  const { plans, success, learn, metrics, reviewCycle } = trackerScaffold;
  return {
    ...TemplateScaffold,
    ...trackerScaffold,
    reviewCycle: prepAndPruneForAppSync({
      ...ReviewCycleScaffold,
      ...reviewCycle
    }, ReviewCycleScaffold),
    plans: plans.length
      ? plans.map(plan => prepAndPruneForAppSync(
        {
          ...PlanScaffold,
          ...plan,
          milestones: plan.milestones.length
            ? plan.milestones.map(milestone => prepAndPruneForAppSync(
              {
                ...MilestoneScaffold,
                ...milestone
              },
              MilestoneScaffold
            ))
            : []
        },
        PlanScaffold
      ))
      : [],
    learn: learn.length
      ? learn.map(item => prepAndPruneForAppSync(
        {
          ...item,
          item: prepAndPruneForAppSync(item.item, LearnItemScaffold)
        },
        LearnScaffold
      ))
      : [],
    success: success.length
      ? success.map(item => item)
      : [],
    metrics: metrics.length
      ? metrics.map(item => prepAndPruneForAppSync(
        {
          ...item,
          item: prepAndPruneForAppSync(item.item, MetricTypeScaffold)
        },
        MetricScaffold
      ))
      : []
  }
};

export const getTrackerTemplateStruct = (templateTemplate = TrackerTemplateStruct) => ({
  ...TrackerTemplateStruct,
  ...templateTemplate,
  scaffold: getTrackerTemplateScaffold(templateTemplate.scaffold),
  variables: templateTemplate.variables && templateTemplate.variables.length
    ? templateTemplate.variables.map(item => ({...VariableStruct, ...item}))
    : []
});

export const createScaffoldFromTracker = (tracker) => getTrackerTemplateScaffold({
  dream: tracker.dream,
  reviewCycle: tracker.reviewCycle,
  learn: tracker.learn.map(learn => ({
    seq: learn.seq,
    type: learn.type,
    item: {...learn.item}
  })),
  success: tracker.success.map(success => success.item),
  metrics: tracker.metrics.map(metric => ({
    type: metric.type,
    item: {
      name: metric.item.name
    }
  })),
  notes: tracker.notes,
  plans: tracker.plans.map(plan => (
    {
      title: plan.title,
      open: plan.open,
      milestones: plan.milestoneIds.map(milestoneId => {
        const planMilestone = tracker.milestones.find(milestone => milestone.id === milestoneId);
        return {
          description: planMilestone.description,
          notes: planMilestone.notes,
          actionItems: planMilestone.actionItems.map(item => item.action)
        };
      })
    }
  ))
});

const findVariables = (string) => {
  const variables = string.match(/{{([a-zA-Z-_:]+)}}/g);

  if (!variables) return [];

  return variables.map(match => {
    const breakdown = match.match(/{{([a-zA-Z-_:]+)}}/);

    return {
      name: breakdown[1].split(':')[0],
      type: breakdown[1].split(':')[1] || 'text',
      token: breakdown[0]
    }
  });
};

export const collectVariablesFromTracker = (tracker) => {
  const { dream, success, milestones, plans } = tracker;
  const variables = [];

  const dreamVars = findVariables(dream);
  const successVars = success.map(item => findVariables(item.item));
  const milestoneVars = milestones.map(item => findVariables(item.description));
  const planVars = plans.map(item => findVariables(item.title));
  const stagedVars = [dreamVars, ...successVars, ...milestoneVars, ...planVars];

  if (stagedVars.length > 0) {
    stagedVars.forEach(variableList => {
      if (variableList.length > 0) {
        variableList.forEach(variable => variables.push(variable));
      }
      return null;
    });
  }

  return variables;
};

export const replaceVariables = (scaffold, values) => {
  // Here we need some magic variable replacement voodoo
  let jsonString = JSON.stringify(scaffold);
  const variables = findVariables(jsonString);
  variables.forEach(variable => {
    const { name, token } = variable;
    if (values[name]) {
      jsonString = jsonString.replace(token, values[name])
    }
  })
  return JSON.parse(jsonString);
};