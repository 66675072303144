import moment from 'moment';
import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import {useDispatch, useSelector} from "react-redux";
import {fetchTrackerActivityByTrackerAction} from "../../../redux/actions/trackerActivity";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List/List";
import CardActions from "@material-ui/core/CardActions";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader/CardHeader";

const Timeline = ({tracker: { id }}) => {
  const dispatch = useDispatch();
  const { items, nextToken } = useSelector(store => store.trackerActivity);
  const { id: userId } = useSelector(store => store.user);

  useEffect(() => {
    // Load Activity
    if (id) {
      dispatch(fetchTrackerActivityByTrackerAction({
        trackerId: id,
        token: null
      }));
    }
  }, [dispatch, id]);

  const handleMore = () => {
    // Load the next page
    dispatch(fetchTrackerActivityByTrackerAction({
      trackerId: id,
      token: nextToken
    }));
  };

  return (
    <Card elevation={0} >
      <CardHeader title="Activity" disableTypography />
      <CardContent>
        <List>
          {items.filter(a => a.trackerId === id).sort((a,b) => a.createdAt > b.createdAt ? -1 : 1).map(item => (
            <ListItem key={`${item.createdAt}${item.trackerId}`}>
              <Typography
                variant="caption"
                title={moment(item.createdAt).format('LLLL')}>{`${item.summary} | ${moment(item.createdAt).calendar()} by ${item.userId === userId ? 'You' : item.byUser}`}
              </Typography>
            </ListItem>
          ))}
        </List>
      </CardContent>
      <CardActions>
        {!!nextToken && (<Button onClick={handleMore}>Load More</Button>)}
      </CardActions>
    </Card>
  );
};

Timeline.defaultProps = {};

Timeline.propTypes = {
  tracker: PropTypes.any.isRequired
};

export default Timeline;
