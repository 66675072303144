import {ROLES_SET_ACTIVE_ACTION, ROLES_SET_ROLES_ACTION} from "../actions/roles";
import {getRoleStruct} from "../../structs/roles";

export default function roleReducer(state = { all: [], active: getRoleStruct() }, action) {

  switch (action.type) {

    case ROLES_SET_ROLES_ACTION:
      return {
        ...state,
        all: [...action.payload]
      };

    case ROLES_SET_ACTIVE_ACTION:
      return {
        ...state,
        active: action.payload
      };

    default:
      return state;
  }
}
