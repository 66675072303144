/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const postStripePayment = /* GraphQL */ `
  mutation PostStripePayment($data: PaymentData) {
    postStripePayment(data: $data)
  }
`;
export const postStripeCancel = /* GraphQL */ `
  mutation PostStripeCancel($data: CancelData) {
    postStripeCancel(data: $data)
  }
`;
export const postStripeInquiry = /* GraphQL */ `
  mutation PostStripeInquiry($data: CheckData) {
    postStripeInquiry(data: $data)
  }
`;
export const postStripeInfo = /* GraphQL */ `
  mutation PostStripeInfo($data: InfoData) {
    postStripeInfo(data: $data)
  }
`;
export const postStripeChange = /* GraphQL */ `
  mutation PostStripeChange($data: ChangeData) {
    postStripeChange(data: $data)
  }
`;
export const smsImmediate = /* GraphQL */ `
  mutation SmsImmediate($message: String, $number: String) {
    smsImmediate(message: $message, number: $number)
  }
`;
export const createCommitmentInvite = /* GraphQL */ `
  mutation CreateCommitmentInvite($input: CreateCommitmentInviteInput!) {
    createCommitmentInvite(input: $input) {
      id
      status
      description
      fromUserName
      fromUserApn
      toUserName
      toUserApn
      contextApn
      contextDescription
      trackerId
      notes
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const updateCommitmentInvite = /* GraphQL */ `
  mutation UpdateCommitmentInvite($input: UpdateCommitmentInviteInput!) {
    updateCommitmentInvite(input: $input) {
      id
      status
      description
      fromUserName
      fromUserApn
      toUserName
      toUserApn
      contextApn
      contextDescription
      trackerId
      notes
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const deleteCommitmentInvite = /* GraphQL */ `
  mutation DeleteCommitmentInvite($input: DeleteCommitmentInviteInput!) {
    deleteCommitmentInvite(input: $input) {
      id
      status
      description
      fromUserName
      fromUserApn
      toUserName
      toUserApn
      contextApn
      contextDescription
      trackerId
      notes
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const createJournal = /* GraphQL */ `
  mutation CreateJournal($input: CreateJournalInput!) {
    createJournal(input: $input) {
      id
      ownerApn
      trackerId
      datetime
      entry
      schemaVersion
      createdBy
      updatedAt
      createdAt
    }
  }
`;
export const updateJournal = /* GraphQL */ `
  mutation UpdateJournal($input: UpdateJournalInput!) {
    updateJournal(input: $input) {
      id
      ownerApn
      trackerId
      datetime
      entry
      schemaVersion
      createdBy
      updatedAt
      createdAt
    }
  }
`;
export const deleteJournal = /* GraphQL */ `
  mutation DeleteJournal($input: DeleteJournalInput!) {
    deleteJournal(input: $input) {
      id
      ownerApn
      trackerId
      datetime
      entry
      schemaVersion
      createdBy
      updatedAt
      createdAt
    }
  }
`;
export const createJournalv2 = /* GraphQL */ `
  mutation CreateJournalv2($input: CreateJournalv2Input!) {
    createJournalv2(input: $input) {
      id
      ownerApn
      trackerId
      datetime
      entry
      schemaVersion
      createdBy
      updatedAt
      createdAt
    }
  }
`;
export const updateJournalv2 = /* GraphQL */ `
  mutation UpdateJournalv2($input: UpdateJournalv2Input!) {
    updateJournalv2(input: $input) {
      id
      ownerApn
      trackerId
      datetime
      entry
      schemaVersion
      createdBy
      updatedAt
      createdAt
    }
  }
`;
export const deleteJournalv2 = /* GraphQL */ `
  mutation DeleteJournalv2($input: DeleteJournalv2Input!) {
    deleteJournalv2(input: $input) {
      id
      ownerApn
      trackerId
      datetime
      entry
      schemaVersion
      createdBy
      updatedAt
      createdAt
    }
  }
`;
export const createCommitmentv3 = /* GraphQL */ `
  mutation CreateCommitmentv3($input: CreateCommitmentv3Input!) {
    createCommitmentv3(input: $input) {
      id
      userId
      milestoneApn
      milestoneId
      trackerId
      contextApn
      contextDescription
      description
      notes
      date
      originalDate
      createdAt
      updatedAt
      highlightColor
      timeCommitment
      status
      role {
        name
        color
      }
      trays
      reminders {
        email
        sms
      }
      schemaVersion
    }
  }
`;
export const updateCommitmentv3 = /* GraphQL */ `
  mutation UpdateCommitmentv3($input: UpdateCommitmentv3Input!) {
    updateCommitmentv3(input: $input) {
      id
      userId
      milestoneApn
      milestoneId
      trackerId
      contextApn
      contextDescription
      description
      notes
      date
      originalDate
      createdAt
      updatedAt
      highlightColor
      timeCommitment
      status
      role {
        name
        color
      }
      trays
      reminders {
        email
        sms
      }
      schemaVersion
    }
  }
`;
export const deleteCommitmentv3 = /* GraphQL */ `
  mutation DeleteCommitmentv3($input: DeleteCommitmentv3Input!) {
    deleteCommitmentv3(input: $input) {
      id
      userId
      milestoneApn
      milestoneId
      trackerId
      contextApn
      contextDescription
      description
      notes
      date
      originalDate
      createdAt
      updatedAt
      highlightColor
      timeCommitment
      status
      role {
        name
        color
      }
      trays
      reminders {
        email
        sms
      }
      schemaVersion
    }
  }
`;
export const createMilestonev3 = /* GraphQL */ `
  mutation CreateMilestonev3($input: CreateMilestonev3Input!) {
    createMilestonev3(input: $input) {
      id
      trackerApn
      trackerId
      description
      notes
      reached
      seq
      status
      targetDate
      actionItems {
        apnPart
        action
        seq
        checked
        schemaVersion
      }
      planApn
      commitments {
        items {
          id
          userId
          milestoneApn
          milestoneId
          trackerId
          contextApn
          contextDescription
          description
          notes
          date
          originalDate
          createdAt
          updatedAt
          highlightColor
          timeCommitment
          status
          role {
            name
            color
          }
          trays
          reminders {
            email
            sms
          }
          schemaVersion
        }
        nextToken
      }
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const updateMilestonev3 = /* GraphQL */ `
  mutation UpdateMilestonev3($input: UpdateMilestonev3Input!) {
    updateMilestonev3(input: $input) {
      id
      trackerApn
      trackerId
      description
      notes
      reached
      seq
      status
      targetDate
      actionItems {
        apnPart
        action
        seq
        checked
        schemaVersion
      }
      planApn
      commitments {
        items {
          id
          userId
          milestoneApn
          milestoneId
          trackerId
          contextApn
          contextDescription
          description
          notes
          date
          originalDate
          createdAt
          updatedAt
          highlightColor
          timeCommitment
          status
          role {
            name
            color
          }
          trays
          reminders {
            email
            sms
          }
          schemaVersion
        }
        nextToken
      }
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const deleteMilestonev3 = /* GraphQL */ `
  mutation DeleteMilestonev3($input: DeleteMilestonev3Input!) {
    deleteMilestonev3(input: $input) {
      id
      trackerApn
      trackerId
      description
      notes
      reached
      seq
      status
      targetDate
      actionItems {
        apnPart
        action
        seq
        checked
        schemaVersion
      }
      planApn
      commitments {
        items {
          id
          userId
          milestoneApn
          milestoneId
          trackerId
          contextApn
          contextDescription
          description
          notes
          date
          originalDate
          createdAt
          updatedAt
          highlightColor
          timeCommitment
          status
          role {
            name
            color
          }
          trays
          reminders {
            email
            sms
          }
          schemaVersion
        }
        nextToken
      }
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const createTrackerv3 = /* GraphQL */ `
  mutation CreateTrackerv3($input: CreateTrackerv3Input!) {
    createTrackerv3(input: $input) {
      id
      ownerApn
      dream
      learn {
        id
        seq
        type
        item {
          title
          text
          pageId
          content
          url
          question
          answer
          schemaVersion
        }
        schemaVersion
      }
      success {
        apnPart
        item
        seq
        checked
        schemaVersion
      }
      metrics {
        id
        seq
        type
        item {
          name
          values {
            id
            metric
            value
            datetime
            schemaVersion
          }
          schemaVersion
        }
        schemaVersion
      }
      milestones {
        items {
          id
          trackerApn
          trackerId
          description
          notes
          reached
          seq
          status
          targetDate
          actionItems {
            apnPart
            action
            seq
            checked
            schemaVersion
          }
          planApn
          commitments {
            items {
              id
              userId
              milestoneApn
              milestoneId
              trackerId
              contextApn
              contextDescription
              description
              notes
              date
              originalDate
              createdAt
              updatedAt
              highlightColor
              timeCommitment
              status
              role {
                name
                color
              }
              trays
              reminders {
                email
                sms
              }
              schemaVersion
            }
            nextToken
          }
          createdAt
          updatedAt
          schemaVersion
        }
        nextToken
      }
      plans {
        apn
        title
        seq
        milestoneIds
        open
      }
      notes
      reviewCycle {
        frequency
        interval
        positions
        lastReviewedDate
        startReviewDate
        updatedAt
        schemaVersion
      }
      status
      createdAt
      updatedAt
      role {
        name
        color
      }
      schemaVersion
    }
  }
`;
export const updateTrackerv3 = /* GraphQL */ `
  mutation UpdateTrackerv3($input: UpdateTrackerv3Input!) {
    updateTrackerv3(input: $input) {
      id
      ownerApn
      dream
      learn {
        id
        seq
        type
        item {
          title
          text
          pageId
          content
          url
          question
          answer
          schemaVersion
        }
        schemaVersion
      }
      success {
        apnPart
        item
        seq
        checked
        schemaVersion
      }
      metrics {
        id
        seq
        type
        item {
          name
          values {
            id
            metric
            value
            datetime
            schemaVersion
          }
          schemaVersion
        }
        schemaVersion
      }
      milestones {
        items {
          id
          trackerApn
          trackerId
          description
          notes
          reached
          seq
          status
          targetDate
          actionItems {
            apnPart
            action
            seq
            checked
            schemaVersion
          }
          planApn
          commitments {
            items {
              id
              userId
              milestoneApn
              milestoneId
              trackerId
              contextApn
              contextDescription
              description
              notes
              date
              originalDate
              createdAt
              updatedAt
              highlightColor
              timeCommitment
              status
              role {
                name
                color
              }
              trays
              reminders {
                email
                sms
              }
              schemaVersion
            }
            nextToken
          }
          createdAt
          updatedAt
          schemaVersion
        }
        nextToken
      }
      plans {
        apn
        title
        seq
        milestoneIds
        open
      }
      notes
      reviewCycle {
        frequency
        interval
        positions
        lastReviewedDate
        startReviewDate
        updatedAt
        schemaVersion
      }
      status
      createdAt
      updatedAt
      role {
        name
        color
      }
      schemaVersion
    }
  }
`;
export const deleteTrackerv3 = /* GraphQL */ `
  mutation DeleteTrackerv3($input: DeleteTrackerv3Input!) {
    deleteTrackerv3(input: $input) {
      id
      ownerApn
      dream
      learn {
        id
        seq
        type
        item {
          title
          text
          pageId
          content
          url
          question
          answer
          schemaVersion
        }
        schemaVersion
      }
      success {
        apnPart
        item
        seq
        checked
        schemaVersion
      }
      metrics {
        id
        seq
        type
        item {
          name
          values {
            id
            metric
            value
            datetime
            schemaVersion
          }
          schemaVersion
        }
        schemaVersion
      }
      milestones {
        items {
          id
          trackerApn
          trackerId
          description
          notes
          reached
          seq
          status
          targetDate
          actionItems {
            apnPart
            action
            seq
            checked
            schemaVersion
          }
          planApn
          commitments {
            items {
              id
              userId
              milestoneApn
              milestoneId
              trackerId
              contextApn
              contextDescription
              description
              notes
              date
              originalDate
              createdAt
              updatedAt
              highlightColor
              timeCommitment
              status
              role {
                name
                color
              }
              trays
              reminders {
                email
                sms
              }
              schemaVersion
            }
            nextToken
          }
          createdAt
          updatedAt
          schemaVersion
        }
        nextToken
      }
      plans {
        apn
        title
        seq
        milestoneIds
        open
      }
      notes
      reviewCycle {
        frequency
        interval
        positions
        lastReviewedDate
        startReviewDate
        updatedAt
        schemaVersion
      }
      status
      createdAt
      updatedAt
      role {
        name
        color
      }
      schemaVersion
    }
  }
`;
export const createChangeLogv2 = /* GraphQL */ `
  mutation CreateChangeLogv2($input: CreateChangeLogv2Input!) {
    createChangeLogv2(input: $input) {
      ownerApn
      timestamp
      changeId
      action
      data
      source
      createdAt
      updatedAt
    }
  }
`;
export const updateChangeLogv2 = /* GraphQL */ `
  mutation UpdateChangeLogv2($input: UpdateChangeLogv2Input!) {
    updateChangeLogv2(input: $input) {
      ownerApn
      timestamp
      changeId
      action
      data
      source
      createdAt
      updatedAt
    }
  }
`;
export const deleteChangeLogv2 = /* GraphQL */ `
  mutation DeleteChangeLogv2($input: DeleteChangeLogv2Input!) {
    deleteChangeLogv2(input: $input) {
      ownerApn
      timestamp
      changeId
      action
      data
      source
      createdAt
      updatedAt
    }
  }
`;
export const createTour = /* GraphQL */ `
  mutation CreateTour($input: CreateTourInput!) {
    createTour(input: $input) {
      ownerApn
      id
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateTour = /* GraphQL */ `
  mutation UpdateTour($input: UpdateTourInput!) {
    updateTour(input: $input) {
      ownerApn
      id
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteTour = /* GraphQL */ `
  mutation DeleteTour($input: DeleteTourInput!) {
    deleteTour(input: $input) {
      ownerApn
      id
      status
      createdAt
      updatedAt
    }
  }
`;
export const createChecklist = /* GraphQL */ `
  mutation CreateChecklist($input: CreateChecklistInput!) {
    createChecklist(input: $input) {
      ownerApn
      id
      apn
      name
      description
      items {
        schemaVersion
        seq
        text
        checked
      }
      status
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const updateChecklist = /* GraphQL */ `
  mutation UpdateChecklist($input: UpdateChecklistInput!) {
    updateChecklist(input: $input) {
      ownerApn
      id
      apn
      name
      description
      items {
        schemaVersion
        seq
        text
        checked
      }
      status
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const deleteChecklist = /* GraphQL */ `
  mutation DeleteChecklist($input: DeleteChecklistInput!) {
    deleteChecklist(input: $input) {
      ownerApn
      id
      apn
      name
      description
      items {
        schemaVersion
        seq
        text
        checked
      }
      status
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const createMembershipStatus = /* GraphQL */ `
  mutation CreateMembershipStatus($input: CreateMembershipStatusInput!) {
    createMembershipStatus(input: $input) {
      id
      subscriptionLevel
      subscriptionCode
      subscriptionId
      lastLoginTime
      stripeSubscription {
        schemaVersion
        stripeSubscriptionType
        stripeSubscriptionId
        cancelServiceDate
        stripeCurrentPeriodEnd
        cancelAtPeriodEnd
        stripeCancelDate
        updatedAt
      }
      paymentVerified
      schemaVersion
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateMembershipStatus = /* GraphQL */ `
  mutation UpdateMembershipStatus($input: UpdateMembershipStatusInput!) {
    updateMembershipStatus(input: $input) {
      id
      subscriptionLevel
      subscriptionCode
      subscriptionId
      lastLoginTime
      stripeSubscription {
        schemaVersion
        stripeSubscriptionType
        stripeSubscriptionId
        cancelServiceDate
        stripeCurrentPeriodEnd
        cancelAtPeriodEnd
        stripeCancelDate
        updatedAt
      }
      paymentVerified
      schemaVersion
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteMembershipStatus = /* GraphQL */ `
  mutation DeleteMembershipStatus($input: DeleteMembershipStatusInput!) {
    deleteMembershipStatus(input: $input) {
      id
      subscriptionLevel
      subscriptionCode
      subscriptionId
      lastLoginTime
      stripeSubscription {
        schemaVersion
        stripeSubscriptionType
        stripeSubscriptionId
        cancelServiceDate
        stripeCurrentPeriodEnd
        cancelAtPeriodEnd
        stripeCancelDate
        updatedAt
      }
      paymentVerified
      schemaVersion
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createFormigioSubscription = /* GraphQL */ `
  mutation CreateFormigioSubscription(
    $input: CreateFormigioSubscriptionInput!
  ) {
    createFormigioSubscription(input: $input) {
      id
      name
      label
      description
      stripePlanId
      active
      level
      sort
      price
      code
      createdAt
      updatedAt
    }
  }
`;
export const updateFormigioSubscription = /* GraphQL */ `
  mutation UpdateFormigioSubscription(
    $input: UpdateFormigioSubscriptionInput!
  ) {
    updateFormigioSubscription(input: $input) {
      id
      name
      label
      description
      stripePlanId
      active
      level
      sort
      price
      code
      createdAt
      updatedAt
    }
  }
`;
export const deleteFormigioSubscription = /* GraphQL */ `
  mutation DeleteFormigioSubscription(
    $input: DeleteFormigioSubscriptionInput!
  ) {
    deleteFormigioSubscription(input: $input) {
      id
      name
      label
      description
      stripePlanId
      active
      level
      sort
      price
      code
      createdAt
      updatedAt
    }
  }
`;
export const createEmailPreferences = /* GraphQL */ `
  mutation CreateEmailPreferences($input: CreateEmailPreferencesInput!) {
    createEmailPreferences(input: $input) {
      userApn
      emailAddress
      emailTopics
      unsubscribedTopics
      createdAt
      updatedAt
    }
  }
`;
export const updateEmailPreferences = /* GraphQL */ `
  mutation UpdateEmailPreferences($input: UpdateEmailPreferencesInput!) {
    updateEmailPreferences(input: $input) {
      userApn
      emailAddress
      emailTopics
      unsubscribedTopics
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmailPreferences = /* GraphQL */ `
  mutation DeleteEmailPreferences($input: DeleteEmailPreferencesInput!) {
    deleteEmailPreferences(input: $input) {
      userApn
      emailAddress
      emailTopics
      unsubscribedTopics
      createdAt
      updatedAt
    }
  }
`;
export const createEmailNotification = /* GraphQL */ `
  mutation CreateEmailNotification($input: CreateEmailNotificationInput!) {
    createEmailNotification(input: $input) {
      id
      userApn
      sendTimestamp
      topic
      email
      summaryText
      payload
      new
      processedTimestamp
      entityApn
      createdAt
      updatedAt
    }
  }
`;
export const updateEmailNotification = /* GraphQL */ `
  mutation UpdateEmailNotification($input: UpdateEmailNotificationInput!) {
    updateEmailNotification(input: $input) {
      id
      userApn
      sendTimestamp
      topic
      email
      summaryText
      payload
      new
      processedTimestamp
      entityApn
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmailNotification = /* GraphQL */ `
  mutation DeleteEmailNotification($input: DeleteEmailNotificationInput!) {
    deleteEmailNotification(input: $input) {
      id
      userApn
      sendTimestamp
      topic
      email
      summaryText
      payload
      new
      processedTimestamp
      entityApn
      createdAt
      updatedAt
    }
  }
`;
export const createTrackerShare = /* GraphQL */ `
  mutation CreateTrackerShare($input: CreateTrackerShareInput!) {
    createTrackerShare(input: $input) {
      id
      trackerId
      ownerApn
      ownerEmailAddress
      ownerName
      emailAddress
      userId
      status
      permissionLevel
      notifications
      reviewCycle {
        frequency
        interval
        positions
        lastReviewedDate
        startReviewDate
        updatedAt
        schemaVersion
      }
      role {
        name
        color
      }
      shareDescription
      schemaVersion
      createdAt
      updatedAt
    }
  }
`;
export const updateTrackerShare = /* GraphQL */ `
  mutation UpdateTrackerShare($input: UpdateTrackerShareInput!) {
    updateTrackerShare(input: $input) {
      id
      trackerId
      ownerApn
      ownerEmailAddress
      ownerName
      emailAddress
      userId
      status
      permissionLevel
      notifications
      reviewCycle {
        frequency
        interval
        positions
        lastReviewedDate
        startReviewDate
        updatedAt
        schemaVersion
      }
      role {
        name
        color
      }
      shareDescription
      schemaVersion
      createdAt
      updatedAt
    }
  }
`;
export const deleteTrackerShare = /* GraphQL */ `
  mutation DeleteTrackerShare($input: DeleteTrackerShareInput!) {
    deleteTrackerShare(input: $input) {
      id
      trackerId
      ownerApn
      ownerEmailAddress
      ownerName
      emailAddress
      userId
      status
      permissionLevel
      notifications
      reviewCycle {
        frequency
        interval
        positions
        lastReviewedDate
        startReviewDate
        updatedAt
        schemaVersion
      }
      role {
        name
        color
      }
      shareDescription
      schemaVersion
      createdAt
      updatedAt
    }
  }
`;
export const createTrackerActivity = /* GraphQL */ `
  mutation CreateTrackerActivity($input: CreateTrackerActivityInput!) {
    createTrackerActivity(input: $input) {
      trackerId
      createdAt
      userId
      byUser
      summary
      entityType
      entityId
      schemaVersion
      updatedAt
    }
  }
`;
export const updateTrackerActivity = /* GraphQL */ `
  mutation UpdateTrackerActivity($input: UpdateTrackerActivityInput!) {
    updateTrackerActivity(input: $input) {
      trackerId
      createdAt
      userId
      byUser
      summary
      entityType
      entityId
      schemaVersion
      updatedAt
    }
  }
`;
export const deleteTrackerActivity = /* GraphQL */ `
  mutation DeleteTrackerActivity($input: DeleteTrackerActivityInput!) {
    deleteTrackerActivity(input: $input) {
      trackerId
      createdAt
      userId
      byUser
      summary
      entityType
      entityId
      schemaVersion
      updatedAt
    }
  }
`;
export const createCommitmentTray = /* GraphQL */ `
  mutation CreateCommitmentTray($input: CreateCommitmentTrayInput!) {
    createCommitmentTray(input: $input) {
      schemaVersion
      ownerApn
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateCommitmentTray = /* GraphQL */ `
  mutation UpdateCommitmentTray($input: UpdateCommitmentTrayInput!) {
    updateCommitmentTray(input: $input) {
      schemaVersion
      ownerApn
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteCommitmentTray = /* GraphQL */ `
  mutation DeleteCommitmentTray($input: DeleteCommitmentTrayInput!) {
    deleteCommitmentTray(input: $input) {
      schemaVersion
      ownerApn
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const createMigration = /* GraphQL */ `
  mutation CreateMigration($input: CreateMigrationInput!) {
    createMigration(input: $input) {
      userId
      migrationKey
      completed
      schemaVersion
      createdAt
      updatedAt
    }
  }
`;
export const updateMigration = /* GraphQL */ `
  mutation UpdateMigration($input: UpdateMigrationInput!) {
    updateMigration(input: $input) {
      userId
      migrationKey
      completed
      schemaVersion
      createdAt
      updatedAt
    }
  }
`;
export const deleteMigration = /* GraphQL */ `
  mutation DeleteMigration($input: DeleteMigrationInput!) {
    deleteMigration(input: $input) {
      userId
      migrationKey
      completed
      schemaVersion
      createdAt
      updatedAt
    }
  }
`;
export const createSMSPreferences = /* GraphQL */ `
  mutation CreateSMSPreferences($input: CreateSMSPreferencesInput!) {
    createSMSPreferences(input: $input) {
      userApn
      number
      verifiedAt
      unsubscribedTopics
      createdAt
      updatedAt
    }
  }
`;
export const updateSMSPreferences = /* GraphQL */ `
  mutation UpdateSMSPreferences($input: UpdateSMSPreferencesInput!) {
    updateSMSPreferences(input: $input) {
      userApn
      number
      verifiedAt
      unsubscribedTopics
      createdAt
      updatedAt
    }
  }
`;
export const deleteSMSPreferences = /* GraphQL */ `
  mutation DeleteSMSPreferences($input: DeleteSMSPreferencesInput!) {
    deleteSMSPreferences(input: $input) {
      userApn
      number
      verifiedAt
      unsubscribedTopics
      createdAt
      updatedAt
    }
  }
`;
export const createSMSNotification = /* GraphQL */ `
  mutation CreateSMSNotification($input: CreateSMSNotificationInput!) {
    createSMSNotification(input: $input) {
      id
      userApn
      sendTimestamp
      topic
      number
      summaryText
      new
      processedTimestamp
      entityApn
      createdAt
      updatedAt
    }
  }
`;
export const updateSMSNotification = /* GraphQL */ `
  mutation UpdateSMSNotification($input: UpdateSMSNotificationInput!) {
    updateSMSNotification(input: $input) {
      id
      userApn
      sendTimestamp
      topic
      number
      summaryText
      new
      processedTimestamp
      entityApn
      createdAt
      updatedAt
    }
  }
`;
export const deleteSMSNotification = /* GraphQL */ `
  mutation DeleteSMSNotification($input: DeleteSMSNotificationInput!) {
    deleteSMSNotification(input: $input) {
      id
      userApn
      sendTimestamp
      topic
      number
      summaryText
      new
      processedTimestamp
      entityApn
      createdAt
      updatedAt
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact($input: CreateContactInput!) {
    createContact(input: $input) {
      id
      userApn
      friendApn
      friendName
      friendEmail
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      id
      userApn
      friendApn
      friendName
      friendEmail
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact($input: DeleteContactInput!) {
    deleteContact(input: $input) {
      id
      userApn
      friendApn
      friendName
      friendEmail
      createdAt
      updatedAt
      schemaVersion
    }
  }
`;
export const createTrackerTemplate = /* GraphQL */ `
  mutation CreateTrackerTemplate($input: CreateTrackerTemplateInput!) {
    createTrackerTemplate(input: $input) {
      id
      ownerApn
      name
      description
      scaffold {
        dream
        reviewCycle {
          frequency
          interval
          positions
        }
        success
        learn {
          type
          item {
            title
            content
            url
            question
            answer
          }
        }
        metrics {
          type
          item {
            name
            values {
              value
              datetime
            }
          }
        }
        notes
        plans {
          title
          open
          milestones {
            description
            notes
            actionItems
          }
        }
      }
      variables {
        name
        type
        token
      }
      sourceTrackerId
      schemaVersion
      createdAt
      updatedAt
    }
  }
`;
export const updateTrackerTemplate = /* GraphQL */ `
  mutation UpdateTrackerTemplate($input: UpdateTrackerTemplateInput!) {
    updateTrackerTemplate(input: $input) {
      id
      ownerApn
      name
      description
      scaffold {
        dream
        reviewCycle {
          frequency
          interval
          positions
        }
        success
        learn {
          type
          item {
            title
            content
            url
            question
            answer
          }
        }
        metrics {
          type
          item {
            name
            values {
              value
              datetime
            }
          }
        }
        notes
        plans {
          title
          open
          milestones {
            description
            notes
            actionItems
          }
        }
      }
      variables {
        name
        type
        token
      }
      sourceTrackerId
      schemaVersion
      createdAt
      updatedAt
    }
  }
`;
export const deleteTrackerTemplate = /* GraphQL */ `
  mutation DeleteTrackerTemplate($input: DeleteTrackerTemplateInput!) {
    deleteTrackerTemplate(input: $input) {
      id
      ownerApn
      name
      description
      scaffold {
        dream
        reviewCycle {
          frequency
          interval
          positions
        }
        success
        learn {
          type
          item {
            title
            content
            url
            question
            answer
          }
        }
        metrics {
          type
          item {
            name
            values {
              value
              datetime
            }
          }
        }
        notes
        plans {
          title
          open
          milestones {
            description
            notes
            actionItems
          }
        }
      }
      variables {
        name
        type
        token
      }
      sourceTrackerId
      schemaVersion
      createdAt
      updatedAt
    }
  }
`;
