import React, { Component } from 'react';
import { connect } from 'react-redux';
import Payment from '../components/Payment/Payment';
import {
  addNotificationAction,
  setInternetStatusAction
} from '../redux/actions/notifications';
import {
  noPaymentAction,
  paymentAction,
  paymentCancelAction,
  paymentChangeAction,
  paymentCheckAction
} from '../redux/actions/payment';
import {fetchSubscriptionsAction} from '../redux/actions/app';

export class PaymentPageContainer extends Component {
  render() {
    return <Payment />;
  }
}

const mapStateToProps = state => ({
  user: state.user,
  membershipStatus: state.membership,
  paymentKey: state.app.paymentKey,
  subscriptions: state.app.subscriptions
});

const mapDispatchToProps = dispatch => ({
  addNotification: notification => {
    dispatch(addNotificationAction(notification));
  },
  setInternetStatus: status => {
    dispatch(setInternetStatusAction(status));
  },
  submitPayment: userPacket => dispatch(paymentAction(userPacket)),
  saveSubscription: subscription => dispatch(noPaymentAction(subscription)),
  changePlan: planId => dispatch(paymentChangeAction(planId)),
  cancelSubscription: planId => dispatch(paymentCancelAction(planId)),
  fetchSubscriptions: code => dispatch(fetchSubscriptionsAction(code)),
  checkSubscription: () => dispatch(paymentCheckAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
