import { put, takeEvery, select } from "redux-saga/effects";
import { DEVICE_SYNC_WAKE_UP } from "../actions/deviceSync";
import {
    AUTH_CHECK_CURRENT_USER_SUCCESS_ACTION,
    getCurrentUserAction
} from "../actions/auth";
import {syncChangeLogAction} from "../actions/changeLogs";
import {syncAllSyncEventsAction} from "../actions/syncEvents";
import {getToday} from '../../utils/date';
import {setTodayAction} from '../actions/app';
import {paymentCheckAction} from '../actions/payment';
import {USER_STATUS_LOGGED_IN} from '../../structs/user';
import {offerSyncAction} from '../actions/sync';

function* handleUserCheckSuccess(action) {
    if (action.payload.initialAction.type === DEVICE_SYNC_WAKE_UP) {
      yield put(syncAllSyncEventsAction());
      yield put(syncChangeLogAction());
      yield put(getCurrentUserAction());
    }
}

function* handleLocationChange() {
  const user = yield select(store => store.user);
  const membership = yield select(store => store.membership);
  const todayStore = yield select(store => store.app.today);
  const todayCalc = getToday(true);
  
  if (user.status !== USER_STATUS_LOGGED_IN || !membership.id) return;
  
  if (todayStore.toString() !== todayCalc.toString()) {
    yield put(setTodayAction(getToday(true)));
    yield put(paymentCheckAction());
  }
  
  const syncVersion = yield select(store => store.syncVersion);
  
  if (!syncVersion.lastSync)
    yield put(offerSyncAction());
}

export default function* deviceSyncSaga() {
  yield takeEvery(AUTH_CHECK_CURRENT_USER_SUCCESS_ACTION, handleUserCheckSuccess);
  yield takeEvery(['@@router/LOCATION_CHANGE'], handleLocationChange);
}
