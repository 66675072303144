import React, { Component } from 'react';
import { connect } from 'react-redux';
import CommitmentRecommitInline from '../../components/Commitment/RecommitInline';
import {
  addCommitmentAction,
  deleteCommitmentAction,
  saveCommitmentAction
} from '../../redux/actions/commitments';
import { resetActiveCommitmentAction } from '../../redux/actions/activeCommitment';
import { addNotificationAction } from '../../redux/actions/notifications';

export class RecommitFormContainer extends Component {
  render() {
    return <CommitmentRecommitInline />;
  }
}

const mapStateToProps = state => ({
  commitmentIndex: state.commitmentIndex
});

const mapDispatchToProps = dispatch => ({
  addCommitment: commitment => {
    dispatch(addCommitmentAction(commitment));
  },
  saveCommitment: commitment => {
    dispatch(saveCommitmentAction(commitment));
  },
  deleteCommitment: async commitment => {
    dispatch(deleteCommitmentAction(commitment));
  },
  resetActiveCommitment: () => {
    dispatch(resetActiveCommitmentAction());
  },
  addNotification: notification => {
    dispatch(addNotificationAction(notification));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommitmentRecommitInline);
