module.exports = () => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 23,
    width: 'calc(100vw - 68px)'
  },
  syncSwitch: {
    marginLeft: 0
  },
  buttonWrap: {
    marginBottom: '1em',
    marginTop: '1em'
  },
  heading: {
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontSize: 27,
    letterSpacing: 0
  },
  logo: {
    width: 35,
    height: 35,
    marginLeft: '1em'
  }
});
