import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Input from '@material-ui/core/Input/Input';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import { Storage } from 'aws-amplify';
import { Typography } from '@material-ui/core';
import { getLearnItemFileStruct } from '../../../structs/learn';

export const style = () => ({
  input: {
    color: '#393939',
    flex: 1,
    width: '100%'
  },
  textField: {
    fontSize: 12,
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    width: '100%'
  },
  FileHeaderContent: {
    fontSize: '1rem'
  },
  FileHeaderRoot: {
    padding: '1rem',
    paddingBottom: 0
  }
});

class LearnItemFile extends Component {
  state = {
    file: getLearnItemFileStruct(),
    edit: false,
    working: '',
    filePath: ''
  };

  componentDidMount() {
    const { item } = this.props;
    const { file } = this.state;

    if (!item.name) {
      this.deferredCommands.push(() => {
        this.setState({ edit: true });
      });
    }

    if (JSON.stringify(item) !== JSON.stringify(file)) {
      this.hydrateState(item);
    }
  }

  componentDidUpdate() {
    this.checkDeferredCommands();
  }

  // List of commands to run after render.
  deferredCommands = [];

  /*
   *
   * The Deferred Commands model is to allow us to run functions once the
   * state has been set.
   * This is useful when we want to persist data via a redux action, or run
   * multiple steps to record progress.
   *
   */
  checkDeferredCommands = () => {
    if (!this.deferredCommands.length) return;

    this.deferredCommands = this.deferredCommands.filter(command => {
      command();
      return false;
    });
  };

  hydrateState = item => {
    this.setState({ file: getLearnItemFileStruct(item) });
  };

  handleSubmit = () => {
    const { file } = this.state;
    const { onChange } = this.props;
    this.deferredCommands.push(() => {
      onChange(file);
    });
    this.handleCancel();
  };

  handleDelete = () => {
    const { file } = this.state;
    const { onDelete } = this.props;
    onDelete(file);
  };

  handleChange = event => {
    const { file } = this.state;
    file[event.target.name] = event.target.value;
    this.setState({ file });
  };

  handleFile = e => {
    const fileObject = e.target.files[0];
    this.handleSendFileToS3(fileObject);
    const { name } = fileObject;
    const { file } = this.state;
    file.name = name;
    const reader = new FileReader();
    reader.onload = () => {
      const { result } = reader;
      file.path = result;
      this.setState({ file });
    };
    reader.readAsDataURL(fileObject);
  };

  handleClick = () => {
    this.setState({ edit: true });
  };

  handleCancel = () => {
    this.setState({ edit: false });
  };

  handleS3Download = async () => {
    const { image } = this.state;
    Storage.configure({ level: 'private' });
    const downloadLink = await Storage.get(image.content);
    fetch(downloadLink)
      .then(response => {
        response
          .blob()
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = image.content;
            a.click();
            return null;
          })
          .catch(err => console.log(err));
        return null;
      })
      .catch(err => console.log(err));
  };

  handleSendFileToS3 = async file => {
    this.setState({ working: 'Uploading...' });
    const { name: imageName } = file;
    await Storage.put(imageName, file, {
      level: 'private',
      progressCallback: this.handleUploadProgress
    }).catch(err => console.log(err));
    this.setState({ filePath: imageName, working: '' });
  };

  handleUploadProgress = progress => {
    this.setState({
      working: `Uploading: ${(progress.loaded / 1000 / 1000).toFixed(
        2
      )} MB / ${(progress.total / 1000 / 1000).toFixed(2)} MB`
    });
  };

  render() {
    const { classes, item } = this.props;
    const { file, edit, filePath, working } = this.state;

    return (
      <React.Fragment>
        <ListItem button onClick={this.handleClick}>
          <Card
            elevation={0}
            style={{ borderLeft: '3px solid lightgray', marginBottom: 4 }}
          >
            {item.title ? (
              <CardHeader
                classes={{ root: classes.FileHeaderRoot }}
                titleTypographyProps={{
                  classes: { h5: classes.FileHeaderContent }
                }}
                title={item.title}
              />
            ) : null}
            <CardContent style={{ fontSize: '.8rem', whiteSpace: 'pre-wrap' }}>
              {item.name}
            </CardContent>
          </Card>
        </ListItem>
        <Dialog open={edit}>
          <DialogContent>
            <Typography>{working}</Typography>
            <Typography>{filePath}</Typography>
            <TextField
              multiline
              id="title"
              name="title"
              value={file.title}
              placeholder="File Title (optional)"
              margin="normal"
              className={classes.textField}
              InputProps={{
                className: classes.input
              }}
              onChange={this.handleChange}
            />
            <div className={classes.textField}>
              <div className={classes.textField}>
                <div>
                  <Card className={classes.textField}>
                    <CardContent>
                      <List>
                        <ListItemText primary="Select Any File to Save to the Formigio Database" />
                        <List>
                          <ListItem>
                            <Input
                              type="file"
                              className={classes.textField}
                              onChange={this.handleFile}
                            />
                          </ListItem>
                        </List>
                      </List>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ marginRight: 'auto' }}
              color="secondary"
              onClick={this.handleDelete}
            >
              Delete
            </Button>
            {item.path ? (
              <Button
                style={{ marginRight: 'auto' }}
                color="secondary"
                onClick={this.handleS3Download}
              >
                Download
              </Button>
            ) : null}
            <Button color="secondary" onClick={this.handleCancel}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

LearnItemFile.propTypes = {
  classes: PropTypes.any.isRequired,
  item: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default withStyles(style)(LearnItemFile);
