import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import List from '@material-ui/core/List/List';
import TextField from '@material-ui/core/TextField/TextField';
import { withStyles } from '@material-ui/core/styles';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Style from '../Style';
import MilestoneItem from './Item';
import { getMilestoneStruct } from '../../../structs/trackers';

class MilestoneList extends Component {
  state = {
    newMilestone: getMilestoneStruct(),
    hydrated: '',
    selected: '',
    sortable: true
  };

  componentDidMount() {
    this.hydrateState();
  }

  componentDidUpdate() {
    this.hydrateState();
  }

  newMilestoneField;

  hydrateState = () => {
    const {
      tracker
    } = this.props;
    const { hydrated, newMilestone } = this.state;

    if (hydrated !== tracker.id) {
      this.setState({ hydrated: tracker.id, newMilestone });
    }
  };

  handleMilestoneRemove = milestone => {
    const { plan: { milestoneIds}, removeMilestone, onChange } = this.props;
    
    onChange(milestoneIds.filter(el => el !== milestone.id));

    removeMilestone(milestone);
  };

  handleMilestoneChange = milestone => {
    const { updateMilestone } = this.props;

    updateMilestone(milestone);
  };

  handleNewChange = event => {
    const { newMilestone } = this.state;

    newMilestone[event.target.name] = event.target.value;

    this.setState({ newMilestone });
  };

  handleKeyPress = event => {
    if (event.key !== 'Enter') return;

    event.target.blur();
  };

  handleItemFocus = id => {
    this.setState({ selected: id });
  };

  handleItemBlur = id => {
    const { selected } = this.state;
    if (selected === id) this.setState({ selected: '' });
  };

  handleAddBlur = () => {
    const { addMilestone, tracker, plan: { apn }} = this.props;
    const { newMilestone } = this.state;
    
    if (!newMilestone.description.trim()) {
      this.setState({ selected: '' });
      return;
    }
    
    addMilestone({...newMilestone, planApn: apn}, tracker);
    
    this.setState({
      newMilestone: getMilestoneStruct(),
      selected: ''
    });
  };
  
  handleAddFocus = () => {
    this.setState({ selected: 'Add' });
  };
  
  handleListKeys = event => {
    const { selected } = this.state;
    const { plan: { milestoneIds} } = this.props;

    const selectedIndex = milestoneIds.findIndex(el => el === selected);
    // Handle Up Arrow
    if (event.keyCode === 38) {
      if (selectedIndex === 0) return;
      this.setState({ selected: milestoneIds[selectedIndex - 1] });
    }

    // Handle Down Arrow
    if (event.keyCode === 40) {
      if (selectedIndex < milestoneIds.length)
        this.setState({ selected: milestoneIds[selectedIndex + 1] });
    }
  };
  
  render() {
    const { classes, plan: { milestoneIds }, milestonesById, plan, tracker, readOnly } = this.props;
    const { newMilestone, selected, sortable } = this.state;

    return (
      <React.Fragment>
        <List onKeyDown={this.handleListKeys}>
          <Droppable droppableId={`plan|${plan.apn}`}>
            {provided => (
              <div ref={provided.innerRef}>
                {milestoneIds && milestoneIds.map((milestoneId, index) => (
                  <Draggable
                    draggableId={`milestone|${milestoneId}`}
                    key={milestoneId}
                    index={index}
                    isDragDisabled={!sortable || readOnly}
                  >
                    {innerProvided => (
                      <div
                        ref={innerProvided.innerRef}
                        {...innerProvided.draggableProps}
                        {...innerProvided.dragHandleProps}
                      >
                        <MilestoneItem
                          key={milestoneId}
                          index={index}
                          milestone={milestonesById[milestoneId] || getMilestoneStruct()}
                          onDelete={this.handleMilestoneRemove}
                          onSave={this.handleMilestoneChange}
                          onInputBlur={this.handleItemBlur}
                          onInputFocus={this.handleItemFocus}
                          onOpenClose={open => this.setState({sortable: !open})}
                          selectedItem={selected}
                          sortable={sortable}
                          readOnly={readOnly}
                          commitmentContext={{
                            description: tracker.dream,
                            tracker
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {!readOnly && (
            <ListItem
              key="newMilestone"
              selected={selected === "Add"}
              dense
              className={classes.listPadding}
            >
              <Checkbox
                checked={false}
                tabIndex={-1}
                disableRipple
                style={{ marginLeft: 4 }}
                icon={
                  <CheckBoxOutlineBlankIcon
                    style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                  />
                }
              />
              <TextField
                style={{ width: '90%', marginTop: 10 }}
                id="description"
                name="description"
                placeholder="Enter a milestone"
                className={classes.textField}
                InputProps={{
                  className: classes.input,
                  disableUnderline: true
                }}
                inputRef={field => {
                  this.newMilestoneField = field;
                }}
                onChange={this.handleNewChange}
                onKeyPress={this.handleKeyPress}
                onFocus={this.handleAddFocus}
                onBlur={this.handleAddBlur}
                margin="normal"
                value={
                  newMilestone.description === ' ' ? '' : newMilestone.description
                }
              />
            </ListItem>
          )}
        </List>
      </React.Fragment>
    );
  }
}

MilestoneList.propTypes = {
  classes: PropTypes.object.isRequired,
  addMilestone: PropTypes.func.isRequired,
  updateMilestone: PropTypes.func.isRequired,
  removeMilestone: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  tracker: PropTypes.object.isRequired,
  plan: PropTypes.object.isRequired
};

export default withStyles(Style)(MilestoneList);
