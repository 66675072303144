import {take, put, all, select, takeLatest, takeEvery} from 'redux-saga/effects';
import moment from 'moment';

import {
  ADD_LOCAL_COMMITMENT,
  REMOVE_LOCAL_COMMITMENT,
  UPSERT_LOCAL_COMMITMENT,
  COMMITMENT_CREATE_REQUEST_ACTION,
  COMMITMENT_CREATE_SUCCESS_ACTION,
  COMMITMENT_DELETE_REQUEST_ACTION,
  COMMITMENT_DELETE_SUCCESS_ACTION,
  COMMITMENT_UPDATE_REQUEST_ACTION,
  COMMITMENT_UPDATE_SUCCESS_ACTION,
  setLocalCommitmentsAction,
  COMMITMENT_DATA_LOAD_SUCCESS_ACTION,
  COMMITMENT_DATA_LOAD_REQUEST_ACTION,
  SET_LOCAL_COMMITMENTS,
  SAVE_LOCAL_COMMITMENT,
  deleteCommitmentAction,
  upsertLocalCommitmentAction,
  COMMITMENT_FULL_SYNC_SUCCESS_ACTION,
  COMMITMENT_FULL_SYNC_REQUEST_ACTION,
  COMMITMENT_QUICK_SYNC_SUCCESS_ACTION,
  COMMITMENT_QUICK_SYNC_REQUEST_ACTION,
  updateCommitmentIndexAction,
  migrateCommitmentAction,
  saveCommitmentAction,
  COMMITMENT_MIGRATE_SUCCESS_ACTION, COMMITMENT_MIGRATE_REQUEST_ACTION
} from '../actions/commitments';
import {
  fetchRemoteCommitmentsForToday,
  fetchRemoteCommitmentsStillPending
} from '../../utils/appSync';
import {
  COMMITMENT_STATUS_COMPLETE,
  COMMITMENT_STATUS_PENDING,
  evaluateCommitments,
  getCommitmentStruct, migrateCommitment,
  migrateCommitments
} from '../../structs/commitments';
import { loadFromStorage } from '../../utils/localStorage';
import {
  APPLICATION_DATA_CLEAR_REQUEST_ACTION,
  APPLICATION_DATA_REQUEST_ACTION
} from '../actions/app';
import { MILESTONE_DELETE_SUCCESS_ACTION } from '../actions/milestones';
import {addNotificationAction, setLoadingAction, setProcessingAction} from '../actions/notifications';
import {
  addSyncEventAction,
  SYNC_EVENT_SYNC_SUCCESS_ACTION,
  syncAllSyncEventsAction
} from '../actions/syncEvents';
import { getSyncEventStruct } from '../../structs/sync';
import {
  COMMITMENT_UPSERT_REMOTE_ACTION,
  COMMITMENT_UPSERT_REMOTE_SUCCESS_ACTION
} from '../actions/appSync';
import {getSystemNotificationStruct} from '../../structs/notification';
import {CommitmentIndexStruct} from '../../structs/indexers';
import {setCompletedCommitmentAction} from "../actions/activeCommitment";

function* handleCreateCommitmentRequest(action) {
  const user = yield select(store => store.user);
  // Persist Commitment to Local Data Store
  const commitment = getCommitmentStruct({
    ...action.payload,
    status: COMMITMENT_STATUS_PENDING,
    userId: user.id,
    createdAt: moment().format(),
    updatedAt: moment().format(),
    date: moment(action.payload.date).format(),
    originalDate: moment(action.payload.date).format()
  });

  yield put({
    type: ADD_LOCAL_COMMITMENT,
    payload: commitment
  });

  // Signal Complete
  yield put({
    type: COMMITMENT_CREATE_SUCCESS_ACTION,
    payload: { commitment }
  });

  yield put(addNotificationAction(
    getSystemNotificationStruct({
      message: 'Commitment Created.'
    })
  ));
}

function* handleUpdateCommitmentRequest({ payload }) {
  // Persist Commitment to Local Data Store
  const commitment = getCommitmentStruct({
    ...payload,
    updatedAt:  moment().format(),
    date:  moment(payload.date).format()
  });

  const currentCommitments = yield select(store => store.commitments);
  const currentCommitment = currentCommitments.find(c => c.id === commitment.id);

  yield put({
    type: SAVE_LOCAL_COMMITMENT,
    payload: commitment
  });

  // Signal Complete
  yield put({
    type: COMMITMENT_UPDATE_SUCCESS_ACTION,
    payload: {
      commitment,
      previousCommitment: currentCommitment
    }
  });

  if (currentCommitment
    && currentCommitment.status === COMMITMENT_STATUS_PENDING
    && commitment.status === COMMITMENT_STATUS_COMPLETE)
  {
    yield put(setCompletedCommitmentAction(commitment));
  }
}

function* handleDeleteCommitmentRequest(action) {
  // Persist Commitment to Local Data Store
  yield put({
    type: REMOVE_LOCAL_COMMITMENT,
    payload: action.payload
  });

  // Signal Complete
  yield put({
    type: COMMITMENT_DELETE_SUCCESS_ACTION,
    payload: action.payload
  });

  yield put(addNotificationAction(
    getSystemNotificationStruct({
      message: 'Commitment Removed.',
      level: 'success'
    })
  ));
}

function* handleLoadCommitmentDataRequest(action) {
  // Persist Commitment to Local Data Store
  yield put({
    type: SET_LOCAL_COMMITMENTS,
    payload: action.payload
  });

  // Signal Complete
  yield put({
    type: COMMITMENT_DATA_LOAD_SUCCESS_ACTION
  });
}

function* handleLoadApplicationDataRequest() {
  // Perform Application Data Load
  const commitmentsState = yield select(store => store.commitments);
  const user = yield select(store => store.user);

  // Call the Util Local Storage loadState function which pulls data
  // from the local storage if cached there, if not it pulls from the disk
  const commitments = yield loadFromStorage('commitments', commitmentsState);
  const migratedCommitments = yield migrateCommitments(commitments, user);

  yield put(setLocalCommitmentsAction(migratedCommitments));
}

function* handleDeleteMilestoneCommitments(action) {
  const milestone = action.payload;
  const { commitments } = milestone;
  if (commitments && commitments.length)
    yield all(
      commitments.map(commitment => put(deleteCommitmentAction(commitment)))
    );
}

function* handleStateChange() {
  const type = 'commitments';

  // Select data from the store
  const data = yield select(store => store[type]);
  const dataString = JSON.stringify(data);

  // As well as in the local storage.
  localStorage.setItem(type, dataString);
}

function* handleApplicationDataClear() {
  yield put(setLocalCommitmentsAction([]));
  yield put(updateCommitmentIndexAction(CommitmentIndexStruct));
}

function* handleFullSyncRequest() {
  const user = yield select(store => store.user);
  yield put(setProcessingAction('Fetching commitments from Formigio Cloud'));
  
  // Pull Commitments from the cloud
  const pendingCommitments = yield fetchRemoteCommitmentsStillPending(user.id);
  const remoteCommitments = yield fetchRemoteCommitmentsForToday(user.id);

  yield put(setLocalCommitmentsAction([]));
  
  const allCommitments = pendingCommitments.concat(remoteCommitments);
  
  const [valid, migrate] = evaluateCommitments(allCommitments);
  
  yield all(valid.map(commitment => put(upsertLocalCommitmentAction(commitment))));
  
  if (migrate.length) {
    yield put(setLoadingAction('Updating data for the latest version...'));
    for (const commitment of migrate) {
      yield put(migrateCommitmentAction(commitment));
      yield take(COMMITMENT_MIGRATE_SUCCESS_ACTION);
    }
    yield put(setLoadingAction(''));
  }
  
  yield put({ type: COMMITMENT_FULL_SYNC_SUCCESS_ACTION });
}

function* handleQuickSyncRequest() {
  const user = yield select(store => store.user);
  yield put(setProcessingAction('Checking for commitment changes...'));
  // Push and Pull Commitments
  let commitmentSyncIndex = {};
  const localCommitments = yield select(store => store.commitments);
  const pendingCommitments = yield fetchRemoteCommitmentsStillPending(user.id);
  const remoteCommitments = yield fetchRemoteCommitmentsForToday(user.id);

  if (localCommitments.length) {
    localCommitments.map(commitment => {
      commitmentSyncIndex[commitment.id] = { local: commitment };
      return true;
    });
  }

  if (pendingCommitments.length) {
    pendingCommitments.map(commitment => {
      if (commitmentSyncIndex[commitment.id])
        commitmentSyncIndex[commitment.id].remote = commitment;
      else commitmentSyncIndex[commitment.id] = { remote: commitment };
      return true;
    });
  }

  if (remoteCommitments.length) {
    remoteCommitments.map(commitment => {
      if (commitmentSyncIndex[commitment.id])
        commitmentSyncIndex[commitment.id].remote = commitment;
      else commitmentSyncIndex[commitment.id] = { remote: commitment };
      return true;
    });
  }

  if (Object.keys(commitmentSyncIndex).length) {
    const localCommitmentsToPush = [];
    const remoteCommitmentsToPull = [];

    Object.values(commitmentSyncIndex).map(commitmentToSync => {
      const { local, remote } = commitmentToSync;
      if (local && !remote) localCommitmentsToPush.push(local);
      if (!local && remote) remoteCommitmentsToPull.push(remote);
      if (local && remote) {
        if (moment(local.updatedAt).isAfter(remote.updatedAt))
          localCommitmentsToPush.push(local);
        else if (moment(remote.updatedAt).isAfter(local.updatedAt))
          remoteCommitmentsToPull.push(remote);
      }
      return true;
    });

    if (remoteCommitmentsToPull.length)
      yield all(
        remoteCommitmentsToPull.map(commitment =>
          put(upsertLocalCommitmentAction(commitment))
        )
      );

    if (localCommitmentsToPush.length) {
      yield all(
        localCommitmentsToPush.map(commitment =>
          put(
            addSyncEventAction(
              getSyncEventStruct({
                action: COMMITMENT_UPSERT_REMOTE_ACTION,
                success: COMMITMENT_UPSERT_REMOTE_SUCCESS_ACTION,
                data: commitment
              })
            )
          )
        )
      );

      yield put(syncAllSyncEventsAction());
      yield take(SYNC_EVENT_SYNC_SUCCESS_ACTION);
    }
  }

  commitmentSyncIndex = {};
  yield put({ type: COMMITMENT_QUICK_SYNC_SUCCESS_ACTION });
  yield put(setProcessingAction(''));
}

function* handleAfterSyncCleanup() {
  const localCommitments = yield select(store => store.commitments);
  const commitmentsToClean = localCommitments.filter(
    commitment =>
      moment(commitment.date).isBefore(moment().startOf('day')) &&
      commitment.status !== COMMITMENT_STATUS_PENDING
  );
  if (commitmentsToClean.length) {
    yield all(
      commitmentsToClean.map(commitment =>
        put({ type: REMOVE_LOCAL_COMMITMENT, payload: commitment })
      )
    );
  }
}

function* handleMigrationRequest({ payload: commitment }) {
  const user = yield select(store => store.user);
  const migrated = migrateCommitment(commitment, user);
  yield put(saveCommitmentAction(migrated));
  const { payload: updatedCommitment } = yield take(COMMITMENT_UPSERT_REMOTE_SUCCESS_ACTION);
  yield put({ type: COMMITMENT_MIGRATE_SUCCESS_ACTION, payload: updatedCommitment });
}

export default function* commitmentSaga() {
  yield takeEvery(
    COMMITMENT_CREATE_REQUEST_ACTION,
    handleCreateCommitmentRequest
  );
  yield takeEvery(
    COMMITMENT_UPDATE_REQUEST_ACTION,
    handleUpdateCommitmentRequest
  );
  yield takeEvery(
    COMMITMENT_DELETE_REQUEST_ACTION,
    handleDeleteCommitmentRequest
  );
  yield takeEvery(
    COMMITMENT_MIGRATE_REQUEST_ACTION,
    handleMigrationRequest
  );
  yield takeLatest(
    APPLICATION_DATA_REQUEST_ACTION,
    handleLoadApplicationDataRequest
  );
  yield takeLatest(
    COMMITMENT_DATA_LOAD_REQUEST_ACTION,
    handleLoadCommitmentDataRequest
  );

  // Handle Milestone Delete
  yield takeEvery(
    MILESTONE_DELETE_SUCCESS_ACTION,
    handleDeleteMilestoneCommitments
  );

  // Push storage on change event
  yield takeEvery(ADD_LOCAL_COMMITMENT, handleStateChange);
  yield takeEvery(UPSERT_LOCAL_COMMITMENT, handleStateChange);
  yield takeEvery(SAVE_LOCAL_COMMITMENT, handleStateChange);
  yield takeEvery(REMOVE_LOCAL_COMMITMENT, handleStateChange);
  yield takeLatest(COMMITMENT_DATA_LOAD_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(
    APPLICATION_DATA_CLEAR_REQUEST_ACTION,
    handleApplicationDataClear
  );
  yield takeLatest(COMMITMENT_FULL_SYNC_REQUEST_ACTION, handleFullSyncRequest);
  yield takeLatest(
    COMMITMENT_QUICK_SYNC_REQUEST_ACTION,
    handleQuickSyncRequest
  );
  yield takeLatest(COMMITMENT_FULL_SYNC_SUCCESS_ACTION, handleAfterSyncCleanup);
  yield takeLatest(
    COMMITMENT_QUICK_SYNC_SUCCESS_ACTION,
    handleAfterSyncCleanup
  );
}
