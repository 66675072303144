import React from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles({
  infoTooltip: {
    fontSize: '1rem',
    textAlign: 'center'
  },
  infoPopper: {
    paddingTop: 10,
    marginTop: 10
  }
});

const HelpIcon = ({ title }) => {
  const classes = useStyles();

  return (
    <Tooltip
      classes={{
        popper: classes.infoPopper,
        tooltip: classes.infoTooltip
      }}
      title={title}
      placement="top"
    >
      <IconButton size="small" style={{ padding: 0 }}>
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );
};

HelpIcon.propTypes = {
  title: PropTypes.string.isRequired
}

export default HelpIcon;