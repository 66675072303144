export const SET_TOUR_COMPLETE = 'SET_TOUR_COMPLETE';
export const LOAD_TOUR_FROM_DISK = 'LOAD_TOUR_FROM_DISK';
export const TOUR_UPDATE_REQUEST_ACTION = 'TOUR_UPDATE_REQUEST';
export const TOUR_UPDATE_SUCCESS_ACTION = 'TOUR_UPDATE_SUCCESS';
export const TOUR_DATA_LOAD_REQUEST_ACTION = 'TOUR_DATA_LOAD_REQUEST';
export const TOUR_DATA_LOAD_SUCCESS_ACTION = 'TOUR_DATA_LOAD_SUCCESS';
export const TOUR_FULL_SYNC_REQUEST_ACTION = 'TOUR_FULL_SYNC_REQUEST';
export const TOUR_FULL_SYNC_SUCCESS_ACTION = 'TOUR_FULL_SYNC_SUCCESS';

export const setTourCompleteAction = userTour => ({
  type: TOUR_UPDATE_REQUEST_ACTION,
  payload: userTour
});

export const loadTourFromDiskAction = tour => ({
  type: TOUR_DATA_LOAD_REQUEST_ACTION,
  payload: tour
});
