import React, { Component } from 'react';
import { connect } from 'react-redux';
import CommitmentItem from '../../components/Commitment/Item';
import {
  deleteCommitmentAction,
  saveCommitmentAction
} from '../../redux/actions/commitments';
import { requestActiveCommitmentAction } from '../../redux/actions/activeCommitment';

export class CommitmentItemContainer extends Component {
  render() {
    return <CommitmentItem />;
  }
}

const mapStateToProps = state => ({
  now: state.app.today,
});

const mapDispatchToProps = dispatch => ({
  saveCommitment: commitment => {
    dispatch(saveCommitmentAction(commitment));
  },
  setActiveCommitment: commitment => {
    dispatch(requestActiveCommitmentAction(commitment));
  },
  deleteCommitment: async commitment => {
    dispatch(deleteCommitmentAction(commitment));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CommitmentItem);
