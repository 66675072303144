import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import {
  COMMITMENT_STATUS_COMPLETE,
  COMMITMENT_STATUS_MOVED,
  COMMITMENT_STATUS_PENDING,
  getCommitmentStruct
} from '../../structs/commitments';
import colors from '../../constants/highlightColors.json';

const style = () => ({
  textField: {
    margin: '8px 0',
    width: '77%'
  },
  override: {
    display: 'inherit'
  },
  listPadding: {
    padding: '0',
    paddingLeft: '.5em'
  },
  commitDate: {
    fontSize: '.8rem',
    color: 'gray',
    marginTop: '-12px',
    paddingLeft: '50px'
  },
  secondaryIconButton: {
    padding: 3,
    marginTop: -3
  },
  secondaryIcon: {
    paddingLeft: 3,
    paddingRight: 3,
    fontSize: 19
  },
  checkbox: {
    padding: 0,
    marginTop: 0,
    marginRight: 2
  }
});

class CommitmentCalendarItem extends Component {
  state = {
    commitment: getCommitmentStruct()
  };

  componentDidMount() {
    const { commitment: commitmentProp } = this.props;
    const { commitment } = this.state;
    if (
      commitment.id !== commitmentProp.id ||
      commitment.updatedAt !== commitmentProp.updatedAt
    ) {
      this.hydrate(commitmentProp);
    }
  }

  componentDidUpdate() {
    const { commitment: commitmentProp } = this.props;
    const { commitment } = this.state;
    if (
      commitment.id !== commitmentProp.id ||
      commitment.updatedAt !== commitmentProp.updatedAt
    ) {
      this.hydrate(commitmentProp);
    }
  }

  hydrate = commitment => this.setState({ commitment });

  handleChange = event => {
    const { commitment } = this.state;
    commitment[event.target.name] = event.target.value;
    if (event.target.name === 'status') {
      commitment[event.target.name] = event.target.checked
        ? COMMITMENT_STATUS_COMPLETE
        : COMMITMENT_STATUS_PENDING;
    }
    this.setState({ commitment });
    this.persistChange();
  };

  persistChange = debounce(() => {
    const { saveCommitment } = this.props;
    const { commitment } = this.state;
    saveCommitment(commitment);
  }, 650);

  handleCommitmentFocus = () => {
    const { setActiveCommitment } = this.props;
    const { commitment } = this.state;
    setActiveCommitment(commitment);
  };

  handleColorChange = color => {
    const { commitment } = this.state;
    commitment.highlightColor = color;

    this.setState({ commitment });

    this.persistChange();
  };

  render() {
    const { commitment } = this.state;
    const { classes, focusable } = this.props;
    const [defaultColor] = colors;

    return (
      <ListItem
        dense
        button={focusable}
        className={[classes.listPadding, classes.override].join(' ')}
        onClick={focusable ? this.handleCommitmentFocus : null}
        style={{
          cursor: 'pointer',
          backgroundColor:
            commitment.status === COMMITMENT_STATUS_PENDING && commitment.highlightColor
              ? commitment.highlightColor
              : defaultColor,
          minHeight: '1.75em',
          height: `${Math.ceil(commitment.timeCommitment / 15) * 1.75}rem`,
          borderRadius: 8
        }}
      >
        <ListItemText
          style={{ margin: 0 }}
          primaryTypographyProps={{
            style: {
              fontSize: '.75rem',
              overflow: 'hidden',
              height: '1.75em',
              paddingTop: 3
            }
          }}
          primary={
            <React.Fragment>
              {commitment.status === COMMITMENT_STATUS_MOVED ? (
                <IconButton disabled className={classes.checkbox}>
                  <ArrowRightAltIcon style={{ fontSize: '1rem' }} />
                </IconButton>
              ) : (
                <Checkbox
                  checked={Boolean(commitment.status === COMMITMENT_STATUS_COMPLETE)}
                  className={classes.checkbox}
                  disabled
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fontSize: '1rem' }} />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '1rem' }} />}
                />
              )}
              {commitment.description}
            </React.Fragment>
          }
        />
      </ListItem>
    );
  }
}

CommitmentCalendarItem.defaultProps = {
  focusable: true
};

CommitmentCalendarItem.propTypes = {
  classes: PropTypes.object.isRequired,
  commitment: PropTypes.object.isRequired,
  saveCommitment: PropTypes.func.isRequired,
  setActiveCommitment: PropTypes.func.isRequired,
  focusable: PropTypes.bool
};

export default withStyles(style)(CommitmentCalendarItem);
