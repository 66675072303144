import moment from 'moment';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NotificationIcon from '@material-ui/icons/NotificationImportant';
import {getDaysUntilReview, getReviewDueDate} from '../../models/tracker';
import HoverButton from '../Ui/HoverButton';

const useStyles = makeStyles({
  reviewCycleButton: {
    minWidth: '100%'
  },
  futureReminder: {
    fontSize: 15,
    fontWeight: 500,
    letterSpacing: 0,
    color: '#2c9c52'
  }
});

const ReviewCycle = ({ reviewCycle, onChange }) => {
  const classes = useStyles();
  const [reviewDate, setReviewDate] = useState(moment());
  const [daysUntilReview, setDaysUntilReview] = useState(0);

  const today = moment().startOf("day");

  useEffect(() => {
    const reviewDateEffect = getReviewDueDate(reviewCycle).startOf("day");
    setReviewDate(reviewDateEffect);
    setDaysUntilReview(getDaysUntilReview(reviewDateEffect));
  },[reviewCycle, reviewCycle.lastReviewedDate]);

  const handleReview = () => {
    onChange({
      ...reviewCycle,
      lastReviewedDate: moment().format()
    });
  };
  
  const reviewDue = reviewDate <= today ? (
    <HoverButton
      variant="outlined"
      color="secondary"
      onClick={handleReview}
      className={classes.reviewCycleButton}
      style={{ color: '#d0021b' }}
      hoverContent={
        <React.Fragment>
          <NotificationIcon style={{ marginRight: '.25em' }} />
          Mark as Reviewed
        </React.Fragment>
      }
    >
      <NotificationIcon style={{ marginRight: '.25em' }} />
      Review Due Today
    </HoverButton>
  ) : (
    <Button
      variant="outlined"
      onClick={handleReview}
      className={classes.reviewCycleButton}
      classes={{ label: classes.futureReminder }}
    >
      <CheckCircleIcon style={{ marginRight: '.25em' }} />
      {daysUntilReview === 1 ? `Review due tomorrow` : ''}
      {daysUntilReview > 1 ? `Review due in ${getDaysUntilReview(reviewDate)} days` : ''}
    </Button>
  );

  return (
    <React.Fragment>
      {reviewDue}
    </React.Fragment>
  );
};

ReviewCycle.defaultProps = {};

ReviewCycle.propTypes = {
  reviewCycle: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ReviewCycle;
