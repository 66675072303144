import React, { Component } from 'react';
import { connect } from 'react-redux';
import Welcome from '../components/Welcome/Welcome';
import { getCurrentUserAction } from '../redux/actions/auth';

export class WelcomePageContainer extends Component {
  render() {
    return <Welcome />;
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  getCurrentUser: () => {
    dispatch(getCurrentUserAction());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
