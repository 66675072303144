import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import TextField from '@material-ui/core/TextField';
import LaunchIcon from '@material-ui/icons/Launch';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import routes from '../../constants/routes.json';
import Tour from '../../containers/Tour';
import CommitmentItem from '../../containers/Commitment/SimpleItem';
import CommitmentRecommitInline from '../../containers/Commitment/RecommitInline';
import TimeSelect from '../../containers/Commitment/TimeSelect';
import {
  ActiveTrackerStruct,
  getTrackerStruct
} from '../../structs/trackers';
import {COMMITMENT_STATUS_PENDING, getCommitmentStruct} from '../../structs/commitments';
import RoleButton from "../Role/Button";
import CommitmentTrayButton from "../CommitmentTray/Button";
import Reminder from "./Reminder";

const style = () => ({
  closePopUp: {
    position: 'absolute',
    right: '10px',
    top: '20px',
    color: '#303030',
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    background: 'transparent',
    boxShadow: 'none'
  },
  itemTitle: {
    fontSize: '.85em',
    fontWeight: 500,
    letterSpacing: 0,
    color: '#393939',
    marginBottom: '1em',
    whiteSpace: 'pre-line'
  },
  smallIconButton: {
    padding: 3,
    marginTop: -3
  },
  smallIcon: {
    paddingLeft: 3,
    paddingRight: 3,
    fontSize: 19
  },
  sectionTitle: {
    fontSize: '0.85em',
    letterSpacing: '0.5px'
  },
  dialogPaper: {
    borderTop: '#fd7a6c 17px solid'
  }
});

class CommitmentFocus extends Component {
  state = {
    tracker: getTrackerStruct(),
    showCommitmentNoteForm: false,
    commitmentNotes: ''
  };
  
  componentDidMount() {
    const { commitment, resetActiveTracker } = this.props;
    const { trackerId } = commitment;
  
    if (!trackerId) {
      resetActiveTracker();
    }
  }
  
  componentDidUpdate() {
    const {
      activeTrackerDoc,
      commitment,
      requestActiveTracker
    } = this.props;
    const { tracker } = this.state;
    const { tracker: activeTracker } = activeTrackerDoc;

    if (!commitment.id) return;

    const { trackerId } = commitment;

    if (!trackerId) return;

    if (
      (!activeTracker.id && !activeTrackerDoc.requestedId) ||
      (activeTrackerDoc.requestedId &&
        activeTracker.id !== activeTrackerDoc.requestedId) ||
      trackerId !== activeTracker.id
    ) {
      requestActiveTracker(trackerId);
      return;
    }

    if (
      activeTracker.id &&
      (tracker.id !== activeTracker.id ||
        tracker.updatedAt !== activeTracker.updatedAt)
    ) {
      this.hydrateTrackerState(activeTracker);
    }

  }
  
  hydrateTrackerState = activeTracker => {
    this.setState({
      tracker: getTrackerStruct(activeTracker)
    });
  };

  handleClose = () => {
    const {
      resetActiveCommitment,
      resetActiveTracker
    } = this.props;
    resetActiveTracker();
    resetActiveCommitment();
  };
  
  handleNotesChange = event => {
    this.setState({ commitmentNotes: event.target.value });
  };

  handleNotesSave = () => {
    const { saveCommitment, commitment } = this.props;
    const { commitmentNotes } = this.state;
    this.setState(
      { showCommitmentNoteForm: false, commitmentNotes: '' },
      () => {
        saveCommitment({ ...commitment, notes: commitmentNotes });
      }
    );
  };

  handleTrackerNavigate = () => {
    const { history } = this.props;
    const { tracker } = this.state;
    history.push(`${routes.TRACKER_BASE}${tracker.id}`);
    this.handleClose();
  };

  handleShowCommitmentNotes = () => {
    const { commitment } = this.props;
    const { showCommitmentNoteForm } = this.state;
    this.setState({
      showCommitmentNoteForm: !showCommitmentNoteForm,
      commitmentNotes: commitment.notes
    });
  };

  handleRoleChange = (role) => {
    const { saveCommitment, commitment } = this.props;
    saveCommitment({ ...commitment, role });
  };

  handleTrayChange = (trays) => {
    const { saveCommitment, commitment } = this.props;
    saveCommitment({ ...commitment, trays });
  };

  handleReminderChange = (reminders) => {
    const { saveCommitment, commitment } = this.props;
    saveCommitment({ ...commitment, reminders });
  };

  render() {
    const { classes, commitment } = this.props;
    const {
      tracker,
      showCommitmentNoteForm,
      commitmentNotes
    } = this.state;

    return (
      <Dialog
        fullScreen
        style={{
          margin: 10,
          marginLeft: 10,
          marginTop: 27
        }}
        open={Boolean(commitment.id)}
        onClose={this.handleClose}
        aria-labelledby="scroll-dialog-title"
        PaperProps={{
          classes: {
            root: classes.dialogPaper
          }
        }}
      >
        <Button
          variant="text"
          className={classes.closePopUp}
          onClick={this.handleClose}
        >
          X
        </Button>
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch'
          }}
        >
          <Grid row>
            <Typography gutterBottom color="primary" variant="h5">
              Commitment
            </Typography>
            <Grid
              container
              style={{ marginRight: 34, alignItems: 'center' }}
              className="tour-commitment-context"
            >
              {commitment ? (
                <Grid item xs={12} md={8}>
                  <List>
                    <CommitmentItem commitment={commitment} />
                  </List>
                </Grid>
              ) : null}
              {commitment.status === COMMITMENT_STATUS_PENDING ? (
                <>
                  <Grid item xs={12} md={4}>
                    <TimeSelect commitment={commitment} />
                  </Grid>
                </>
              ) : null}
              <div>
                <RoleButton onChange={this.handleRoleChange} role={commitment.role} />
                <CommitmentTrayButton onChange={this.handleTrayChange} trayIds={commitment.trays} />
              </div>
            </Grid>
          </Grid>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <div
              style={{
                flexBasis: 'auto',
                flexGrow: 8,
                margin: '1em 2em',
                borderLeft: 'gray 1px solid',
                paddingLeft: '1em'
              }}
            >
              <Typography color="primary" className={classes.sectionTitle}>
                Date / Time
              </Typography>
              <Typography className={classes.itemTitle} gutterBottom>
                {new Date(commitment.date).toLocaleString()}
                <Reminder
                  onChange={this.handleReminderChange}
                  reminders={commitment.reminders}
                />
              </Typography>
              {tracker.id ? (
                <React.Fragment>
                  <Typography color="primary" className={classes.sectionTitle}>
                    Tracker
                  </Typography>
                  <Button
                    className={classes.itemTitle}
                    size="small"
                    variant="text"
                    onClick={this.handleTrackerNavigate}
                    endIcon={<LaunchIcon className={classes.smallIcon} />}
                  >
                    {commitment.contextDescription}
                  </Button>
                </React.Fragment>
              ) : <Typography>{commitment.contextDescription}</Typography>}

              {!showCommitmentNoteForm ? (
                <React.Fragment>
                  <Typography color="primary" className={classes.sectionTitle}>
                    Commitment Notes
                    <IconButton
                      className={classes.smallIconButton}
                      onClick={this.handleShowCommitmentNotes}
                    >
                      <EditIcon className={classes.smallIcon} />
                    </IconButton>
                  </Typography>
                  <Typography
                    className={classes.itemTitle}
                    gutterBottom
                    onClick={this.handleShowCommitmentNotes}
                  >
                    {commitment.notes}
                  </Typography>
                </React.Fragment>
              ) : null}
            </div>
          </div>
          <div
            style={{
              flex: 'inherit'
            }}
          >
            {showCommitmentNoteForm ? (
              <Card elevation={0} style={{ border: '1px lightgray solid' }}>
                <CardContent>
                  <TextField
                    key={`${commitment.id}_notes`}
                    multiline
                    fullWidth
                    InputProps={{
                      disableUnderline: true
                    }}
                    placeholder="Enter some notes"
                    name="notes"
                    onChange={this.handleNotesChange}
                    value={commitmentNotes === ' ' ? '' : commitmentNotes}
                  />
                </CardContent>
                <CardActions>
                  <Button
                    onClick={this.handleShowCommitmentNotes}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ marginLeft: 'auto' }}
                    onClick={this.handleNotesSave}
                    color="primary"
                    variant="contained"
                    disabled={commitmentNotes === commitment.notes}
                  >
                    Save
                  </Button>
                </CardActions>
              </Card>
            ) : null}
          </div>
          {!showCommitmentNoteForm ? (
            <div
              style={{
                flex: 'inherit',
                display: 'flex',
                alignItems: 'flex-end',
                zIndex: 1,
                height: 1
              }}
            >
              <CommitmentRecommitInline commitment={commitment} />
            </div>
          ) : null}
          <Tour
            id="commitment-focus-v1.0"
            zIndex={1500}
            continuous
            steps={[
              {
                target: '.tour-commitment-context',
                content:
                  'Welcome to the Focus View! This is where you can see the ' +
                  'context of what you are working on.'
              }
            ]}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

CommitmentFocus.defaultProps = {
  commitment: getCommitmentStruct(),
  activeTrackerDoc: ActiveTrackerStruct
};

CommitmentFocus.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.any.isRequired,
  activeTrackerDoc: PropTypes.any,
  resetActiveTracker: PropTypes.func.isRequired,
  resetActiveCommitment: PropTypes.func.isRequired,
  requestActiveTracker: PropTypes.func.isRequired,
  saveTracker: PropTypes.func.isRequired,
  saveCommitment: PropTypes.func.isRequired,
  commitment: PropTypes.object
};
export default withRouter(withStyles(style)(CommitmentFocus));
