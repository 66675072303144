import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Trackers from '../../containers/ListsPage/Trackers';
import Commitments from '../../containers/ListsPage/Commitments';
import { getDaysUntilReview } from '../../models/tracker';
import Style from './Style';
import RoleFilter from "../Role/Filter";

class ListsPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    trackerIndex: PropTypes.object.isRequired
  };

  state = {
    currentFilters: ['trackers', 'commitments'],
    filters: ['trackers', 'commitments'],
    filtersTab: {
      active: false
    },
    searchValue: '',
    typing: false
  };

  timeout = 0;

  dateFormatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit'
  });

  setSearchResults = value => () => {
    const formattedValue = value.toLowerCase();

    this.setState({
      searchValue: formattedValue,
      typing: false
    });
  };

  handleSearch = event => {
    const { typing } = this.state;
    const {
      target: { value }
    } = event;
    if (!typing) {
      this.setState({
        typing: true
      });
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(this.setSearchResults(value), 200);
  };

  onValueChange = event => {
    const { value } = event.target;
    this.setState({
      currentFilters: value
    });
  };

  onClick = () => {
    const {
      filtersTab: { active }
    } = this.state;

    this.setState({
      filtersTab: {
        active: !active
      }
    });
  };

  render() {
    const {
      currentFilters,
      searchValue,
      typing
    } = this.state;
    const { classes, searchArchive, trackerIndex: { all } } = this.props;
  
    let searchResults;
    if (searchValue.length === 0) {
      searchResults = [];
    } else {
      searchResults = all.filter(tracker => {
        const {
          entity: { dream },
          reviewDate
        } = tracker;
        return (
          dream.toLowerCase().includes(searchValue) ||
          getDaysUntilReview(reviewDate)
            .toString()
            .includes(searchValue) ||
          this.dateFormatter
            .format(reviewDate)
            .toLowerCase()
            .includes(searchValue)
        );
      });
    }
  
    return (
      <div style={{ width: '100%' }}>
        <RoleFilter/>
        {currentFilters.includes('trackers') && (
          <div>
            <div style={{ position: 'relative' }}>
              <TextField
                onChange={this.handleSearch}
                label="Search for trackers:"
                className={classes.input}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
              {typing && (
                <CircularProgress size={20} className={classes.progress} />
              )}
            </div>
            <Trackers
              dateFormatter={this.dateFormatter}
              searchResults={searchResults}
              searchValue={searchValue}
              searchArchive={searchArchive}
              typing={typing}
            />
          </div>
        )}
        {currentFilters.includes('commitments') && <Commitments />}
        {!currentFilters.includes('trackers') &&
          !currentFilters.includes('commitments') && (
            <span className={classes.noItems}>No items</span>
          )}
      </div>
    );
  }
}

export default withStyles(Style)(ListsPage);
