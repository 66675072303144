import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';
import UserIcon from "@material-ui/icons/AccountCircle";
import Chip from "@material-ui/core/Chip";
import {getContactStruct} from "../../structs/contact";
import {TRACKER_SHARE_PERMISSION_CONTRIBUTE, TRACKER_SHARE_STATUS_ACTIVE} from "../../structs/trackerShare";
import {getTrackerStruct} from "../../structs/trackers";
import {stringifyApn} from "../../utils/apn/v2";
import ChooseDialog from "../Contacts/ChooseDialog";

const CommitInviteButton = ({ onChange, selectedContact, tracker }) => {
  const user = useSelector(store => store.user);
  const trackerShares = useSelector(store => store.trackerShares);
  const contacts = useSelector(store => store.contacts);

  const [ open, setOpen ] = useState(false);
  const [allowedContacts, setAllowedContacts] = useState([]);

  useEffect(() => {
    if (tracker.id && trackerShares.length > 0) {
      const isTrackerOwner = tracker.ownerApn === stringifyApn({ userId: user.id });
      const share = trackerShares.find(s => tracker.id === s.userId === user.id);
      const canContribute = share && share.id && share.permissionLevel === TRACKER_SHARE_PERMISSION_CONTRIBUTE;
      const allowList = trackerShares
        .filter(s => (tracker.id === s.trackerId && s.status === TRACKER_SHARE_STATUS_ACTIVE) || isTrackerOwner || canContribute)
        .map(s => s.emailAddress);

      const contactEmails = contacts.filter(c => c.friendApn === tracker.ownerApn || isTrackerOwner || canContribute).map(c => c.friendEmail);

      setAllowedContacts([...allowList, ...contactEmails]);
    }
  }, [tracker.id, trackerShares, user.id, tracker.ownerApn, contacts]);

  const setContact = useCallback((contact) => onChange(contact), [onChange]);

  return (
    <>
      {selectedContact.id ? (
          <>
            To:{' '}
            <Chip
              onClick={() => setOpen(true)}
              variant={"outlined"}
              avatar={<UserIcon fontSize="small" />}
              label={selectedContact.friendName}
              onDelete={selectedContact.id ? () => onChange(getContactStruct()) : null}
            />
          </>
      ) : (
        <Button
          onClick={() => setOpen(true)}
        >
          <UserIcon fontSize="small" style={{ marginRight: 5 }} />
          Me
        </Button>
      )}
      <ChooseDialog onSelect={setContact} open={open} setOpen={setOpen} selectedContact={selectedContact} allowedContacts={allowedContacts} />
    </>
  );
};

CommitInviteButton.defaultProps = {
  tracker: getTrackerStruct(),
  selectedContact: getContactStruct()
};

CommitInviteButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedContact: PropTypes.object,
  tracker: PropTypes.object
};

export default CommitInviteButton;
