// Commitment Tray Actions
export const UPSERT_LOCAL_COMMITMENT_TRAY = 'UPSERT_LOCAL_COMMITMENT_TRAY';
export const REMOVE_LOCAL_COMMITMENT_TRAY = 'REMOVE_LOCAL_COMMITMENT_TRAY';
export const COMMITMENT_TRAY_CREATE_REQUEST_ACTION = 'COMMITMENT_TRAY_CREATE_REQUEST';
export const COMMITMENT_TRAY_CREATE_SUCCESS_ACTION = 'COMMITMENT_TRAY_CREATE_SUCCESS';
export const COMMITMENT_TRAY_UPDATE_REQUEST_ACTION = 'COMMITMENT_TRAY_UPDATE_REQUEST';
export const COMMITMENT_TRAY_UPDATE_SUCCESS_ACTION = 'COMMITMENT_TRAY_UPDATE_SUCCESS';
export const COMMITMENT_TRAY_DELETE_REQUEST_ACTION = 'COMMITMENT_TRAY_DELETE_REQUEST';
export const COMMITMENT_TRAY_DELETE_SUCCESS_ACTION = 'COMMITMENT_TRAY_DELETE_SUCCESS';
export const COMMITMENT_TRAY_FULL_SYNC_REQUEST_ACTION =
  'COMMITMENT_TRAY_FULL_SYNC_REQUEST';
export const COMMITMENT_TRAY_FULL_SYNC_SUCCESS_ACTION =
  'COMMITMENT_TRAY_FULL_SYNC_SUCCESS';

export const addCommitmentTrayAction = tray => ({
  type: COMMITMENT_TRAY_CREATE_REQUEST_ACTION,
  payload: tray
});

export const deleteCommitmentTrayAction = tray => ({
  type: COMMITMENT_TRAY_DELETE_REQUEST_ACTION,
  payload: tray
});

export const saveCommitmentTrayAction = tray => ({
  type: COMMITMENT_TRAY_UPDATE_REQUEST_ACTION,
  payload: tray
});

export const upsertLocalCommitmentAction = tray => ({
  type: UPSERT_LOCAL_COMMITMENT_TRAY,
  payload: tray
});
