import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import EditIcon from '@material-ui/icons/Edit';
import OpenIcon from '@material-ui/icons/Launch';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import { getLearnItemLinkStruct } from '../../../structs/learn';

export const style = () => ({
  input: {
    color: '#393939',
    flex: 1,
    width: '100%'
  },
  textField: {
    fontSize: 12,
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    width: '100%'
  },
  cardContentRoot: {
    padding: '.65rem',
    paddingTop: '.25rem',
    paddingBottom: '.25rem',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
});

class LearnItemLink extends Component {
  state = {
    link: getLearnItemLinkStruct(),
    edit: false
  };

  componentDidMount() {
    const { item } = this.props;
    const { link } = this.state;

    if (!item.url || !item.title) {
      this.deferredCommands.push(() => {
        this.setState({ edit: true });
      });
    }

    if (JSON.stringify(item) !== JSON.stringify(link)) {
      this.hydrateState(item);
    }
  }

  componentDidUpdate() {
    this.checkDeferredCommands();
  }

  // List of commands to run after render.
  deferredCommands = [];

  /*
   *
   * The Deferred Commands model is to allow us to run functions once the
   * state has been set.
   * This is useful when we want to persist data via a redux action, or run
   * multiple steps to record progress.
   *
   */
  checkDeferredCommands = () => {
    if (!this.deferredCommands.length) return;

    this.deferredCommands = this.deferredCommands.filter(command => {
      command();
      return false;
    });
  };

  hydrateState = item => {
    this.setState({ link: getLearnItemLinkStruct(item) });
  };

  handleSubmit = () => {
    const { link } = this.state;
    const { onChange } = this.props;
    this.deferredCommands.push(() => {
      onChange(link);
    });
    this.handleCancel();
  };

  handleDelete = () => {
    const { link } = this.state;
    const { onDelete } = this.props;
    onDelete(link);
  };

  handleChange = event => {
    const { link } = this.state;
    link[event.target.name] = event.target.value;
    this.setState({ link });
  };

  handleClick = () => {
    this.setState({ edit: true });
  };

  handleCancel = () => {
    this.setState({ edit: false });
  };

  handleLinkClick = url => event => {
    event.stopPropagation();
    url = url.trim();
    if (url) {
      const externalUrl = url.startsWith('http') ? url : `http://${url}`;
      window.open(externalUrl, '_blank').focus();
    }
  };

  render() {
    const { item, classes, readOnly } = this.props;
    const { link, edit } = this.state;

    return (
      <React.Fragment>
        <ListItem button onClick={this.handleLinkClick(item.url)}>
          <Card
            elevation={0}
            style={{ borderLeft: '3px solid lightgray', marginBottom: 4 }}
          >
            <CardContent
              style={{ fontSize: '.8rem' }}
              classes={{ root: classes.cardContentRoot }}
            >
              {item.title}
              {readOnly ? (
                <IconButton
                  onClick={this.handleLinkClick(item.url)}
                  style={{ padding: 8 }}
                >
                  <OpenIcon style={{ fontSize: '1rem' }} />
                </IconButton>
              ) : (
                <IconButton
                  onClick={this.handleClick}
                  style={{ padding: 8 }}
                >
                  <EditIcon style={{ fontSize: '1rem' }} />
                </IconButton>
              )}
            </CardContent>
          </Card>
        </ListItem>
        <Dialog open={edit}>
          <DialogContent>
            <TextField
              multiline
              id="title"
              name="title"
              value={link.title === ' ' ? '' : link.title}
              required
              placeholder="Link Title"
              margin="normal"
              className={classes.textField}
              InputProps={{
                className: classes.input
              }}
              onChange={this.handleChange}
            />
            <TextField
              multiline
              id="url"
              name="url"
              placeholder="Link URL"
              value={link.url === ' ' ? '' : link.url}
              required
              margin="normal"
              className={classes.textField}
              InputProps={{
                className: classes.input
              }}
              onChange={this.handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              style={{ marginRight: 'auto' }}
              color="secondary"
              onClick={this.handleDelete}
            >
              Delete
            </Button>
            <Button color="secondary" onClick={this.handleCancel}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

LearnItemLink.propTypes = {
  item: PropTypes.any.isRequired,
  classes: PropTypes.any.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default withStyles(style)(LearnItemLink);
