import PropTypes from "prop-types";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import {EmailOutlined, MobileFriendly} from "@material-ui/icons";
import {useSelector} from "react-redux";

const Reminder = ({ reminders, onChange }) => {
  const preference = useSelector(state => state.userSMSPreference.preference);
  const { email, sms } = reminders;

  const handleChange = (email, sms) => {
    onChange({ email, sms });
  };

  return (
    <div>
      <ButtonGroup size={"small"} disableElevation style={{paddingLeft: 5}}>
        <Button
          onClick={() => handleChange(!email, sms)}
          variant={email ? "contained" : "outlined"}
          color={email ? "primary" : "default"}
          title="Email Me an reminder at the commitment time"
        >
          <EmailOutlined style={{ fontSize: '1.25rem' }}/>
        </Button>
        <Button
          onClick={() => handleChange(email, !sms)}
          disabled={!(preference.number && preference.verifiedAt)}
          variant={sms ? "contained" : "outlined"}
          color={sms ? "primary" : "default"}
          title="Text Message Me an reminder at the commitment time"
        >
          <MobileFriendly style={{ fontSize: '1.25rem' }}/>
        </Button>
      </ButtonGroup>
    </div>
  )
};

Reminder.propTypes = {
  reminders: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

export default Reminder