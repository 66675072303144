import React, { Component } from 'react';
import { connect } from 'react-redux';
import CommitmentFocus from '../../components/Commitment/Focus';
import { addJournalAction } from '../../redux/actions/journal';
import {
  requestActiveTrackerAction,
  resetActiveTrackerAction,
  setActiveTrackerAction
} from '../../redux/actions/activeTracker';
import {
  requestActiveMilestoneAction,
  resetActiveMilestoneAction,
  saveActiveMilestoneAction
} from '../../redux/actions/activeMilestone';
import { saveTrackerAction } from '../../redux/actions/trackers';
import { resetActiveCommitmentAction } from '../../redux/actions/activeCommitment';
import { saveCommitmentAction } from '../../redux/actions/commitments';

export class CommitmentFocusContainer extends Component {
  render() {
    return <CommitmentFocus />;
  }
}

const mapStateToProps = state => ({
  activeTrackerDoc: state.activeTracker,
  activeMilestoneDoc: state.activeMilestone,
  activeMilestones: state.activeMilestones,
  commitment: state.activeCommitment.active
});

const mapDispatchToProps = dispatch => ({
  setActiveTracker: tracker => {
    dispatch(setActiveTrackerAction(tracker));
  },
  requestActiveTracker: trackerId => {
    dispatch(requestActiveTrackerAction(trackerId));
  },
  resetActiveTracker: () => {
    dispatch(resetActiveTrackerAction());
  },
  resetActiveCommitment: () => {
    dispatch(resetActiveCommitmentAction());
  },
  resetActiveMilestone: () => {
    dispatch(resetActiveMilestoneAction());
  },
  requestActiveMilestone: milestoneId => {
    dispatch(requestActiveMilestoneAction(milestoneId));
  },
  addJournalEntry: item => {
    dispatch(addJournalAction(item));
  },
  saveActiveMilestone: milestone => {
    dispatch(saveActiveMilestoneAction(milestone));
  },
  saveTracker: tracker => {
    dispatch(saveTrackerAction(tracker));
  },
  saveCommitment: commitment => {
    dispatch(saveCommitmentAction(commitment));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CommitmentFocus);
