import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import TextField from '@material-ui/core/TextField';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import LaunchIcon from '@material-ui/icons/Launch';
import { withStyles } from '@material-ui/core/styles';
import {
  COMMITMENT_STATUS_COMPLETE,
  COMMITMENT_STATUS_MOVED,
  COMMITMENT_STATUS_PENDING,
  getCommitmentStruct
} from '../../structs/commitments';
import ColorSelectorHelper from '../Ui/ColorSelectorHelper';

const style = () => ({
  textField: {
    margin: '8px 0',
    width: 'calc(100% - 96px)'
  },
  override: {
    display: 'inherit'
  },
  listPadding: {
    padding: '0'
  },
  commitDate: {
    fontSize: '.8rem',
    color: 'gray',
    marginTop: '-12px',
    paddingLeft: '50px'
  },
  secondaryIconButton: {
    padding: 3,
    marginTop: -3
  },
  secondaryIcon: {
    paddingLeft: 3,
    paddingRight: 3,
    fontSize: 19
  },
  checkbox: {
    padding: '10px 5px'
  }
});

class CommitmentItem extends Component {
  state = {
    commitment: getCommitmentStruct()
  };

  componentDidMount() {
    const { commitment: commitmentProp } = this.props;
    const { commitment } = this.state;
    if (
      commitment.id !== commitmentProp.id ||
      commitment.updatedAt !== commitmentProp.updatedAt
    ) {
      this.hydrate(commitmentProp);
    }
  }

  componentDidUpdate() {
    const { commitment: commitmentProp } = this.props;
    const { commitment } = this.state;
    if (
      commitment.id !== commitmentProp.id ||
      commitment.updatedAt !== commitmentProp.updatedAt
    ) {
      this.hydrate(commitmentProp);
    }
  }

  hydrate = commitment => this.setState({ commitment });

  handleChange = event => {
    const { commitment } = this.state;
    const newCommitment = {
      ...commitment
    };
    newCommitment[event.target.name] = event.target.value;
    if (event.target.name === 'status') {
      newCommitment[event.target.name] = event.target.checked
        ? COMMITMENT_STATUS_COMPLETE
        : COMMITMENT_STATUS_PENDING;
    }
    this.setState({
      commitment: newCommitment
    });
    this.persistChange();
  };

  persistChange = debounce(() => {
    const { saveCommitment } = this.props;
    const { commitment } = this.state;
    saveCommitment(commitment);
  }, 650);

  handleCommitmentFocus = () => {
    const { setActiveCommitment } = this.props;
    const { commitment } = this.state;
    setActiveCommitment(commitment);
  };

  handleColorChange = color => {
    const { commitment } = this.state;
    commitment.highlightColor = color;

    this.setState({ commitment });

    this.persistChange();
  };

  render() {
    const { commitment } = this.state;
    const {
      classes,
      showDate,
      superSimple
    } = this.props;
    const commitmentDate = new Date(commitment.date);

    let secondary = '';
    if (showDate)
      secondary = (
        <React.Fragment>
          {secondary}
          {commitmentDate.toDateString()}
        </React.Fragment>
      );

    return (
      <ListItem
        dense
        className={[classes.listPadding, classes.override].join(' ')}
      >
        <ListItemText
          primary={
            superSimple ? (
              <Typography>
                <ColorSelectorHelper
                  color={
                    commitment.status === COMMITMENT_STATUS_PENDING
                      ? commitment.highlightColor
                      : null
                  }
                  iconStyles={{ fontSize: 14 }}
                  buttonStyles={{
                    padding: 3,
                    marginLeft: -20
                  }}
                />
                {commitment.description}
              </Typography>
            ) : (
              <React.Fragment>
                <ColorSelectorHelper
                  color={
                    commitment.status === COMMITMENT_STATUS_PENDING
                      ? commitment.highlightColor
                      : null
                  }
                  onSelect={this.handleColorChange}
                  buttonStyles={{
                    paddingRight: 0
                  }}
                />
                {commitment.status === COMMITMENT_STATUS_MOVED ? (
                  <IconButton disabled className={classes.checkbox}>
                    <ArrowRightAltIcon />
                  </IconButton>
                ) : (
                  <Checkbox
                    key={`${commitment.id}_complete`}
                    id={`${commitment.id}_complete`}
                    name="status"
                    checked={Boolean(commitment.status === COMMITMENT_STATUS_COMPLETE)}
                    tabIndex={-1}
                    disableRipple
                    onChange={this.handleChange}
                    className={classes.checkbox}
                    color={
                      commitment.status === COMMITMENT_STATUS_COMPLETE ? 'secondary' : 'default'
                    }
                  />
                )}
                <TextField
                  multiline
                  name="description"
                  onChange={this.handleChange}
                  key={`${commitment.id}_description`}
                  className={classes.textField}
                  value={commitment.description}
                  InputProps={{
                    disableUnderline: true
                  }}
                />
                <Typography className={classes.commitDate}>
                  {showDate ? (
                    <React.Fragment>
                      <span>{commitmentDate.toDateString()}</span>
                      <IconButton
                        className={classes.secondaryIconButton}
                        onClick={this.handleCommitmentFocus}
                      >
                        <LaunchIcon className={classes.secondaryIcon} />
                      </IconButton>
                    </React.Fragment>
                  ) : null}
                </Typography>
              </React.Fragment>
            )
          }
          secondary={superSimple ? secondary : null}
        />
      </ListItem>
    );
  }
}

CommitmentItem.defaultProps = {
  showDate: false,
  superSimple: false
};

CommitmentItem.propTypes = {
  classes: PropTypes.object.isRequired,
  commitment: PropTypes.object.isRequired,
  saveCommitment: PropTypes.func.isRequired,
  setActiveCommitment: PropTypes.func.isRequired,
  showDate: PropTypes.bool,
  superSimple: PropTypes.bool
};

export default withStyles(style)(CommitmentItem);
