import { put, takeLatest, select, take } from 'redux-saga/effects';
import {
  APPLICATION_DATA_CLEAR_REQUEST_ACTION, APPLICATION_DATA_REQUEST_ACTION,
} from '../actions/app';
import {addNotificationAction, addEmailNotificationAction} from '../actions/notifications';
import {
  getSystemNotificationStruct,
  NOTIFICATION_TOPIC_COMMITMENT_INVITE
} from '../../structs/notification';
import {
  getCommitInviteStruct,
  COMMIT_INVITE_STATUS_ACCEPTED,
  COMMIT_INVITE_STATUS_DECLINED, COMMIT_INVITE_STATUS_PENDING
} from '../../structs/commitInvite';
import {
  COMMIT_INVITE_ACCEPT_REQUEST_ACTION,
  COMMIT_INVITE_ACCEPT_SUCCESS_ACTION,
  COMMIT_INVITE_CREATE_REQUEST_ACTION,
  COMMIT_INVITE_CREATE_SUCCESS_ACTION,
  COMMIT_INVITE_DATA_LOAD_SUCCESS_ACTION,
  COMMIT_INVITE_DECLINE_REQUEST_ACTION,
  COMMIT_INVITE_DECLINE_SUCCESS_ACTION,
  COMMIT_INVITE_DELETE_REQUEST_ACTION,
  COMMIT_INVITE_DELETE_SUCCESS_ACTION, COMMIT_INVITE_FULL_SYNC_REQUEST_ACTION,
  COMMIT_INVITE_FULL_SYNC_SUCCESS_ACTION,
  COMMIT_INVITE_REMOVE_LOCAL_ACTION,
  COMMIT_INVITE_UPSERT_REQUEST_ACTION,
  COMMIT_INVITE_UPSERT_SUCCESS_ACTION,
  setCommitInvitesAction,
  upsertCommitInviteAction,
  upsertLocalCommitInviteAction
} from '../actions/commitInvite';
import {
  deleteRemoteCommitInvite, fetchRemoteCommitInvites,
  fetchRemoteCommitInvitesByTracker,
  upsertRemoteCommitInvite
} from '../../utils/appSync/commitInvite';
import {TRACKER_FETCH_SUCCESS_ACTION} from '../actions/trackers';
import {COMMIT_INVITE_UPSERT_LOCAL_ACTION} from "../actions/commitInvite";
import {parseApn, stringifyApn} from "../../utils/apn/v2";
import {setActiveContextAction} from "../actions/activeContext";
import {
  acceptTrackerShareAction,
  addTrackerShareAction, TRACKER_SHARE_ACCEPT_SUCCESS_ACTION,
  TRACKER_SHARE_CREATE_SUCCESS_ACTION
} from "../actions/trackerShares";
import {
  TRACKER_SHARE_PERMISSION_CONTRIBUTE,
  TRACKER_SHARE_STATUS_ACTIVE,
  TRACKER_SHARE_STATUS_PENDING
} from "../../structs/trackerShare";
import {CONTACT_AFTER_SHARE_SUCCESS_ACTION} from "../actions/contacts";

function* handleCreateCommitInviteRequest({ payload: invite }) {
  const user = yield select(store => store.user);
  const contacts = yield select(store => store.contacts);
  const trackerDoc = yield select(store => store.trackerIndex.byId[invite.trackerId]);
  const { entity: tracker, share } = trackerDoc;
  const toTrackerOwner = tracker.ownerApn === invite.toUserApn;
  const { attributes: { familyName, givenName }} = user;

  const contact = contacts.find(c => c.friendApn === invite.toUserApn);

  const commitInvite = getCommitInviteStruct({
    ...invite,
    fromUserName: `${givenName} ${familyName}`,
    fromUserApn: stringifyApn({ userId: user.id })
  });

  // We need to ensure the remote user has access to the tracker via a share
  //
  // This Scenario can happen when you have previously shared with someone, then try to invite that person to a
  // new tracker, and haven't shared with them first.
  if (!toTrackerOwner) {
    if (!share.id || (share.id && share.permissionLevel === TRACKER_SHARE_PERMISSION_CONTRIBUTE)) {
      const { userId: shareUserId } = parseApn(invite.toUserApn);
      yield put(addTrackerShareAction({
        trackerId: commitInvite.trackerId,
        emailAddress: contact.friendEmail,
        userId: shareUserId
      }));

      yield take(TRACKER_SHARE_CREATE_SUCCESS_ACTION);
    }
  }

  const remoteCommitInvite = yield upsertRemoteCommitInvite(commitInvite);
  
  yield put({
    type: COMMIT_INVITE_UPSERT_LOCAL_ACTION,
    payload: remoteCommitInvite
  });
  
  yield put(
    addEmailNotificationAction({
      topic: NOTIFICATION_TOPIC_COMMITMENT_INVITE,
      payload: `
        <h1>Formigio Planner</h1>
        <p>You have sent an invitation to help: "${remoteCommitInvite.contextDescription}"</p>
        <p>Once they accept the invitation, they can add the commitment to their planner.</p>
        <br/>
        <p>Stay Tuned!</p>
      `,
      summaryText: `You have invited ${remoteCommitInvite.toUserName} to participate with "${remoteCommitInvite.contextDescription}"`
    })
  );
  
  yield put(
    addEmailNotificationAction({
      topic: NOTIFICATION_TOPIC_COMMITMENT_INVITE,
      payload: `
        <h1>Formigio Planner</h1>
        <p>You have been invited to help with ${user.attributes.givenName} ${user.attributes.familyName} by helping with: "${remoteCommitInvite.contextDescription}"</p>
        <br/>
        <p>Invites will appear in your inbox on your Home Page or on the Tracker in the <a href="https://app.formigio.com/">Formigio Planner</a></p>
        <br/>
        <p>Once you open, you can accept or decline. Accepting will prompt you to create a commitment in your planner.</p>
      `,
      summaryText: `Invitation to Work Together on "${remoteCommitInvite.contextDescription}"`,
      email: contact.friendEmail
    })
  );

  // Signal Complete
  yield put({
    type: COMMIT_INVITE_CREATE_SUCCESS_ACTION,
    payload: remoteCommitInvite
  });
}

function* handleUpdateCommitInviteRequest({ payload: invite }) {

  const user = yield select(store => store.user);
  const contacts = yield select(store => store.contacts);
  const contact = contacts.find(c => c.friendApn === invite.toUserApn);

  // Persist CommitInvite to Local Data Store
  const commitInvite = getCommitInviteStruct(invite);
  
  const remoteCommitInvite = yield upsertRemoteCommitInvite(commitInvite);
  
  yield put({
    type: COMMIT_INVITE_UPSERT_LOCAL_ACTION,
    payload: remoteCommitInvite
  });

  if (invite.status === COMMIT_INVITE_STATUS_PENDING) {
    yield put(
      addEmailNotificationAction({
        topic: NOTIFICATION_TOPIC_COMMITMENT_INVITE,
        payload: `
          <h1>Formigio Planner</h1>
          <p>A pending invite has been updated to: "${invite.contextDescription}".</p>
          <br/>
          <p>If you have any questions, ask ${user.attributes.givenName}.</p>
        `,
        summaryText: `RE: Invitation to Work Together on "${invite.contextDescription}"`,
        email: contact.friendEmail
      })
    );
  }

  // Signal Complete
  yield put({
    type: COMMIT_INVITE_UPSERT_SUCCESS_ACTION,
    payload: remoteCommitInvite
  });
}

function* handleDeleteCommitInviteRequest({ payload: invite }) {

  const user = yield select(store => store.user);
  const contacts = yield select(store => store.contacts);
  const contact = contacts.find(c => c.friendApn === invite.toUserApn);

  yield deleteRemoteCommitInvite(invite);
  
  yield put({
    type: COMMIT_INVITE_REMOVE_LOCAL_ACTION,
    payload: invite
  });

  if (invite.status === COMMIT_INVITE_STATUS_PENDING) {
    yield put(
      addEmailNotificationAction({
        topic: NOTIFICATION_TOPIC_COMMITMENT_INVITE,
        payload: `
          <h1>Formigio Planner</h1>
          <p>The pending invite to help with: "${invite.contextDescription}" has been removed. So, don't worry about it. :) </p>
          <br/>
          <p>If you have any questions, ask ${user.attributes.givenName}.</p>
        `,
        summaryText: `RE: Invitation to Work Together on "${invite.contextDescription}"`,
        email: contact.friendEmail
      })
    );
  }

  // Signal Complete
  yield put({
    type: COMMIT_INVITE_DELETE_SUCCESS_ACTION,
    payload: invite
  });
}

function* handleCommitInviteAcceptRequest({ payload: invite }) {
  
  const user = yield select(store => store.user);
  const trackerShares = yield select(store => store.trackerShares);

  const pendingShare = trackerShares.find(t => t.status === TRACKER_SHARE_STATUS_PENDING
    && t.trackerId === invite.trackerId
    && t.emailAddress === user.attributes.email);

  if (pendingShare) {
    yield put(acceptTrackerShareAction(pendingShare));
    yield take(TRACKER_SHARE_ACCEPT_SUCCESS_ACTION);
    yield take(CONTACT_AFTER_SHARE_SUCCESS_ACTION);
  }

  const activeShares = yield select(store => store.trackerShares);
  const activeShare = activeShares.find(t => t.status === TRACKER_SHARE_STATUS_ACTIVE
    && t.trackerId === invite.trackerId
    && t.userId === user.id);

  if (!activeShare) {
    yield put(addNotificationAction(getSystemNotificationStruct({
      message: 'Looks like the tracker is not shared with you yet. Please accept the Tracker Share before accepting this invite.',
      level: 'warning'
    })));
    return 0;
  }

  const contacts = yield select(store => store.contacts);

  yield put(upsertCommitInviteAction({
    ...invite,
    status: COMMIT_INVITE_STATUS_ACCEPTED
  }));

  yield take(COMMIT_INVITE_UPSERT_SUCCESS_ACTION);

  const contact = contacts.find(c => c.friendApn === invite.fromUserApn);

  yield put(
    addEmailNotificationAction({
      topic: NOTIFICATION_TOPIC_COMMITMENT_INVITE,
      payload: `
        <h1>Formigio Planner</h1>
        <p>Your invitation to help <b>${invite.contextDescription}</b> has been accepted by ${user.attributes.givenName} ${user.attributes.familyName}</p>
        <br/>
        <p>Now let's get to work! :)</p>
      `,
      summaryText: `RE: Invitation to help "${invite.contextDescription}" has been accepted!`,
      email: contact.friendEmail
    })
  );

  const trackers = yield select(store => store.trackers);
  const tracker = trackers.find(t => t.id === invite.trackerId);

  yield put(setActiveContextAction({
    apn: invite.contextApn,
    description: invite.contextDescription,
    tracker,
    commitmentDescription: invite.description
  }));

  // Signal Complete
  yield put({
    type: COMMIT_INVITE_ACCEPT_SUCCESS_ACTION,
    payload: invite
  });
  
}

function* handleCommitInviteDeclineRequest({ payload: commitInvite }) {

  const user = yield select(store => store.user);
  const contacts = yield select(store => store.contacts);

  const contact = contacts.find(c => c.friendApn === commitInvite.fromUserApn);

  yield put(upsertCommitInviteAction({
    ...commitInvite,
    status: COMMIT_INVITE_STATUS_DECLINED
  }));
  
  yield take(COMMIT_INVITE_UPSERT_SUCCESS_ACTION);
  
  yield put(
    addEmailNotificationAction({
      topic: NOTIFICATION_TOPIC_COMMITMENT_INVITE,
      payload: `
        <h1>Formigio Planner</h1>
        <p>Your invitation to help <b>${commitInvite.contextDescription}</b> has been declined by ${user.attributes.givenName} ${user.attributes.familyName}</p>
        <br/>
      `,
      summaryText: `Invitation to commit "${commitInvite.contextDescription}" has been declined.`,
      email: contact.friendEmail
    })
  );

  yield put(addNotificationAction(getSystemNotificationStruct({
    message: 'Declined decision has been saved.'
  })));

  // Signal Complete
  yield put({
    type: COMMIT_INVITE_DECLINE_SUCCESS_ACTION,
    payload: commitInvite
  });
  
}

function* handleCommitInviteTrackerDataLoadRequest({ payload: tracker }) {

  // Pull shares for the active tracker
  const { items: commitInvites } = yield fetchRemoteCommitInvitesByTracker(tracker.id);

  for (const commitInvite of commitInvites) {
    yield put(upsertLocalCommitInviteAction(commitInvite));
  }

  // Signal Complete
  yield put({
    type: COMMIT_INVITE_DATA_LOAD_SUCCESS_ACTION
  });
}

function* handleApplicationDataClear() {
  yield put(setCommitInvitesAction([]));
}

function* handleFullSyncRequest() {
  const user = yield select(store => store.user);
  
  if (!user.id) return;
  
  // Pull Trackers Shared with Me
  const activeCommitInvites = yield fetchRemoteCommitInvites(user.id);

  // Fetch shared trackers
  for (const commitInvite of activeCommitInvites) {
    yield put(upsertLocalCommitInviteAction(commitInvite));
  }

  yield put({ type: COMMIT_INVITE_FULL_SYNC_SUCCESS_ACTION });
}

export default function* commitInviteSaga() {
  // Handle Actions
  yield takeLatest(COMMIT_INVITE_CREATE_REQUEST_ACTION, handleCreateCommitInviteRequest);
  yield takeLatest(COMMIT_INVITE_UPSERT_REQUEST_ACTION, handleUpdateCommitInviteRequest);
  yield takeLatest(COMMIT_INVITE_ACCEPT_REQUEST_ACTION, handleCommitInviteAcceptRequest);
  yield takeLatest(COMMIT_INVITE_DECLINE_REQUEST_ACTION, handleCommitInviteDeclineRequest);
  yield takeLatest(COMMIT_INVITE_DELETE_REQUEST_ACTION, handleDeleteCommitInviteRequest);
  yield takeLatest(
    TRACKER_FETCH_SUCCESS_ACTION,
    handleCommitInviteTrackerDataLoadRequest
  );

  // Update Storage State
  yield takeLatest(
    APPLICATION_DATA_CLEAR_REQUEST_ACTION,
    handleApplicationDataClear
  );
  yield takeLatest([
    COMMIT_INVITE_FULL_SYNC_REQUEST_ACTION,
    APPLICATION_DATA_REQUEST_ACTION
  ], handleFullSyncRequest);
}
