import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import BookIcon from "@material-ui/icons/Book";
import UserIcon from "@material-ui/icons/AccountCircle";
import ViewListIcon from "@material-ui/icons/ViewList";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import { withStyles } from "@material-ui/core/styles";
import routes from "../../constants/routes.json";
import home from "../images/home.png";
import help from "../images/help.png";
import settings from "../images/settings.png";
import trackers from "../images/trackers.png";
import addTrackers from "../images/addtracker.png";

const styles = () => ({
  list: {
    paddingRight: 18,
    paddingLeft: 0,
    margin: 0,
    marginTop: "1em",
    textAlign: "center",
  },
  listItem: {
    listStyleType: "none",
    padding: "0 0 11px 0",
    display: "block",
  },
  listItemDream: {
    listStyleType: "none",
    padding: "0 0 11px 0",
    display: "block",
    left: 3,
    position: "relative",
    marginTop: 10,
  },
  shape: {
    height: 22,
  },
});

class Navigation extends Component {
  state = {};

  render() {
    const { classes } = this.props;

    return (
      <ul className={classes.list}>
        <NavLink
          to={routes.HOME}
          className={`${classes.listItem} tour-homepage`}
          exact
          title="Home"
        >
          <Tooltip title="Home Page" placement="right">
            <img className={classes.shape} alt="" src={home} />
          </Tooltip>
        </NavLink>

        <NavLink
          to={routes.TRACKERS}
          className={`${classes.li} tour-trackers`}
          exact
          title="Tracker Pages"
        >
          <Tooltip title="Tracker List" placement="right">
            <img className={classes.shape} alt="" src={trackers} />
          </Tooltip>
        </NavLink>
        <NavLink
          to={routes.CREATEDREAM}
          className={`${classes.listItemDream} tour-create-tracker`}
          exact
          title="Add a Tracker Page"
        >
          <Tooltip title="Add a Tracker" placement="right">
            <img className={classes.shape} alt="" src={addTrackers} />
          </Tooltip>
        </NavLink>
        <NavLink
          to={routes.LIST}
          className={`${classes.listItem}`}
          exact
          title="Lists"
        >
          <Tooltip title="Lists" placement="right">
            <ViewListIcon />
          </Tooltip>
        </NavLink>
        <NavLink
          to={routes.JOURNAL}
          className={`${classes.listItem} tour-journal`}
          exact
          title="Journal Pages"
        >
          <Tooltip title="Journal" placement="right">
            <BookIcon />
          </Tooltip>
        </NavLink>
        <NavLink
          to={routes.TRACKER_TEMPLATES}
          className={`${classes.listItem}`}
          exact
          title="Tracker Templates"
        >
          <Tooltip title="Tracker Templates" placement="right">
            <LibraryBooks/>
          </Tooltip>
        </NavLink>
        <NavLink
          to={routes.SETTINGS}
          className={`${classes.listItem} tour-settings`}
          exact
          title="View Settings"
        >
          <Tooltip title="Settings" placement="right">
            <img className={classes.shape} alt="" src={settings} />
          </Tooltip>
        </NavLink>
        <NavLink
          to={routes.HELP}
          className={`${classes.listItem} tour-help`}
          exact
          title="View Help Area"
        >
          <Tooltip title="Help Section" placement="right">
            <img className={classes.shape} alt="" src={help} />
          </Tooltip>
        </NavLink>

        <NavLink
          to={routes.MY_ACCOUNT}
          className={`${classes.listItem}`}
          exact
          title="My Account"
        >
          <Tooltip title="My Account" placement="right">
            <UserIcon />
          </Tooltip>
        </NavLink>

      </ul>
    );
  }
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigation);
