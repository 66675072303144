import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Style from './Style';
import {Redirect} from 'react-router';
import ROUTES from '../../constants/routes';
import {USER_STATUS_LOGGED_IN} from '../../structs/user';

class ConfirmConfirmation extends Component {

  handleContinue = () => {
    const { signIn } = this.props;
    const { user: { credentials } } = this.props;
  
    signIn(credentials);
  };
  
  render() {
    const { classes, user: { status } } = this.props;
  
    if (status === USER_STATUS_LOGGED_IN) {
      return <Redirect to={ROUTES.PAYMENT} />
    }
  
    return (
      <Grid item data-tid="container" xs={8} sm={6} md={4}>
        <div className={classes.heading}>
          <Fade in>
            <Typography
              variant="h5"
              color="secondary"
              style={{ marginBottom: 15 }}
            >
              Congratulations, your email is verified.
            </Typography>
          </Fade>
          <Typography style={{ paddingTop: 20, fontWeight: 700 }}>
            You are now free to move forward towards your dreams, and make magic happen.
          </Typography>
        </div>

        <Fade in>
          <div style={{ marginTop: 20, width: '100%' }}>
            <Button
              variant="contained"
              fullWidth={true}
              color="primary"
              onClick={this.handleContinue}
            >
              Continue
            </Button>
          </div>
        </Fade>
      </Grid>
    );
  }
}

ConfirmConfirmation.propTypes = {
  classes: PropTypes.object.isRequired,
  signIn: PropTypes.func.isRequired
};

export default withStyles(Style)(ConfirmConfirmation);
