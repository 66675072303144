import moment from 'moment';
import { parseApn } from '../utils/apn/v1';
import { stringifyApn as stringifyApnV2 } from '../utils/apn/v2';

export const JournalStruct = {
  schemaVersion: '1.3.0',
  ownerApn: '',
  id: '',
  entry: '',
  datetime: '',
  apn: '', // Depreciated 1.2.0
  trackerId: '',
  updatedAt: '',
  createdBy: ''
};

export const JournalDocumentStruct = {
  entity: {},
  dream: ''
};

export const getJournalStruct = (journal = {}) =>
  Object.assign({}, JournalStruct, journal);

export const getJournalDocumentStruct = (doc = JournalDocumentStruct) =>
  Object.assign({}, JournalDocumentStruct, doc);

export const requiresMigration = (entity) => {
  const { schemaVersion } = JournalStruct;
  return entity.schemaVersion !== schemaVersion;
};

export const evaluateJournals = (journals) => {
  const valid = [];
  const migrate = [];

  journals.map(journal => {
    if (requiresMigration(journal)) {
      migrate.push(journal);
    } else {
      valid.push(journal);
    }

    return journal;
  });

  return {valid, migrate};
};

export const migrateJournalEntries = (entries, user) => {
  const { schemaVersion } = JournalStruct;

  return entries.map(entry => {
    if (entry.schemaVersion !== schemaVersion) {
      return migrateJournalEntry(entry, user, schemaVersion);
    }
    return entry;
  });
};

export const migrateJournalEntry = (entry, user, toVersion = JournalStruct.schemaVersion) => {
  const versions = ['undefined', '1.0.0', '1.1.0', '1.2.0', '1.3.0'];
  const newEntry = getJournalStruct(entry);

  if (!entry.schemaVersion) newEntry.schemaVersion = 'undefined';

  while (newEntry.schemaVersion !== toVersion) {
    const whereAreWeNow = versions.indexOf(newEntry.schemaVersion);
    const whereAreWeGoing = versions.indexOf(toVersion);

    let targetVersion = toVersion;

    if (whereAreWeGoing - whereAreWeNow > 1)
      targetVersion = versions[whereAreWeNow + 1];

    // Version transitions
    if (`${newEntry.schemaVersion}_${targetVersion}` === 'undefined_1.0.0') {
      // We don't need to do anything for this version
    } else if (`${newEntry.schemaVersion}_${targetVersion}` === '1.0.0_1.1.0') {
      // Set the updatedAt to the current datetime
      newEntry.updatedAt = newEntry.datetime;
    } else if (`${newEntry.schemaVersion}_${targetVersion}` === '1.1.0_1.2.0') {
      // Update APN
      const { trackerId } = parseApn(entry.apn);
      // Add Owner Apn
      newEntry.ownerApn = stringifyApnV2({ userId: user.id });
      newEntry.trackerId = trackerId;
      delete newEntry.apn;

      // Convert Dates
      newEntry.datetime = moment(entry.datetime).format();
      newEntry.updatedAt = moment(entry.updatedAt).format();
    } else if (`${newEntry.schemaVersion}_${targetVersion}` === '1.2.0_1.3.0') {

      // Convert Dates
      newEntry.datetime = moment(entry.datetime).toISOString();
      newEntry.updatedAt = moment(entry.updatedAt).toISOString();

    }

    // Version Up the Tracker Schema
    newEntry.schemaVersion = targetVersion;
  }

  return getJournalStruct(newEntry);
};
