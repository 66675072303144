import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { ActiveTrackerStruct, getTrackerStruct } from '../../structs/trackers';
import routes from '../../constants/routes.json';
import SuccessList from '../Tracker/Success/List';
import logo from '../images/fwing.png';
import Tour from '../../containers/Tour';
import TrackerLearnItems from '../Tracker/Learn/Items';
import TrackerMeasureMetrics from '../Tracker/Measure/Metrics';
import style from './Style';
import debounce from 'lodash/debounce';
import PlanList from "../../containers/Plan/List";
import RoleButton from "../Role/Button";

class CreateDream extends Component {
  state = {
    tracker: getTrackerStruct()
  };

  componentDidMount() {
    const { resetActiveTracker, checkPayGate } = this.props;
    resetActiveTracker();
    checkPayGate();
  }

  componentDidUpdate() {
    const { activeTracker } = this.props;
    const { tracker } = this.state;

    if (
      tracker.id !== activeTracker.tracker.id ||
      tracker.updatedAt !== activeTracker.tracker.updatedAt
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ tracker: { ...tracker, ...activeTracker.tracker } });
    }
  }
  
  debounceSave = debounce(() => {
    const { tracker } = this.state;
    const { saveTracker } = this.props;
    saveTracker(tracker);
  }, 650);
  
  handleChange = event => {
    const { tracker } = this.state;
    if (Object.keys(tracker).includes(event.target.name)) {
      tracker[event.target.name] = event.target.value;
      this.setState({ tracker },() => tracker.id && this.debounceSave());
    }
  };

  handleSuccessChange = success => {
    const { tracker } = this.state;
    const { saveTracker } = this.props;
    tracker.success = success.map(m => m);
    this.setState({ tracker },() => saveTracker(tracker));
  };

  handleReviewCycleChange = event => {
    const { tracker } = this.state;
    const { saveTracker } = this.props;
    const { reviewCycle } = tracker;
    if (Object.keys(reviewCycle).includes(event.target.name)) {
      reviewCycle[event.target.name] = event.target.value;
      tracker.reviewCycle = reviewCycle;
      this.setState({ tracker },() => saveTracker(tracker));
    }
  };

  handleLearnChange = learn => {
    const { tracker } = this.state;
    const { saveTracker } = this.props;
    tracker.learn = learn.map(m => m);
    this.setState({ tracker },() => saveTracker(tracker));
  };

  handleMilestoneChange = milestones => {
    const { tracker } = this.state;
    const { saveTracker } = this.props;
    tracker.milestones = milestones.map(m => m);
    this.setState({ tracker },() => saveTracker(tracker));
  };
  
  handlePlanChange = plans => {
    const { tracker } = this.state;
    const { saveTracker } = this.props;
    tracker.plans = plans;
    this.setState({ tracker },() => saveTracker(tracker));
  };

  handleMetricsChange = metrics => {
    const { tracker } = this.state;
    const { saveTracker } = this.props;
    tracker.metrics = metrics.map(m => m);
    this.setState({ tracker },() => saveTracker(tracker));
  };

  handleRoleChange = (role) => {
    const { tracker } = this.state;
    const { saveTracker } = this.props;
    this.setState({ tracker: {...tracker, role }},() => saveTracker({...tracker, role }));
  };

  handleCreateTracker = () => {
    const { tracker } = this.state;
    const { createTracker } = this.props;
    createTracker(tracker);
  };

  handleSaveTracker = () => {
    const { tracker } = this.state;
    const {
      saveTracker,
      history,
      activeTracker: {
        tracker: { milestones }
      }
    } = this.props;
    saveTracker({ ...tracker, milestones });
    history.push(`${routes.TRACKER_BASE}${tracker.id}`);
  };

  handleClear = () => {
    const { resetActiveTracker, deleteTracker, activeTracker } = this.props;
    deleteTracker(activeTracker);
    resetActiveTracker();

    this.setState({
      tracker: getTrackerStruct()
    });
  };

  render() {
    const { tracker } = this.state;
    const { reviewCycle } = tracker;
    const { activeTracker: activeTrackerDoc, classes } = this.props;
    const { tracker: activeTracker } = activeTrackerDoc || ActiveTrackerStruct;

    return (
      <div className={classes.container}>
        {/* <Navigation /> */}
        <div className={classes.containerCenter}>
          <Typography variant="h5" color="secondary" className="tour-welcome">
            Create a Tracker page
          </Typography>
          <div className={`${classes.dreamListLabel} tour-dream`}>
            <Typography gutterBottom>Describe the dream</Typography>
            <Card>
              <CardContent>
                <TextField
                  style={{ width: '90%' }}
                  id="dreamInput"
                  name="dream"
                  label="What’s your dream?"
                  helperText="Using just a few words explain the dream."
                  onChange={this.handleChange}
                  value={tracker.dream}
                  margin="normal"
                  autoFocus
                />
              </CardContent>
            </Card>
          </div>
          {activeTracker.id ? (
            <React.Fragment>
              <div className={`${classes.dreamListLabel} tour-success`}>
                <Typography gutterBottom>Define Success</Typography>
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="caption">
                      List the things that need to be in place for you you feel
                      satisfied at the end.
                    </Typography>
                    <SuccessList
                      tracker={tracker}
                      onChange={this.handleSuccessChange}
                    />
                  </CardContent>
                </Card>
              </div>
              <div className={`${classes.dreamListLabel} tour-learn`}>
                <Typography gutterBottom>
                  Capture what you know so far
                </Typography>
                <Card>
                  <CardContent>
                    <TrackerLearnItems
                      tracker={tracker}
                      onChange={this.handleLearnChange}
                    />
                  </CardContent>
                </Card>
              </div>
              <div className={`${classes.dreamListLabel} tour-plan`}>
                <Typography gutterBottom>Make plans</Typography>
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="caption">
                      List the plans along the accomplishment
                      path. Don&apos;t worry if you don&apos;t know many yet,
                      you can change these later.
                    </Typography>
                    <PlanList
                        tracker={tracker}
                        onChange={this.handlePlanChange}
                        withMilestoneList={false}
                    />
                  </CardContent>
                </Card>
              </div>
              <div className={`${classes.dreamListLabel} tour-measure`}>
                <Typography gutterBottom>
                  Anything you want to measure?
                </Typography>
                <Card>
                  <CardContent>
                    <TrackerMeasureMetrics
                      tracker={tracker}
                      onChange={this.handleMetricsChange}
                    />
                  </CardContent>
                </Card>
              </div>
              <div className={`${classes.dreamListLabel} tour-review-cycle`}>
                <Typography gutterBottom>Commit to a review cycle</Typography>
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="caption">
                      How often do you want to review your tracker?
                    </Typography>
                    <RadioGroup
                      aria-label="Review Cycle"
                      name="frequency"
                      value={reviewCycle.frequency}
                      onChange={this.handleReviewCycleChange}
                    >
                      <FormControlLabel
                        value="daily"
                        control={<Radio />}
                        label="Every Day"
                      />
                      <FormControlLabel
                        value="weekly"
                        control={<Radio />}
                        label="Every Week"
                      />
                      <FormControlLabel
                        value="monthly"
                        control={<Radio />}
                        label="Every Month"
                      />
                    </RadioGroup>
                  </CardContent>
                </Card>
              </div>
              <div className={`${classes.dreamListLabel}`}>
                <Typography gutterBottom>Add a Role</Typography>
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="caption">
                      Roles can help you focus by focusing on what you are doing at the time.
                    </Typography>
                    <div>
                      <RoleButton role={tracker.role} onChange={this.handleRoleChange}/>
                    </div>
                  </CardContent>
                </Card>
              </div>

              <div className={classes.buttonWrap}>
                <Button onClick={this.handleClear}>START OVER</Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleSaveTracker}
                >
                  CONTINUE TO TRACKER
                </Button>
              </div>
            </React.Fragment>
          ) : (
            <div className={classes.buttonWrap}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleCreateTracker}
              >
                CREATE TRACKER
              </Button>
            </div>
          )}
        </div>
        <div>
          <img src={logo} alt="" className={classes.logo} />
        </div>
        <Tour
          id="create-tracker-v1.0"
          steps={[
            {
              target: '.tour-welcome',
              content:
                'Tracker pages are the life blood of The Accomplishment System. ' +
                'Whenever there is something that you want to accomplish, you ' +
                'probably can do it better if you have a Tracker page. Tracker ' +
                'pages help you keep track of what you wanted to get done in ' +
                'the first place, and what the next steps are along the way.'
            },
            {
              target: '.tour-dream',
              content:
                'The Dream section of the page is for describing what you desire. ' +
                'We call it a dream, because much of the time that is all it is ' +
                'at the beginning. The purpose of the Tracker page is to make your ' +
                'dreams a reality. ' +
                'It is normally best if you keep this short and sweet. You will ' +
                'have more opportunity to define what success looks like below.'
            },
            {
              target: '.tour-success',
              content:
                'Success statements are next. Creating success statements is a ' +
                'good exercise in thinking through what will need to be true or ' +
                'physically in place at the end, for you to feel satisfied.'
            },
            {
              target: '.tour-learn',
              content:
                'Tracker pages have a Learn section. This is a free form area to ' +
                'make and keep notes about what it takes to accomplish your dream.'
            },
            {
              target: '.tour-plan',
              content:
                'The Plan section is where you will most likely spend most of ' +
                'your time when reviewing the Tracker pages and working towards ' +
                'your dream. Here we list out desired outcomes along the ' +
                'accomplishment path. We call these outcomes, Milestones. We like ' +
                'to think of these as things that mark our progress along the path.'
            },
            {
              target: '.tour-measure',
              content:
                'The Measure section is where you can track how you are doing ' +
                'towards a specific metric. Here you can record a value over time.'
            },
            {
              target: '.tour-review-cycle',
              content:
                'The Magic Bullet of accomplishing your dream - The Review Cycle. ' +
                'These Tracker pages are only as good as the time we spend reviewing ' +
                'them and committing to the next step. So, here you choose when ' +
                'you would like the application to prompt you to review.'
            }
          ]}
        />
      </div>
    );
  }
}

CreateDream.propTypes = {
  activeTracker: PropTypes.object.isRequired,
  createTracker: PropTypes.func.isRequired,
  saveTracker: PropTypes.func.isRequired,
  deleteTracker: PropTypes.func.isRequired,
  resetActiveTracker: PropTypes.func.isRequired,
  checkPayGate: PropTypes.func.isRequired,
  history: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(style)(CreateDream));
