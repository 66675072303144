import { UPDATE_TRACKER_INDEX } from '../actions/trackers';
import { TrackerIndexStruct } from '../../structs/indexers';

export default function trackerIndexReducer(
  oldstate = Object.assign({}, TrackerIndexStruct),
  action
) {
  switch (action.type) {
    case UPDATE_TRACKER_INDEX:
      return action.payload;

    default:
      return oldstate;
  }
}
