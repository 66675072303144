import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

const style = () => ({});

class CommitButton extends Component {

  handleClick = () => {
    const { context, setActiveContext } = this.props;
    setActiveContext(context);
  };

  render() {
    const {children, icon, ...rest} = this.props;
    
    if (icon) {
      return <IconButton
        size="small"
        color="primary"
        variant="contained"
        onClick={this.handleClick}
        {...rest}
      >
        {children}
      </IconButton>
    }

    return (
      <Button
        size="small"
        color="primary"
        variant="contained"
        onClick={this.handleClick}
        {...rest}
      >
        {children}
      </Button>
    );
  }
}

CommitButton.defaultProps = {
  icon: false
};

CommitButton.propTypes = {
  classes: PropTypes.any.isRequired,
  context: PropTypes.object.isRequired,
  setActiveContext: PropTypes.func.isRequired,
  icon: PropTypes.bool
};

export default withStyles(style)(CommitButton);
