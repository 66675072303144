import {API, graphqlOperation} from "aws-amplify";
import * as queries from "../../graphql/queries";
import {stringifyApn} from "../apn/v2";
import * as mutations from "../../graphql/mutations";
import {getCommitInviteStruct} from "../../structs/commitInvite";
import {prepAndPruneForAppSync} from "../appSync";

export const fetchRemoteCommitInvites = async userId => {
  let all = [];
  let whileNextToken = 'initial';

  /* eslint-disable no-await-in-loop */
  while (whileNextToken) {
    const { data: { listCommitmentInvites: { items, nextToken }}} = await API.graphql(
      graphqlOperation(queries.listCommitmentInvites, {
        toUserApn: stringifyApn({ userId }),
        limit: 500,
        nextToken: whileNextToken === 'initial' ? null : whileNextToken
      })
    );
    all = all.concat(items);
    whileNextToken = nextToken;
  }
  /* eslint-enable no-await-in-loop */

  return all.length > 0
    ? all.map(item => getCommitInviteStruct(item))
    : [];
};

export const fetchRemoteCommitInvitesByTracker = async (trackerId, token) => {
  const {data: {commitmentInvitesByTracker: {items, nextToken}}} = await API.graphql(
    graphqlOperation(queries.commitmentInvitesByTracker, {
      limit: 25,
      trackerId,
      sortDirection: 'DESC',
      nextToken: token
    })
  );

  return {
    nextToken,
    items: items.length > 0
      ? items.map(item => getCommitInviteStruct(item))
      : []
  }
};

export const upsertRemoteCommitInvite = async invite => {
  // We fetch the latest from the API prior to sending it in, for some DynamoDB conditional checks
  const remoteInvite = await fetchRemoteCommitInvite(invite.toUserApn, invite.id);

  if (!remoteInvite.id) {
    const input = prepAndPruneForAppSync(invite, getCommitInviteStruct);
    delete input.id;
    const { data: { createCommitmentInvite: item }} = await API.graphql(
      graphqlOperation(mutations.createCommitmentInvite, {
        input
      })
    );

    return getCommitInviteStruct(item);
  }

  const input = prepAndPruneForAppSync(invite, getCommitInviteStruct);

  const { data: { updateCommitmentInvite: item }} = await API.graphql(
    graphqlOperation(mutations.updateCommitmentInvite, {
      input
    })
  );

  return getCommitInviteStruct(item);
};

export const fetchRemoteCommitInvite = async (toUserApn, id) => {

  if (!id) return getCommitInviteStruct();

  const { data: { getCommitmentInvite: item }} = await API.graphql(
    graphqlOperation(queries.getCommitmentInvite, {
      toUserApn,
      id
    })
  );

  if (!item) return getCommitInviteStruct();
  return getCommitInviteStruct(item);
};

export const deleteRemoteCommitInvite = async invite => {
  await API.graphql(
    graphqlOperation(mutations.deleteCommitmentInvite, {
      input: { id: invite.id, toUserApn: invite.toUserApn }
    })
  ).catch(err => console.log(err));
};
