// Active Commitment Actions
export const SET_ACTIVE_COMMITMENT = 'SET_ACTIVE_COMMITMENT';
export const RESET_ACTIVE_COMMITMENT = 'RESET_ACTIVE_COMMITMENT';
export const SET_COMPLETED_COMMITMENT = 'SET_COMPLETED_COMMITMENT';
export const RESET_COMPLETED_COMMITMENT = 'RESET_COMPLETED_COMMITMENT';
export const REQUEST_ACTIVE_COMMITMENT = 'REQUEST_ACTIVE_COMMITMENT';

export const requestActiveCommitmentAction = commitment => ({
  type: REQUEST_ACTIVE_COMMITMENT,
  payload: commitment
});

export const setActiveCommitmentAction = commitment => ({
  type: SET_ACTIVE_COMMITMENT,
  payload: commitment
});

export const resetActiveCommitmentAction = () => ({
  type: RESET_ACTIVE_COMMITMENT,
  payload: null
});

export const setCompletedCommitmentAction = commitment => ({
  type: SET_COMPLETED_COMMITMENT,
  payload: commitment
});

export const resetCompletedCommitmentAction = () => ({
  type: RESET_COMPLETED_COMMITMENT,
  payload: null
});
