// Active Milestone Actions
export const SET_ACTIVE_MILESTONE = 'SET_ACTIVE_MILESTONE';
export const SAVE_ACTIVE_MILESTONE = 'SAVE_ACTIVE_MILESTONE';
export const RESET_ACTIVE_MILESTONE = 'RESET_ACTIVE_MILESTONE';
export const REQUEST_ACTIVE_MILESTONE = 'REQUEST_ACTIVE_MILESTONE';

export const setActiveMilestoneAction = milestone => ({
  type: SET_ACTIVE_MILESTONE,
  payload: milestone
});

export const saveActiveMilestoneAction = milestone => ({
  type: SAVE_ACTIVE_MILESTONE,
  payload: milestone
});

export const requestActiveMilestoneAction = milestoneId => ({
  type: REQUEST_ACTIVE_MILESTONE,
  payload: milestoneId
});

export const resetActiveMilestoneAction = () => ({
  type: RESET_ACTIVE_MILESTONE,
  payload: null
});
