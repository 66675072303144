import {API, graphqlOperation} from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import {getMilestoneStruct} from '../../structs/trackers';
import moment from 'moment';

/*
 *
 * Milestone Functions
 *
 */
export const fetchRemoteMilestone = async id => {
  const { data: { getMilestonev3 }} = await API.graphql(
    graphqlOperation(queries.getMilestonev3, {
      id
    })
  );
  
  if (!getMilestonev3) return getMilestoneStruct();
  
  return postFetchProcessMilestone(getMilestonev3);
};

export const upsertRemoteMilestone = async milestone => {
  if (!milestone) return;
  
  const remoteMilestone = await fetchRemoteMilestone(milestone.id);
  
  // If there is no remote milestone then create
  if (!remoteMilestone.id) {
    const input = prepAndPruneMilestoneForAppSync(
      Object.assign({}, remoteMilestone, milestone)
    );
    
    const GQL = await API.graphql(
      graphqlOperation(mutations.createMilestonev3, { input })
    );
    return postFetchProcessMilestone(GQL.data.createMilestonev3);
  }
  
  // If the remote is behind the local, then
  if (moment(remoteMilestone.updatedAt).isSameOrAfter(milestone.updatedAt)) {
    // No need to send the Milestone
    return postFetchProcessMilestone(remoteMilestone);
  }
  
  const input = prepAndPruneMilestoneForAppSync(
    Object.assign({}, remoteMilestone, milestone)
  );
  
  const GQL = await API.graphql(
    graphqlOperation(mutations.updateMilestonev3, { input })
  );
  return postFetchProcessMilestone(GQL.data.updateMilestonev3);
};

export const removeRemoteMilestone = async milestone => {
  await API.graphql(
    graphqlOperation(mutations.deleteMilestonev3, {
      input: { id: milestone.id }
    })
  ).catch(err => console.log(err));
};

const postFetchProcessMilestone = milestone => {
  // Pass fetched value through the Struct, never trust input. :)
  return getMilestoneStruct(milestone);
};

export const prepAndPruneMilestoneForAppSync = milestone => {
  // Prune any keys that are not in the schema
  const keys = Object.keys(getMilestoneStruct());
  const pruned = getMilestoneStruct(milestone);
  const prunedKeys = Object.keys(pruned);
  const propertiesToPrune = prunedKeys.filter(key => !keys.includes(key));
  if (propertiesToPrune.length)
    propertiesToPrune.map(key => delete pruned[key]);
  
  // Prep any empty strings
  keys.map(key => {
    if (typeof pruned[key] === 'string' && pruned[key] === '') {
      pruned[key] = ' ';
    }
    return true;
  });
  
  // Ensure that the milestone updated date is formatted correctly
  pruned.updatedAt = moment(pruned.updatedAt).format();
  
  return pruned;
};
