import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListsCard from '../../containers/Checklists/Card';
import { getChecklistStruct } from '../../structs/checklists';

export const style = () => ({
  listCard: {
    margin: '.5em',
    border: 'lightgray 1px solid',
    minWidth: '16em',
    maxWidth: '22em'
  },
  listCardFlexContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  container: {
    width: 'calc(100vw - 68px)',
    display: 'flex',
    flexDirection: 'row',
    marginTop: 23
  },
  contentContainer: {
    marginRight: '1em',
    display: 'flex'
  }
});

class ListsPage extends Component {
  state = {
    newList: getChecklistStruct()
  };

  handleNewChange = event => {
    const { newList } = this.state;
    newList[event.target.name] = event.target.value;
    this.setState({ newList });
  };

  handleAdd = event => {
    const { addList } = this.props;
    const { newList } = this.state;
    if (event.key !== 'Enter') return;

    addList(newList);

    this.setState({ newList: getChecklistStruct() });
  };

  render() {
    const { checklists, classes } = this.props;
    const { newList } = this.state;

    return (
      <div className={classes.container}>
        <div style={{ width: '100%' }}>
          <Typography variant="h5">Checklists</Typography>
          <div className={classes.contentContainer}>
            <div className={classes.listCardFlexContainer}>
              {checklists.length
                ? checklists.map(list => (
                    <ListsCard key={list.id} list={list} />
                  ))
                : null}
              <Card key="newList" elevation={0} className={classes.listCard}>
                <CardHeader
                  title={
                    <TextField
                      name="name"
                      placeholder="Add Checklist - Enter Name"
                      onChange={this.handleNewChange}
                      onKeyPress={this.handleAdd}
                      value={newList.name === ' ' ? '' : newList.name}
                      fullWidth
                      InputProps={{
                        disableUnderline: true
                      }}
                    />
                  }
                />
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ListsPage.defaultProps = {
  checklists: []
};

ListsPage.propTypes = {
  classes: PropTypes.any.isRequired,
  checklists: PropTypes.any,
  addList: PropTypes.func.isRequired
};

export default withStyles(style)(ListsPage);
