import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from '../redux/reducers';
import sagas from '../redux/sagas';
import { handleDiagnosticError } from '../redux/actions/diagnostics';

export const configureStore = (history) => {
  const sagaMiddleware = createSagaMiddleware({
    onError: error => {
      store.dispatch(handleDiagnosticError(error));
    }
  });

  const enhancer = applyMiddleware(thunk, sagaMiddleware, routerMiddleware(history));

  const store = createStore(createRootReducer(history), enhancer);

  store.runSaga = sagaMiddleware.run;
  sagas.forEach(saga => store.runSaga(saga));

  return store;
}
