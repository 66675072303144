import React, { Component } from 'react';
import { connect } from 'react-redux';
import {loginAfterConfirmAction} from '../redux/actions/auth';
import ConfirmConfirmation from '../components/Register/ConfirmConfirmation';

export class ConfirmConfirmationPageContainer extends Component {
  render() {
    return <ConfirmConfirmation />;
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  signIn: credentials => dispatch(loginAfterConfirmAction(credentials))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmConfirmation);
