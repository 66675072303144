import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles(() => ({
  cardElementContainer: {
    height: 40,
    display: 'flex',
    alignItems: 'center',

    '& .StripeElement': {
      width: '100%',
      padding: 15
    }
  }
}));

const PaymentForm = ({ onSubmit, onError }) => {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const [ready, setReady] = useState(false);

  const handleSubmit = async () => {
    const {
      error,
      paymentMethod: { id },
    } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      onError(`Hmm, looks like there is a problem. ${error.message}`);
    }

    onSubmit(id);
  };

  const handleChange = (event) => {
    setReady(event.complete);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardContent className={classes.cardElementContainer}>
          <CardElement onChange={handleChange} />
        </CardContent>
      </Card>

      <div style={{ marginTop: 20 }}>
        <Fade in>
          <Button
            disabled={!stripe || !ready}
            variant="contained"
            fullWidth={true}
            color="primary"
            onClick={handleSubmit}
          >
            {
              ready ? 'Process Payment & Subscribe' : 'Enter Payment Info'
            }
          </Button>
        </Fade>
      </div>
    </form>
  );
};

PaymentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default PaymentForm;
