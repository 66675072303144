import React from 'react';
import PropTypes from 'prop-types';
import {MenuItem} from "@material-ui/core";
import {TRACKER_ACTION_DELETE} from "../../../structs/trackers";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";

const DeleteMenuItem = ({
  onDialog
}) => {

  const handleDialog = () => {
    onDialog(TRACKER_ACTION_DELETE);
  };

  return (
    <MenuItem
      variant="text"
      color="secondary"
      onClick={handleDialog}
    >
      <ListItemIcon>
        <DeleteIcon fontSize={"small"}/>
      </ListItemIcon>
      <ListItemText>Delete</ListItemText>
    </MenuItem>
  )
};

DeleteMenuItem.defaultProps = {};

DeleteMenuItem.propTypes = {
  onDialog: PropTypes.func.isRequired
};

export default DeleteMenuItem;
