import { API, graphqlOperation } from 'aws-amplify';
import { stringifyApn } from '../apn/v2';
import { createTour, updateTour } from '../../graphql/mutations';
import { getUserTourStruct, UserTourStruct } from '../../structs/tours';
import { getTour, listTours } from '../../graphql/queries';

/*
 *
 * Tour Data Functions
 *
 */
export const fetchRemoteTours = async userId => {
  const {
    data: {
      listTours: { items: tours }
    }
  } = await API.graphql(
    graphqlOperation(listTours, {
      ownerApn: stringifyApn({ userId })
    })
  );
  return tours;
};

export const fetchRemoteTour = async (userId, id) => {
  const {
    data: { getTour: tour }
  } = await API.graphql(
    graphqlOperation(getTour, {
      ownerApn: stringifyApn({ userId }),
      id
    })
  );
  return postFetchProcessTour(tour);
};

export const upsertRemoteTour = async (user, tour) => {
  const currentTour = await fetchRemoteTour(user.id, tour.id);

  if (currentTour.ownerApn) {
    const input = prepAndPruneTourForAppSync({
      ...currentTour,
      ...tour,
      ownerApn: stringifyApn({ userId: user.id })
    });
    const {
      data: { updateTour: newTour }
    } = await API.graphql(
      graphqlOperation(updateTour, {
        input
      })
    );
    return postFetchProcessTour(newTour);
  }

  const input = prepAndPruneTourForAppSync({
    ...tour,
    ownerApn: stringifyApn({ userId: user.id })
  });
  const {
    data: { createTour: newTour }
  } = await API.graphql(
    graphqlOperation(createTour, {
      input
    })
  );
  return postFetchProcessTour(newTour);
};

/*
 *
 * Post Process Functions
 *
 */
const postFetchProcessTour = tour => {
  // Pass fetched value through the Struct, never trust input. :)
  const processedTour = getUserTourStruct(tour);

  return processedTour;
};

/*
 *
 * Pre-Process Functions
 *
 */
export const prepAndPruneTourForAppSync = tour => {
  // Prune any keys that are not in the schema
  const keys = Object.keys(UserTourStruct);
  const pruned = getUserTourStruct(tour);

  const prunedKeys = Object.keys(pruned);
  const propertiesToPrune = prunedKeys.filter(key => !keys.includes(key));
  if (propertiesToPrune.length)
    propertiesToPrune.map(key => delete pruned[key]);

  // Prep any empty strings
  keys.map(key => {
    if (typeof pruned[key] === 'string' && pruned[key] === '') {
      pruned[key] = ' ';
    }
    return true;
  });

  delete pruned.schemaVersion;

  return pruned;
};
