import {API, graphqlOperation} from 'aws-amplify';
import {getSubscriptionStruct, SUBSCRIPTION_CODE_PUBLIC} from '../../structs/subscription';
import {listFormigioSubscriptions} from '../../graphql/queries';

/*
 *
 * Subscription Fetch Function
 *
 */
export const fetchRemoteSubscriptions = async (code) => {
  const all = [];

  // Fetch all public codes
  const {
    data: { listFormigioSubscriptions: { items }}
  } = await API.graphql(
    graphqlOperation(listFormigioSubscriptions, {
      filter: {
        code: {
          eq: SUBSCRIPTION_CODE_PUBLIC
        }
      }
    })
  );
  const fetched = postFetchSubscriptions(items);

  fetched.forEach(item => {
    if (!all.find(sub => sub.id === item.id)) {
      all.push(item);
    }
  });

  // Fetch all public codes
  if (code) {
    const {
      data: { listFormigioSubscriptions: { items: subs }}
    } = await API.graphql(
      graphqlOperation(listFormigioSubscriptions, {
        filter: {
          code: {
            eq: code
          }
        }
      })
    );
    const codeSubs = postFetchSubscriptions(subs);
    codeSubs.forEach(item => {
      if (!all.find(sub => sub.id === item.id)) {
        all.push(item);
      }
    });
  }

  return all;
};

/*
 *
 * Post Process Functions
 *
 */
const postFetchSubscriptions = subscriptions => {
  // Pass fetched value through the Struct, never trust input. :)
  return subscriptions.map(subscription => getSubscriptionStruct(subscription))
    .sort((a,b) => a.sort > b.sort ? 1 : -1 );
};
