import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const style = () => ({});

class HoverButton extends Component {
  state = { hover: false };

  handleMouseOut = () => {
    this.setState({ hover: false });
  };

  handleMouseOver = () => {
    this.setState({ hover: true });
  };

  render() {
    const { ...props } = this.props;
    const { children, hoverContent, buttonClasses } = this.props;
    const { hover } = this.state;
    delete props.hoverContent;
    delete props.buttonClasses;
    return (
      <Button
        {...props}
        classes={buttonClasses}
        onMouseOut={this.handleMouseOut}
        onBlur={this.handleMouseOut}
        onMouseOver={this.handleMouseOver}
        onFocus={this.handleMouseOver}
      >
        {hover ? hoverContent : children}
      </Button>
    );
  }
}

HoverButton.defaultProps = {
  buttonClasses: {}
};

HoverButton.propTypes = {
  children: PropTypes.any.isRequired,
  hoverContent: PropTypes.any.isRequired,
  buttonClasses: PropTypes.any
};

export default withStyles(style)(HoverButton);
