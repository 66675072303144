import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
/*
 *
 * Metrics are things that can be measured.
 *
 * */
export const MetricStruct = {
  schemaVersion: '1.0.0',
  id: ' ',
  seq: ' ',
  type: ' ',
  item: {}
};

/*
 *
 * A Number Metric is something like your weight, or waistline,
 * when you are trying to lose weight
 *
 * */
export const MetricNumberStruct = {
  schemaVersion: '1.0.0',
  name: ' ',
  values: []
};

/*
 *
 * Metric Values are data points for a metric at a certain date and time
 *
 * */
export const MetricValueStruct = {
  schemaVersion: '1.0.0',
  id: ' ',
  value: ' ',
  datetime: ' '
};

export const getMetricStruct = (metric = MetricStruct) =>
  Object.assign({}, MetricStruct, metric, {
    id: metric.id && metric.id !== ' ' ? metric.id : uuidv4(),
    item: getMetricTypeStruct(metric)
  });

export const getMetricNumberStruct = (metric = MetricNumberStruct) =>
  Object.assign({}, MetricNumberStruct, metric, {
    values:
      typeof metric.values === 'object' && metric.values.length
        ? metric.values.map(value => getMetricValueStruct(value))
        : []
  });

export const getMetricValueStruct = (metricValue = MetricValueStruct) =>
  Object.assign({}, MetricValueStruct, metricValue, {
    id: metricValue.id && metricValue.id !== ' ' ? metricValue.id : uuidv4(),
    datetime:
      metricValue.datetime && metricValue.datetime !== ' '
        ? metricValue.datetime
        : moment().format()
  });

export const getMetricTypeStruct = (item = MetricStruct) => {
  switch (item.type) {
    case 'number':
      return getMetricNumberStruct(item.item);
    default:
      return getMetricNumberStruct(item.item);
  }
};
