import React, { Component } from 'react';
import { connect } from 'react-redux';
import PayGate from '../components/PayGate/PayGate';
import {withRouter} from 'react-router';
import {setPayGateAction} from '../redux/actions/app';

export class PayGateContainer extends Component {
  render() {
    return <PayGate />;
  }
}

const mapStateToProps = state => ({
  payGated: state.app.payGated,
  subscription: state.user.activeSubscription
});

const mapDispatchToProps = dispatch => ({
  disablePayGate: () => dispatch(setPayGateAction(false))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PayGate));
