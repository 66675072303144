module.exports = () => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 23,
    width: 'calc(100vw - 68px)'
  },
  heading: {
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontSize: 27,
    letterSpacing: 0,
    color: '#73bb86'
  },
  logo: {
    width: 35,
    height: 35,
  }
});
