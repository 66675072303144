import {
  ADD_LOCAL_COMMITMENT,
  REMOVE_LOCAL_COMMITMENT,
  SET_LOCAL_COMMITMENTS,
  SAVE_LOCAL_COMMITMENT,
  UPSERT_LOCAL_COMMITMENT
} from '../actions/commitments';

export default function commitmentReducer(oldstate = [], action) {
  switch (action.type) {
    case ADD_LOCAL_COMMITMENT:
      return [...oldstate, action.payload];

    case SET_LOCAL_COMMITMENTS:
      return action.payload;

    case REMOVE_LOCAL_COMMITMENT:
      return oldstate.filter(commitment => commitment.id !== action.payload.id);

    case SAVE_LOCAL_COMMITMENT:
      return oldstate.map(commitment => {
        if (action.payload.id === commitment.id) {
          return action.payload;
        }
        return commitment;
      });

    case UPSERT_LOCAL_COMMITMENT:
      if (!action.payload) return oldstate;
      if (
        oldstate.filter(commitment => action.payload.id === commitment.id)
          .length
      )
        return oldstate.map(commitment => {
          if (action.payload.id === commitment.id) {
            return action.payload;
          }
          return commitment;
        });

      return [...oldstate, action.payload];

    default:
      return oldstate;
  }
}
