// User Subscriptions
import moment from 'moment';
import StripeSubscriptionStruct from '../structs/stripe';

export const USER_MEMBERSHIP_LEVEL_BASIC = 'basic';
export const USER_MEMBERSHIP_LEVEL_BEGINNER = 'beginner';
export const USER_MEMBERSHIP_LEVEL_ADVANCED = 'advanced';

export const membershipLevels = {
  [USER_MEMBERSHIP_LEVEL_BASIC]: {
    trackersLimit: 3
  },
  [USER_MEMBERSHIP_LEVEL_BEGINNER]: {
    trackersLimit: 12
  },
  [USER_MEMBERSHIP_LEVEL_ADVANCED]: {
    trackersLimit: 0
  }
};

// Active memberships are paid for
export const USER_MEMBERSHIP_STATUS_ACTIVE = 'active';
// Cancelled memberships are cancelled completely, no time left of paid subscription
export const USER_MEMBERSHIP_STATUS_CANCELLED = 'cancelled';
// None are unknown
export const USER_MEMBERSHIP_STATUS_NONE = 'none';
// Lapsed are active that are not renewing for some reason, New Payment Method is needed
export const USER_MEMBERSHIP_STATUS_LAPSED = 'lapsed';

export const membershipStatusMap = {
  [USER_MEMBERSHIP_STATUS_ACTIVE]: 'Active',
  [USER_MEMBERSHIP_STATUS_CANCELLED]: 'Cancelled',
  [USER_MEMBERSHIP_STATUS_LAPSED]: 'Payment Needed',
  [USER_MEMBERSHIP_STATUS_NONE]: 'Unknown'
};

export const getMembershipStatus = (activeSubscription, stripeSubscription = StripeSubscriptionStruct) => {
  const { stripeCurrentPeriodEnd, cancelAtPeriodEnd } = stripeSubscription;
  const now = new Date();
  const currentTimeStamp = Math.floor(now.getTime() / 1000);

  // Active and in good standing
  if (currentTimeStamp <= stripeCurrentPeriodEnd || !activeSubscription || (activeSubscription && !activeSubscription.price)) {
    return {
      status: USER_MEMBERSHIP_STATUS_ACTIVE,
      label: membershipStatusMap[USER_MEMBERSHIP_STATUS_ACTIVE],
      endDate: stripeCurrentPeriodEnd
        ? moment(stripeCurrentPeriodEnd * 1000).calendar()
        : 'Forever'
    };
  }
  
  // Subscription is cancelled and ended
  if (currentTimeStamp > stripeCurrentPeriodEnd && cancelAtPeriodEnd) {
    return {
      status: USER_MEMBERSHIP_STATUS_CANCELLED,
      label: membershipStatusMap[USER_MEMBERSHIP_STATUS_CANCELLED],
      endDate: moment(stripeCurrentPeriodEnd * 1000).calendar()
    };
  }
  
  // Subscription Lapsed
  if (currentTimeStamp > stripeCurrentPeriodEnd) {
    return {
      status: USER_MEMBERSHIP_STATUS_LAPSED,
      label: membershipStatusMap[USER_MEMBERSHIP_STATUS_LAPSED],
      endDate: moment(stripeCurrentPeriodEnd * 1000).calendar()
    };
  }

  // Unknown, in a weird state
  return {
    status: USER_MEMBERSHIP_STATUS_NONE,
    label: membershipStatusMap[USER_MEMBERSHIP_STATUS_NONE],
    endDate: ''
  };
};
