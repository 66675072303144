import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider/Divider';
import Grid from '@material-ui/core/Grid';
import {getSMSTopicKeys, SMSTopicMapping} from '../../../structs/notification';
import Checkbox from '@material-ui/core/Checkbox';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchSMSPreferenceAction,
  saveUserSMSPreferencesAction, SET_USER_SMS_VERIFICATION_CODE_ACTION, showErrorAction, VERIFY_SMS_ACTION
} from '../../../redux/actions/notifications';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {sendImmediateSMS} from "../../../utils/appSync/smsNotification";
import InputAdornment from "@material-ui/core/InputAdornment";
import {CheckCircle} from "@material-ui/icons";

const SMSPreferencesPanel = () => {
  const dispatch = useDispatch();
  const userPreference = useSelector(store => store.userSMSPreference);
  const { verification_code, preference } = userPreference;
  const [sending, setSending] = useState(false);
  const [changed, setChanged] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [code, setCode] = useState('');
  const [number, setNumber] = useState('');
  const [unsubscribedTopics, setUnsubscribedTopics] = useState([]);
  const topics = getSMSTopicKeys();
  
  useEffect(() => {
    dispatch(fetchSMSPreferenceAction());
  }, [dispatch]);

  useEffect(() => {
    if (changed) {
      dispatch(saveUserSMSPreferencesAction({
        unsubscribedTopics,
      }));
    }
  }, [unsubscribedTopics, dispatch, changed]);

  const isEnabled = () => {
    return enabled && preference.number && preference.verifiedAt;
  }

  const toggleEnabled = () => {
    setChanged(true);
    setEnabled(!enabled);
    setUnsubscribedTopics(!enabled ? [] : topics);
    if (enabled === false){
      setOpen(true);
    }
  };
  
  const toggleTopics = (topic, value) => {
    setChanged(true);
    setUnsubscribedTopics(topics.filter(t => {
      if (t === topic && value === false)
        return false;
      if (t === topic && value)
        return true;
  
      return unsubscribedTopics.indexOf(t) !== -1;
    }));
  };
  
  const handleTopicLoad = () => {
    const { unsubscribedTopics: propTopics } = preference;
    
    setUnsubscribedTopics(propTopics);
    setEnabled(propTopics.length !== topics.length);
  };

  const handleSendVerification = () => {
    const code = Math.ceil(Math.random() * 100000);

    dispatch({
      type: SET_USER_SMS_VERIFICATION_CODE_ACTION,
      payload: code
    });

    const message = `Formigio - Verification Code: ${code}`;
    setSending(true);
    sendImmediateSMS(message, number).then((response) => {
      if (response === 'optedOpt') {
        dispatch(showErrorAction('It appears you have opted out of SMS Messages for this phone number. Please contact support if you wish to configure SMS messages.'));
      } else if (response === 'sent') {
        setConfirm(true);
      } else {
        dispatch((showErrorAction('An error has occurred when attempting to send validation SMS message. Please try again or contact support.')));
      }
      setSending(false);
    })
  };

  const handleVerify = () => {
    dispatch({
      type: VERIFY_SMS_ACTION,
      payload: {
        number,
        code
      }
    });
    setConfirm(false);
    setOpen(false);
  };

  const numberValid = number && String(number).length === 10;
  const codeValid = code && String(code).length === 5;

  return (
    <ExpansionPanel onChange={handleTopicLoad}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>SMS Notification Preferences</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Card>
          <CardHeader
            title="SMS Notification Preferences"
            subheader="Toggle your text message notifications"
          />
          <CardContent>
            <FormControlLabel
              onClick={toggleEnabled}
              control={<Switch checked={isEnabled()} />}
              label={
                enabled
                  ? `SMS Notifications - Enabled for number ending ...${String(preference.number).substring(String(preference.number).length - 4)}`
                  : "SMS Notifications - Disabled"
              }
            />
            <Divider />
            <Button
              variant={"text"}
              onClick={() => setOpen(true)}
            >
              {preference.number ? 'Update Number' : 'Add Mobile Number'}
            </Button>
            {isEnabled() && (
              <>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  {Object.keys(SMSTopicMapping).map(topicKey => {
                    const checked = unsubscribedTopics.indexOf(topicKey) === -1;
                    const description = SMSTopicMapping[topicKey];
                    return (
                      <Grid key={topicKey} item xs={6}>
                        <FormControlLabel
                          onClick={() =>
                            toggleTopics(
                              topicKey,
                              checked
                            )
                          }
                          control={
                            <Checkbox checked={checked} />
                          }
                          label={description}
                        />{" "}
                      </Grid>
                    )
                  })}
                </Grid>
              </>
            )}
          </CardContent>
        </Card>
        {confirm ? (
          <Dialog
            open={open}
            fullWidth
          >
            <DialogTitle>
              Confirm Subscription
            </DialogTitle>
            <DialogContent>
              <TextField
                fullWidth
                placeholder="00000"
                label="Confirmation Code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                error={String(code).length > 5}
                helperText={
                  !!code
                    ? 'Confirmation Codes are 5 digits, e.g. 12345'
                    : "A confirmation message has been sent to your mobile device. It may take a minute or three to arrive."
                }
                InputProps={{
                  endAdornment: <InputAdornment position="start">{codeValid && <CheckCircle color={"secondary"}/>}</InputAdornment>,
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {setConfirm(false)}}
                style={{marginRight: 'auto'}}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!(verification_code && codeValid)}
                onClick={handleVerify}
              >
                Complete Verification
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          <Dialog
            open={open}
            fullWidth
          >
            <DialogTitle>
              Add Mobile Number to Enable SMS Messages
            </DialogTitle>
            <DialogContent>
              <TextField
                fullWidth
                placeholder="0000000000"
                label="Mobile Phone Number"
                value={String(number).replace('-','')}
                onChange={(e) => setNumber(e.target.value)}
                error={number && String(number).length > 10}
                InputProps={{
                  startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                  endAdornment: <InputAdornment position="start">{numberValid && <CheckCircle color={"secondary"}/>}</InputAdornment>,
                }}
                helperText={
                  !!number
                    ? "Phone numbers must be 10 digits US country phone numbers. e.g. 8885551212"
                    : "By entering your number, you agree to messages to be sent to your phone. Message and data rates may apply."
                }
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpen(false)}
                style={{marginRight: 'auto'}}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color={sending ? "secondary" : "primary"}
                disabled={!numberValid}
                onClick={handleSendVerification}
              >
                {sending ? 'Sending...' : 'Send Verification SMS Message'}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default SMSPreferencesPanel;
