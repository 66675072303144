import React, {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import List from "@material-ui/core/List";
import TrackerTemplateItem from "./Item";
import Dialog from "@material-ui/core/Dialog";
import {getTrackerTemplateStruct, replaceVariables} from "../../structs/trackerTemplate";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {createTrackerFromScaffoldAction} from "../../redux/actions/trackers";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider/Divider";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Input from "@material-ui/core/Input/Input";
import {createTrackerTemplateAction} from "../../redux/actions/trackerTemplate";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

const TrackerTemplatePage = () => {
  const dispatch = useDispatch();
  const trackerTemplates = useSelector(store => store.trackerTemplates);
  const [activeTemplate, setActiveTemplate] = useState(getTrackerTemplateStruct());
  const [variableValues, setVariableValues] = useState({});
  const [searchTerm, setSearchTerm] = useState('');

  const handleCreateTracker = () => {
    const scrubbedScaffold = replaceVariables(activeTemplate.scaffold, variableValues);
    dispatch(createTrackerFromScaffoldAction(scrubbedScaffold));
    setActiveTemplate(getTrackerTemplateStruct());
  };

  const handleFileRead = (e) => {
    const importData = JSON.parse(e.target.result);
    dispatch(createTrackerTemplateAction(importData));
  };

  const handleFile = (e) => {
    const fileReader = new FileReader();
    fileReader.onload = handleFileRead;
    fileReader.readAsText(e.target.files[0]);
  };

  const handleSetActiveTemplate = useCallback((template) => {
    setActiveTemplate(template);
  }, []);

  const handleVariableChange = variable => (event) => {
    setVariableValues({
      ...variableValues,
      [variable.name]: event.target.value
    })
  };

  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };

  return (
    <div style={{ width: '100%' }}>
      <Typography variant="h1">Tracker Templates</Typography>
      <div>
        <TextField
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </div>
      <List>
        {trackerTemplates.length > 0 ? (
          trackerTemplates
            .filter(template => (searchTerm
              ? (template.name.toLowerCase().includes(searchTerm.toLowerCase())
                  || template.description.toLowerCase().includes(searchTerm.toLowerCase()))
              : template))
            .map(template =>
              (
                <TrackerTemplateItem key={template.id} template={template} onSetActiveTemplate={handleSetActiveTemplate} />
              )
            )
        ) : (
          <ListItem>
            <ListItemText primary="No Templates Yet. Create a template from the Tracker view or import." />
          </ListItem>
        )}
        <Divider />
        {!searchTerm && <ListItem>
          <ListItemText primary="Import Tracker Template" />
          <Input type="file" onChange={handleFile} />
        </ListItem>}
      </List>
      <Dialog
        open={!!activeTemplate.id}
        fullWidth
      >
        <DialogTitle>Create Tracker</DialogTitle>
        <DialogContent>
          <Typography>{activeTemplate.name}</Typography>
          <Typography>{activeTemplate.description}</Typography>

          {activeTemplate.variables.length > 0 ? activeTemplate.variables.map(variable => (
            <TextField
              fullWidth
              name={variable.name}
              label={`Enter value for ${variable.name}`}
              value={variableValues[variable.name] || ''}
              onChange={handleVariableChange(variable)}
            />
          )) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setActiveTemplate(getTrackerTemplateStruct())}
            style={{marginRight: 'auto'}}
          >
            Cancel
          </Button>
          <Button
            onClick={handleCreateTracker}
            variant={"contained"}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default TrackerTemplatePage;
