import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import ArchiveIcon from '@material-ui/icons/Archive';
import PropTypes from 'prop-types';

const defaultToolbarSelectStyles = {
  iconButton: {},
  iconContainer: {
    marginRight: '24px'
  }
};

class CustomToolbarSelect extends React.Component {
  handleArchive = () => {
    console.log('hello from Archive');
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.iconContainer}>
        <Tooltip title="Archive Selected">
          <IconButton
            className={classes.iconButton}
            onClick={this.handleArchive}
          >
            <ArchiveIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
}

CustomToolbarSelect.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(defaultToolbarSelectStyles, {
  name: 'CustomToolbarSelect'
})(CustomToolbarSelect);
