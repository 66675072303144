import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import RefreshIcon from '@material-ui/icons/Refresh';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import {COMMITMENT_STATUS_MOVED, COMMITMENT_STATUS_PENDING, getCommitmentStruct} from '../../structs/commitments';
import { convertPickerDateToDate } from '../../utils/date';
import DateHelper from './DateHelper';
import TimeSelectorHelper from '../Ui/TimeSelectorHelper';
import ColorSelectorHelper from '../Ui/ColorSelectorHelper';
import Calendar from '../../containers/Planner/Calendar';
import CommitmentTrayButton from "../CommitmentTray/Button";
import Reminder from "./Reminder";
import RoleButton from "../Role/Button";

export const style = () => ({});

class CommitmentRecommitInline extends Component {
  state = {
    commitment: getCommitmentStruct(),
    open: false,
    commitAgain: false
  };

  componentDidMount() {
    this.initializeCommitment();
  }

  componentDidUpdate() {
    this.initializeCommitment();
  }

  initializeCommitment = () => {
    const { commitment } = this.state;
    const { commitment: commitmentProp } = this.props;

    if (commitmentProp.id === '') return;

    if (
      commitment.id !== commitmentProp.id ||
      commitment.updatedAt !== commitmentProp.updatedAt
    ) {
      this.setState({ commitment: getCommitmentStruct(commitmentProp) });
    }
  };

  handleChange = event => {
    const { commitment } = this.state;

    this.setState({
      commitment: {
        ...commitment,
        [event.target.name]: event.target.name === 'date'
          ? moment(convertPickerDateToDate(commitment.date)).format()
          : event.target.value
      }
    });
  };

  handleSetDate = dateVal => {
    const { commitment } = this.state;
    const passedDate = moment(dateVal);
    const newDate = moment(commitment.date);
    newDate.set('year', passedDate.get('year'));
    newDate.set('month', passedDate.get('month'));
    newDate.set('date', passedDate.get('date'));

    this.setState({
      commitment: {
        ...commitment,
        date: moment(newDate).format()
      }
    });
  };

  handleSetDateStamp = dateVal => {
    const { commitment } = this.state;

    this.setState({
      commitment: {
        ...commitment,
        date: moment(dateVal).format()
      }
    });
  };

  handleSetTime = timeCommitment => {
    const { commitment } = this.state;

    this.setState({
      commitment: {
        ...commitment,
        timeCommitment
      }
    });
  };

  handleColorSelect = highlightColor => {
    const { commitment } = this.state;

    this.setState({
      commitment: {
        ...commitment,
        highlightColor
      }
    });
  };

  handleTrayChange = trays => {
    const { commitment } = this.state;
    this.setState({
      commitment: {
        ...commitment,
        trays
      }
    });
  };

  handleReminderChange = (reminders) => {
    const { commitment } = this.state;
    this.setState({
      commitment: {
        ...commitment,
        reminders
      }
    });
  };

  handleSubmit = () => {
    const { commitment } = this.state;
    const {
      saveCommitment,
      addCommitment,
      commitment: commitmentProp,
      resetActiveCommitment
    } = this.props;

    let originalDate = commitment.date;
    if (!this.getCommitAgain()) {
      originalDate = commitmentProp.originalDate || commitmentProp.date;
    }

    const newCommitment = getCommitmentStruct({
      ...commitment,
      originalDate
    });

    if (!this.getCommitAgain()) {
      saveCommitment(getCommitmentStruct({
        ...commitmentProp,
        status: COMMITMENT_STATUS_MOVED
      }));
    }

    addCommitment(getCommitmentStruct(newCommitment));
    this.handleHideCommitmentForm();
    resetActiveCommitment();
  };

  handleShowCommitmentForm = (commitAgain = false) => () => {
    const { commitment } = this.state;
    this.setState({
      open: true,
      commitAgain,
      commitment: {
        ...commitment,
        date: moment()
          .minutes(Math.floor(moment().minutes() / 15) * 15)
          .seconds(0)
          .format()
      }
    });
  };

  handleHideCommitmentForm = () => {
    this.setState({ open: false, commitAgain: false });
  };

  handleFocus = event => {
    event.target.select();
  };

  handleDelete = () => {
    const {
      deleteCommitment,
      resetActiveCommitment,
      commitment
    } = this.props;
    deleteCommitment(commitment);
    resetActiveCommitment();
  };

  getCommitAgain = () => {
    const { commitment } = this.props;
    const { commitAgain } = this.state;
    return commitAgain || commitment.status !== COMMITMENT_STATUS_PENDING;
  };

  render() {
    const { commitment, open } = this.state;
    const { commitment: commitmentProp, commitmentIndex } = this.props;

    return (
      <Grid container direction="column">
        <Collapse in={open}>
          <Card elevation={1} style={{ border: 'gray solid 1px' }}>
            <CardHeader
              title={
                !this.getCommitAgain() ? (
                  <React.Fragment>
                    Move / Re-commit <ArrowRightAltIcon />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    Commit Again <RefreshIcon />
                  </React.Fragment>
                )
              }
            />
            <CardContent>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'baseline',
                  marginBottom: '1.5em'
                }}
              >
                <div
                  style={{
                    marginRight: '.75em'
                  }}
                >
                  <ColorSelectorHelper
                    color={commitment.highlightColor}
                    onSelect={this.handleColorSelect}
                    buttonStyles={{ margin: 0 }}
                  />
                </div>
                {open ? (
                  <TextField
                    margin="dense"
                    id="commitmentDescription"
                    label="What do you still need to do?"
                    name="description"
                    autoFocus
                    value={commitment.description}
                    fullWidth
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    InputProps={{
                      disableUnderline: true
                    }}
                  />
                ) : null}
              </div>
              <div>
                <RoleButton onChange={this.handleRoleChange} role={commitment.role} />
                <CommitmentTrayButton onChange={this.handleTrayChange} trayIds={commitment.trays} />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                }}
              >
                <div>
                  {open ? (
                    <>
                      <DateHelper
                        commitment={commitment}
                        commitmentIndex={commitmentIndex}
                        onSelect={this.handleSetDate}
                      />
                      <div style={{display: 'flex'}}>
                        <TimeSelectorHelper
                          minutes={commitment.timeCommitment}
                          onSelect={this.handleSetTime}
                        />
                        <Reminder
                          onChange={this.handleReminderChange}
                          reminders={commitment.reminders}
                        />
                      </div>
                      <Calendar
                        onSelect={this.handleSetDateStamp}
                        activeDateStamp={moment(commitment.date).format()}
                        duration={commitment.timeCommitment}
                        color={commitment.highlightColor}
                      />
                    </>
                  ) : null}
                </div>
              </div>
            </CardContent>
            <CardActions>
              <Button
                onClick={this.handleHideCommitmentForm}
                color="secondary"
                variant="text"
              >
                Cancel
              </Button>
              <Button
                style={{ marginLeft: 'auto' }}
                onClick={this.handleSubmit}
                color="primary"
                variant="contained"
              >
                Save
              </Button>
            </CardActions>
          </Card>
        </Collapse>
        <Collapse in={!open}>
          <Card elevation={0}>
            <CardContent>
              <Grid container justify="space-evenly">
                {commitmentProp.status === COMMITMENT_STATUS_PENDING ? (
                  <Grid item xs={12} md={4}>
                    <Typography>Not able to get to this?</Typography>
                    <Button
                      size="small"
                      onClick={this.handleShowCommitmentForm()}
                      color="primary"
                      variant="outlined"
                    >
                      Move / Re-commit
                      <ArrowRightAltIcon style={{ padding: 3 }} />
                    </Button>
                  </Grid>
                ) : null}
                <Grid item xs={12} md={4}>
                  <Typography>More to do?</Typography>
                  <Button
                    size="small"
                    onClick={this.handleShowCommitmentForm(true)}
                    color="primary"
                    variant="outlined"
                  >
                    Commit Again
                    <RefreshIcon style={{ padding: 3 }} />
                  </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography>Plans change?</Typography>
                  <Button
                    size="small"
                    onClick={this.handleDelete}
                    color="secondary"
                    variant="text"
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Collapse>
      </Grid>
    );
  }
}

CommitmentRecommitInline.defaultProps = {
  commitment: getCommitmentStruct()
};

CommitmentRecommitInline.propTypes = {
  commitment: PropTypes.any,
  commitmentIndex: PropTypes.any.isRequired,
  deleteCommitment: PropTypes.func.isRequired,
  saveCommitment: PropTypes.func.isRequired,
  resetActiveCommitment: PropTypes.func.isRequired,
  addCommitment: PropTypes.func.isRequired
};

export default withStyles(style)(CommitmentRecommitInline);
