import React, { Component } from 'react';
import { connect } from 'react-redux';
import ListsList from '../../components/Checklists/Items/List';
import { saveChecklistAction } from '../../redux/actions/checklists';

export class ListsListContainer extends Component {
  render() {
    return <ListsList />;
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  saveList: list => {
    dispatch(saveChecklistAction(list));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ListsList);
