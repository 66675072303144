import moment from 'moment';
import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import PropType from 'prop-types';
import S3 from 'aws-sdk/clients/s3';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import Divider from '@material-ui/core/Divider/Divider';
import Input from '@material-ui/core/Input/Input';
import CardHeader from '@material-ui/core/CardHeader/CardHeader';
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Fade from '@material-ui/core/Fade';
import Chip from '@material-ui/core/Chip';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Avatar from '@material-ui/core/Avatar';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Style from './Style';
import sync from '../../utils/backup/sync';
import logo from '../images/fwing.png';
import { getBackupProfileStruct } from '../../structs/sync';
import { getUserToursStruct } from '../../structs/tours';
import { migrateTrackers } from '../../structs/trackers';
import { migrateCommitments } from '../../structs/commitments';
import { migrateJournalEntries } from '../../structs/journal';
import { migrateChecklists } from '../../structs/checklists';
import { migrateTimers } from '../../structs/timers';
import {
  getSystemNotificationStruct,
} from '../../structs/notification';
import ROUTES from '../../constants/routes.json';
import {fetchRemoteTrackerMostRecent} from '../../utils/appSync/tracker';
import EmailPreferencesPanel from './Panel/EmailPreferences';
import SMSPreferencesPanel from "./Panel/SMSPreferences";

class Settings extends Component {
  state = {
    user: null,
    tour: null,
    trackers: null,
    journal: null,
    commitments: null,
    timers: null,
    checklists: null,
    fileImport: false,
    imported: {
      user: null,
      trackers: [],
      journal: [],
      commitments: [],
      timers: [],
      checklists: [],
      tour: null,
    },
    localBackupProfile: getBackupProfileStruct(),
    backups: [],
    message: "",
    saving: false,
    clearDataConfirm: false,
    testJSON: "{}"
  };

  componentDidMount() {
    this.hydrateState();
  }

  componentDidUpdate() {
    this.hydrateState();
    this.checkDeferredCommands();
  }

  backupLocation = "backups";

  // List of commands to run after render.
  deferredCommands = [];

  /*
   *
   * The Deferred Commands model is to allow us to run functions once the
   * state has been set.
   * This is useful when we want to persist data via a redux action, or run
   * multiple steps to record progress.
   *
   */
  checkDeferredCommands = () => {
    if (!this.deferredCommands.length) return;
    
    this.deferredCommands = this.deferredCommands.filter((command) => {
      command();
      return false;
    });
  };
  
  hydrateState = () => {
    const { backupProfile } = this.props;
    let { localBackupProfile } = this.state;
    if (localBackupProfile.updatedAt !== backupProfile.updatedAt) {
      localBackupProfile = getBackupProfileStruct(backupProfile);
      this.setState({
        localBackupProfile,
      });
    }
  };

  validateBackupProfile = async () => {
    const { localBackupProfile } = this.state;
    const {
      user,
      addNotification,
      setBackupProfile,
      setInternetStatus,
    } = this.props;
    return new Promise((resolve, reject) => {
      if (localBackupProfile.type === "appSync") {
        fetchRemoteTrackerMostRecent(user.id)
          .then(() => {
            this.deferredCommands.push(() => {
              addNotification(
                getSystemNotificationStruct({
                  message: "AppSync Profile Verified, and profile saved.",
                  type: "snackbar",
                  level: "success",
                })
              );
            });
            localBackupProfile.verifiedAt = moment().toISOString();
            setInternetStatus("Connected");
            setBackupProfile(localBackupProfile);
            resolve();
            return true;
          })
          .catch((err) => {
            console.log(err);
            addNotification(
              getSystemNotificationStruct({
                message: "Profile was saved, but AppSync not verified.",
                type: "snackbar",
                level: "warning",
              })
            );
            localBackupProfile.verifiedAt = "";
            setBackupProfile(localBackupProfile);
            reject();
          });
      } else if (localBackupProfile.type === "s3") {
        console.log("Not Implemented Yet.");
        resolve();
      }
    });
  };

  handleBackupFetch = () => {
    const { localBackupProfile } = this.state;
    const { setBackupProfile, addNotification } = this.props;
    const { config: backupConfig } = localBackupProfile;

    if (localBackupProfile.type !== "s3") return;

    const client = new S3({
      accessKeyId: backupConfig.accessKey,
      secretAccessKey: backupConfig.secretKey,
      region: backupConfig.region,
    });
    client.listObjectsV2(
      {
        Bucket: backupConfig.bucket,
        Prefix: `${backupConfig.location}/${this.backupLocation}`,
        FetchOwner: false,
        MaxKeys: 20,
      },
      (err, data) => {
        if (err) {
          localBackupProfile.verifiedAt = "";
          this.deferredCommands.push(() => {
            addNotification(
              getSystemNotificationStruct({
                message: "Looks like there was a problem saving the profile.",
                type: "snackbar",
                level: "error",
              })
            );
            setBackupProfile(localBackupProfile);
          });
          this.setState({ saving: false, message: err.message });
        } else {
          localBackupProfile.verifiedAt = moment().toISOString();
          this.deferredCommands.push(() => {
            addNotification(
              getSystemNotificationStruct({
                message: "AWS Profile Verified.",
                type: "snackbar",
                level: "success",
              })
            );
            setBackupProfile(localBackupProfile);
          });
          this.setState({
            saving: false,
            backups: data.Contents,
            message: "",
          });
        }
      }
    );
  };

  handleBackupDownload = (key) => () => {
    const { localBackupProfile } = this.state;
    const { config: backupConfig } = localBackupProfile;

    if (localBackupProfile.type !== "s3") return;

    const client = new S3({
      accessKeyId: backupConfig.accessKey,
      secretAccessKey: backupConfig.secretKey,
      region: backupConfig.region,
    });
    client.getObject(
      {
        Bucket: backupConfig.bucket,
        Key: key,
      },
      (err, data) => {
        if (err) this.setState({ message: err.message });
        else {
          this.setState({
            imported: JSON.parse(data.Body.toString("utf-8")),
          });
        }
      }
    );
  };

  handleBackupDelete = (key) => () => {
    const { localBackupProfile } = this.state;
    const { config: backupConfig } = localBackupProfile;

    if (localBackupProfile.type !== "s3") return;

    const { addNotification } = this.props;
    const client = new S3({
      accessKeyId: backupConfig.accessKey,
      secretAccessKey: backupConfig.secretKey,
      region: backupConfig.region,
    });
    client.deleteObject(
      {
        Bucket: backupConfig.bucket,
        Key: key,
      },
      (err) => {
        if (err) {
          addNotification(
            getSystemNotificationStruct({
              message: `There has been an error: ${err.message}`,
              type: "snackbar",
              level: "error",
            })
          );
        } else {
          addNotification(
            getSystemNotificationStruct({
              message: "Backup has been deleted.",
              type: "snackbar",
              level: "success",
            })
          );
          this.handleBackupFetch();
        }
      }
    );
  };

  processBackup = (jsonData) => {
    const { localBackupProfile } = this.state;
    const { config: backupConfig } = localBackupProfile;

    if (localBackupProfile.type !== "s3") return;

    const { addNotification, setProcessingMessage } = this.props;
    const client = new S3({
      accessKeyId: backupConfig.accessKey,
      secretAccessKey: backupConfig.secretKey,
      region: backupConfig.region,
    });

    setProcessingMessage("Backup Process Started...");

    client.upload(
      {
        Bucket: backupConfig.bucket,
        Key: `${backupConfig.location}/${
          this.backupLocation
        }/${new Date().toJSON()}.json`,
        Body: jsonData,
      },
      {},
      (err) => {
        setProcessingMessage("");
        if (err) {
          addNotification(
            getSystemNotificationStruct({
              message: `There has been an error: ${err.message}`,
              type: "snackbar",
              level: "error",
            })
          );
        } else {
          addNotification(
            getSystemNotificationStruct({
              message: "Your data has been backed up and saved.",
              type: "snackbar",
              level: "success",
            })
          );
        }
      }
    );
  };

  processDownload = (jsonData, filename) => {
    const blob = new Blob([jsonData], { type: "application/json" });

    const dataURI = `data:application/json;charset=utf-8,${jsonData}`;

    const URL = window.URL || window.webkitURL;
    const downloadURI =
      typeof URL.createObjectURL === "undefined"
        ? dataURI
        : URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", downloadURI);
    link.setAttribute("download", `${filename}.json`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  handleAllDownload = () => {
    const {
      user,
      trackers,
      commitments,
      journal,
      tour,
      timers,
      checklists,
    } = this.state;
    this.processDownload(
      JSON.stringify({
        user,
        trackers,
        journal,
        commitments,
        tour,
        timers,
        checklists,
      }),
      "formigio-archive"
    );
    this.setState({
      user: null,
      trackers: [],
      journal: [],
      commitments: [],
      timers: [],
      checklists: [],
      tour: null,
    });
  };

  handleAllBackup = () => {
    const {
      user,
      trackers,
      commitments,
      journal,
      tour,
      timers,
      checklists,
    } = this.state;
    this.processBackup(
      JSON.stringify({
        user,
        trackers,
        journal,
        commitments,
        tour,
        timers,
        checklists,
      })
    );
    this.setState({
      user: null,
      trackers: [],
      journal: [],
      commitments: [],
      timers: [],
      checklists: [],
      tour: null,
    });
  };

  handleFileSelect = () => {
    this.setState({ fileImport: true });
  };

  handleFileRead = (e) => {
    const importData = JSON.parse(e.target.result);
    console.log(importData);
    this.setState({
      imported: {
        user: importData.user || null,
        trackers: Array.isArray(importData.trackers) ? importData.trackers : [],
        journal: Array.isArray(importData.journal) ? importData.journal : [],
        commitments: Array.isArray(importData.commitments)
          ? importData.commitments
          : [],
        timers: Array.isArray(importData.timers) ? importData.timers : [],
        checklists: Array.isArray(importData.checklists)
          ? importData.checklists
          : [],
        tour: importData.tour || null,
      },
    });
  };

  handleFile = (e) => {
    const fileReader = new FileReader();
    fileReader.onload = this.handleFileRead;
    fileReader.readAsText(e.target.files[0]);
  };

  handleImport = async () => {
    const {
      user: userProp,
      loadUser,
      loadTrackers,
      loadJournal,
      loadCommitments,
      loadTour,
      loadTimers,
      loadChecklists,
      addNotification,
    } = this.props;
    const { imported } = this.state;
    const {
      user,
      journal,
      commitments,
      trackers,
      tour,
      timers,
      checklists,
    } = imported;
    const migratedTrackers = migrateTrackers(trackers, userProp);
    const migratedCommitments = migrateCommitments(commitments, userProp);
    const migratedJournal = migrateJournalEntries(journal, userProp);
    const migratedChecklists = migrateChecklists(checklists, userProp);
    const migratedTimers = migrateTimers(timers, userProp);
    loadUser(Object.assign({}, userProp, { notes: user.notes }));
    loadTour(tour);
    loadTrackers(migratedTrackers);
    loadJournal(migratedJournal);
    loadCommitments(migratedCommitments);
    loadTimers(migratedTimers);
    loadChecklists(migratedChecklists);
    addNotification(
      getSystemNotificationStruct({
        message: "Your data has been imported.",
        type: "snackbar",
      })
    );
    sync();
    this.handleImportCancel();
  };

  handleImportCancel = () => {
    this.setState({
      fileImport: false,
      imported: {
        user: null,
        tour: null,
        trackers: [],
        journal: [],
        commitments: [],
        timers: [],
        checklists: [],
      },
    });
  };

  handleArchiveCancel = () => {
    this.setState({
      user: null,
      tour: null,
      trackers: [],
      journal: [],
      commitments: [],
      timers: [],
      checklists: [],
    });
  };

  handleBackupProfileSave = () => {
    this.deferredCommands.push(() => {
      this.validateBackupProfile()
        .then(() => this.setState({ saving: false }))
        .catch((err) => {
          console.log(err);
          this.setState({ saving: false });
        });
    });
    this.setState({ saving: true });
  };

  handleBackupConfigChange = (event) => {
    const { localBackupProfile } = this.state;
    localBackupProfile.config[event.target.name] = event.target.value;
    this.setState({ localBackupProfile });
  };

  handleBackupProfileChange = (event) => {
    const { localBackupProfile } = this.state;
    localBackupProfile[event.target.name] = event.target.value;
    this.setState({ localBackupProfile });
  };

  handleAutoSyncToggle = () => {
    const { localBackupProfile } = this.state;
    localBackupProfile.autoSyncEnabled = !localBackupProfile.autoSyncEnabled;
    this.setState({ localBackupProfile });
  };

  handleTriggerFullSync = () => {
    const { triggerFullSync } = this.props;
    triggerFullSync();
  };

  handleClearData = () => {
    const { clearData, history } = this.props;
    clearData();
    history.push(ROUTES.LOGIN);
  };

  handleClearDataConfirm = () => {
    this.setState({ clearDataConfirm: true });
  };

  handleClearDataCancel = () => {
    this.setState({ clearDataConfirm: false });
  };

  handleTourReset = () => {
    const { resetTours, addNotification } = this.props;
    resetTours(getUserToursStruct({ updatedAt: moment().format() }));
    addNotification(
      getSystemNotificationStruct({
        message:
          "The Tours have been reset. You can now re-visit all the Tours throughout the application.",
        type: "dialog",
      })
    );
  };

  handleCreateTrainingTracker = () => {
    const { createTrackerFromTemplate } = this.props;
    createTrackerFromTemplate('trainingTracker');
  };
  
  handleErrorTest = () => {
    this.setState({ testJSON: "BAD-STRING" });
  };

  handleDataTest = () => {
    const { triggerError } = this.props;
    triggerError();
  };

  render() {
    const {
      user,
      trackers,
      commitments,
      journal,
      timers,
      checklists,
      fileImport,
      imported,
      message,
      backups,
      saving,
      localBackupProfile,
      clearDataConfirm,
      testJSON
    } = this.state;
    const { backupProfile, classes } = this.props;
    const { config: backupConfig } = localBackupProfile;

    return (
      <div className={classes.contentContainer}>
        <div style={{ flex: "1 1 auto" }}>
          <div>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Data Import/Export</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Card style={{ width: "100%" }}>
                  <CardHeader
                    title="Application Data"
                    subheader="Export and Import Data"
                  />
                  <CardContent>
                    <List>
                      <Divider />
                      <ListItem button onClick={this.handleFileSelect}>
                        <ListItemText primary="Import From Archive" />
                        {fileImport ? (
                          <List>
                            <ListItem>
                              <Input type="file" onChange={this.handleFile} />
                            </ListItem>
                          </List>
                        ) : null}
                      </ListItem>
                      <Divider />
                      <ListItem button onClick={this.handleClearDataConfirm}>
                        <ListItemText primary="Clear all data" />
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <div>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Diagnostics</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Card>
                  <CardHeader
                    title="Run Application Diagnostics"
                    subheader="Error Management"
                  />
                  <CardContent>
                    <List>
                      <ListItem button onClick={this.handleErrorTest}>
                        <ListItemText primary="Test Application Error" />
                      </ListItem>
                      <ListItem button onClick={this.handleDataTest}>
                        <ListItemText primary="Test Data Flow Error" />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`Good/bad JSON: ${JSON.stringify(
                            JSON.parse(testJSON)
                          )}`}
                        />
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <div>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Backup/Sync Settings</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Card>
                  <CardHeader
                    title="Backup/Sync Profile"
                    subheader="Backup and Sync data"
                  />
                  <CardContent>
                    <FormControl>
                      <InputLabel id="type-label">
                        Data Sync Configuration
                      </InputLabel>
                      <Select
                        id="type"
                        name="type"
                        value={localBackupProfile.type}
                        onChange={this.handleBackupProfileChange}
                      >
                        <MenuItem value="">None</MenuItem>
                        <MenuItem value="appSync">Formigio DataSync</MenuItem>
                        <MenuItem value="s3">
                          Amazon S3 (Requires AWS Account)
                        </MenuItem>
                      </Select>
                      <FormHelperText>
                        Use Formigio DataSync if you are using the Mobile Apps
                      </FormHelperText>
                    </FormControl>

                    {localBackupProfile.type === "s3" ? (
                      <React.Fragment>
                        <TextField
                          id="configAccessKey"
                          key="configAccessKey"
                          name="accessKey"
                          label="AWS Access Key"
                          onChange={this.handleBackupConfigChange}
                          value={backupConfig.accessKey}
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          id="configSecretKey"
                          key="configSecretKey"
                          type="password"
                          name="secretKey"
                          label="AWS Secret Key"
                          onChange={this.handleBackupConfigChange}
                          value={backupConfig.secretKey}
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          id="configBucket"
                          key="configBucket"
                          name="bucket"
                          label="S3 Bucket Name"
                          onChange={this.handleBackupConfigChange}
                          value={backupConfig.bucket}
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          id="configLocation"
                          key="configLocation"
                          name="location"
                          label="Location/Path"
                          onChange={this.handleBackupConfigChange}
                          value={backupConfig.location}
                          helperText="The folder path that will be used as the storage root. This will be the bucket root if not defined."
                          fullWidth
                          margin="normal"
                        />
                      </React.Fragment>
                    ) : null}

                    <TextField
                      id="verifiedAt"
                      key="verifiedAt"
                      label="Profile Verified"
                      value={backupProfile.verifiedAt}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            {backupProfile.verifiedAt ? (
                              <CheckCircleIcon />
                            ) : (
                              <React.Fragment />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      margin="normal"
                    />

                    {localBackupProfile.type ? (
                      <FormControlLabel
                        onClick={this.handleAutoSyncToggle}
                        control={
                          <Switch
                            checked={localBackupProfile.autoSyncEnabled}
                          />
                        }
                        label={
                          localBackupProfile.autoSyncEnabled
                            ? "Auto Sync - Enabled"
                            : "Auto Sync - Disabled"
                        }
                      />
                    ) : null}
                    <div className={classes.buttonWrap}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={saving}
                        onClick={this.handleBackupProfileSave}
                      >
                        Save Profile
                      </Button>
                      <Button
                        color="primary"
                        onClick={this.handleTriggerFullSync}
                      >
                        Trigger Full Sync
                      </Button>
                    </div>
                    <Fade in={Boolean(message)}>
                      <Chip
                        avatar={
                          <Avatar>
                            <ErrorIcon />
                          </Avatar>
                        }
                        label={message}
                      />
                    </Fade>
                  </CardContent>
                </Card>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>

          {localBackupProfile.type === "s3" ? (
            <div>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Backups</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Card>
                    <CardHeader
                      title="Backups"
                      subheader="Manage backed up data in S3"
                    />
                    <CardContent>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleBackupFetch}
                      >
                        Fetch Backups from S3
                      </Button>
                      <List>
                        {backups.length
                          ? backups.map((backup) => (
                              <ListItem key={backup.Key}>
                                <ListItemText primary={backup.Key} />
                                <ListItemSecondaryAction>
                                  <IconButton
                                    onClick={this.handleBackupDownload(
                                      backup.Key
                                    )}
                                  >
                                    <CloudDownloadIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={this.handleBackupDelete(
                                      backup.Key
                                    )}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            ))
                          : null}
                      </List>
                    </CardContent>
                  </Card>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          ) : null}

          <div>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Tours</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Card>
                  <CardHeader
                    title="Tours & Help Content"
                    subheader="Manage your help settings"
                  />
                  <CardContent>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleTourReset}
                    >
                      Reset All Tours
                    </Button>
                  </CardContent>
                  <CardContent>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleCreateTrainingTracker}
                    >
                      Create Training Tracker
                    </Button>
                  </CardContent>
                </Card>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>

          <div>
            <EmailPreferencesPanel/>
          </div>

          <div>
            <SMSPreferencesPanel/>
          </div>

          <Dialog
            open={Boolean(
              imported.commitments.length ||
                imported.trackers.length ||
                imported.journal.length ||
                imported.timers.length ||
                imported.checklists.length
            )}
          >
            <DialogTitle>Process Import</DialogTitle>
            <DialogContent>
              <List>
                <ListItem>
                  <ListItemText
                    primary={`Trackers found: ${imported.trackers.length}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={`Commitments found: ${imported.commitments.length}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={`Journal Entries found: ${imported.journal.length}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={`Timers found: ${imported.timers.length}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={`Checklists found: ${imported.checklists.length}`}
                  />
                </ListItem>
              </List>
              <Typography>
                WARNING: Importing an archive will replace all the data in the
                app. Please make sure you have downloaded an archive prior to
                importing.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleImportCancel} color="secondary">
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={this.handleImport}
              >
                Process Import
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={Boolean(
              user && commitments && journal && trackers && timers && checklists
            )}
          >
            <DialogTitle>Archive Data</DialogTitle>
            <DialogContent>
              You can download the Archive or Push the Archive to S3 as a
              backup. These S3 backups can be imported back in from the Backups
              list.
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleArchiveCancel} color="secondary">
                Cancel
              </Button>
              <Button
                color="primary"
                variant="outlined"
                onClick={this.handleAllDownload}
              >
                Download Archive
              </Button>

              {localBackupProfile.type === "s3" ? (
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={this.handleAllBackup}
                >
                  Backup Archive
                </Button>
              ) : null}
            </DialogActions>
          </Dialog>
          <Dialog open={Boolean(clearDataConfirm)}>
            <DialogTitle>Clear All Data</DialogTitle>
            <DialogContent>
              WARNING: This is a destructive action. If you don't have data
              syncing turned on all data will be lost. You will also be logged
              out of the app, and will need to login again.
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClearDataCancel} color="secondary">
                Cancel
              </Button>
              <Button
                color="primary"
                variant="outlined"
                onClick={this.handleClearData}
              >
                Clear All Data
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div className={classes.heading}>
          <img src={logo} alt="" className={classes.logo} />
        </div>
      </div>
    );
  }
}

Settings.defaultProps = {
  backupProfile: getBackupProfileStruct(),
};

Settings.propTypes = {
  user: PropType.any.isRequired,
  loadUser: PropType.func.isRequired,
  addNotification: PropType.func.isRequired,
  setProcessingMessage: PropType.func.isRequired,
  loadTour: PropType.func.isRequired,
  loadTrackers: PropType.func.isRequired,
  loadJournal: PropType.func.isRequired,
  loadTimers: PropType.func.isRequired,
  loadChecklists: PropType.func.isRequired,
  loadCommitments: PropType.func.isRequired,
  setBackupProfile: PropType.func.isRequired,
  setInternetStatus: PropType.func.isRequired,
  resetTours: PropType.func.isRequired,
  triggerFullSync: PropType.func.isRequired,
  clearData: PropType.func.isRequired,
  triggerError: PropType.func.isRequired,
  createTrackerFromTemplate: PropType.func.isRequired,
  history: PropType.any.isRequired,
  backupProfile: PropType.any,
  classes: PropType.object.isRequired,
};

const FormigioSettings = withStyles(Style)(Settings);

export default FormigioSettings;
