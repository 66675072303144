import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import {acceptCommitInviteAction, declineCommitInviteAction} from "../../redux/actions/commitInvite";
import UserIcon from "@material-ui/icons/AccountCircle";

const RespondDialog = ({ invite, onReset }) => {
  const dispatch = useDispatch();
  const [notes, setNotes] = useState('');
  const [decline, setDecline] = useState(false);

  const handleDecline = () => {
    setDecline(true);
  };

  const handleDeclineConfirm = () => {
    dispatch(declineCommitInviteAction(invite));
    onReset();
  };

  const handleAccept = () => {
    dispatch(acceptCommitInviteAction(invite));
    onReset();
  };

  return (
    <Dialog open={!!invite.id} fullWidth>
      <DialogTitle>
        Respond to Invite
        <IconButton
          style={{ position: 'absolute', right: 0, top: 0 }}
          onClick={onReset}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>{<Chip avatar={<UserIcon fontSize={"small"} />} variant={"outlined"} label={invite.fromUserName}/>} has asked you to help with {invite.contextDescription}.</Typography>

        <hr/>

        <Typography>Description: {invite.description}</Typography>

        {invite.notes ? <Typography>Notes: {invite.notes}</Typography> : null}

        <hr/>

        {decline ? (
          <>
            <Typography>Add a reason or just some notes</Typography>
            <TextField
              fullWidth
              autoFocus
              label="Reason or notes"
              value={notes}
              onChange={event => setNotes(event.target.value)}
            />
          </>
        ) : (
          <>
            <Typography>Will you accept or decline the invite?</Typography>
            <Typography variant={"caption"}>If you accept, we will help you create a commitment.</Typography><br/>
            <Typography variant={"caption"}>If you decline, you can add a note to send to them.</Typography>
          </>
        )}

      </DialogContent>
      <DialogActions>
        {decline ? (
          <>
            <Button
              onClick={() => setDecline(false)}
              variant={"outlined"}
              style={{ marginRight: 'auto' }}
            >
              Cancel
            </Button>
            <Button
              color={"primary"}
              variant={"contained"}
              onClick={handleDeclineConfirm}
            >
              Confirm
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={handleDecline}
              variant={"outlined"}
              style={{ marginRight: 'auto' }}
            >
              Decline
            </Button>
            <Button
              color={"primary"}
              variant={"contained"}
              onClick={handleAccept}
            >
              Accept
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

RespondDialog.defaultProps = {};

RespondDialog.propTypes = {
  invite: PropTypes.object.isRequired
};

export default RespondDialog;
