import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField/TextField';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { getMilestoneStruct } from '../../../structs/trackers';
import CalendarCarousel from "../../Ui/CalendarCarousel";
import {getToday} from "../../../utils/date";

const style = () => ({
  closePopUp: {
    position: 'absolute',
    right: '10px',
    top: '20px',
    color: '#303030',
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    background: 'transparent',
    boxShadow: 'none'
  },
  popupHeader: {
    height: 17,
    backgroundColor: '#fd7a6c'
  }
});

class Detail extends Component {
  state = {
    milestone: getMilestoneStruct(),
    targetDate: getToday(true)
  };

  componentDidUpdate() {
    const { milestone: activeMilestone } = this.props;
    const { milestone } = this.state;

    if (
      activeMilestone &&
      (milestone.id !== activeMilestone.id ||
        milestone.updatedAt !== activeMilestone.updatedAt)
    ) {
      this.hydrateMilestoneState(activeMilestone);
    }
  }

  hydrateMilestoneState = activeMilestone => {
    this.setState({
      milestone: getMilestoneStruct(activeMilestone)
    });
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  handleMilestoneChange = event => {
    const { milestone } = this.state;
    milestone[event.target.name] = event.target.value;
    this.setState({ milestone });
    this.deferMilestoneSave();
  };
  
  handleDateSelect = date => {
    const { milestone } = this.state;
    milestone.targetDate = date;
    this.setState({ milestone, targetDate: date });
    this.deferMilestoneSave();
  }

  deferMilestoneSave = debounce(() => {
    const { updateMilestone } = this.props;
    const { milestone } = this.state;
    updateMilestone(milestone);
  }, 2000);

  render() {
    const { classes, open } = this.props;
    const { milestone, targetDate } = this.state;

    return (
      <div>
        <Dialog
          fullScreen
          style={{
            margin: 10,
            marginLeft: 10,
            marginTop: 27,
            height: '90vh'
          }}
          open={open}
          onClose={this.handleClose}
          aria-labelledby="scroll-dialog-title"
        >
          <div className={classes.popupHeader} />
          <Button
            variant="text"
            className={classes.closePopUp}
            onClick={this.handleClose}
          >
            X
          </Button>
          <DialogContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch'
            }}
          >
            <div style={{ paddingTop: '1em' }}>
              <Typography gutterBottom color="primary" variant="h5">
                Milestone
              </Typography>
              <div>
                <Typography
                  className={classes.itemTitle}
                  gutterBottom
                  variant="h5"
                >
                  {milestone.description}
                </Typography>
              </div>
            </div>
  
            { /* todo: Add active items? */ }
  
            <Card elevation={0} style={{ border: '1px lightgray solid' }}>
              <CardHeader
                  title="Target date"
                  titleTypographyProps={{
                    style: { fontSize: '1em', color: 'gray' }
                  }}
              />
              <CardContent>
                <CalendarCarousel
                    onSelect={this.handleDateSelect}
                    selectedDate={milestone.targetDate ? new Date(milestone.targetDate) : targetDate}
                />
              </CardContent>
            </Card>
            <Card elevation={0} style={{ border: '1px lightgray solid' }}>
              <CardHeader
                title="Milestone Notes"
                titleTypographyProps={{
                  style: { fontSize: '1em', color: 'gray' }
                }}
              />
              <CardContent>
                <TextField
                  key={`${milestone.id}_notes`}
                  multiline
                  rows="6"
                  InputProps={{
                    disableUnderline: true
                  }}
                  placeholder="Enter some notes"
                  name="notes"
                  onChange={this.handleMilestoneChange}
                  value={milestone.notes === ' ' ? '' : milestone.notes}
                  style={{ width: '100%' }}
                />
              </CardContent>
            </Card>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

Detail.defaultProps = {
  open: false
};

Detail.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  updateMilestone: PropTypes.func.isRequired,
  milestone: PropTypes.any.isRequired
};
export default withStyles(style)(Detail);
