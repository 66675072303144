import React from 'react';
import PropTypes from 'prop-types';
import ForwardIcon from '@material-ui/icons/Forward';
import HoverButton from '../Ui/HoverButton';
import routes from '../../constants/routes';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';

const ReviewNextButton = ({ tracker }) => {
  const history = useHistory();
  const trackerIndex = useSelector(store => store.trackerIndex);

  const { today: trackersToReview } = trackerIndex;
  const nextTrackers = trackersToReview
      .filter(({ entity: t}) => t.id !== tracker.id)
      .map(({ entity }) => entity);

  const navigateToTracker = tracker => {
    history.push(
      `${routes.TRACKER_BASE}${tracker.id}`
    );
  };
  
  return (
    <React.Fragment>
      {nextTrackers.length ? (
        <HoverButton
          size="small"
          variant="text"
          fullWidth
          onClick={() => navigateToTracker(nextTrackers[0])}
          endIcon={<ForwardIcon />}
          hoverContent={nextTrackers[0].dream}
        >
          Review Next Tracker
        </HoverButton>
      ) : (
        <HoverButton
          size="small"
          variant="text"
          fullWidth
          onClick={() => history.push(routes.HOME)}
          endIcon={<ForwardIcon />}
          hoverContent={'Go Home'}
        >
          Reviews Done
        </HoverButton>
      )}

    </React.Fragment>
  );
};

ReviewNextButton.defaultProps = {};

ReviewNextButton.propTypes = {
  tracker: PropTypes.any.isRequired
};

export default ReviewNextButton;
