import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import routes from '../../constants/routes.json';
import Style from './Style';
import {
  USER_STATUS_FORGOT,
  USER_STATUS_LOGIN_REQUIRED
} from '../../structs/user';

class ResetPassword extends Component {
  state = {
    username: '',
    verificationCode: '',
    password: ''
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleForgotPassword = () => {
    const { forgotPassword } = this.props;
    const { username, verificationCode, password } = this.state;
    const userCredentials = {
      username,
      verificationCode,
      password
    };

    forgotPassword(userCredentials);
  };

  handleRouteToLogin = () => {
    const { history } = this.props;
    history.push(routes.LOGIN);
  };

  render() {
    const { classes, user } = this.props;
    const { username, verificationCode, password } = this.state;
  
    const usernameValid = username.length > 4;
    
    if (user.status === USER_STATUS_LOGIN_REQUIRED) {
      return <Redirect to={routes.LOGIN} />;
    }

    const greeting = <div className={classes.heading}>Forgot Password</div>;

    const waitAction = (
      <Typography variant="h5" color="secondary">
        Loading ...
      </Typography>
    );

    const loginAction = (
      <div className={classes.wrapper}>
        <div className={classes.textLabel}>
          {' '}
          All good, passwords are the worst. :){' '}
        </div>
        {user.status === USER_STATUS_FORGOT && (
          <div className={classes.textLabel}>
            {' '}
            Please check your email for the code to reset your password.{' '}
          </div>
        )}
        <div>
          <TextField
            id="standard-name"
            label="Username"
            error={username && !usernameValid}
            autoFocus
            fullWidth={true}
            disabled={user.status === USER_STATUS_FORGOT}
            className={classes.textField}
            InputProps={{
              className: classes.input
            }}
            value={username}
            onChange={this.handleChange('username')}
            margin="normal"
            helperText={
              username && usernameValid
                ? ''
                : 'Username needs to be at east 5 characters'
            }
          />
        </div>
        {user.status === USER_STATUS_FORGOT && (
          <div>
            <TextField
              id="verificationCode"
              label="Email Code"
              className={classes.textField}
              InputProps={{
                className: classes.input
              }}
              value={verificationCode}
              onChange={this.handleChange('verificationCode')}
              margin="normal"
            />
          </div>
        )}
        <div>
          {user.status === USER_STATUS_FORGOT && (
            <TextField
              id="password"
              label="New Password"
              type="password"
              className={classes.textField}
              InputProps={{
                className: classes.input
              }}
              value={password}
              onChange={this.handleChange('password')}
              margin="normal"
            />
          )}
        </div>
        <div style={{ marginTop: 20 }}>
          <Fade in>
            <Button
              variant="contained"
              disabled={!usernameValid}
              fullWidth={true}
              color="primary"
              onClick={this.handleForgotPassword}
            >
              Submit
            </Button>
          </Fade>
        </div>
        <div style={{ marginTop: 20 }}>
          <Fade in>
            <Button
              variant="text"
              fullWidth={true}
              color="primary"
              onClick={this.handleRouteToLogin}
            >
              Return to Sign In
            </Button>
          </Fade>
        </div>
      </div>
    );

    return (
      <Grid item data-tid="container" xs={12} sm={6} md={6}>
        <Fade in>
          <Typography variant="h5" color="secondary">
            {greeting}
          </Typography>
        </Fade>
        {this.state.user ? waitAction : loginAction}
      </Grid>
    );
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default withStyles(Style)(ResetPassword);
