import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Style from './Style';
import TimeSelectorHelper from '../Ui/TimeSelectorHelper';
import TimeOfDayHelper from '../Ui/TimeOfDayHelper';

class CommitmentTimeSelect extends Component {
  handleSetTime = minutes => {
    this.persistChange(minutes);
  };

  handleSetDate = date => {
    this.persistDateChange(date);
  };

  persistChange = debounce(minutes => {
    const { saveCommitment, setActiveCommitment, commitment } = this.props;
    commitment.timeCommitment = minutes;
    setActiveCommitment(commitment);
    saveCommitment(commitment);
  }, 650);

  persistDateChange = debounce(date => {
    const { saveCommitment, setActiveCommitment, commitment } = this.props;
    commitment.date = moment(date).format();
    setActiveCommitment(commitment);
    saveCommitment(commitment);
  }, 650);

  render() {
    const { commitment } = this.props;
    const date =
      commitment.date.length > 0 ? new Date(commitment.date) : new Date();

    return (
      <React.Fragment>
        <TimeOfDayHelper date={date} onSelect={this.handleSetDate} />
        <TimeSelectorHelper
          minutes={commitment.timeCommitment}
          onSelect={this.handleSetTime}
        />
      </React.Fragment>
    );
  }
}

CommitmentTimeSelect.defaultProps = {
  commitment: null
};

CommitmentTimeSelect.propTypes = {
  commitment: PropTypes.any,
  saveCommitment: PropTypes.func.isRequired,
  setActiveCommitment: PropTypes.func.isRequired
};

export default withStyles(Style)(CommitmentTimeSelect);
