// Backup Profile
export const SET_BACKUP_PROFILE = 'SET_BACKUP_PROFILE';
export const LOAD_BACKUP_PROFILE_FROM_DISK = 'LOAD_BACKUP_PROFILE_FROM_DISK';
export const BACKUP_PROFILE_UPDATE_REQUEST_ACTION =
  'BACKUP_PROFILE_UPDATE_REQUEST';
export const BACKUP_PROFILE_UPDATE_SUCCESS_ACTION =
  'BACKUP_PROFILE_UPDATE_SUCCESS';
export const BACKUP_PROFILE_DATA_LOAD_REQUEST_ACTION =
  'BACKUP_PROFILE_DATA_LOAD_REQUEST';
export const BACKUP_PROFILE_DATA_LOAD_SUCCESS_ACTION =
  'BACKUP_PROFILE_DATA_LOAD_SUCCESS';

export const setBackupProfileAction = profile => ({
  type: BACKUP_PROFILE_UPDATE_REQUEST_ACTION,
  payload: profile
});

export const loadBackupProfileFromDiskAction = profile => ({
  type: BACKUP_PROFILE_DATA_LOAD_REQUEST_ACTION,
  payload: profile
});
