
export const COMMIT_INVITE_STATUS_PENDING = 'pending';
export const COMMIT_INVITE_STATUS_ACCEPTED = 'accepted';
export const COMMIT_INVITE_STATUS_DECLINED = 'declined';

export const CommitInviteStruct = {
  schemaVersion: '1.0.0',
  id: '',
  status: COMMIT_INVITE_STATUS_PENDING,
  description: '',
  fromUserName: '',
  fromUserApn: '',
  toUserName: '',
  toUserApn: '',
  contextApn: '',
  contextDescription: '',
  trackerId: '',
  notes: ''
};

export const getCommitInviteStruct = (invite = CommitInviteStruct) => ({...CommitInviteStruct, ...invite});
