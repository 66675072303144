import React, { Component } from 'react';
import { connect } from 'react-redux';
import SimpleItem from '../../components/Commitment/SimpleItem';
import { saveCommitmentAction } from '../../redux/actions/commitments';
import { requestActiveCommitmentAction } from '../../redux/actions/activeCommitment';

export class SimpleItemContainer extends Component {
  render() {
    return <SimpleItem />;
  }
}

const mapDispatchToProps = dispatch => ({
  saveCommitment: commitment => {
    dispatch(saveCommitmentAction(commitment));
  },
  setActiveCommitment: commitment => {
    dispatch(requestActiveCommitmentAction(commitment));
  }
});

export default connect(null, mapDispatchToProps)(SimpleItem);
