import React, { Component } from 'react';
import {v4 as uuidv4} from "uuid";
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Style from '../../Style';
import ActionItemItem from './Item';
import {getActionItemStruct, getMilestoneStruct} from '../../../../structs/trackers';
import {getActionItemApn, getMilestoneApn} from '../../../../utils/apn/v2';
import {getContextStruct} from "../../../../structs/context";

const reorderSeq = items => {
  let seq = 1;
  return items.map(i => {
    const item = getActionItemStruct(i);
    item.seq = seq;
    seq += 1;
    return item;
  });
};

const reorder = (items, startIndex, endIndex) => {
  const result = Array.from(items);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class ActionItemList extends Component {
  state = { items: [], newItem: getActionItemStruct(), selected: '' };

  componentDidMount() {
    const { milestone } = this.props;
    const { items } = this.state;

    if (milestone.actionItems !== items) {
      this.hydrateState(milestone.actionItems || []);
    }
  }

  componentDidUpdate() {
    const { milestone } = this.props;
    const { items, selected, newItem } = this.state;

    if (milestone.actionItems !== items) {
      this.hydrateState(milestone.actionItems || []);
    }

    if (
      selected === newItem.seq &&
      document.activeElement !== this.newItemField
    ) {
      this.newItemField.focus();
    }
  }

  newItemField;

  hydrateState = items => {
    const { newItem } = this.state;
    newItem.seq = this.getNewItemSequence(items);
    this.setState({ items, newItem });
  };

  handleNewChange = event => {
    const { newItem } = this.state;
    if (event.target.name === 'checked') {
      newItem[event.target.name] = event.target.checked;
    } else {
      if (event.target.value.length === 1 && !event.target.value.trim()) {
        return;
      }
      newItem[event.target.name] = event.target.value;
    }

    this.setState({ newItem });
  };

  handleItemChange = itemToChange => {
    const { items } = this.state;
    const { onChange } = this.props;
    const newItems = items.map(item => {
      if (item.seq === itemToChange.seq) {
        return itemToChange;
      }
      return item;
    });
    this.setState({
      items: newItems
    }, () => {
      // Persist to Tracker onChange
      onChange(newItems);
    });
  };

  handleRemove = itemToRemove => {
    const { items } = this.state;
    const { onChange } = this.props;
    let newItems = items.filter(item => item.apnPart !== itemToRemove.apnPart);
    this.setState({
      items: reorderSeq(newItems)
    }, () => {
      // Persist to Milestone onChange
      onChange(newItems);
    });
  };

  handleAdd = event => {
    if (event.key !== 'Enter') return;

    event.target.blur();
  };

  handleAddBlur = event => {
    const { items, newItem } = this.state;
    const { onChange } = this.props;

    if (!newItem[event.target.name].trim()) {
      this.setState({ selected: '' });
      return;
    }
  
    items.push(getActionItemStruct({
      ...newItem,
      apnPart: uuidv4()
    }));
    const cleanItem = getActionItemStruct({
      seq: this.getNewItemSequence(items)
    });
    this.setState({
      items,
      newItem: cleanItem,
      selected: cleanItem.seq
    }, () => {
      // Persist to Tracker
      onChange(items);
    });

  };

  getNewItemSequence = items => {
    let focusSeq = 1;
    if (items.length)
      items.map(item => {
        focusSeq += 1;
        return item;
      });
    return focusSeq;
  };

  handleAddFocus = () => {
    const { items, newItem } = this.state;
    const selected = this.getNewItemSequence(items);
    newItem.seq = selected;
    this.setState({ selected, newItem });
  };

  handleAddKeydown = event => {
    const { newItem } = this.state;

    // Support Up Arrow Navigation
    if (event.keyCode === 38) {
      this.setState({ selected: newItem.seq - 1 });
    }
  };

  handleItemFocus = id => {
    this.setState({ selected: id });
  };

  handleItemBlur = id => {
    const { selected } = this.state;
    if (selected === id) this.setState({ selected: '' });
  };

  handleListKeys = event => {
    const { selected, items } = this.state;

    // Handle Up Arrow
    if (event.keyCode === 38) {
      if (selected === 1) return;
      this.setState({ selected: selected - 1 });
    }

    // Handle Down Arrow
    if (event.keyCode === 40) {
      if (selected < items.length + 1)
        this.setState({ selected: selected + 1 });
    }
  };

  onDragEnd = result => {
    const { items } = this.state;
    const { onChange } = this.props;

    // dropped outside the list
    if (!result.destination) return;

    let newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    newItems = reorderSeq(newItems);

    this.setState({
      items: newItems
    }, () => {
      // Persist to Tracker
      onChange(newItems);
    });
  };

  render() {
    const { classes, milestone, readOnly, commitmentContext: { tracker } } = this.props;
    const { items, newItem, selected } = this.state;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <List onKeyDown={this.handleListKeys}>
          <Droppable droppableId={`milestone|${milestone.id}`}>
            {provided => (
              <div ref={provided.innerRef}>
                {items.length
                  ? items.map((item, index) => (
                      <Draggable
                        draggableId={item.apnPart}
                        key={item.seq}
                        index={index}
                      >
                        {innerProvided => (
                          <div
                            ref={innerProvided.innerRef}
                            {...innerProvided.draggableProps}
                            {...innerProvided.dragHandleProps}
                          >
                            <ActionItemItem
                              key={item.apnPart}
                              item={item}
                              readOnly={readOnly}
                              onRemove={this.handleRemove}
                              onChange={this.handleItemChange}
                              onInputFocus={this.handleItemFocus}
                              onInputBlur={this.handleItemBlur}
                              selectedItem={selected}
                              commitmentContext={{
                                apn: getActionItemApn({...item, milestoneApn: getMilestoneApn(milestone)}),
                                description: milestone.description,
                                tracker
                              }}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))
                  : null}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {!readOnly && (
            <ListItem
              key="newItem"
              selected={selected === newItem.seq}
              className={classes.listPadding}
            >
              <Checkbox
                checked={false}
                tabIndex={-1}
                disableRipple
                disabled
                style={{ marginLeft: 4 }}
                icon={
                  <CheckBoxOutlineBlankIcon
                    style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                  />
                }
              />
              <TextField
                name="action"
                style={{ width: '90%' }}
                placeholder="Enter a action item"
                onChange={this.handleNewChange}
                onKeyPress={this.handleAdd}
                onBlur={this.handleAddBlur}
                onFocus={this.handleAddFocus}
                onKeyDown={this.handleAddKeydown}
                multiline
                value={newItem.action === ' ' ? '' : newItem.action}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    padding: '3px 0 0'
                  }
                }}
                inputRef={field => {
                  this.newItemField = field;
                }}
              />
            </ListItem>
          )}
        </List>
      </DragDropContext>
    );
  }
}

ActionItemList.defaultProps = {
  milestone: getMilestoneStruct(),
  commitmentContext: getContextStruct()
};

ActionItemList.propTypes = {
  milestone: PropTypes.any,
  commitmentContext: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(Style)(ActionItemList);
