export const BackupProfileStruct = {
  schemaVersion: '1.1.0',
  type: 'appSync', // type: s3, appSync
  updatedAt: '',
  verifiedAt: '',
  autoSyncEnabled: false,
  config: {}
};

export const S3BackupConfigStruct = {
  schemaVersion: '1.0.0',
  accessKey: '',
  secretKey: '',
  bucket: '',
  region: '',
  location: ''
};

export const AppSyncBackupConfigStruct = {
  schemaVersion: '1.0.0'
};

export const SyncMetaDataStruct = {
  schemaVersion: '1.0.0',
  deviceId: '',
  currentVersion: '',
  applicationVersion: '',
  lastSync: ''
};

export const SyncCheckerStruct = {
  opened: false
};

export const SyncEventStruct = {
  schemaVersion: '1.0.0',
  id: '',
  action: '',
  success: '',
  data: {}
};

export const getS3BackupConfigStruct = (config = {}) =>
  Object.assign({}, S3BackupConfigStruct, config);

export const getAppSyncBackupConfigStruct = (config = {}) =>
  Object.assign({}, AppSyncBackupConfigStruct, config);

export const getSyncEventStruct = (event = {}) =>
  Object.assign({}, SyncEventStruct, event);

export const getBackupProfileStruct = (profile = {}) => {
  let config = {};
  if (profile.type === 's3') config = getS3BackupConfigStruct(profile.config);
  if (profile.type === 'appSync')
    config = getAppSyncBackupConfigStruct(profile.config);

  return Object.assign({}, BackupProfileStruct, profile, { config });
};
