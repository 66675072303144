import {
  CHANGE_LOG_ADD,
  CHANGE_LOG_REMOVE,
  CHANGE_LOG_LOAD_ALL
} from '../actions/changeLogs';
import { getChangeLogStruct } from '../../structs/changeLogs';

export default function changeLogReducer(oldstate = [], action) {
  const newChangeLog = getChangeLogStruct(action.payload);

  switch (action.type) {
    case CHANGE_LOG_ADD:
      return [...oldstate, newChangeLog];

    case CHANGE_LOG_REMOVE:
      return oldstate.filter(
        item =>
          !(Array.isArray(action.payload) ? action.payload : [action.payload])
            .map(mapItem => mapItem.changeId)
            .includes(item.changeId)
      );

    case CHANGE_LOG_LOAD_ALL:
      return action.payload;

    default:
      return oldstate;
  }
}
