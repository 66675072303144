import { getSystemNotificationStruct } from '../../structs/notification';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const SET_LOADING = 'SET_LOADING';
export const SET_PROCESSING = 'SET_PROCESSING';
export const SET_INTERNET_STATUS = 'SET_INTERNET_STATUS';

export const ADD_EMAIL_NOTIFICATION_ACTION = 'ADD_EMAIL_NOTIFICATION';
export const EMAIL_NOTIFICATIONS_FETCH_SUCCESS_ACTION = 'EMAIL_NOTIFICATIONS_FETCH_SUCCESS';

export const SAVE_USER_EMAIL_PREFERENCE = 'SAVE_USER_EMAIL_PREFERENCE';
export const FETCH_EMAIL_PREFERENCE = 'FETCH_EMAIL_PREFERENCE';
export const SET_USER_EMAIL_PREFERENCE = 'SET_USER_EMAIL_PREFERENCE';
export const FETCH_EMAIL_PREFERENCE_SUCCESS_ACTION = 'FETCH_EMAIL_PREFERENCE_SUCCESS';
export const INITIALIZE_EMAIL_PREFERENCE_REQUEST_ACTION = 'INITIALIZE_EMAIL_PREFERENCE_REQUEST';
export const INITIALIZE_EMAIL_PREFERENCE_SUCCESS_ACTION = 'INITIALIZE_EMAIL_PREFERENCE_SUCCESS';

export const ADD_SMS_NOTIFICATION_ACTION = 'ADD_SMS_NOTIFICATION';
export const VERIFY_SMS_ACTION = 'VERIFY_SMS';
export const SAVE_USER_SMS_PREFERENCE_ACTION = 'SAVE_USER_SMS_PREFERENCE';
export const FETCH_SMS_PREFERENCE_ACTION = 'FETCH_SMS_PREFERENCE';
export const SET_USER_SMS_PREFERENCE_ACTION = 'SET_USER_SMS_PREFERENCE';
export const SET_USER_SMS_VERIFICATION_CODE_ACTION = 'SET_USER_SMS_VERIFICATION_CODE';
export const FETCH_SMS_PREFERENCE_SUCCESS_ACTION = 'FETCH_SMS_PREFERENCE_SUCCESS';

export const LIST_USER_NOTIFICATION = 'LIST_USER_NOTIFICATION';
export const SET_USER_NOTIFICATIONS = 'SET_USER_NOTIFICATIONS';
export const UPDATE_USER_NOTIFICATION = 'UPDATE_USER_NOTIFICATION';

export const addEmailNotificationAction = (notification) => ({
  type: ADD_EMAIL_NOTIFICATION_ACTION,
  payload: notification,
});

export const listUserStoredNotificationAction = () => ({
  type: LIST_USER_NOTIFICATION,
  payload: null,
});

export const saveUserEmailPreferencesAction = (userPreference) => ({
  type: SAVE_USER_EMAIL_PREFERENCE,
  payload: userPreference,
});

export const saveUserSMSPreferencesAction = (userPreference) => ({
  type: SAVE_USER_SMS_PREFERENCE_ACTION,
  payload: userPreference,
});

export const fetchEmailPreferenceAction = () => ({
  type: FETCH_EMAIL_PREFERENCE
});

export const fetchSMSPreferenceAction = () => ({
  type: FETCH_SMS_PREFERENCE_ACTION
});

export const updateUserNotificationAction = (notificationDetails) => ({
  type: UPDATE_USER_NOTIFICATION,
  payload: notificationDetails,
});

export const setLoadingAction = (message) => ({
  type: SET_LOADING,
  payload: message,
});

export const setProcessingAction = (message) => ({
  type: SET_PROCESSING,
  payload: message,
});

export const addNotificationAction = (notification) => ({
  type: ADD_NOTIFICATION,
  payload: notification,
});

export const removeNotificationAction = (notification) => ({
  type: REMOVE_NOTIFICATION,
  payload: notification,
});

export const setInternetStatusAction = (status) => ({
  type: SET_INTERNET_STATUS,
  payload: status,
});

export const showSuccessAction = (message) =>
  addNotificationAction(
    getSystemNotificationStruct({
      message,
      type: 'snackbar',
      level: 'success',
    })
  );

export const showErrorAction = (message) =>
  addNotificationAction(
    getSystemNotificationStruct({
      message,
      type: 'snackbar',
      level: 'error',
    })
  );
