import {CONTACT_UPSERT_LOCAL_ACTION} from "../actions/contacts";

export default function contactReducer(state = [], action) {
  switch (action.type) {

    case CONTACT_UPSERT_LOCAL_ACTION:
      if (!action.payload) return state;
      if (
        state.filter(item => action.payload.id === item.id)
          .length
      )
        return state.map(item => {
          if (action.payload.id === item.id) {
            return action.payload;
          }
          return item;
        });

      return [...state, action.payload];

    default:
      return state;
  }
}
