import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveUserNotesAction } from '../redux/actions/user';
import Notes from '../components/Home/Notes';

export class UserNotesContainer extends Component {
  render() {
    return <Notes />;
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  saveUserNotes: notes => {
    dispatch(saveUserNotesAction(notes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
