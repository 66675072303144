import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import { getLearnItemPageStruct } from '../../../structs/learn';
import LearnPageSelector from '../../../containers/Learn/PageSelector';
import routes from '../../../constants/routes.json';

export const style = () => ({
  input: {
    color: '#393939',
    flex: 1,
    width: '100%'
  },
  textField: {
    fontSize: 12,
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    width: '100%'
  }
});

class LearnItemPage extends Component {
  state = {
    page: getLearnItemPageStruct(),
    edit: false
  };

  componentDidMount() {
    const { item } = this.props;
    const { page } = this.state;

    if (!item.title) {
      this.deferredCommands.push(() => {
        this.setState({ edit: true });
      });
    }

    if (JSON.stringify(item) !== JSON.stringify(page)) {
      this.hydrateState(item);
    }
  }

  componentDidUpdate() {
    this.checkDeferredCommands();
  }

  // List of commands to run after render.
  deferredCommands = [];

  /*
   *
   * The Deferred Commands model is to allow us to run functions once the
   * state has been set.
   * This is useful when we want to persist data via a redux action, or run
   * multiple steps to record progress.
   *
   */
  checkDeferredCommands = () => {
    if (!this.deferredCommands.length) return;

    this.deferredCommands = this.deferredCommands.filter(command => {
      command();
      return false;
    });
  };

  hydrateState = item => {
    this.setState({ page: getLearnItemPageStruct(item) });
  };

  handleSubmit = () => {
    const { page } = this.state;
    const { onChange } = this.props;
    this.deferredCommands.push(() => {
      onChange(page);
    });
    this.setState({ edit: false });
  };

  handleDelete = () => {
    const { page } = this.state;
    const { onDelete } = this.props;
    onDelete(page);
  };

  handleChange = event => {
    const { page } = this.state;
    page[event.target.name] = event.target.value;
    this.setState({ page });
  };

  handleClick = () => {
    this.setState({ edit: true });
  };

  handleCancel = () => {
    this.setState({ edit: false });
  };

  handleSetPageId = pageId => {
    const { page } = this.state;
    page.pageId = pageId;
    this.setState({ page });
  };

  handleNavigate = () => {
    const { history, item } = this.props;
    history.push(`${routes.LEARN_PAGE_BASE}${item.pageId}`);
  };

  render() {
    const { classes, item } = this.props;
    const { page, edit } = this.state;
    const ready = page.title && page.pageId;

    return (
      <React.Fragment>
        <ListItem button onClick={this.handleClick}>
          <Card
            elevation={0}
            style={{ borderLeft: '3px solid lightgray', marginBottom: 4 }}
          >
            <CardContent style={{ fontSize: '.8rem', whiteSpace: 'pre-wrap' }}>
              <Button onClick={this.handleNavigate}>{item.title}</Button>
            </CardContent>
          </Card>
        </ListItem>
        <Dialog open={edit}>
          <DialogContent>
            <TextField
              multiline
              id="title"
              name="title"
              value={page.title === ' ' ? '' : page.title}
              placeholder="Page Link Title"
              required
              margin="normal"
              className={classes.textField}
              InputProps={{
                className: classes.input
              }}
              onChange={this.handleChange}
            />
            <LearnPageSelector
              selected={page.pageId}
              onChange={this.handleSetPageId}
            />
          </DialogContent>
          <DialogActions>
            <Button
              style={{ marginRight: 'auto' }}
              color="secondary"
              onClick={this.handleDelete}
            >
              Delete
            </Button>
            <Button color="secondary" onClick={this.handleCancel}>
              Cancel
            </Button>
            <Button
              disabled={!ready}
              color="primary"
              variant="contained"
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

LearnItemPage.propTypes = {
  classes: PropTypes.any.isRequired,
  item: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  history: PropTypes.any.isRequired
};

export default withRouter(withStyles(style)(LearnItemPage));
