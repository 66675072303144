export const INTERNET_STATUS_CONNECTED = "Connected";
export const NOTIFICATION_TOPIC_REGISTRATION_CONFIRM = "registration_confirm";
export const NOTIFICATION_TOPIC_SMS_VERIFY = "sms_verify";
export const NOTIFICATION_TOPIC_SUBSCRIPTION_STARTED = "subscription_started";
export const NOTIFICATION_TOPIC_SUBSCRIPTION_CHANGED = "subscription_changed";
export const NOTIFICATION_TOPIC_SUBSCRIPTION_RENEWAL = "subscription_renewal";
export const NOTIFICATION_TOPIC_REVIEW_REMINDER = "review_reminder";
export const NOTIFICATION_TOPIC_TRACKER_SHARE = "tracker_share";
export const NOTIFICATION_TOPIC_COMMITMENT_REMIND = "commitment_remind";
export const NOTIFICATION_TOPIC_COMMITMENT_INVITE = "commitment_invite";

export const SystemNotificationStruct = {
  schemaVersion: "1.0.0",
  id: "",
  message: "",
  type: "snackbar", // dialog, snackbar
  level: "info", // success, error, info, warning
  autoHideDuration: 5000, // Milliseconds (Only applies to snackbars)
};

export const InternetStatusStruct = {
  schemaVersion: "1.0.0",
  status: "",
};

export const EmailNotificationStruct = {
  id: "",
  sendTimestamp: "",
  userApn: "",
  topic: "",
  email: "",
  summaryText: "",
  payload: "",
  new: true,
  processedTimestamp: "",
  entityApn: ""
};

export const SMSNotificationStruct = {
  id: "",
  sendTimestamp: "",
  userApn: "",
  topic: "",
  number: "",
  summaryText: "",
  new: true,
  processedTimestamp: "",
  entityApn: ""
};

export const EmailTopicMapping = {
  [NOTIFICATION_TOPIC_SUBSCRIPTION_STARTED]: 'Subscription Started',
  [NOTIFICATION_TOPIC_SUBSCRIPTION_CHANGED]: 'Subscription Changed',
  [NOTIFICATION_TOPIC_SUBSCRIPTION_RENEWAL]: 'Subscription Renewal',
  [NOTIFICATION_TOPIC_REVIEW_REMINDER]: 'Trackers to Review Reminder',
  [NOTIFICATION_TOPIC_TRACKER_SHARE]: 'Tracker Sharing Notifications',
  [NOTIFICATION_TOPIC_COMMITMENT_INVITE]: 'Commitment Invitations'
};

export const SMSTopicMapping = {
  [NOTIFICATION_TOPIC_REVIEW_REMINDER]: 'Trackers to Review Reminder',
  [NOTIFICATION_TOPIC_COMMITMENT_REMIND]: 'Commitment Reminders'
};

export const getEmailTopicKeys = () => Object.keys(EmailTopicMapping);
export const getSMSTopicKeys = () => Object.keys(SMSTopicMapping);

export const EmailPreferenceStruct = {
  userApn: '',
  emailAddress: '',
  unsubscribedTopics: []
};

export const SMSPreferenceStruct = {
  userApn: '',
  number: '',
  verifiedAt: '',
  unsubscribedTopics: []
};

export const getSystemNotificationStruct = (
  notification = SystemNotificationStruct
) => Object.assign({}, SystemNotificationStruct, notification);

export const getEmailNotificationStruct = (
  notification = EmailNotificationStruct
) =>
  Object.assign(
    {},
    EmailNotificationStruct,
    notification
  );

export const getEmailPreferenceStruct = (
  preferences = EmailPreferenceStruct
) =>
  Object.assign(
    {},
    EmailPreferenceStruct,
    preferences
  );

export const getSMSNotificationStruct = (
  notification = SMSNotificationStruct
) =>
  Object.assign(
    {},
    SMSNotificationStruct,
    notification
  );

export const getSMSPreferenceStruct = (
  preferences = SMSPreferenceStruct
) =>
  Object.assign(
    {},
    SMSPreferenceStruct,
    preferences
  );
