import { v4 as uuidv4 } from 'uuid';
import { getTimerStruct } from '../../structs/timers';

export const ADD_TIMER = 'ADD_TIMER';
export const LOAD_TIMERS_FROM_DISK = 'LOAD_TIMERS_FROM_DISK';
export const DELETE_TIMER = 'DELETE_TIMER';
export const SAVE_TIMER = 'SAVE_TIMER';
export const TIMER_CREATE_REQUEST_ACTION = 'TIMER_CREATE_REQUEST';
export const TIMER_CREATE_SUCCESS_ACTION = 'TIMER_CREATE_SUCCESS';
export const TIMER_DELETE_REQUEST_ACTION = 'TIMER_DELETE_REQUEST';
export const TIMER_DELETE_SUCCESS_ACTION = 'TIMER_DELETE_SUCCESS';
export const TIMER_UPDATE_REQUEST_ACTION = 'TIMER_UPDATE_REQUEST';
export const TIMER_UPDATE_SUCCESS_ACTION = 'TIMER_UPDATE_SUCCESS';
export const TIMER_DATA_LOAD_REQUEST_ACTION = 'TIMER_DATA_LOAD_REQUEST';
export const TIMER_DATA_LOAD_SUCCESS_ACTION = 'TIMER_DATA_LOAD_SUCCESS';

export const addTimerAction = timer => ({
  type: TIMER_CREATE_REQUEST_ACTION,
  payload: Object.assign({}, getTimerStruct(), timer, {
    id: uuidv4()
  })
});

export const loadTimersFromDiskAction = timers => ({
  type: TIMER_DATA_LOAD_REQUEST_ACTION,
  payload: timers
});

export const deleteTimerAction = id => ({
  type: TIMER_DELETE_REQUEST_ACTION,
  payload: id
});

export const saveTimerAction = timer => ({
  type: TIMER_UPDATE_REQUEST_ACTION,
  payload: timer
});
