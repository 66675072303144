import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import routes from '../constants/routes.json';
import {getTrackerShareStruct, TRACKER_SHARE_STATUS_PENDING} from '../structs/trackerShare';
import {fetchRemoteTrackerShare} from '../utils/appSync/trackerShare';
import {useDispatch, useSelector} from 'react-redux';
import {USER_STATUS_LOGGED_IN} from '../structs/user';
import Button from '@material-ui/core/Button';
import {
  acceptTrackerShareAction, declineTrackerShareAction
} from '../redux/actions/trackerShares';

const ShareAcceptPage = () => {
  const { id, trackerId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(store => store.user);
  const [trackerShare, setTrackerShare] = useState(getTrackerShareStruct());
  
  useEffect(() => {
    if (user.status === USER_STATUS_LOGGED_IN){
      fetchRemoteTrackerShare(trackerId, id).then(data => setTrackerShare(data));
    }
  },[id, trackerId, user.status]);
  
  return (
    <Grid item data-tid="container" xs={8} sm={6} md={4} style={{textAlign: "center"}}>
      <div>
        <Fade in>
          <Typography
            variant="h5"
            color="secondary"
            style={{ marginBottom: 15 }}
          >
            Formigio Sharing
          </Typography>
        </Fade>
        {user.status === USER_STATUS_LOGGED_IN ? (
          <div>
            {trackerShare.status !== TRACKER_SHARE_STATUS_PENDING ? (
              <Typography>This share invite is no longer available.</Typography>
            ) : (
              <div>
                <Typography style={{ padding: '1em' }}>
                  <b>{trackerShare.ownerName}</b> has invited you to work together by sharing <b>{trackerShare.shareDescription}</b>.
                </Typography>
                <Typography style={{ padding: '1em' }}>
                  Would like you like to accept the invitation?
                </Typography>
                <Typography variant={"caption"}>
                  By accepting you will be given access to shared information.
                </Typography>
                <br/>
                <Typography variant={"caption"}>
                  <b>{trackerShare.ownerName}</b> will be added to your contacts and you will be added to theirs.
                </Typography>
              </div>
            )}
            
            <div style={{ marginTop: 20 }}>
              <Button
                color="primary"
                fullWidth
                disabled={!trackerShare.id || trackerShare.status !== TRACKER_SHARE_STATUS_PENDING}
                variant="contained"
                onClick={() => dispatch(acceptTrackerShareAction(trackerShare))}
              >
                Accept
              </Button>
            </div>
            <div style={{ marginTop: 20 }}>
              <Button
                color="secondary"
                fullWidth
                disabled={!trackerShare.id || trackerShare.status !== TRACKER_SHARE_STATUS_PENDING}
                variant="text"
                onClick={() => dispatch(declineTrackerShareAction(trackerShare))}
              >
                Reject
              </Button>
            </div>
            {trackerShare.status !== TRACKER_SHARE_STATUS_PENDING ? (
              <div style={{ marginTop: 20 }}>
                <Button color="primary" fullWidth variant="contained" onClick={() => history.push(routes.HOME)}>Home Page</Button>
              </div>
            ) : null}
          </div>
        ) : (
          <div>
            <Typography>Great to have you here. :)</Typography>
            <Typography>You must be logged in to accept a share invite.</Typography>
            <div style={{ marginTop: 20 }}>
              <Button color="primary" fullWidth variant="contained" onClick={() => history.push(routes.LOGIN)}>Login</Button>
            </div>
            <div style={{ marginTop: 20 }}>
              <Button color="secondary" fullWidth variant="text" onClick={() => history.push(routes.REGISTER)}>Sign Up</Button>
            </div>
          </div>
        )}
        
      </div>
    </Grid>
  );
};

export default ShareAcceptPage;
