import {
  REMOVE_LOCAL_TRACKER_SHARE_ACTION,
  SET_LOCAL_TRACKER_SHARE_ACTION, UPSERT_LOCAL_TRACKER_SHARE_ACTION
} from '../actions/trackerShares';

export default function trackerShareReducer(oldstate = [], action) {
  let trackerShares = [];

  switch (action.type) {

    case SET_LOCAL_TRACKER_SHARE_ACTION:
      trackerShares = action.payload;
      return trackerShares;

    case UPSERT_LOCAL_TRACKER_SHARE_ACTION:
      if (!action.payload) return oldstate;
      if (!action.payload.id) return oldstate;
      if (
        oldstate.filter(
          trackerShare => action.payload.id === trackerShare.id && trackerShare.id
        ).length
      )
        return oldstate.map(trackerShare => {
          if (action.payload.id === trackerShare.id) {
            return {
              ...action.payload
            };
          }
          return trackerShare;
        });

      return [...oldstate, action.payload];

    case REMOVE_LOCAL_TRACKER_SHARE_ACTION:
      trackerShares = oldstate.filter(
        existingShare => existingShare.id !== action.payload.id
      );
      return trackerShares;

    default:
      return oldstate;
  }
}
