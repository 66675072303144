import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Style from '../Style';
import { getJournalStruct } from '../../../structs/journal';
import {stringifyApn} from "../../../utils/apn/v2";

class JournalItem extends Component {
  state = {
    item: getJournalStruct()
  };

  componentDidMount() {
    this.hydrateState();
  }

  componentDidUpdate() {
    this.hydrateState();
  }

  hydrateState = () => {
    const { item } = this.state;
    const { item: itemProp } = this.props;
    if (itemProp.id && itemProp.updatedAt !== item.updatedAt) {
      this.setState({ item: getJournalStruct(itemProp) });
    }
  };

  handleChange = event => {
    const { item } = this.state;
    item[event.target.name] = event.target.value;
    this.setState({ item });
  };

  handleSaveClick = () => {
    const { item } = this.state;
    const { saveJournalEntry } = this.props;
    saveJournalEntry(item);
  };

  render() {
    const { item } = this.state;
    const { item: itemProp, classes, user } = this.props;
    const createdBy = item.ownerApn === stringifyApn({userId: user.id})
      ? 'You'
      : item.createdBy;

    return (
      <ListItem key={item.id} dense className={classes.noPadding}>
        <ListItemText
          primary={
            <TextField
              multiline
              value={item.entry}
              name="entry"
              fullWidth
              onChange={this.handleChange}
              InputProps={{
                disableUnderline: true
              }}
            />
          }
          secondary={
            item.updatedAt === item.datetime
              ? `${createdBy} - ${new Date(item.datetime).toLocaleString()}`
              : `${createdBy} - ${new Date(item.datetime).toLocaleString()} (edited ${new Date(
                  item.updatedAt
                ).toLocaleString()})`
          }
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            borderRadius: 7,
            marginTop: '1em',
            padding: '.5em 1em'
          }}
        />
        <Fade in={item.entry !== itemProp.entry}>
          <ListItemSecondaryAction>
            <Button
              size="small"
              variant="outlined"
              onClick={this.handleSaveClick}
            >
              Save
            </Button>
          </ListItemSecondaryAction>
        </Fade>
      </ListItem>
    );
  }
}

JournalItem.propTypes = {
  item: PropTypes.object.isRequired,
  saveJournalEntry: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(Style)(JournalItem);
