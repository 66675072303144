import {getTrackerStruct} from "./trackers";

export const ContextStruct = {
  schemaVersion: '1.0.0',
  apn: '',
  description: '',
  tracker: getTrackerStruct(),
  commitmentDescription: ''
};

export const getContextStruct = (context = {}) =>
  Object.assign({}, ContextStruct, context);
