import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import trackerReducer from './tracker';
import commitmentReducer from './commitment';
import userReducer from './user';
import journalReducer from './journal';
import activeMilestonesReducer from './activeMilestones';
import activeTrackerReducer from './activeTracker';
import activeMilestoneReducer from './activeMilestone';
import backupProfileReducer from './backupProfile';
import tourReducer from './tour';
import syncReducer from './sync';
import commitmentIndexReducer from './commitmentIndex';
import trackerIndexReducer from './trackerIndex';
import searchIndexReducer from './searchIndex';
import timerReducer from './timer';
import activeCommitmentReducer from './activeCommitment';
import journalIndexReducer from './journalIndex';
import checklistReducer from './checklist';
import learnPageReducer from './learnPage';
import checklistIndexReducer from './checklistIndex';
import internetStatusReducer from './internetStatus';
import notificationReducer from './notification';
import loadingReducer from './loading';
import processingReducer from './processing';
import changeLogReducer from './changeLog';
import syncEventReducer from './syncEvent';
import appReducer from './app';
import membershipReducer from './membership';
import milestoneIndexReducer from './milestoneIndex';
import activeContextReducer from './activeContext';
import syncCheckerReducer from './syncChecker';
import userEmailPreferenceReducer from './userEmailPreference';
import userSMSPreferenceReducer from './userSMSPreference';
import trackerShareReducer from './trackerShare';
import trackerActivityReducer from './trackerActivity';
import roleReducer from "./role";
import commitmentTrayReducer from "./commitmentTray";
import contactReducer from "./contact";
import trackerTemplateReducer from "./trackerTemplate";
import commitInviteReducer from "./commitInvite";

const createRootReducer = (history) =>
  combineReducers(
    {
      router: connectRouter(history),
      app: appReducer,
      trackers: trackerReducer,
      trackerIndex: trackerIndexReducer,
      activeContext: activeContextReducer,
      commitments: commitmentReducer,
      commitmentIndex: commitmentIndexReducer,
      activeCommitment: activeCommitmentReducer,
      searchIndex: searchIndexReducer,
      user: userReducer,
      membership: membershipReducer,
      milestoneIndex: milestoneIndexReducer,
      journal: journalReducer,
      journalIndex: journalIndexReducer,
      activeMilestones: activeMilestonesReducer,
      activeMilestone: activeMilestoneReducer,
      activeTracker: activeTrackerReducer,
      backupProfile: backupProfileReducer,
      tour: tourReducer,
      syncVersion: syncReducer,
      timers: timerReducer,
      checklists: checklistReducer,
      learnPages: learnPageReducer,
      checklistIndex: checklistIndexReducer,
      internetStatus: internetStatusReducer,
      notifications: notificationReducer,
      loading: loadingReducer,
      processing: processingReducer,
      changeLogs: changeLogReducer,
      syncEvents: syncEventReducer,
      userEmailPreference: userEmailPreferenceReducer,
      userSMSPreference: userSMSPreferenceReducer,
      syncChecker: syncCheckerReducer,
      trackerShares: trackerShareReducer,
      trackerActivity: trackerActivityReducer,
      roles: roleReducer,
      trays: commitmentTrayReducer,
      contacts: contactReducer,
      trackerTemplates: trackerTemplateReducer,
      commitInvites: commitInviteReducer
    }
  );

export default createRootReducer;
