import React, { Component } from 'react';
import { connect } from 'react-redux';
import Calendar from '../../components/Planner/Calendar';

export class CalendarContainer extends Component {
  render() {
    return <Calendar />;
  }
}

const mapStateToProps = state => ({
  commitmentIndex: state.commitmentIndex
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
