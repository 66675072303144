import moment from 'moment';

import {
  LOAD_BACKUP_PROFILE_FROM_DISK,
  SET_BACKUP_PROFILE
} from '../actions/backupProfile';
import { getBackupProfileStruct } from '../../structs/sync';

export default function backupProfileReducer(
  oldstate = getBackupProfileStruct(),
  action
) {
  let profile;
  switch (action.type) {
    case SET_BACKUP_PROFILE:
      profile = getBackupProfileStruct(action.payload);
      profile.updatedAt = moment().format();
      return profile;

    case LOAD_BACKUP_PROFILE_FROM_DISK:
      return getBackupProfileStruct(action.payload);

    default:
      return oldstate;
  }
}
