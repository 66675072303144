import React, { Component } from 'react';
import PropType from 'prop-types';
import debounce from 'lodash/debounce';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ErrorIcon from '@material-ui/icons/Error';
import SuccessIcon from '@material-ui/icons/Mood';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import { withRouter } from 'react-router';
import { getBackupProfileStruct } from '../../structs/sync';
import routes from '../../constants/routes.json';
import { INTERNET_STATUS_CONNECTED } from '../../structs/notification';
import {API, graphqlOperation} from "aws-amplify";
import {onCreateChangeLogv2Filtered} from "../../graphql/subscriptions";
import {stringifyApn} from "../../utils/apn/v2";

const style = () => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 23,
    width: 'calc(100vw - 68px)'
  },
  syncSwitch: {
    marginLeft: 0,
    color: 'rgba(0, 0, 0, 0.38)'
  },
  sleeper: {
    animationDuration: '20000ms'
  }
});

/*
 *
 * The Device Sync Component is responsible for watching the sync events across
 * the system, and persisting the sync data to the backup location.
 *
 */
class DeviceSync extends Component {
  state = {
    showSyncInfo: false,
    sleeping: false
  };

  componentDidMount() {
    if (window.navigator.onLine) {
      this.handleOnline();
    }
    this.requestSync();
  }

  componentDidUpdate() {
    const { sleeping } = this.state;
    const { backupProfile } = this.props;

    this.checkDeferredCommands();

    // If there is no verified profile,
    // or autoSync is disabled,
    if (!backupProfile.verifiedAt || !backupProfile.autoSyncEnabled) {
      return;
    }

    if (!this.subscribed) {
      // We subscribe to a global window event that is created by sync event
      window.addEventListener('sync', this.requestSync);
      window.addEventListener('activity', this.resetActivityInterval);
      window.addEventListener('mousemove', this.debouncedActivity);
      window.addEventListener('keydown', this.debouncedActivity);
      window.addEventListener('online', this.handleOnline);
      window.addEventListener('offline', this.handleOffline);
      this.subscribeToLiveChanges();
      this.subscribed = true;
    }

    if (sleeping) {
      clearTimeout(this.activityTimeout);
    }
  }

  componentWillUnmount() {
    // We need to remove the event subscription to clean up.
    window.removeEventListener('sync', this.requestSync);
    window.removeEventListener('activity', this.resetActivityInterval);
    window.removeEventListener('mousemove', this.debouncedActivity);
    window.removeEventListener('keydown', this.debouncedActivity);
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
    this.debouncedActivity.cancel();
    clearTimeout(this.activityTimeout);
    this.clearLiveSubscription();
    this.subscribed = false;
  }

  // Local flag for recording the event subscription.
  subscribed = false;

  // List of commands to run after render.
  deferredCommands = [];

  // Inactivity Timeout contains the ID of the current inactivity counter
  activityTimeout;

  // GraphQL Subscriptions
  graphSubscription;

  /*
   *
   * For any user interaction events we reset the activity as well.
   * If the activity happens after the app has gone to sleep then we kick off
   * the backup fetch and check.
   *
   */
  debouncedActivity = debounce(
    () => {
      const { sleeping } = this.state;
      if (sleeping) {
        console.log('Wake Up!');
        this.setState({ sleeping: false });
        this.handleWakeUp();
      }
      this.resetActivityInterval();
    },
    500,
    { leading: false, trailing: true, maxWait: 1000 }
  );

  /*
   *
   * The Deferred Commands model is to allow us to run functions once the
   * state has been set.
   * This is useful when we want to persist data via a redux action, or run
   * multiple steps to record progress.
   *
   */
  checkDeferredCommands = () => {
    if (!this.deferredCommands.length) return;

    this.deferredCommands = this.deferredCommands.filter(command => {
      command();
      return false;
    });
  };

  handleOnline = () => {
    const { setInternetStatus } = this.props;
    setInternetStatus(INTERNET_STATUS_CONNECTED);
  };

  handleOffline = () => {
    const { setInternetStatus } = this.props;
    setInternetStatus('');
  };

  /*
   *
   * Check to see if there is a valid backup profile, autoSync is enabled, and we have internet connection.
   *
   */
  isSyncEnabled = () => {
    const { backupProfile, internetStatus } = this.props;
    return (
      backupProfile.verifiedAt &&
      backupProfile.autoSyncEnabled &&
      internetStatus.status === 'Connected'
    );
  };

  /*
   *
   * Set Sync Requested to communicate that there is work to do.
   *
   */
  requestSync = () => {
    const { syncChangeLogs, syncEvents } = this.props;
    syncChangeLogs();
    syncEvents();
  };

  handleWakeUp = () => {
    const { handleWakeUp } = this.props;
    this.subscribeToLiveChanges();
    handleWakeUp();
  };

  subscribeToLiveChanges = () => {
    const { user: { id: userId }, processChangeLogAction} = this.props;

    if (!this.isSyncEnabled()) return;

    // Subscribe to creation of changeLogs
    this.graphSubscription = API.graphql(
      graphqlOperation(onCreateChangeLogv2Filtered, { ownerApn: stringifyApn({ userId })})
    ).subscribe({
      next: ({ value: { data: {onCreateChangeLogv2Filtered: changeLog }}}) => {
        // console.log(changeLog);
        processChangeLogAction(changeLog);
      },
      error: (error) => console.warn(error)
    });
  };

  clearLiveSubscription = () => {
    // Stop receiving data updates from the subscription
    if (this.graphSubscription) this.graphSubscription.unsubscribe();
  };

  /*
   *
   * When the user is inactive we want to fetch the backups periodically, to make
   * sure that when the user comes back, they have the latest.
   *
   */
  resetActivityInterval = () => {
    if (this.activityTimeout) {
      clearTimeout(this.activityTimeout);
    }
    this.activityTimeout = setTimeout(() => {
      console.log('Going to Sleep...');
      this.setState({ sleeping: true }, () => {
        this.clearLiveSubscription();
      });
    }, 30000);
  };

  /*
   *
   * Hide and show the Sync Info modal
   *
   */
  toggleSyncInfo = () => {
    const { showSyncInfo } = this.state;
    this.setState({ showSyncInfo: !showSyncInfo });
  };

  navigateToSettings = () => {
    const { history } = this.props;
    this.toggleSyncInfo();
    history.push(routes.SETTINGS);
  };

  navigateToHelp = () => {
    const { history } = this.props;
    this.toggleSyncInfo();
    history.push(routes.HELP);
  };

  render() {
    const { showSyncInfo } = this.state;
    const { classes, internetStatus, backupProfile, syncVersion } = this.props;
    const canSync = !!this.isSyncEnabled();

    return (
      <div>
        <FormControlLabel
          onClick={!canSync ? this.toggleSyncInfo : null}
          className={classes.syncSwitch}
          control={<Switch disabled checked={canSync} />}
          label={
            canSync && syncVersion.lastSync
              ? `Saved ${moment(syncVersion.lastSync).fromNow()}`
              : 'Sync Off'
          }
        />

        <Dialog open={Boolean(showSyncInfo)}>
          <DialogTitle>Syncing Is Disabled</DialogTitle>
          <DialogContent>
            Looks like syncing is disabled.
            <br />
            <br />
            There are 3 reasons this could be:
            <br />
            1. No Internet connectivity
            <br />
            {internetStatus.status === 'Connected' ? (
              <Chip
                color="secondary"
                label="Connected, looks good.."
                variant="outlined"
                avatar={
                  <Avatar>
                    <SuccessIcon />
                  </Avatar>
                }
              />
            ) : (
              <Chip
                color="primary"
                label="Whoops, yep, looks like this is a problem."
                variant="outlined"
                avatar={
                  <Avatar>
                    <ErrorIcon />
                  </Avatar>
                }
              />
            )}
            <br />
            <br />
            2. Backup Profile not Verified
            <br />
            {backupProfile.verifiedAt ? (
              <Chip
                color="secondary"
                label="Verified, so this looks good."
                variant="outlined"
                avatar={
                  <Avatar>
                    <SuccessIcon />
                  </Avatar>
                }
              />
            ) : (
              <Chip
                color="primary"
                label="No Profile, Check the Settings Page under Backup Profile to enter in your credentials."
                variant="outlined"
                avatar={
                  <Avatar>
                    <ErrorIcon />
                  </Avatar>
                }
              />
            )}
            <br />
            <br />
            3. Disabled Auto-sync in your Backup Profile
            <br />
            {backupProfile.autoSyncEnabled ? (
              <Chip
                color="secondary"
                label="Enabled, so this looks good."
                variant="outlined"
                avatar={
                  <Avatar>
                    <SuccessIcon />
                  </Avatar>
                }
              />
            ) : (
              <Chip
                color="primary"
                label="Disabled, so if you want to enable, check the Settings page under Backup Profile."
                variant="outlined"
                avatar={
                  <Avatar>
                    <ErrorIcon />
                  </Avatar>
                }
              />
            )}
            <br />
            <br />
            <br />
            See Contact Us on the Help Page to get some help.
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="text"
              onClick={this.navigateToSettings}
            >
              Settings Page
            </Button>
            <Button
              color="secondary"
              variant="text"
              onClick={this.navigateToHelp}
            >
              Help Page
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={this.toggleSyncInfo}
            >
              Ok, I Understand
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DeviceSync.defaultProps = {
  backupProfile: getBackupProfileStruct()
};

DeviceSync.propTypes = {
  classes: PropType.any.isRequired,
  user: PropType.object.isRequired,
  backupProfile: PropType.any,
  history: PropType.any.isRequired,
  internetStatus: PropType.any.isRequired,
  syncVersion: PropType.any.isRequired,
  syncChangeLogs: PropType.func.isRequired,
  syncEvents: PropType.func.isRequired,
  setInternetStatus: PropType.func.isRequired,
  handleWakeUp: PropType.func.isRequired,
  processChangeLogAction: PropType.func.isRequired
};

export default withRouter(withStyles(style)(DeviceSync));
