import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Style from './Style';

class SomethingWentWrong extends Component {
  state = {};

  handleAppRefresh = () => {
    window.location.reload();
  };

  render() {
    const { error } = this.props;

    return (
      <div
        data-tid="container"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh'
        }}
      >
        <div style={{ width: '85%' }}>
          <Typography
            variant="h5"
            color="secondary"
            style={{ textAlign: 'center' }}
          >
            Hmm... looks like something has gone wrong.
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: 20,
              flexDirection: 'column'
            }}
          >
            <Typography style={{ textAlign: 'center' }}>
              Most of the time a refresh will iron out the wrinkles...
            </Typography>
            <Button
              color="primary"
              style={{ margin: 20 }}
              variant="outlined"
              onClick={this.handleAppRefresh}
            >
              Refresh the App
            </Button>
            <Typography style={{ textAlign: 'center' }}>
              But, if the problem persists, let us know. :)
            </Typography>
            {error ? (
              <div style={{ overflow: 'scroll' }}>
                <pre>{JSON.stringify(error, null, 2)}</pre>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

SomethingWentWrong.defaultProps = {
  error: ''
};

SomethingWentWrong.propTypes = {
  error: PropTypes.any
};

export default withStyles(Style)(SomethingWentWrong);
