import React, { Component } from 'react';
import { connect } from 'react-redux';
import Settings from '../components/Settings/Settings';
import { loadUserFromDiskAction } from '../redux/actions/user';
import {createTrackerFromScaffoldAction, setLocalTrackersAction} from '../redux/actions/trackers';
import { setLocalJournalsAction } from '../redux/actions/journal';
import { setLocalCommitmentsAction } from '../redux/actions/commitments';
import { setBackupProfileAction } from '../redux/actions/backupProfile';
import { loadTourFromDiskAction } from '../redux/actions/tour';
import { loadTimersFromDiskAction } from '../redux/actions/timers';
import { loadChecklistsFromDiskAction } from '../redux/actions/checklists';
import {
  addNotificationAction,
  setInternetStatusAction,
  setProcessingAction
} from '../redux/actions/notifications';
import { applicationFullSyncAction } from '../redux/actions/appSync';
import { triggerDiagnosticError } from '../redux/actions/diagnostics';
import {signOutAction} from '../redux/actions/auth';

export class SettingsPageContainer extends Component {
  render() {
    return <Settings />;
  }
}

const mapStateToProps = state => ({
  user: state.user,
  backupProfile: state.backupProfile
});

const mapDispatchToProps = dispatch => ({
  loadUser: user => {
    dispatch(loadUserFromDiskAction(user));
  },
  loadTour: tour => {
    dispatch(loadTourFromDiskAction(tour));
  },
  loadTrackers: trackers => {
    dispatch(setLocalTrackersAction(trackers));
  },
  loadJournal: journal => {
    dispatch(setLocalJournalsAction(journal));
  },
  loadCommitments: commitments => {
    dispatch(setLocalCommitmentsAction(commitments));
  },
  loadTimers: timers => {
    dispatch(loadTimersFromDiskAction(timers));
  },
  loadChecklists: lists => {
    dispatch(loadChecklistsFromDiskAction(lists));
  },
  setBackupProfile: profile => {
    dispatch(setBackupProfileAction(profile));
  },
  resetTours: tour => {
    dispatch(loadTourFromDiskAction(tour));
  },
  addNotification: notification => {
    dispatch(addNotificationAction(notification));
  },
  setProcessingMessage: message => {
    dispatch(setProcessingAction(message));
  },
  setInternetStatus: message => {
    dispatch(setInternetStatusAction(message));
  },
  triggerFullSync: () => {
    dispatch(applicationFullSyncAction());
  },
  clearData: () => {
    dispatch(signOutAction());
  },
  triggerError: () => {
    dispatch(triggerDiagnosticError());
  },
  createTrackerFromTemplate: key => {
    dispatch(createTrackerFromScaffoldAction({key}))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
