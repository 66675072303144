import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {upsertCommitInviteAction} from "../../redux/actions/commitInvite";

const EditDialog = ({ invite, onReset }) => {
  const dispatch = useDispatch();
  const [description, setDescription] = useState('');
  const [contextDescription, setContextDescription] = useState('');
  const [notes, setNotes] = useState('');

  useEffect(() => {
    setDescription(invite.description);
    setContextDescription(invite.contextDescription);
    setNotes(invite.notes);
  }, [invite])

  const handleSubmit = () => {
    dispatch(upsertCommitInviteAction({
      ...invite,
      description,
      contextDescription,
      notes
    }));
    onReset();
  };

  return (
    <Dialog open={!!invite.id} fullWidth>
      <DialogTitle>
        Edit Invite
        <IconButton
          style={{ position: 'absolute', right: 0, top: 0 }}
          onClick={onReset}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Description"
          value={description}
          onChange={event => setDescription(event.target.value)}
          style={{ paddingBottom: '1em' }}
        />
        <TextField
          fullWidth
          label="Context"
          value={contextDescription}
          onChange={event => setContextDescription(event.target.value)}
          style={{ paddingBottom: '1em' }}
        />
        <TextField
          fullWidth
          multiline
          rows={4}
          label="Notes"
          value={notes}
          onChange={event => setNotes(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color={"primary"}
          variant={"contained"}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditDialog.defaultProps = {};

EditDialog.propTypes = {
  invite: PropTypes.object.isRequired
};

export default EditDialog;
