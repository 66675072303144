import { put, takeEvery, select, takeLatest, all } from 'redux-saga/effects';
import {stringifyApn} from "../../utils/apn/v2";
import {APPLICATION_DATA_CLEAR_REQUEST_ACTION, APPLICATION_DATA_REQUEST_ACTION} from "../actions/app";
import {addNotificationAction} from "../actions/notifications";
import {getSystemNotificationStruct} from "../../structs/notification";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {
  TRACKER_TEMPLATE_CREATE_REQUEST_ACTION,
  TRACKER_TEMPLATE_CREATE_SUCCESS_ACTION,
  TRACKER_TEMPLATE_DELETE_REQUEST_ACTION,
  TRACKER_TEMPLATE_DELETE_SUCCESS_ACTION,
  TRACKER_TEMPLATE_FROM_TRACKER_REQUEST_ACTION,
  TRACKER_TEMPLATE_FROM_TRACKER_SUCCESS_ACTION,
  TRACKER_TEMPLATE_REMOVE_LOCAL_ACTION,
  TRACKER_TEMPLATE_UPSERT_LOCAL_ACTION, upsertLocalTrackerTemplateAction
} from "../actions/trackerTemplate";
import {
  createRemoteTrackerTemplate,
  deleteRemoteTrackerTemplate,
  fetchRemoteTrackerTemplates
} from "../../utils/appSync/trackerTemplate";
import {createScaffoldFromTracker, getTrackerTemplateStruct} from "../../structs/trackerTemplate";
import {prepAndPruneForAppSync} from "../../utils/appSync";

function* handleCreateTrackerTemplateRequest({ payload: template }) {

  const templates = yield select(store => store.trackerTemplates);

  if (templates.length >= 100) {
    yield put(
      addNotificationAction(
        getSystemNotificationStruct({
          message: (
            <div>
              <Typography>
                {`Sorry there is a limit of 100 Tracker Templates, if would like to add more, please remove some.`}
              </Typography>
            </div>
          ),
          type: "dialog",
          level: "warning",
        })
      )
    );
    return 0;
  }

  const { id: userId } = yield select(store => store.user);
  const ownerApn = stringifyApn({ userId });

  const trackerTemplate = getTrackerTemplateStruct({
    ...template,
    ownerApn
  });

  const remoteTemplate = yield createRemoteTrackerTemplate(trackerTemplate);

  yield put({
    type: TRACKER_TEMPLATE_UPSERT_LOCAL_ACTION,
    payload: remoteTemplate
  });

  // Signal Complete
  yield put({
    type: TRACKER_TEMPLATE_CREATE_SUCCESS_ACTION,
    payload: remoteTemplate
  });
}

function* handleDeleteTrackerTemplateRequest({ payload: template }) {
  const { ownerApn, id } = template;
  yield deleteRemoteTrackerTemplate(ownerApn, id);

  yield put({
    type: TRACKER_TEMPLATE_REMOVE_LOCAL_ACTION,
    payload: template
  });

  // Signal Complete
  yield put({
    type: TRACKER_TEMPLATE_DELETE_SUCCESS_ACTION,
    payload: template
  });
}

function* handleCreateTemplateFromTracker({ payload: { tracker, template }}) {
  const user = yield select(store => store.user);
  const scaffold = createScaffoldFromTracker(tracker);
  const trackerTemplate = prepAndPruneForAppSync({
    ...template,
    ownerApn: stringifyApn({ userId: user.id }),
    scaffold
  }, getTrackerTemplateStruct);

  console.log(trackerTemplate);

  const remoteTemplate = yield createRemoteTrackerTemplate(trackerTemplate);

  yield put(upsertLocalTrackerTemplateAction(remoteTemplate));

  yield put({ type: TRACKER_TEMPLATE_FROM_TRACKER_SUCCESS_ACTION });
}

function* handleApplicationDataClear() {
  const templates = yield select(store => store.trackerTemplates);
  yield all(templates.map((template) => put({ type: TRACKER_TEMPLATE_REMOVE_LOCAL_ACTION, payload: template })))
}

function* handleFullSyncRequest() {
  const { id: userId } = yield select(store => store.user);
  const ownerApn = stringifyApn({ userId });
  const remoteTemplates = yield fetchRemoteTrackerTemplates(ownerApn);
  yield all(remoteTemplates.map((template) => put({ type: TRACKER_TEMPLATE_UPSERT_LOCAL_ACTION, payload: template })))
}

export default function* trackerTemplateSaga() {
  // Handle Actions
  yield takeEvery(TRACKER_TEMPLATE_CREATE_REQUEST_ACTION, handleCreateTrackerTemplateRequest);
  yield takeEvery(TRACKER_TEMPLATE_DELETE_REQUEST_ACTION, handleDeleteTrackerTemplateRequest);

  yield takeLatest(APPLICATION_DATA_CLEAR_REQUEST_ACTION, handleApplicationDataClear);
  yield takeLatest(APPLICATION_DATA_REQUEST_ACTION, handleFullSyncRequest);

  yield takeLatest(TRACKER_TEMPLATE_FROM_TRACKER_REQUEST_ACTION, handleCreateTemplateFromTracker);
}
