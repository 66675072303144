import React, { Component } from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import isEqual from 'lodash/isEqual';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {Accordion, AccordionDetails, AccordionSummary, ListItemAvatar} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import logo from '../images/fwing.png';
import UserNotes from '../../containers/UserNotes';
import routes from '../../constants/routes.json';
import CommitmentItem from '../../containers/Commitment/Item';
import CommitmentCalendarItem from '../../containers/Commitment/CalendarItem';
import QuickAdd from '../../containers/Commitment/QuickAdd';
import Tour from '../../containers/Tour';
import { getCommitmentIndexByDateStruct } from '../../structs/indexers';
import { getDateFromString, getHoursFromMinutes } from '../../utils/date';
import CalendarCarousel from '../Ui/CalendarCarousel';
import Clock from './Clock';
import PendingShareList from './PendingShareList';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Chip from '@material-ui/core/Chip';
import RoleFilter from "../Role/Filter";
import RolePills from "../Role/Pills";
import CommitmentTrayList from "../CommitmentTray/List";
import Divider from "@material-ui/core/Divider";
import {NotificationImportant} from "@material-ui/icons";
import MyInvitesList from "../CommitInvite/MyInvitesList";

const style = () => ({
  card: {
    margin: '.5em',
    '&.Mui-expanded': {
      margin: '.5em'
    }
  },
  badge: {
    top: 0,
    right: 0,
  },
  logo: {
    width: 35,
    height: 35,
    position: 'absolute',
    top: 0,
    right: 0,
    margin: '0.5em 1em',
  },
  contentContainer: {
    display: 'flex',
  },
  textArea: {
    width: '32vw'
  },
  future: {
    width: '100%'
  }
});

class Home extends Component {
  state = {
    startDate: getDateFromString(new Date(), true),
    timeSlots: [],
    timeSlotIndex: {},
    today: new Date(),
    dailyCommitments: {
      today: getCommitmentIndexByDateStruct(),
      past: [],
      future: [],
    },
  };

  componentDidMount() {
    const { setUserCheck } = this.props;

    setUserCheck(true);

    this.startTimer();
    this.indexDate();
  }

  componentDidUpdate(prevProps) {
    const { today, commitmentIndex } = this.props;
    const { today: prevToday, commitmentIndex: prevCommitmentIndex } = prevProps;
    
    if (prevToday !== today || !isEqual(commitmentIndex, prevCommitmentIndex)) {
      this.indexDate(today);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    clearTimeout(this.timeout);
  }

  commitmentIndexDate;

  selectedDate;

  timer;

  timeout;

  calendarRef;

  scrolled;

  startTimer = () => {
    if (!this.timer) {
      this.timer = setInterval(this.handleSetCurrentTime, 3000);
    }
    if (!this.timeout) {
      this.timeout = setTimeout(this.handleSetCurrentTime, 1000);
    }
  };

  handleSetCurrentTime = () => {
    const { startDate } = this.state;
    if (!this.scrolled) {
      const midnight = getDateFromString(new Date(), true);
      const scrollAdjustment =
        this.calendarRef.scrollHeight *
        (Math.abs(midnight - new Date()) / (1000 * 60 * 60 * 24));
      let visualAdjustment = this.calendarRef.offsetHeight * 0.25;
      if (
        scrollAdjustment >
        this.calendarRef.scrollHeight - this.calendarRef.offsetHeight * 0.25
      ) {
        visualAdjustment = 0;
      }
      this.calendarRef.scrollTop = scrollAdjustment - visualAdjustment;

      this.scrolled = true;
    }
    this.setState({ startDate });
  };

  indexDate = (forceStartDate = null, forceCommitmentIndex = null) => {
    let { commitmentIndex } = this.props;
    if (forceCommitmentIndex) {
      commitmentIndex = forceCommitmentIndex;
    }
    const { all, byDate } = commitmentIndex;
    let { startDate, dailyCommitments } = this.state;

    if (forceStartDate) {
      startDate = getDateFromString(forceStartDate.toString(), true);
    }

    const timeSlots = range(0, 24 * 4).map((slotIndex) => {
      let time = getDateFromString(startDate.toString(), true);
      if (forceStartDate) {
        time = getDateFromString(forceStartDate.toString(), true);
      }

      // Add 15 minutes for our calendar
      time.setTime(time.getTime() + slotIndex * 15 * 60 * 1000);

      return time;
    });

    const timeSlotIndex = {};
    timeSlots.map((timeSlot) => {
      timeSlotIndex[timeSlot.toLocaleString()] = {
        commitments: [],
      };
      return timeSlot;
    });

    // Active Commitment Indexing
    commitmentIndex.active.map((document) => {
      const { entity: commitment } = document;
      const { date } = commitment;
      const commitmentDate = new Date(date);
      let commitmentDateString = commitmentDate.toLocaleString();
      const { [commitmentDateString]: commitmentTimeSlot } = timeSlotIndex;
    
      // If the commitment has a non-standard time of day, we need to find a
      // valid time slot
      if (!commitmentTimeSlot) {
        const adjustedTimeSlot = timeSlots
            .filter((timeSlot) => timeSlot < commitmentDate)
            .pop();
        if (adjustedTimeSlot) {
          commitmentDateString = adjustedTimeSlot.toLocaleString();
        }
      }
      if (commitmentDateString && timeSlotIndex[commitmentDateString]) {
        timeSlotIndex[commitmentDateString].commitments.push(commitment);
      }

      return document;
    });

    dailyCommitments = {
      today: getCommitmentIndexByDateStruct(),
      past: [],
      future: [],
    };

    if (byDate[startDate.toDateString()]) {
      dailyCommitments[
        startDate.toDateString()
      ] = getCommitmentIndexByDateStruct(byDate[startDate.toDateString()]);
    } else {
      dailyCommitments[
        startDate.toDateString()
      ] = getCommitmentIndexByDateStruct();
    }

    if (byDate && startDate && byDate[startDate.toDateString()]) {
      dailyCommitments.today = getCommitmentIndexByDateStruct(
        byDate[startDate.toDateString()]
      );
    }

    // Daily Commitment Indexing
    all.forEach((document) => {
      const { entity: commitment } = document;
      const { date } = commitment;
      const commitmentDate = new Date(date);
      const tomorrow = getDateFromString(startDate.toString(), true);
      tomorrow.setDate(tomorrow.getDate() + 1);
      if (commitmentDate >= tomorrow) {
        dailyCommitments.future.push(document);
      }
    });

    this.selectedDate = startDate;
    this.commitmentIndexDate = commitmentIndex.updatedAt;

    this.setState({
      startDate,
      timeSlots,
      timeSlotIndex,
      dailyCommitments,
    });
  };

  handleNavigateToDate = (date) => {
    const { setToday } = this.props;
    setToday(getDateFromString(date.toString(), true));
  };
  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleDrop = (result) => {
    const { commitmentIndex, saveCommitment } = this.props;
    const { all } = commitmentIndex;
    const { destination, draggableId: commitmentId } = result;

    if (!destination) return;

    const { droppableId: newCommitmentDate } = destination;
    const [documentToUpdate] = all.filter(
      (document) => document.entity.id === commitmentId
    );
    const { entity: commitmentToUpdate } = documentToUpdate;
    commitmentToUpdate.date = new Date(newCommitmentDate);

    this.indexDate(null, commitmentIndex);

    saveCommitment(commitmentToUpdate);
  };
  
  render() {
    const { classes, commitmentIndex, trackerIndex } = this.props;
    const {
      today,
      startDate,
      timeSlots,
      timeSlotIndex,
      dailyCommitments,
    } = this.state;
    const { today: trackersToReview, activeByRole } = trackerIndex;
    const { byRole } = commitmentIndex;
    const activeCommitments = commitmentIndex.active || [];

    const startDateLabel = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: '2-digit',
    }).format(startDate);

    const getDiffNow = () => {
      const start = new Date();
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);

      const end = new Date();
      end.setSeconds(0);
      return (end.getTime() - start.getTime()) / 1000;
    };

    const isToday = (time) => {
      const todayDate = new Date();

      return todayDate.getFullYear() === time.getFullYear()
        && todayDate.getMonth() === time.getMonth()
        && todayDate.getDate() === time.getDate();
    };

    return (
      <Grid container direction="column">
        <Grid row container>
          <Hidden only="xs">
            <Grid item md={2}>
              <img src={logo} alt="Formigio Logo" className={classes.logo} />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={10}>
            <CalendarCarousel
              selectedDate={startDate}
              onSelect={this.handleNavigateToDate}
            />
          </Grid>
        </Grid>
        <Grid
          row
          container
          direction="row-reverse"
          style={{ marginTop: '1.75em' }}
        >
          <Grid item xs={12} md={6}>
            {/* Planner Lists */}
            <div style={{ flex: 'auto', overflow: 'auto' }}>
              {/* Today's Card */}
              <Card className={classes.card} elevation={0}>
                <CardContent>
                  <Typography
                    variant={"overline"}
                    color="secondary"
                    style={{display: 'inline-block', textAlign: 'center', width: '100%', marginTop: '1em'}}
                  >
                    Invitations to Review
                  </Typography>

                  <PendingShareList/>
                  <MyInvitesList />

                  <RoleFilter/>
                  {startDate.toDateString() === today.toDateString() ? (
                    <List className="tour-tracker-review" dense>
                      <Typography
                        variant={"overline"}
                        color="secondary"
                        style={{display: 'inline-block', textAlign: 'center', width: '100%', marginTop: '1em'}}
                      >
                        Trackers to Review
                      </Typography>
                      <RolePills roles={activeByRole}/>
                      {trackersToReview.length ? (
                        trackersToReview.map((trackerDoc) => {
                          const { entity: tracker, share, shares } = trackerDoc;
                          return (
                            <ListItem key={tracker.id}>
                              <ListItemAvatar>
                                <NotificationImportant style={{ color: 'lightgray' }}/>
                              </ListItemAvatar>
                              <ListItemText primary={
                                <Link to={`${routes.TRACKER_BASE}${tracker.id}`}>
                                  {tracker.dream}
                                </Link>
                              }/>
                              {share.id ? <ListItemSecondaryAction>
                                <Chip label="Shared with me" variant="outlined" color="secondary" size="small"/>
                              </ListItemSecondaryAction> : (
                                shares.length > 0 ? <ListItemSecondaryAction>
                                  <Chip label={`Shared with ${shares.length}`} variant="outlined" color="primary" size="small"/>
                                </ListItemSecondaryAction> : null
                              )}
                            </ListItem>
                          );
                        })
                      ) : (
                        <ListItem key="noTrackersToReview">
                          <ListItemText secondary="Great News, you are all caught up! :)" />
                        </ListItem>
                      )}
                    </List>
                  ) : null}
                  <List className="tour-daily-commitments">
                    <Typography
                      variant={"overline"}
                      color="secondary"
                      style={{display: 'inline-block', textAlign: 'center', width: '100%', marginTop: '1em'}}
                    >
                      Today's Commitments
                    </Typography>
                    <Typography
                      color="primary"
                      variant={"caption"}
                      style={{display: 'inline-block', textAlign: 'center', width: '100%'}}
                    >
                      {`Committed Time: ${getHoursFromMinutes(
                        dailyCommitments.today.committedTime
                      )}`}
                    </Typography>

                    <RolePills roles={byRole}/>
                    {activeCommitments.length ? (
                      activeCommitments.filter(c => !c.entity.trays || (c.entity.trays && !c.entity.trays.length)).map((document) => {
                        const { entity: commitment } = document;
                        return (<CommitmentItem
                          key={commitment.id}
                          commitment={document}
                          showDate
                        />)
                      })
                    ) : (
                      <ListItem key="noCommitments">
                        <ListItemText
                          primary="None"
                          secondary={
                            startDate > today
                              ? 'No commitments for this day, yet.'
                              : ''
                          }
                        />
                      </ListItem>
                    )}
                    <QuickAdd />
                    {dailyCommitments.today.commitments.length ? (
                      <Tour
                        id="first-commitments-v1.0"
                        steps={[
                          {
                            target: '.tour-daily-commitments',
                            content:
                              'Looks like you have a commitment! Let us ' +
                              'show you around.',
                          },
                          {
                            target:
                              '.tour-daily-commitments .tour-commitment-complete',
                            content:
                              'For each commitment, you can mark it as ' +
                              'complete.',
                          },
                          {
                            target:
                              '.tour-daily-commitments .tour-commitment-focus',
                            content:
                              'Click on the commitment to go to the Focus View.',
                          },
                          {
                            target:
                              '.tour-daily-commitments .tour-commitment-edit',
                            content:
                              'Using the edit button, you can change the ' +
                              'description, or delete the commitment.',
                          },
                          {
                            target:
                              '.tour-daily-commitments .tour-commitment-recommit',
                            content:
                              'To move the commitment you can use the arrow. ' +
                              'Choosing a new date and re-commiting to work on ' +
                              'it at a different time.',
                          },
                        ]}
                      />
                    ) : null}
                  </List>
                </CardContent>
              </Card>
              {/* Today's Card /end */}

              {dailyCommitments.future.length ? (
                <Accordion className={classes.card} elevation={0}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>
                      Future Commitments
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List className={classes.future}>
                      {dailyCommitments.future.map((document) => {
                        const { entity: commitment } = document;
                        if (commitment) {
                          return (
                            <CommitmentItem
                              key={commitment.id}
                              commitment={document}
                              showDate
                            />
                          );
                        }
                        return null;
                      })}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ) : null}
            </div>
          </Grid>

          {/* Calendar View */}
          <Grid item xs={12} md={6}>
            <Grid
              container
            >
              <div
                style={{ flex: 'auto' }}
              >
                <Card
                  className={classes.card}
                  elevation={0}
                >
                  <CommitmentTrayList/>
                </Card>
              </div>
            </Grid>
            <Grid
              container
              style={{
                height: '75vh',
              }}
            >
              <div
                className="tour-calendar"
                style={{ flex: 'auto', overflow: 'hidden' }}
              >
                <Card
                  className={classes.card}
                  style={{ height: '75vh' }}
                  elevation={0}
                >
                  <CardHeader
                    title={startDateLabel}
                    subheader={
                      startDate.toDateString() === today.toDateString()
                        ? 'Today'
                        : ''
                    }
                  />
                  <Typography
                    variant={"overline"}
                    color="secondary"
                    style={{display: 'inline-block', textAlign: 'center', width: '100%', marginTop: '1em'}}
                  >
                    Calendar
                  </Typography>
                  <Divider/>
                  <div
                    ref={(el) => {
                      this.calendarRef = el;
                    }}
                    style={{
                      height: '100%',
                      overflow: 'auto',
                      paddingBottom: '5em'
                    }}
                  >
                    <CardContent>
                      <DragDropContext onDragEnd={this.handleDrop}>
                        {timeSlots.map((time, idx) => (
                          <div
                            key={time.toString()}
                            style={{
                              borderTop:
                                time.getMinutes() === 0
                                  ? '1px dashed lightgray'
                                  : '',
                              marginLeft: '2em',
                              height: '1.75em',
                              position: 'relative',
                            }}
                          >
                            <Typography
                              style={{
                                position: 'absolute',
                                top: '-.75em',
                                left: '-3em',
                                color: 'lightgray',
                              }}
                            >
                              {time.getMinutes() === 0
                                ? new Intl.DateTimeFormat('en-US', {
                                    hour: 'numeric',
                                  }).format(time)
                                : ''}
                            </Typography>
                            {idx === 0 && isToday(timeSlots[0]) && (
                              <Typography
                                style={{
                                  fontSize: '0.6em',
                                  marginLeft: '-4em',
                                  borderBottom: '1px solid lightpink',
                                  position: 'absolute',
                                  top: `${getDiffNow() / 3600 * 4 * 1.75 - 1}rem`,
                                  backgroundColor: 'white',
                                  zIndex: 1000
                                }}
                              >
                                {new Intl.DateTimeFormat('en-US', {
                                  hour: 'numeric',
                                  minute: 'numeric',
                                }).format(new Date())}
                              </Typography>
                            )}
                            <List style={{ padding: 0 }}>
                              <Droppable droppableId={time.toString()}>
                                {(provided, snapshot) => (
                                  <div
                                    style={{
                                      height: '1.75em',
                                      backgroundColor: snapshot.isDraggingOver
                                        ? 'lightgray'
                                        : '',
                                      display: 'flex',
                                    }}
                                    ref={provided.innerRef}
                                  >
                                    {timeSlotIndex[time.toLocaleString()]
                                      .commitments.length
                                      ? timeSlotIndex[
                                          time.toLocaleString()
                                        ].commitments.map(
                                          (commitment, index) => (
                                            <Draggable
                                              draggableId={commitment.id}
                                              key={commitment.id}
                                              index={index}
                                            >
                                              {(
                                                innerProvided,
                                                innerSnapshot
                                              ) => (
                                                <div
                                                  ref={innerProvided.innerRef}
                                                  {...innerProvided.draggableProps}
                                                  {...innerProvided.dragHandleProps}
                                                  style={{
                                                    backgroundColor: innerSnapshot.isDragging
                                                      ? 'lightgray'
                                                      : '',
                                                    flex: 'auto',
                                                    ...innerProvided
                                                      .draggableProps.style,
                                                  }}
                                                >
                                                  <CommitmentCalendarItem
                                                    key={commitment.id}
                                                    commitment={commitment}
                                                    superSimple
                                                  />
                                                </div>
                                              )}
                                            </Draggable>
                                          )
                                        )
                                      : null}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </List>
                          </div>
                        ))}
                      </DragDropContext>
                    </CardContent>
                  </div>
                </Card>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid row container>
            <Grid item xs={12} md={6}>
              <Paper>
                <UserNotes />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper>
                <Clock />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Tour
          id="home-v1.1"
          steps={[
            {
              target: '.tour-homepage',
              content:
                'Welcome to the Home Page. This is your first stop each ' +
                'day. You can review the Tracker pages that are ready for review, ' +
                'and start working on the commitments that you have made.',
            },
            {
              target: '.tour-calendar',
              content:
                'The home page has a space for each day in the calendar. ' +
                'As you work, you can plan out out your day, marking on the ' +
                'calendar how much time you want to spend.',
            },
            {
              target: '.tour-tracker-review',
              content:
                'The Tracker pages that are ready for review will show here.',
            },
            {
              target: '.tour-home-notes',
              content: 'Here you can make notes for yourself.',
            },
            {
              target: '.tour-trackers',
              content:
                'Find any of your Tracker pages by going to the Trackers ' +
                'grid.',
            },
            {
              target: '.tour-settings',
              content: 'Manage your data and your application settings here.',
            },
            {
              target: '.tour-help',
              content:
                'Access the help directory, if you have questions about ' +
                'The Accomplishment System, or functions of the application.',
            },
            {
              target: '.tour-create-tracker',
              content:
                'Get started by creating a new Tracker page, and start ' +
                'working towards a dream today!',
            },
          ]}
        />
      </Grid>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  commitmentIndex: PropTypes.object.isRequired,
  trackerIndex: PropTypes.object.isRequired,
  saveCommitment: PropTypes.func.isRequired,
  setUserCheck: PropTypes.func.isRequired,
  setToday: PropTypes.func.isRequired,
  today: PropTypes.instanceOf(Date)
};

export default withStyles(style)(Home);
