import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import LearnItem from '../../Learn/Items/Item';
import { getLearnItemStruct } from '../../../structs/learn';

export const style = () => ({});

class TrackerLearnItems extends Component {
  state = { learn: [] };

  componentDidMount() {
    const { tracker } = this.props;
    const { learn } = this.state;

    if (tracker.learn !== learn) {
      this.hydrateState(tracker.learn);
    }
  }

  componentDidUpdate() {
    const { tracker } = this.props;
    const { learn } = this.state;

    if (tracker.learn !== learn) {
      this.hydrateState(tracker.learn);
    }
  }

  hydrateState = learn => {
    this.setState({ learn });
  };

  handleItemChange = itemToChange => {
    const { learn } = this.state;
    const { onChange } = this.props;
    const newLearn = this.reSequenceItems(
      learn.map(item => {
        if (item.seq === itemToChange.seq) {
          return itemToChange;
        }
        return item;
      })
    );
    this.setState({
      learn: newLearn
    });
    // Persist to Tracker onChange
    onChange(newLearn);
  };

  handleAdd = type => () => {
    this.addLearnItem({ type });
  };

  addLearnItem = itemToAdd => {
    const { learn } = this.state;
    const { onChange } = this.props;

    learn.push(getLearnItemStruct(itemToAdd));

    onChange(this.reSequenceItems(learn));
  };

  handleItemRemove = itemToRemove => {
    const { learn } = this.state;
    const { onChange } = this.props;

    const newLearn = learn.filter(item => item.id !== itemToRemove.id);

    onChange(this.reSequenceItems(newLearn));
  };

  reSequenceItems = items => {
    let seq = 1;
    const list = items.map(item => {
      const newItem = item;
      newItem.seq = seq;
      seq += 1;
      return newItem;
    });
    return list;
  };

  render() {
    const { readOnly } = this.props;
    const { learn } = this.state;

    return (
      <List>
        {learn.length
          ? learn.map(item => (
              <LearnItem
                key={item.id}
                item={item}
                readOnly={readOnly}
                onChange={this.handleItemChange}
                onDelete={this.handleItemRemove}
              />
            ))
          : null}
        {!readOnly && (
          <ListItem>
            +
            <Button size="small" onClick={this.handleAdd('note')}>
              Note
            </Button>
            <Button size="small" onClick={this.handleAdd('link')}>
              Link
            </Button>
            <Button size="small" onClick={this.handleAdd('question')}>
              Question
            </Button>
            {/* <Button size="small" onClick={this.handleAdd('page')}> */}
            {/*  Page */}
            {/* </Button> */}
            {/* <Button size="small" onClick={this.handleAdd('image')}> */}
            {/*  Image */}
            {/* </Button> */}
            {/* <Button size="small" onClick={this.handleAdd('file')}> */}
            {/*  FILE */}
            {/* </Button> */}
          </ListItem>
        )}
      </List>
    );
  }
}

TrackerLearnItems.propTypes = {
  tracker: PropTypes.any.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default withStyles(style)(TrackerLearnItems);
