import {
  SET_TRACKER_ACTIVITY_NEXT_TOKEN_ACTION,
  UPSERT_LOCAL_TRACKER_ACTIVITY_ACTION
} from '../actions/trackerActivity';

export default function trackerActivityReducer(origstate = { items: [], nextToken: null }, action) {

  switch (action.type) {

    case UPSERT_LOCAL_TRACKER_ACTIVITY_ACTION:
      if (!action.payload) return origstate;
      if (
        origstate.items.filter(
          trackerActivity => `${action.payload.trackerId}${action.payload.createdAt}` === `${trackerActivity.trackerId}${trackerActivity.createdAt}`
        ).length
      )
        return {
          ...origstate,
          items: origstate.items.map(trackerActivity => {
            if (`${action.payload.trackerId}${action.payload.createdAt}` === `${trackerActivity.trackerId}${trackerActivity.createdAt}`) {
              return {
                ...action.payload
              };
            }
            return trackerActivity;
          })
        };

      return {
        ...origstate,
        items: [action.payload, ...origstate.items]
      };

    case SET_TRACKER_ACTIVITY_NEXT_TOKEN_ACTION:
      return {
        ...origstate,
        nextToken: action.payload
      };

    default:
      return origstate;
  }
}
