import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import {useDispatch} from 'react-redux';
import {deleteTrackerAction} from '../../../redux/actions/trackers';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {DeleteForever} from "@material-ui/icons";

const DeleteDialog = ({
  tracker,
  onDialog,
  open
}) => {
  
  const dispatch = useDispatch();

  const handleClose = () => {
    onDialog('');
  };

  const handleDelete = () => {
    dispatch(deleteTrackerAction(tracker));
    onDialog('');
  };
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Tracker Delete
        <IconButton
          style={{ position: 'absolute', right: 0, top: 0 }}
          onClick={handleClose}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure that you want to delete this tracker? This
          cannot be un-done, once you delete, poof... it is gone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<DeleteForever/>}
          onClick={handleDelete}
          color="primary"
          variant="contained"
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
};

DeleteDialog.defaultProps = {};

DeleteDialog.propTypes = {
  tracker: PropTypes.object.isRequired,
  onDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default DeleteDialog;
