import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch} from 'react-redux';
import {TRACKER_STATUS_ACTIVE} from '../../../structs/trackers';
import {addTrackerShareAction} from '../../../redux/actions/trackerShares';
import SelectContactButton from "../../Contacts/SelectContactButton";
import {getContactStruct} from "../../../structs/contact";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(() => ({
  buttonWrap: {
    marginTop: '1em'
  }
}));

const ShareButton = ({tracker, onShare}) => {
  
  const classes = useStyles();
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState(false);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState(getContactStruct());
  const valid = email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const handleShare = () => {
    dispatch(addTrackerShareAction({
      trackerId: tracker.id,
      emailAddress: email,
    }));
    setOpen(false);
    setConfirm(false);
    setEmail('');
    onShare(true);
  };
  
  const handleEmailChange = (event) => {
    const email = event.target.value;
    setEmail(email);
    setConfirm(false);
  };

  const handleContactSelect = (contact) => {
    setContact(contact);
    setEmail(contact.friendEmail);
  };

  return tracker.status === TRACKER_STATUS_ACTIVE ? (
    <React.Fragment>
      <div className={classes.buttonWrap}>
        <Button
          variant="text"
          color="secondary"
          size="small"
          onClick={() => setOpen(true)}
        >
          Share Tracker
        </Button>
      </div>
      <Dialog
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-share-dialog-title"
        aria-describedby="alert-share-dialog-description"
      >
        <DialogTitle id="alert-share-dialog-title">
          Share Tracker
          <IconButton
            style={{ position: 'absolute', right: 0, top: 0 }}
            onClick={() => setOpen(false)}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent id="alert-share-dialog-description">
          <SelectContactButton onSelect={handleContactSelect} selectedContact={contact} tracker={tracker} />
          {contact.id ? null : <Typography style={{ paddingTop: '1.5em' }}>- OR -</Typography>}
          <TextField
            id="email"
            error={email && !valid}
            label={!valid
              ? 'Enter an email for someone new'
              : 'Email to Share With'
            }
            fullWidth={true}
            value={email}
            onChange={handleEmailChange}
            margin="normal"
            helperText={
              email && valid
                ? 'Awesome. Email address looks great!'
                : email ? 'That email is not looking valid, keep going...' : ''
            }
          />
          {confirm && valid
            ? (
              <React.Fragment>
                <DialogContentText>
                  You are about to share this tracker.
                  When you share, the entire tracker will be visible to the person you share with.
                </DialogContentText>
                <DialogContentText>
                  When the other person accepts the share, you both will be added to each other's contacts.
                </DialogContentText>
                <DialogContentText>
                  Do you really want to share this tracker?
                </DialogContentText>
              </React.Fragment>
            )
            : null}
        </DialogContent>
        <DialogActions>
          {confirm && valid
            ? <Button
              onClick={handleShare}
              color="primary"
              variant="contained"
              disabled={!confirm || !valid}
              autoFocus
            >
              Confirm
            </Button>
            : <Button
              onClick={() => setConfirm(true)}
              color="primary"
              variant="contained"
              disabled={!valid}
              autoFocus
            >
              Share
            </Button>}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  ) : null;
};

ShareButton.defaultProps = {
  onShare: () => null
};

ShareButton.propTypes = {
  tracker: PropTypes.object.isRequired,
  onShare: PropTypes.func
};

export default ShareButton;
