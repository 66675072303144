import moment from 'moment';
import { convertPickerDateToDate } from '../utils/date';
import { parseApn } from '../utils/apn/v1';
import { stringifyApn as stringifyApnV2, parseApn as parseApnV2 } from '../utils/apn/v2';
import {getRoleStruct, RoleStruct} from "./roles";

export const COMMITMENT_STATUS_PENDING = 'pending';
export const COMMITMENT_STATUS_MOVED = 'moved';
export const COMMITMENT_STATUS_COMPLETE = 'complete';

export const COMMITMENT_DEFAULT_COLOR = '#ebebeb';

export const CommitmentReminderStruct = {
  email: false,
  sms: false
}

export const CommitmentStruct = {
  schemaVersion: '1.7.0',
  id: '',
  status: COMMITMENT_STATUS_PENDING, // pending, moved, complete
  userId: ' ',
  milestoneApn: '', // Deprecated
  milestoneId: '', // Deprecated
  description: '',
  notes: '',
  date: '', // Date String
  originalDate: '',
  createdAt: '',
  updatedAt: '',
  highlightColor: COMMITMENT_DEFAULT_COLOR,
  timeCommitment: 0, // Time Commitments in Minutes
  trackerId: '',
  contextApn: '',
  contextDescription: '',
  role: RoleStruct,
  trays: [],
  reminders: CommitmentReminderStruct
};

export const CommitmentDocumentStruct = {
  entity: {},
  dream: '',
  milestone: '',
  trackerApn: ''
};

export const ActiveCommitmentStruct = {
  active: {},
  completed: {}
};

export const getCommitmentStruct = (commitment = CommitmentStruct) => ({
  ...CommitmentStruct,
  ...commitment,
  role: getRoleStruct(commitment.role),
  reminders: {
    ...CommitmentReminderStruct,
    ...commitment.reminders
  }
});

export const getCommitmentDocumentStruct = (doc = {}) =>
  Object.assign({}, CommitmentDocumentStruct, doc);

export const commitmentRequiresMigration = (entity, struct = CommitmentStruct) => {
  const { schemaVersion } = struct;
  return entity.schemaVersion !== schemaVersion;
};

export const evaluateCommitments = (commitments) => {
  const valid = [];
  const migrate = [];
  
  commitments.map(commitment => {
    if (commitmentRequiresMigration(commitment)) {
      migrate.push(commitment);
    } else {
      valid.push(commitment);
    }
    
    return commitment;
  });
  
  return [valid, migrate];
};

export const migrateCommitments = (commitments, user) => {
  const { schemaVersion } = CommitmentStruct;

  return commitments.map(commitment => {
    if (commitment.schemaVersion !== schemaVersion) {
      return migrateCommitment(commitment, user);
    }
    return commitment;
  });
};

export const migrateCommitment = (commitment, user, toVersion = CommitmentStruct.schemaVersion) => {
  const versions = [
    'undefined',
    '1.0.0',
    '1.1.0',
    '1.2.0',
    '1.3.0',
    '1.4.0',
    '1.5.0',
    '1.6.0',
    '1.7.0'
  ];
  const newCommitment = getCommitmentStruct(commitment);

  if (!commitment.schemaVersion) newCommitment.schemaVersion = 'undefined';

  while (newCommitment.schemaVersion !== toVersion) {
    const whereAreWeNow = versions.indexOf(newCommitment.schemaVersion);
    const whereAreWeGoing = versions.indexOf(toVersion);

    let targetVersion = toVersion;

    if (whereAreWeGoing - whereAreWeNow > 1)
      targetVersion = versions[whereAreWeNow + 1];

    // Version transitions
    if (
      `${newCommitment.schemaVersion}_${targetVersion}` === 'undefined_1.0.0'
    ) {
      // We don't need to do anything for this version
    } else if (
      `${newCommitment.schemaVersion}_${targetVersion}` === '1.0.0_1.1.0'
    ) {
      // Migrate Status and updatedAt date
      newCommitment.status = commitment.complete ? COMMITMENT_STATUS_COMPLETE : COMMITMENT_STATUS_PENDING;
      newCommitment.updatedAt = moment().format();
    } else if (
      `${newCommitment.schemaVersion}_${targetVersion}` === '1.1.0_1.2.0'
    ) {
      newCommitment.date = convertPickerDateToDate(commitment.date).toString();
    } else if (
      `${newCommitment.schemaVersion}_${targetVersion}` === '1.2.0_1.3.0'
    ) {
      // Nothing to do here.
    } else if (
      `${newCommitment.schemaVersion}_${targetVersion}` === '1.3.0_1.4.0'
    ) {
      // Nothing to do here.
    } else if (
      `${newCommitment.schemaVersion}_${targetVersion}` === '1.4.0_1.5.0'
    ) {
      // Nothing to do here.
    } else if (
      `${newCommitment.schemaVersion}_${targetVersion}` === '1.5.0_1.6.0'
    ) {
      // Update APN
      const { trackerId, milestoneId } = parseApn(commitment.milestoneApn);
      newCommitment.userId = user.id;
      newCommitment.milestoneApn = stringifyApnV2({
        userId: user.id,
        trackerId,
        milestoneId
      });
      newCommitment.milestoneId = milestoneId;

      newCommitment.createdAt = moment(newCommitment.createdAt).format();
      newCommitment.updatedAt = moment(newCommitment.updatedAt).format();
      newCommitment.date = moment(newCommitment.date).format();
      newCommitment.originalDate = newCommitment.originalDate
        ? moment(newCommitment.originalDate).format()
        : moment(newCommitment.date).format();
    } else if (
      `${newCommitment.schemaVersion}_${targetVersion}` === '1.6.0_1.7.0'
    ) {
      // Tracker ID
      const { trackerId } = parseApnV2(commitment.milestoneApn);
      newCommitment.trackerId = trackerId;
      // ContextApn
      newCommitment.contextApn = commitment.milestoneApn;
      // ContextDescription
    }

    // Version Up the Tracker Schema
    newCommitment.schemaVersion = targetVersion;
    
    console.log(newCommitment);
  }

  return getCommitmentStruct(newCommitment);
};
