import moment from 'moment';

export const ReviewCycleStruct = {
  schemaVersion: '1.0.0',
  frequency: 'daily', // Daily, Weekly, Monthly
  interval: 1, // Occurs each
  positions: [], // Hold the days of the week, month, year
  lastReviewedDate: '', // Stores the latest review date
  startReviewDate: '', // Snooze the tracker by setting a date in the future
  updatedAt: ''
};

export const getReviewCycleStruct = (doc = ReviewCycleStruct) =>
  Object.assign({}, ReviewCycleStruct, doc, {
    positions: [...(doc.positions || [])],
    lastReviewedDate: doc.lastReviewedDate || moment().format(),
    startReviewDate: doc.startReviewDate || moment().format()
  });
