module.exports = () => ({
  container: {
    flex: '1 1 auto'
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  card: {
    marginRight: '17px'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardBase: {
    marginTop: 19
  },
  grid: {
    width: 'auto',
    display: 'flex',
    marginLeft: '-1em'
  },
  gridColumn: {
    paddingLeft: '1em',
    backgroundClip: 'padding-box',
    '& > div': {
      marginBottom: '1em'
    }
  },
  cardHeader: {
    paddingTop: '8px',
    paddingBottom: '8px',
    fontWeight: 'bold',
    fontSize: '14px'
  },
  group: {
    marginTop: 23
  },
  radioLabel: {
    borderBottom: '1px solid #e0e0e0'
  },
  itemTitle: {
    marginTop: 15,
    fontSize: '17px',
    fontWeight: 500,
    letterSpacing: 0,
    color: '#393939'
  },
  paddingBottom10: {
    paddingBottom: 10
  },
  heading: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.44,
    letterSpacing: 0,
    color: '#393939'
  },
  headingIn: {
    paddingLeft: '10px',
    fontSize: '15px',
    fontWeight: 300,
    letterSpacing: 0,
    color: '#393939'
  },
  listItem: {
    marginTop: 30
  },
  input: {
    color: '#393939',
    flex: 1,
    width: '100%'
  },
  textField: {
    fontSize: 12,
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    width: '100%'
  },
  infoTooltip: {
    fontSize: '1rem',
    textAlign: 'center'
  },
  infoPopper: {
    padding: 10,
    margin: 10
  },
  activeCommitments: {
    overflow: 'visible'
  },
  success: {
    backgroundColor: '#a2dedc',
    color: '#fff'
  },
  learn: {
    backgroundColor: '#f4cf69',
    color: '#fff'
  },
  plan: {
    backgroundColor: '#fd7a6c',
    color: '#fff'
  },
  progress: {
    backgroundColor: '#9fdd9c',
    color: '#fff'
  },
  notes: {
    minHeight: 150,
    marginTop: 18,
    marginBottom: 18,
    padding: 16,
    backgroundColor: '#fffbdd'
  },
  notesLabel: {
    fontSize: 11,
    fontWeight: 'bold',
    letterSpacing: 0.5,
    color: '#5d7bb0'
  },
  buttonWrap: {
    marginTop: '1em'
  },
  override: {
    display: 'inherit'
  },
  listPadding: {
    padding: 0,
    minHeight: 48
  },
  lessPadding: {
    paddingTop: 0,
    paddingBottom: 0
  },
  noPadding: {
    padding: 0,
    minHeight: '3em',
    display: 'block',
    marginBottom: '1em'
  }
});
