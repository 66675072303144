import { API, graphqlOperation } from 'aws-amplify';
import {
  postStripePayment,
  postStripeCancel, postStripeInfo,
  postStripeInquiry, postStripeChange
} from '../../graphql/mutations';

/*
 *
 * Stripe Functions
 *
 */
export const postSubscription = async (user, id) => {
  const {
    data: { postStripePayment: response }
  } = await API.graphql(
    graphqlOperation(postStripePayment, {
      data: {
        command: 'subscribe',
        key: id,
        name: `${user.attributes.givenName} ${user.attributes.familyName}`,
        email: user.attributes.email,
        subscriptionPlanId: user.activeSubscription.stripePlanId
      }
    })
  );
  
  return typeof response === 'string' ? JSON.parse(response) : response;
};

export const postSubscriptionChange = async (subscriptionId, subscriptionPlanId) => {
  const {
    data: { postStripeChange: response }
  } = await API.graphql(
    graphqlOperation(postStripeChange, {
      data: {
        command: 'change',
        subscriptionId,
        subscriptionPlanId
      }
    })
  );
  
  return typeof response === 'string' ? JSON.parse(response) : response;
};

export const fetchSubscription = async subscriptionId => {
  const {
    data: { postStripeInquiry: response }
  } = await API.graphql(
    graphqlOperation(postStripeInquiry, {
      data: {
        command: 'retrieve',
        subscriptionId
      }
    })
  );
  
  return typeof response === 'string' ? JSON.parse(response) : response;
};

export const cancelSubscription = async subscriptionId => {
  const {
    data: { postStripeCancel: response }
  } = await API.graphql(
    graphqlOperation(postStripeCancel, {
      data: {
        command: 'cancel',
        subscriptionId
      }
    })
  );
  
  return typeof response === 'string' ? JSON.parse(response) : response;
};

export const getInfo = async () => {
  const {
    data: { postStripeInfo: response }
  } = await API.graphql(
    graphqlOperation(postStripeInfo, {
      data: {
        command: 'info'
      }
    })
  );
  
  return typeof response === 'string' ? JSON.parse(response) : response;
};
