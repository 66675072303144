export const DIAGNOSTICS_TRIGGER_ERROR_ACTION = 'DIAGNOSTICS_TRIGGER_ERROR';
export const DIAGNOSTICS_HANDLE_ERROR_ACTION = 'DIAGNOSTICS_HANDLE_ERROR';

export function triggerDiagnosticError() {
  return { type: DIAGNOSTICS_TRIGGER_ERROR_ACTION };
}

export function handleDiagnosticError(error) {
  return { type: DIAGNOSTICS_HANDLE_ERROR_ACTION, payload: error };
}
