import {
  ADD_LOCAL_MILESTONE,
  SET_MILESTONES,
  REMOVE_LOCAL_MILESTONE,
  SAVE_LOCAL_MILESTONE,
  UPSERT_LOCAL_MILESTONE
} from '../actions/milestones';

export default function activeMilestonesReducer(oldstate = [], action) {
  switch (action.type) {
    case ADD_LOCAL_MILESTONE:
      return [...oldstate, action.payload];

    case SET_MILESTONES:
      return action.payload;

    case REMOVE_LOCAL_MILESTONE: {
      return oldstate.filter(item => item.id !== action.payload.id);
    }

    case SAVE_LOCAL_MILESTONE:
      return oldstate.map(item => {
        if (action.payload.id === item.id) {
          return {
            commitments: item.commitments,
            ...action.payload
          };
        }
        return item;
      });

    case UPSERT_LOCAL_MILESTONE:
      if (!action.payload) return oldstate;
      if (!action.payload.id) return oldstate;

      if (
        oldstate.filter(
          milestone => action.payload.id === milestone.id && milestone.id
        ).length
      )
        return oldstate.map(item => {
          if (action.payload.id === item.id) {
            return {
              commitments: item.commitments,
              ...action.payload
            };
          }
          return item;
        });
      return [...oldstate, action.payload];

    default:
      return oldstate;
  }
}
