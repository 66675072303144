import {API, graphqlOperation} from 'aws-amplify';
import moment from 'moment';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import {stringifyApn} from './apn/v2';
import {COMMITMENT_STATUS_PENDING, getCommitmentStruct} from '../structs/commitments';
import {getChangeLogStruct} from '../structs/changeLogs';
import {getChecklistStruct} from '../structs/checklists';

/*
 *
 * Change Log Functions
 *
 */
export const fetchRemoteChangeLogs = async (ownerApn, timestamp) => {
  if (timestamp) {
    const GQL = await API.graphql(
      graphqlOperation(queries.changeLogsByOwnerv2, {
        ownerApn,
        timestamp: {
          gt: timestamp
        },
        limit: 100,
        sortDirection: 'ASC'
      })
    );

    return GQL.data.changeLogsByOwnerv2.items;
  }
  return [];
};

export const createRemoteChangeLog = async changeLog => {
  const {
    data: { getChangeLogv2: remoteChangeLog }
  } = await API.graphql(
    graphqlOperation(queries.getChangeLogv2, {
      changeId: changeLog.changeId,
      ownerApn: changeLog.ownerApn
    })
  );

  const input = prepAndPruneChangeLogForAppSync(changeLog);
  const GQL = await API.graphql(
    graphqlOperation(
      remoteChangeLog
        ? mutations.updateChangeLogv2
        : mutations.createChangeLogv2,
      {
        input
      }
    )
  );
  return GQL.data[remoteChangeLog ? 'updateChangeLogv2' : 'createChangeLogv2'];
};

/*
 *
 * Commitment Functions
 *
 */
export const fetchRemoteCommitmentMostRecent = async userId => {
  const GQL = await API.graphql(
    graphqlOperation(queries.fetchCommitmentsByLatestDatev3, {
      userId,
      limit: 1,
      sortDirection: 'DESC'
    })
  );

  return postFetchProcessCommitment(
    GQL.data.fetchCommitmentsByLatestDatev3.items.pop()
  );
};

export const fetchRemoteCommitmentsSince = async (userId, date) => {
  let allCommitments = [];
  let whileNextToken = 'initial';

  /* eslint-disable no-await-in-loop */
  while (whileNextToken) {
    const GQL = await API.graphql(
      graphqlOperation(queries.fetchCommitmentsByLatestDatev3, {
        userId,
        limit: 1,
        updatedAt: {
          gt: date
        },
        sortDirection: 'ASC'
      })
    );
    allCommitments = allCommitments.concat(
      GQL.data.fetchCommitmentsByLatestDatev3.items
    );
    whileNextToken = GQL.data.fetchCommitmentsByLatestDatev3.nextToken;
  }
  /* eslint-enable no-await-in-loop */

  return allCommitments.length
    ? allCommitments.map(commitment => postFetchProcessCommitment(commitment))
    : [];
};

export const fetchRemoteCommitments = userId =>
  new Promise(async resolve => {
    let allCommitments = [];
    let whileNextToken = 'initial';

    /* eslint-disable no-await-in-loop */
    while (whileNextToken && allCommitments.length < 200) {
      const GQL = await API.graphql(
        graphqlOperation(queries.fetchCommitmentsByUserv3, {
          userId,
          limit: 100,
          sortDirection: 'DESC',
          nextToken: whileNextToken === 'initial' ? null : whileNextToken
        })
      );
      allCommitments = allCommitments.concat(
        GQL.data.fetchCommitmentsByUserv3.items
      );
      whileNextToken = GQL.data.fetchCommitmentsByUserv3.nextToken;
    }
    /* eslint-enable no-await-in-loop */

    resolve(
      allCommitments.length
        ? allCommitments.map(commitment =>
            postFetchProcessCommitment(commitment)
          )
        : []
    );
  });

export const fetchRemoteCommitmentsStillPending = userId =>
  new Promise(async resolve => {
    let allCommitments = [];
    let whileNextToken = 'initial';

    /* eslint-disable no-await-in-loop */
    while (whileNextToken && allCommitments.length < 200) {
      const GQL = await API.graphql(
        graphqlOperation(queries.fetchCommitmentsByUserv3, {
          userId,
          limit: 1000,
          sortDirection: 'DESC',
          filter: {
            status: {
              eq: COMMITMENT_STATUS_PENDING
            }
          },
          nextToken: whileNextToken === 'initial' ? null : whileNextToken
        })
      );
      allCommitments = allCommitments.concat(
        GQL.data.fetchCommitmentsByUserv3.items
      );
      whileNextToken = GQL.data.fetchCommitmentsByUserv3.nextToken;
    }
    /* eslint-enable no-await-in-loop */

    resolve(
      allCommitments.length
        ? allCommitments.map(commitment =>
            postFetchProcessCommitment(commitment)
          )
        : []
    );
  });

export const fetchRemoteCommitmentsForToday = userId =>
  new Promise(async resolve => {
    let allCommitments = [];
    let whileNextToken = 'initial';

    /* eslint-disable no-await-in-loop */
    while (whileNextToken && allCommitments.length < 200) {
      const GQL = await API.graphql(
        graphqlOperation(queries.fetchCommitmentsByUserv3, {
          userId,
          date: {
            ge: moment()
              .startOf('day')
              .format()
          },
          limit: 1000,
          sortDirection: 'DESC',
          nextToken: whileNextToken === 'initial' ? null : whileNextToken
        })
      );
      allCommitments = allCommitments.concat(
        GQL.data.fetchCommitmentsByUserv3.items
      );
      whileNextToken = GQL.data.fetchCommitmentsByUserv3.nextToken;
    }
    /* eslint-enable no-await-in-loop */

    resolve(
      allCommitments.length
        ? allCommitments.map(commitment =>
            postFetchProcessCommitment(commitment)
          )
        : []
    );
  });

export const upsertRemoteCommitment = async commitment => {
  // We fetch the latest from the API prior to sending it in, for some DynamoDB conditional checks
  const remoteCommitment = await fetchRemoteCommitment(
    commitment.userId,
    commitment.id
  );

  if (!remoteCommitment.id) {
    const input = prepAndPruneCommitmentForAppSync(
      Object.assign({}, remoteCommitment, commitment)
    );

    const GQL = await API.graphql(
      graphqlOperation(mutations.createCommitmentv3, {
        input
      })
    );

    return postFetchProcessCommitment(GQL.data.createCommitmentv3);
  }

  // If the local is behind the remote, then
  if (moment(remoteCommitment.updatedAt).isSameOrAfter(commitment.updatedAt)) {
    // No need to send the Commitment, rather update the local
    return postFetchProcessCommitment(remoteCommitment);
  }

  const input = prepAndPruneCommitmentForAppSync(
    Object.assign({}, remoteCommitment, commitment)
  );

  const GQL = await API.graphql(
    graphqlOperation(mutations.updateCommitmentv3, {
      input
    })
  );

  return postFetchProcessCommitment(GQL.data.updateCommitmentv3);
};

export const fetchRemoteCommitment = async (userId, id) => {
  const GQL = await API.graphql(
    graphqlOperation(queries.getCommitmentv3, {
      userId,
      id
    })
  );

  if (!GQL.data.getCommitmentv3) return getCommitmentStruct();
  return postFetchProcessCommitment(GQL.data.getCommitmentv3);
};

export const removeRemoteCommitment = async commitment => {
  await API.graphql(
    graphqlOperation(mutations.deleteCommitmentv3, {
      input: { id: commitment.id, userId: commitment.userId }
    })
  ).catch(err => console.log(err));
};

/*
 *
 * Checklist Functions
 *
 */
export const fetchRemoteChecklists = async userId => {
  let allChecklists = [];
  let whileNextToken = 'initial';

  /* eslint-disable no-await-in-loop */
  while (whileNextToken) {
    const GQL = await API.graphql(
      graphqlOperation(queries.listChecklists, {
        ownerApn: stringifyApn({ userId }),
        limit: 500,
        nextToken: whileNextToken === 'initial' ? null : whileNextToken
      })
    );
    allChecklists = allChecklists.concat(GQL.data.listChecklists.items);
    whileNextToken = GQL.data.listChecklists.nextToken;
  }
  /* eslint-enable no-await-in-loop */

  return allChecklists.length
    ? allChecklists.map(checklist => postFetchProcessChecklist(checklist))
    : [];
};

export const upsertRemoteChecklist = async checklist => {
  // We fetch the latest from the API prior to sending it in, for some DynamoDB conditional checks
  const remoteChecklist = await fetchRemoteChecklist(
    checklist.ownerApn,
    checklist.id
  );

  const input = prepAndPruneChecklistForAppSync(
    Object.assign({}, remoteChecklist, checklist)
  );

  if (remoteChecklist.id) {
    const GQL = await API.graphql(
      graphqlOperation(mutations.updateChecklist, {
        input
      })
    );

    return postFetchProcessChecklist(GQL.data.updateChecklist);
  }

  const GQL = await API.graphql(
    graphqlOperation(mutations.createChecklist, {
      input
    })
  );

  return postFetchProcessChecklist(GQL.data.createChecklist);
};

export const fetchRemoteChecklist = async (ownerApn, id) => {
  const GQL = await API.graphql(
    graphqlOperation(queries.getChecklist, {
      ownerApn,
      id
    })
  );

  if (!GQL.data.getChecklist) return getChecklistStruct();
  return postFetchProcessChecklist(GQL.data.getChecklist);
};

export const removeRemoteChecklist = async checklist => {
  await API.graphql(
    graphqlOperation(mutations.deleteChecklist, {
      input: { id: checklist.id, ownerApn: checklist.ownerApn }
    })
  ).catch(err => console.log(err));
};

/*
 *
 * Pre / Post Process Functions
 *
 */

// Pass fetched value through the Struct, never trust input. :)
// Reformat Commitment (If needed)
const postFetchProcessCommitment = commitment => getCommitmentStruct(commitment);

const postFetchProcessChecklist = checklist => getChecklistStruct(checklist);

export const prepAndPruneChangeLogForAppSync = changeLog => {
  // Prune any keys that are not in the schema
  const keys = Object.keys(getChangeLogStruct());
  const pruned = getChangeLogStruct(changeLog);

  const prunedKeys = Object.keys(pruned);
  const propertiesToPrune = prunedKeys.filter(key => !keys.includes(key));
  if (propertiesToPrune.length)
    propertiesToPrune.map(key => delete pruned[key]);

  // Prep any empty strings
  keys.map(key => {
    if (typeof pruned[key] === 'string' && pruned[key] === '') {
      pruned[key] = ' ';
    }
    return true;
  });

  // Stringify Data Object
  pruned.data = JSON.stringify(pruned.data);

  return pruned;
};

export const prepAndPruneCommitmentForAppSync = commitment => {
  // Prune any keys that are not in the schema
  const commitmentKeys = Object.keys(getCommitmentStruct());
  const pruned = getCommitmentStruct(commitment);
  const prunedKeys = Object.keys(pruned);
  const propertiesToPrune = prunedKeys.filter(
    key => !commitmentKeys.includes(key)
  );
  if (propertiesToPrune.length)
    propertiesToPrune.map(key => delete pruned[key]);

  // Prep any empty strings
  commitmentKeys.map(key => {
    if (typeof pruned[key] === 'string' && pruned[key] === '') {
      pruned[key] = ' ';
    }
    return true;
  });

  // Ensure that the commitment dates are formatted correctly
  pruned.date = moment(pruned.date).format();
  pruned.updatedAt = moment(pruned.updatedAt).format();
  pruned.originalDate = moment(pruned.originalDate).format();

  return pruned;
};

export const prepAndPruneChecklistForAppSync = checklist => {
  // Prune any keys that are not in the schema
  const checklistKeys = Object.keys(getChecklistStruct());
  const pruned = getChecklistStruct(checklist);
  const prunedKeys = Object.keys(pruned);
  const propertiesToPrune = prunedKeys.filter(
    key => !checklistKeys.includes(key)
  );
  if (propertiesToPrune.length)
    propertiesToPrune.map(key => delete pruned[key]);

  // Prep any empty strings
  checklistKeys.map(key => {
    if (typeof pruned[key] === 'string' && pruned[key] === '') {
      pruned[key] = ' ';
    }
    return true;
  });

  return pruned;
};

export const prepAndPruneForAppSync = (item, struct) => {
  let getStruct = struct;
  // Prune any keys that are not in the schema
  if (typeof struct !== 'function') {
    getStruct = (doc = struct) => ({...struct,...doc});
  }
  const itemKeys = Object.keys(getStruct());
  const pruned = getStruct(item);
  const prunedKeys = Object.keys(pruned);
  const propertiesToPrune = prunedKeys.filter(
    key => !itemKeys.includes(key)
  );
  if (propertiesToPrune.length)
    propertiesToPrune.map(key => delete pruned[key]);

  // Prep any empty strings
  itemKeys.map(key => {
    if (typeof pruned[key] === 'string' && pruned[key] === '') {
      pruned[key] = ' ';
    }
    return true;
  });

  return pruned;
};
