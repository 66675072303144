import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import { getLearnItemType, getLearnPageStruct } from '../../structs/learn';

export const style = () => ({
  input: {
    color: '#393939',
    flex: 1,
    width: '100%'
  },
  textField: {
    fontSize: 12,
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    width: '100%'
  }
});

class LearnPageSelector extends Component {
  state = {
    selectedPage: '',
    newPage: getLearnPageStruct()
  };

  componentDidMount() {
    const { selected } = this.props;
    const { selectedPage } = this.state;

    if (selected && selected !== selectedPage) {
      this.setSelected(selected);
    }
  }

  setSelected = selected => {
    this.setState({ selectedPage: selected });
  };

  handleAdd = () => {
    const { addLearnPage } = this.props;
    const { newPage } = this.state;
    addLearnPage(
      getLearnPageStruct({
        title: newPage.title,
        parts: [
          getLearnItemType({
            item: {
              type: 'heading',
              item: {
                text: 'Page Heading'
              }
            }
          })
        ]
      }),
      this.handleAddAfter
    );
  };

  handleAddAfter = newPage => {
    const { onChange } = this.props;
    this.setState({ selectedPage: newPage.id });
    onChange(newPage.id);
  };

  handlePageChoice = event => {
    const { onChange } = this.props;
    this.setState({ selectedPage: event.target.value });
    onChange(event.target.value);
  };

  handleChange = event => {
    const { newPage } = this.state;
    newPage[event.target.name] = event.target.value;
    this.setState({ newPage });
  };

  render() {
    const { learnPages, classes } = this.props;
    const { selectedPage, newPage } = this.state;

    return (
      <div>
        <FormControl>
          <Select
            value={selectedPage}
            onChange={this.handlePageChoice}
            displayEmpty
            name="selectedPage"
          >
            <MenuItem key="none" value="">
              <em>New Page</em>
            </MenuItem>
            {learnPages.length
              ? learnPages.map(page => (
                  <MenuItem key={page.id} value={page.id}>
                    {page.title ? page.title : 'No Title'}
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>
        {!selectedPage ? (
          <Card>
            <CardHeader title="Add New Page" />
            <CardContent>
              <TextField
                id="title"
                name="title"
                value={newPage.title === ' ' ? '' : newPage.title}
                placeholder="Page Title"
                required
                multiline
                margin="normal"
                className={classes.textField}
                InputProps={{
                  className: classes.input
                }}
                onChange={this.handleChange}
              />
            </CardContent>
            <CardActions>
              <Button
                style={{ marginLeft: 'auto' }}
                color="primary"
                variant="contained"
                onClick={this.handleAdd}
              >
                Save Page
              </Button>
            </CardActions>
          </Card>
        ) : null}
      </div>
    );
  }
}

LearnPageSelector.defaultProps = {
  selected: ''
};

LearnPageSelector.propTypes = {
  classes: PropTypes.any.isRequired,
  selected: PropTypes.any,
  learnPages: PropTypes.any.isRequired,
  addLearnPage: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default withStyles(style)(LearnPageSelector);
