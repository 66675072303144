import React, { Component } from 'react';
import { connect } from 'react-redux';
import MilestoneList from '../../components/Tracker/Milestone/List';
import {
  addMilestoneAction,
  removeMilestoneAction,
  updateMilestoneAction
} from '../../redux/actions/milestones';

export class MilestoneListContainer extends Component {
  render() {
    return <MilestoneList />;
  }
}

const mapStateToProps = state => ({
  activeMilestones: state.activeMilestones,
  milestonesById: state.milestoneIndex.byId
});

const mapDispatchToProps = dispatch => ({
  addMilestone: (milestone, tracker) => {
    dispatch(addMilestoneAction(milestone, tracker));
  },
  removeMilestone: async milestone => {
    dispatch(removeMilestoneAction(milestone));
  },
  updateMilestone: milestone => {
    dispatch(updateMilestoneAction(milestone));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MilestoneList);
