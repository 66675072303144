import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { getMetricStruct } from '../../../structs/measure';
import MeasureMetric from '../../Measure/Metric';

export const style = () => ({});

class TrackerMeasureMetrics extends Component {
  state = { metrics: [] };

  componentDidMount() {
    const { tracker } = this.props;
    const { metrics } = this.state;

    if (tracker.metrics !== metrics) {
      this.hydrateState(tracker.metrics);
    }
  }

  componentDidUpdate() {
    const { tracker } = this.props;
    const { metrics } = this.state;

    if (tracker.metrics !== metrics) {
      this.hydrateState(tracker.metrics);
    }
  }

  hydrateState = metrics => {
    this.setState({ metrics });
  };

  handleItemChange = itemToChange => {
    const { metrics } = this.state;
    const { onChange } = this.props;
    const newMetrics = this.reSequenceItems(
      metrics.map(item => {
        if (item.id === itemToChange.id) {
          return itemToChange;
        }
        return item;
      })
    );
    this.setState({
      metrics: newMetrics
    });
    // Persist to Tracker onChange
    onChange(newMetrics);
  };

  handleAdd = type => () => {
    this.addMetric({ type });
  };

  addMetric = itemToAdd => {
    const { metrics } = this.state;
    const { onChange } = this.props;

    metrics.push(getMetricStruct(itemToAdd));

    onChange(this.reSequenceItems(metrics));
  };

  handleItemRemove = itemToRemove => {
    const { metrics } = this.state;
    const { onChange } = this.props;

    const newMetrics = metrics.filter(item => item.id !== itemToRemove.id);

    onChange(this.reSequenceItems(newMetrics));
  };

  reSequenceItems = items => {
    let seq = 1;
    const list = items.map(item => {
      const newItem = item;
      newItem.seq = seq;
      seq += 1;
      return newItem;
    });
    return list;
  };

  render() {
    const { readOnly } = this.props;
    const { metrics } = this.state;

    return (
      <List>
        {metrics.length
          ? metrics.map(item => (
              <MeasureMetric
                key={item.id}
                item={item}
                readOnly={readOnly}
                onChange={this.handleItemChange}
                onDelete={this.handleItemRemove}
              />
            ))
          : null}
        {!readOnly && (
          <ListItem>
            +
            <Button size="small" onClick={this.handleAdd('number')}>
              Number
            </Button>
          </ListItem>
        )}
      </List>
    );
  }
}

TrackerMeasureMetrics.propTypes = {
  tracker: PropTypes.any.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default withStyles(style)(TrackerMeasureMetrics);
