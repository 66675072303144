import {
  SET_LOCAL_TRACKERS,
  SAVE_LOCAL_TRACKER,
  REMOVE_LOCAL_TRACKER,
  ADD_LOCAL_TRACKER,
  UPSERT_LOCAL_TRACKER
} from '../actions/trackers';

export default function trackerReducer(oldstate = [], action) {
  let trackers = [];

  switch (action.type) {
    case ADD_LOCAL_TRACKER:
      return [...oldstate, action.payload];

    case SET_LOCAL_TRACKERS:
      trackers = action.payload;
      return trackers;

    case SAVE_LOCAL_TRACKER:
      trackers = oldstate.map(existingTracker => {
        if (existingTracker.id === action.payload.id) {
          return action.payload;
        }
        return existingTracker;
      });
      return trackers;

    case UPSERT_LOCAL_TRACKER:
      if (!action.payload) return oldstate;
      if (!action.payload.id) return oldstate;
      if (
        oldstate.filter(
          tracker => action.payload.id === tracker.id && tracker.id
        ).length
      )
        return oldstate.map(tracker => {
          if (action.payload.id === tracker.id) {
            return {
              milestones: tracker.milestones,
              ...action.payload
            };
          }
          return tracker;
        });

      return [...oldstate, action.payload];

    case REMOVE_LOCAL_TRACKER:
      trackers = oldstate.filter(
        existingTracker => existingTracker.id !== action.payload.id
      );
      return trackers;

    default:
      return oldstate;
  }
}
