
export const CommitmentTrayStruct = {
  schemaVersion: '1.0.0',
  id: '',
  ownerApn: '',
  name: '',
  description: ''
};

export const getCommitmentTrayStruct = (tray = CommitmentTrayStruct) => ({
  ...CommitmentTrayStruct,
  ...tray
});