import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {TRACKER_STATUS_ACTIVE} from '../../../structs/trackers';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import CardHeader from '@material-ui/core/CardHeader';
import ShareButton from '../Actions/ShareButton';
import {isReadOnly} from '../../../models/trackerShare';
import CardActions from "@material-ui/core/CardActions";
import ShareItem from "./Item";

const ShareList = ({trackerDoc: { entity: tracker, share}, classes, isOwner}) => {
  const allShares = useSelector(store => store.trackerShares);

  if (!tracker.id) return null;

  const trackerShares = allShares.filter(share => share.trackerId === tracker.id);
  const readOnly = isReadOnly(share);
  
  return tracker.status === TRACKER_STATUS_ACTIVE ? (
    <Card elevation={0} style={{marginBottom: '1em'}}>
      <CardHeader
        classes={{ root: classes.cardHeader }}
        className={classes.progress}
        title="Sharing"
        disableTypography
      />
      <List>
        {trackerShares.map(item => (
          <ShareItem key={item.id} item={item} readOnly={readOnly} isOwner={isOwner} />
        ))}
      </List>
      {(!readOnly && isOwner) && (
        <CardActions>
          <ShareButton tracker={tracker} />
        </CardActions>
      )}
    </Card>
  ) : null;
};

ShareList.defaultProps = {};

ShareList.propTypes = {
  trackerDoc: PropTypes.object.isRequired
};

export default ShareList;
