import {API, graphqlOperation} from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import {
  getTrackerShareStruct,
  TRACKER_SHARE_STATUS_ACTIVE,
  TRACKER_SHARE_STATUS_PENDING
} from '../../structs/trackerShare';

/*
 *
 * TrackerShare Functions
 *
 */
export const fetchRemoteTrackerSharesActiveByUser = async userId =>
  new Promise(async resolve => {
    let allTrackerShares = [];
    let whileNextToken = 'initial';
    
    /* eslint-disable no-await-in-loop */
    while (whileNextToken && allTrackerShares.length < 100) {
      const GQL = await API.graphql(
        graphqlOperation(queries.trackerSharesByUserId, {
          userId: userId,
          status: {
            eq: TRACKER_SHARE_STATUS_ACTIVE
          },
          limit: 1000,
          sortDirection: 'DESC',
          nextToken: whileNextToken === 'initial' ? null : whileNextToken
        })
      );
      allTrackerShares = allTrackerShares.concat(GQL.data.trackerSharesByUserId.items);
      whileNextToken = GQL.data.trackerSharesByUserId.nextToken;
    }
    /* eslint-enable no-await-in-loop */
    
    resolve(
      allTrackerShares.length
        ? allTrackerShares.map(trackerShare => postFetchProcessTrackerShare(trackerShare))
        : []
    );
  });

export const fetchRemoteTrackerSharesPendingByUser = async emailAddress =>
  new Promise(async resolve => {
    let allTrackerShares = [];
    let whileNextToken = 'initial';
    
    /* eslint-disable no-await-in-loop */
    while (whileNextToken && allTrackerShares.length < 100) {
      const GQL = await API.graphql(
        graphqlOperation(queries.trackerSharesByUserId, {
          userId: ' ',
          status: {
            eq: TRACKER_SHARE_STATUS_PENDING
          },
          emailAddress: {
            eq: emailAddress
          },
          limit: 1000,
          sortDirection: 'DESC',
          nextToken: whileNextToken === 'initial' ? null : whileNextToken
        })
      );
      allTrackerShares = allTrackerShares.concat(GQL.data.trackerSharesByUserId.items);
      whileNextToken = GQL.data.trackerSharesByUserId.nextToken;
    }
    /* eslint-enable no-await-in-loop */
    
    resolve(
      allTrackerShares.length
        ? allTrackerShares.map(trackerShare => postFetchProcessTrackerShare(trackerShare))
        : []
    );
  });


export const fetchRemoteTrackerSharesByTracker = async tracker =>
  new Promise(async resolve => {
    let allTrackerShares = [];
    let whileNextToken = 'initial';
    
    /* eslint-disable no-await-in-loop */
    while (whileNextToken && allTrackerShares.length < 100) {
      const GQL = await API.graphql(
        graphqlOperation(queries.listTrackerShares, {
          trackerId: tracker.id,
          limit: 1000,
          sortDirection: 'DESC',
          nextToken: whileNextToken === 'initial' ? null : whileNextToken
        })
      );
      allTrackerShares = allTrackerShares.concat(GQL.data.listTrackerShares.items);
      whileNextToken = GQL.data.listTrackerShares.nextToken;
    }
    /* eslint-enable no-await-in-loop */
    
    resolve(
      allTrackerShares.length
        ? allTrackerShares.map(trackerShare => postFetchProcessTrackerShare(trackerShare))
        : []
    );
  });

export const upsertRemoteTrackerShare = async trackerShare => {
  let remoteTrackerShare = {};
  // We fetch the latest from the API prior to sending it in, for some DynamoDB conditional checks
  if (trackerShare.id)
    remoteTrackerShare = await fetchRemoteTrackerShare(trackerShare.trackerId, trackerShare.id);
  
  if (!remoteTrackerShare.id) {
    const input = prepAndPruneTrackerShareForAppSync(
      Object.assign({}, remoteTrackerShare, trackerShare)
    );
    
    delete input.id;
    
    const GQL = await API.graphql(
      graphqlOperation(mutations.createTrackerShare, {
        input
      })
    );
    return postFetchProcessTrackerShare(GQL.data.createTrackerShare);
  }
  
  const input = prepAndPruneTrackerShareForAppSync(
    Object.assign({}, remoteTrackerShare, trackerShare)
  );
  
  const GQL = await API.graphql(
    graphqlOperation(mutations.updateTrackerShare, {
      input
    })
  );
  return postFetchProcessTrackerShare(GQL.data.updateTrackerShare);
};

export const fetchRemoteTrackerShare = async (trackerId, id) => {
  const GQL = await API.graphql(
    graphqlOperation(queries.getTrackerShare, {
      id,
      trackerId
    })
  );
  
  if (!GQL.data.getTrackerShare) return getTrackerShareStruct();
  
  return postFetchProcessTrackerShare(GQL.data.getTrackerShare);
};

export const deleteRemoteTrackerShare = async trackerShare => {
  await API.graphql(
    graphqlOperation(mutations.deleteTrackerShare, {
      input: { id: trackerShare.id, trackerId: trackerShare.trackerId }
    })
  ).catch(err => console.log(err));
};

const postFetchProcessTrackerShare = trackerShare => {
  // Pass fetched value through the Struct, never trust input. :)
  return getTrackerShareStruct(trackerShare);
};

export const prepAndPruneTrackerShareForAppSync = trackerShare => {
  // Prune any keys that are not in the schema
  const keys = Object.keys(getTrackerShareStruct());
  const pruned = getTrackerShareStruct(trackerShare);
  const prunedKeys = Object.keys(pruned);
  const propertiesToPrune = prunedKeys.filter(key => !keys.includes(key));
  if (propertiesToPrune.length)
    propertiesToPrune.map(key => delete pruned[key]);
  
  // Prep any empty strings
  keys.map(key => {
    if (typeof pruned[key] === 'string' && pruned[key] === '') {
      pruned[key] = ' ';
    }
    return true;
  });
  
  delete pruned.tracker;
  
  return pruned;
};
