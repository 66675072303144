import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import routes from '../../constants/routes.json';
import {
  UserStruct,
  USER_STATUS_LOGGED_IN,
  USER_STATUS_CONFIRMED
} from '../../structs/user';
import Style from './Style';
import { INTERNET_STATUS_CONNECTED } from '../../structs/notification';
import Trademarked from '../Ui/Trademarked';

class Login extends Component {
  state = {
    username: '',
    user: undefined,
    password: ''
  };

  componentDidMount() {
    this.updateUser();
    const { setInternetStatus } = this.props;
    setInternetStatus(INTERNET_STATUS_CONNECTED);
  }

  componentDidUpdate() {
    this.updateUser();
  }

  componentWillUnmount() {
    clearInterval(this.stepTimer);
  }

  updateUser = () => {
    const { user, history } = this.props;
    if (
      user.status === USER_STATUS_LOGGED_IN ||
      user.status === USER_STATUS_CONFIRMED
    ) {
      history.replace(routes.WELCOME);
    }
  };

  stepTimer = null;

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.onSignIn();
    }
  };

  onSignIn = () => {
    const { signIn, setInternetStatus } = this.props;
    const { username, password } = this.state;
    const userCredentials = {
      username,
      password
    };
    signIn(userCredentials);
    setInternetStatus(INTERNET_STATUS_CONNECTED);
  };

  handleRegister = () => {
    this.props.history.push(routes.REGISTER);
  };

  handleResetPassword = () => {
    this.props.history.push(routes.RESET_PASSWORD);
  };

  render() {
    const { classes, user } = this.props;
    const { username, password } = this.state;

    // If we already have a user record.
    const greeting =
      user.id === '' ? (
        <Trademarked>Formigio</Trademarked>
      ) : (
        <div>Sign in to your <Trademarked>Formigio</Trademarked> Account</div>
      );

    const waitAction = (
      <Typography variant="h5" color="secondary">
        Loading ...
      </Typography>
    );

    const loginAction = (
      <div>
        <TextField
          id="standard-name"
          label="Username"
          autoFocus
          fullWidth={true}
          className={classes.textField}
          InputProps={{
            className: classes.input
          }}
          value={username}
          onChange={this.handleChange('username')}
          margin="normal"
        />
        <TextField
          id="password"
          label="Password"
          type="password"
          fullWidth={true}
          className={classes.textField}
          InputProps={{
            className: classes.input
          }}
          value={password}
          onChange={this.handleChange('password')}
          onKeyPress={this.handleKeyPress}
          margin="normal"
        />
        <div style={{ marginTop: 20 }}>
          <Fade in>
            <Button
              variant="contained"
              fullWidth={true}
              disabled={!username || !password}
              color="primary"
              onClick={this.onSignIn}
            >
              Sign In
            </Button>
          </Fade>
        </div>
        <div style={{ marginTop: 20 }}>
          <Fade in>
            <Button
              variant="outlined"
              fullWidth={true}
              color="primary"
              onClick={this.handleRegister}
            >
              Sign Up
            </Button>
          </Fade>
        </div>
        <div style={{ marginTop: 20 }}>
          <Fade in>
            <Button
              variant="text"
              fullWidth={true}
              color="primary"
              onClick={this.handleResetPassword}
            >
              Forgot Password
            </Button>
          </Fade>
        </div>
      </div>
    );

    return (
      <Grid item data-tid="container" xs={8} sm={6} md={4}>
        <div className={classes.heading}>
          <Fade in>
            <Typography
              variant="h5"
              color="secondary"
              style={{ marginBottom: 15 }}
            >
              {greeting}
            </Typography>
          </Fade>
          <Typography>Great to have you here. :)</Typography>
        </div>
        {this.state.user ? waitAction : loginAction}
      </Grid>
    );
  }
}

Login.defaultProps = {
  user: UserStruct
};

Login.propTypes = {
  signIn: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  history: PropTypes.object.isRequired,
  setInternetStatus: PropTypes.func.isRequired
};

export default withStyles(Style)(Login);
