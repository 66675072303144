import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  deleteLearnPagesAction,
  saveLearnPageAction
} from '../../redux/actions/learnPages';
import Page from '../../components/Learn/Page';

export class LearnPageContainer extends Component {
  render() {
    return <Page />;
  }
}

const mapStateToProps = state => ({
  learnPages: state.learnPages
});

const mapDispatchToProps = dispatch => ({
  saveLearnPage: page => {
    dispatch(saveLearnPageAction(page));
  },
  deleteLearnPage: id => {
    dispatch(deleteLearnPagesAction(id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Page);
