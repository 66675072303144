import moment from 'moment';

export const formatDateForPicker = date => {
  let convertDate = date;
  if (date && typeof date === 'string') {
    convertDate = new Date(date);
  } else if (typeof date === 'string') {
    convertDate = new Date();
  }
  const [month, day, year] = new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  })
    .format(convertDate)
    .split('/');
  return [year, month, day].join('-');
};

export const formatDateTimeForPicker = date => {
  let convertDate = date;
  if (date && typeof date === 'string') {
    convertDate = new Date(date);
  } else if (typeof date === 'string') {
    convertDate = new Date();
  }

  return moment(convertDate).format('YYYY-MM-DDTkk:mm');
};

export const convertPickerDateToDate = dateString => {
  const [year, month, day] = dateString.split('-');
  const convertedDate = new Date([month, day, year].join('/'));
  convertedDate.setHours('00');
  convertedDate.setMinutes('00');
  convertedDate.setSeconds('00');

  return moment(convertedDate);
};

export const convertPickerDateToDateTime = dateTimeString => {
  const convertedDate = new Date(dateTimeString);

  return convertedDate;
};

export const getDateFromString = (stringDate, midnight = false) => {
  const date = new Date(stringDate);
  if (midnight) {
    date.setHours('00');
    date.setMinutes('00');
    date.setSeconds('00');
  }

  return date;
};

export const getToday = (midnight = false) => {
  const date = new Date();
  if (midnight) {
    date.setHours('00');
    date.setMinutes('00');
    date.setSeconds('00');
  }

  return date;
};

export const getTomorrow = (midnight = false) => {
  const date = new Date();
  date.setDate(date.getDate() + 1);

  if (midnight) {
    date.setHours('00');
    date.setMinutes('00');
    date.setSeconds('00');
  }

  return date;
};

export const getHoursFromMinutes = (minutesVal = 0, short = false) => {
  const hours = Math.floor(minutesVal / 60);
  const minutes = minutesVal - Math.floor(minutesVal / 60) * 60;
  let hoursLabel = hours > 1 ? ' hours' : ' hour';
  let minutesLabel = minutes > 1 ? ' mins' : ' min';
  if (short) {
    hoursLabel = hours > 1 ? 'hrs' : 'hr';
    minutesLabel = 'm';
  }

  if (minutesVal < 60) {
    return `${minutes}${minutesLabel}`;
  }

  if (minutesVal % 60 === 0) {
    return `${hours}${hoursLabel}`;
  }

  return `${hours}${hoursLabel}, ${minutes}${minutesLabel}`;
};

export const getTimeOfDayFromMinutes = minutesVal => {
  const displayTime = new Date(
    new Date(minutesVal * 60 * 1000).toLocaleString('en-US', {
      timeZone: 'GMT'
    })
  );

  return new Intl.DateTimeFormat('en-US', {
    hour12: true,
    hour: '2-digit',
    minute: '2-digit'
  }).format(displayTime);
};

export const get12HourFromDate = date => {
  if (date.getHours() >= 12) {
    return date.getHours() - 12;
  }
  return date.getHours();
};

export const getClockFromMilliseconds = (milliseconds, withSeconds = true) => {
  const increment = Number.isNaN(milliseconds) ? 0 : milliseconds;
  const today = getToday(true);
  today.setTime(today.getTime() + increment);

  if (withSeconds) {
    return new Intl.DateTimeFormat('en-US', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }).format(today);
  }

  return new Intl.DateTimeFormat('en-US', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit'
  }).format(today);
};

export const getDayTimeSteps = (increment = 900) => {
  function* timeStepGenerator() {
    let step = 0;
    yield step;
    while (step < 86400) {
      yield (step += increment);
    }
  }

  const gen = timeStepGenerator();

  const steps = [];

  let step = gen.next();

  while (!step.done) {
    steps.push(step.value);
    step = gen.next();
  }

  return steps;
};

export const isSameDate = (date1, date2) =>
  date1.getDate() === date2.getDate() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getFullYear() === date2.getFullYear();
