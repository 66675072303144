import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import ItemList from '../../containers/Checklists/List';
import routes from '../../constants/routes.json';
import { getChecklistStruct } from '../../structs/checklists';

export const style = () => ({
  listCard: {
    border: 'lightgray 1px solid',
    position: 'relative',
    paddingBottom: '3.25em'
  },
  cardHeader: {
    paddingBottom: 0
  },
  cardContent: {
    paddingTop: 0
  },
  cardSubheader: {
    fontSize: '.85rem',
    color: 'gray'
  },
  cardTitle: {
    fontSize: '1.5rem'
  },
  cardActions: {
    position: 'absolute',
    bottom: 0,
    right: 0
  }
});

class ListsCard extends Component {
  state = {
    list: getChecklistStruct()
  };

  componentDidMount() {
    const { list: listProp } = this.props;
    const { list } = this.state;

    if (list.updatedAt !== listProp.updatedAt) {
      this.hydrateState(listProp);
    }
  }

  componentDidUpdate() {
    const { list: listProp } = this.props;
    const { list } = this.state;

    if (list.updatedAt !== listProp.updatedAt) {
      this.hydrateState(listProp);
    }
  }

  hydrateState = list => {
    this.setState({ list });
  };

  handleChange = event => {
    const { list } = this.state;
    const { saveList } = this.props;

    list[event.target.name] = event.target.value;

    this.setState({ list });

    saveList(list);
  };

  handleDelete = () => {
    const { list } = this.state;
    const { deleteList } = this.props;
    deleteList(list);
  };

  handleFocus = () => {
    const { list, history } = this.props;
    history.push(`${routes.CHECKLISTS}/${list.id}`);
  };

  render() {
    const { classes } = this.props;
    const { list } = this.state;

    return (
      <Card elevation={0} className={classes.listCard}>
        <CardHeader
          title={
            <TextField
              name="name"
              onChange={this.handleChange}
              value={list.name}
              fullWidth
              InputProps={{
                disableUnderline: true,
                className: classes.cardTitle
              }}
            />
          }
          subheader={
            <TextField
              name="description"
              onChange={this.handleChange}
              placeholder="Add Description"
              value={list.description}
              multiline
              fullWidth
              InputProps={{
                disableUnderline: true,
                className: classes.cardSubheader
              }}
            />
          }
          className={classes.cardHeader}
        />
        <CardContent className={classes.cardContent}>
          <ItemList list={list} />
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button variant="text" color="secondary" onClick={this.handleFocus}>
            Focus
          </Button>
          <Button variant="text" color="secondary" onClick={this.handleDelete}>
            Delete
          </Button>
        </CardActions>
      </Card>
    );
  }
}

ListsCard.defaultProps = {
  list: getChecklistStruct()
};

ListsCard.propTypes = {
  classes: PropTypes.any.isRequired,
  list: PropTypes.any,
  saveList: PropTypes.func.isRequired,
  deleteList: PropTypes.func.isRequired,
  history: PropTypes.any.isRequired
};

export default withRouter(withStyles(style)(ListsCard));
