import React, { Component } from 'react';
import Trackers from '../components/Trackers/Trackers';

export class TrackersPageContainer extends Component {
  render() {
    return <Trackers />;
  }
}

export default Trackers;
