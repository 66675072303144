module.exports = () => ({
  input: {
    color: '#393939'
  },
  textField: {
    fontSize: 12,
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    lableTextColor: '#FFF'
  },
  container: {
    marginTop: 44,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  heading: {
    textAlign: 'center'
  }
});
