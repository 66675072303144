import React, { Component } from 'react';
import Help from '../components/Help/Help';

export class HelpPageContainer extends Component {
  render() {
    return <Help />;
  }
}

export default Help;
