import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import WarningIcon from '@material-ui/icons/Warning';
import amber from '@material-ui/core/colors/amber';
import Typography from '@material-ui/core/Typography';
import ROUTES from '../../constants/routes';

const variantColor = {
  warning: amber[700],
};

export const style = theme => ({
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: 20
  },
  bigIcon: {
    fontSize: 50
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  textBlock: {
    paddingTop: theme.spacing(1)
  }
});

class PayGate extends Component {
  
  handleNavigate = route => () => {
    const { history, disablePayGate } = this.props;
    disablePayGate();
    history.push(route);
  };
  
  render() {
    const { classes, payGated, subscription } = this.props;
    
    if(!subscription)
      return <React.Fragment/>;
    
    return (
      <React.Fragment>
        <Dialog
          open={payGated}
        >
          <DialogContent style={{ textAlign: 'center' }}>
            <WarningIcon
              className={classNames(
                classes.bigIcon,
                classes.iconVariant
              )}
              style={{ color: variantColor.warning }}
            />
            <div>
              <Typography variant="h5">Congratulations! You have been busy.</Typography>
              <Typography variant="subtitle1">
                BUT... unfortunately that means that you have reached a limit.
              </Typography>
              <Typography className={classes.textBlock}>
                {`As a Formigio ${subscription.name} Member you have reached the limit for your subscription level.`}
              </Typography>
              <Typography className={classes.textBlock}>
                You can <b>upgrade</b> to a membership that allows for more trackers.
              </Typography>
              <Typography className={classes.textBlock}>
                Or you can go to your existing trackers and archive some to make room for a new Tracker.
              </Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.handleNavigate(ROUTES.LIST)}
            >
              Go to List
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleNavigate(ROUTES.PAYMENT)}
            >
              Go to Upgrade
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

PayGate.propTypes = {
  classes: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  disablePayGate: PropTypes.func.isRequired,
  payGated: PropTypes.bool.isRequired,
  subscription: PropTypes.any.isRequired
};

export default withStyles(style)(PayGate);
