import {
  TRACKER_SHARE_PERMISSION_READONLY,
  TRACKER_SHARE_PERMISSIONS
} from '../structs/trackerShare';

export const getPermissionLevelLabel = (level) => TRACKER_SHARE_PERMISSIONS[level] || '';

export const isReadOnly = (share) => Boolean(share.id && share.permissionLevel === TRACKER_SHARE_PERMISSION_READONLY);

export const isShared = (share) => share.id;
