import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addLearnPageAction } from '../../redux/actions/learnPages';
import PageSelector from '../../components/Learn/PageSelector';

export class LearnPageSelectorContainer extends Component {
  render() {
    return <PageSelector />;
  }
}

const mapStateToProps = state => ({
  learnPages: state.learnPages
});

const mapDispatchToProps = dispatch => ({
  addLearnPage: (page, callback) => {
    dispatch(addLearnPageAction(page, callback));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PageSelector);
