import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import DragHandle from '@material-ui/icons/DragHandle';
import TextField from '@material-ui/core/TextField';
import Collapse from '@material-ui/core/Collapse';
import Badge from '@material-ui/core/Badge';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { getMilestoneStruct } from '../../../structs/trackers';
import Detail from '../../../containers/Milestone/Detail';
import { ListItemText } from "@material-ui/core";
import moment from "moment";
import ActionItemList from './ActionItem/List';
import CommitButton from '../../../containers/Commitment/CommitButton';
import {getMilestoneApn} from '../../../utils/apn/v2';
import Typography from '@material-ui/core/Typography';
import CalendarIcon from '@material-ui/icons/Event';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import {getContextStruct} from "../../../structs/context";

const style = () => ({
  smallerButton: {
    padding: '4px'
  },
  dragHandle: {
    margin: '15px -9px 12px -27px',
    color: 'lightgray'
  },
  checkboxButton: {
    padding: '12px 10px',
    margin: '4px 4px 0 0px'
  },
  secondaryAction: {
    right: 0
  },
  smallIconButton: {
    padding: 3,
    marginTop: -3
  },
  smallIcon: {
    paddingLeft: 3,
    paddingRight: 3,
    fontSize: 19
  }
});

class MilestoneItem extends Component {
  state = {
    activeMilestone: getMilestoneStruct(),
    detailHandle: false,
    collapseHandle: false,
    showDeleteConfirm: false,
    editNotes: false
  };

  componentDidMount() {
    this.hydrateState();
  }

  componentDidUpdate() {
    this.hydrateState();
    this.focusSelected();
  }

  inputField;
  
  focusSelected = () => {
    const { selectedItem, milestone } = this.props;

    if (
      milestone &&
      selectedItem &&
      selectedItem === milestone.id &&
      document.activeElement !== this.inputField
    ) {
      this.inputField.focus();
    }
  };

  hydrateState = () => {
    const { milestone } = this.props;
    const { activeMilestone } = this.state;
    if (
      milestone &&
      milestone.id === activeMilestone.id &&
      milestone.updatedAt === activeMilestone.updatedAt
    )
      return;
    this.setState({ activeMilestone: {...milestone} });
  };

  handleRemoveCancel = () => {
    this.setState({ showDeleteConfirm: false });
  };

  handleRemoveConfirm = () => {
    this.setState({ showDeleteConfirm: true });
  };

  handleRemove = () => {
    const { onDelete } = this.props;
    const { activeMilestone } = this.state;
    this.setState({ showDeleteConfirm: false }, () => {
      onDelete(activeMilestone);
    });
  };

  handleCollapse = () => {
    const { onOpenClose } = this.props;
    const { collapseHandle } = this.state;
    this.setState({ collapseHandle: !collapseHandle }, () => {
      onOpenClose(!collapseHandle);
    });
  };

  handleMilestoneDetailOpen = () => {
    this.setState({ detailHandle: true });
  };

  handleMilestoneDetailClose = () => {
    this.setState({ detailHandle: false });
  };

  handleChange = event => {
    const { activeMilestone } = this.state;
    const { onSave } = this.props;

    if (event.target.type === 'checkbox') {
      activeMilestone[event.target.name] = event.target.checked;
      onSave(activeMilestone);
    } else {
      activeMilestone[event.target.name] = event.target.value;
    }

    this.setState({ activeMilestone });
  };
  
  handleFocus = () => {
    const { onInputFocus, milestone } = this.props;
    if (milestone && milestone.id) {
      onInputFocus(milestone.id);
    }
  };

  handleBlur = () => {
    const { onSave, onInputBlur, milestone } = this.props;
    const { activeMilestone } = this.state;
    
    if (!activeMilestone || !milestone.id) return;
    
    if (activeMilestone.description !== milestone.description
      || activeMilestone.reached !== milestone.reached
      || activeMilestone.notes !== milestone.notes){
      onSave(activeMilestone);
    }
    onInputBlur(milestone.id);
  };
  
  handleActionItemChange = items => {
    const { onSave } = this.props;
    const { activeMilestone } = this.state;
    activeMilestone.actionItems = items.map(m => m);
    this.setState({ activeMilestone },() => {
      onSave(activeMilestone);
    });
  };
  
  
  render() {
    const { selectedItem, classes, sortable, commitmentContext: { description: contextDescription, tracker }, readOnly } = this.props;
    const {
      activeMilestone,
      detailHandle,
      collapseHandle,
      showDeleteConfirm,
      editNotes
    } = this.state;
    const milestoneApn = getMilestoneApn(activeMilestone);
    
    if (!activeMilestone.id) return null;
    
    return (
      <React.Fragment>
        <ListItem
          dense
          selected={selectedItem === activeMilestone.id}
          className={classes.listPadding}
        >
          <DragHandle className={classes.dragHandle} style={sortable ? {} : {color: 'white'}} />
          <Checkbox
            key={`${activeMilestone.id}_reached`}
            className={`tour-milestone-complete ${classes.checkboxButton}`}
            name="reached"
            checked={activeMilestone.reached}
            disabled={readOnly}
            tabIndex={-1}
            disableRipple
            onChange={this.handleChange}
            icon={
              <CheckBoxOutlineBlankIcon
                style={{ color: 'rgba(0, 0, 0, 0.54)' }}
              />
            }
          />
          <ListItemText
            secondary={activeMilestone.targetDate ? (
              <>
                <Typography style={{ fontSize: '.85em' }}>
                  <CalendarIcon color="inherit" fontSize="inherit" />
                  {' Target: '}{moment(activeMilestone.targetDate).format('MMM DD, YYYY')}
                </Typography>
              </>
            ) : null}
          >
            <TextField
              fullWidth
              name="description"
              disabled={readOnly}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onKeyPress={this.handleKeyPress}
              key={`${activeMilestone.id}_description`}
              margin="normal"
              multiline
              value={activeMilestone.description}
              InputProps={{
                disableUnderline: true
              }}
              inputRef={field => {
                this.inputField = field;
              }}
            />
          </ListItemText>
          <Detail
            open={detailHandle}
            milestone={activeMilestone}
            onClose={this.handleMilestoneDetailClose}
          />
          <ListItemSecondaryAction
            className={`tour-milestone-actions ${classes.secondaryAction}`}
          >
            <CommitButton
              style={{ marginLeft: 'auto' }}
              context={{
                apn: milestoneApn,
                description: `${contextDescription} - ${activeMilestone.description}`,
                tracker
              }}
              icon
            >
              <PostAddIcon/>
            </CommitButton>
            <IconButton size="small" onClick={this.handleCollapse} title="Show Milestone Actions">
              {collapseHandle ? (
                <ExpandLess />
              ) : (
                <Badge>
                  <ExpandMore />
                </Badge>
              )}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Collapse in={collapseHandle}>
          <Card>
            <CardActions style={{ backgroundColor: 'aliceblue'}}>
              {editNotes
                ? <>
                  <TextField
                    fullWidth
                    name="notes"
                    disabled={readOnly}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    key={`${activeMilestone.id}_notes`}
                    variant="outlined"
                    margin="normal"
                    multiline
                    autoFocus
                    value={activeMilestone.notes.trim() ? activeMilestone.notes : ''}
                    placeholder="Milestone Notes"
                    InputProps={{
                      disableUnderline: true
                    }}
                  />
                  {!readOnly && (
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => this.setState({ editNotes: !editNotes })}
                    >
                      Save
                    </Button>
                  )}
                </>
                : <Typography style={{ fontSize: '.85rem' }} onClick={() => this.setState({ editNotes: true })}>
                  {activeMilestone.notes}
                  {!readOnly && (<IconButton
                    className={classes.smallIconButton}
                    onClick={() => this.setState({ editNotes: !editNotes })}
                  >
                    <EditIcon className={classes.smallIcon}/>
                  </IconButton>)}
                </Typography>
              }
            </CardActions>
            <CardContent>
              <ActionItemList
                commitmentContext={{
                  apn: milestoneApn,
                  tracker
                }}
                milestone={activeMilestone}
                readOnly={readOnly}
                onChange={this.handleActionItemChange}
              />
            </CardContent>
            <CardActions style={{ backgroundColor: 'aliceblue'}}>
              {!readOnly && (<IconButton
                size="small"
                color="default"
                onClick={this.handleRemoveConfirm}
              >
                <DeleteForeverIcon/>
              </IconButton>)}
    
              <Button
                style={{ marginLeft: 'auto' }}
                size="small"
                color="primary"
                onClick={this.handleMilestoneDetailOpen}
                startIcon={<EditIcon />}
              >
                Details
              </Button>
    
              <IconButton
                style={{ marginLeft: 'auto' }}
                className={classes.smallerButton}
                onClick={this.handleCollapse}
              >
                <ExpandLess />
              </IconButton>
            </CardActions>
          </Card>
        </Collapse>
        <Dialog open={showDeleteConfirm}>
          <DialogTitle>Delete Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to remove this milestone?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              color="secondary"
              onClick={this.handleRemoveCancel}
            >
              Cancel
            </Button>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={this.handleRemove}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

MilestoneItem.defaultProps = {
  selectedItem: null,
  commitmentContext: getContextStruct()
};

MilestoneItem.propTypes = {
  classes: PropTypes.any.isRequired,
  milestone: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onInputBlur: PropTypes.func.isRequired,
  onInputFocus: PropTypes.func.isRequired,
  onOpenClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  sortable: PropTypes.bool.isRequired,
  selectedItem: PropTypes.any,
  readOnly: PropTypes.bool.isRequired,
  commitmentContext: PropTypes.object
};

export default withStyles(style)(MilestoneItem);
