import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  TRACKER_CREATE_SUCCESS_ACTION,
  TRACKER_DELETE_SUCCESS_ACTION,
  TRACKER_FETCH_REQUEST_ACTION,
  TRACKER_FETCH_SUCCESS_ACTION,
  TRACKER_UPDATE_SUCCESS_ACTION
} from '../actions/trackers';
import { getMilestoneStruct, getTrackerStruct } from '../../structs/trackers';
import { INTERNET_STATUS_CONNECTED } from '../../structs/notification';
import { addSyncEventAction } from '../actions/syncEvents';
import { getSyncEventStruct } from '../../structs/sync';
import {
  CHECKLIST_DELETE_REMOTE_ACTION,
  CHECKLIST_DELETE_REMOTE_SUCCESS_ACTION,
  CHECKLIST_UPSERT_REMOTE_ACTION,
  CHECKLIST_UPSERT_REMOTE_SUCCESS_ACTION,
  COMMITMENT_DELETE_REMOTE_ACTION,
  COMMITMENT_DELETE_REMOTE_SUCCESS_ACTION,
  COMMITMENT_UPSERT_REMOTE_ACTION,
  COMMITMENT_UPSERT_REMOTE_SUCCESS_ACTION,
  deleteRemoteChecklistAction,
  deleteRemoteCommitmentAction,
  deleteRemoteJournalAction,
  deleteRemoteMilestoneAction,
  deleteRemoteTrackerAction,
  JOURNAL_DELETE_REMOTE_ACTION,
  JOURNAL_DELETE_REMOTE_SUCCESS_ACTION,
  JOURNAL_UPSERT_REMOTE_ACTION,
  JOURNAL_UPSERT_REMOTE_SUCCESS_ACTION,
  MILESTONE_DELETE_REMOTE_ACTION,
  MILESTONE_DELETE_REMOTE_SUCCESS_ACTION,
  MILESTONE_UPSERT_REMOTE_ACTION,
  MILESTONE_UPSERT_REMOTE_SUCCESS_ACTION,
  TOUR_UPSERT_REMOTE_ACTION,
  TOUR_UPSERT_REMOTE_SUCCESS_ACTION,
  TRACKER_DELETE_REMOTE_ACTION,
  TRACKER_DELETE_REMOTE_SUCCESS_ACTION,
  TRACKER_UPSERT_REMOTE_ACTION,
  TRACKER_UPSERT_REMOTE_SUCCESS_ACTION,
  upsertRemoteChecklistAction,
  upsertRemoteCommitmentAction,
  upsertRemoteJournalAction,
  upsertRemoteMilestoneAction,
  upsertRemoteTourAction,
  upsertRemoteTrackerAction
} from '../actions/appSync';
import {
  MILESTONE_CREATE_SUCCESS_ACTION,
  MILESTONE_DELETE_SUCCESS_ACTION,
  MILESTONE_UPDATE_SUCCESS_ACTION
} from '../actions/milestones';
import {
  COMMITMENT_CREATE_SUCCESS_ACTION,
  COMMITMENT_DELETE_SUCCESS_ACTION,
  COMMITMENT_UPDATE_SUCCESS_ACTION
} from '../actions/commitments';
import {
  JOURNAL_CREATE_SUCCESS_ACTION,
  JOURNAL_DELETE_SUCCESS_ACTION,
  JOURNAL_UPDATE_SUCCESS_ACTION,
} from '../actions/journal';
import { getJournalStruct } from '../../structs/journal';
import {
  ADD_CHECKLIST,
  DELETE_CHECKLIST,
  SAVE_CHECKLIST
} from '../actions/checklists';
import { getChecklistStruct } from '../../structs/checklists';
import { setLoadingAction } from '../actions/notifications';
import { TOUR_UPDATE_SUCCESS_ACTION } from '../actions/tour';
import { getUserTourStruct } from '../../structs/tours';
import {fetchRemoteTracker} from '../../utils/appSync/tracker';
import { OFFER_SYNC_ACTION, toggleSyncCheckerAction } from '../actions/sync';
import { setBackupProfileAction } from '../actions/backupProfile';

function* handleUpsertTrackerRequest(action) {
  const backupProfile = yield select(store => store.backupProfile);
  const internetStatus = yield select(store => store.internetStatus);

  if (!backupProfile.verifiedAt || !backupProfile.autoSyncEnabled) {
    // No backup profile means we do nothing, return;
    return;
  }

  const tracker = getTrackerStruct(action.payload);

  if (!tracker.id) return;

  // If we have connection to AppSync then send off the request, otherwise create sync event
  if (internetStatus.status === INTERNET_STATUS_CONNECTED)
    yield put(upsertRemoteTrackerAction(tracker));
  else
    yield put(
      addSyncEventAction(
        getSyncEventStruct({
          action: TRACKER_UPSERT_REMOTE_ACTION,
          success: TRACKER_UPSERT_REMOTE_SUCCESS_ACTION,
          data: tracker
        })
      )
    );
}

function* handleFetchTrackerRequest(action) {
  const backupProfile = yield select(store => store.backupProfile);
  const internetStatus = yield select(store => store.internetStatus);

  if (!backupProfile.verifiedAt || !backupProfile.autoSyncEnabled) {
    // No backup profile means we do nothing, return;
    return;
  }

  const tracker = getTrackerStruct(action.payload);

  if (!tracker.id) return;

  // If we have connection to AppSync then send off the request, otherwise create sync event
  if (internetStatus.status === INTERNET_STATUS_CONNECTED) {
    yield put(setLoadingAction('Fetching Latest...'));
    const remoteTracker = yield fetchRemoteTracker(
      tracker.ownerApn,
      tracker.id
    );
    if (remoteTracker.id)
      yield put({ type: TRACKER_FETCH_SUCCESS_ACTION, payload: remoteTracker });
    else yield put({ type: TRACKER_FETCH_SUCCESS_ACTION, payload: tracker });
    yield put(setLoadingAction(''));
  } else {
    yield put({ type: TRACKER_FETCH_SUCCESS_ACTION, payload: tracker });
  }
}

function* handleDeleteTrackerRequest(action) {
  const backupProfile = yield select(store => store.backupProfile);
  const internetStatus = yield select(store => store.internetStatus);

  // No backup profile means we do nothing, return;
  if (!backupProfile.verifiedAt || !backupProfile.autoSyncEnabled) {
    return;
  }

  // If we have connection to AppSync then send off the request, otherwise create sync event
  if (internetStatus.status === INTERNET_STATUS_CONNECTED)
    yield put(deleteRemoteTrackerAction(action.payload));
  else
    yield put(
      addSyncEventAction(
        getSyncEventStruct({
          action: TRACKER_DELETE_REMOTE_ACTION,
          success: TRACKER_DELETE_REMOTE_SUCCESS_ACTION,
          data: action.payload
        })
      )
    );
}

function* handleUpsertMilestoneRequest(action) {
  const backupProfile = yield select(store => store.backupProfile);
  const internetStatus = yield select(store => store.internetStatus);

  if (!backupProfile.verifiedAt || !backupProfile.autoSyncEnabled) {
    // No backup profile means we do nothing. Show a sync checker popup
    return;
  }

  const milestone = getMilestoneStruct(action.payload);

  if (!milestone.id) return;

  // If we have connection to AppSync then send off the request, otherwise create sync event
  if (internetStatus.status === INTERNET_STATUS_CONNECTED)
    yield put(upsertRemoteMilestoneAction(milestone));
  else
    yield put(
      addSyncEventAction(
        getSyncEventStruct({
          action: MILESTONE_UPSERT_REMOTE_ACTION,
          success: MILESTONE_UPSERT_REMOTE_SUCCESS_ACTION,
          data: milestone
        })
      )
    );
}

function* handleDeleteMilestoneRequest(action) {
  const backupProfile = yield select(store => store.backupProfile);
  const internetStatus = yield select(store => store.internetStatus);

  // No backup profile means we do nothing, return;
  if (!backupProfile.verifiedAt || !backupProfile.autoSyncEnabled) {
    return;
  }

  // If we have connection to AppSync then send off the request, otherwise create sync event
  if (internetStatus.status === INTERNET_STATUS_CONNECTED)
    yield put(deleteRemoteMilestoneAction(action.payload));
  else
    yield put(
      addSyncEventAction(
        getSyncEventStruct({
          action: MILESTONE_DELETE_REMOTE_ACTION,
          success: MILESTONE_DELETE_REMOTE_SUCCESS_ACTION,
          data: action.payload
        })
      )
    );
}

function* handleUpsertCommitmentRequest({ payload: { commitment }}) {
  const backupProfile = yield select(store => store.backupProfile);
  const internetStatus = yield select(store => store.internetStatus);

  if (!backupProfile.verifiedAt || !backupProfile.autoSyncEnabled) {
    // No backup profile means we do nothing, return;
    return;
  }

  if (!commitment.id) return;

  // If we have connection to AppSync then send off the request, otherwise create sync event
  if (internetStatus.status === INTERNET_STATUS_CONNECTED)
    yield put(upsertRemoteCommitmentAction(commitment));
  else
    yield put(
      addSyncEventAction(
        getSyncEventStruct({
          action: COMMITMENT_UPSERT_REMOTE_ACTION,
          success: COMMITMENT_UPSERT_REMOTE_SUCCESS_ACTION,
          data: commitment
        })
      )
    );
}

function* handleDeleteCommitmentRequest(action) {
  const backupProfile = yield select(store => store.backupProfile);
  const internetStatus = yield select(store => store.internetStatus);

  // No backup profile means we do nothing, return;
  if (!backupProfile.verifiedAt || !backupProfile.autoSyncEnabled) {
    return;
  }

  // If we have connection to AppSync then send off the request, otherwise create sync event
  if (internetStatus.status === INTERNET_STATUS_CONNECTED)
    yield put(deleteRemoteCommitmentAction(action.payload));
  else
    yield put(
      addSyncEventAction(
        getSyncEventStruct({
          action: COMMITMENT_DELETE_REMOTE_ACTION,
          success: COMMITMENT_DELETE_REMOTE_SUCCESS_ACTION,
          data: action.payload
        })
      )
    );
}

function* handleUpsertJournalRequest(action) {
  const backupProfile = yield select(store => store.backupProfile);
  const internetStatus = yield select(store => store.internetStatus);

  if (!backupProfile.verifiedAt || !backupProfile.autoSyncEnabled) {
    // No backup profile means we do nothing, return;
    return;
  }

  const journal = getJournalStruct(action.payload);

  if (!journal.id) return;

  // If we have connection to AppSync then send off the request, otherwise create sync event
  if (internetStatus.status === INTERNET_STATUS_CONNECTED)
    yield put(upsertRemoteJournalAction(journal));
  else
    yield put(
      addSyncEventAction(
        getSyncEventStruct({
          action: JOURNAL_UPSERT_REMOTE_ACTION,
          success: JOURNAL_UPSERT_REMOTE_SUCCESS_ACTION,
          data: journal
        })
      )
    );
}

function* handleUpsertTourRequest(action) {
  const backupProfile = yield select(store => store.backupProfile);
  const internetStatus = yield select(store => store.internetStatus);

  if (!backupProfile.verifiedAt || !backupProfile.autoSyncEnabled) {
    // No backup profile means we do nothing, return;
    return;
  }

  const tour = getUserTourStruct(action.payload);

  if (!tour.id) return;

  // If we have connection to AppSync then send off the request, otherwise create sync event
  if (internetStatus.status === INTERNET_STATUS_CONNECTED)
    yield put(upsertRemoteTourAction(tour));
  else
    yield put(
      addSyncEventAction(
        getSyncEventStruct({
          action: TOUR_UPSERT_REMOTE_ACTION,
          success: TOUR_UPSERT_REMOTE_SUCCESS_ACTION,
          data: tour
        })
      )
    );
}

function* handleDeleteJournalRequest(action) {
  const backupProfile = yield select(store => store.backupProfile);
  const internetStatus = yield select(store => store.internetStatus);

  // No backup profile means we do nothing, return;
  if (!backupProfile.verifiedAt || !backupProfile.autoSyncEnabled) {
    return;
  }

  // If we have connection to AppSync then send off the request, otherwise create sync event
  if (internetStatus.status === INTERNET_STATUS_CONNECTED)
    yield put(deleteRemoteJournalAction(action.payload));
  else
    yield put(
      addSyncEventAction(
        getSyncEventStruct({
          action: JOURNAL_DELETE_REMOTE_ACTION,
          success: JOURNAL_DELETE_REMOTE_SUCCESS_ACTION,
          data: action.payload
        })
      )
    );
}

function* handleUpsertChecklistRequest(action) {
  const backupProfile = yield select(store => store.backupProfile);
  const internetStatus = yield select(store => store.internetStatus);

  if (!backupProfile.verifiedAt || !backupProfile.autoSyncEnabled) {
    // No backup profile means we do nothing, return;
    return;
  }

  const checklist = getChecklistStruct(action.payload);

  if (!checklist.id) return;

  // If we have connection to AppSync then send off the request, otherwise create sync event
  if (internetStatus.status === INTERNET_STATUS_CONNECTED)
    yield put(upsertRemoteChecklistAction(checklist));
  else
    yield put(
      addSyncEventAction(
        getSyncEventStruct({
          action: CHECKLIST_UPSERT_REMOTE_ACTION,
          success: CHECKLIST_UPSERT_REMOTE_SUCCESS_ACTION,
          data: checklist
        })
      )
    );
}

function* handleDeleteChecklistRequest(action) {
  const backupProfile = yield select(store => store.backupProfile);
  const internetStatus = yield select(store => store.internetStatus);

  // No backup profile means we do nothing, return;
  if (!backupProfile.verifiedAt || !backupProfile.autoSyncEnabled) {
    return;
  }

  // If we have connection to AppSync then send off the request, otherwise create sync event
  if (internetStatus.status === INTERNET_STATUS_CONNECTED)
    yield put(deleteRemoteChecklistAction(action.payload));
  else
    yield put(
      addSyncEventAction(
        getSyncEventStruct({
          action: CHECKLIST_DELETE_REMOTE_ACTION,
          success: CHECKLIST_DELETE_REMOTE_SUCCESS_ACTION,
          data: action.payload
        })
      )
    );
}

function* handleOfferSyncRequest() {
  const syncVersion = yield select(store => store.syncVersion);
  const backupProfile = yield select(store => store.backupProfile);

  if (syncVersion.lastSync) return;

  // Open sync checker dialog
  yield put(toggleSyncCheckerAction(true));

  // Set backup profile
  yield put(setBackupProfileAction({
    ...backupProfile,
    verifiedAt: '',
    autoSyncEnabled: true
  }));
}

export default function* dataSyncSaga() {
  // Tracker Flow
  yield takeLatest(TRACKER_FETCH_REQUEST_ACTION, handleFetchTrackerRequest);
  yield takeEvery(TRACKER_CREATE_SUCCESS_ACTION, handleUpsertTrackerRequest);
  yield takeEvery(TRACKER_UPDATE_SUCCESS_ACTION, handleUpsertTrackerRequest);
  yield takeEvery(TRACKER_DELETE_SUCCESS_ACTION, handleDeleteTrackerRequest);

  //  Milestone Flow
  yield takeEvery(
    MILESTONE_CREATE_SUCCESS_ACTION,
    handleUpsertMilestoneRequest
  );
  yield takeEvery(
    MILESTONE_UPDATE_SUCCESS_ACTION,
    handleUpsertMilestoneRequest
  );
  yield takeEvery(
    MILESTONE_DELETE_SUCCESS_ACTION,
    handleDeleteMilestoneRequest
  );

  // Commitment Flow
  yield takeEvery(
    COMMITMENT_CREATE_SUCCESS_ACTION,
    handleUpsertCommitmentRequest
  );
  yield takeEvery(
    COMMITMENT_UPDATE_SUCCESS_ACTION,
    handleUpsertCommitmentRequest
  );
  yield takeEvery(
    COMMITMENT_DELETE_SUCCESS_ACTION,
    handleDeleteCommitmentRequest
  );

  // Journal Flow
  yield takeEvery(JOURNAL_CREATE_SUCCESS_ACTION, handleUpsertJournalRequest);
  yield takeEvery(JOURNAL_UPDATE_SUCCESS_ACTION, handleUpsertJournalRequest);
  yield takeEvery(JOURNAL_DELETE_SUCCESS_ACTION, handleDeleteJournalRequest);

  yield takeEvery(ADD_CHECKLIST, handleUpsertChecklistRequest);
  yield takeEvery(SAVE_CHECKLIST, handleUpsertChecklistRequest);
  yield takeEvery(DELETE_CHECKLIST, handleDeleteChecklistRequest);

  yield takeEvery(TOUR_UPDATE_SUCCESS_ACTION, handleUpsertTourRequest);
  yield takeLatest(OFFER_SYNC_ACTION, handleOfferSyncRequest);
}
