import { put, select, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { loadFromStorage } from '../../utils/localStorage';
import {
  APPLICATION_DATA_CLEAR_REQUEST_ACTION,
  APPLICATION_DATA_REQUEST_ACTION
} from '../actions/app';
import {
  LOAD_TOUR_FROM_DISK,
  loadTourFromDiskAction,
  SET_TOUR_COMPLETE,
  TOUR_DATA_LOAD_REQUEST_ACTION,
  TOUR_DATA_LOAD_SUCCESS_ACTION,
  TOUR_FULL_SYNC_REQUEST_ACTION,
  TOUR_FULL_SYNC_SUCCESS_ACTION,
  TOUR_UPDATE_REQUEST_ACTION,
  TOUR_UPDATE_SUCCESS_ACTION
} from '../actions/tour';
import { getUserToursStruct, getUserTourStruct } from '../../structs/tours';
import { fetchRemoteTours } from '../../utils/appSync/tour';

function* handleUpdateTourRequest(action) {
  // Persist BackupProfile to Local Data Store
  const userTours = yield select(store => store.tour);
  userTours.tours[action.payload.id] = getUserTourStruct(action.payload);
  userTours.updatedAt = moment().format();

  yield put({
    type: SET_TOUR_COMPLETE,
    payload: userTours
  });

  // Signal Complete
  yield put({
    type: TOUR_UPDATE_SUCCESS_ACTION,
    payload: getUserTourStruct(action.payload)
  });
}

function* handleTourDataLoadRequest(action) {
  // Load data into the store
  yield put({
    type: LOAD_TOUR_FROM_DISK,
    payload: action.payload
  });

  yield put({
    type: TOUR_DATA_LOAD_SUCCESS_ACTION
  });
}

function* handleLoadApplicationDataRequest() {
  // Perform Application Data Load
  const tourState = yield select(store => store.tour);

  // Call the Util Local Storage loadState function which pulls data
  // from the local storage if cached there, if not it pulls from the disk
  const tour = yield loadFromStorage('tour', tourState);

  yield put(loadTourFromDiskAction(tour));

  yield put({ type: TOUR_DATA_LOAD_SUCCESS_ACTION });
}

function* handleStateChange() {
  const type = 'tour';

  // Select data from the store
  const data = yield select(store => store[type]);
  const dataString = JSON.stringify(data);

  // As well as in the local storage.
  localStorage.setItem(type, dataString);
}

function* handleApplicationDataClear() {
  yield put(loadTourFromDiskAction(getUserToursStruct()));
}

function* handleFullSyncRequest() {
  const user = yield select(store => store.user);
  const localTours = yield select(store => store.tours);

  const remoteTours = yield fetchRemoteTours(user.id);

  if (remoteTours.length) {
    const tours = {};
    remoteTours.map(tour => {
      tours[tour.id] = tour;
      return tour;
    });
    yield put(
      loadTourFromDiskAction(getUserToursStruct({ ...localTours, tours }))
    );
  }

  yield put({ type: TOUR_FULL_SYNC_SUCCESS_ACTION });
}

export default function* tourSaga() {
  yield takeLatest(TOUR_UPDATE_REQUEST_ACTION, handleUpdateTourRequest);
  yield takeLatest(TOUR_DATA_LOAD_REQUEST_ACTION, handleTourDataLoadRequest);
  yield takeLatest(TOUR_UPDATE_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(
    APPLICATION_DATA_REQUEST_ACTION,
    handleLoadApplicationDataRequest
  );
  yield takeLatest(TOUR_DATA_LOAD_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(
    APPLICATION_DATA_CLEAR_REQUEST_ACTION,
    handleApplicationDataClear
  );
  yield takeLatest(TOUR_FULL_SYNC_REQUEST_ACTION, handleFullSyncRequest);
}
