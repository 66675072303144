import moment from 'moment';
import {
  RESET_ACTIVE_MILESTONE,
  SET_ACTIVE_MILESTONE,
  SAVE_ACTIVE_MILESTONE,
  REQUEST_ACTIVE_MILESTONE
} from '../actions/activeMilestone';
import {
  ActiveMilestoneStruct,
  getMilestoneStruct
} from '../../structs/trackers';

export default function activeMilestoneReducer(
  state = Object.assign({}, ActiveMilestoneStruct),
  action
) {
  let activeMilestone = getMilestoneStruct();
  switch (action.type) {
    case SET_ACTIVE_MILESTONE:
      activeMilestone = getMilestoneStruct(action.payload);
      return { milestone: activeMilestone, requestedId: activeMilestone.id };

    case SAVE_ACTIVE_MILESTONE:
      activeMilestone = getMilestoneStruct(action.payload);
      activeMilestone.updatedAt = moment().format();
      return { milestone: activeMilestone, requestedId: activeMilestone.id };

    case REQUEST_ACTIVE_MILESTONE:
      return { milestone: activeMilestone, requestedId: action.payload };

    case RESET_ACTIVE_MILESTONE:
      return { milestone: activeMilestone, requestedId: '' };

    default:
      return state;
  }
}
