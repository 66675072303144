import { UPDATE_JOURNAL_INDEX } from '../actions/journal';
import { JournalIndexStruct } from '../../structs/indexers';

export default function journalIndexReducer(
  oldstate = Object.assign({}, JournalIndexStruct),
  action
) {
  switch (action.type) {
    case UPDATE_JOURNAL_INDEX:
      return action.payload;

    default:
      return oldstate;
  }
}
