import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuickAdd from '../../components/Commitment/QuickAdd';
import { addCommitmentAction } from '../../redux/actions/commitments';

export class QuickAddFormContainer extends Component {
  render() {
    return <QuickAdd />;
  }
}

const mapStateToProps = state => ({
  searchIndex: state.searchIndex,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  addCommitment: commitment => {
    dispatch(addCommitmentAction(commitment));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickAdd);
