import React, { Component } from 'react';
import { connect } from 'react-redux';
import MyAccount from '../components/MyAccount/MyAccount';
import {
  confirmEmailAction,
  signOutAction,
  updateUserAttributesAction
} from '../redux/actions/auth';
import { showErrorAction } from '../redux/actions/notifications';
import {setUserCheckAction} from '../redux/actions/app';

export class MyAccountPageContainer extends Component {
  render() {
    return <MyAccount />;
  }
}

const mapStateToProps = state => ({
  appUser: state.user,
  membership: state.membership
});

const mapDispatchToProps = dispatch => ({
  showError: message => dispatch(showErrorAction(message)),
  setUserCheck: userCheck => dispatch(setUserCheckAction(userCheck)),
  updateUser: user => dispatch(updateUserAttributesAction(user)),
  confirmEmail: user => dispatch(confirmEmailAction(user)),
  signOut: () => dispatch(signOutAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
