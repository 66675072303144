import { SET_TOUR_COMPLETE, LOAD_TOUR_FROM_DISK } from '../actions/tour';
import { getUserToursStruct } from '../../structs/tours';

export default function tourReducer(state = getUserToursStruct(), action) {
  switch (action.type) {
    case SET_TOUR_COMPLETE:
      return action.payload;

    case LOAD_TOUR_FROM_DISK:
      return action.payload;

    default:
      return state;
  }
}
