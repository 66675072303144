import { v4 as uuidv4 } from 'uuid';
import { getTrackerStruct } from '../../structs/trackers';
import { stringifyApn } from '../../utils/apn/v2';

// Active Tracker Actions
export const INIT_ACTIVE_TRACKER = 'INIT_ACTIVE_TRACKER';
export const SET_ACTIVE_TRACKER = 'SET_ACTIVE_TRACKER';
export const RESET_ACTIVE_TRACKER = 'RESET_ACTIVE_TRACKER';
export const REQUEST_ACTIVE_TRACKER = 'REQUEST_ACTIVE_TRACKER';

export const initActiveTrackerAction = user => {
  const id = uuidv4();
  const ownerApn = stringifyApn({ userId: user.id });

  return {
    type: INIT_ACTIVE_TRACKER,
    payload: getTrackerStruct({ id, ownerApn })
  };
};

export const setActiveTrackerAction = tracker => ({
  type: SET_ACTIVE_TRACKER,
  payload: getTrackerStruct(tracker)
});

export const requestActiveTrackerAction = trackerId => ({
  type: REQUEST_ACTIVE_TRACKER,
  payload: trackerId
});

export const resetActiveTrackerAction = () => ({
  type: RESET_ACTIVE_TRACKER,
  payload: null
});
