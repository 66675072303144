import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import {
  COMMITMENT_STATUS_MOVED,
  COMMITMENT_STATUS_PENDING,
  getCommitmentDocumentStruct
} from '../../structs/commitments';
import {
  getCommitmentIndexByDateStruct,
  getSearchIndexStruct
} from '../../structs/indexers';
import {getTrackerDocumentStruct, TRACKER_STATUS_ACTIVE, TRACKER_STATUS_ARCHIVED} from '../../structs/trackers';
import { getMilestoneIndexStruct } from '../../structs/indexers';
import { getJournalDocumentStruct } from '../../structs/journal';
import { updateCommitmentIndexAction } from '../../redux/actions/commitments';
import { updateTrackerIndexAction } from '../../redux/actions/trackers';
import {getDaysUntilReview, getLastReviewedDate, getReviewDueDate} from '../../models/tracker';
import { updateSearchIndexAction } from '../../redux/actions/search';
import { updateJournalIndexAction } from '../../redux/actions/journal';
import { loadApplicationDataAction } from '../../redux/actions/app';
import {getDateFromString, isSameDate} from '../../utils/date';
import {updateMilestoneIndexAction} from "../../redux/actions/milestones";
import {getTrackerShareStruct} from "../../structs/trackerShare";
import {setRolesAction} from "../../redux/actions/roles";
import {ROLE_NO_ROLE_NAME} from "../../structs/roles";

class DataIndexer extends Component {
  componentDidMount() {
    const { loadApplicationData } = this.props;
    this.reindexActive = true;
    loadApplicationData();
  }

  componentDidUpdate() {
    this.debounceReIndex();
  }

  componentWillUnmount() {
    this.reindexActive = false;
    this.debounceReIndex.cancel();
  }

  reindexActive = false;

  debounceReIndex = debounce(() => {
    if (this.reindexActive === false) return;

    const {
      user,
      commitments,
      trackers,
      trackerShares,
      journal,
      updateCommitmentIndex,
      updateTrackerIndex,
      updateSearchIndex,
      updateJournalIndex,
      updateRoles,
      activeRole,
      today: now
    } = this.props;

    let allTrackerDocs = [];
    const allRoles = {};
    const trackersById = {};
    const activeByRole = {};
    const shareConfigForTracker = {};
    const sharesByTracker = {};
    const today = [];
    const allToday = [];
    const byDate = {};
    const searchIndex = [];
    const journalByDate = {};
    const milestoneIndex = getMilestoneIndexStruct({});

    if (trackerShares.length) {
      for (const share of trackerShares) {
        if (share.userId === user.id) {
          shareConfigForTracker[share.trackerId] = share;
        } else {
          if (!sharesByTracker[share.trackerId])
            sharesByTracker[share.trackerId] = [];
          if (sharesByTracker[share.trackerId])
            sharesByTracker[share.trackerId].push(share);
        }
      }
    }

    if (!trackers.length) {
      updateTrackerIndex({
        allToday: [],
        today: [],
        byId: {},
        all: allTrackerDocs,
        activeByRole: {}
      });
    } else {
      allTrackerDocs = trackers
        .filter(tracker => tracker.id)
        .map(tracker => {

        const { milestones }  = tracker;
        const share = shareConfigForTracker[tracker.id] || getTrackerShareStruct();
        let { reviewCycle, role } = tracker;

        // If there is a share, override the owner's review cycle with the share version.
        if (share.id && share.reviewCycle) {
          reviewCycle = share.reviewCycle
        }

        // If there is a share, override the owner's role with the share version.
        if (share.id && share.role) {
          role = share.role
        }

        const reviewDate = moment(getReviewDueDate(reviewCycle, tracker.status === TRACKER_STATUS_ARCHIVED));

        const trackerDoc = getTrackerDocumentStruct({
          entity: tracker,
          reviewCycle,
          reviewDate,
          share,
          role,
          shares: sharesByTracker[tracker.id] || []
        });

        trackersById[tracker.id] = trackerDoc;

        const roleName = role?.name?.trim() || '';
        
        if (roleName) {
          allRoles[roleName] = role;
        } else {
          allRoles[''] = {
            name: ROLE_NO_ROLE_NAME,
            color: ''
          };
        }

        if (typeof activeByRole[roleName] === 'undefined') {
          activeByRole[roleName] = [];
        }

        if (
          tracker.status === TRACKER_STATUS_ACTIVE &&
          getDaysUntilReview(reviewDate) <= 0
        ) {
          today.push(trackerDoc);
          activeByRole[role.name.trim()].push(tracker.id);
        }

        if (
          tracker.status === TRACKER_STATUS_ACTIVE &&
          (getDaysUntilReview(reviewDate) <= 0 ||
            isSameDate(new Date(getLastReviewedDate(reviewCycle)), now))
        ) {
          allToday.push(trackerDoc);
        }

        searchIndex.push(
          getSearchIndexStruct({
            entity: tracker,
            id: tracker.id,
            text: tracker.dream,
            type: 'tracker'
          })
        );

        if (milestones && milestones.length) {
          milestones.map(milestone => {
            milestoneIndex.byId[milestone.id] = milestone;

            searchIndex.push(
              getSearchIndexStruct({
                entity: milestone,
                id: tracker.id,
                text: milestone.description,
                context: `Tracker: ${tracker.dream}`,
                type: 'milestone'
              })
            );

            return true;
          });
        }

        return trackerDoc;
      });
    }

    const journalSearchIndex = journal.map(entity => {
      const { trackerId, entry: text } = entity;
      const entryDate = new Date(entity.datetime);
      const trackerDoc = trackersById[trackerId];
      const { entity: tracker } = trackerDoc || getTrackerDocumentStruct();

      // Index By Date for the journal page
      if (journalByDate[entryDate.toDateString()] === undefined) {
        journalByDate[entryDate.toDateString()] = [];
      }
      journalByDate[entryDate.toDateString()].push(
        getJournalDocumentStruct({
          entity,
          dream: tracker.dream
        })
      );

      return getSearchIndexStruct({
        id: tracker.id,
        text,
        context: `Tracker: ${tracker.dream}`,
        type: 'journal',
        entity
      });
    });

    if (!commitments.length) {
      updateCommitmentIndex({
        byDate: {},
        all: [],
        active: [],
        byRole: {},
        byTray: {}
      });
    } else {
      const activeCommitments = [];
      const byRole = {};
      const byTray = {};
      // Daily Commitment Indexing
      commitments.sort((a, b) => {
        const aSortDate = moment(a.date);
        const bSortDate = moment(b.date);

        if (a.status !== COMMITMENT_STATUS_PENDING) aSortDate.add(1, 'day');
        if (b.status !== COMMITMENT_STATUS_PENDING) bSortDate.add(1, 'day');
        
        return aSortDate.isAfter(bSortDate) ? 1 : -1;
      });

      const allCommitmentDocs = commitments.map(entity => {
        const commitmentIndex = getCommitmentDocumentStruct({ entity });
        const commitDate = new Date(entity.date);
        const { trackerId, id, role } = entity;
        const commitDay = getDateFromString(entity.date, true);
        
        const trackerDoc = trackersById[trackerId];
        if (trackerDoc) {
          if (entity.status === COMMITMENT_STATUS_PENDING)
            trackersById[trackerId].activeCommitments.push(commitmentIndex);
        }

        if (trackerDoc?.entity?.status === 'active' || !trackerDoc) {
          if (
            (entity.status === COMMITMENT_STATUS_PENDING && commitDay <= now)
            || (entity.status === COMMITMENT_STATUS_MOVED && isSameDate(commitDay, now))
          ) {
            activeCommitments.push(commitmentIndex);

            if (typeof byRole[role.name.trim()] === 'undefined') {
              byRole[role.name.trim()] = [];
            }

            byRole[role.name.trim()].push(id);

            if (entity.trays && entity.trays.length > 0) {
              entity.trays.map((trayId) => {
                if (typeof byTray[trayId] === 'undefined') {
                  byTray[trayId] = [];
                }
                byTray[trayId].push(commitmentIndex);
                return trayId;
              });
            }
          }
        }

        // Index By Date for the home page
        if (byDate[commitDate.toDateString()] === undefined) {
          byDate[commitDate.toDateString()] = getCommitmentIndexByDateStruct();
        }

        if (activeRole.name === '') {
          byDate[commitDate.toDateString()].commitments.push(commitmentIndex);
        } else if (activeRole.name && activeRole.name === role.name) {
          byDate[commitDate.toDateString()].commitments.push(commitmentIndex);
        }

        if (entity.status === COMMITMENT_STATUS_PENDING) {
          byDate[commitDate.toDateString()].committedTime += parseInt(
            entity.timeCommitment,
            10
          );
        }

        if (role.name.trim()) {
          allRoles[role.name.trim()] = role;
        } else {
          allRoles[''] = {
            name: ROLE_NO_ROLE_NAME,
            color: ''
          };
        }

        searchIndex.push(
          getSearchIndexStruct({
            entity,
            id,
            text: entity.description,
            context: entity.contextDescription,
            type: 'commitment'
          })
        );

        return commitmentIndex;
      });

      updateCommitmentIndex({
        byDate,
        all: allCommitmentDocs,
        active: activeRole.name
          ? activeCommitments.filter(c => (c.entity.role.name === activeRole.name) || (activeRole.name === ROLE_NO_ROLE_NAME && c.entity.role.name === ''))
          : activeCommitments,
        byRole,
        byTray
      });
    }

    today.sort((a, b) => {
      const {
        entity: {
          reviewCycle: { lastReviewedDate: dateA }
        }
      } = a;
      const {
        entity: {
          reviewCycle: { lastReviewedDate: dateB }
        }
      } = b;
      return moment(dateA).diff(moment(dateB));
    });

    updateTrackerIndex({
      allToday: activeRole.name
        ? allToday.filter(t => (t.role.name === activeRole.name) || (t.role.name === '' && activeRole.name === ROLE_NO_ROLE_NAME))
        : allToday,
      today: activeRole.name
        ? today.filter(t => (t.role.name === activeRole.name) || (t.role.name === '' && activeRole.name === ROLE_NO_ROLE_NAME))
        : today,
      byId: trackersById,
      all: allTrackerDocs,
      activeByRole
    });
    updateRoles(Object.values(allRoles));
    updateSearchIndex({ all: [...searchIndex, ...journalSearchIndex] });
    updateJournalIndex({ byDate: journalByDate });
    updateMilestoneIndexAction(milestoneIndex);
  }, 250);

  render() {
    return <React.Fragment />;
  }
}

DataIndexer.propTypes = {
  user: PropTypes.any.isRequired,
  commitments: PropTypes.any.isRequired,
  trackers: PropTypes.any.isRequired,
  trackerShares: PropTypes.any.isRequired,
  journal: PropTypes.any.isRequired,
  updateCommitmentIndex: PropTypes.any.isRequired,
  updateTrackerIndex: PropTypes.any.isRequired,
  updateSearchIndex: PropTypes.any.isRequired,
  updateJournalIndex: PropTypes.any.isRequired,
  loadApplicationData: PropTypes.func.isRequired,
  updateRoles: PropTypes.func.isRequired,
  today: PropTypes.instanceOf(Date).isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  commitments: state.commitments,
  trackers: state.trackers,
  trackerShares: state.trackerShares,
  journal: state.journal.items,
  today: state.app.today,
  activeRole: state.roles.active,
  trays: state.trays
});

const mapDispatchToProps = dispatch => ({
  updateRoles: data => {
    dispatch(setRolesAction(data));
  },
  updateCommitmentIndex: data => {
    dispatch(updateCommitmentIndexAction(data));
  },
  updateTrackerIndex: data => {
    dispatch(updateTrackerIndexAction(data));
  },
  updateSearchIndex: data => {
    dispatch(updateSearchIndexAction(data));
  },
  updateJournalIndex: data => {
    dispatch(updateJournalIndexAction(data));
  },
  loadApplicationData: () => {
    dispatch(loadApplicationDataAction());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DataIndexer);
