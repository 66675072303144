import React, { Component } from 'react';
import { connect } from 'react-redux';
import ListsPage from '../components/ListsPage/ListsPage';

export class ListsPageContainer extends Component {
  render() {
    return <ListsPage />;
  }
}

function mapStateToProps(state) {
  return {
    trackerIndex: state.trackerIndex
  };
}

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ListsPage);
