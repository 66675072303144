import {API, graphqlOperation} from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import moment from "moment";
import {getMigrationStruct} from "../../structs/migration";
import {prepAndPruneForAppSync} from "../appSync";

export const fetchMigration = async (userId, migrationKey) => {
  const { data: { getMigration: entry }} = await API.graphql(
    graphqlOperation(queries.getMigration, {
      userId,
      migrationKey
    })
  );

  return postFetchProcessMigration(entry);
};

export const saveMigration = async (userId, migrationKey) => {
  const existingMigration = await fetchMigration(userId, migrationKey);

  const input = {
    input: prepAndPruneForAppSync({
      userId,
      migrationKey,
      completed: moment().toISOString()
    }, getMigrationStruct)
  }

  if (existingMigration.migrationKey) {
    await API.graphql(
      graphqlOperation(mutations.updateMigration, input)
    );
  } else {
    await API.graphql(
      graphqlOperation(mutations.createMigration, input)
    );
  }

};

const postFetchProcessMigration = migration => getMigrationStruct(migration);
