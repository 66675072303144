import { put, takeLatest, select } from 'redux-saga/effects';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import {
  ADD_CHECKLIST,
  DELETE_CHECKLIST,
  SAVE_CHECKLIST,
  CHECKLIST_CREATE_REQUEST_ACTION,
  CHECKLIST_CREATE_SUCCESS_ACTION,
  CHECKLIST_DELETE_REQUEST_ACTION,
  CHECKLIST_DELETE_SUCCESS_ACTION,
  CHECKLIST_UPDATE_REQUEST_ACTION,
  CHECKLIST_UPDATE_SUCCESS_ACTION,
  CHECKLIST_DATA_LOAD_SUCCESS_ACTION,
  loadChecklistsFromDiskAction,
  CHECKLIST_DATA_LOAD_REQUEST_ACTION,
  LOAD_CHECKLISTS_FROM_DISK,
  UPSERT_LOCAL_CHECKLIST
} from '../actions/checklists';
import { CHANGE_LOG_ADD_REQUEST_ACTION } from '../actions/changeLogs';
import { getChangeLogStruct } from '../../structs/changeLogs';
import { prepAndPruneChecklistForAppSync } from '../../utils/appSync';
import {
  getChecklistStruct,
  migrateChecklists
} from '../../structs/checklists';
import { stringifyApn } from '../../utils/apn/v2';
import {
  APPLICATION_DATA_CLEAR_REQUEST_ACTION,
  APPLICATION_DATA_REQUEST_ACTION
} from '../actions/app';
import { loadFromStorage } from '../../utils/localStorage';

function* handleCreateChecklistRequest(action) {
  const user = yield select(store => store.user);
  // Persist Checklist to Local Data Store
  const checklist = getChecklistStruct(action.payload);
  checklist.id = uuidv4();
  checklist.ownerApn = stringifyApn({ userId: user.id });
  checklist.createdAt = moment().format();
  checklist.updatedAt = moment().format();

  yield put({
    type: ADD_CHECKLIST,
    payload: checklist
  });

  // Signal Complete
  yield put({
    type: CHECKLIST_CREATE_SUCCESS_ACTION
  });
}

function* handleUpdateChecklistRequest(action) {
  // Persist Checklist to Local Data Store
  const checklist = getChecklistStruct(action.payload);
  checklist.updatedAt = moment().format();

  yield put({
    type: SAVE_CHECKLIST,
    payload: checklist
  });

  // Signal Complete
  yield put({
    type: CHECKLIST_UPDATE_SUCCESS_ACTION
  });
}

function* handleDeleteChecklistRequest(action) {
  // Persist Checklist to Local Data Store
  yield put({
    type: DELETE_CHECKLIST,
    payload: action.payload
  });

  // Signal Complete
  yield put({
    type: CHECKLIST_DELETE_SUCCESS_ACTION
  });
}

function* handleUpdateChangeLog(action) {
  // Persist Change Log
  yield put({
    type: CHANGE_LOG_ADD_REQUEST_ACTION,
    payload: getChangeLogStruct({
      action: UPSERT_LOCAL_CHECKLIST,
      data: prepAndPruneChecklistForAppSync(action.payload)
    })
  });
}

function* handleAddChangeLog(action) {
  // Persist Change Log
  yield put({
    type: CHANGE_LOG_ADD_REQUEST_ACTION,
    payload: getChangeLogStruct({
      action: UPSERT_LOCAL_CHECKLIST,
      data: prepAndPruneChecklistForAppSync(action.payload)
    })
  });
}

function* handleDeleteChangeLog(action) {
  // Persist Change Log
  yield put({
    type: CHANGE_LOG_ADD_REQUEST_ACTION,
    payload: getChangeLogStruct({
      action: DELETE_CHECKLIST,
      data: prepAndPruneChecklistForAppSync(action.payload)
    })
  });
}

function* handleChecklistDataLoadRequest(action) {
  // Persist Tracker to Local Data Store
  yield put({
    type: LOAD_CHECKLISTS_FROM_DISK,
    payload: action.payload
  });

  // Signal Complete
  yield put({ type: CHECKLIST_DATA_LOAD_SUCCESS_ACTION });
}

function* handleLoadApplicationDataRequest() {
  // Perform Application Data Load
  const checklistsState = yield select(store => store.checklists);
  const user = yield select(store => store.user);

  // Call the Util Local Storage loadState function which pulls data
  // from the local storage if cached there, if not it pulls from the disk
  const checklists = yield loadFromStorage('checklists', checklistsState);
  const migratedChecklists = yield migrateChecklists(checklists, user);

  yield put(loadChecklistsFromDiskAction(migratedChecklists));
}

function* handleStateChange() {
  const type = 'checklists';

  // Select data from the store
  const data = yield select(store => store[type]);
  const dataString = JSON.stringify(data);

  // As well as in the local storage.
  localStorage.setItem(type, dataString);
}

function* handleApplicationDataClear() {
  yield put(loadChecklistsFromDiskAction([]));
}

export default function* checklistSaga() {
  // Handle Request Events
  yield takeLatest(
    CHECKLIST_CREATE_REQUEST_ACTION,
    handleCreateChecklistRequest
  );
  yield takeLatest(
    CHECKLIST_UPDATE_REQUEST_ACTION,
    handleUpdateChecklistRequest
  );
  yield takeLatest(
    CHECKLIST_DELETE_REQUEST_ACTION,
    handleDeleteChecklistRequest
  );
  yield takeLatest(
    CHECKLIST_DATA_LOAD_REQUEST_ACTION,
    handleChecklistDataLoadRequest
  );
  yield takeLatest(
    APPLICATION_DATA_REQUEST_ACTION,
    handleLoadApplicationDataRequest
  );

  // Add Change Logs after data events
  yield takeLatest(ADD_CHECKLIST, handleAddChangeLog);
  yield takeLatest(SAVE_CHECKLIST, handleUpdateChangeLog);
  yield takeLatest(DELETE_CHECKLIST, handleDeleteChangeLog);

  // Update Storage after certain events
  yield takeLatest(CHECKLIST_CREATE_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(CHECKLIST_UPDATE_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(CHECKLIST_DELETE_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(UPSERT_LOCAL_CHECKLIST, handleStateChange);
  yield takeLatest(CHECKLIST_DATA_LOAD_SUCCESS_ACTION, handleStateChange);
  yield takeLatest(
    APPLICATION_DATA_CLEAR_REQUEST_ACTION,
    handleApplicationDataClear
  );
}
