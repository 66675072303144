import React, { Component } from 'react';
import { connect } from 'react-redux';
import ResetPassword from '../components/ResetPassword/ResetPassword';
import { forgotPasswordAction } from '../redux/actions/auth';

export class ResetPasswordPageContainer extends Component {
  render() {
    return <ResetPassword />;
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  forgotPassword: credentials => dispatch(forgotPasswordAction(credentials))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
