import React, { Component } from 'react';
import {v4 as uuidv4} from "uuid";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { getPlanStruct } from "../../../structs/trackers";
import PlanItem from './Item';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List/List';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';

const Style = () => ({
  details: {
    width: '100%',
    '& > ul': {
      width: '100%'
    }
  }
});

const reorderItems = (items, startIndex, endIndex) => {
  const result = Array.from(items);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class PlanList extends Component {
  state = {
    newPlan: getPlanStruct(),
    reorder: false
  };
  
  handleKeyPress = event => {
    if (event.key !== 'Enter') return;
    
    event.target.blur();
  };
  
  handlePlanAdd = () => {
    const { newPlan } = this.state;
    const { onChange, tracker: { plans } } = this.props;
    let i = 1;
    
    this.setState({ newPlan: getPlanStruct() }, () => {
      if (newPlan.title) {
        const newPlans = [...plans, {...newPlan, apn: uuidv4()}].map(plan => ({...plan, seq: i++}));
        onChange(newPlans);
      }
    })
  };

  handlePlanChange = changedPlan => {
    const { onChange, tracker: { plans } } = this.props;
    
    const newPlans = plans.map(plan => plan.apn === changedPlan.apn ? changedPlan : plan);
    onChange(newPlans);
  };

  handlePlanRemove = planToRemove => {
    const { onChange, tracker: { plans } } = this.props;
    let i = 1;
    
    const newPlans = plans.filter(plan => plan.apn !== planToRemove.apn).map(plan => ({...plan, seq: i++}));
    onChange(newPlans);
  };
  
  handlePlanReorder = () => {
    const { reorder } = this.state;
    this.setState({ reorder: !reorder });
  };
  
  onDragEnd = result => {
    const {
      destination: {
        droppableId: destinationDropId, index: destinationIndex
      }, source: {
        droppableId: sourceDropId, index: sourceIndex
      }
    } = result;
    const { onChange, tracker: { plans } } = this.props;

    // dropped outside the list
    if (!result.destination) return;

    if (destinationDropId === 'plans') {
      // Plans only reorder
      let i = 1;
      let newPlans = reorderItems(
        plans,
        sourceIndex,
        destinationIndex
      ).map(plan => ({...plan, seq: i++}));
  
      // Persist to Tracker
      onChange(newPlans);
    } else {
      
      // Reorder milestones, or move them between plans
      const [, destinationPlanApn] = destinationDropId.split('|');
      const [, sourcePlanApn] = sourceDropId.split('|');
      const destinationPlan = plans.find(plan => plan.apn === destinationPlanApn);
      const { milestoneIds: destinationMilestoneIds } = destinationPlan;
      
      // Dropped in the same place
      if (destinationIndex === sourceIndex && destinationDropId === sourceDropId) return;

      if (destinationDropId === sourceDropId) {
        // Dropped in the same list
        const newMilestoneIds = reorderItems(
          destinationMilestoneIds,
          sourceIndex,
          destinationIndex
        );
        const newPlans = plans.map(plan => destinationPlan.apn === plan.apn ? {...plan, milestoneIds: newMilestoneIds} : plan);
        onChange(newPlans);
        
      } else {
        // Dropped in a different plan
        const sourcePlan = plans.find(plan => plan.apn === sourcePlanApn);
        const { milestoneIds: sourceMilestoneIds } = sourcePlan;
        
        const [removed] = sourceMilestoneIds.splice(sourceIndex, 1);
        destinationMilestoneIds.splice(destinationIndex, 0, removed);
  
        const newPlans = plans.map(plan => {
          if (destinationPlan.apn === plan.apn)
            return {...plan, milestoneIds: destinationMilestoneIds};
          else if (sourcePlan.apn === plan.apn)
            return {...plan, milestoneIds: sourceMilestoneIds};
          else
            return plan;
        });
        
        // Persist the updated plans to the tracker
        onChange(newPlans);
  
      }
    }
  };
  
  
  render() {
    const { tracker: { plans }, tracker, readOnly } = this.props;
    const { reorder } = this.state;
    return (
      <div>
        <DragDropContext onDragEnd={this.onDragEnd}>
        {reorder ? (
            <List>
              <Droppable droppableId="plans">
                {provided => (
                  <div ref={provided.innerRef}>
                    {plans.map((plan, index) => (
                      <Draggable
                        draggableId={String(plan.apn)}
                        key={plan.apn}
                        index={index}
                      >
                        {innerProvided => (
                          <div
                            ref={innerProvided.innerRef}
                            {...innerProvided.draggableProps}
                            {...innerProvided.dragHandleProps}
                          >
                            <PlanItem listItem key={plan.apn} plan={plan} tracker={tracker} onChange={this.handlePlanChange} onRemove={this.handlePlanRemove} />
                          </div>
                          )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </List>
        ) :
          (plans.map((plan) => (
            <PlanItem key={plan.apn} plan={plan} tracker={tracker} readOnly={readOnly} onChange={this.handlePlanChange} onRemove={this.handlePlanRemove} />
          )))
        }
        </DragDropContext>
        {readOnly ? null : (
          <>
            <Button onClick={this.handlePlanAdd}>Add Plan</Button>
            <Button onClick={this.handlePlanReorder}>{reorder ? 'Finish' : 'Reorder/Edit'}</Button>
          </>
        )}
      </div>
    )
  }
}

PlanList.propTypes = {
  classes: PropTypes.any.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(Style)(PlanList);
