import {
  all,
  put,
  select,
  takeEvery,
  take
} from 'redux-saga/effects';
import moment from 'moment';
import {
  removeRemoteChecklist,
  removeRemoteCommitment,
  upsertRemoteChecklist,
  upsertRemoteCommitment,
} from '../../utils/appSync';
import {
  upsertRemoteJournal,
  removeRemoteJournal
} from '../../utils/appSync/journal';
import {
  JOURNAL_DELETE_REMOTE_ACTION,
  JOURNAL_DELETE_REMOTE_SUCCESS_ACTION,
  JOURNAL_UPSERT_REMOTE_ACTION,
  JOURNAL_UPSERT_REMOTE_SUCCESS_ACTION,
  COMMITMENT_DELETE_REMOTE_ACTION,
  COMMITMENT_DELETE_REMOTE_SUCCESS_ACTION,
  COMMITMENT_UPSERT_REMOTE_ACTION,
  COMMITMENT_UPSERT_REMOTE_SUCCESS_ACTION,
  MILESTONE_DELETE_REMOTE_ACTION,
  MILESTONE_DELETE_REMOTE_SUCCESS_ACTION,
  MILESTONE_UPSERT_REMOTE_ACTION,
  MILESTONE_UPSERT_REMOTE_SUCCESS_ACTION,
  TRACKER_DELETE_REMOTE_ACTION,
  TRACKER_DELETE_REMOTE_SUCCESS_ACTION,
  TRACKER_UPSERT_REMOTE_ACTION,
  TRACKER_UPSERT_REMOTE_SUCCESS_ACTION,
  CHECKLIST_UPSERT_REMOTE_SUCCESS_ACTION,
  CHECKLIST_DELETE_REMOTE_SUCCESS_ACTION,
  CHECKLIST_UPSERT_REMOTE_ACTION,
  CHECKLIST_DELETE_REMOTE_ACTION,
  APPLICATION_FULL_SYNC_SUCCESS_ACTION,
  APPLICATION_FULL_SYNC_REQUEST_ACTION,
  VERIFY_APP_SYNC_REQUEST_ACTION,
  VERIFY_APP_SYNC_SUCCESS_ACTION,
  VERIFY_APP_SYNC_FAILURE_ACTION,
  APPLICATION_QUICK_SYNC_REQUEST_ACTION,
  APPLICATION_QUICK_SYNC_SUCCESS_ACTION,
  applicationFullSyncAction,
  TOUR_UPSERT_REMOTE_ACTION,
  TOUR_UPSERT_REMOTE_SUCCESS_ACTION
} from '../actions/appSync';
import {
  JOURNAL_FULL_SYNC_REQUEST_ACTION,
  JOURNAL_FULL_SYNC_SUCCESS_ACTION,
  UPSERT_LOCAL_JOURNAL
} from '../actions/journal';
import {SYNC_EVENT_SYNC_SUCCESS_ACTION, syncAllSyncEventsAction} from '../actions/syncEvents';
import { setLoadingAction, showErrorAction } from '../actions/notifications';
import {
  TRACKER_DATA_LOAD_SUCCESS_ACTION,
  TRACKER_FULL_SYNC_REQUEST_ACTION,
  TRACKER_FULL_SYNC_SUCCESS_ACTION,
  TRACKER_QUICK_SYNC_REQUEST_ACTION,
  TRACKER_QUICK_SYNC_SUCCESS_ACTION,
  upsertLocalTrackerAction
} from '../actions/trackers';
import {
  COMMITMENT_DATA_LOAD_SUCCESS_ACTION,
  COMMITMENT_FULL_SYNC_REQUEST_ACTION,
  COMMITMENT_FULL_SYNC_SUCCESS_ACTION,
  COMMITMENT_QUICK_SYNC_REQUEST_ACTION,
  COMMITMENT_QUICK_SYNC_SUCCESS_ACTION,
  UPSERT_LOCAL_COMMITMENT
} from '../actions/commitments';
import {
  setLastSyncAction,
  SYNC_DATA_LOAD_SUCCESS_ACTION
} from '../actions/sync';
import {
  CHANGE_LOG_SYNC_SUCCESS_ACTION,
  syncChangeLogAction
} from '../actions/changeLogs';
import { UPSERT_LOCAL_MILESTONE } from '../actions/milestones';
import { APPLICATION_INIT_REQUEST_ACTION } from '../actions/app';
import { BACKUP_PROFILE_DATA_LOAD_SUCCESS_ACTION } from '../actions/backupProfile';
import {
  SET_USER
} from '../actions/user';
import {
  MEMBERSHIP_FETCH_STATUS_ACTION,
  MEMBERSHIP_FETCH_STATUS_SUCCESS_ACTION,
  MEMBERSHIP_UPSERT_STATUS_ACTION, MEMBERSHIP_UPSERT_STATUS_SUCCESS_ACTION, setMembershipStatusAction
} from '../actions/membership';
import { AUTH_GET_CURRENT_USER_SUCCESS_ACTION } from '../actions/auth';
import { INTERNET_STATUS_CONNECTED } from '../../structs/notification';
import {
  fetchRemoteMembershipStatus,
  upsertRemoteMembershipStatus
} from '../../utils/appSync/membershipStatus';
import {
  TOUR_FULL_SYNC_REQUEST_ACTION,
  TOUR_FULL_SYNC_SUCCESS_ACTION
} from '../actions/tour';
import { upsertRemoteTour } from '../../utils/appSync/tour';
import {fetchRemoteTrackerMostRecent, removeRemoteTracker, upsertRemoteTracker} from '../../utils/appSync/tracker';
import {removeRemoteMilestone, upsertRemoteMilestone} from '../../utils/appSync/milestone';
import {TRACKER_SHARE_FULL_SYNC_REQUEST_ACTION, TRACKER_SHARE_FULL_SYNC_SUCCESS_ACTION} from '../actions/trackerShares';

function* handleUpsertTrackerRequest(action) {
  const updatedTracker = yield upsertRemoteTracker(action.payload);
  yield put(upsertLocalTrackerAction(updatedTracker));
  yield put({
    type: TRACKER_UPSERT_REMOTE_SUCCESS_ACTION,
    payload: updatedTracker
  });
}

function* handleDeleteTrackerRequest(action) {
  yield removeRemoteTracker(action.payload);
  yield put({
    type: TRACKER_DELETE_REMOTE_SUCCESS_ACTION,
    payload: action.payload
  });
}

function* handleUpsertMilestoneRequest(action) {
  const updatedMilestone = yield upsertRemoteMilestone(action.payload);
  if (updatedMilestone) {
    yield put({ type: UPSERT_LOCAL_MILESTONE, payload: updatedMilestone });
    yield put({
      type: MILESTONE_UPSERT_REMOTE_SUCCESS_ACTION,
      payload: updatedMilestone
    });
  }
}

function* handleDeleteMilestoneRequest(action) {
  yield removeRemoteMilestone(action.payload);
  yield put({
    type: MILESTONE_DELETE_REMOTE_SUCCESS_ACTION,
    payload: action.payload
  });
}

function* handleUpsertCommitmentRequest(action) {
  const updatedCommitment = yield upsertRemoteCommitment(action.payload);
  yield put({ type: UPSERT_LOCAL_COMMITMENT, payload: updatedCommitment });
  yield put({
    type: COMMITMENT_UPSERT_REMOTE_SUCCESS_ACTION,
    payload: updatedCommitment
  });
}

function* handleDeleteCommitmentRequest(action) {
  yield removeRemoteCommitment(action.payload);
  yield put({
    type: COMMITMENT_DELETE_REMOTE_SUCCESS_ACTION,
    payload: action.payload
  });
}

function* handleUpsertJournalRequest(action) {
  const updatedJournal = yield upsertRemoteJournal(action.payload);
  yield put({ type: UPSERT_LOCAL_JOURNAL, payload: updatedJournal });
  yield put({
    type: JOURNAL_UPSERT_REMOTE_SUCCESS_ACTION,
    payload: updatedJournal
  });
}

function* handleDeleteJournalRequest(action) {
  yield removeRemoteJournal(action.payload);
  yield put({
    type: JOURNAL_DELETE_REMOTE_SUCCESS_ACTION,
    payload: action.payload
  });
}

function* handleUpsertTourRequest(action) {
  const user = yield select(store => store.user);
  const updatedTour = yield upsertRemoteTour(user, action.payload);
  yield put({
    type: TOUR_UPSERT_REMOTE_SUCCESS_ACTION,
    payload: updatedTour
  });
}

function* handleUpsertChecklistRequest(action) {
  yield upsertRemoteChecklist(action.payload);
  yield put({ type: CHECKLIST_UPSERT_REMOTE_SUCCESS_ACTION });
}

function* handleDeleteChecklistRequest(action) {
  yield removeRemoteChecklist(action.payload);
  yield put({ type: CHECKLIST_DELETE_REMOTE_SUCCESS_ACTION });
}

function* handleFetchMembershipStatus() {
  const localUser = yield select(store => store.user);
  const status = yield fetchRemoteMembershipStatus(localUser);
  if (status.id)
    yield put(setMembershipStatusAction(status));

  yield put({ type: MEMBERSHIP_FETCH_STATUS_SUCCESS_ACTION });
}

function* handleUpsertMembershipStatus() {
  const user = yield select(store => store.user);
  const membership = yield select(store => store.membership);
  const status = yield upsertRemoteMembershipStatus({
    ...membership,
    id: user.id,
    lastLoginTime: moment().format(),
    stripeSubscription: {
      ...membership.stripeSubscription,
      updatedAt: moment().format()
    }
  });
  
  yield put(setMembershipStatusAction(status));
  
  yield put({
    type: MEMBERSHIP_UPSERT_STATUS_SUCCESS_ACTION
  });
}

function* handleAppSyncVerifyRequest() {
  yield put(setLoadingAction('Verifying...'));

  const user = yield select(store => store.user);
  const backupProfile = yield select(store => store.backupProfile);
  try {
    if (!backupProfile.verifiedAt) {
      yield fetchRemoteTrackerMostRecent(user);
      yield put({ type: VERIFY_APP_SYNC_SUCCESS_ACTION });
    }
  } catch (err) {
    yield put(showErrorAction(`There was an error: ${JSON.stringify(err)}`));
    yield put({ type: VERIFY_APP_SYNC_FAILURE_ACTION, payload: err });
  }

  yield put(setLoadingAction(''));
}

function* handleAppFullSyncRequest() {
  const internetStatus = yield select(store => store.internetStatus);
  if (internetStatus.status !== INTERNET_STATUS_CONNECTED) return;

  yield put(syncAllSyncEventsAction());
  yield take(SYNC_EVENT_SYNC_SUCCESS_ACTION);
  
  yield put({ type: TRACKER_FULL_SYNC_REQUEST_ACTION });
  yield take(TRACKER_FULL_SYNC_SUCCESS_ACTION);

  yield put({ type: TRACKER_SHARE_FULL_SYNC_REQUEST_ACTION });
  yield take(TRACKER_SHARE_FULL_SYNC_SUCCESS_ACTION);

  yield put({ type: COMMITMENT_FULL_SYNC_REQUEST_ACTION });
  yield take(COMMITMENT_FULL_SYNC_SUCCESS_ACTION);

  yield put({ type: JOURNAL_FULL_SYNC_REQUEST_ACTION });
  yield take(JOURNAL_FULL_SYNC_SUCCESS_ACTION);

  yield put({ type: TOUR_FULL_SYNC_REQUEST_ACTION });
  yield take(TOUR_FULL_SYNC_SUCCESS_ACTION);

  yield put(setLastSyncAction(moment().toISOString()));

  yield put(syncChangeLogAction());

  yield put({ type: APPLICATION_FULL_SYNC_SUCCESS_ACTION });
}

function* handleAppQuickSyncRequest() {
  const internetStatus = yield select(store => store.internetStatus);
  if (internetStatus.status !== INTERNET_STATUS_CONNECTED) return;

  yield put(syncChangeLogAction());
  yield take(CHANGE_LOG_SYNC_SUCCESS_ACTION);

  yield put({ type: TRACKER_QUICK_SYNC_REQUEST_ACTION });
  yield take(TRACKER_QUICK_SYNC_SUCCESS_ACTION);

  yield put({ type: COMMITMENT_QUICK_SYNC_REQUEST_ACTION });
  yield take(COMMITMENT_QUICK_SYNC_SUCCESS_ACTION);

  yield put(setLastSyncAction(moment().toISOString()));

  yield put({ type: APPLICATION_QUICK_SYNC_SUCCESS_ACTION });
}

function* handleApplicationInitSync() {
  yield all([
    take(TRACKER_DATA_LOAD_SUCCESS_ACTION),
    take(COMMITMENT_DATA_LOAD_SUCCESS_ACTION),
    take(BACKUP_PROFILE_DATA_LOAD_SUCCESS_ACTION),
    take(SYNC_DATA_LOAD_SUCCESS_ACTION),
    take(AUTH_GET_CURRENT_USER_SUCCESS_ACTION),
    take(SET_USER)
  ]);

  console.log('Application data is loaded');

  const backupProfile = yield select(store => store.backupProfile);
  const syncVersion = yield select(store => store.syncVersion);

  if (backupProfile.verifiedAt && syncVersion.lastSync)
    yield put(applicationFullSyncAction());
}

export default function* appSyncSaga() {
  yield takeEvery(APPLICATION_INIT_REQUEST_ACTION, handleApplicationInitSync);
  yield takeEvery(
    APPLICATION_FULL_SYNC_REQUEST_ACTION,
    handleAppFullSyncRequest
  );
  yield takeEvery(
    APPLICATION_QUICK_SYNC_REQUEST_ACTION,
    handleAppQuickSyncRequest
  );
  yield takeEvery(TRACKER_UPSERT_REMOTE_ACTION, handleUpsertTrackerRequest);
  yield takeEvery(TRACKER_DELETE_REMOTE_ACTION, handleDeleteTrackerRequest);
  yield takeEvery(
    MILESTONE_UPSERT_REMOTE_ACTION,
    handleUpsertMilestoneRequest
  );
  yield takeEvery(MILESTONE_DELETE_REMOTE_ACTION, handleDeleteMilestoneRequest);
  yield takeEvery(
    COMMITMENT_UPSERT_REMOTE_ACTION,
    handleUpsertCommitmentRequest
  );
  yield takeEvery(
    COMMITMENT_DELETE_REMOTE_ACTION,
    handleDeleteCommitmentRequest
  );
  yield takeEvery(JOURNAL_UPSERT_REMOTE_ACTION, handleUpsertJournalRequest);
  yield takeEvery(JOURNAL_DELETE_REMOTE_ACTION, handleDeleteJournalRequest);
  yield takeEvery(TOUR_UPSERT_REMOTE_ACTION, handleUpsertTourRequest);
  yield takeEvery(CHECKLIST_UPSERT_REMOTE_ACTION, handleUpsertChecklistRequest);
  yield takeEvery(CHECKLIST_DELETE_REMOTE_ACTION, handleDeleteChecklistRequest);
  yield takeEvery(
    MEMBERSHIP_FETCH_STATUS_ACTION,
    handleFetchMembershipStatus
  );
  yield takeEvery(
    MEMBERSHIP_UPSERT_STATUS_ACTION,
    handleUpsertMembershipStatus
  );
  yield takeEvery(VERIFY_APP_SYNC_REQUEST_ACTION, handleAppSyncVerifyRequest);
}
