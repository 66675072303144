import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {copyTrackerAction} from "../../../redux/actions/trackers";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const CopyDialog = (
  {
    tracker,
    onDialog,
    open
  }
) => {
  const dispatch = useDispatch();
  const [ name, setName ] = useState('');
  const nameRef = useRef(null);

  useEffect(() => {
    setName(tracker.dream + ' (COPY)')
  }, [tracker.dream])

  const handleClose = () => {
    onDialog('');
  };

  const handleFocus = event => {
    event.target.select();
  };

  const handleSubmit = () => {
    dispatch(copyTrackerAction({
      ...tracker,
      dream: name
    }));
    onDialog('');
  };

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Copy Tracker
        <IconButton
          style={{ position: 'absolute', right: 0, top: 0 }}
          onClick={handleClose}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          inputRef={nameRef}
          label="Tracker Dream Statement"
          autoFocus
          value={name}
          fullWidth
          onFocus={handleFocus}
          onChange={(event) => setName(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
};

CopyDialog.defaultProps = {};

CopyDialog.propTypes = {
  tracker: PropTypes.object.isRequired,
  onDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default CopyDialog;
