import React, { Component } from 'react';
import { connect } from 'react-redux';
import ListsCard from '../../components/Checklists/Card';
import {
  deleteChecklistAction,
  saveChecklistAction
} from '../../redux/actions/checklists';

export class ListsCardContainer extends Component {
  render() {
    return <ListsCard />;
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  saveList: list => {
    dispatch(saveChecklistAction(list));
  },
  deleteList: list => {
    dispatch(deleteChecklistAction(list));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ListsCard);
