
const createFileName = (filename) => {
  return filename.toLowerCase().replace(RegExp('[^a-z0-9-]+','g'),'_')
}

const triggerDownload = (data, filename) => {
  const blob = new Blob([data], { type: "application/json" });

  const dataURI = `data:application/json;charset=utf-8,${data}`;

  const URL = window.URL || window.webkitURL;
  const downloadURI =
    typeof URL.createObjectURL === "undefined"
      ? dataURI
      : URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.setAttribute("href", downloadURI);
  link.setAttribute("download", `${createFileName(filename)}.json`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default triggerDownload;