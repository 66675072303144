import { put, select, takeLatest } from 'redux-saga/effects';
import {
  REQUEST_ACTIVE_MILESTONE,
  setActiveMilestoneAction
} from '../actions/activeMilestone';

function* handleActiveMilestoneRequest(action) {
  // Set Active Milestone to the store for Commitment Focus and Milestone Focus

  const { tracker } = yield select(store => store.activeTracker);

  if (!tracker.id) return;

  const milestoneToSet = tracker.milestones
    .filter(milestone => milestone.id === action.payload)
    .pop();

  if (!milestoneToSet) return;

  yield put(setActiveMilestoneAction(milestoneToSet));
}

export default function* activeMilestoneSaga() {
  yield takeLatest(REQUEST_ACTIVE_MILESTONE, handleActiveMilestoneRequest);
}
