import React, { Component } from 'react';
import { connect } from 'react-redux';
import Register from '../components/Register/Register';
import {
  confirmUserAction,
  resendCodeAction,
  signUpAction
} from '../redux/actions/auth';

export class RegisterPageContainer extends Component {
  render() {
    return <Register />;
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  signUp: user => dispatch(signUpAction(user)),
  confirmUser: user => dispatch(confirmUserAction(user)),
  resendCode: username => dispatch(resendCodeAction(username))
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
