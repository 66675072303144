import React, { Component } from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CalendarIcon from '@material-ui/icons/DateRange';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Style from './Style';
import { getCommitmentIndexByDateStruct } from '../../structs/indexers';
import {
  convertPickerDateToDate,
  formatDateForPicker,
  getHoursFromMinutes,
  getToday,
  getTomorrow
} from '../../utils/date';
import TimeOfDayHelper from '../Ui/TimeOfDayHelper';

class DateHelper extends Component {
  state = {
    dates: [],
    custom: false
  };

  componentDidMount() {
    this.hydrateState();
  }

  componentDidUpdate() {
    this.hydrateState();
  }

  customDateRef;

  hydrateState = () => {
    const { dates } = this.state;

    if (!dates.length) {
      range(0, 6).map(adjust => {
        const dateVal = new Date();
        dateVal.setDate(dateVal.getDate() + adjust);
        dates.push(dateVal);
        return null;
      });
      this.setState({ dates });
    }
  };

  handleSetDate = dateVal => () => {
    const { onSelect } = this.props;
    onSelect(dateVal.toString());
  };

  handleCustomDate = () => {
    const { onSelect } = this.props;
    this.setState({ custom: false });
    onSelect(convertPickerDateToDate(this.customDateRef.value));
  };

  handleShowCustom = () => {
    this.setState({ custom: true });
  };

  getButtonDateText = date => {
    const today = getToday(true);
    const tomorrow = getTomorrow(true);
    let buttonText = new Intl.DateTimeFormat('en-US', {
      weekday: 'short'
    }).format(date);
    const subButtonText = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric'
    }).format(date);

    if (today.toDateString() === date.toDateString()) {
      buttonText = 'Today';
    }
    if (tomorrow.toDateString() === date.toDateString()) {
      buttonText = 'Tomorrow';
    }

    return (
      <div style={{ display: 'block' }}>
        <Typography>{buttonText}</Typography>
        <Typography style={{ fontSize: '.85em' }}>{subButtonText}</Typography>
      </div>
    );
  };

  render() {
    const { dates, custom } = this.state;
    const { commitment, commitmentIndex } = this.props;
    const commitmentDate = new Date(commitment.date);
    const { byDate } = commitmentIndex;

    if (!commitment.date) return null;

    return (
      <div>
        <div>
          <IconButton onClick={this.handleShowCustom}>
            <CalendarIcon />
          </IconButton>
          {dates.length
            ? dates.map(dateVal => {
                let { timeCommitted } = getCommitmentIndexByDateStruct();
                if (byDate[dateVal.toDateString()])
                  timeCommitted = byDate[dateVal.toDateString()].committedTime;
                const selected =
                  dateVal.toDateString() === commitmentDate.toDateString();

                return (
                  <Tooltip
                    key={dateVal.toDateString()}
                    title={`Time Committed: ${getHoursFromMinutes(
                      timeCommitted
                    )}`}
                  >
                    <Button
                      size="small"
                      variant={selected ? 'outlined' : 'text'}
                      onClick={this.handleSetDate(dateVal)}
                    >
                      {this.getButtonDateText(dateVal)}
                    </Button>
                  </Tooltip>
                );
              })
            : null}
        </div>
        <Dialog open={custom}>
          <DialogContent>
            <Typography>
              Current Date: {commitmentDate.toLocaleString()}
            </Typography>
            <TextField
              type="date"
              label="Choose Another Date"
              defaultValue={formatDateForPicker(commitmentDate)}
              inputRef={ref => {
                this.customDateRef = ref;
              }}
              InputLabelProps={{ shrink: true }}
            />
            <TimeOfDayHelper
              date={commitmentDate}
              onSelect={this.handleSetDate}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCustomDate}>Go</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DateHelper.defaultProps = {
  commitment: null
};

DateHelper.propTypes = {
  commitment: PropTypes.any,
  commitmentIndex: PropTypes.any.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default withStyles(Style)(DateHelper);
