import { v4 as uuidv4 } from "uuid";
import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from "../actions/notifications";
import { getSystemNotificationStruct } from "../../structs/notification";

export default function notificationReducer(oldstate = [], action) {
  let notification = getSystemNotificationStruct();
  switch (action.type) {
    case ADD_NOTIFICATION:
      notification = getSystemNotificationStruct(action.payload);
      notification.id = uuidv4();
      return [...oldstate, notification];

    case REMOVE_NOTIFICATION:
      return oldstate.filter((compare) => compare.id !== action.payload.id);

    default:
      return oldstate;
  }
}
