import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import routes from './constants/routes.json';
import packageJson from '../package.json';
import versionInfo from './version.json';
import AppContainer from './containers/App';
import HomePage from './containers/HomePage';
import LoginPage from './containers/LoginPage';
import WelcomePage from './containers/WelcomePage';
import MyAccountPage from './containers/MyAccountPage';
import ResetPasswordPage from './containers/ResetPasswordPage';
import RegisterPage from './containers/RegisterPage';
import ConfirmPage from './containers/ConfirmPage';
import ConfirmConfirmationPage from './containers/ConfirmConfirmationPage';
import CreateDreamPage from './containers/CreateDreamPage';
import TrackersPage from './containers/TrackersPage';
import TrackerPage from './containers/TrackerPage';
import Navigation from './components/Navgation/Navigation';
import SettingsPage from './containers/SettingsPage';
import HelpPage from './containers/HelpPage';
import BottomBar from './components/Navgation/BottomBar';
import DataIndexer from './containers/Indexer/Data';
import JournalPage from './containers/JournalPage';
import CommitmentFocus from './containers/Commitment/Focus';
import CheckListsPage from './containers/ChecklistsPage';
import ListDetailPage from './containers/ChecklistDetailPage';
import LearnPage from './containers/Learn/Page';
import LearnPages from './containers/Learn/Pages';
import Notifications from './containers/Notifications';
import SyncChecker from './containers/Indexer/SyncChecker';
import SomethingWentWrong from './components/Errors/SomethingWentWrong';
import PaymentPage from './containers/PaymentPage';
import NavigationHandler from './containers/NavigationHandler';
import ListsPage from './containers/ListsPage';
import GeneratedNotificationPage from './containers/GeneratedNotificationPage';
import { USER_STATUS_LOGGED_IN } from './structs/user';
import PayGate from './containers/PayGate';
import CommitmentForm from './containers/Commitment/Form';
import Typography from '@material-ui/core/Typography';
import ShareAcceptPage from './containers/ShareAccept';
import CompletedDialog from "./components/Commitment/CompletedDialog";
import TrackerTemplatePage from "./containers/TrackerTemplatesPage";

const styles = () => ({
  navigation: {
    width: '10%'
  },
  container: {
    marginTop: '1em',
    width: '90%'
  },
  containerFull: {
    marginTop: '1em',
    width: '100%',
    minHeight: '90vh'
  },
  copyright: {
    textAlign: 'center',
    margin: '1em',
    padding: '1em',
    marginBottom: 40,
    '& p': {
      fontSize: 12
    }
  }
});

const UnconnectedPrivateRoute = ({ component: Component, user, ...rest }) => {
  const isLoggedIn = user.status === USER_STATUS_LOGGED_IN;
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to={routes.LOGIN} />
      }
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const PrivateRoute = connect(mapStateToProps)(UnconnectedPrivateRoute);

class Routes extends Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  state = {
    hasError: false,
  };

  componentDidCatch(e) {
    console.log('Routes Component Error Catcher', e);
  }

  renderErrorContent = () => <SomethingWentWrong />;

  isPagePublic = page => {
    const publicPages = [
      routes.LOGIN,
      routes.REGISTER,
      routes.CONFIRM,
      routes.CONFIRM_CONFIRMATION,
      routes.WELCOME,
      routes.RESET_PASSWORD,
      routes.SHARE_ACCEPT
    ];

    const publicPage = publicPages.filter(route => {
      if (route === page) return true;
      if (RegExp(':[a-zA-Z0-9-]+').test(route)) {
        const wildcardRoute = route.replace('/','').replace(/(:[a-zA-Z0-9-]+)/g,'(.*)');
        const test = RegExp(wildcardRoute,'g').test(page.replace('/',''));
        return test;
      }
      return false;
    });

    return Boolean(publicPage.length);
  };

  renderRoutes() {
    const { location, classes } = this.props;

    const publicPage = this.isPagePublic(location.pathname);

    return (
      <AppContainer>
        <Grid container>
          {!publicPage ? (
            <Grid item className={classes.navigation}>
              <Navigation />
            </Grid>
          ) : null}
          <Grid
            container
            className={publicPage ? classes.containerFull : classes.container}
            direction="row"
            alignItems={publicPage ? 'center' : 'flex-start'}
            justify={publicPage ? 'center' : 'flex-start'}
          >
            <Switch>
              <PrivateRoute path={routes.HOME} component={HomePage} exact />
              <PrivateRoute path={routes.PAYMENT} component={PaymentPage} exact />
              <PrivateRoute path={routes.MY_ACCOUNT} component={MyAccountPage} exact />
              <PrivateRoute path={routes.SETTINGS} component={SettingsPage} exact />
              <PrivateRoute path={routes.HELP} component={HelpPage} exact />
              <PrivateRoute path={routes.JOURNAL} component={JournalPage} exact />
              <PrivateRoute
                path={routes.CHECKLISTS}
                component={CheckListsPage}
                exact
              />
              <PrivateRoute path={routes.CHECKLIST} component={ListDetailPage} exact />
              <PrivateRoute
                path={routes.CREATEDREAM}
                component={CreateDreamPage}
                exact
              />
              <PrivateRoute
                path={routes.NOTIFICATIONS}
                component={GeneratedNotificationPage}
                exact
              />
              <PrivateRoute
                path={routes.TRACKERS}
                component={TrackersPage}
                exact
              />
              <PrivateRoute
                path={routes.TRACKER}
                component={TrackerPage}
                exact
              />
              <PrivateRoute
                path={routes.LEARN_PAGE}
                component={LearnPage}
                exact
              />
              <PrivateRoute
                path={routes.LEARN_PAGE_BASE}
                component={LearnPages}
                exact
              />
              <PrivateRoute path={routes.LIST} component={ListsPage} exact />
              <PrivateRoute path={routes.TRACKER_TEMPLATES} component={TrackerTemplatePage} exact />
              <Route path={routes.LOGIN} component={LoginPage} exact />
              <Route path={routes.REGISTER} component={RegisterPage} exact />
              <Route path={routes.CONFIRM} component={ConfirmPage} exact />
              <Route path={routes.CONFIRM_CONFIRMATION} component={ConfirmConfirmationPage} exact />
              <Route path={routes.WELCOME} component={WelcomePage} exact />
              <Route path={routes.SHARE_ACCEPT} component={ShareAcceptPage} exact />
              <Route
                path={routes.RESET_PASSWORD}
                component={ResetPasswordPage}
                exact
              />
              <Redirect to={routes.WELCOME} />
            </Switch>
            <Grid item className={classes.copyright} xs={12}>
              <Typography>
                Copyright &copy; 2019-{new Date().getFullYear()}{" "}
                {packageJson.author.name} - Version {versionInfo.version} - RC:{" "}
                {versionInfo.candidate}
              </Typography>
              <Typography>
                Formigio is a registered trademark of {packageJson.author.name}.
              </Typography>
            </Grid>
          </Grid>
          {!publicPage && (
            <>
              <BottomBar />
              <DataIndexer />
              <SyncChecker />
              <CommitmentFocus />
              <CommitmentForm />
              <CompletedDialog />
            </>
          )}
          <Notifications />
          <NavigationHandler />
          <PayGate />
        </Grid>
      </AppContainer>
    );
  }

  render() {
    const { hasError } = this.state;

    if (hasError) return this.renderErrorContent();

    return this.renderRoutes();
  }
}

Routes.propTypes = {
  location: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Routes));
