import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { UserStruct } from '../../structs/user';

const style = theme => ({
  textField: {
    fontSize: 12,
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    display: 'block'
  },
  input: {
    color: '#393939',
    flex: '1 1 auto',
    width: '100%'
  },
  title: {
    color: theme.palette.primary.main
  },
  notesTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 0.5,
    marginTop: 55
  },
  popUpNotes: {
    marginTop: 17,
    marginRight: 14,
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 1.43,
    letterSpacing: 0
  },
  notes: {
    minHeight: 50,
    marginTop: 17,
    padding: 16,
    backgroundColor: '#fffbdd',
    boxShadow:
      '0 2px 12px 0 rgba(0, 0, 0, 0.13), 0 3px 4px 0 rgba(0, 0, 0, 0.13)'
  },
  notesLabel: {
    fontSize: 11,
    fontWeight: 'bold',
    letterSpacing: 0.5
  }
});

class Notes extends Component {
  state = {
    notes: ''
  };

  componentDidMount() {
    const { notes } = this.state;
    const { user } = this.props;

    if (notes !== user.notes) {
      this.fillInNotes(user.notes);
    }
  }

  componentDidUpdate() {
    const { notes } = this.state;
    const { user } = this.props;

    if (user.notes !== notes && this.originalNotes) {
      this.fillInNotes(user.notes);
    }
  }

  originalNotes = true;

  fillInNotes = notes => {
    this.setState({ notes });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    this.originalNotes = false;
    const notes = event.target.value;
    this.deferSave(notes);
  };

  deferSave = debounce(notes => {
    const { saveUserNotes } = this.props;
    saveUserNotes(notes);
  }, 1000);

  render() {
    const { classes, user } = this.props;
    const { notes } = this.state;

    return (
      <div className={`${classes.notes} tour-home-notes`}>
        <span className={[classes.notesLabel, classes.title].join(' ')}>
          Notes / Scratch Pad
        </span>
        <TextField
          id="notes"
          name="notes"
          key={user.id}
          multiline
          value={notes === ' ' ? '' : notes}
          margin="normal"
          placeholder="Makes some notes"
          className={classes.textField}
          InputProps={{
            className: classes.input,
            disableUnderline: true
          }}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

Notes.defaultProps = {
  user: UserStruct,
  saveUserNotes: () => null
};

Notes.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  saveUserNotes: PropTypes.func
};
export default withStyles(style)(Notes);
