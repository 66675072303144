import { SET_PROCESSING } from '../actions/notifications';

export default function processingReducer(oldstate = '', action) {
  switch (action.type) {
    case SET_PROCESSING:
      return action.payload;

    default:
      return oldstate;
  }
}
